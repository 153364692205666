import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import React, { useEffect, useMemo, useState } from 'react';
import { Button, Card, Col, Form, Modal, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
interface RenegociacaoModalProps {
    data: any;
    showRenegociarModal: boolean;
    handleClose: () => void;
    handleBack: () => void;
    handleNext: () => void;
    handleData: (newData: any) => void;
}
interface Parcela {
    id: number;
    name: string;
    valor_original: number;
}

const RenegociacaoModal: React.FC<RenegociacaoModalProps> = ({ data, handleData, handleNext, handleBack, handleClose, showRenegociarModal }) => {
    const [show, setShow] = useState(showRenegociarModal);
    const [selectedAbertas, setSelectedAbertas] = useState<Parcela[]>([]);
    const [selectedInadimplentes, setSelectedInadimplentes] = useState<Parcela[]>([]);
    const combinedSelectedParcelas = [...selectedAbertas, ...selectedInadimplentes];
    const [rowClick, setRowClick] = useState(true);
    const contrato = data?.contrato;

    const objectToArray = (dataObject: { [x: string]: any; }) => {
        return Object.keys(dataObject).map(key => dataObject[key]);
    }

    const Qtd_abertas = selectedAbertas?.length;
    const Qtd_inadimplentes = selectedInadimplentes?.length;
    
    const parcelasAbertas = useMemo(() => objectToArray(data?.abertas ?? {}), [data?.abertas]);
    const parcelasInadimplentes = useMemo(() => objectToArray(data?.inadimplentes ?? {}), [data?.inadimplentes]);

    useEffect(() => {
        if (showRenegociarModal) {
            setSelectedAbertas([]);
            setSelectedInadimplentes([]);
        }
        setShow(showRenegociarModal);
    }, [showRenegociarModal]);

    const calculaTotalParcelas = (parcelas: any[]) => {
        let total = 0;
        parcelas.forEach((item: { valor_original: string; }) => {
            const valor = parseFloat(item?.valor_original);
            total += isNaN(valor) ? 0 : valor;
        });
        return total;
    };

    const MoneyFormatter = (saldoDevedor: any) => {
        const formatMoney = (value: number): string => {
            return new Intl.NumberFormat('pt-BR', {
                style: 'currency',
                currency: 'BRL',
            }).format(value);
        };
        return formatMoney(saldoDevedor);
    };

    const totalParcelasAbertas = calculaTotalParcelas(selectedAbertas);
    const totalParcelasInadimplentes = calculaTotalParcelas(selectedInadimplentes);
    const valorTotalParcSelecionada = totalParcelasAbertas + totalParcelasInadimplentes;

    const dataToSend = {
        totalQuantidadeParcelas: Qtd_abertas + Qtd_inadimplentes,
        parcelasSelecionadas: combinedSelectedParcelas,
        valorTotalParcelasSelecionadas: valorTotalParcSelecionada,
        abertas: data?.abertas,
        inadimplentes: data?.inadimplentes,
        contrato: contrato,
        qtd_total_vencidas: data?.qtd_total_vencidas,
        valor_total_vencidas: data?.valor_total_vencidas,
        qtd_total_pagas: data?.qtd_total_pagas,
        valor_total_pagas: data?.valor_total_pagas,
        qtd_total_abertas: data?.qtd_total_abertas,
        valor_total_abertas: data?.valor_total_abertas
    };

    const isFormValid = () => {
        if ((Qtd_abertas + Qtd_inadimplentes) === 0) {
            toast.error('Selecione ao menos uma parcela.');
            return false;
        }
        return true;
    };

    return (
        <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            size='xl'
        >
            <Modal.Header closeButton>
                <Modal.Title>Renegociação</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Card style={{ display: 'flex', borderRadius: '10px', justifyContent: 'center', margin: '12px', padding: '6px', paddingTop: '12px', backgroundColor: '#282c34' }}>
                        <Form.Label style={{ color: '#e8550f', display: 'flex', justifyContent: 'center' }}>Qtd Total Parcelas: {contrato?.qtd_parcelas}</Form.Label>
                        <h6 style={{ display: 'flex', color: '#fff', justifyContent: 'center' }}>{MoneyFormatter(contrato?.valor_total ?? 0)}</h6>
                        <Form.Label style={{ color: '#e8550f', display: 'flex', justifyContent: 'center' }}>Valor Total</Form.Label>
                    </Card>
                    <Card style={{ display: 'flex', borderRadius: '10px', justifyContent: 'center', margin: '12px', padding: '6px', paddingTop: '12px', backgroundColor: '#282c34' }}>
                        <Form.Label style={{ color: '#e8550f', display: 'flex', justifyContent: 'center' }}>Qtd Parcelas Venc: {data?.qtd_total_vencidas}</Form.Label>
                        <h6 style={{ display: 'flex', color: '#fff', justifyContent: 'center' }}>{MoneyFormatter(data?.valor_total_vencidas ?? 0)}</h6>
                        <Form.Label style={{ color: '#e8550f', display: 'flex', justifyContent: 'center' }}>Valor Vencido</Form.Label>
                    </Card>
                    <Card style={{ display: 'flex', borderRadius: '10px', justifyContent: 'center', margin: '12px', padding: '6px', paddingTop: '12px', backgroundColor: '#282c34' }}>
                        <Form.Label style={{ color: '#e8550f', display: 'flex', justifyContent: 'center' }}>Qtd Parcelas Pagas: {data?.qtd_total_pagas}</Form.Label>
                        <h6 style={{ display: 'flex', color: '#fff', justifyContent: 'center' }}>{MoneyFormatter(data?.valor_total_pagas ?? 0)}</h6>
                        <Form.Label style={{ color: '#e8550f', display: 'flex', justifyContent: 'center' }}>Valor Pago</Form.Label>
                    </Card>
                    <Card style={{ display: 'flex', borderRadius: '10px', justifyContent: 'center', margin: '12px', padding: '6px', paddingTop: '12px', backgroundColor: '#282c34' }}>
                        <Form.Label style={{ color: '#e8550f', display: 'flex', justifyContent: 'center' }}>Qtd Parcelas a Vencer: {data?.qtd_total_abertas}</Form.Label>
                        <h6 style={{ display: 'flex', color: '#fff', justifyContent: 'center' }}>{MoneyFormatter(data?.valor_total_abertas ?? 0)}</h6>
                        <Form.Label style={{ color: '#e8550f', display: 'flex', justifyContent: 'center' }}>Total a Pagar</Form.Label>
                    </Card>
                </Row>
                <Row className="body-content-dash">
                    <Col md={6}>
                        <div
                            className="mb-3"
                            style={{
                                borderRadius: '10px',
                                backgroundColor: '#d9d9d9',
                                height: '425px',
                                width: '100%',
                            }}
                        >
                            <Card
                                className="mb-3"
                                style={{
                                    height: '100%',
                                    width: '100%',
                                    padding: '10px',
                                    borderRadius: '10px',
                                    backgroundColor: '#f7f7f7',
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}
                            >
                                <h5 style={{ textAlign: 'center' }}>Parcelas Inadimplentes</h5>
                                {data?.inadimplentes && (
                                    <DataTable
                                        value={parcelasInadimplentes || []}
                                        paginator
                                        rows={5}
                                        dataKey="id"
                                        selectionMode={rowClick ? null : 'checkbox'}
                                        selection={selectedInadimplentes}
                                        onSelectionChange={(e: any) => setSelectedInadimplentes(e.value || [])}
                                        paginatorPosition="bottom" 
                                        style={{
                                            borderTop: '1px solid #bdbdbd96',
                                            flexGrow: 1,
                                            overflowY: 'auto',
                                            maxHeight: '400px',
                                        }}
                                        emptyMessage={
                                            <span
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    fontStyle: 'italic',
                                                    color: '#888',
                                                }}
                                            >
                                                Nenhum item encontrado.
                                            </span>
                                        }
                                    >
                                        <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
                                        <Column
                                            style={{ textAlign: 'center', color: 'black' }}
                                            field="numero_documento"
                                            header="Doc"
                                        ></Column>
                                        <Column
                                            style={{ textAlign: 'center', color: 'black' }}
                                            field="data_vencimento"
                                            header="Venc"
                                            body={(rowData) =>
                                                new Date(rowData.data_vencimento).toLocaleDateString('pt-BR')
                                            }
                                        ></Column>
                                        <Column
                                            style={{ textAlign: 'center', color: 'black' }}
                                            field="valor_original"
                                            header="Valor"
                                            body={(rowData) => MoneyFormatter(rowData.valor_original)}
                                        ></Column>
                                        <Column
                                            style={{ textAlign: 'center', color: 'black' }}
                                            field="valor_pago"
                                            header="Valor-Atu"
                                            body={(rowData) => MoneyFormatter(rowData.valor_pago)}
                                        ></Column>
                                        <Column
                                            style={{ textAlign: 'center', color: 'black' }}
                                            field="justificativa"
                                            header="OBS"
                                        ></Column>
                                    </DataTable>
                                )}
                            </Card>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div
                            className="mb-3"
                            style={{
                                borderRadius: '10px',
                                backgroundColor: '#d9d9d9',
                                height: '425px',
                                width: '100%',
                            }}
                        >
                            <Card
                                className="mb-3"
                                style={{
                                    height: '100%',
                                    width: '100%',
                                    padding: '10px',
                                    borderRadius: '10px',
                                    backgroundColor: '#f7f7f7',
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}
                            >
                                <h5 style={{ textAlign: 'center' }}>Parcelas Abertas</h5>
                                {data?.abertas && (
                                    <DataTable
                                        value={parcelasAbertas || []}
                                        paginator
                                        rows={5}
                                        rowsPerPageOptions={[5, 10, 15, 20]}
                                        selectionMode={rowClick ? null : 'checkbox'}
                                        selection={selectedAbertas}
                                        onSelectionChange={(e: any) => setSelectedAbertas(e.value || [])}
                                        dataKey="id"
                                        style={{
                                            borderTop: '1px solid #bdbdbd96',
                                            flexGrow: 1,
                                            overflowY: 'auto',
                                            maxHeight: '400px',
                                        }}
                                        emptyMessage={
                                            <span
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    fontStyle: 'italic',
                                                    color: '#888',
                                                }}
                                            >
                                                Nenhum item encontrado.
                                            </span>
                                        }
                                    >
                                        <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
                                        <Column
                                            style={{ textAlign: 'center', color: 'black' }}
                                            field="numero_documento"
                                            header="Doc"
                                        ></Column>
                                        <Column
                                            style={{ textAlign: 'center', color: 'black' }}
                                            field="data_vencimento"
                                            header="Venc"
                                            body={(rowData) =>
                                                new Date(rowData.data_vencimento).toLocaleDateString('pt-BR')
                                            }
                                        ></Column>
                                        <Column
                                            style={{ textAlign: 'center', color: 'black' }}
                                            field="valor_original"
                                            header="Valor"
                                            body={(rowData) => MoneyFormatter(rowData.valor_original)}
                                        ></Column>
                                        <Column
                                            style={{ textAlign: 'center', color: 'black' }}
                                            field="valor_pago"
                                            header="Valor-Atu"
                                            body={(rowData) => MoneyFormatter(rowData.valor_pago)}
                                        ></Column>
                                        <Column
                                            style={{ textAlign: 'center', color: 'black' }}
                                            field="justificativa"
                                            header="OBS"
                                        ></Column>
                                    </DataTable>
                                )}
                            </Card>
                        </div>
                    </Col>
                </Row>
                <Row className="justify-content-end">
                    <Col md={2}>
                        <Card
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                borderRadius: '10px',
                                justifyContent: 'center',
                                backgroundColor: '#282c34',
                            }}
                        >
                            <Form.Label
                                style={{
                                    color: '#e8550f',
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                            >
                                Qtd de Parcelas
                            </Form.Label>
                            <h6
                                style={{
                                    display: 'flex',
                                    color: '#fff',
                                    justifyContent: 'center',
                                }}
                            >
                                {Qtd_abertas + Qtd_inadimplentes || 0}
                            </h6>
                        </Card>
                    </Col>
                    <Col md={2}>
                        <Card
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                borderRadius: '10px',
                                justifyContent: 'center',
                                backgroundColor: '#282c34',
                            }}
                        >
                            <Form.Label
                                style={{
                                    color: '#e8550f',
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                            >
                                Valor Parcela
                            </Form.Label>
                            <h6
                                style={{
                                    display: 'flex',
                                    color: '#fff',
                                    justifyContent: 'center',
                                }}
                            >
                                {valorTotalParcSelecionada
                                    ? valorTotalParcSelecionada.toLocaleString('pt-BR', {
                                        style: 'currency',
                                        currency: 'BRL',
                                    })
                                    : 'R$ 0,00'}
                            </h6>
                        </Card>
                    </Col>
                </Row>
                <div style={{ display: 'flex', justifyContent: 'end', marginTop:'10px' }}>
                    <Button onClick={() => { handleBack() }} className="button-back-form" variant="primary">
                        Voltar
                    </Button>
                    <Button className="button-next-form" variant="primary" onClick={() => {
                        if (!isFormValid()) {
                            return;
                        }
                        handleData(dataToSend);
                        handleNext();
                    }}>
                        Próximo
                    </Button>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default RenegociacaoModal;
