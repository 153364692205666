import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import { useAppContext } from '../../../contexts/AppContext';
import { url } from '../../../environments/environments-develop';
import { AlertService } from '../../../components/AlertService';
import ContaFinanceiraForm from './Formularios/ContaFinanceiraForm';

function EditContaFinanceira() {
    const { setUrl, setBody, setShowButtonSave, setTypeRequest, setItemsBreadsCrumbs } = useAppContext();
    const [formBasico, setFormBasico] = useState<any>({})

    useEffect(() => {
        try {
            setItemsBreadsCrumbs([
                {
                    label: 'Financeiro',
                    url: '/financeiro',
                },
                {
                    label: 'Conta Financeira',
                    url: '/financeiro/conta-financeira',
                },
                {
                    label: 'Editar'
                }
            ]);
            const storedData = localStorage.getItem('dadosEdicao');
            if (storedData) {
                setFormBasico((prevGrupoData: any) => {
                    const dadosAtualizados = {
                        ...prevGrupoData,
                        ...JSON.parse(storedData)
                    };
                    return dadosAtualizados;
                });
                setTypeRequest('PUT');
                setUrl(`${url}/api/v1/admin/financeiro/conta-financeira/${storedData && JSON.parse(storedData).id}`);
                setShowButtonSave(true);
            }
            return () => {
                localStorage.removeItem('dadosEdicao');
            };
        } catch (error) {
            AlertService.error("error", String(error))
        }
    }, []);
    useEffect(() => {
        setBody(formBasico)
    }, [formBasico]);

    const handleChangeInformacaoBancaria = (field: string, value: any) => {
        if (setFormBasico) {
            if (formBasico) {
                if (field.startsWith('informacao_bancaria.')) {
                    const informacaoField = field.replace('informacao_bancaria.', '');
                    setFormBasico((prevGrupoData: any) => {
                        return {
                            ...prevGrupoData,
                            informacao_bancaria: {
                                ...prevGrupoData.informacao_bancaria,
                                [informacaoField]: value,
                            },
                        };
                    });
                }
            }
        }
    }
    const handleChangePessoaForm = (field: string, value: any) => {

        if (setFormBasico) {
            let newForm: any = { ...formBasico };

            if (formBasico) {
                if (field.startsWith('pessoa.')) {
                    const pessoaField = field.replace('pessoa.', '');
                    if (newForm.informacao_bancaria && newForm.informacao_bancaria.pessoa) {
                        setFormBasico((prevGrupoData: any) => {
                            return {
                                ...prevGrupoData,
                                informacao_bancaria: {
                                    ...prevGrupoData.informacao_bancaria,
                                    pessoa: {
                                        ...prevGrupoData.informacao_bancaria.pessoa,
                                        [pessoaField]: value,
                                    },
                                },
                            };
                        });
                    }
                }
            }
        }
    }
    const handleChangeBairro = (field: string, value: string) => {
        if (setFormBasico) {
            let newForm: any = { ...formBasico };

            if (formBasico) {
                if (field.startsWith('bairro.')) {
                    const bairroField = field.replace('bairro.', '');
                    if (newForm?.informacao_bancaria) {
                        const bairro = newForm?.informacao_bancaria.pessoa?.enderecos?.data
                            ? newForm?.informacao_bancaria.pessoa?.enderecos?.data
                            : newForm?.informacao_bancaria.pessoa?.enderecos;
                        if (newForm && bairro && newForm.informacao_bancaria.pessoa) {
                            const updatedEnderecos = newForm?.informacao_bancaria.pessoa?.enderecos?.data
                                ? [...newForm.informacao_bancaria.pessoa.enderecos?.data]
                                : [...newForm.informacao_bancaria.pessoa.enderecos];
                            updatedEnderecos[0] = {
                                ...updatedEnderecos[0],
                                bairro: {
                                    [bairroField]: value,
                                }
                            };
                            setFormBasico((prevGrupoData: any) => {
                                return {
                                    ...prevGrupoData,
                                    informacao_bancaria: {
                                        ...prevGrupoData.informacao_bancaria,
                                        pessoa: {
                                            ...prevGrupoData.informacao_bancaria.pessoa,
                                            enderecos: updatedEnderecos,
                                        },
                                    }
                                }
                            });
                        }
                    }
                }
            }
        }
    }
    const handleChangeEnderecos = (field: string, value: string) => {
        if (setFormBasico) {
            let newForm: any = { ...formBasico };

            if (formBasico) {
                if (field.startsWith('enderecos.')) {
                    const enderecosField = field.replace('enderecos.', '');
                    if (newForm?.informacao_bancaria) {
                        const enderecos = newForm?.informacao_bancaria.pessoa?.enderecos?.data
                            ? newForm?.informacao_bancaria.pessoa?.enderecos?.data
                            : newForm?.informacao_bancaria.pessoa?.enderecos;
                        if (newForm && enderecos && newForm.informacao_bancaria.pessoa) {
                            const updatedEnderecos = newForm?.informacao_bancaria.pessoa?.enderecos?.data
                                ? [...newForm.informacao_bancaria.pessoa.enderecos?.data]
                                : [...newForm.informacao_bancaria.pessoa.enderecos];
                            updatedEnderecos[0] = {
                                ...updatedEnderecos[0],
                                [enderecosField]: value,
                            };
                            setFormBasico((prevGrupoData: any) => {
                                return {
                                    ...prevGrupoData,
                                    informacao_bancaria: {
                                        ...prevGrupoData.informacao_bancaria,
                                        pessoa: {
                                            ...prevGrupoData.informacao_bancaria.pessoa,
                                            enderecos: updatedEnderecos,
                                        },
                                    }
                                }
                            });
                        }
                    }
                }
            }
        }
    }
    const handleChangeTelefones = (field: string, value: string) => {
        if (setFormBasico) {
            let newForm: any = { ...formBasico };

            if (formBasico) {
                if (field.startsWith('telefones.')) {
                    const telefonesField = field.replace('telefones.', '');
                    if (newForm?.informacao_bancaria) {
                        const telefones = newForm?.informacao_bancaria.pessoa?.telefones?.data
                            ? newForm?.informacao_bancaria.pessoa?.telefones?.data
                            : newForm?.informacao_bancaria.pessoa?.telefones;
                        if (newForm && telefones && newForm.informacao_bancaria.pessoa) {
                            const updatedTelefones = newForm?.informacao_bancaria.pessoa?.telefones?.data
                                ? [...newForm.informacao_bancaria.pessoa.telefones?.data]
                                : [...newForm.informacao_bancaria.pessoa.telefones];
                            updatedTelefones[0] = {
                                ...updatedTelefones[0],
                                [telefonesField]: value,
                            };
                            setFormBasico((prevGrupoData: any) => {
                                return {
                                    ...prevGrupoData,
                                    informacao_bancaria: {
                                        ...prevGrupoData.informacao_bancaria,
                                        pessoa: {
                                            ...prevGrupoData.informacao_bancaria.pessoa,
                                            telefones: updatedTelefones,
                                        },
                                    }
                                }
                            });
                        }
                    }
                }
            }
        }
    }
    const handleChange = (field: string, value: string) => {
        if (setFormBasico) {
            if (formBasico) {
                setFormBasico((prevGrupoData: any) => {
                    return {
                        ...prevGrupoData,
                        [field]: value,
                    };
                });
            }
        }
    }

    useEffect(() => {
        setBody(formBasico)
        setShowButtonSave(true);
    }, [formBasico]);

    return (
        <>
            <div className="body-content">
                <div className="form-create-group">
                    <div className="form-create-group-header">
                        <h3>Editar Conta Financeira</h3>
                    </div>
                </div>
                <div className="form-group-create">
                    <Container>
                        <ContaFinanceiraForm
                            initialRightValues={[]}
                            formBasico={formBasico}
                            onInputChangeInformacaoBancaria={(field: string, value: any) => handleChangeInformacaoBancaria(`informacao_bancaria.${field}`, value)}
                            onInputChangePessoaForm={(field: string, value: any) => handleChangePessoaForm(`pessoa.${field}`, value)}
                            onInputChangeEnderecos={(field: string, value: any) => handleChangeEnderecos(`enderecos.${field}`, value)}
                            onInputChangeTelefones={(field: string, value: any) => handleChangeTelefones(`telefones.${field}`, value)}
                            onInputChangeBairro={(field: string, value: any) => handleChangeBairro(`bairro.${field}`, value)}
                            onInputChange={(field: string, value: any) => handleChange(`${field}`, value)}
                        />
                    </Container>
                </div>
            </div>
        </>
    );
}

export default EditContaFinanceira;
