import { Link } from "react-router-dom";
import { useAppContext } from "../contexts/AppContext";
import { useEffect, useState } from "react";
import TokenService from "../services/Auth/token.service";
import { showToast } from "./ToastComponent";

function ButtonCreateComponent() {
    const { url_button_create } = useAppContext();
    const [validateEmpreendimento, setValidateEmpreendimento] = useState(false);

    useEffect(() => {
        if (
            TokenService.getEmpreendimentoId() !== null ||
            url_button_create === '/empreendimentos/store' || 
            url_button_create === '/usuario/store' ||
            url_button_create === '/filial/store' ||
            url_button_create === '/usuario/store' ||
            url_button_create === '/grupo/store' ||
            url_button_create === '/licenca/store' 
        ) {
            setValidateEmpreendimento(true);
        } else {
            setValidateEmpreendimento(false);
        }
    }, [url_button_create]);

    const empreendimento = () => {
        if (!validateEmpreendimento) {
            showToast('error', 'Nenhum empreendimento Cadastrado! Você precisa cadastrar um empreendimento para prosseguir!')
        }
    }
    return (
        <>
            {url_button_create && validateEmpreendimento && (<li className="nav-item">
                <Link type="submit" to={url_button_create}>
                    <svg
                        className="button-create"
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                    >
                        <path
                            d="M16 7.30435H8.69565V0H7.30435V7.30435H0V8.69565H7.30435V16H8.69565V8.69565H16V7.30435Z"
                            fill="white"
                        />
                    </svg>
                </Link>
            </li>)}
            {url_button_create && !validateEmpreendimento && (<li className="nav-item">
                <a type="submit" onClick={(e) => empreendimento()}>
                    <svg
                        className="button-create"
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                    >
                        <path
                            d="M16 7.30435H8.69565V0H7.30435V7.30435H0V8.69565H7.30435V16H8.69565V8.69565H16V7.30435Z"
                            fill="white"
                        />
                    </svg>
                </a>
            </li>)}
        </>
    );
}

export default ButtonCreateComponent;