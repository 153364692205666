import { Col, Form, InputGroup, Row } from "react-bootstrap";
interface PropsData {
    Data: {
        nome?: string;
        descricao?: string;
        status?: boolean;
    };
    initialRightValues: any;
    onInputChange: (field: string, value: any) => void;
}
function FormBasico(props: PropsData) {
    const { nome, descricao, status, } = props.Data || {};
    const handleChange = (field: string, value: any) => {
        props.onInputChange(field, value);
    };

    return (
        <>
            <Form.Group className="forms" controlId="validationCustomUsername">
                <Row className="mb-3">
                    <Col md="3">
                        <Form.Label className="mb-0">Nome</Form.Label>
                        <InputGroup hasValidation>
                            <Form.Control
                                required
                                value={nome || ''}
                                onChange={(e) => handleChange("nome", e.target.value)}
                                onBlur={(e) => handleChange("nome", e.target.value)}
                            />
                            <Form.Control.Feedback type="invalid">
                                Este campo é Obrigatório.
                            </Form.Control.Feedback>
                        </InputGroup>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col md="3">
                        <Form.Label className="mb-0">Descrição</Form.Label>
                        <Form.Control value={descricao || ''} onChange={(e) => handleChange("descricao", e.target.value)} />
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col md="1">
                        <Form.Label className="mb-0">Status</Form.Label>
                        <Form.Check
                            checked={status || false}
                            onChange={(e) => handleChange("status", e.target.checked)}
                            type="switch"
                            id="custom-switch"
                        />
                    </Col>
                </Row>
            </Form.Group>
        </>
    );
}

export default FormBasico;
