import { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";

function UsuarioForm(props: any) {
    const [formData, setFormData] = useState<any>(props.formData || {});
    const handleChange = (field: string, value: string) => {
        props.onInputChange(field, value);
    };
    useEffect(() => {
        setFormData((prevGrupoData: any) => {
            return {
                ...prevGrupoData,
                ...props.formData
            };
        });
    }, [props.formData]);

    const validaPreenchimento = () => {
        if (props.tipoAcao == "editar") {
            return { invalidLogin: false };
        } else {
            formData.tentativas_login = 0;
            return { invalidLogin: true };
        }
    };

    return (
        <>
            <Form.Group className="usuario-form">
                <Row className="mb-3">
                    <Col>
                        <Form.Label>Nome de Usuário</Form.Label>
                        <Form.Control value={formData.username} onChange={(e) => handleChange('username', e.target.value)} />
                    </Col>
                    <Form.Group as={Col} controlId="formGridState">
                        <Form.Label>Status</Form.Label>
                        <Form.Select value={formData.status} onChange={(e) => handleChange('status', e.target.value)}>
                            <option>Selecione..</option>
                            <option value={1}>Ativado</option>
                            <option value={2}>Desativado</option>
                        </Form.Select>
                    </Form.Group>
                    <Col>
                        <Form.Label>Senha</Form.Label>
                        <Form.Control type="password" value={formData.password} onChange={(e) => handleChange("password", e.target.value)} />
                    </Col>
                    <Col>
                        <Form.Label>Confirmar senha</Form.Label>
                        <Form.Control type="password" value={formData.password_confirmation} onChange={(e) => handleChange("password_confirmation", e.target.value)} />
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col>
                        <Form.Label>Nome</Form.Label>
                        <Form.Control value={formData.nome} onChange={(e) => handleChange("nome", e.target.value)} />
                    </Col>
                    <Col>
                        <Form.Label>Email de Recuperação</Form.Label>
                        <Form.Control value={formData.email} onChange={(e) => handleChange("email", e.target.value)} required />
                        <Form.Control.Feedback type="invalid">
                            Please provide a valid state.
                        </Form.Control.Feedback>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col md='2'>
                        <Form.Label> Tentativas de Login</Form.Label>
                        <Form.Control
                            value={formData.tentativas_login}
                            onChange={(e) => handleChange("tentativas_login", e.target.value)}
                            disabled={validaPreenchimento().invalidLogin}
                        />
                    </Col>
                </Row>
            </Form.Group>
        </>
    );
}

export default UsuarioForm;
