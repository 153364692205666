import React, { useEffect, useState } from 'react';
import { Modal, Container, Row, Col, Form } from 'react-bootstrap';
import { Button } from 'primereact/button';
import 'react-datepicker/dist/react-datepicker.css';
import { AlertService } from '../../../../components/AlertService';
import api from '../../../../services/api';
import { url } from '../../../../environments/environments-develop';
import { AutoComplete } from 'primereact/autocomplete';
import TokenService from '../../../../services/Auth/token.service';
import { showToast } from '../../../../components/ToastComponent';
import { abrirPDF } from '../../../../utils/PdfUtils';

interface ModalEmitirMemorialSheetProps {
    showModal: boolean;
    onHide: () => void;
}
const ModalEmitirMemorialSheet: React.FC<ModalEmitirMemorialSheetProps> = ({
    showModal,
    onHide,
}) => {
    const [listFiltros, setListFiltros] = useState<any>({});
    const [subTipoImovel, setSubTipoImovel] = useState<any>([]);
    const [tipoImovel, setTipoImovel] = useState<any>([]);
    const [FilteredCorretores, setFilteredCorretores] = useState<any>(null);

    async function search(event: any) {
        let _FilteredPessoa: any;

        if (event.query) {
            try {
                _FilteredPessoa = await api.get(`${url}/api/v1/admin/vendas/corretores/buscarCorrretoresList/${event.query.toLowerCase()}`);
            } catch (error) {
                console.error('Erro ao buscar corretores:', error);
                _FilteredPessoa = [];
            }
        } else {
            _FilteredPessoa = [];
        }
        setFilteredCorretores(_FilteredPessoa ? _FilteredPessoa?.data?.data : []);
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [responseTiposImovel, responseSubtiposImovel] = await Promise.all([
                    api.get(`${url}/api/v1/admin/vendas/tipoimovel`),
                    api.get(`${url}/api/v1/admin/vendas/subtipoimovel`)
                ]);
                if (responseTiposImovel.status === 200) {
                    setTipoImovel(responseTiposImovel.data.data);
                }
                if (responseSubtiposImovel.status === 200) {
                    setSubTipoImovel(responseSubtiposImovel.data.data);
                }
            } catch (error) {
                AlertService.error('Erro!', String('Erro desconhecido'));
            }
        };
        fetchData();
    }, []);

    const emitirMemorial = async (): Promise<void> => {
        try {
            if (!listFiltros.tipo_relatorio) {
                showToast('warn', 'É obrigado selecionar um tipo de relatório!');
            }
            const consulta: any = {
                consulta: {
                    order: "id;desc",
                    filtro: {
                        "vendas.imoveis.identificador1": listFiltros?.identificador1,
                        "vendas.imoveis.identificador2": listFiltros?.identificador2,
                        "vendas.imoveis.corretor_id": listFiltros?.corretor_id,
                        "vendas.imoveis.logradouro": listFiltros?.logradouro,
                        "vendas.imoveis.observacao": listFiltros?.observacao,
                        "vendas.imoveis.construcao": listFiltros?.construcao,
                        "vendas.imoveis.tipo_imovel_id": listFiltros?.tipo_imovel_id,
                        "vendas.imoveis.sub_tipo_imovel_id": listFiltros?.sub_tipo_imovel_id,
                        "vendas.imoveis.status": listFiltros?.status,
                        tipo_relatorio: listFiltros.tipo_relatorio,
                        empreendimento_id: TokenService.getEmpreendimentoId()
                            ? TokenService.getEmpreendimentoId()?.id
                            : null,
                    }
                }
            };
            const queryParams: any = {};

            const camposEnviar = ['consulta'];

            camposEnviar.forEach((key: any) => {
                const value = consulta[key];
                if (value !== undefined && value !== null && value !== '') {
                    if (key === 'consulta') {
                        // Converte o objeto consulta em uma string JSON e codifica para URI
                        queryParams[key] = JSON.stringify(value);
                    } else {
                        queryParams[key] = value;
                    }
                }
            });

            const queryString = Object.entries(queryParams)
                .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(String(value))}`)
                .join('&');
            const response = await api.get(`${url}/api/v1/admin/vendas/imoveis-relatorio-memorial-descritivo?` + queryString, {
                responseType: 'arraybuffer',
            });
            if (response.status === 200) {
                var file = null;
                if (listFiltros.tipo_relatorio == 2) {
                    file = new Blob([response.data], {
                        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                    });
                } else {
                    file = new Blob([response.data], { type: 'application/pdf' });
                }
                const fileURL = URL.createObjectURL(file);
                window.open(fileURL, '_blank');
                // AlertService.sucess('Sucesso!', "Memorial gerado com sucesso!").then(() => {
                //     setListFiltros({});
                //     onHide();
                // });
            }

        } catch (error: any) {
            // AlertService.infomessage('Erro!', String(error.message)).then(() => {
            //     onHide();
            // });
        }
    };
    return (
        <Modal
            show={showModal}
            size='xl'
            onHide={() => onHide()}
            className='modal-filtro-avancado'
        >
            <Modal.Header closeButton>
                <Modal.Title>Gerar Memorial Descritivo</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container>
                    <Form.Group className="imovel-form">
                        <Row className='mb-3'>
                            <Col style={{ display: 'flex', flexDirection: 'column' }}>
                                <Form.Label>Quadra</Form.Label>
                                <Form.Control
                                    value={listFiltros?.identificador1 || ''}
                                    onChange={(e) => setListFiltros({ ...listFiltros, identificador1: e.target.value })}
                                />
                            </Col>
                            <Col style={{ display: 'flex', flexDirection: 'column' }}>
                                <Form.Label>Lote</Form.Label>
                                <Form.Control
                                    value={listFiltros?.identificador2 || ''}
                                    onChange={(e) => setListFiltros({ ...listFiltros, identificador2: e.target.value })}
                                />
                            </Col>
                            <Col style={{ display: 'flex', flexDirection: 'column' }}>
                                <Form.Label>Corretor</Form.Label>
                                <AutoComplete
                                    placeholder='Pesquisar nome/cpf/cnpj'
                                    field="nome_corretor"
                                    value={listFiltros?.nome_corretor ? listFiltros?.nome_corretor : listFiltros?.campo_busca}
                                    suggestions={FilteredCorretores}
                                    completeMethod={search}
                                    onChange={(e) =>
                                        setListFiltros({ ...listFiltros, campo_busca: e.value })}
                                    onSelect={(e) =>
                                        setListFiltros({ ...listFiltros, corretor_id: e.value })}
                                />
                            </Col>
                            <Col style={{ display: 'flex', flexDirection: 'column' }}>
                                <Form.Label>Logradouro</Form.Label>
                                <Form.Control
                                    value={listFiltros?.logradouro ? listFiltros?.logradouro : ''}
                                    onChange={(e) => setListFiltros({ ...listFiltros, logradouro: e.target.value })}
                                />
                            </Col>
                            <Col style={{ display: 'flex', flexDirection: 'column' }}>
                                <Form.Label>Observação</Form.Label>
                                <Form.Control
                                    value={listFiltros?.observacao ? listFiltros?.observacao : ''}
                                    onChange={(e) => setListFiltros({ ...listFiltros, observacao: e.target.value })}
                                />
                            </Col>
                        </Row>
                        <Row className='mb-3'>
                            <Col style={{ display: 'flex', flexDirection: 'column' }}>
                                <Form.Label>Tipo Imóvel</Form.Label>
                                <Form.Select value={listFiltros?.tipo_imovel_id || ''} onChange={(e) => setListFiltros({ ...listFiltros, tipo_imovel_id: e.target.value })}>
                                    <option>Selecione...</option>
                                    {tipoImovel.map((tipoimovel: any) => (
                                        <option value={tipoimovel.id}>{tipoimovel.nome}</option>
                                    ))}
                                </Form.Select>
                            </Col>
                            <Col style={{ display: 'flex', flexDirection: 'column' }}>
                                <Form.Label>SubTipo Imóvel</Form.Label>
                                <Form.Select value={listFiltros?.sub_tipo_imovel_id || ''} onChange={(e) => setListFiltros({ ...listFiltros, sub_tipo_imovel_id: e.target.value })}>
                                    <option>Selecione...</option>
                                    {subTipoImovel.map((subtipo: any) => (
                                        <option value={subtipo.id}>{subtipo.nome}</option>
                                    ))}
                                </Form.Select>
                            </Col>
                            <Col style={{ display: 'flex', flexDirection: 'column' }}>
                                <Form.Label>Status</Form.Label>
                                <Form.Select value={listFiltros?.status || ''} onChange={(e) => setListFiltros({ ...listFiltros, status: e.target.value })}>
                                    <option>Selecione...</option>
                                    <option value={"1"}>Disponível</option>
                                    <option value={"2"}>Reservado</option>
                                    <option value={"3"}>Reserva Fixa</option>
                                    <option value={"4"}>Negociado</option>
                                    <option value={"5"}>Vendido</option>
                                    <option value={"6"}>Reserva Técnica</option>
                                    <option value={"7"}>Reserva Diretoria</option>
                                </Form.Select>
                            </Col>
                            <Col style={{ display: 'flex', flexDirection: 'column' }}>
                                <Form.Label>Tipo de Relatório</Form.Label>
                                <Form.Select value={listFiltros.tipo_relatorio}
                                    isInvalid={!listFiltros.tipo_relatorio}
                                    onChange={(e) => setListFiltros({ ...listFiltros, tipo_relatorio: e.target.value })}>
                                    <option>Selecione...</option>
                                    <option value={1} key={1}>PDF</option>
                                    <option value={2} key={2}>EXCEL</option>
                                </Form.Select>
                            </Col>
                            <Col style={{ display: 'flex', flexDirection: 'column' }}>
                                <Form.Label>Construção</Form.Label>
                                <Form.Check
                                    style={{ display: "flex" }}
                                    checked={listFiltros?.construcao || false}
                                    onChange={(e) => setListFiltros({ ...listFiltros, construcao: e.target.checked })}
                                    type="switch"
                                    id="custom-switch"
                                />
                            </Col>
                        </Row>
                    </Form.Group>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    style={{ height: '30px', borderRadius: '20px', padding: '5px 10px', marginRight: '5px', fontSize: '14px' }}
                    type="button"
                    severity="success"
                    icon="pi pi-filter"
                    rounded
                    onClick={() => {
                        emitirMemorial();
                    }}
                >
                    <p style={{ marginLeft: '5px', marginTop: '13px' }}>Gerar Memorial</p>
                </Button>
                <Button
                    style={{ height: '30px', borderRadius: '20px', padding: '5px 10px', marginRight: '5px', fontSize: '14px' }}
                    type="button"
                    severity="danger"
                    icon="pi pi-delete-left"
                    rounded
                    onClick={() => {
                        setListFiltros({});
                    }}
                >
                    <p style={{ marginLeft: '5px', marginTop: '13px' }}>Limpar</p>
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ModalEmitirMemorialSheet;
