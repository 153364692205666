import iconMenuTresPonto from '../../../../../assets/images/icon-menu-tres-pontos.png';
import { Button } from 'primereact/button';
import React, { useEffect, useState } from 'react';
import { useSidebarContext } from '../../../../contexts/SidebarContext';
import { Col, Container, Form, Modal, NavLink, Row } from 'react-bootstrap';
import 'react-autocomplete-input/dist/bundle.css';
import iconFiltroAvancado from '../../../../../assets/images/icon-filtro-avancado.png';

interface ContextSearchProps {
    header?: string;
    filtrosData: {
        cpf_cnpj: string | null;
        razao_social: string | null;
        nome_fantasia: string | null;
        portfolio_atividade: string | null;
        obs: string | null;
    };
    onInputClearList: () => void;
    onInputChangeList: (field: string, value: any) => void;
    onInputChange: (field: string, value: any) => void;
}

function FiltroFornecedores(props: ContextSearchProps) {
    const { nome_fantasia, cpf_cnpj, razao_social, portfolio_atividade, obs } = props.filtrosData || {};
    const { isVisible, updateVisibility } = useSidebarContext();
    const [showModal, setShowModal] = useState(false);

    const toggleVisibility = () => {
        const newValue = !isVisible;
        updateVisibility(newValue);
    };

    const handleChange = (field: string, value: any) => {
        props.onInputChange(field, value);
    };

    const handleChangeList = (field: string, value: any) => {
        props.onInputChangeList(field, value);
    };

    const handleClearList = () => {
        props.onInputClearList();
    };

    const handleFilter = () => {
        if (nome_fantasia) {
            handleChangeList('nome_fantasia', nome_fantasia);
        }
        if (cpf_cnpj) {
            handleChangeList('cpf_cnpj', cpf_cnpj);
        }
        if (razao_social) {
            handleChangeList('razao_social', razao_social);
        }
        if (portfolio_atividade) {
            handleChangeList('portfolio_atividade', portfolio_atividade);
        }
        if (obs) {
            handleChangeList('obs', obs);
        }
    };

    return (
        <div className="contentSearch">
            <div className="contentSearch-header">
                <NavLink onClick={toggleVisibility}>
                    <img
                        className="menu-context"
                        src={iconMenuTresPonto}
                    />
                </NavLink>
                <Col className='text-center' style={{ fontSize: '20px', color: '#494949' }}>{props.header}</Col>
            </div>
            <div style={{ opacity: '34%' }} className="contentTraçoBodyHeader"></div>
            <div className="contentSearch-body">
                <div className='div-calendar'>
                    <Col md="6" style={{ marginRight: "8px" }}>
                        <Form.Label>Nome Fantasia</Form.Label>
                        <Form.Control
                            value={nome_fantasia ? nome_fantasia : ''}
                            onChange={(e) => handleChange("nome_fantasia", e.target.value)}
                        />
                    </Col>
                    <Col md="6" style={{ marginRight: "8px" }}>
                        <Form.Label>Cpf / Cnpj</Form.Label>
                        <Form.Control
                            value={cpf_cnpj ? cpf_cnpj : ''}
                            onChange={(e) => handleChange("cpf_cnpj", e.target.value)}
                            onBlur={(e) => handleChange("cpf_cnpj", e.target.value)}
                        />
                    </Col>
                </div>
                <div className='div-filtros-avancados' style={{ marginTop: "35px" }}>
                    <Button
                        style={{ height: '30px', borderRadius: '20px', padding: '5px 10px', marginRight: '5px', fontSize: '14px' }}
                        type="button"
                        rounded outlined severity="secondary"
                        onClick={() => { setShowModal(true) }}>
                        <img style={{ width: '25px', height: '35px' }} src={iconFiltroAvancado} />
                    </Button>
                    <Button
                        onClick={handleFilter}
                        style={{ height: '30px', borderRadius: '20px', padding: '5px 10px', marginRight: '5px', fontSize: '14px' }}
                        type="button"
                        severity="success"
                        icon="pi pi-filter"
                        rounded>
                        <p style={{ marginLeft: '5px', marginTop: '15px' }}>Pesquisar</p>
                    </Button>
                    <Button
                        onClick={handleClearList}
                        style={{ height: '30px', borderRadius: '20px', padding: '5px 10px', marginRight: '5px', fontSize: '14px' }}
                        type="button"
                        severity="danger"
                        icon="pi pi-delete-left"
                        rounded>
                        <p style={{ marginLeft: '5px', marginTop: '15px' }}>Limpar</p>
                    </Button>
                </div>
                <Modal
                    show={showModal}
                    size='lg'
                    onHide={() => setShowModal(false)}
                    className='modal-filtro-avancado'
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Filtros Avançados</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Container>
                            <Row>
                                <Col xs={6} md={4}>
                                    <Form.Label>Razão Social</Form.Label>
                                    <Form.Control
                                        value={razao_social ? razao_social : ''}
                                        onChange={(e) => handleChange("razao_social", e.target.value)}
                                    />
                                </Col>
                                <Col xs={6} md={4}>
                                    <Form.Label>Portfólio de Atividades</Form.Label>
                                    <Form.Control
                                        value={portfolio_atividade ? portfolio_atividade : ''}
                                        onChange={(e) => handleChange("portfolio_atividade", e.target.value)}
                                    />
                                </Col>
                                <Col xs={6} md={4}>
                                    <Form.Label>Observações</Form.Label>
                                    <Form.Control
                                        value={obs ? obs : ''}
                                        onChange={(e) => handleChange("obs", e.target.value)}
                                    />
                                </Col>
                            </Row>
                        </Container>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            onClick={handleFilter}

                            style={{ marginTop: '3px', width: '100px', height: '30px', borderRadius: '5px', padding: '5px 10px', marginRight: '5px', fontSize: '14px', marginLeft: '10px' }}
                            type="button"
                            severity="success"
                            icon="pi pi-filter"
                            rounded>
                            <p style={{ marginLeft: '5px', marginTop: '15px' }}>Pesquisar</p>
                        </Button>
                        <Button
                            onClick={handleClearList}
                            style={{ marginTop: '3px', width: '100px', height: '30px', borderRadius: '5px', padding: '5px 10px', marginRight: '5px ', fontSize: '14px' }}
                            type="button"
                            severity="danger"
                            icon="pi pi-delete-left"
                            rounded>
                            <p style={{ marginLeft: '5px', marginTop: '15px' }}>Limpar</p>
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </div >
    );
}

export default FiltroFornecedores;
