import React, { useEffect, useRef, useState } from 'react';
import { Toast } from 'primereact/toast';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import api from '../../../../services/api';
import { url } from '../../../../environments/environments-develop';
import { showToast } from '../../../../components/ToastComponent';
import { InputNumber } from 'primereact/inputnumber';

interface PropsData {
    data: any;
    showModal: boolean;
    onHide: () => void;
    onInputChange: (field: string, value: any) => void;
}

function ModalClonar(props: PropsData) {
    const [identificador, setIdentificador] = useState('');
    const teste = { ...props.data };
    const [formData, setFormData] = useState<any>({});

    useEffect(() => {
        setFormData((prevGrupoData: any) => {
            return {
                ...prevGrupoData,
                ['nome_servico']: teste.nome_servico || '',
                ['descricao_resumida']: teste.descricao_resumida || '',
                ['descricao_detalhada']: teste.descricao_detalhada || '',
                ['unidade']: teste.unidade || '',
                ['n_serie']: teste.n_serie || '',
                ['acessorios']: teste.acessorios || '',
                ['patrimonio']: teste.patrimonio || '',
                ['tipo']: teste.tipo || '',
                ['control_saldo']: teste.control_saldo || '',
                ['valor']: teste.valor || '',
                ['status']: teste.status || '',
                ['empreendimento_id']: teste.empreendimento_id || '',
                ['filial_id']: teste.filial_id || '',
                ['tipo_comissao']: teste.tipo_comissao || '',
                ['percentual_comissao']: teste.percentual_comissao || '',
                ['stk_atual']: teste.stk_atual || '',
                ['pontos']: teste.pontos || '',
                ['condicoes_proposta']: teste.condicoes_proposta || '',
                ['localizacao']: teste.localizacao || '',
                ['vl_aquisic']: teste.vl_aquisic || ''
            };
        });
    }, [props.data]);


    const clonar = async () => {
        if (!identificador) {
            showToast("error", "O campo identificador é obrigatório.");
            return;
        } if (!formData.nome_servico) {
            showToast("error", "O campo nome é obrigatório.");
            return;
        } if (!formData.unidade) {
            showToast("error", "O campo unidade é obrigatório.");
            return;
        } try {
            const response = await api.post(`${url}/api/v1/admin/cemiterio/produto`, {
                ...formData,
                identificador: identificador,
            });
            const toast = useRef<Toast>();
            if (response.data.error) {
                showToast("error", "Erro ao clonar produto.");
            }
        } catch (success) {
            showToast("success", "O produto foi clonado com sucesso.");
            window.location.reload();
        }
    }

    const handleChange = (field: string, value: any) => {
        if (field === 'identificador') {
            setIdentificador(value);
        }
        setFormData((prevGrupoData: any) => {
            return {
                ...prevGrupoData,
                [field]: value
            };
        });
    };

    const validaComissao = () => {
        const perc = formData.percentual_comissao;
        const tipo = formData.tipo_comissao;
        const invalidPerc = !perc && tipo;
        const invalidTipo = !tipo && perc;
        return { invalidPerc, invalidTipo };
    };

    const { invalidPerc, invalidTipo } = validaComissao();

    return (
        <div>
            <Modal
                show={props.showModal}
                onHide={props.onHide}
                size='xl'
            >
                <Modal.Header closeButton>
                    <Modal.Title>Clone | Produto: #{teste.id}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form className="forms">
                        <Row className="mb-3">
                            <Col md="2">
                                <Form.Label className="mb-0">Identificador</Form.Label>
                                <Form.Control
                                    value={identificador}
                                    isInvalid={!identificador}

                                    onChange={(e) => handleChange("identificador", e.target.value)}
                                />
                            </Col>
                            <Col md="4">
                                <Form.Label className="mb-0">Nome / Descrição Serviço</Form.Label>
                                <Form.Control
                                    value={formData.nome_servico || ''}
                                    onChange={(e) => handleChange("nome_servico", e.target.value)}
                                />
                            </Col>
                            <Col md="2">
                                <Form.Label className="mb-0">Unidade</Form.Label>
                                <Form.Select
                                    value={formData.unidade || 1}
                                    onChange={(e) => handleChange("unidade", e.target.value)}
                                >
                                    <option value={1}>Unidade</option>
                                    <option value={2}>Peça</option>
                                    <option value={3}>Hora</option>
                                </Form.Select>
                            </Col>
                            <Col md="2">
                                <Form.Label className="mb-0">Tipo</Form.Label>
                                <Form.Select
                                    value={formData.tipo}
                                    disabled
                                >
                                    <option value="1">Serviço</option>
                                    <option value="2">Mercadoria</option>
                                    <option value="3">Patrimônio</option>
                                </Form.Select>
                            </Col>
                            <Col md="2">
                                <Form.Label className="mb-0">Status</Form.Label>
                                <Form.Select
                                    value={formData.status}
                                    disabled
                                >
                                    <option value="1">Ativo</option>
                                    <option value="2">Inativo</option>
                                </Form.Select>
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col md="2">
                                <Form.Label className="mb-0">Nº Série</Form.Label>
                                <Form.Control
                                    value={formData.n_serie || ''}
                                    onChange={(e) => handleChange("n_serie", e.target.value)}
                                />
                            </Col>
                            <Col md="2">
                                <Form.Label className="mb-0">Patrimônio</Form.Label>
                                <Form.Control
                                    value={formData.patrimonio || ''}
                                    onChange={(e) => handleChange("patrimonio", e.target.value)}
                                />
                            </Col>
                            <Col md="2">
                                <Form.Label className="mb-0">Estoque Atual</Form.Label>
                                <Form.Control
                                    value={formData.stk_atual || ''}
                                    onChange={(e) => handleChange("stk_atual", e.target.value)}
                                />
                            </Col>
                            <Col md="3">
                                <Form.Label className="mb-0">Localização</Form.Label>
                                <Form.Control
                                    value={formData.localizacao || ''}
                                    onChange={(e) => handleChange("localizacao", e.target.value)}
                                />
                            </Col>
                            <Col md="1">
                                <Form.Label className="mb-0">Pontos</Form.Label>
                                <Form.Control
                                    value={formData.pontos || ''}
                                    onChange={(e) => handleChange("pontos", e.target.value)}
                                />
                            </Col>
                            <Col md="2">
                                <Form.Label className="mb-0">Control Saldo</Form.Label>
                                <Form.Check
                                    type="switch"
                                    id="control_saldo"
                                    checked={formData.control_saldo || false}
                                    onChange={(e) => handleChange("control_saldo", e.target.checked)}
                                />
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col md="3" style={{ display: 'grid' }}>
                                <Form.Label className="mb-0">Valor</Form.Label>
                                <InputNumber
                                    value={formData.valor}
                                    mode="currency"
                                    currency="BRL"
                                    locale="pt-BR"
                                    onChange={(e) => handleChange("valor", e.value)}
                                />
                            </Col>
                            <Col md="2" style={{ display: 'grid' }}>
                                <Form.Label className="mb-0">Valor Aquisição</Form.Label>
                                <InputNumber
                                    value={formData.vl_aquisic}
                                    mode="currency"
                                    currency="BRL"
                                    locale="pt-BR"
                                    onChange={(e) => handleChange("vl_aquisic", e.value)}
                                />
                            </Col>
                            <Col md="2" style={{ display: 'grid' }}>
                                <Form.Label className="mb-0">Percentual Comissão</Form.Label>
                                <Form.Control
                                    value={formData.percentual_comissao || ''}
                                    isInvalid={invalidPerc}
                                    onChange={(e) => handleChange("percentual_comissao", e.target.value)}
                                />
                            </Col>
                            <Col md="2" style={{ display: 'grid' }}>
                                <Form.Label className="mb-0">Tipo Comissão</Form.Label>
                                <Form.Select
                                    value={formData.tipo_comissao || ''}
                                    isInvalid={invalidTipo}
                                    onChange={(e) => handleChange("tipo_comissao", e.target.value)}
                                >
                                    <option value="">Selecione...</option>
                                    <option value={1}>Percentual</option>
                                    <option value={2}>Valor</option>
                                </Form.Select>
                            </Col>
                            <Col md="3" style={{ display: 'grid' }}>
                                <Form.Label className="mb-0">Acessórios</Form.Label>
                                <Form.Control
                                    value={formData.acessorios || ''}
                                    onChange={(e) => handleChange("acessorios", e.target.value)}
                                />
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col>
                                <Form.Label className="mb-0">Descrição Resumida</Form.Label>
                                <Form.Control
                                    value={formData.descricao_resumida || ''}
                                    onChange={(e) => handleChange("descricao_resumida", e.target.value)}
                                />
                            </Col>
                            <Col>
                                <Form.Label className="mb-0">Condições Proposta</Form.Label>
                                <Form.Control
                                    value={formData.condicoes_proposta || ''}
                                    onChange={(e) => handleChange("condicoes_proposta", e.target.value)}
                                />
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col md="12">
                                <Form.Label className="mb-0">Descrição Detalhada</Form.Label>
                                <Form.Control
                                    value={formData.descricao_detalhada || ''}
                                    onChange={(e) => handleChange("descricao_detalhada", e.target.value)}
                                />
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="success"
                        onClick={clonar}
                        className='button-end-form'
                        style={{ marginRight: '11px' }}
                    >
                        Confirmar
                    </Button>
                    <Button variant="danger" onClick={props.onHide}>
                        Cancelar
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default ModalClonar;