import React, { useEffect, useState } from "react";
import {  Col, Form, Row } from "react-bootstrap";

function TelefoneFormContaFInanceira(props: any) {
    const { ddd, numero, tipo_telefone, observacao } = props;
   
    const handleChange = (field: string, value: any) => {
        if (field === 'ddd') {
            if (/^\d*$/.test(value) && value.length <= 3) {
                props.onInputChangeTelefones(field, value);
            }
        } else if (field === 'nome_contato') {
            if (!value || value.length <= 60) {
                props.onInputChangeTelefones(field, value);
            }
        } else if (field === 'numero') {
            if (/^\d*$/.test(value) && value.length <= 11) {
                props.onInputChangeTelefones(field, value);
            }
        } else if (field === 'observacao') {
            if (!value || value.length <= 60) {
                props.onInputChangeTelefones(field, value);
            }
        }
        else {
            props.onInputChangeTelefones(field, value);
        }
    };
    
    return (
        <Form.Group className="usuario-form">
                    <Row className="mb-3" style={{ marginLeft: '12px', padding: '12px', borderBottom: '1px solid #e6e5e5', borderTop: '1px solid #e6e5e5' }}>
                        <h5 className="sub-label-form-create-imovel" style={{ color: 'rgba(6, 6, 6, 0.47)', display: 'flex', justifyContent: 'center' }}>Telefone</h5>
                    </Row>
                    <Row className="mb-3">
                        <Col md="2">
                            <Form.Label>DDD</Form.Label>
                            <Form.Control required value={ddd} onChange={(e) => handleChange('ddd', e.target.value)} />
                        </Col>
                        <Col md="2">
                            <Form.Label>Numero</Form.Label>
                            <Form.Control required value={numero} onChange={(e) => handleChange('numero', e.target.value)} />
                        </Col>
                        <Col md="3">
                            <Form.Label>Tipo de Telefone</Form.Label>
                            <Form.Select
                                required
                                value={tipo_telefone}
                                onChange={(e) => handleChange('tipo_telefone', e.target.value)}
                            >
                                <option value={0}>Selecione...</option>
                                <option value={1}>Pessoal</option>
                                <option value={2}>Comercial</option>
                                <option value={3}>Contato</option>
                                <option value={4}>Recado</option>
                            </Form.Select>
                        </Col>
                        <Col>
                            <Form.Label>Observação</Form.Label>
                            <Form.Control
                                required
                                value={observacao}
                                onChange={(e) => handleChange('observacao', e.target.value)}
                            />
                        </Col>
                    </Row>
                    
                </Form.Group>
    );
}

export default TelefoneFormContaFInanceira;
