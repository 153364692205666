import React, { useState, useEffect } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import SecaoIntermediacao from '../../../Vendas/NovaVenda/Formularios/SecaoIntermediacao';

function Venda(props: any) {
    const [formData, setFormData] = useState<any>({});

    useEffect(() => {
        setFormData(props.formData);
    }, [props.formData]);

    const handleFormChange = (field: string, value: any) => {
        props.onInputChange(field, value);
    }

    return (
        <>
            <Row className="group-form">
                <Col>
                    <Form.Label>Em qual Coluna está o id da Venda?</Form.Label>
                    <Form.Control
                        value={formData.id_externo || ''}
                        onChange={(e) => handleFormChange('id_externo', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está a entrada da Venda?</Form.Label>
                    <Form.Control
                        value={formData.entrada || ''}
                        onChange={(e) => handleFormChange('entrada', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está a intermediação da Venda?</Form.Label>
                    <Form.Control
                        value={formData.imo_intermed || ''}
                        onChange={(e) => handleFormChange('imo_intermed', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está a parte do corretor?</Form.Label>
                    <Form.Control
                        value={formData.part_corret || ''}
                        onChange={(e) => handleFormChange('part_corret', e.target.value)} />
                </Col>
            </Row>
            <Row className="group-form">
                <Col>
                    <Form.Label>Em qual Coluna está o valor do desconto da Venda?</Form.Label>
                    <Form.Control
                        value={formData.valor_desconto || ''}
                        onChange={(e) => handleFormChange('valor_desconto', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está o valor de desconto da intermediação?</Form.Label>
                    <Form.Control
                        value={formData.intermed_valor_desc || ''}
                        onChange={(e) => handleFormChange('intermed_valor_desc', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está a justificativa do desconto da Venda?</Form.Label>
                    <Form.Control
                        value={formData.justificativa_desc || ''}
                        onChange={(e) => handleFormChange('justificativa_desc', e.target.value)} />
                </Col>
            </Row>
            <Row className="group-form">

                <Col>
                    <Form.Label>Em qual Coluna está a anotação da Venda?</Form.Label>
                    <Form.Control
                        value={formData.anotacoes || ''}
                        onChange={(e) => handleFormChange('anotacoes', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está o id do corretor da venda?</Form.Label>
                    <Form.Control
                        value={formData.corretor_id || ''}
                        onChange={(e) => handleFormChange('corretor_id', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está o id do lote da venda?</Form.Label>
                    <Form.Control
                        value={formData.lote_id || ''}
                        onChange={(e) => handleFormChange('lote_id', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está a data do acerto?</Form.Label>
                    <Form.Control
                        value={formData.data_acerto || ''}
                        onChange={(e) => handleFormChange('data_acerto', e.target.value)} />
                </Col>
            </Row>
            <Row className="group-form">
                <Col>
                    <Form.Label>Em qual Coluna está o id empreendimento?</Form.Label>
                    <Form.Control
                        value={formData.empreendimento_id || ''}
                        onChange={(e) => handleFormChange('empreendimento_id', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está o valor das parcelas?</Form.Label>
                    <Form.Control
                        value={formData.valor_parcelas || ''}
                        onChange={(e) => handleFormChange('valor_parcelas', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está o valor acertado?</Form.Label>
                    <Form.Control
                        value={formData.valor_acertado || ''}
                        onChange={(e) => handleFormChange('valor_acertado', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está o id do comprador 1?</Form.Label>
                    <Form.Control
                        value={formData.comprador1_id || ''}
                        onChange={(e) => handleFormChange('comprador1_id', e.target.value)} />
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Label>Em qual Coluna está o id comprador 2?</Form.Label>
                    <Form.Control
                        value={formData.comprador2_id || ''}
                        onChange={(e) => handleFormChange('comprador2_id', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está o id comprador 3?</Form.Label>
                    <Form.Control
                        value={formData.comprador3_id || ''}
                        onChange={(e) => handleFormChange('comprador3_id', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está o numero de parcelas totais da venda?</Form.Label>
                    <Form.Control
                        value={formData.qtd_parcelas || ''}
                        onChange={(e) => handleFormChange('qtd_parcelas', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está o valor total?</Form.Label>
                    <Form.Control
                        value={formData.valor_total || ''}
                        onChange={(e) => handleFormChange('valor_total', e.target.value)} />
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Label>Em qual Coluna está a parte da imobiliária?</Form.Label>
                    <Form.Control
                        value={formData.part_imob || ''}
                        onChange={(e) => handleFormChange('part_imob', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está a data do proximo reajuste?</Form.Label>
                    <Form.Control
                        value={formData.prox_reajuste || ''}
                        onChange={(e) => handleFormChange('prox_reajuste', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está o valor do proximo reajuste?</Form.Label>
                    <Form.Control
                        value={formData.valor_prox_reajuste || ''}
                        onChange={(e) => handleFormChange('valor_prox_reajuste', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está o valor em dinheiro?</Form.Label>
                    <Form.Control
                        value={formData.valor_dinheiro || ''}
                        onChange={(e) => handleFormChange('valor_dinheiro', e.target.value)} />
                </Col>
            </Row>
        </>
    );
}

export default Venda;
