import { Col, Form } from "react-bootstrap";
import CompradorForm from "../../../Formularios/Compradores";
import SecaoEntrada from "../../../Formularios/SecaoEntrada";
import SecaoParcelamento from "../../../Formularios/SecaoParcelamentoCemiterio";
import ProdutosServicos from "../../../Formularios/ProdutosServicos";
import SecaoJazigo from "../../../Formularios/SecaoJazigo";


interface ProgressBarFormProps {
    currentPart: number;
    formBasico: any;
    onInputChange: (field: string, value: string) => void;
    onCompradoresInputChange: (index: number, field: string, value: any) => void;
    onAddCompradores: any;
    onRemoveCompradores: any;
}

const VendaForm: React.FC<ProgressBarFormProps> = ({
    currentPart,
    formBasico,
    onInputChange,
    onCompradoresInputChange,
    onAddCompradores,
    onRemoveCompradores
}) => {

    return (
        <Form.Group className="venda-jazigo" style={{ width: '-webkit-fill-available', height: '-webkit-fill-available' }}>
            <Col md="12">
                {currentPart === 0 && (
                    <SecaoJazigo
                        secaoImovelData={formBasico}
                        onInputChange={(field: string, value: string) => onInputChange(`${field}`, value)} />
                )}
                {currentPart === 1 && (
                    <CompradorForm
                        compradorData={formBasico?.cliente_compradores || []}
                        onInputChange={(index: number, field: string, value: any) => onCompradoresInputChange(index, field, value)}
                        onAddCompradores={onAddCompradores}
                        onRemoveCompradores={onRemoveCompradores}
                    />
                )}
                {currentPart === 2 && (
                    <ProdutosServicos
                        produtoData={formBasico}
                        onInputChange={(field: string, value: string) => onInputChange(`${field}`, value)} />
                )}
                {currentPart === 3 && (
                    <SecaoEntrada
                        secaoEntradaData={formBasico}
                        onInputChange={(field: string, value: any) => onInputChange(`${field}`, value)} />
                )}
                {currentPart === 4 && (
                    <SecaoParcelamento
                        secaoParcelamentoData={formBasico}
                        onInputChange={(field: string, value: string) => onInputChange(`${field}`, value)} />
                )}
            </Col>
        </Form.Group>
    );
}

export default VendaForm;
