import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import { useAppContext } from '../../../contexts/AppContext';
import { url } from '../../../environments/environments-develop';
import GroupForm from './Formularios/groupPermission';

interface PropsGrupoData {
    nome?: string;
    descricao?: string;
    padrao?: boolean;
}

function CreateGroup() {
    const { setUrl, setBody, setShowButtonSave, setItemsBreadsCrumbs } = useAppContext();
    const [grupoData, setGrupoData] = useState<PropsGrupoData>({});

    useEffect(() => {
        setItemsBreadsCrumbs([
            {
                label: 'Configurações',
                url: '/configuracoes',
            },
            {
                label: 'Grupo de Permissões',
                url: '/grupo',
            },
            {
                label: 'Criar'
            }
        ]);
        setUrl(`${url}/api/v1/admin/core/grupo`);
        setShowButtonSave(true);
    }, []);


    const handleChange = (field: string, value: string) => {
        setGrupoData((prevGrupoData) => {
            return {
                ...prevGrupoData,
                [field]: value
            };
        });
        setBody(grupoData)
    }
    return (
        <>
            <div className="body-content">
                <div className="form-create-group">
                    <div className="form-create-group-header">
                        <h3>Cadastrar Grupo de permissões</h3>
                    </div>
                </div>
                <div className="form-group-create">
                    <Container>
                        <GroupForm
                            initialRightValues={[]}
                            grupoData={grupoData}
                            onInputChange={(field: string, value: string) => handleChange(`${field}`, value)}
                        />
                    </Container>
                </div>
            </div>
        </>
    );
}

export default CreateGroup;
