import { AlertService } from "../../../../components/AlertService";
import { url } from "../../../../environments/environments-develop";
import api from "../../../../services/api";
import TokenService from "../../../../services/Auth/token.service";
import CorretorSevice from "../../../Pessoas/Services/CorretorSevice";
import ImovelService from "../../Services/ImovelService";

interface Consulta {
  order?: string;
  filtro?: any;
}
const consulta: Consulta = {
  order: "id;asc",
};

export const loadData = (
  listFiltros: any,
  itensPorPagina: any,
  currentPage: any,
  setLoading: (value: any) => void,
  setNodes: (value: any) => void,
  updateTitles: (titles: string[]) => void,
  updatePagination: (totalPages: number) => void,
  setShowModalFiltro: (value: any) => void,
  sortOptions: { field: string; order: number } | null = null
) => {
  setLoading(true);
  const form = {
    "vendas.imoveis.identificador1": listFiltros?.identificador1,
    "vendas.imoveis.identificador2": listFiltros?.identificador2,
    "vendas.imoveis.corretor_id": listFiltros?.corretor_id,
    "vendas.imoveis.logradouro": listFiltros?.logradouro,
    "vendas.imoveis.observacao": listFiltros?.observacao,
    "vendas.imoveis.construcao": listFiltros?.construcao,
    "vendas.imoveis.tipo_imovel_id": listFiltros?.tipo_imovel_id,
    "vendas.imoveis.sub_tipo_imovel_id": listFiltros?.sub_tipo_imovel_id,
    "vendas.imoveis.status": listFiltros?.status,
    empreendimento_id: TokenService.getEmpreendimentoId()
      ? TokenService.getEmpreendimentoId()?.id
      : null,
  };

  const filtro = Object.fromEntries(
    Object.entries(form).filter(
      ([_, value]) => value !== null && value !== "" && value !== undefined
    )
  );

  if (consulta) {
    consulta.filtro = filtro;
    if (sortOptions) {
      const orderDirection = sortOptions.order === 1 ? "asc" : "desc";
      consulta.order = `${sortOptions.field};${orderDirection}`;
    }
  }

  ImovelList(
    consulta,
    itensPorPagina,
    currentPage,
    setLoading,
    setNodes,
    updateTitles,
    updatePagination,
    setShowModalFiltro
  );
};

export const ImovelList = (
  filtros: any,
  itensPorPagina: any,
  currentPage: any,
  setLoading: (value: any) => void,
  setNodes: (value: any) => void,
  updateTitles: (titles: string[]) => void,
  updatePagination: (totalPages: number) => void,
  setShowModalFiltro: any | null
) => {
  ImovelService.list(
    {
      include: "anexo,cliente,plano_conta,conta_financeiro,centro_custo",
      totalitems: itensPorPagina ?? 8,
      consulta: JSON.stringify(filtros),
      page: currentPage + 1,
    },
    null,
    updateTitles,
    updatePagination
  ).subscribe(
    (response) => {
      setNodes(response);
      setShowModalFiltro(false);
      setLoading(false);
    },
    (error) => {
      let mensagens: string[] = [];
      mensagens.push(error?.response?.data?.error?.message);
      AlertService.error("Erro!", String(mensagens));
    }
  );
};

export const getStatusColorCard = (status: any) => {
  switch (status) {
    case "Disponível":
      return "#289b1e"; // Background color for Disponível
    case "Reservado":
      return "#f5da41"; // Background color for Reservado
    case "Reserva Fixa":
      return "#f5da41"; // Background color for Reserva Fixa
    case "Negociado":
      return "#a8a8a8"; // Background color for Negociado
    case "vendido":
      return "#ff3232"; // Background color for Vendido
    case "Reserva Técnica":
      return "#fff"; // Background color for Reserva Técnica
    case "Reserva Diretoria":
      return "#fff"; // Background color for Reserva Diretoria
    default:
      return "#fff";
  }
};

export const excluirImovel = (id: any) => {
  AlertService.confirm({
    title: "Confirmar Exclusão!",
    text: "Deseja realmente excluir este imóvel?",
    cancelButtonText: "Não",
    confirmButtonText: "Sim",
  }).then((e: any) => {
    if (e?.isConfirmed) { 
      ImovelService.excluir(id).subscribe(
        (response) => {
          AlertService.sucess("Sucesso!", "Excluído com sucesso!").then(() => {
            window.location.reload();
          });
        },
        (error) => {
          let mensagens: string[] = [];
          Object.keys(error?.response?.data?.error?.message).forEach((key) => {
            mensagens.push("\n" + error?.response?.data?.error?.message[key][0]);
          });
          AlertService.error("Erro!", String(mensagens));
        }
      );
    }
  });
};

export const setSessionEditData = async (value: any, url_redirect_edit: any) => {
  ImovelService.get(value,{
    include:
    "config_venda,config_carteira,pessoa.telefones,pessoa.enderecos,grupo_permissoes",
  }).subscribe(
    (response) => {
      localStorage.setItem("dadosEdicao", JSON.stringify(response));
      window.location.href = url_redirect_edit;
    },
    (error) => {
      let mensagens: string[] = [];
      mensagens.push(error?.response?.data?.error?.message);
      AlertService.error("Erro!", String(mensagens));
    }
  );
};

export const searchCorretor = (
  event: any,
  currentPage: any,
  setFilteredClient: (value: any) => void,
) => {
  const query = event.query;
  const filtro = {
    nome: query,
  };

  if (consulta) {
    consulta.filtro = filtro;
  }

  CorretorSevice.list({
    empreendimento_id: TokenService.getEmpreendimentoId().id,
  }).subscribe(
    (response) => {
      setFilteredClient(response);
    },
    (error) => {
      let mensagens: string[] = [];
      mensagens.push(error?.response?.data?.error?.message);
      AlertService.error("Erro!", String(mensagens));
    }
  );
}