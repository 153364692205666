import { Col, Form, InputGroup, Row } from "react-bootstrap";
import { useState, useEffect } from "react";
import api from "../../../../services/api";
import { url } from "../../../../environments/environments-develop";
import { AlertService } from "../../../../components/AlertService";
interface PropsData {
    Data: {
        tipo_indice_id?: number;
        mes_referencia?: string;
        valor?: number;
    };
    initialRightValues: any;
    onInputChange: (field: string, value: any) => void;
};
function IndiceForm(props: PropsData) {
    const { tipo_indice_id, mes_referencia, valor } = props.Data || {};
    const [listTipoIndice, setListTipoIndice] = useState<any>([]);
    const handleChange = (field: string, value: any) => {
        props.onInputChange(field, value);
    };
    async function InitTiposIndice() {
        try {
            const response = await api.get(`${url}/api/v1/admin/financeiro/tipo-indice/`);
            setListTipoIndice(response.data.data);
        } catch (error) {
            AlertService.error('Error', 'Ocorreu um erro ao buscar a de clientes. Erro:');
        }
    };
    useEffect(() => {
        InitTiposIndice();
    }, []);

    return (
        <>
            <Form.Group className="indice-form" style={{ marginTop: "10px", marginLeft: "22px" }}>
                <Form.Group controlId="validationCustomUsername" className="indice-form">
                    <Col md="2">
                        <Row className="mb-3">
                            <Form.Label className="mb-0">Tipo de Indice</Form.Label>
                            <InputGroup hasValidation >
                                <Form.Select
                                    required
                                    value={tipo_indice_id}
                                    onChange={(e) => handleChange("tipo_indice_id", e.target.value)}
                                >
                                    <option>Selecione...</option>
                                    {listTipoIndice.map((item: any) => (
                                        <option key={item.id} value={item.id}>{item.nome}</option>
                                    ))}
                                </Form.Select>
                                <Form.Control.Feedback type="invalid">
                                    Este campo é obrigatório.
                                </Form.Control.Feedback>
                            </InputGroup>
                        </Row>
                        <Row className="mb-3">
                            <Form.Label className="mb-0">Mês de Referencia</Form.Label>
                            <InputGroup hasValidation>
                                <Form.Control
                                    required
                                    value={mes_referencia}
                                    onChange={(e) => handleChange("mes_referencia", e.target.value)}
                                    onBlur={(e) => handleChange("mes_referencia", e.target.value)}
                                    placeholder="Exemplo: 08/2024"
                                />
                            </InputGroup>
                        </Row>
                        <Row className="mb-3">
                            <Form.Label className="mb-0">Valor</Form.Label>
                            <InputGroup hasValidation >
                                <Form.Control
                                    value={valor}
                                    onChange={(e) => handleChange("valor", e.target.value)}
                                    onBlur={(e) => handleChange("valor", e.target.value)}
                                    placeholder="Preencha..."
                                    required
                                />
                                <Form.Control.Feedback type="invalid">
                                    Este campo é obrigatório.
                                </Form.Control.Feedback>
                            </InputGroup>
                        </Row>
                    </Col>
                </Form.Group >
            </Form.Group >
        </>
    );
};

export default IndiceForm;
