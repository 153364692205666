import iconMenuTresPonto from '../../../../../assets/images/icon-menu-tres-pontos.png';
import { Button } from 'primereact/button';
import { useState } from 'react';
import { useSidebarContext } from '../../../../contexts/SidebarContext';
import { Col, Form, NavLink } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import CalendarIcon from './../../../../../assets/images/icon-calendar.png';
import pt from 'date-fns/locale/pt';
import 'react-autocomplete-input/dist/bundle.css';

interface ContextSearchProps {
  header?: string;
  filtrosData: {
    nome?: string | null;
    dataInicio?: string;
    dataFim?: string;
  };
  onInputClearList: () => void;
  onInputChangeList: (field: string, value: any) => void;
  onInputChange: (field: string, value: any) => void;
}

function ContentSearchPagarReceber(props: ContextSearchProps) {
  const { nome } = props.filtrosData || {};
  const { isVisible, updateVisibility } = useSidebarContext();
  const [dataInicio, setDataInicio] = useState<string | null>(
    props.filtrosData && typeof props.filtrosData.dataInicio === 'string'
      ? props.filtrosData.dataInicio
      : null
  );

  const [dataFim, setDataFim] = useState<string | null>(
    props.filtrosData && typeof props.filtrosData.dataFim === 'string'
      ? props.filtrosData.dataFim
      : null
  );

  const toggleVisibility = () => {
    const newValue = !isVisible;
    updateVisibility(newValue);
  };

  const handleChange = (field: string, value: any) => {
    props.onInputChange(field, value);
  };

  const handleChangeList = (field: string, value: any) => {
    props.onInputChangeList(field, value);
  };

  const handleClearList = () => {
    setDataInicio(null);
    setDataFim(null);
    props.onInputClearList();
  };

  const handleFilter = () => {
    if (nome) {
      handleChangeList('nome', nome);
    }
    if (dataInicio) {
      handleChangeList('dataInicio', dataInicio);
    }
    if (dataFim) {
      handleChangeList('dataFim', dataFim);
    }
  };

  return (
    <div className="contentSearch">
      <div className="contentSearch-header">
        <NavLink onClick={toggleVisibility}>
          <img
            className="menu-context"
            src={iconMenuTresPonto}
          />
        </NavLink>
        <Col className='text-center' style={{ fontSize: '20px', color: '#494949' }}>{props.header}</Col>
      </div>
      <div style={{ opacity: '34%' }} className="contentTraçoBodyHeader"></div>
      <div className="contentSearch-body">
        <div className='filter-campos'>
          <div className='div-calendar' style={{ height: "37px", marginRight: '12px' }}>
            <Form.Control
              value={nome ? nome : ''}
              placeholder='Pesquisar por Nome'
              onChange={(e) => handleChange("nome", e.target.value)}
            />
          </div>
          <div className='div-calendar' style={{ width: '400px' }}>
            <Form.Group as={Col} controlId="formGridState" style={{ display: 'grid' }}>
              <DatePicker
                selected={dataInicio ? new Date(dataInicio) : null}
                onChange={(date: Date) => {
                  const formattedDate = date.toISOString().split('T')[0]; // Extrai apenas a parte da data YYYY-MM-DD
                  setDataInicio(formattedDate);
                  handleChange('dataInicio', formattedDate); // Adapte conforme necessário
                }}
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                fixedHeight
                locale={pt}
                customInput={
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <input
                      type="date"
                      placeholder="Data Inicio"
                      className="dateFormFinanceiro"
                      value={dataInicio ? dataInicio : ''}
                      readOnly
                    />
                    <img
                      className="calendar-icon"
                      src={CalendarIcon}
                    />
                  </div>
                }
              />

            </Form.Group>
            <p className='label-date'>Até</p>
            <Form.Group as={Col} controlId="formGridState" style={{ display: 'grid' }}>
              <DatePicker
                selected={dataFim ? new Date(dataFim) : null}
                onChange={(date: Date) => {
                  const formattedDate = date.toISOString().split('T')[0]; // Extrai apenas a parte da data YYYY-MM-DD
                  setDataFim(formattedDate);
                  handleChange('dataFim', formattedDate); // Adapte conforme necessário
                }}
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                fixedHeight
                locale={pt}
                customInput={
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <input
                      type="date"
                      placeholder="Data Fim"
                      className="dateFormFinanceiro"
                      value={dataFim ? String(dataFim) : ''}
                      readOnly
                    />
                    <img
                      className="calendar-icon"
                      src={CalendarIcon}
                    />
                  </div>
                }
              />
            </Form.Group>
          </div>
        </div>
        <div className='div-filtros-avancados'>
          <Button
            onClick={handleFilter}
            style={{ height: '30px', borderRadius: '20px', padding: '5px 10px', marginRight: '5px', fontSize: '14px' }}
            type="button"
            severity="success"
            icon="pi pi-filter"
            rounded>
            <p style={{ marginLeft: '5px', marginTop: '13px' }}>Pesquisar</p>
          </Button>
          <Button
            onClick={handleClearList}
            style={{ height: '30px', borderRadius: '20px', padding: '5px 10px', marginRight: '5px', fontSize: '14px' }}
            type="button"
            severity="danger"
            icon="pi pi-delete-left"
            rounded>
            <p style={{ marginLeft: '5px', marginTop: '13px' }}>Limpar</p>
          </Button>
        </div>
      </div>
    </div>
  );
}

export default ContentSearchPagarReceber;
