import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Button, Modal } from 'react-bootstrap';

interface ModalConfirmarCancelarContratoJazigoProps {
    selectedRow: any[];
    showConfirmarModalCancelarContratoJazigo: boolean;
    handleShowConfirmarModalCancelarContratoJazigo: (show: boolean) => void;
    handleShowModalCancelarContratoJazigo: (show: boolean) => void;
}

function ModalConfirmarCancelarContratoJazigo({
    selectedRow,
    showConfirmarModalCancelarContratoJazigo,
    handleShowConfirmarModalCancelarContratoJazigo,
    handleShowModalCancelarContratoJazigo
}: ModalConfirmarCancelarContratoJazigoProps) {

    return (
        <Modal
            show={showConfirmarModalCancelarContratoJazigo}
            size='xl'
            onHide={() => handleShowConfirmarModalCancelarContratoJazigo(false)}
        >
            <Modal.Header closeButton>
                <Modal.Title className='text-center'>
                    Confirmar o cancelamento do seguinte contrato?
                </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ padding: '0px !important' }}>
                <DataTable
                    stripedRows
                    lazy
                    value={[selectedRow]}
                    tableStyle={{ minWidth: '50rem' }}
                    emptyMessage={
                        <span style={{ display: 'flex', justifyContent: 'center', fontStyle: 'italic' }}>
                            Nenhum item encontrado.
                        </span>
                    }
                >
                    <Column
                        key={'id'}
                        field={'id'}
                        header={'Id'}
                        style={{ textAlign: 'center', borderBottom: '1px solid #bdbdbd96' }}
                    />
                    <Column
                        key={'numero'}
                        field={'numero'}
                        header={'Nº Contrato'}
                        style={{ textAlign: 'center', borderBottom: '1px solid #bdbdbd96' }}
                    />
                    <Column
                        key={'data_venda'}
                        field={'data_venda'}
                        header={'Data Venda'}
                        style={{ textAlign: 'center', borderBottom: '1px solid #bdbdbd96' }}
                    />
                    <Column
                        key={'status_name'}
                        field={'status_name'}
                        header={'Status'}
                        style={{ textAlign: 'center', borderBottom: '1px solid #bdbdbd96' }}
                    />
                </DataTable>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="success"
                    onClick={() => {[
                        handleShowConfirmarModalCancelarContratoJazigo(false),
                        handleShowModalCancelarContratoJazigo(true)
                    ]}}
                >
                    Confirmar
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default ModalConfirmarCancelarContratoJazigo;
