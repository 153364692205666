import React, { useEffect, useState } from 'react';
import { Form, Row, Col, InputGroup } from 'react-bootstrap';
import { InputNumber } from 'primereact/inputnumber';

interface ModalAniversarioFormProps {
    formBasico: any;
    handleChange: (field: string, value: any) => void;
    handleSaveButton: () => Promise<void>;
}

const ModalAniversarioForm: React.FC<ModalAniversarioFormProps> = ({ formBasico, handleChange, handleSaveButton }) => {
    const [dia_parcela, setDiaParcela] = useState<any>(null);
    const [alterar_parcelas_banco, setAlterarParcelas] = useState<any>(false);

    return (
        <Form onSubmit={handleSaveButton}>
            <Row className="group-form">
                <Col md="2">
                    <Form.Label>Documento Partida</Form.Label>
                    <Form.Control
                        disabled
                        value={formBasico?.numero_documento}
                    />
                </Col>
                <Col md="2">
                    <Form.Label>Vencimento Atual</Form.Label>
                    <Form.Control
                        disabled
                        value={formBasico?.data_vencimento}
                    />
                </Col>
                <Col md="2">
                    <Form.Label>Cliente|Devedor</Form.Label>
                    <Form.Control
                        disabled
                        value={formBasico?.cliente_nome}
                    />
                </Col>
                <Col md="2">
                    <Form.Label>Valor</Form.Label>
                    <InputNumber
                        disabled
                        style={{ width: '100%', height: '38px' }}
                        value={formBasico?.valor_original ? formBasico?.valor_original : null}
                        mode="currency" currency="BRL" locale="pt-BR"
                    />
                </Col>
                <Col md="2">
                    <Form.Label>Dia da Parcela</Form.Label>
                    <Form.Control
                        value={dia_parcela}
                        onChange={(e) => {
                            handleChange(`dia_parcela`, Number(e.target.value))
                            setDiaParcela(e.target.value)
                        }}
                    />
                </Col>
                <Col md="2">
                    <Form.Label >Alterar Parcelas Banco?</Form.Label>
                    <Form.Check
                        checked={alterar_parcelas_banco || false}
                        style={{ display: 'flex', placeContent: 'center' }}
                        onChange={(e) => { handleChange("alterar_parcelas_banco", e.target.checked); setAlterarParcelas(e.target.checked) }}
                        type="switch"
                        id="custom-switch"
                    />
                </Col>
            </Row>
            <Row className="group-form">
                <Col md="12">
                    <Form.Label>Justificativa</Form.Label>
                    <Form.Control
                        disabled
                        value={formBasico?.justificativa}
                    />
                </Col>
            </Row>
        </Form>
    );
};

export default ModalAniversarioForm;
