import { AlertService } from "../../../../components/AlertService";
import { useSidebarContext } from "../../../../contexts/SidebarContext";
import TokenService from "../../../../services/Auth/token.service";
import ClienteService from "../../../Pessoas/Services/ClienteService";
import CessaoDeDireitoService from "../../Services/CessaoDeDireitoService";

interface Consulta {
  order?: string;
  filtro?: any;
}
const consulta: Consulta = {
  order: "id;desc",
};

export const loadData = (
  listFiltros: any,
  currentPage: any,
  setNodes: (value: any) => void,
  updateTitles: (titles: string[]) => void,
  updatePagination: (totalPages: number) => void
) => {
  const form = {
    numero: listFiltros?.numero,
    identificador1: listFiltros?.identificador1,
    identificador2: listFiltros?.identificador2,
    status: listFiltros?.status,
    vencimento: listFiltros?.vencimento,
    data: listFiltros?.data,
    cpf_cnpj: listFiltros?.cpf_cnpj,
    nome_comprador: listFiltros?.nome_comprador,
    empreendimento_id: TokenService.getEmpreendimentoId()
      ? TokenService.getEmpreendimentoId()?.id
      : null,
  };

  const filtro = Object.fromEntries(
    Object.entries(form).filter(
      ([_, value]) => value !== null && value !== "" && value !== undefined
    )
  );

  if (consulta) {
    consulta.filtro = filtro;
  }
  ContasPagarList(
    consulta,
    currentPage,
    setNodes,
    updateTitles,
    updatePagination
  );
};

export const ContasPagarList = (
  filtros: any,
  currentPage: any,
  setNodes: (value: any) => void,
  updateTitles: (titles: string[]) => void,
  updatePagination: (totalPages: number) => void
) => {
  CessaoDeDireitoService.list(
    {
      include: "",
      totalitems: 8,
      consulta: JSON.stringify(filtros),
      page: currentPage + 1,
    },
    null,
    updateTitles,
    updatePagination
  ).subscribe(
    (response) => {
      setNodes(response);
    },
    (error) => {
      let mensagens: string[] = [];
      mensagens.push(error?.response?.data?.error?.message);
      AlertService.error("Erro!", String(mensagens));
    }
  );
};

export const toggleVisibility = () => {
  const { isVisible, updateVisibility } = useSidebarContext();
  const newValue = !isVisible;
  updateVisibility(newValue);
};

export const handleOpenModal = (
  row: any,
  setSelectedRow: (value: any) => void,
  setShowModal: (value: any) => void
) => {
  setSelectedRow(row);
  setShowModal(true);
};

export const searchCliente = (
  event: any,
  setFilteredClient: (value: any) => void
) => {
  const query = event.query;
  const filtro = {
    nome: query,
  };

  if (consulta) {
    consulta.filtro = filtro;
  }

  ClienteService.list({
    include: "pessoa.enderecos,pessoa.telefones",
    totalitems: 8,
    consulta: JSON.stringify(consulta),
  }).subscribe(
    (response) => {
      setFilteredClient(response);
    },
    (error) => {
      console.error(error);
      let mensagens: string[] = [];
      mensagens.push(error?.response?.data?.error?.message);
      AlertService.error("Erro!", String(mensagens));
    }
  );
};
