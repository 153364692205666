import React, { useEffect, useState } from 'react';
import { Button, Modal, Row, Col, Form } from 'react-bootstrap';
import { Checkbox } from 'primereact/checkbox';
import TokenService from '../../../../services/Auth/token.service';
import api from '../../../../services/api';
import { url_financeiro } from '../../../../environments/environments-develop';
import { toast } from 'react-toastify';

interface SelecaoRenegociacaoProps {
    data: any;
    handleData: (newData: any) => void;
    showSelecaoRenegociacao: boolean;
    handleClose: () => void;
    handleNext: () => void;
}

const SelecaoRenegociacao: React.FC<SelecaoRenegociacaoProps> = ({
    handleData,
    showSelecaoRenegociacao,
    handleClose,
    handleNext,
    data,
}) => {
    const [show, setShow] = useState(showSelecaoRenegociacao);
    const empreendimento = TokenService.getEmpreendimentoId() ? TokenService.getEmpreendimentoId() : null;
    const [numeroContrato, setNumeroContrato] = useState('');
    const [parcelasVencer, setParcelasVencer] = useState(false);
    const [parcelasAtraso, setParcelasAtraso] = useState(true);
    const [tipo_parcela, setTipoParcela] = useState('');

    useEffect(() => {
        if (showSelecaoRenegociacao) {
            setNumeroContrato('');
            setParcelasVencer(false);
            setParcelasAtraso(true);
            setTipoParcela('');
        }
        setShow(showSelecaoRenegociacao);
    }, [showSelecaoRenegociacao]);

    const body = {
        empreendimento_id: empreendimento?.id,
        num_contrato: numeroContrato,
        tipo_parcela: tipo_parcela,
        parcelas_vencidas: parcelasAtraso,
        parcelas_abertas: parcelasVencer,
    };

    const buscarParcelas = async () => {
        try {
            const response = await api.get(
                `${url_financeiro}/api/v1/admin/financeiro/renegociacao/listar-parcelas`,
                { params: body }
            );
            const { abertas, inadimplentes } = response.data;

            const hasAbertas = abertas && Object.keys(abertas).length > 0;
            const hasInadimplentes = inadimplentes && Object.keys(inadimplentes).length > 0;

            if (hasAbertas || hasInadimplentes) {
                handleData(response.data);
                handleNext();
            } else {
                toast.warning('Nenhuma parcela encontrada');
            }
        } catch (error) {
            const errorMessage =
                (error as any)?.response?.data?.message ||
                (error as any)?.error.message ||
                'Erro desconhecido ao buscar parcelas';
            toast.error(`Erro ao buscar parcelas: ${errorMessage}`);
        }
    };

    return (
        <Modal
            show={showSelecaoRenegociacao}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            size="lg"
            className="modal-selecao-renegociacao"
        >
            <Modal.Header closeButton>
                <Modal.Title>Seleção de Renegociação</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row className="mb-3">
                    <Col md="3">
                        <Form.Label>Empreendimento</Form.Label>
                        <Form.Control
                            type="text"
                            disabled
                            value={empreendimento?.nome_empreendimento}
                        ></Form.Control>
                    </Col>
                    <Col md="3">
                        <Form.Label>Número do Contrato</Form.Label>
                        <Form.Control
                            isInvalid={!numeroContrato}
                            type="text"
                            value={numeroContrato}
                            onChange={(e) => setNumeroContrato(e.target.value)}
                        />
                    </Col>
                    <Col md="3">
                        <Form.Label>Tipo Parcela</Form.Label>
                        <Form.Select value={tipo_parcela} onChange={(e) => setTipoParcela(e.target.value)}>
                            <option value="">Selecione...</option>
                            <option value="1">Ordinária</option>
                            <option value="2">Balão</option>
                            <option value="3">Avulso</option>
                            <option value="4">Saldo Restante</option>
                            <option value="5">Comissões</option>
                            <option value="6">Temporário</option>
                            <option value="7">Condomínio</option>
                            <option value="8">Parcela Mensal</option>
                            <option value="9">Acordo Avulso</option>
                            <option value="10">Intermediação</option>
                            <option value="11">Entrada</option>
                            <option value="12">Agrupado</option>
                            <option value="13">Lançamento Pai</option>
                        </Form.Select>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col md="3" style={{ display: 'flex', alignItems: 'center' }}>
                        <Checkbox checked={parcelasVencer} onChange={() => setParcelasVencer(!parcelasVencer)} />
                        <Form.Label
                            style={{ textAlign: 'center', marginLeft: '10px', marginTop: '10px' }}
                        >
                            Exibir parcelas a vencer
                        </Form.Label>
                    </Col>
                    <Col md="4" style={{ display: 'flex', alignItems: 'center' }}>
                        <Checkbox checked={parcelasAtraso} onChange={() => setParcelasAtraso(!parcelasAtraso)} />
                        <Form.Label
                            style={{ textAlign: 'center', marginLeft: '10px', marginTop: '10px' }}
                        >
                            Exibir parcelas em atraso
                        </Form.Label>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Cancelar
                </Button>
                <Button
                    className="button-next-form"
                    onClick={buscarParcelas}
                    disabled={!numeroContrato || (!parcelasVencer && !parcelasAtraso)}
                >
                    Buscar
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default SelecaoRenegociacao;
