import React, { useState, useEffect } from 'react';
import Table from 'react-bootstrap/Table';
import { MDBPagination, MDBPageItem, MDBPageNav, MDBCol, MDBRow } from "mdbreact";
import { useNavigate } from 'react-router-dom';
import { AlertService } from './AlertService';
import { url } from '../environments/environments-develop';
import api from '../services/api';
import iconLock from '../../assets/images/Nothing-Found.png';
interface TableImportComponentsProps {
    header: string[];
    columns: string[][];
    itemsPerPage: number;
    urlSearchDataPut: string;
    url_redirect_edit: string;
}

const TableImportComponents: React.FC<TableImportComponentsProps> = ({ header, columns, itemsPerPage, urlSearchDataPut, url_redirect_edit }) => {
    const [currentPage, setCurrentPage] = useState(0);

    const handlePageClick = (selectedObject: { selected: number }) => {
        setCurrentPage(selectedObject.selected);
    };

    const totalColumns = columns.length;
    const totalPages = Math.ceil(totalColumns / itemsPerPage);
    const navigate = useNavigate();
    const offset = currentPage * itemsPerPage;
    const currentColumns = columns.slice(offset, offset + itemsPerPage);

    const renderPageNumbers = () => {
        const pageNumbers = [];

        for (let i = 0; i < totalPages; i++) {
            // Adiciona as três primeiras páginas
            if (i < 3 || i === totalPages - 1 || (i >= currentPage - 1 && i <= currentPage + 1)) {
                pageNumbers.push(
                    <MDBPageItem key={i} active={i === currentPage}>
                        <MDBPageNav className="page-link" onClick={() => setCurrentPage(i)}>
                            <div>{i + 1}</div>
                        </MDBPageNav>
                    </MDBPageItem>
                );
            }
        }

        return pageNumbers;
    };

    async function setSessionEditData(value: any): Promise<void> {
        try {
            const includeParam = 'config_venda,config_carteira,pessoa.telefones,pessoa.enderecos,grupo_permissoes';
            const response = await api.get(`${url}${urlSearchDataPut}${value}?include=${includeParam}`);
            if (response.status === 200) {
                localStorage.setItem('dadosEdicao', JSON.stringify(response.data));
            }
        } catch (error: any) {
            let mensagens: string[] = [];
            mensagens.push(error?.response?.data?.error?.message);
            AlertService.error('Erro!', String(mensagens));
        }
    }
    return (
        <div style={{ width: '100%' }} className='table-listagem'>
            {!header || currentColumns.length == 0 && (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div className='md-12' style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', alignContent: 'center' }}>
                        <img alt="iconLock" className="iconLock" src={String(iconLock)} />
                        <div className='label-table-not-found'>
                            Nenhum dado Encontrado para listagem!
                        </div>
                        <p className='label-not-found'>Se você acredita que isto é um erro, entre em contato com o suporte ou com o administrador da conta e relate o ocorrido.</p>
                    </div>
                </div>
            )}
            {header && (currentColumns.length > 0) && (<Table responsive>
                <thead>
                    <tr>
                        {header.map((item: any, index: any) => (
                            <th className='text-center' key={index}>{item}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    <>
                        {currentColumns.map((row: any, rowIndex: any) => (
                            <tr key={rowIndex}>
                                {row.map((cell: any, cellIndex: any) => (
                                    <td className='text-center' key={cellIndex}>{cell}</td>
                                ))}
                            </tr>
                        ))}
                    </>
                </tbody>
            </Table>
            )}
            {header && (currentColumns.length > 0) && (<MDBRow>
                <MDBCol>
                    <MDBPagination circle>
                        <MDBPageItem disabled={currentPage === 0}>
                            <MDBPageNav className="page-link" aria-label="Previous" onClick={() => setCurrentPage(currentPage - 1)}>
                                <span aria-hidden="true">&laquo;</span>
                            </MDBPageNav>
                        </MDBPageItem>
                        {renderPageNumbers()}
                        <MDBPageItem disabled={currentPage === totalPages - 1}>
                            <MDBPageNav className="page-link" aria-label="Next" onClick={() => setCurrentPage(currentPage + 1)}>
                                <span aria-hidden="true">&raquo;</span>
                            </MDBPageNav>
                        </MDBPageItem>
                    </MDBPagination>
                </MDBCol>
            </MDBRow>
            )}
        </div>
    );
};

export default TableImportComponents;
