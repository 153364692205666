import { useAppContext } from '../../../contexts/AppContext';
import { useEffect, useState } from 'react';
import { AlertService } from '../../../components/AlertService';
import TableComponentsContrato from './Components/TableComponentsContrato';
import TokenService from '../../../services/Auth/token.service';
import FiltroGestaoContratos from './Components/filtro';

function GestaoContratos() {
  const { showSubSidebar, setItemsBreadsCrumbs } = useAppContext();
  const [listFiltros, setListFiltros] = useState<any>({});
  const [filtros, setFiltros] = useState<string>();
  const [formBasico, setFormBasico] = useState<any>({});
  const { setContextItemsButtonMenu } = useAppContext();
  const [order, setOrder] = useState<string>('vendas.contrato.data_venda;asc');
  const [activeSearch, setActiveSearch] = useState<any>(false);

  useEffect(() => {
    if (order) {
      montarFiltro();
    }
  }, [order]);

  async function montarFiltro(): Promise<void> {
    try {
      const consulta: any = {
        consulta: {
          order: order,
          filtro: {
            "numero": listFiltros ? listFiltros?.numero : null,
            "nome": listFiltros ? listFiltros?.nome : null,
            "status": listFiltros ? listFiltros?.status : null,
            "data_venda_format": listFiltros ? listFiltros?.data_venda_format : null,
            "qtd_parcelas": listFiltros ? listFiltros?.qtd_parcelas : null,
            "dataInicio": listFiltros ? listFiltros?.dataInicio : null,
            "dataFim": listFiltros ? listFiltros?.dataFim : null,
            "tipo_consulta": listFiltros ? listFiltros?.tipo_consulta : null,
            "empreendimento_id": TokenService.getEmpreendimentoId() ? TokenService.getEmpreendimentoId().id : null
          }
        }
      };
      const queryParams: any = {};

      const camposEnviar = ['consulta'];
      camposEnviar.forEach((key: any) => {
        const value = consulta[key];
        if (value !== undefined && value !== null && value !== '') {
          if (key === 'consulta') {
            queryParams[key] = JSON.stringify(value);
          } else {
            queryParams[key] = value;
          }
        }
      });
      const queryString = Object.entries(queryParams)
        .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(String(value))}`)
        .join('&');
      setFiltros(queryString);
    } catch (error: any) {
      AlertService.error('Erro!', String(error.response?.data?.message) || 'Erro desconhecido');
    }
  }
  useEffect(() => {
    setItemsBreadsCrumbs([
      {
        label: 'Carteira',
        url: '/carteira',
      },
      {
        label: 'Lista de Contratos',
        url: '/contrato',
      }
    ]);
  }, []);
  useEffect(() => {
    const commonStyle = {
      width: '35px',
      height: '35px',
      top: '0px',
      color: '#fff'
    };
    const items = [
      {
        label: 'Gerar Nova Venda',
        icon: 'pi pi-user-plus',
        style: { ...commonStyle, backgroundColor: '#19c022', display: 'flex' },
        command: () => {
          window.location.href = '/venda/gerar-venda';
        },
        tooltip: 'Gerar Nova Venda'
      },
      {
        label: 'Cessão de Direitos',
        icon: 'pi pi-arrow-right-arrow-left',
        style: { ...commonStyle, backgroundColor: '#007bff', display: 'flex' },
        command: () => {
          window.location.href = 'carteira/contrato-transferencia';
        },
        tooltip: 'Cessão de Direitos',
      }
    ];
    setContextItemsButtonMenu(items);
  }, [])

  useEffect(() => {
    montarFiltro();
  }, [listFiltros]);

  const handleChange = (field: string, value: string) => {
    setFormBasico((prevGrupoData: any) => {
      return {
        ...prevGrupoData,
        [field]: value
      };
    });
  };

  const handleChangeList = (field: string, value: string) => {
    setListFiltros((prevGrupoData: any) => {
      return {
        ...prevGrupoData,
        [field]: value
      };
    });
  };

  const handleClearList = () => {
    setListFiltros(null);
    setFormBasico(null);
    montarFiltro();
  };

  return (
    <div className="body-content">
      <div
        className={`${showSubSidebar ? "container-section-header" : "container-section-header-none"}`}
      >
        <FiltroGestaoContratos
          onInputChange={(field: string, value: string) => handleChange(`${field}`, value)}
          onInputChangeList={(field: string, value: string) => handleChangeList(`${field}`, value)}
          onInputClearList={() => handleClearList()}
          filtrosData={formBasico}
          header={'Gestão de Contratos'}
        />
      </div>
      <div
        className={`${showSubSidebar ? "container-section-body" : "container-section-body-none"}`}
      >
        <TableComponentsContrato
          key={filtros}
          ordernacao={setOrder}
          urlRedirectCreated=""
          urlSearch={`/api/v1/admin/vendas/contrato?${filtros}`}
          urlSearchDataPut="/api/v1/admin/vendas/contrato/"
          url_redirect_edit="/contrato/update"
        />
      </div>
    </div>
  );
}
export default GestaoContratos;