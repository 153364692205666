import ButtonCreateComponent from "./ButtonCreateComponent";
import ButtonMenuCrumbsComponent from "./ButtonMenuCrumbsComponent";

function ButtonsCrumbsComponent() {
  return (
    <div className="ulConfig">
      <ul className=" navbar-nav">
        <ButtonCreateComponent></ButtonCreateComponent>
        <ButtonMenuCrumbsComponent></ButtonMenuCrumbsComponent>
      </ul>
    </div>
  );
}

export default ButtonsCrumbsComponent;
