import { Col, Form, InputGroup, Row } from "react-bootstrap";
interface PropsData {
    Data: {
        taxa_juros?: number;
        taxa_multa?: number;
        valor_multa?: number;
        valor_juros?: number;
        valor_desconto?: number;
        valor_taxa_bancaria?: number;
        valor_outros?: number;
        qtd_dias_carencia_multa?: number;
        qtd_dias_carencia_juros?: number;
    };
    onInputChange: (field: string, value: any) => void;
}
function TaxasJurosForm(props: PropsData) {
    const { taxa_juros, taxa_multa, valor_multa, valor_juros, valor_desconto, valor_taxa_bancaria,
        valor_outros, qtd_dias_carencia_multa, qtd_dias_carencia_juros } = props.Data || {};
    const handleChange = (field: string, value: any) => {
        props.onInputChange(field, value);
    };


    return (
        <>
            <Form.Group className="formulario-empreendimento">
                <Row className="group-form">
                    <Col>
                        <Form.Label>Taxa de Juros</Form.Label>
                        <Form.Control value={taxa_juros} onChange={(e) => handleChange('data_competencia', e.target.value)} />
                    </Col>
                    <Col>
                        <Form.Label>Taxa de Multa</Form.Label>
                        <Form.Control value={taxa_multa} onChange={(e) => handleChange('data_competencia', e.target.value)} />
                    </Col>
                    <Col>
                        <Form.Label>Valores de Multa</Form.Label>
                        <Form.Control value={valor_multa} onChange={(e) => handleChange('data_competencia', e.target.value)} />
                    </Col>
                </Row>
                <Row className="group-form">
                    <Col>
                        <Form.Label>Valores de Juros</Form.Label>
                        <Form.Control value={valor_juros} onChange={(e) => handleChange('data_competencia', e.target.value)} />
                    </Col>
                    <Col>
                        <Form.Label>Valores de Desconto</Form.Label>
                        <Form.Control value={valor_desconto} onChange={(e) => handleChange('data_competencia', e.target.value)} />
                    </Col>
                    <Col>
                        <Form.Label>Valor da Taxa Bancária</Form.Label>
                        <Form.Control value={valor_taxa_bancaria} onChange={(e) => handleChange('data_competencia', e.target.value)} />
                    </Col>
                </Row>
                <Row className="group-form">
                    <Col>
                        <Form.Label>Outros Valores</Form.Label>
                        <Form.Control value={valor_outros} onChange={(e) => handleChange('data_competencia', e.target.value)} />
                    </Col>
                    <Col>
                        <Form.Label>Quantidade de Dias de Carência da Multa</Form.Label>
                        <Form.Control value={qtd_dias_carencia_multa} onChange={(e) => handleChange('data_competencia', e.target.value)} />
                    </Col>
                    <Col>
                        <Form.Label>Quantidade de Dias de Carência da Juros</Form.Label>
                        <Form.Control value={qtd_dias_carencia_juros} onChange={(e) => handleChange('data_competencia', e.target.value)} />
                    </Col>
                </Row>
            </Form.Group>
        </>
    );
}

export default TaxasJurosForm;
