import React, { useRef, useEffect } from 'react';
import ace from 'ace-builds'; // Renomeando o tipo Editor como AceEditor
import 'ace-builds/src-noconflict/mode-pgsql';
import 'ace-builds/src-noconflict/theme-textmate';
import 'ace-builds/src-noconflict/ext-language_tools';
import 'ace-builds/src-noconflict/ext-options';

interface AceEditorProps {
    onChange: (value: string) => void;
    defaultValue?: string;
    value?: string;
    theme?: string;
    mode?: string;
}

const AceEditorComponent: React.FC<AceEditorProps> = ({ onChange, defaultValue = '', value, theme = 'monokai', mode = 'pgsql' }) => {
    const editorRef = useRef<HTMLDivElement | any | null>(null);
    let editor: any | null = null;

    useEffect(() => {
        if (editorRef.current) {
            editor = ace.edit(editorRef.current);

            // Configurações do editor
            editor.setTheme(`ace/theme/${theme}`);
            editor.getSession().setMode(`ace/mode/${mode}`);
            editor.getSession().setValue(defaultValue);
            editor.setOptions({
                enableBasicAutocompletion: true,
                enableLiveAutocompletion: true,
                enableSnippets: true,
                showLineNumbers: true,
                tabSize: 2,
                useWorker: false,
            });



            // Evento de mudança de conteúdo
            editor.getSession().on('change', () => {
                if (onChange) {
                    onChange(editor.getSession().getValue());
                }
            });
        }
    }, [onChange, defaultValue, theme, mode]);


    // Atualiza o valor do editor se a propriedade 'value' mudar
    useEffect(() => {
        if (editor.current && value !== undefined) {
            editor.current.getSession().setValue(value);
        }
    }, [value]);

    // Limpa o editor quando o componente é desmontado
    useEffect(() => {
        return () => {
            if (editor.current) {
                editor.current.destroy();
                editor.current = null;
            }
        };
    }, []);

    // Cria o editor quando o componente é montado
    useEffect(() => {
        editorRef.current = ace.edit(editor.current!);
    }, []);

    return <div ref={editorRef} style={{ fontSize: '20px', width: '100%', height: '400px' }} />;
};

export default AceEditorComponent;
