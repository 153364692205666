import { Col, Form, InputGroup, Row } from "react-bootstrap";
import { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CalendarIcon from '../../../../../assets/images/icon-calendar.png';
import pt from 'date-fns/locale/pt';
import TokenService from "../../../../services/Auth/token.service";
interface PropsData {
    Data: {
        tipo_licenca: string;
        data_inicio: string;
        data_expiracao: string;
        valor_original: number;
        tipo_intervalo: string;
        renovassao_automatica: boolean;
        qtd_acessos: number;
        situacao_licenca: number;
        filial_id: string;
    };
    initialRightValues: any;
    onInputChange: (field: string, value: any) => void;
};
function LicencaForm(props: PropsData) {
    const { tipo_licenca, valor_original, tipo_intervalo, situacao_licenca, renovassao_automatica, qtd_acessos, filial_id } = props.Data || {};
    const [data_inicio, setDataInicio] = useState<Date | null>(null);
    const [data_expiracao, setDataExpiracao] = useState<Date | null>(null);
    const filiais: Array<any> = TokenService.getFiliais();

    const handleChange = (field: any, value: any) => {
        props.onInputChange(field, value);
    };

    useEffect(() => {
        setDataInicio(props?.Data?.data_inicio ? new Date(props?.Data?.data_inicio) : null);
        setDataExpiracao(props?.Data?.data_expiracao ? new Date(props?.Data?.data_expiracao) : null);
    }, [props.Data]);

    return (
        <Form className="forms">
            <Row classname="mb-3">
                <Col md={3}>
                    <Form.Label>Tipo de Licença</Form.Label>
                    <Form.Select
                        value={tipo_licenca}
                        onChange={(e) => handleChange('tipo_licenca', e.target.value)}
                    >
                        <option>Selecione...</option>
                        <option value="1">Financeira</option>
                        <option value="2">Imobiliaria</option>
                        <option value="3">Cemiterio</option>
                    </Form.Select>
                </Col>
                <Col md={2}>
                    <Form.Group className="mb-3">
                        <Form.Label>Data de Início</Form.Label>
                        <DatePicker
                            selected={data_inicio}
                            onChange={(date: Date) => {
                                setDataInicio(date);
                                handleChange('data_inicio', date ? date.toISOString() : '');
                            }}
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            fixedHeight
                            locale={pt}
                            customInput={
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <input
                                        type="text"
                                        className="dateFormFinanceiro"
                                        value={data_inicio ? data_inicio.toLocaleDateString() : ''}
                                        readOnly
                                    />
                                    <img
                                        className="calendar-icon"
                                        src={CalendarIcon}
                                    />
                                </div>
                            }
                        />
                    </Form.Group>
                </Col>
                <Col md={2}>
                    <Form.Group className="mb-3">
                        <Form.Label>Data de Expiração</Form.Label>
                        <DatePicker
                            selected={data_expiracao}
                            onChange={(date: Date) => {
                                setDataExpiracao(date);
                                handleChange('data_expiracao', date.toISOString());
                            }}
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            fixedHeight
                            locale={pt}
                            customInput={
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <input
                                        type="text"
                                        className="dateFormFinanceiro"
                                        value={data_expiracao ? data_expiracao.toLocaleDateString() : ''}
                                        readOnly
                                    />
                                    <img
                                        className="calendar-icon"
                                        src={CalendarIcon}
                                    />
                                </div>
                            }
                        />
                    </Form.Group>
                </Col>
                <Col md={3}>
                    <Form.Label>Tipo de Intervalo</Form.Label>
                    <Form.Select
                        value={tipo_intervalo}
                        onChange={(e) => handleChange('tipo_intervalo', e.target.value)}
                    >
                        <option>Selecione...</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                    </Form.Select>
                </Col>
                <Col md={2}>
                    <Form.Label style={{ alignItems: 'center', textAlign: 'center' }}>Renovação Automática</Form.Label>
                    <Form.Check
                        type="switch"
                        id="renovassao_automatica"
                        checked={renovassao_automatica}
                        onChange={(e) => handleChange('renovassao_automatica', e.target.checked)}
                        style={{ marginLeft: '50px' }}
                    />
                </Col>
            </Row>
            <Row className="mb-3">
                <Col md={2}>
                    <Form.Label>Valor Original</Form.Label>
                    <Form.Control
                        type="number"
                        value={Number(valor_original)}
                        onChange={(e) => handleChange('valor_original', e.target.value)}
                    />
                </Col>
                <Col md={2}>
                    <Form.Label>Quantidade de Acessos</Form.Label>
                    <Form.Control
                        type="number"
                        value={qtd_acessos}
                        onChange={(e) => handleChange('qtd_acessos', e.target.value)}
                    />
                </Col>
                <Col md={3}>
                    <Form.Label>Filial</Form.Label>
                    <Form.Select
                        value={filial_id}
                        onChange={(e) => handleChange('filial_id', e.target.value)}
                    >
                        <option>Selecione...</option>
                        {filiais.map((item, index) => (
                            <option key={index} value={item.id}>{item.nome_conta}</option>
                        ))}
                    </Form.Select>
                </Col>
                <Col md={2}>
                    <Form.Label>Bloqueado</Form.Label>
                    <Form.Select
                        value={situacao_licenca}
                        onChange={(e) => handleChange('situacao_licenca', e.target.value)}
                    >
                        <option>Selecione...</option>
                        <option value={1}>Liberado</option>
                        <option value={2}>Alerta</option>
                        <option value={3}>Suspenso</option>
                    </Form.Select>
                </Col>
            </Row>
        </Form >
    );
}
export default LicencaForm;
