import React, { ReactNode, createContext, useContext, useState } from 'react';

interface ResponseContextProps {
    titles: any[];
    updateTitles: (newData: any[]) => void;
    pagination: any;
    updatePagination: (newData: any) => void;
}

const ResponseContext = createContext<ResponseContextProps | undefined>(undefined);

export const useAppContext = () => {
    const context = useContext(ResponseContext);
    if (!context) {
        throw new Error('useAppContext must be used within an AppProvider');
    }
    return context;
};

interface AppProviderProps {
    children: ReactNode;
}

export const ResponseProvider: React.FC<AppProviderProps> = ({ children }) => {
    const [titles, updateTitles] = useState<any[]>([]);
    const [pagination, updatePagination] = useState<any[]>([]);

    const updateResponse = (newData: any[]) => {
        updateTitles(newData);
    };
    const updateMeta = (newData: any[]) => {
        updatePagination(newData);
    };

    return (
        <ResponseContext.Provider value={{ titles, updateTitles: updateResponse, pagination, updatePagination: updateMeta }}>
            {children}
        </ResponseContext.Provider>
    );
};