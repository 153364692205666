import axios from "../../../services/api";
import { Observable, from, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { url } from "../../../environments/environments-develop";
import { IInumado, InumadoModel } from "../Models/InumadoModel";

interface IApiResponse<T> {
  data: T;
  headers: {
    titles: string[];
  };
  meta: any;
}

class InumadoService {
  private resourceUrl = url + "/api/v1/admin/cemiterio/inumado";

  list(
params: any = {}, headers: any = null, updateTitles: (titles: string[]) => void, updatePagination: (totalPages: number) => void  ): Observable<InumadoModel[]> {
    return from(
      axios
        .get<IApiResponse<IInumado[]>>(this.resourceUrl, {
          params,
          headers,
        })
        .then((response) => {
          return response.data.data.map((item) =>
            new InumadoModel().deserialize(item)
          );
        })
    ).pipe(catchError((error) => throwError(error)));
  }

  listAll(
    params: any = {},
    headers: any = null,
    updateTitles: (titles: string[]) => void,
    updatePagination: (totalPages: number) => void
  ): Observable<InumadoModel[]> {
    return from(
      axios
        .get<IApiResponse<IInumado[]>>(this.resourceUrl, {
          params,
          headers,
        })
        .then((response) => {
          updateTitles(response.data.headers.titles);
          updatePagination(response.data.meta.pagination.total_pages);
          return response.data.data.map((item) =>
            new InumadoModel().deserialize(item)
          );
        })
    ).pipe(catchError((error) => throwError(error)));
  }

  get(
    id: number,
    params: any = {},
    headers: any = null
  ): Observable<InumadoModel> {
    return from(
      axios
        .get<IInumado>(`${this.resourceUrl}/${id}`, {
          params,
          headers,
        })
        .then((response) =>
          new InumadoModel().deserialize(response.data)
        )
    ).pipe(catchError((error) => throwError(error)));
  }

  create(
    data: any,
    params: any = {},
    headers: any = null
  ): Observable<InumadoModel> {
    return from(
      axios
        .post<IInumado>(this.resourceUrl, data, {
          params,
          headers,
        })
        .then((response) =>
          new InumadoModel().deserialize(response.data)
        )
    ).pipe(catchError((error) => throwError(error)));
  }

  update(
    id: number,
    data: any,
    params: any = {},
    headers: any = null
  ): Observable<InumadoModel> {
    return from(
      axios
        .put<IInumado>(`${this.resourceUrl}/${id}`, data, {
          params,
          headers,
        })
        .then((response) =>
          new InumadoModel().deserialize(response.data)
        )
    ).pipe(catchError((error) => throwError(error)));
  }

  excluir(params: any = {}, headers: any = null): Observable<any> {
    return from(
      axios
        .post(`${this.resourceUrl}/destroy-all`, { ids: params }, { headers })
        .then((response) => response.data)
    ).pipe(catchError((error) => throwError(error)));
  }
  listInumado(
    params: any = {},
    headers: any = null,
  ): Observable<InumadoModel[]> {
    return from(
      axios
        .get<IApiResponse<IInumado[]>>(this.resourceUrl, {
          params,
          headers,
        })
        .then((response) => {
          return response.data.data.map((item) =>
            new InumadoModel().deserialize(item)
          );
        })
    ).pipe(catchError((error) => throwError(error)));
  }
}

export default new InumadoService();
