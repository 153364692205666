import React, { useEffect, useState } from 'react';
import { Modal, Container, Form, Row, Col, Button } from 'react-bootstrap';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { url } from '../../../../environments/environments-develop';
import { AlertService } from '../../../../components/AlertService';
import api from '../../../../services/api';
import { Calendar } from 'primereact/calendar';

interface ModalListaRemessaProps {
    conta_financeira_id: any;
    showModal: boolean;
    onHide: () => void;
    emitirRemessa: () => void;
}

const ModalListaRemessa: React.FC<ModalListaRemessaProps> = ({
    conta_financeira_id,
    showModal,
    onHide,
    emitirRemessa
}) => {

    const [dados, setDados] = useState<any | null>(null);
    useEffect(() => {
        if (showModal) {
            InitDadosDash();
        }
        return () => {
            setDados(null);
        };
    }, [conta_financeira_id, showModal])
    useEffect(() => {
        return () => {
            setDados(null);
        };
    }, [])
    async function InitDadosDash() {
        if (!conta_financeira_id) {
            return;
        }
        try {
            const response = await api.get(`${url}/api/v1/admin/financeiro/listar-remessa/${conta_financeira_id}`);
            setDados(response.data.data);

        } catch (error: any) {
            AlertService.infomessage('Atenção!', String(error.response?.data?.error?.message || 'Erro desconhecido'));
        }
    }
    return (
        <Modal show={showModal} size='xl' style={{ padding: '0px !important' }} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title className='text-center'>Confirmar emissão da remessa dos seguintes boletos?</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ padding: '0px !important' }}>
                <DataTable
                    stripedRows
                    lazy
                    size="small"
                    paginator
                    rows={12}
                    value={dados ? dados : []}
                    tableStyle={{ minWidth: '50rem' }}
                    emptyMessage={<span style={{ display: 'flex', justifyContent: 'center', fontStyle: 'italic' }}>Nenhum item encontrado.</span>}
                >
                    <Column
                        style={{ textAlign: 'left' }}
                        key={'numero_documento'}
                        field={'numero_documento'}
                        headerClassName="lefted-header"
                        header={<div className="lefted-content">N°Documento</div>}
                    />
                    <Column
                        style={{ textAlign: 'left' }}
                        key={'data_vencimento_format'}
                        field={'data_vencimento_format'}
                        headerClassName="lefted-header"
                        header={<div className="lefted-content">Vencimento</div>}
                    />

                    <Column
                        style={{ textAlign: 'right' }}
                        key={'valor_original_format'}
                        field={'valor_original_format'}
                        headerClassName="righted-header"
                        header={<div className="righted-content">Valor</div>}
                    />
                </DataTable>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="success" onClick={() => { emitirRemessa() }}>Emitir Remessa</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ModalListaRemessa;
