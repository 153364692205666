import { Col, Form, InputGroup, Row } from "react-bootstrap";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import CalendarIcon from '../../../../../assets/images/icon-calendar.png';
import { useEffect, useState } from "react";
import pt from "date-fns/locale/pt";
import { InputNumber } from "primereact/inputnumber";
function ContratosForm(props: any) {
    const {
        status_name,
        data_venda_format,
        imovel,
        imo_intermed,
        valor_total,
        entrada,
        qtd_parcelas,
        valor_parcelas,
        valor_desconto,
        intermed_valor_desc,
        qtd_parc_intermed,
        valor_parc_intermed,
        qtd_parc_entrada,
        corretor,
        saldo_financiar,
        tipo_contrato_name,
        valor_prim_parc_entrada,

    } = props.Data || {};
    const [prox_reajuste, setProxReajuste] = useState<Date | null>(null);
    const [venc_prim_parc_intermed, setVencPrimParcIntermed] = useState<Date | null>(null);
    const [venc_prim_parc_entrada, setVencPrimParcEntrada] = useState<Date | null>(null);
    const [data_prim_parcela, setDataPrimParcela] = useState<Date | null>(null);
    const [data_ult_reajuste, setUltReajuste] = useState<Date | null>(null);
    const [formCompradores, setFormCompradores] = useState<any>({
        pessoa: []
    });
    const handleChange = (field: string, value: any) => {
        props.onInputChange(field, value);
    };
    useEffect(() => {
        if (props.Data?.cliente_compradores) {
            let comprador: number[] = [];

            props.Data?.cliente_compradores?.forEach((item: any) => {
                comprador.push(item);
            });
            setFormCompradores((prevData: any) => {
                return {
                    ...prevData,
                    pessoa: comprador
                };
            });
        }
        setProxReajuste(props.Data.prox_reajuste ? new Date(props.Data.prox_reajuste) : null);
        setVencPrimParcIntermed(props.Data.venc_prim_parc_intermed ? new Date(props.Data.venc_prim_parc_intermed) : null);
        setVencPrimParcEntrada(props.Data.venc_prim_parc_entrada ? new Date(props.Data.venc_prim_parc_entrada) : null);
        setDataPrimParcela(props.Data.data_prim_parcela ? new Date(props.Data.data_prim_parcela) : null);
        setUltReajuste(props.Data.data_ult_reajuste ? new Date(props.Data.data_ult_reajuste) : null)
    }, [props.Data])
    return (
        <div style={{ margin: '15px' }}>
            {formCompradores?.pessoa.map((comprador: any) => (
                <Row className="group-form" key={comprador.id}>
                    <Col md="3">
                        <Form.Label>Comprador</Form.Label>
                        <p>{comprador?.cliente_name}</p>
                    </Col>
                    <Col md="3">
                        <Form.Label>Comprador Principal</Form.Label>
                        <p>{comprador?.principal === 1 ? 'Sim' : 'Não'}</p>
                    </Col>
                    <Col md="3">
                        <Form.Label>Unidade do Imóvel</Form.Label>
                        <p>{imovel ? imovel?.unidade : null}</p>
                    </Col>
                    <Col md="2">
                        <Form.Label>Qtd. Parcelas</Form.Label>
                        <p>{qtd_parcelas}</p>
                    </Col>
                </Row>
            ))}
            <Row className="group-form">
                <Col md="3">
                    <Form.Label>Status</Form.Label>
                    <p>{status_name}</p>
                </Col>
                <Col md="3">
                    <Form.Label>Data da Venda</Form.Label>
                    <p>{data_venda_format}</p>
                </Col>
                <Col md="3">
                    <Form.Label>Intermediação</Form.Label>
                    <InputNumber
                        style={{ width: '100%', height: '38px' }}
                        value={imo_intermed ? imo_intermed : 0}
                        onChange={(e: any) => handleChange('imo_intermed', e.value)}
                        mode="currency" currency="BRL" locale="pt-BR"
                    />
                </Col>
                <Col md="3">
                    <Form.Label>Valor Total</Form.Label>
                    <InputNumber
                        style={{ width: '100%', height: '38px' }}
                        value={valor_total ? valor_total : 0}
                        onChange={(e: any) => handleChange('valor_total', e.value)}
                        mode="currency" currency="BRL" locale="pt-BR"
                    />
                </Col>
            </Row>
            <Row className="mb-3">
                <Col md="3">
                    <Form.Label>Entrada</Form.Label>
                    <InputNumber
                        style={{ width: '100%', height: '38px' }}
                        value={entrada ? entrada : 0}
                        onChange={(e: any) => handleChange('entrada', e.value)}
                        mode="currency" currency="BRL" locale="pt-BR"
                    />
                </Col>
                <Col md="3">
                    <Form.Label>Valor das Parcelas</Form.Label>
                    <p>{valor_parcelas}</p>
                </Col>
                <Col md="3">
                    <Form.Label>Desconto</Form.Label>
                    <InputNumber
                        style={{ width: '100%', height: '38px' }}
                        value={valor_desconto ? valor_desconto : 0}
                        onChange={(e: any) => handleChange('valor_desconto', e.value)}
                        mode="currency" currency="BRL" locale="pt-BR"
                    />
                </Col>
                <Col md="3">
                    <Form.Label>Desconto da Intermediação</Form.Label>
                    <InputNumber
                        style={{ width: '100%', height: '38px' }}
                        value={intermed_valor_desc ? intermed_valor_desc : 0}
                        onChange={(e: any) => handleChange('intermed_valor_desc', e.value)}
                        mode="currency" currency="BRL" locale="pt-BR"
                    />
                </Col>
            </Row>
            <Row className="mb-3">
                <Col md="3">
                    <Form.Label>Qtd. Parcelas Intermediação</Form.Label>
                    <Form.Control
                        value={qtd_parc_intermed}
                        onChange={(e) => handleChange("qtd_parc_intermed", e.target.value)}
                    />
                </Col>
                <Col md="3">
                    <Form.Label>Valor da Primeira Parc. Intermed.</Form.Label>
                    <InputNumber
                        style={{ width: '100%', height: '38px' }}
                        value={valor_parc_intermed ? valor_parc_intermed : 0}
                        onChange={(e: any) => handleChange('valor_parc_intermed', e.value)}
                        mode="currency" currency="BRL" locale="pt-BR"
                    />
                </Col>
                <Col md="3" controlId="formGridState" style={{ display: 'grid' }}>
                    <Form.Label>Venc. Da Primeira Parc. Intermed</Form.Label>
                    <DatePicker
                        selected={venc_prim_parc_intermed || null}
                        onChange={(date: Date) => {
                            setVencPrimParcIntermed(date);
                            handleChange('venc_prim_parc_intermed', date ? date.toISOString() : ''); // Garante que a string seja vazia se a data for nula
                        }}
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        fixedHeight
                        locale={pt}
                        customInput={
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <input
                                    type="text"
                                    placeholder="Data da Prim. Parcela da Intermed"
                                    className="dateFormFinanceiro"
                                    value={venc_prim_parc_intermed ? venc_prim_parc_intermed.toLocaleDateString() : ''}
                                    readOnly
                                />
                                <img
                                    className="calendar-icon"
                                    src={CalendarIcon}
                                />
                            </div>
                        }
                    />
                </Col>
                <Col md="3">
                    <Form.Label>Valor Da Primeira Parc. Entrada</Form.Label>
                    <InputNumber
                        style={{ width: '100%', height: '38px' }}
                        value={valor_prim_parc_entrada ? valor_prim_parc_entrada : 0}
                        onChange={(e: any) => handleChange('valor_prim_parc_entrada', e.value)}
                        mode="currency" currency="BRL" locale="pt-BR"
                    />
                </Col>
            </Row>
            <Row className="mb-3">
                <Col md="3" controlId="formGridState" style={{ display: 'grid' }}>
                    <Form.Label>Venc. Da Primeira Parc. Entrada</Form.Label>
                    <DatePicker
                        selected={venc_prim_parc_entrada || null}
                        onChange={(date: Date) => {
                            setVencPrimParcEntrada(date);
                            handleChange('venc_prim_parc_entrada', date ? date.toISOString() : ''); // Garante que a string seja vazia se a data for nula
                        }}
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        fixedHeight
                        locale={pt}
                        customInput={
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <input
                                    type="text"
                                    placeholder="Data da Prim. Parcela da Entrada"
                                    className="dateFormFinanceiro"
                                    value={venc_prim_parc_entrada ? venc_prim_parc_entrada.toLocaleDateString() : ''}
                                    readOnly
                                />
                                <img
                                    className="calendar-icon"
                                    src={CalendarIcon}
                                />
                            </div>
                        }
                    />
                </Col>
                <Col md="3">
                    <Form.Label>Qtd. Parcela da entrada</Form.Label>
                    <Form.Control
                        value={qtd_parc_entrada}
                        onChange={(e) => handleChange("qtd_parc_entrada", e.target.value)}
                    />
                </Col>
                <Col md="3" controlId="formGridState" style={{ display: 'grid' }}>
                    <Form.Label>Data Primeira Parcela do Imovel</Form.Label>
                    <DatePicker
                        selected={data_prim_parcela || null}
                        onChange={(date: Date) => {
                            setDataPrimParcela(date);
                            handleChange('data_prim_parcela', date ? date.toISOString() : ''); // Garante que a string seja vazia se a data for nula
                        }}
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        fixedHeight
                        locale={pt}
                        customInput={
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <input
                                    type="text"
                                    placeholder="Data da Prim. Parcela do Imóvel"
                                    className="dateFormFinanceiro"
                                    value={data_prim_parcela ? data_prim_parcela.toLocaleDateString() : ''}
                                    readOnly
                                />
                                <img
                                    className="calendar-icon"
                                    src={CalendarIcon}
                                />
                            </div>
                        }
                    />
                </Col>
                <Col md="3">
                    <Form.Label>Saldo à Financiar</Form.Label>
                    <p>R$ {saldo_financiar}</p>
                </Col>
            </Row>
            <Row>
                <Col md="3">
                    <Form.Label>Tipo Contrato</Form.Label>
                    <p>{tipo_contrato_name}</p>
                </Col>
                <Col md="3">
                    <Form.Label>Corretor</Form.Label>
                    <p>{corretor?.nome_corretor}</p>
                </Col>
                <Col md="3" controlId="formGridState" style={{ display: 'grid' }}>
                    <Form.Label>Data do Proximo Reajuste</Form.Label>
                    <DatePicker
                        selected={prox_reajuste || null}
                        onChange={(date: Date) => {
                            setProxReajuste(date);
                            handleChange('prox_reajuste', date ? date.toISOString() : ''); // Garante que a string seja vazia se a data for nula
                        }}
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        fixedHeight
                        locale={pt}
                        customInput={
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <input
                                    type="text"
                                    placeholder="Data do Prox. Reajuste"
                                    className="dateFormFinanceiro"
                                    value={prox_reajuste ? prox_reajuste.toLocaleDateString() : ''}
                                    readOnly
                                />
                                <img
                                    className="calendar-icon"
                                    src={CalendarIcon}
                                />
                            </div>
                        }
                    />
                </Col>
                <Col md="3" controlId="formGridState" style={{ display: 'grid' }}>
                    <Form.Label>Data do Ult. Reajuste</Form.Label>
                    <DatePicker
                        selected={data_ult_reajuste || null}
                        onChange={(date: Date) => {
                            setUltReajuste(date);
                            handleChange('data_ult_reajuste', date ? date.toISOString() : ''); // Garante que a string seja vazia se a data for nula
                        }}
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        fixedHeight
                        locale={pt}
                        customInput={
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <input
                                    type="text"
                                    placeholder="Data do Ult. Reajuste"
                                    className="dateFormFinanceiro"
                                    value={data_ult_reajuste ? data_ult_reajuste.toLocaleDateString() : ''}
                                    readOnly
                                />
                                <img
                                    className="calendar-icon"
                                    src={CalendarIcon}
                                />
                            </div>
                        }
                    />
                </Col>
            </Row>
        </div>
    );
}

export default ContratosForm;
