import iconMenuTresPonto from '../../../../../assets/images/icon-menu-tres-pontos.png';
import { Button } from 'primereact/button';
import 'react-autocomplete-input/dist/bundle.css';
import { useState } from 'react';
import { useSidebarContext } from '../../../../contexts/SidebarContext';
import { Row, Col, Form, NavLink, Modal, Container } from 'react-bootstrap';
import iconFiltroAvancado from '../../../../../assets/images/icon-filtro-avancado.png';
import 'react-datepicker/dist/react-datepicker.css';
import ModalResetBoleto from './ModalResetBoleto';
import { ambiente } from '../../../../environments/environments-develop';
import TokenService from '../../../../services/Auth/token.service';
import { Calendar } from 'primereact/calendar';
import { addLocale } from 'primereact/api';
interface ContextSearchProps {
    header?: string;
    filtrosData: {
        numero?: string;
        data_venda_format?: string;
        status?: number;
        qtd_parcelas?: number;
        dataInicio?: string;
        dataFim?: string;
        nome?: string;
        tipo_consulta?: string;
    };
    onInputClearList: () => void;
    onInputChangeList: (field: string, value: any) => void;
    onInputChange: (field: string, value: any) => void;
}

function FiltroGestaoContratos(props: ContextSearchProps) {
    const {dataInicio, dataFim, numero, data_venda_format, status, qtd_parcelas, nome, tipo_consulta } = props.filtrosData || {};
    const { isVisible, updateVisibility } = useSidebarContext();
    const [showModal, setShowModal] = useState(false);
    const [showModalResetBoletos, setShowModalResetBoletos] = useState(false);

    addLocale('pt', {
        firstDayOfWeek: 0,
        dayNames: ['domingo', 'segunda-feira', 'terça-feira', 'quarta-feira', 'quinta-feira', 'sexta-feira', 'sábado'],
        dayNamesShort: ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sáb'],
        dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
        monthNames: ['janeiro', 'fevereiro', 'março', 'abril', 'maio', 'junho', 'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro'],
        monthNamesShort: ['jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez'],
        today: 'Hoje',
        clear: 'Limpar'
    });

    const toggleVisibility = () => {
        const newValue = !isVisible;
        updateVisibility(newValue);
    };

    const handleChange = (field: string, value: any) => {
        props.onInputChange(field, value);
    };

    const handleChangeList = (field: string, value: any) => {
        props.onInputChangeList(field, value);
    };

    const handleClearList = () => {
        setShowModal(false);
        props.onInputClearList();
    };

    const handleFilter = () => {
        setShowModal(false);
        if (numero) {
            handleChangeList('numero', numero);
        }
        if (nome) {
            handleChangeList('nome', nome);
        }
        if (data_venda_format) {
            handleChangeList('data_venda_format', data_venda_format);
        }
        if (status) {
            handleChangeList('status', status);
        }
        if (qtd_parcelas) {
            handleChangeList('qtd_parcelas', qtd_parcelas);
        }
        if (dataInicio) {
            handleChangeList('dataInicio', dataInicio);
        }
        if (dataFim) {
            handleChangeList('dataFim', dataFim);
        }
        if (tipo_consulta) {
            handleChangeList('tipo_consulta', tipo_consulta);
        }
    };

    return (
        <div className="contentSearch">
            <div className="contentSearch-header">
                <NavLink onClick={toggleVisibility}>
                    <img
                        className="menu-context"
                        src={iconMenuTresPonto}
                    />
                </NavLink>
                <Col className='text-center' style={{ fontSize: '20px', color: '#494949' }}>{props.header}</Col>
                {TokenService.getPerfil()?.data?.is_admin == true && ambiente === 'homolog' && (
                    <Button
                        rounded
                        outlined
                        severity="help"
                        style={{
                            borderRadius: '50px',
                            cursor: 'pointer',
                            transition: 'all 0.5s'
                        }}
                        onClick={() => setShowModalResetBoletos(true)}
                    >
                        Reset
                    </Button>
                )}
            </div>
            <div style={{ opacity: '34%' }} className="contentTraçoBodyHeader"></div>
            <div className="contentSearch-body">
                <div className='div-calendar'>
                    <Col md="2" style={{ marginRight: "8px" }}>
                        <Form.Label>N° Contrato</Form.Label>
                        <Form.Control
                            value={numero || ''}
                            onChange={(e) => handleChange("numero", e.target.value)}
                        />
                    </Col>
                    <Col md="4" style={{ marginRight: "8px" }}>
                        <Form.Label>Nome</Form.Label>
                        <Form.Control
                            value={nome ? nome : ''}
                            onChange={(e) => handleChange("nome", e.target.value)}
                        />
                    </Col>
                    <Col md='4' style={{ marginRight: "8px" }}>
                        <Form.Label>Status</Form.Label>
                        <Form.Select value={status || ''} onChange={(e) => handleChange('status', e.target.value)}>
                            <option>Selecione...</option>
                            <option value={"1"}>Faltando Acertar</option>
                            <option value={"2"}>Intermediação Acertada</option>
                            <option value={"3"}>Vigente</option>
                            <option value={"4"}>Suspenso</option>
                            <option value={"5"}>Jurídico/Distrato</option>
                            <option value={"6"}>Cancelado</option>
                            <option value={"7"}>Quitado</option>
                        </Form.Select>
                    </Col>
                </div>
                <ModalResetBoleto
                    showModalResetBoletos={showModalResetBoletos}
                    handleShowModalResetBoletos={setShowModalResetBoletos}
                />
                <div className='div-filtros-avancados' style={{ marginTop: '35px' }}>
                    <Button
                        style={{ height: '30px', borderRadius: '20px', padding: '5px 10px', marginRight: '5px', fontSize: '14px' }}
                        type="button"
                        onClick={() => { setShowModal(true) }}
                        rounded outlined severity="secondary">
                        <img style={{ width: '25px', height: '35px' }} src={iconFiltroAvancado} />
                    </Button>
                    <Button
                        onClick={handleFilter}
                        style={{ height: '30px', borderRadius: '20px', padding: '5px 10px', marginRight: '5px', fontSize: '14px' }}
                        type="button"
                        severity="success"
                        icon="pi pi-filter"
                        rounded>
                        <p style={{ marginLeft: '5px', marginTop: '13px' }}>Pesquisar</p>
                    </Button>
                    <Button
                        onClick={handleClearList}
                        style={{ height: '30px', borderRadius: '20px', padding: '5px 10px', marginRight: '5px', fontSize: '14px' }}
                        type="button"
                        severity="danger"
                        icon="pi pi-delete-left"
                        rounded>
                        <p style={{ marginLeft: '5px', marginTop: '13px' }}>Limpar</p>
                    </Button>
                </div>
            </div>
            <Modal
                show={showModal}
                size='lg'
                onHide={() => setShowModal(false)}
                className='modal-filtro-avancado'
            >
                <Modal.Header closeButton>
                    <Modal.Title>Filtros Avançados</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Row>
                            <Col md={3}>
                                <Form.Label>Tipo Consulta</Form.Label>
                                <Form.Select
                                    value={tipo_consulta || ''}
                                    onChange={(e) => handleChange("tipo_consulta", e.target.value)}
                                >
                                    <option>Selecione...</option>
                                    <option value={'data_venda'}> Data Venda</option>
                                    <option value={'prox_reajuste'}> Data Prox.Reajuste</option>
                                    <option value={'data_prim_parcela'}> Data Prim.Parcela</option>
                                    <option value={'data_ass_contrato'}> Data Ass.Contrato</option>
                                    <option value={'data_acerto'}> Data Acerto</option>
                                </Form.Select>
                            </Col>
                            <Col md={3}>
                                <Form.Label>Data Início</Form.Label>
                                <Calendar
                                    value={dataInicio ? new Date(dataInicio) : null}
                                    onChange={(event: any) => {
                                        const selectedDates = event.value;
                                        handleChange('dataInicio', selectedDates);
                                    }}
                                    placeholder="Data Inicio"
                                    showButtonBar
                                    selectionMode="single"
                                    dateFormat="dd/mm/yy"
                                    locale="pt"
                                    readOnlyInput
                                    style={{ textAlign: 'center', width: '100%', height: '29px' }}
                                    panelClassName="custom-calendar-panel"
                                />
                            </Col>
                            <Col md={3}>
                                <Form.Label>Data Fim</Form.Label>
                                <Calendar
                                    value={dataFim ? new Date(dataFim) : null}
                                    onChange={(event: any) => {
                                        const selectedDates = event.value;
                                        handleChange('dataFim', selectedDates);
                                    }}
                                    placeholder="Data Inicio"
                                    showButtonBar
                                    selectionMode="single"
                                    dateFormat="dd/mm/yy"
                                    locale="pt"
                                    readOnlyInput
                                    style={{ textAlign: 'center', width: '100%' }}
                                    panelClassName="custom-calendar-panel"
                                />
                            </Col>
                            <Col md={3}>
                                <Form.Label>Quantidade de Parcelas</Form.Label>
                                <Form.Control
                                    value={qtd_parcelas || ''}
                                    onChange={(e) => handleChange("qtd_parcelas", e.target.value)}
                                />
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        onClick={handleFilter}
                        style={{ marginTop: '3px', width: '100px', height: '30px', borderRadius: '5px', padding: '5px 10px', marginRight: '5px', fontSize: '14px', marginLeft: '10px' }}
                        type="button"
                        severity="success"
                        icon="pi pi-filter"
                        rounded>
                        <p style={{ marginLeft: '5px', marginTop: '15px' }}>Pesquisar</p>
                    </Button>
                    <Button
                        onClick={handleClearList}
                        style={{ marginTop: '3px', width: '100px', height: '30px', borderRadius: '5px', padding: '5px 10px', marginRight: '5px ', fontSize: '14px' }}
                        type="button"
                        severity="danger"
                        icon="pi pi-delete-left"
                        rounded>
                        <p style={{ marginLeft: '5px', marginTop: '15px' }}>Limpar</p>
                    </Button>
                </Modal.Footer>
            </Modal >
        </div >
    );
}

export default FiltroGestaoContratos;
