import { useAppContext } from '../../../contexts/AppContext';
import { useEffect, useState } from 'react';
import { AlertService } from '../../../components/AlertService';
import TokenService from '../../../services/Auth/token.service';
import FiltroProduto from './filtro';
import TableProduto from './Components/TableProduto';

function Produto() {
  const { showSubSidebar, setItemsBreadsCrumbs } = useAppContext();
  const [listFiltros, setListFiltros] = useState<any>({});
  const [filtros, setFiltros] = useState<string>();
  const [formBasico, setFormBasico] = useState<any>({});

  useEffect(() => {
    setItemsBreadsCrumbs([
      {
        label: 'Cemitério',
        url: '/cemiterio',
      },
      {
        label: 'Lista de Produtos',
        url: '/produto',
      },
    ]);
  }, []);
  async function montarFiltro(): Promise<void> {
    try {
      const consulta: any = {
        consulta: {
          order: "id;desc",
          filtro: {
            "n_serie": listFiltros ? listFiltros?.n_serie : null,
            "identificador": listFiltros ? listFiltros?.identificador : null,
            "tipo": listFiltros ? listFiltros?.tipo : null,
            "status": listFiltros ? listFiltros?.status : null,
            "acessorios": listFiltros ? listFiltros?.acessorios : null,
            "patrimonio": listFiltros ? listFiltros?.patrimonio : null,
            "descricao": listFiltros ? listFiltros?.descricao : null,
            "empreendimento_id": TokenService.getEmpreendimentoId() ? TokenService.getEmpreendimentoId().id : null
          }
        }
      };
      const queryParams: any = {};
      const camposEnviar = ['consulta'];
      camposEnviar.forEach((key: any) => {
        const value = consulta[key];
        if (value !== undefined && value !== null && value !== '') {
          if (key === 'consulta') {
            queryParams[key] = JSON.stringify(value);
          } else {
            queryParams[key] = value;
          }
        }
      });
      const queryString = Object.entries(queryParams)
        .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(String(value))}`)
        .join('&');
      setFiltros(queryString);
    } catch (error: any) {
      AlertService.error('Erro!', String(error.response?.data?.message) || 'Erro desconhecido');
    }
  }
  useEffect(() => {
    montarFiltro();
  }, [listFiltros]);
  const handleChange = (field: string, value: string) => {
    setFormBasico((prevGrupoData: any) => {
      return {
        ...prevGrupoData,
        [field]: value
      };
    });
  };
  const handleChangeList = (field: string, value: string) => {
    setListFiltros((prevGrupoData: any) => {
      return {
        ...prevGrupoData,
        [field]: value
      };
    });
  };
  const handleClearList = () => {
    setListFiltros(null);
    setFormBasico(null);
    montarFiltro();
  };

  return (
    <div className="body-content">
      <div
        className={`${showSubSidebar ? "container-section-header" : "container-section-header-none"}`}
      >
        <FiltroProduto
          onInputChange={(field: string, value: string) => handleChange(`${field}`, value)}
          onInputChangeList={(field: string, value: string) => handleChangeList(`${field}`, value)}
          onInputClearList={() => handleClearList()}
          filtrosData={formBasico}
          header={'Produtos'}
        />
      </div>
      <div
        className={`${showSubSidebar ? "container-section-body" : "container-section-body-none"}`}
      >
        <TableProduto
          key={filtros}
          urlRedirectCreated="/produto/store"
          urlSearch={`/api/v1/admin/cemiterio/produto?${filtros}`}
          urlSearchDataPut="/api/v1/admin/cemiterio/produto/"
          url_redirect_edit="/produto/update"
        />
      </div>
    </div>
  );
}
export default Produto;