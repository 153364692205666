import { AlertService } from "../../components/AlertService";
import { url } from "../../environments/environments-develop";
import api from "../api";
interface Time {
  loginTime: string;
}
const TokenService = {
  getLocalRefreshToken() {
    const userStr = localStorage.getItem("user");
    const user = userStr ? JSON.parse(userStr) : null;
    return user?.refreshToken;
  },

  getLocalAccessToken() {
    const userStr = localStorage.getItem("user");
    const user = userStr ? JSON.parse(userStr) : null;
    return user?.access_token;
  },

  updateLocalAccessToken(token: any) {
    let user = getUserFromLocalStorage();
    if (user) {
      user.access_token = token;
      localStorage.setItem("user", JSON.stringify(user));
    }
  },

  getUser() {
    return getUserFromLocalStorage();
  },

  getContextUser() {
    return getObjectFromLocalStorage("contextUser");
  },

  getFilialId() {
    return getObjectFromLocalStorage("filialId");
  },

  getPerfil() {
    return getObjectFromLocalStorage("perfil");
  },
  getFiliais() {
    return getObjectFromLocalStorage("filiais");
  },
  getEmpreendimentoId() {
    return getObjectFromLocalStorage("empreendimento_id");
  },
  getPermissoes() {
    return getObjectFromLocalStorage("permissoes");
  },

  getMenuSelect() {
    return getObjectFromLocalStorage("menu-select");
  },

  setMenuSelect(header: any) {
    localStorage.setItem("menu-select", JSON.stringify(header));
  },
  setUser(user: any) {
    localStorage.setItem("user", JSON.stringify(user));
  },

  setContextUser(context: any) {
    localStorage.setItem("contextUser", JSON.stringify(context));
  },

  setFilialId(filialId: any) {
    localStorage.setItem("filialId", JSON.stringify(filialId));
  },
  setEmpreendimento(empreendimento_id: any) {
    localStorage.setItem(
      "empreendimento_id",
      JSON.stringify(empreendimento_id)
    );
  },
  setFiliais(filiais: any) {
    localStorage.setItem("filiais", JSON.stringify(filiais));
  },

  setPerfil(perfil: any) {
    localStorage.setItem("perfil", JSON.stringify(perfil));
  },

  setPermissoes(permissoes: any) {
    localStorage.setItem("permissoes", JSON.stringify(permissoes));
  },

  removeUser() {
    localStorage.removeItem("user");
  },
  clearLocalStorage() {
    localStorage.clear();
  },

  changePermission() {
    alterPermission();
  },

  changePerfil() {
    alterPerfil();
  },

  atualizarFilial(filialId: number) {
    atualizarFilialContext(filialId);
  },

  getLocalTimeLogin() {
    return getObjectFromLocalStorage("loginTime");
  },
  setLoginTime() {
    const time = new Date().toISOString();
    localStorage.setItem("loginTime", JSON.stringify(time));
  },
};
async function atualizarFilialContext(filialId: number) {
  try {
    const contextFilialResponse = await api.get(
      `${url}/api/v1/admin/core/context-filiais/${filialId}`
    );
    if (contextFilialResponse.status === 200) {
      let responseObj: { data: any } = { data: contextFilialResponse.data };
      TokenService.setContextUser(contextFilialResponse);
      TokenService.changePerfil();
      TokenService.changePermission();
      const includeParam = "config_venda,config_carteira,pessoa.telefones";
      const retornoApi = await api.get(
        `${url}/api/v1/admin/vendas/empreendimentos?include=${includeParam}`
      );
      TokenService.setEmpreendimento(retornoApi.data.data[0]);
    } else {
      AlertService.error("erro", contextFilialResponse.statusText);
    }
    await window.location.reload();
  } catch (error: any) {
    AlertService.error("Erro ao atualizar a filial:", error);
  }
}

async function alterPerfil() {
  const includeParam = "pessoa.telefones,pessoa.enderecos";
  const userPerfilResponse = await api.get(
    `${url}/api/v1/admin/core/user/perfil?include=${includeParam}`
  );
  TokenService.setPerfil(userPerfilResponse.data);
}
function alterPermission() {
  const userPermissoes: Array<any> =
    TokenService.getPerfil().data?.user_permissoes?.data;
  let permissaoForm: Array<any> = [];

  for (const element of userPermissoes) {
    const existingPermissionIndex = permissaoForm.findIndex(
      (perm) => perm.name === element.grupo.data.nome
    );

    if (existingPermissionIndex !== -1) {
      permissaoForm[existingPermissionIndex].permission.push(
        element.permissoes.data.rotas.data
      );
    } else {
      permissaoForm.push({
        name: element.grupo.data.nome,
        permission: [element.permissoes.data.rotas.data],
      });
    }
  }
  TokenService.setPermissoes(permissaoForm);
}

function getUserFromLocalStorage() {
  const userStr = localStorage.getItem("user");
  return userStr ? JSON.parse(userStr) : null;
}

function getObjectFromLocalStorage(key: string) {
  const dataStr: any = localStorage.getItem(key);
  let retorno = dataStr !== "undefined" ? JSON.parse(dataStr) : null;
  return retorno;
}

export default TokenService;
