import React, { useEffect, useState } from 'react';
import { Col, Container, Form, Modal, Row } from 'react-bootstrap';
import { AlertService } from '../../../../../../components/AlertService';
import { url } from '../../../../../../environments/environments-develop';
import api from '../../../../../../services/api';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import pt from 'date-fns/locale/pt';
import CalendarIcon from '../../../../../../../assets/images/icon-calendar.png';
import { AutoComplete } from 'primereact/autocomplete';
import { searchInumado } from '../Service/VendaService';
import { Button } from 'primereact/button';

interface ModalInputInumadoProps {
    showModal: boolean;
    handleClose: () => void;
    gavetaSelect: any;
}

const ModalInputInumado: React.FC<ModalInputInumadoProps> = ({ showModal, handleClose, gavetaSelect }) => {
    const [show, setShow] = useState(showModal);
    const [formBasico, setFormBasico] = useState<any | null>(null);
    const [data_sepultamento, setData_sepultamento] = useState<any | null>(null);
    const [filteredInumado, setFilteredInumado] = useState<any>(null);
    const urlRedirectCreated = "/inumados/store";

    useEffect(() => {
        if (gavetaSelect) {
            setFormBasico({
                "imovel_id": gavetaSelect.imovel_id,
                "identificador_gaveta": gavetaSelect.identificador_gaveta,
            });
        }
    }, [gavetaSelect]);

    useEffect(() => {
        setShow(showModal);
    }, [showModal]);

    const handleSaveButton = async (): Promise<void> => {
        try {
            const response = await api.put(`${url}/api/v1/admin/cemiterio/imovel-gaveta/inputar-inumado`, formBasico);
            if (response.status === 200) {
                AlertService.sucess('Sucesso!', 'Salvo com sucesso!').then(() => {
                    setShow(false);
                    window.location.reload();
                });
            }
        } catch (error: any) {
            AlertService.infomessage('Erro!', String(error?.response?.data?.error?.message?.inumado_id)).then(() => {
                setShow(false);
            });
        }
    };

    const handleChange = (field: string, value: any) => {
        setFormBasico((prevForm: any) => ({
            ...prevForm,
            [field]: value
        }));
    };

    return (
        <Modal
            show={show}
            onHide={handleClose}
            size="lg"
            className='modal-input-inumado'
        >
            <Modal.Header closeButton>
                <Modal.Title>Adicionar Inumado ao Jazigo</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container>
                    <Row className='mb-3'>
                        <Col md="4">
                            <Form.Label>Inumado</Form.Label>
                            <AutoComplete
                                field="pessoa.nome"
                                style={{ width: '80%' }}
                                value={formBasico?.inumado || ''}
                                suggestions={filteredInumado}
                                completeMethod={(e) => searchInumado(e, 0, setFilteredInumado)}
                                onChange={(e) => {
                                    handleChange('inumado', e.value);
                                }}
                                onSelect={(e) => {
                                    handleChange('inumado', e.value);
                                    handleChange('inumado_id', e.value.id);
                                }}
                                dropdown
                            />
                            <Button
                                icon="pi pi-plus"
                                className="p-button-rounded p-button-success"
                                style={{
                                    borderRadius: '4px',
                                    marginLeft: '-1px',
                                    height: "32px",
                                    width: "26.43px",
                                    backgroundColor: "#10c117",
                                    borderColor: "#10c117",
                                }}
                                onClick={() => {
                                    window.location.href = urlRedirectCreated;
                                }}
                            />
                        </Col>
                        <Col md="6">
                            <Form.Label>Data Sepultamento</Form.Label>
                            <Form.Group controlId="formGridState">
                                <DatePicker
                                    selected={data_sepultamento ? new Date(data_sepultamento) : null}
                                    onChange={(date: Date) => {
                                        const formattedDate = date.toISOString().split('T')[0];
                                        setData_sepultamento(formattedDate);
                                        handleChange('data_sepultamento', formattedDate);
                                    }}
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    fixedHeight
                                    locale={pt}
                                    customInput={
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <input
                                                type="date"
                                                className="dateFormFinanceiro"
                                                value={data_sepultamento ? data_sepultamento : ''}
                                                readOnly
                                            />
                                            <img
                                                className="calendar-icon"
                                                src={CalendarIcon}
                                            />
                                        </div>
                                    }
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <div style={{ display: 'flex' }}>
                    <Button
                        className='button-save-or-update'
                        style={{ marginRight: '27px', borderRadius: '5px' }}
                        onClick={handleSaveButton}
                    >
                        Confirmar
                    </Button>
                </div>
            </Modal.Footer>
        </Modal>
    );
};

export default ModalInputInumado;
