import { Col, Form, InputGroup, Row } from "react-bootstrap";
import { InputNumber } from "primereact/inputnumber";

function CessaoDeDireitoShow(props: any) {
    const { data_label, numero, unidade, contrato_cliente, local_pagamento, valor_servico, observacao, vencimento_label } = props.formBasico || {};

    return (
        <div style={{ margin: '15px' }}>
            <Row className="mb-3">
                {props.formCompradores && props.formCompradores.map((comprador: any, key: any) => (
                    <Row key={comprador.cliente_id}>
                        <Col md="4">
                            <Form.Label>Nº {key + 1} | Comprador</Form.Label>
                            <p>{comprador?.cliente_name}</p>
                        </Col>
                        <Col md="3">
                            <Form.Label>Principal</Form.Label>
                            <p>{comprador?.principal === 1 ? 'Sim' : 'Não'}</p>
                        </Col>
                    </Row>
                ))}
            </Row>
            <Row className="mb-3">
                <Col md="2">
                    <Form.Label>Número de contrato </Form.Label>
                    <Form.Control
                        value={numero}
                        disabled
                    />
                </Col>
                <Col md="2">
                    <Form.Label>Unidade</Form.Label>
                    <Form.Control
                        value={unidade}
                        disabled
                    />
                </Col>
                <Col md="2">
                    <Form.Label>Local de Pagamento </Form.Label>
                    <Form.Control
                        value={local_pagamento}
                        disabled
                    />
                </Col>
                <Col md="2">
                    <Form.Label>Valor do Serviço</Form.Label>
                    <InputNumber
                        style={{ width: '100%', height: '38px' }}
                        value={valor_servico}
                        mode="currency"
                        currency="BRL"
                        locale="pt-BR"
                        disabled
                    />
                </Col>
                <Col md="2" controlId="formGridState" style={{ display: 'grid' }}>
                    <Form.Label>Data do processo</Form.Label>
                    <Form.Control
                        value={data_label}
                        disabled
                    />
                </Col>
                <Col md="2">
                    <Form.Label>Vencimento Taxa Temporária</Form.Label>
                    <Form.Control
                        value={vencimento_label}
                        disabled
                    />
                </Col>
            </Row>
            <Row className="mb-3">
                <Col md="12">
                    <Form.Label>Observação</Form.Label>
                    <Form.Control
                        as={'textarea'}
                        value={observacao}
                        disabled
                    />
                </Col>
            </Row>
        </div>
    );
}

export default CessaoDeDireitoShow;
