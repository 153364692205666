// pessoaModel.ts

import { Deserializable } from "../../../../core/interfaces/deserializable.model";
import { EnderecoModel } from "../../../../core/models/EnderecoModel";
import { TelefoneModel } from "../../../../core/models/TelefoneModel";
import { BaseModel } from "./BaseModel";

const FEMININO = 0;
const MASCULINO = 1;

const SOLTEIRO = 0;
const CASADO = 1;
const VIUVO = 2;
const SEPARADO = 3;
const DIVORCIADO = 4;
const UNIAO_ESTAVEL = 5;

const COMUNHAO_PARCIAL = 0;
const COMUNHAO_UNIVERSAL = 1;
const SEPARACAO_TOTAL = 2;
const NAO_INFORMADO = 3;
export interface IPessoa {
  nome: string;
  email: string;
  cpf_cnpj: string;
  estado_civil: number;
  regime_uniao: number;
  data_nascimento: Date | null;
  sexo: number;
  filiacao_mae: string;
  razao_social: string;
  inscricao_municipal: string;
  inscricao_estadual: string;
  telefones: TelefoneModel[];
  data_fundacao: Date | null;
  descricao: string;
  created_at: Date | null;
  updated_at: Date | null;
  enderecos: EnderecoModel[];
  naturalidade_id: number | null;
  naturalidade_label: string;
  numero_cns: string;
  rg: string;
}
export class PessoaModel extends BaseModel implements Deserializable {
  nome: string = "";
  email: string = "";
  cpf_cnpj: string = "";
  estado_civil: number = SOLTEIRO;
  regime_uniao: number = COMUNHAO_PARCIAL;
  data_nascimento: Date | null = null;
  sexo: number = FEMININO;
  filiacao_mae: string = "";
  razao_social: string = "";
  inscricao_municipal: string = "";
  inscricao_estadual: string = "";
  telefones: TelefoneModel[] = [];
  data_fundacao: Date | null = null;
  descricao: string = "";
  created_at: Date = new Date();
  updated_at: Date = new Date();
  enderecos: EnderecoModel[] = [];
  naturalidade_id: number | null = null;
  naturalidade_label: string = "";
  numero_cns: string = "";
  rg: string = "";

  deserialize(input: any): this {
    if (!Array.isArray(input)) {
      this.assignItems(input);
      Object.assign(this, input);
      return this;
    }
    const array: PessoaModel[] = [];
    input.forEach((item, key) => {
      this.assignItems(item);
      array.push(Object.assign(new PessoaModel(), item));
    });
    return array as any;
  }

  private assignItems(input: any): void {
    if (input && typeof input === "object") {
      if (input.hasOwnProperty("telefones")) {
        input.telefones = new TelefoneModel().deserialize(input.telefones.data);
      }

      if (input.hasOwnProperty("enderecos")) {
        input.enderecos = new EnderecoModel().deserialize(input.enderecos.data);
      }
    }
  }

  static getPessoaSexo(tipo: number): string {
    switch (tipo) {
      case FEMININO:
        return "Feminino";
      case MASCULINO:
        return "Masculino";
      default:
        return tipo.toString();
    }
  }

  static getSexo(): { label: string; value: number }[] {
    return [
      { label: "Feminino", value: FEMININO },
      { label: "Masculino", value: MASCULINO },
    ];
  }

  static getPessoaEstadoCivil(tipo: number): string {
    switch (tipo) {
      case SOLTEIRO:
        return "Solteiro(a)";
      case CASADO:
        return "Casado(a)";
      case VIUVO:
        return "Viúvo(a)";
      case SEPARADO:
        return "Separado(a)";
      case DIVORCIADO:
        return "Divorciado(a)";
      case UNIAO_ESTAVEL:
        return "União Estável";
      default:
        return tipo.toString();
    }
  }

  static getEstadoCivil(): { label: string; value: number }[] {
    return [
      { label: "Solteiro(a)", value: SOLTEIRO },
      { label: "Casado(a)", value: CASADO },
      { label: "Viúvo(a)", value: VIUVO },
      { label: "Separado(a)", value: SEPARADO },
      { label: "Divorciado(a)", value: DIVORCIADO },
      { label: "União Estável", value: UNIAO_ESTAVEL },
    ];
  }

  static getPessoaRegimeUniao(tipo: number): string {
    switch (tipo) {
      case COMUNHAO_PARCIAL:
        return "Comunhão Parcial de Bens";
      case COMUNHAO_UNIVERSAL:
        return "Comunhão Universal de Bens";
      case SEPARACAO_TOTAL:
        return "Separação Total de Bens";
      case NAO_INFORMADO:
        return "Não Informado";
      default:
        return tipo.toString();
    }
  }

  static getRegimeUniao(): { label: string; value: number }[] {
    return [
      { label: "Comunhão Parcial de Bens", value: COMUNHAO_PARCIAL },
      { label: "Comunhão Universal de Bens", value: COMUNHAO_UNIVERSAL },
      { label: "Separação Total de Bens", value: SEPARACAO_TOTAL },
      { label: "Não Informado", value: NAO_INFORMADO },
    ];
  }
}
