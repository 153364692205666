import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { Button } from 'primereact/button';
import 'react-datepicker/dist/react-datepicker.css';
import { AlertService } from '../../../../components/AlertService';
import api from '../../../../services/api';
import { url } from '../../../../environments/environments-develop';
import FileUploaderWithModal from '../../../../services/FileUploaderForm';

interface ModalMemorialSheetProps {
    showModal: boolean;
    onHide: () => void;
}

const ModalMemorialSheet: React.FC<ModalMemorialSheetProps> = ({
    showModal,
    onHide,
}) => {
    const [bodyRequest, setBodyRequest] = useState<any>({});
    async function handleSaveButton(): Promise<void> {
        try {
            const response = await api.post(`${url}/api/v1/admin/vendas/import-imovel`, bodyRequest);
            localStorage.removeItem("excelProcessado");
            if (response.status === 200) {
                await AlertService.sucess('Sucesso!', 'Salvo com sucesso!');
                onHide();
                window.location.reload();
            }
        } catch (error: any) {
            await AlertService.infomessage('Erro!', String(error?.response?.data?.message || error.message));
            onHide();
            window.location.reload();
        }
    };
    
    const processFile = (data: any | null) => {
        setBodyRequest(data)
    };

    return (
        <Modal
            show={showModal}
            onHide={onHide}
            style={{ width: '1200px !important' }}
            backdrop="static"
            keyboard={false}
            size="xl"
        >
            <Modal.Header closeButton>
                <Modal.Title>Importação de Memorial Descritivo</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <FileUploaderWithModal onFileUpload={processFile} />
            </Modal.Body>
            <Modal.Footer>
                <Button style={{ borderRadius:'10px'}} severity="secondary" onClick={onHide}>
                    Close
                </Button>
                <Button  style={{ borderRadius:'10px'}} className="button-save-or-update" onClick={() => handleSaveButton()} severity="success">Confirmar e Importar</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ModalMemorialSheet;
