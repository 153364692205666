import { Button, Modal } from 'react-bootstrap';
import api from '../../../../services/api';
import { useEffect, useState } from 'react';
import { AlertService } from '../../../../components/AlertService';
import { url } from '../../../../environments/environments-develop';
import AnexoForm from './AnexoForm';

interface ModalAnexoProps {
    showModalAnexo: boolean;
    handleShowModalAnexo: (show: boolean) => void;
    selectedNodeKeys: any;
}

const ModalAnexo: React.FC<ModalAnexoProps> = (props) => {
    const [dados, setDados] = useState({});

    // async function InitContrato() {
    //     if (!props.selectedNodeKeys) {
    //         return;
    //     }
    //     try {
    //         const response = await api.get(`${url}/api/v1/admin/vendas/contrato/${props.selectedNodeKeys}?include=anexo`);
    //         setDados((prevGrupoData) => {
    //             const dadosAtualizados = {
    //                 ...response.data,
    //                 ['anexo']: response.data?.anexo,
    //                 ['cliente_compradores']: response.data?.cliente_compradores?.data,
    //             };
    //             return dadosAtualizados;
    //         });
    //     } catch (error) {
    //         AlertService.error('Erro', 'Ocorreu um erro ao buscar os detalhes do contrato: ' + String(error));
    //     }
    // }

    // useEffect(() => {
    //     InitContrato();
    // }, [props.selectedNodeKeys]);

    const handleChange = (field: string, value: string) => {
        setDados((prevGrupoData: any) => {
            return {
                ...prevGrupoData,
                [field]: value
            };
        });
    };

    async function saveChanges() {
        const payload = {
            ...dados,
        };
        try {
            const response = await api.put(`${url}/api/v1/admin/vendas/contrato/${props.selectedNodeKeys}`, payload);
            if (response.status === 200) {
                AlertService.sucess('Sucesso', 'Dados atualizados com sucesso!').then(() => {
                    props.handleShowModalAnexo(false);
                    window.location.reload()
                });
            }
        } catch (error) {
            AlertService.error('Erro', 'Não foi possível atualizar os dados: ' + String(error));
        }
    };

    return (
        <Modal show={props.showModalAnexo} size='xl' onHide={() => props.handleShowModalAnexo(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Incluir Anexo</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <AnexoForm Data={dados} onInputChange={handleChange} />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="success" onClick={saveChanges}>Confirmar</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ModalAnexo;
