import { getStatusColorCard } from "../Service/ContasPagarService";

export const togglerStatus = (node: any) => {
    if (!node) {
        return null;
    }

    return (
        <div style={{ display: 'flex' }}>
            {node && (<div style={{ width: '5px', position: 'relative', top: '-5px', display: 'flex', flex: '1', alignItems: 'center', backgroundColor: `${getStatusColorCard(node?.situacao_pagamento)}`, margin: '3px', border: `2px solid ${getStatusColorCard(node?.situacao_pagamento)}`, borderRadius: '12px', marginTop: '12px', boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.32)', padding: '8px' }}></div>)}
        </div>
    );
};