import { isArray } from "util";
import { Deserializable } from "../interfaces/deserializable.model";

const RESIDENCIAL = 0;
const COMERCIAL = 1;
const CONTATO = 2;
const COBRANCA = 3;

export class EnderecoModel implements Deserializable {
  logradouro: string = "";
  cep: string = "";
  numero: string = "";
  complemento: string = "";
  cidade_id: number | null = null;
  bairro_id: number | null = null;
  tipo_endereco: string = "";

  deserialize(input: any): this {
    if (!isArray(input)) {
      Object.assign(this, input);
      return this;
    }
    const array: EnderecoModel[] = [];
    input.forEach((item, key) => {
      array.push(Object.assign(new EnderecoModel(), item));
    });
    return array as any;
  }

  static getTipoEndereco() {
    return [
      { label: "Residencial", value: RESIDENCIAL },
      { label: "Comercial", value: COMERCIAL },
      { label: "Contato", value: CONTATO },
      { label: "Cobrança", value: COBRANCA },
    ];
  }
}
