import React, { useState, useEffect, useRef } from 'react';
import "primereact/resources/themes/lara-light-cyan/theme.css";
import { TreeTable } from 'primereact/treetable';
import { Column } from 'primereact/column';
import { MDBCol, MDBPageItem, MDBPageNav, MDBPagination, MDBRow } from 'mdbreact';
import { classNames } from 'primereact/utils';
import 'primereact/resources/themes/lara-light-cyan/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { useAppContext } from '../../../../contexts/AppContext';
import { Skeleton } from 'primereact/skeleton';
import { Toast } from 'primereact/toast';
import { Tooltip } from 'primereact/tooltip';
import { SpeedDial } from 'primereact/speeddial';
import { url } from '../../../../environments/environments-develop';
import api from '../../../../services/api';
import { AlertService } from '../../../../components/AlertService';
import ModalConfirmarExclusao from './ModalConfirmarExclusão';

interface TableComponentsArvoreListProps {
    urlSearch: string;
    urlSearchDataPut: string;
    url_redirect_edit: string;
    urlRedirectCreated: string;
}

interface TreeNode {
    key: string;
    data: {
        id: number;
        name: string;
    };
    children?: TreeNode[];
}

const TableComponentsArvoreList: React.FC<TableComponentsArvoreListProps> = ({ urlRedirectCreated, urlSearch, urlSearchDataPut, url_redirect_edit }) => {
    const [currentPage, setCurrentPage] = useState(0);
    const [nodes, setNodes] = useState<TreeNode[]>([]);
    const [titles, setTitles] = useState<TreeNode[]>([]);
    const [totalPages, setTotalPages] = useState(0);
    const { setUrl_button_create } = useAppContext();
    const [isLoading, setLoading] = useState(false);
    const [showModalConfirmarExclusao, setShowModalConfirmarExclusao] = useState(false);
    const [selectedData, setSelectedData] = useState<any>(null);

    useEffect(() => {
        setUrl_button_create(urlRedirectCreated);
    }, [])
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const includeParam = 'tipo_label,filhos.filhos.filhos.filhos.filhos.filhos'
                const response = await api.get(`${url}${urlSearch}&include=${includeParam}&page=${currentPage + 1}`);
                if (response.status === 200) {
                    const titles = response.data.headers.titles;
                    setTitles(titles)
                    const data = response.data.data;
                    const totalPages = response.data.meta.pagination.total_pages;
                    setTotalPages(totalPages);
                    const buildTreeNode = (item: any): TreeNode | null => {
                        if (item) {
                            return {
                                key: item.id.toString(),
                                data: item,
                                children: item.filhos?.data?.map((child: any) => buildTreeNode(child)),
                            };
                        }
                        return null;
                    };
                    const nodesData: TreeNode[] = data
                        ? data.reduce((acc: TreeNode[], item: any) => {
                            const treeNode = buildTreeNode(item);
                            if (treeNode) {
                                acc.push(treeNode);
                            }
                            return acc;
                        }, [])
                        : [];
                    setNodes(nodesData);
                    setLoading(false);
                }
            } catch (error: any) {
                let mensagens: string[] = [];
                mensagens.push(error?.response?.data?.error?.message);
                AlertService.error('Erro!', String(mensagens));
            }
        };

        fetchData();
    }, [currentPage, urlSearch]);

    const renderPageNumbers = () => {
        const pageNumbers = [];
        for (let i = 0; i < totalPages; i++) {
            if (i < 3 || i === totalPages - 1 || (i >= currentPage - 1 && i <= currentPage + 1)) {
                pageNumbers.push(
                    <MDBPageItem key={i} active={i === currentPage}>
                        <MDBPageNav className="page-link" onClick={() => setCurrentPage(i)}>
                            <span key={i} onClick={() => setCurrentPage(i)} style={{ cursor: 'pointer', margin: '0 5px', fontWeight: i === currentPage ? 'bold' : 'normal' }}>
                                {i + 1}
                            </span>
                        </MDBPageNav>
                    </MDBPageItem>

                );
            }
        }
        return pageNumbers;
    };

    const handleOpenModal = (data: any) => {
        setSelectedData(data);
        setShowModalConfirmarExclusao(true);
    };

    const actionTemplate = (
        value: any,
        url_redirect_edit: any,
    ) => {
        const data = value.data ? value.data : value;
        const toast = useRef<Toast>(null);
        const commonStyle = {
            width: '35px',
            height: '35px',
            top: '0px',
            color: '#fff'
        };
        const items: any[] = [
            {
                label: 'Editar',
                icon: 'pi pi-pencil',
                style: { backgroundColor: '#007bff', color: '#fff', top: '0px', width: '35px', height: '35px', display: `${data.situacao_pagamento === 1 ? 'none' : 'flex'}` },
                command: () => {
                    setSessionEditData(value.id, url_redirect_edit)
                },
                tooltip: 'Editar'
            },
            {
                label: 'Criar Segmento',
                icon: 'pi pi-plus',
                style: { backgroundColor: '#289b1e', color: '#fff', top: '0px', width: '35px', height: '35px', display: `${data.situacao_pagamento === 1 ? 'none' : 'flex'}` },
                command: () => {
                    sessionCreateData(value, urlRedirectCreated)
                },
                tooltip: 'Criar Segmento'
            },
            {
                label: 'Excluir',
                icon: 'pi pi-trash',
                style: { backgroundColor: '#ff0000', color: '#fff', top: '0px', width: '35px', height: '35px', display: `${data.situacao_pagamento === 1 ? 'none' : 'flex'}` },
                command: () => {
                    handleOpenModal(data);
                },
                tooltip: 'Excluir'
            }
        ];
        useEffect(() => {
            items.forEach((item, index) => {
                const element = document.querySelectorAll('.p-speeddial-action')[index];
                if (element) {
                    element.setAttribute('data-pr-tooltip', item.tooltip);
                    element.setAttribute('data-pr-position', 'left');
                }
            });
        }, [items]);
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
                <Toast ref={toast} />
                <Tooltip target="[data-pr-tooltip]" />
                <div style={{ position: 'relative' }}>
                    <SpeedDial
                        model={items}
                        radius={45}
                        showIcon="pi pi-bars"
                        hideIcon="pi pi-times"
                        type="linear"
                        direction="left"
                        style={{ top: 'calc(50% - 1rem)', right: -5 }}
                        buttonClassName="button-speed-dial"
                    />
                </div>
            </div>
        );
    };

    const togglerTemplate = (node: any, options: any) => {
        if (!node) {
            return;
        }
        const expanded = options.expanded;
        const iconClassName = classNames('p-treetable-toggler-icon pi pi-fw', {
            'pi-caret-right': !expanded,
            'pi-caret-down': expanded
        });
        return (
            <button type="button" className="p-treetable-toggler p-link" style={options.buttonStyle} tabIndex={-1} onClick={options.onClick}>
                <span className={iconClassName} aria-hidden="true"></span>
            </button>
        );
    };

    const sessionCreateData = async (value: any, urlCreated: any) => {
        try {
            const data = {
                codigoConcat: value.codigo,
                codigo: '',
                nomeSegmento: value.nome,
                nome: '',
                tipo: '',
                parent_id: value.id,
                status: true,
                recebe_lancamento: false,
                contabil: false,
            };
            await localStorage.setItem('dadosEdicao', JSON.stringify(data));
            window.location.href = urlCreated;
        } catch (error: any) {
            let mensagens: string[] = [];
            mensagens.push(error?.response?.data?.error?.message);
            AlertService.error('Erro!', String(mensagens));
        }
    };


    const setSessionEditData = async (value: any, urlEdit: any) => {
        try {
            const includeParam = 'config_venda,config_carteira,pessoa.telefones,pessoa.enderecos,grupo_permissoes';
            const response = await api.get(`${url}${urlSearchDataPut}${value}?include=${includeParam}`);
            if (response.status === 200) {
                const data = {
                    id: response.data.id,
                    codigoPai: response.data.codigo_pai,
                    codigo: response.data.codigo,
                    nomePai: response.data.nome_pai,
                    nome: response.data.nome,
                    tipo: response.data.tipo,
                    parent_id: response.data.parent_id,
                    status: response.data.status,
                    recebe_lancamento: response.data.recebe_lancamento,
                    contabil: response.data.contabil,
                };
                await localStorage.setItem('dadosEdicao', JSON.stringify(data));
                window.location.href = urlEdit;
            }
        } catch (error: any) {
            let mensagens: string[] = [];
            mensagens.push(error?.response?.data?.error?.message);
            AlertService.error('Erro!', String(mensagens));
        }
    };

    return (
        <div className="card">
            <TreeTable
                stripedRows
                togglerTemplate={togglerTemplate}
                tableStyle={{ minWidth: '50rem' }} value={nodes}
                emptyMessage={isLoading ? (<Skeleton />) : (<span style={{ display: 'flex', justifyContent: 'center', fontStyle: 'italic' }}>Nenhum item encontrado.</span>)}
            >
                <Column
                    style={{ textAlign: 'right', width: '7%' }}
                    key={'codigo'}
                    field={'codigo'}
                    expander={true}
                    headerClassName="righted-header"
                    header={<div className="righted-content">Codigo</div>}
                    body={isLoading && (<Skeleton />)}
                />
                <Column
                    style={{ textAlign: 'left' }}
                    key={'nome'}
                    field={'nome'}
                    headerClassName="lefted-header"
                    header={<div className="lefted-content">Nome</div>}
                    body={isLoading && (<Skeleton />)}
                />
                <Column
                    style={{ textAlign: 'left', width: '10%' }}
                    key={'tipo_name'}
                    field={'tipo_name'}
                    headerClassName="lefted-header"
                    header={<div className="lefted-content">Tipo</div>}
                    body={isLoading && (<Skeleton />)}
                />
                <Column
                    style={{ textAlign: 'left', width: '10%' }}
                    key={'status_label'}
                    field={'status_label'}
                    headerClassName="lefted-header"
                    header={<div className="lefted-content">Status</div>}
                    body={isLoading && (<Skeleton />)}
                />
                <Column
                    style={{ textAlign: 'left', width: '15%' }}
                    key={'recebe_lancamento_label'}
                    field={'recebe_lancamento_label'}
                    headerClassName="lefted-header"
                    header={<div className="lefted-content">Recebe Lançamento</div>}
                    body={isLoading && (<Skeleton />)}
                />
                <Column
                    style={{ textAlign: 'left', width: '7%' }}
                    body={(e) => actionTemplate(
                        e.data,
                        url_redirect_edit,
                    )}
                    header={<div className="lefted-content">Menu</div>}
                    headerClassName="lefted-header"
                />
            </TreeTable>
            <ModalConfirmarExclusao
                selectedData={selectedData}
                showModalConfirmarExclusao={showModalConfirmarExclusao}
                setShowModalConfirmarExclusao={setShowModalConfirmarExclusao}
            />
            <div className="pagination-footer">
                <MDBRow>
                    <MDBCol>
                        <MDBPagination circle>
                            <MDBPageItem disabled={currentPage === 0}>
                                <MDBPageNav className="page-link" aria-label="Previous" onClick={() => setCurrentPage(currentPage - 1)}>
                                    <span aria-hidden="true">&laquo;</span>
                                </MDBPageNav>
                            </MDBPageItem>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                {renderPageNumbers()}
                            </div>
                            <MDBPageItem disabled={currentPage === totalPages - 1}>
                                <MDBPageNav className="page-link" aria-label="Next" onClick={() => setCurrentPage(currentPage + 1)}>
                                    <span aria-hidden="true">&raquo;</span>
                                </MDBPageNav>
                            </MDBPageItem>
                        </MDBPagination>
                    </MDBCol>
                </MDBRow>
            </div>

        </div>
    );
};

export default TableComponentsArvoreList;
