import { ClienteModel } from "../../Pessoas/Models/ClienteModel";
import { PessoaModel } from "../../Pessoas/Models/PessoaModel";
import { JazigoModel } from "./JazigoModel";
import { ProfissaoModel } from "./ProfissaoModel";
import { ReligiaoModel } from "./ReligiaoModel";

// Inumado Model
  export interface IInumado {
    numero_interno: string | null;
    numero_exumacao: string | null;
    tipo_servico: string | null;
    corpo_embalsamado: string | null;
    causa_mortis: string | null;
    local_falecimento: string | null;
    data_falecimento: string | null;
    hora_falecimento: string | null;
    data_sepultamento: string | null;
    hora_sepultamento: string | null;
    nome: string | null;
    situacao: string | null;
    classificacao: string | null;
    cliente_id: number | null;
    cliente: ClienteModel | ClienteModel[] | null;
    jazigo_id: number | null;
    jazigo: JazigoModel | JazigoModel[] | null;
    origem: string | null;
    plantonista: string | null;
    guia: string | null;
    idade: string | null;
    peso: string | null;
    cpf_cnpj: string | null;
    rg: string | null;
    profissao_id: number | null;
    profissao: ProfissaoModel | ProfissaoModel[] | null;
    religiao_id: number | null;
    religiao: ReligiaoModel | ReligiaoModel[] | null;
    numero_registro_proprio: string | null;
    folha_registro_proprio: string | null;
    declarante_nome: string | null;
    declarante_cpf: string | null;
    declarante_rg: string | null;
    translado_id: number | null;
    sala_velorio_id: number | null;
    cartorio: string | null;
    n_cartorio: string | null;
    folha_cartorio: string | null;
    livro_cartorio: string | null;
    funeraria: string | null;
    empreendimento_id: number | null;
    filial_id: number | null;
    id?: number | null;

    inumado_pessoa_id: number | null;
    pessoa: PessoaModel | PessoaModel[] | null;
  }

  export class InumadoModel implements IInumado {
    numero_interno: string | null = null;
    numero_exumacao: string | null = null;
    nome: string | null = null;
    tipo_servico: string | null = null;
    corpo_embalsamado: string | null = null;
    causa_mortis: string | null = null;
    local_falecimento: string | null = null;
    data_falecimento: string | null = null;
    hora_falecimento: string | null = null;
    data_sepultamento: string | null = null;
    hora_sepultamento: string | null = null;
    situacao: string | null = null;
    classificacao: string | null = null;
    cliente_id: number | null = null;
    cliente: ClienteModel | ClienteModel[] | null = null;
    jazigo_id: number | null = null;
    jazigo: JazigoModel | JazigoModel[] | null = null;
    origem: string | null = null;
    plantonista: string | null = null;
    guia: string | null = null;
    idade: string | null = null;
    peso: string | null = null;
    cpf_cnpj: string | null = null;
    rg: string | null = null;
    profissao_id: number | null = null;
    profissao: ProfissaoModel | ProfissaoModel[] | null = null;
    religiao_id: number | null = null;
    religiao: ReligiaoModel | ReligiaoModel[] | null = null;
    numero_registro_proprio: string | null = null;
    folha_registro_proprio: string | null = null;
    declarante_nome: string | null = null;
    declarante_cpf: string | null = null;
    declarante_rg: string | null = null;
    translado_id: number | null = null;
    sala_velorio_id: number | null = null;
    cartorio: string | null = null;
    n_cartorio: string | null = null;
    folha_cartorio: string | null = null;
    livro_cartorio: string | null = null;
    funeraria: string | null = null;
    empreendimento_id: number | null = null;
    filial_id: number | null = null;
    id?: number | null = null;
    inumado_pessoa_id: number | null = null;
    pessoa: PessoaModel | PessoaModel[] | null = null;

    deserialize(input: any): this {
      Object.assign(this, input);
      if (input?.religiao?.data) {
        this.religiao = new ReligiaoModel().deserialize(input.religiao.data);
      } else if (input?.religiao) {
        this.religiao = new ReligiaoModel().deserialize(input.religiao);
      }

      if (input?.profissao?.data) {
        this.profissao = new ProfissaoModel().deserialize(input.profissao.data);
      } else if (input?.profissao) { this.profissao = new ProfissaoModel().deserialize(input.profissao); }

      if (input?.jazigo?.data) {
        this.jazigo = new JazigoModel().deserialize(input.jazigo.data);
      } else if (input?.jazigo?.data) {
        this.jazigo = new JazigoModel().deserialize(input.jazigo);
      }

      if (input?.pessoa?.data) {
        this.pessoa = new PessoaModel().deserialize(input.pessoa.data);
      } else if (input?.pessoa) {
        this.pessoa = new PessoaModel().deserialize(input.pessoa);
      }

      if (input?.cliente?.data) {
        this.cliente = new ClienteModel().deserialize(input.cliente.data);
      } else if (input?.cliente) {
        this.cliente = new ClienteModel().deserialize(input.cliente);
      }
      return this;
    }
  }
