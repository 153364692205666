import { AlertService } from "../../../../components/AlertService";
import TokenService from "../../../../services/Auth/token.service";
import ClienteService from "../../../Pessoas/Services/ClienteService";
import InumadoService from "../../Services/InumadoService";

interface Consulta {
  order?: string;
  filtro?: any;
}
const consulta: Consulta = {
  order: "id;desc",
};

export const loadData = (
  listFiltros: any,
  currentPage: any,
  setLoading: (value: any) => void,
  setNodes: (value: any) => void,
  updateTitles: (titles: string[]) => void,
  updatePagination: (totalPages: number) => void,
  setShowModalFiltro: (value: any) => void
) => {
  setLoading(true);
  const form = {
    nome: listFiltros ? listFiltros?.nome : null,
    numero_interno: listFiltros ? listFiltros?.numero_interno : null,
    numero_exumacao: listFiltros ? listFiltros?.numero_exumacao : null,
    data_falecimento: listFiltros ? listFiltros?.data_falecimento : null,
    data_sepultamento: listFiltros ? listFiltros?.data_sepultamento : null,
    empreendimento_id: TokenService.getEmpreendimentoId()
      ? TokenService.getEmpreendimentoId()?.id
      : null,
  };

  const filtro = Object.fromEntries(
    Object.entries(form).filter(
      ([_, value]) => value !== null && value !== "" && value !== undefined
    )
  );

  if (consulta) {
    consulta.filtro = filtro;
  }
  InumadoList(
    consulta,
    currentPage,
    setLoading,
    setNodes,
    updateTitles,
    updatePagination,
    setShowModalFiltro
  );
};

export const InumadoList = (
  filtros: any,
  currentPage: any,
  setLoading: (value: any) => void,
  setNodes: (value: any) => void,
  updateTitles: (titles: string[]) => void,
  updatePagination: (totalPages: number) => void,
  setShowModalFiltro: any | null
) => {
  InumadoService.listAll(
    {
      include: "inumado,religiao,profissao,jazigo,translado,cliente,pessoa",
      totalitems: 8,
      consulta: JSON.stringify(filtros),
      page: currentPage + 1,
    },
    null,
    updateTitles,
    updatePagination
  ).subscribe(
    (response) => {
      setNodes(response);
      setShowModalFiltro(false);
      setLoading(false);
    },
    (error) => {
      let mensagens: string[] = [];
      mensagens.push(error?.response?.data?.error?.message);
      AlertService.error("Erro!", String(mensagens));
    }
  );
};

export const setSessionEditData = (value: any, url_redirect_edit: any) => {
  InumadoService.get(value, {
    include: "religiao,profissao,jazigo,translado,cliente,pessoa",
  }).subscribe(
    (response) => {
      localStorage.setItem("dadosEdicao", JSON.stringify(response));
      window.location.href = url_redirect_edit;
    },
    (error) => {
      let mensagens: string[] = [];
      mensagens.push(error?.response?.data?.error?.message);
      AlertService.error("Erro!", String(mensagens));
    }
  );
};

export const handleOpenModal = (
  row: any,
  setSelectedRow: (value: any) => void,
  setShowModal: (value: any) => void
) => {
  setSelectedRow(row);
  setShowModal(true);
};

export const searchCliente = (
  query: any,
  setFilteredCliente: (value: any) => void
) => {
  const form = { nome_cliente: query };
  const filtro = Object.fromEntries(
    Object.entries(form).filter(
      ([_, value]) => value !== null && value !== "" && value !== undefined
    )
  );

  if (consulta) {
    consulta.filtro = filtro;
  }

  if (query.length > 2) {
    setTimeout(() => {
      ClienteService.list({
        consulta: JSON.stringify(consulta),
      }).subscribe(
        (response) => {
          setFilteredCliente(response);
        },
        (error) => {
          let mensagens: string[] = [];
          mensagens.push(error?.response?.data?.error?.message);
          AlertService.error(
            "Erro!",
            "Não foi possível buscar os clientes: " +
              error?.response?.data?.error?.message
          );
        }
      );
    }, 600);
  }
};
