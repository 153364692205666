import { AlertService } from "../../../../components/AlertService";
import { showToast } from "../../../../components/ToastComponent";
import TokenService from "../../../../services/Auth/token.service";
import MovCaixaService from "../../../Financeiro/Services/MovCaixaService";
import PessoaService from "../../../Pessoas/Services/PessoaService";

interface Consulta {
  order?: string;
  filtro?: any;
}
const consulta: Consulta = {
  order: "financeiro.lancamento_financeiros.data_vencimento;asc",
};

export const loadData = (
  listFiltros: any,
  itensPorPagina: any,
  currentPage: any,
  setNodes: (value: any) => void,
  updateTitles: (titles: string[]) => void,
  updatePagination: (totalPages: number) => void,
  setNomeCliente: (value: any) => void,
  setShowModalListLancamento: (value: any) => void
) => {
  if (
    !listFiltros?.numero_contrato &&
    !listFiltros?.cliente_id &&
    !listFiltros?.fornecedor_id &&
    !listFiltros?.unidade
  ) {
    showToast("error", "Selecione um contrato ou cliente ou unidade");
    return;
  }
  if (
    listFiltros?.tipo_consulta &&
    !listFiltros?.data_inicial &&
    !listFiltros?.data_final
  ) {
    showToast(
      "error",
      "Se selecionado o tipo de consulta deve-se selecionar uma data de inicio e de fim"
    );
    return;
  }
  if (
    listFiltros?.data_inicial &&
    listFiltros.data_final &&
    !listFiltros.tipo_consulta
  ) {
    showToast(
      "error",
      "Se selecionado uma data de inicio e de fim, o tipo de consulta deve ser selecionado"
    );
    return;
  }
  if (listFiltros?.data_inicial && !listFiltros.data_final) {
    showToast(
      "error",
      "Se selecionado uma data de inicio deve-se selecionar uma data de fim e o tipo de consulta"
    );
    return;
  }
  if (!listFiltros?.data_inicial && listFiltros.data_final) {
    showToast(
      "error",
      "Se selecionado uma data de fim deve-se selecionar uma data de inicio e o tipo de consulta"
    );
    return;
  }
  const form = {
    numero_contrato:
      listFiltros && listFiltros?.numero_contrato
        ? listFiltros?.numero_contrato
        : null,
    cliente_id: listFiltros && listFiltros?.cliente_id,
    fornecedor_id: listFiltros && listFiltros?.fornecedor_id,
    situacao_pagamento: 1,
    tipo_consulta: listFiltros && listFiltros?.tipo_consulta,
    data_inicial: listFiltros.data_inicial,
    data_final: listFiltros.data_final,
    tipo_relatorio: 1,
    unidade: listFiltros?.unidade && listFiltros?.unidade,
    empreendimento_id: TokenService.getEmpreendimentoId()
      ? TokenService.getEmpreendimentoId().id
      : null,
  };

  const filtro = Object.fromEntries(
    Object.entries(form).filter(
      ([_, value]) => value !== null && value !== "" && value !== undefined
    )
  );

  if (consulta) {
    consulta.filtro = filtro;
  }

  list(
    consulta,
    itensPorPagina,
    currentPage,
    setNodes,
    updateTitles,
    updatePagination,
    setNomeCliente,
    setShowModalListLancamento
  );
};

export const list = (
  filtros: any,
  itensPorPagina: any,
  currentPage: any,
  setNodes: (value: any) => void,
  updateTitles: (titles: string[]) => void,
  updatePagination: (totalPages: number) => void,
  setNomeCliente: (value: any) => void,
  setShowModalListLancamento: (value: any) => void
) => {
  MovCaixaService.list(
    {
      include: "anexo,cliente,plano_conta,conta_financeiro,centro_custo",
      totalitems: itensPorPagina ?? 8,
      consulta: JSON.stringify(filtros),
      page: currentPage + 1,
    },
    null,
    updateTitles,
    updatePagination
  ).subscribe(
    (response: any) => {
      if (response.length > 0) {
        setNomeCliente(response[0].cliente_nome);
        setNodes(response);
        setShowModalListLancamento(true);
      } else {
        showToast("error", "Nenhum Registro encontrado!");
      }
    },
    (error) => {
      let mensagens: string[] = [];
      mensagens.push(error?.response?.data?.error?.message);
      AlertService.error("Erro!", String(mensagens));
    }
  );
};
