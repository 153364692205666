// pessoaModel.ts

import { Deserializable } from "../../../../core/interfaces/deserializable.model";
import { EnderecoModel } from "../../../../core/models/EnderecoModel";
import { TelefoneModel } from "../../../../core/models/TelefoneModel";
import { BaseModel } from "../../Pessoas/Models/BaseModel";

export interface IBanco {
  value: Number;
  nome: string;
  label: string;
}
export class BancoModel extends BaseModel implements Deserializable {
  value: Number = 0;
  nome: string = "";
  label: string = "";

  deserialize(input: any): this {
    if (!Array.isArray(input)) {
      this.assignItems(input);
      Object.assign(this, input);
      return this;
    }
    const array: BancoModel[] = [];
    input.forEach((item, key) => {
      this.assignItems(item);
      array.push(Object.assign(new BancoModel(), item));
    });
    return array as any;
  }

  private assignItems(input: any): void {
    if (input && typeof input === "object") {
      if (input.hasOwnProperty("telefones")) {
        input.telefones = new TelefoneModel().deserialize(input.telefones.data);
      }

      if (input.hasOwnProperty("enderecos")) {
        input.enderecos = new EnderecoModel().deserialize(input.enderecos.data);
      }
    }
  }
}
