import iconMenuTresPonto from '../../../../../assets/images/icon-menu-tres-pontos.png';
import { Button } from 'primereact/button';
import { useEffect, useState } from 'react';
import { useSidebarContext } from '../../../../contexts/SidebarContext';
import { Col, Container, Form, Modal, NavLink, Row } from 'react-bootstrap';
import iconFiltroAvancado from '../../../../../assets/images/icon-filtro-avancado.png';
import 'react-autocomplete-input/dist/bundle.css';
import { AlertService } from '../../../../components/AlertService';
import { url } from '../../../../environments/environments-develop';
import api from '../../../../services/api';
import { AutoComplete } from 'primereact/autocomplete';
import { Calendar } from 'primereact/calendar';
import { addLocale } from 'primereact/api';

interface ContextSearchProps {
  header?: string;
  filtrosData: {
    empreendimento_id?: number;
    filial_id?: number;
    event?: string;
    origem?: number;
    user_id?: number;
    dataInicio?: string;
    dataFim?: string;
    auditable_id?: number;
    auditable_type?: string;
    new_values?: string;
    authorization?: string;
  };
  onInputClearList: () => void;
  onInputChangeList: (field: string, value: any) => void;
  onInputChange: (field: string, value: any) => void;
};

function ContentSearchAudit(props: ContextSearchProps) {
  const { empreendimento_id, filial_id, event, user_id, origem, auditable_id, auditable_type, new_values, authorization }: any = props.filtrosData || {};
  const { isVisible, updateVisibility } = useSidebarContext();
  const [FilteredUsuarios, setFilteredUsuarios] = useState<any>(null);
  const [FilteredFiliais, setFilteredFiliais] = useState<any>(null);
  const [FilteredEmpreedimentos, setFilteredEmpreedimentos] = useState<any>(null);
  const [listaUsuarios, setListaUsuarios] = useState<any>([]);
  const [listaFiliais, setListaFiliais] = useState<any>([]);
  const [listaEmpreedimentos, setListaEmpreedimentos] = useState<any>([]);
  const [dataInicio, setDataInicio] = useState<Date | null>(null);
  const [dataFim, setDataFim] = useState<Date | null>(null);
  const [showModal, setShowModal] = useState(false);

  const toggleVisibility = () => {
    const newValue = !isVisible;
    updateVisibility(newValue);
  };

  const handleChange = (field: string, value: any) => {
    if (field === 'dataInicio') {
      setDataInicio(value);
    } else if (field === 'dataFim') {
      setDataFim(value);
    } else {
      props.onInputChange(field, value);
    }
  };

  const handleChangeList = (field: string, value: any) => {
    props.onInputChangeList(field, value);
  };

  const handleClearList = () => {
    setDataInicio(null);
    setDataFim(null);
    props.onInputClearList();
  };

  addLocale('pt', {
    firstDayOfWeek: 0,
    dayNames: ['domingo', 'segunda-feira', 'terça-feira', 'quarta-feira', 'quinta-feira', 'sexta-feira', 'sábado'],
    dayNamesShort: ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sáb'],
    dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
    monthNames: ['janeiro', 'fevereiro', 'março', 'abril', 'maio', 'junho', 'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro'],
    monthNamesShort: ['jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez'],
    today: 'Hoje',
    clear: 'Limpar'
  });

  const handleFilter = () => {
    let filtrosAplicados = false;

    if (new_values) {
      handleChangeList('new_values', new_values);
      filtrosAplicados = true;
    }
    if (user_id) {
      handleChangeList('user_id', user_id.id);
      filtrosAplicados = true;
    }
    if (event) {
      handleChangeList('event', event);
      filtrosAplicados = true;
    }
    if (filial_id) {
      handleChangeList('filial_id', filial_id.id);
      filtrosAplicados = true;
    }
    if (empreendimento_id) {
      handleChangeList('empreendimento_id', empreendimento_id.id);
      filtrosAplicados = true;
    }
    if (auditable_id) {
      handleChangeList('auditable_id', auditable_id);
      filtrosAplicados = true;
    }
    if (auditable_type) {
      handleChangeList('auditable_type', auditable_type);
      filtrosAplicados = true;
    }
    if (origem) {
      handleChangeList('origem', origem);
      filtrosAplicados = true;
    }
    if (authorization) {
      handleChangeList('authorization', authorization);
      filtrosAplicados = true;
    }

    if (filtrosAplicados) {
      setShowModal(false);
    }
  };

  const searchFilial = (event: any) => {
    // Timeout to emulate a network connection
    setTimeout(() => {
      let _Filteredfilial: any;

      if (!event.query.trim().length) {
        _Filteredfilial = [...listaFiliais];
      }
      else {
        _Filteredfilial = listaFiliais.filter((item: any) => {
          return item.nome_conta.toLowerCase().startsWith(event.query.toLowerCase());
        });
      }
      setFilteredFiliais(_Filteredfilial);
    }, 250);
  };

  const searchUsuarios = (event: any) => {
    // Timeout to emulate a network connection
    setTimeout(() => {
      let _FilteredUsuarios: any;

      if (!event.query.trim().length) {
        _FilteredUsuarios = [...listaUsuarios];
      }
      else {
        _FilteredUsuarios = listaUsuarios.filter((item: any) => {
          return item.nome.toLowerCase().startsWith(event.query.toLowerCase());
        });
      }
      setFilteredUsuarios(_FilteredUsuarios);
    }, 250);
  };

  const searchEmpreendimento = (event: any) => {
    // Timeout to emulate a network connection
    setTimeout(() => {
      let _FilteredEmpreendimentos: any;

      if (!event.query.trim().length) {
        _FilteredEmpreendimentos = [...listaEmpreedimentos];
      }
      else {
        _FilteredEmpreendimentos = listaEmpreedimentos.filter((item: any) => {
          return item.nome_empreendimento.toLowerCase().startsWith(event.query.toLowerCase());
        });
      }
      setFilteredEmpreedimentos(_FilteredEmpreendimentos);
    }, 250);
  };

  useEffect(() => {
    InitFiliais();
    InitUser();
    InitEmpreendimento();
  }, []);

  async function InitFiliais() {
    try {
      const response = await api.get(`${url}/api/v1/admin/core/user-filiais`);
      setListaFiliais(response.data.data);
    } catch (error) {
      AlertService.error('Error', 'Ocorreu um erro ao buscar a de clientes. Erro:' + String(error));
    }
  };

  async function InitUser() {
    try {
      const response = await api.get(`${url}/api/v1/admin/core/user`);
      setListaUsuarios(response.data.data);
    } catch (error) {
      AlertService.error('Error', 'Ocorreu um erro ao buscar a de clientes. Erro:' + String(error));
    }
  };

  async function InitEmpreendimento() {
    try {
      const response = await api.get(`${url}/api/v1/admin/vendas/empreendimentos`);
      setListaEmpreedimentos(response.data.data);
    } catch (error) {
      AlertService.error('Error', 'Ocorreu um erro ao buscar a de clientes. Erro:' + String(error));
    }
  };

  const formatDate = (date: Date | null) => {
    if (date) {
      const day = ('0' + date.getDate()).slice(-2);
      const month = ('0' + (date.getMonth() + 1)).slice(-2);
      const year = date.getFullYear();
      const hours = ('0' + date.getHours()).slice(-2);
      const minutes = ('0' + date.getMinutes()).slice(-2);
      const seconds = ('0' + date.getSeconds()).slice(-2);
      return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
    }
    return '';
  };

  return (
    <div className="contentSearch">
      <div className="contentSearch-header">
        <NavLink onClick={toggleVisibility}>
          <img
            className="menu-context"
            src={iconMenuTresPonto}
          />
        </NavLink>
        <Col className='text-center' style={{ fontSize: '20px', color: '#494949' }}>{props.header}</Col>
      </div>
      <div style={{ opacity: '34%' }} className="contentTraçoBodyHeader"></div>
      <div className="contentSearch-body">
        <div>
          <Container>
            <Col className='md-12' >
              <Row style={{ display: 'flex' }} className='mt-2'>
                <Col style={{ display: 'flex', flexDirection: 'column' }}>
                  <Form.Label>Ocorrência</Form.Label>
                  <Form.Control
                    value={new_values ? new_values : ''}
                    placeholder='Pesquisar por ocorrência'
                    onChange={(e) => handleChange("new_values", e.target.value)}
                  />
                </Col>
                <Col style={{ display: 'flex', flexDirection: 'column' }}>
                  <Form.Label>Data Início</Form.Label>
                  <Calendar
                    value={dataInicio}
                    onChange={(event: any) => {
                      const selectedDate = event.value;
                      handleChange('dataInicio', selectedDate);
                    }}
                    placeholder="Data Início"
                    showButtonBar
                    selectionMode="single"
                    dateFormat="dd/mm/yy"
                    locale="pt"
                    readOnlyInput
                    style={{ textAlign: 'center', width: '100%', height: '29px' }}
                    panelClassName="custom-calendar-panel"
                  />
                </Col>
                <Col style={{ display: 'flex', flexDirection: 'column' }}>
                  <Form.Label>Data Fim</Form.Label>
                  <Calendar
                    value={dataFim}
                    onChange={(event: any) => {
                      const selectedDate = event.value;
                      handleChange('dataFim', selectedDate);
                    }}
                    placeholder="Data Fim"
                    showButtonBar
                    selectionMode="single"
                    dateFormat="dd/mm/yy"
                    locale="pt"
                    readOnlyInput
                    style={{ textAlign: 'center', width: '100%' }}
                    panelClassName="custom-calendar-panel"
                  />
                </Col>
                <Col style={{ display: 'flex', flexDirection: 'column' }}>
                  <Form.Label>Usuarios</Form.Label>
                  <AutoComplete
                    required
                    placeholder='Pesquisar Usuarios'
                    field="nome"
                    style={{ height: "37px" }}
                    value={user_id ? user_id : ''}
                    suggestions={FilteredUsuarios}
                    completeMethod={searchUsuarios}
                    onChange={(e) => handleChange('user_id', e.target.value)} />
                </Col>
              </Row>
            </Col>
          </Container>
        </div>
        <div className='div-filtros-avancados' style={{ marginTop: '35px' }}>
          <Button
            style={{ height: '30px', borderRadius: '20px', padding: '5px 10px', marginRight: '5px', fontSize: '14px' }}
            type="button"
            onClick={() => { setShowModal(true) }}
            rounded outlined severity="secondary">
            <img style={{ width: '25px', height: '35px' }} src={iconFiltroAvancado} />
          </Button>
          <Button
            onClick={handleFilter}
            style={{ height: '30px', borderRadius: '20px', padding: '5px 10px', marginRight: '5px', fontSize: '14px' }}
            type="button"
            severity="success"
            icon="pi pi-filter"
            rounded>
            <p style={{ marginLeft: '5px', marginTop: '13px' }}>Pesquisar</p>
          </Button>
          <Button
            onClick={handleClearList}
            style={{ height: '30px', borderRadius: '20px', padding: '5px 10px', marginRight: '5px', fontSize: '14px' }}
            type="button"
            severity="danger"
            icon="pi pi-delete-left"
            rounded>
            <p style={{ marginLeft: '5px', marginTop: '13px' }}>Limpar</p>
          </Button>
        </div>
        <Modal
          show={showModal}
          size='lg'
          onHide={() => setShowModal(false)}
          className='modal-filtro-avancado'
        >
          <Modal.Header closeButton>
            <Modal.Title>Filtros Avançados</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container>
              <Row>
                <Col style={{ display: 'flex', flexDirection: 'column' }}>
                  <Form.Label>Evento</Form.Label>
                  <Form.Control
                    value={event ? event : ''}
                    placeholder='Pesquisar por evento'
                    onChange={(e) => handleChange("event", e.target.value)}
                  />
                </Col>
                <Col style={{ display: 'flex', flexDirection: 'column' }}>
                  <Form.Label>Filial</Form.Label>
                  <AutoComplete
                    required
                    placeholder='Pesquisar Filial'
                    field="nome_conta"
                    style={{ height: "37px" }}
                    value={filial_id ? filial_id : ''}
                    suggestions={FilteredFiliais}
                    completeMethod={searchFilial}
                    onChange={(e) => handleChange('filial_id', e.target.value)} />
                </Col>
                <Col style={{ display: 'flex', flexDirection: 'column' }}>
                  <Form.Label>Empreendimento</Form.Label>
                  <AutoComplete
                    required
                    placeholder='Pesquisar Empreendimento'
                    field="nome_empreendimento"
                    style={{ height: "37px" }}
                    value={empreendimento_id ? empreendimento_id : ''}
                    suggestions={FilteredEmpreedimentos}
                    completeMethod={searchEmpreendimento}
                    onChange={(e) => handleChange('empreendimento_id', e.target.value)} />
                </Col>
              </Row>
              <Row className='mt-3'>
                <Col style={{ display: 'flex', flexDirection: 'column' }}>
                  <Form.Label>ID Externo da Auditoria</Form.Label>
                  <Form.Control
                    value={auditable_id ? auditable_id : ''}
                    placeholder='Pesquisar por ID externo'
                    onChange={(e) => handleChange("auditable_id", e.target.value)}
                  />
                </Col>
                <Col style={{ display: 'flex', flexDirection: 'column' }}>
                  <Form.Label>Entidade do Sistema</Form.Label>
                  <Form.Control
                    value={auditable_type ? auditable_type : ''}
                    placeholder='Pesquisar por entidade do sistema'
                    onChange={(e) => handleChange("auditable_type", e.target.value)}
                  />
                </Col>
                <Col style={{ display: 'flex', flexDirection: 'column' }}>
                  <Form.Label>Origem</Form.Label>
                  <Form.Select value={origem || 2} onChange={(e) => handleChange('origem', e.target.value)}>
                    <option>Selecione...</option>
                    <option value={1}>Banco de Dados</option>
                    <option value={2}>Sistema</option>
                  </Form.Select>
                </Col>
              </Row>
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={handleFilter}
              style={{ height: '30px', borderRadius: '20px', padding: '5px 10px', marginRight: '5px', fontSize: '14px' }}
              type="button"
              severity="success"
              icon="pi pi-filter"
              rounded>
              <p style={{ marginLeft: '5px', marginTop: '13px' }}>Pesquisar</p>
            </Button>
            <Button
              onClick={handleClearList}
              style={{ height: '30px', borderRadius: '20px', padding: '5px 10px', marginRight: '5px', fontSize: '14px' }}
              type="button"
              severity="danger"
              icon="pi pi-delete-left"
              rounded>
              <p style={{ marginLeft: '5px', marginTop: '13px' }}>Limpar</p>
            </Button>
          </Modal.Footer>
        </Modal >
      </div>
    </div>
  );
}

export default ContentSearchAudit;
