import React, { useEffect, useState } from 'react';
import { PanelMenu } from 'primereact/panelmenu';
import TokenService from '../../../../services/Auth/token.service';
import { useAppContext } from '../../../../contexts/AppContext';
import { useLocation } from 'react-router-dom';
import { MenuItemCommandEvent } from 'primereact/menuitem';
import { classNames } from 'primereact/utils';
import { getImageByName } from '../../../../../assets/images';
import { Nav } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

interface SubmenuItem {
  id: number;
  parent_id: number;
  name: string;
  icon: string;
  route: string;
  submenu: SubmenuItem[];
}

interface MenuProps {
  id: number;
  parent_id: number;
  name: string;
  icon: string;
  route: string;
  submenu: SubmenuItem[];
}

interface SidebarProps {
  menus: MenuProps[];
}
type Submenu = {
  id: any;
  label: any;
  icon: any;
  items?: Submenu[];
  style: any;
  classNames: any;
  url: any,
  template: (item: any, options: any) => JSX.Element;
};
interface MenuItem {
  label: string;
  icon: any;
  classNames: any;
  items: any[];
}
export default function SidebarRouterComponent({ menus }: SidebarProps) {
  const [selectedOption, setSelectedOption] = useState("#");
  const [selectedMenu, setSelectedMenu] = useState<any>();
  const [subMenu, setSubMenu] = useState<any>();
  const [subMenuFormatted, setSubMenuFormatted] = useState<any>([]);
  const location = useLocation();
  const { setShowSubSidebar } = useAppContext();
  const menuSidebar: any = localStorage.getItem("menu-sidebar");
  const [conteudo, setConteudo] = useState<MenuItem[]>([]);
  useEffect(() => {
    setSelectedMenu(TokenService.getMenuSelect());
  }, []);
  useEffect(() => {
    setSubMenu(selectedMenu?.submenu)
  }, [selectedMenu]);
  useEffect(() => {
    const formatSubmenu = (submenu: any): Submenu => {
      const formattedSubmenu: Submenu = {
        id: submenu.id,
        label: submenu.name,
        classNames: 'svgNavBar sidebar-text',
        url: submenu.route,
        style: {
          flexDirection: 'column',
          textAlign: 'left !important',
          fontFamily: '"Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif !important',
          fontSize: '14px !important',
          fontStyle: 'normal !important',
          fontWeight: '400 !important',
          lineHeight: 'normal !important',
          paddingLeft: '7px !important',
          textDecoration: 'none !important',
          color: '#e8550f !important',
        },
        icon: () =>
          <img
            alt={submenu.icon}
            className={submenu.icon}
            style={{ marginRight: '5px' }}
            src={getImageByName(submenu.icon) ?? ""}
          />,
        template: itemRenderer,
      };

      if (submenu.submenu && submenu.submenu.length > 0) {
        formattedSubmenu.items = submenu.submenu.map(formatSubmenu);
      }

      return formattedSubmenu;
    };

    if (subMenu) {
      subMenu.map((submenusidebar: any) => {
        const existingSubmenuIds = new Set(submenusidebar.submenu.map((s: any) => s.id));

        submenusidebar.submenu = submenusidebar.submenu.map(formatSubmenu);

        menus.forEach((submenu) => {

          if (submenusidebar.id === submenu.parent_id && !existingSubmenuIds.has(submenu.id)) {
            const formattedSubmenu = formatSubmenu(submenu);
            submenusidebar.submenu.push(formattedSubmenu);
            existingSubmenuIds.add(submenu.id); // Adiciona o ID do submenu adicionado ao conjunto
          }
        });

      });
      setSubMenuFormatted(subMenu);
    }
  }, [subMenu]);
  const itemRenderer = (item: any, options: any) => (
    <Nav className="sidebar-navbar">
      {item?.items && (<a
        style={{
          textDecoration: 'none',
          color: location.pathname === item.url ? '#fff' : '#e8550f',
          backgroundColor: location.pathname === item.url ? '#282c34' : '',
        }}
        className={`flex align-items-center px-3 py-2 svgNavBar sidebar-text ${!item.icon ? "sidebar-text-noIcon" : ""}`}
        onClick={options.onClick}
      >
        {item.icon()}
        <span>{item.label}</span>
      </a>)}

      {!item?.items && (
        <NavLink
          style={{
            textDecoration: 'none',
            color: location.pathname === item.url ? '#fff' : '#e8550f',
            backgroundColor: location.pathname === item.url ? '#282c34' : '',
          }}
          to={item.url}
          className={`flex align-items-center px-3 py-2 svgNavBar sidebar-text ${!item.icon ? "sidebar-text-noIcon" : ""}`}
          onClick={options.onClick}
        >
          {item.icon()}
          <span>{item.label}</span>
        </NavLink>)}
    </Nav>
  );
  useEffect(() => {
    const novoConteudo: MenuItem[] = subMenuFormatted.map((item: any) => ({
      label: item.name,
      url: item.route,
      icon: () => <img
        alt={item.icon}
        className={item.icon}
        src={getImageByName(item.icon) ?? ""}
      />,
      template: itemRenderer,
      ...(item.submenu.length > 0 && { items: item.submenu }),
    }));
    setConteudo(novoConteudo);
  }, [subMenuFormatted]);
  return (
    <div className="flex justify-content-center" style={{ width: "100%" }}>
      <PanelMenu model={conteudo} className="w-full md:w-20rem" />
    </div>
  )
}