import React, { useEffect, useState } from 'react';
import { Form, Row, Col, InputGroup } from 'react-bootstrap';
import CalendarIcon from './../../assets/images/icon-calendar.png';
import 'react-datepicker/dist/react-datepicker.css';
import pt from 'date-fns/locale/pt';
import DatePicker from 'react-datepicker';

interface ModalAltDataProgramadaFormProps {
    formBasico: any;
    handleChange: (field: string, value: any) => void;
    handleSaveButton: () => Promise<void>;
}

const ModalAltDataProgramadaForm: React.FC<ModalAltDataProgramadaFormProps> = ({ formBasico, handleChange, handleSaveButton }) => {
    const [data_prog_pgto, setDataProgPgto] = useState<Date | null>(null);

    useEffect(() => {
        setDataProgPgto(formBasico?.data_prog_pgto ? new Date(formBasico?.data_prog_pgto) : null)
    }, [formBasico])

    return (
        <Form onSubmit={handleSaveButton}>
            <Row className="group-form">
                <Col md="5">
                    <Form.Label>Fornecedor</Form.Label>
                    <InputGroup>
                        <Form.Control
                            type="text"
                            disabled
                            value={formBasico?.fornecedor_nome}
                            onChange={(e) => handleChange('fornecedor_nome', e.target.value)}
                        />
                    </InputGroup>
                </Col>
                <Col md="3">
                    <Form.Label>Valor</Form.Label>
                    <InputGroup>
                        <Form.Control
                            type="text"
                            disabled
                            value={formBasico?.valor_original_format}
                            onChange={(e) => handleChange('valor_original_format', e.target.value)}
                        />
                    </InputGroup>
                </Col>
                <Col md="4" style={{ display: 'grid' }}>
                        <Form.Label>Data Programada para Pagamento</Form.Label>
                        <DatePicker
                            selected={ data_prog_pgto || null}
                            onChange={(date: Date) => {
                                setDataProgPgto(date);
                                handleChange('data_prog_pgto', date ? date.toISOString() : ''); 
                            }}
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            fixedHeight
                            locale={pt}
                            customInput={
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <input
                                        type="text"
                                        placeholder="Data de Vencimento"
                                        className="dateFormFinanceiro"
                                        value={ data_prog_pgto ?  data_prog_pgto.toLocaleDateString() : ''}
                                        readOnly
                                    />
                                    <img
                                        className="calendar-icon"
                                        src={CalendarIcon}
                                    />
                                </div>
                            }
                        />
                    </Col>
            </Row>
        </Form>
    );
};

export default ModalAltDataProgramadaForm;
