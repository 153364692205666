import { Col, Row } from "react-bootstrap";
interface PropsData {
    EditSecaoParcelamentoData: {
        qtd_parcelas?: string;
        valor_parcelas?: string;
        data_prim_parcela?: string;
    };
    onInputChange: (field: string, value: any) => void;
}

function EditSecaoParcelamento(props: PropsData) {
    const { qtd_parcelas, valor_parcelas, data_prim_parcela } = props.EditSecaoParcelamentoData || {};
    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            flex: '1',
            height: '-webkit-fill-available',
            marginTop: '6px',
            minHeight: '350px'
        }}>
            <Row style={{ textAlign: 'center' }}>
                <Col>
                    <Row>
                        <h6>Parcelas do Imovel</h6>
                    </Row>
                    <hr />
                    <Row >
                        <p style={{ display: 'flex', justifyContent: 'center' }}> {qtd_parcelas}x</p>
                    </Row>
                </Col>
                <Col>
                    <Row>
                        <h6>Valor das Parcela</h6>
                    </Row>
                    <hr />
                    <Row >
                        <p style={{ display: 'flex', justifyContent: 'center' }}> {valor_parcelas}</p>
                    </Row>
                </Col>
                <Col>
                    <Row>
                        <h6>Data da Primeira Parcela do Imóvel</h6>
                    </Row>
                    <hr />
                    <Row >
                        <p style={{ display: 'flex', justifyContent: 'center' }}> {data_prim_parcela}</p>
                    </Row>
                </Col>
            </Row>
        </div>
    );
}

export default EditSecaoParcelamento;
