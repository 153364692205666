export const changeUserGrupo = (
  data: any,
  updateData: any,
  index: number,
  field: string,
  value: any
) => {
  const UserGrupos = data?.user_grupos;

  if (data && UserGrupos && index >= 0 && index < UserGrupos.length) {
    updateData((prevFormBasico: any) => {
      const updatedUserGrupos = [...prevFormBasico.user_grupos];

      updatedUserGrupos[index] = {
        ...updatedUserGrupos[index],
        [field]: value,
      };

      return {
        ...prevFormBasico,
        user_grupos: updatedUserGrupos,
      };
    });
  }
};

export const addUserGrupo = (
  data: any,
  updateData: ((data: any) => void) | undefined
) => {
  if (data && updateData) {
    const grupos = data?.user_grupos ? data?.user_grupos : [];
    const novoUserGrupo: any = {
      filial_id: null,
      grupo_id: null,
    };
    updateData({
      ...data,
      user_grupos: [...grupos, novoUserGrupo],
    });
  }
};

export const removeUserGrupo = (
  data: any,
  updateData: ((data: any) => void) | undefined,
  index: number
) => {
  if (data && updateData) {
    const updatedUserGrupos = [...data?.user_grupos];
    updatedUserGrupos.splice(index, 1);
    updateData({
      ...data,
      user_grupos: updatedUserGrupos,
    });
  }
};
