import { Chart } from 'primereact/chart';

interface DoughnutChartProps {
  disponivel: number;
  vendido: number;
  reservados: number;
  total: number;
}

function DoughnutChart({ disponivel, vendido, reservados, total }: DoughnutChartProps) {
  const documentStyle = getComputedStyle(document.documentElement);
  const chartData = {
    labels: ['Vendido', 'Reservados', 'Disponível'],
    datasets: [
      {
        data: [
          vendido,
          reservados,
          disponivel
        ],
        backgroundColor: [
          documentStyle.getPropertyValue('--orange-500'),
          documentStyle.getPropertyValue('--purple-500'),
          documentStyle.getPropertyValue('--green-500')
        ],
        hoverBackgroundColor: [
          documentStyle.getPropertyValue('--orange-400'),
          documentStyle.getPropertyValue('--purple-400'),
          documentStyle.getPropertyValue('--green-400')
        ]
      }
    ],
  };

  const chartOptions = {
    plugins: {
      legend: {
        labels: {
          usePointStyle: true
        }
      },
    },
    cutout: '60%'
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '10px' }}>
      <Chart type="doughnut" data={chartData} options={chartOptions} style={{ width: '100%', maxWidth: '300px', height: 'auto' }} className="mb-6" />
      <div className="grid grid-cols-2 md:grid-cols-4 gap-4 text-center mt-4">
        <div className="flex flex-col items-center">
          <span style={{ fontSize: '14px'}}>Vendido: {vendido}</span>
        </div>
        <div className="flex flex-col items-center">
          <span style={{ fontSize: '14px'}}>Reservados: {reservados}</span>
        </div>
        <div className="flex flex-col items-center">
          <span style={{ fontSize: '14px'}}>Disponível: {disponivel}</span>
        </div>
        <div className="flex flex-col items-center">
          <span style={{ fontSize: '14px', fontWeight: 'bold' }}>Total: {total}</span>
        </div>
      </div>
    </div>
  );
};

export default DoughnutChart;