import { Button } from "primereact/button";
import React, { useState, useEffect } from "react";
import { Col, Form, InputGroup, Row } from "react-bootstrap";

interface PropsData {
  Data: {
    nome?: string;
    descricao?: string;
    status?: boolean;
    empreendimento_id?: number;
    anexo?: AnexosPropsData;
  };
  initialRightValues: any;
  onInputChange: (field: string, value: any) => void;
}
interface AnexosPropsData {
  nome?: string;
  diretorio: null;
  alias?: string;
  extensao?: string;
  conteudo?: string;
}

function ModeloContratoForm(props: PropsData) {
  const { nome, descricao, status, empreendimento_id, anexo } = props.Data || {};
  const handleChange = (field: string, value: any) => {
    props.onInputChange(field, value);
  };

  const [attachmentDetails, setAttachmentDetails] = useState<any>();
  useEffect(() => {
    setAttachmentDetails(props.Data.anexo);
  }, [props]);

  const handleFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const uploadedFile = e.target.files[0];
      const { name: nome } = uploadedFile;
      const alias = nome;
      const extensao = 'docx';
      const reader = new FileReader();
      reader.onload = () => {
        const conteudo = reader.result as string;
        setAttachmentDetails({
          ...attachmentDetails,
          nome,
          alias,
          extensao,
          conteudo,
        });
        props.onInputChange("anexo", {
          nome,
          alias,
          extensao,
          conteudo
        });
      };
      reader.readAsDataURL(uploadedFile);
    }
  };
  const trocarArquivo = () => {
    setAttachmentDetails(null);
  };

  return (
    <>
      <Form.Group className="forms" controlId="validationCustomUsername">
        <Row className="mb-3">
          <Col md="3">
            <Form.Label>Nome</Form.Label>
            <InputGroup hasValidation>
              <Form.Control
                required
                value={nome || ""}
                onChange={(e) => handleChange("nome", e.target.value)}
                onBlur={(e) => handleChange("nome", e.target.value)}
              />
              <Form.Control.Feedback type="invalid">
                Este campo é Obrigatório.
              </Form.Control.Feedback>
            </InputGroup>
          </Col>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} md="3">
            <Form.Label>Descrição</Form.Label>
            <Form.Control
              value={descricao || ""}
              onChange={(e) => handleChange("descricao", e.target.value)}
              placeholder="Preencha a descrição"
            />
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} md="3" hasValidation>
            <Form.Label>Anexo</Form.Label>
            {attachmentDetails && (
              <div style={{ display: 'flex' }}>
                <Form.Control
                  placeholder={attachmentDetails?.alias}
                  disabled={true}
                />
                <Button style={{ borderRadius: '100%', maxHeight: '15px', maxWidth: '25px', marginLeft: '5px', marginTop: '5px' }}
                  icon="pi pi-times" rounded outlined severity="danger" aria-label="Cancel"
                  onClick={() => trocarArquivo()}
                ></Button>
              </div>
            )}
            {!attachmentDetails && (<Form.Control
              type="file"
              onChange={handleFile}
              isInvalid={anexo ? false : true}
              required
            />)}
            <Form.Control.Feedback type="valid">
              Este campo é Obrigatório.
            </Form.Control.Feedback>
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} md="3" hasValidation>
            <Form.Label>Status</Form.Label>
            <Form.Check
              checked={status || false}
              onChange={(e) => handleChange("status", e.target.checked)}
              type="switch"
              id="custom-switch"
              required
            />
            <Form.Control.Feedback type="invalid">
              Este campo é Obrigatório.
            </Form.Control.Feedback>
          </Form.Group>
        </Row>
      </Form.Group>
    </>
  );
};

export default ModeloContratoForm;
