import { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import api from "../../../../services/api";
import { url } from "../../../../environments/environments-develop";
import { AutoComplete } from "primereact/autocomplete";
import { Button } from "primereact/button";
import { AlertService } from "../../../../components/AlertService";
import { showToast } from "../../../../components/ToastComponent";

function EnderecoFormContaFinanceira(props: any) {
    var { cep, logradouro, numero, cidade, tipo_endereco, nome, complemento } = props.enderecoData;
    const [FilteredCidade, setFilteredCidade] = useState<any>(null);
    const [listCidade, setListCidade] = useState([]);
    const handleChange = (field: string, value: any) => {
        if (field === 'numero') {
            if (/^\d*$/.test(value) && value.length <= 6) {
                props.onInputChangeEnderecos(field, value);
            }
        } else if (field === 'logradouro') {
            if (!value || value.length <= 60) {
                props.onInputChangeEnderecos(field, value);
            }
        } else if (field === 'cep') {
            if (/^\d*$/.test(value) && value.length <= 11) {
                props.onInputChangeEnderecos(field, value);
            }
        } else if (field === 'complemento') {
            if (!value || value.length <= 60) {
                props.onInputChangeEnderecos(field, value);
            }
        }
        else {
            props.onInputChangeEnderecos(field, value);
        }
    };
    useEffect(() => {
        InitCidade();
    }, []);
    async function InitCidade() {
        try {
            const response = await api.get(`${url}/api/v1/admin/core/cidades/listar-cidades`);
            setListCidade(response.data.data);
        } catch (error) {
            AlertService.error('Error', 'Ocorreu um erro ao buscar a lista de cidades. Erro:' + String(error));
        }
    }
    const searchCidade = (event: any) => {
        // Timeout to emulate a network connection
        setTimeout(() => {
            let _FilteredCidade: any;

            if (!event.query.trim().length) {
                _FilteredCidade = [...listCidade];
            }
            else {
                _FilteredCidade = listCidade.filter((item: any) => {
                    return item.nome.toLowerCase().startsWith(event.query.toLowerCase());
                });
            }
            setFilteredCidade(_FilteredCidade);
        }, 250);
    }

    const handleChangeCidade = (field: string, value: any) => {
        props.onInputChangeEnderecos('cidade_id', value.id)
        handleChange(field, value);
    };
    const handleChangeBairro = (field: string, value: any) => {
        props.onInputChangeBairro(field, value)
    };
    async function buscarCep(value: any): Promise<void> {
        try {
            const retorno = await api.get(
                `${url}/api/v1/admin/core/cep-localidade/${value}`
            );
            if (retorno && retorno.status === 200) {
                const novoEndereco: any = {
                    apelido: "",
                    logradouro: retorno.data.data.logradouro,
                    tipo_endereco: "",
                    cep: retorno.data.data.cep,
                    cidade: retorno.data.data.cidade.data,
                    numero: "",
                    cidade_id: retorno.data.data.cidade_id,
                    cidade_nome: retorno.data.data.cidade_nome,
                    complemento: "",
                };
                Object.keys(novoEndereco).forEach((field) => {
                    handleChange(field, novoEndereco[field]);
                });
            }

        } catch (err) {
            showToast("error", `Erro: ${err}`);
        }
    }

    return (
        <Form.Group className="usuario-form">
            <Row className="mb-3" style={{ marginLeft: '12px', padding: '12px', borderBottom: '1px solid #e6e5e5', borderTop: '1px solid #e6e5e5' }}>
                <h5 className="sub-label-form-create-imovel" style={{ color: 'rgba(6, 6, 6, 0.47)', display: 'flex', justifyContent: 'center' }}>Endereço</h5>
            </Row>
            <Row className="mb-3">
                <Col style={{ display: 'flex' }}>
                    <Col md="10">
                        <Form.Label>CEP</Form.Label>
                        <Form.Control
                            value={cep || ''}
                            onChange={(e) => handleChange("cep", e.target.value)}

                        />
                    </Col>
                    <Col md="1">
                        <Form.Label></Form.Label>
                        <Button
                            severity="success"
                            className="p-button-rounded p-button-text"
                            icon="pi pi-search"
                            style={{ borderRadius: '30px', width: '25px', height: '15px', marginTop: '10px', marginLeft: '10px' }}
                            onClick={(e) => buscarCep(cep)}
                        ></Button>
                    </Col>
                </Col>
                <Col>
                    <Form.Label>Logradouro</Form.Label>
                    <Form.Control
                        value={logradouro}
                        onChange={(e) =>
                            handleChange("logradouro", e.target.value)
                        }
                    />
                </Col>
                <Col>
                    <Form.Label>Número</Form.Label>
                    <Form.Control
                        value={numero}
                        onChange={(e) =>
                            handleChange("numero", e.target.value)
                        }
                    />
                </Col>
                <Form.Group as={Col} controlId="formGridState">
                    <Form.Label>Tipo de Endereço</Form.Label>
                    <Form.Select
                        value={tipo_endereco}
                        onChange={(e) =>
                            handleChange("tipo_endereco", e.target.value)
                        }
                    >
                        <option>Selecione...</option>
                        <option value={1}>Residencial</option>
                        <option value={2}>Comercial</option>
                        <option value={3}>Contato</option>
                        <option value={4}>Cobrança</option>
                    </Form.Select>
                </Form.Group>
            </Row>
            <Row className="mb-3">
                <Col md="3" style={{ display: 'flex', flexDirection: 'column' }}>
                    <Form.Label>Cidade</Form.Label>
                    <AutoComplete
                        required
                        placeholder='Pesquisar cidade'
                        field="nome"
                        style={{ height: "37px" }}
                        value={cidade ? cidade : ''}
                        suggestions={FilteredCidade}
                        completeMethod={searchCidade}
                        onChange={(e) => handleChangeCidade('enderecos.cidade', e.target.value)} />
                </Col>
                <Col md="3">
                    <Form.Label>Nome do Bairro</Form.Label>
                    <Form.Control
                        required
                        value={nome}
                        onChange={(e) =>
                            handleChangeBairro("nome", e.target.value)
                        }
                    />
                </Col>
                <Col md="6">
                    <Form.Label>Complemento</Form.Label>
                    <Form.Control
                        value={complemento}
                        onChange={(e) =>
                            handleChange("complemento", e.target.value)
                        }
                    />
                </Col>
            </Row>
        </Form.Group>
    );
}

export default EnderecoFormContaFinanceira;
