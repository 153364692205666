import React, { useState, useEffect } from 'react';
import iconMenuTresPonto from '../../../../assets/images/icon-menu-tres-pontos.png';

import { Col, Row, Form, InputGroup, NavLink } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import { useAppContext } from '../../../contexts/AppContext';
import { AlertService } from '../../../components/AlertService';
import { useSidebarContext } from '../../../contexts/SidebarContext';
import AceEditorComponent from '../RotinasEspeciais/AceEditor';

export interface auditData {
    id: string | null;
    nome_usuario: string | null;
    authorization_nome: string | null;
    filial_name: string | null;
    empreendimento_name: string | null;
    event: string | null;
    url: string | null;
    ip_address: string | null;
    user_agent: string | null;
    created_at: string | null;
    origem_label: string | null;
    auditable_id: string | null;
    auditable_type: string | null;
    old_values_txt: string | null;
    new_values_txt: string | null;
}

function AuditShow() {
    const { showSubSidebar, setItemsBreadsCrumbs } = useAppContext();
    const { isVisible, updateVisibility } = useSidebarContext();
    const [auditData, setAuditData] = useState<any | null>(null);
    useEffect(() => {
        try {
            setItemsBreadsCrumbs([
                {
                    label: 'Configurações',
                    url: '/configuracoes',
                },
                {
                    label: 'Auditoria',
                    url: '/audit',
                },
                {
                    label: 'Detalhes'
                }
            ]);
            const storedData = localStorage.getItem('dadosShow');
            if (storedData) {
                let audit: auditData = JSON.parse(storedData);
                setAuditData(audit);
            }
            return () => {
                localStorage.removeItem('dadosShow');
            };
        } catch (error) {
            AlertService.error("error", String(error))
        }
    }, []);

    const toggleVisibility = () => {
        const newValue = !isVisible;
        updateVisibility(newValue);
    };

    return (
        <div className="body-content">
            <div
                className={`${showSubSidebar ? "container-section-header" : "container-section-header-none"}`}
            >
                <div className="contentSearch">
                    <div className="contentSearch-header">
                        <NavLink onClick={toggleVisibility}>
                            <img
                                className="menu-context"
                                src={iconMenuTresPonto}
                            />
                        </NavLink>
                        <Col className='text-center' style={{ fontSize: '20px', color: '#494949' }}>Auditoria</Col>
                    </div>
                </div>
            </div>

            <div className="audit-show">
                <Container>
                    <Col className='md-12' >
                        <Row className="mb-3">
                            <Col md="1">
                                <Form.Label>Id</Form.Label>
                                <InputGroup>
                                    <Form.Control
                                        value={auditData?.id}
                                        disabled
                                    />
                                </InputGroup>
                            </Col>
                            <Col>
                                <Form.Label>Nome Usuario</Form.Label>
                                <InputGroup>
                                    <Form.Control
                                        value={auditData?.nome_usuario}
                                        disabled
                                    />
                                </InputGroup>
                            </Col>
                            <Col>
                                <Form.Label>Filial</Form.Label>
                                <InputGroup>
                                    <Form.Control
                                        value={auditData?.filial_name}
                                        disabled
                                    />
                                </InputGroup>
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col md="1">
                                <Form.Label>Tipo Evento</Form.Label>
                                <InputGroup>
                                    <Form.Control
                                        value={auditData?.event}
                                        disabled
                                    />
                                </InputGroup>
                            </Col>
                            <Col md="1">
                                <Form.Label>Origem</Form.Label>
                                <InputGroup>
                                    <Form.Control
                                        value={auditData?.origem_label}
                                        disabled
                                    />
                                </InputGroup>
                            </Col>
                            <Col>
                                <Form.Label>Autorizado por</Form.Label>
                                <InputGroup>
                                    <Form.Control
                                        value={auditData?.authorization_nome}
                                        disabled
                                    />
                                </InputGroup>
                            </Col>
                            <Col>
                                <Form.Label>Empreendimento</Form.Label>
                                <InputGroup>
                                    <Form.Control
                                        value={auditData?.empreendimento_name}
                                        disabled
                                    />
                                </InputGroup>
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col md="2">
                                <Form.Label>Data</Form.Label>
                                <InputGroup>
                                    <Form.Control
                                        value={auditData?.created_at}
                                        disabled
                                    />
                                </InputGroup>
                            </Col>
                            <Col md="1">
                                <Form.Label>Id externo</Form.Label>
                                <InputGroup>
                                    <Form.Control
                                        value={auditData?.auditable_id}
                                        disabled
                                    />
                                </InputGroup>
                            </Col>
                            <Col>
                                <Form.Label>Entidade Sistema</Form.Label>
                                <InputGroup>
                                    <Form.Control
                                        value={auditData?.auditable_type}
                                        disabled
                                    />
                                </InputGroup>
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col>
                                <Form.Label>Novo Valor</Form.Label>
                                <InputGroup>
                                    <Form.Control
                                        as="textarea"
                                        value={auditData?.new_values_txt}
                                        rows={5}
                                        disabled
                                    />
                                </InputGroup>
                            </Col>
                            <Col>
                                <Form.Label>Valor Antigo</Form.Label>
                                <InputGroup>
                                    <Form.Control
                                        as="textarea"
                                        value={auditData?.old_values_txt}
                                        rows={5}
                                        disabled
                                    />
                                </InputGroup>
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col md="2">
                                <Form.Label>Ip Address</Form.Label>
                                <InputGroup>
                                    <Form.Control
                                        value={auditData?.ip_address}
                                        disabled
                                    />
                                </InputGroup>
                            </Col>
                            <Col>
                                <Form.Label>User Cliente</Form.Label>
                                <InputGroup>
                                    <Form.Control
                                        value={auditData?.user_agent}
                                        disabled
                                    />
                                </InputGroup>
                            </Col>
                            <Col>
                                <Form.Label>Url Acesso</Form.Label>
                                <InputGroup>
                                    <Form.Control
                                        value={auditData?.url}
                                        disabled
                                    />
                                </InputGroup>
                            </Col>
                        </Row>
                    </Col>
                </Container>
            </div>
        </div>
    );
}

export default AuditShow;
