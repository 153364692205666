import { useEffect, useRef } from "react";
import { handleOpenModal, setSessionEditData } from '../Service/VendaService';
import { Tooltip } from 'primereact/tooltip';
import { Toast } from 'primereact/toast';
import { SpeedDial } from "primereact/speeddial";
import 'primeicons/primeicons.css';
import { handleOpenModalAnexo, handleToggleClickEmitirContrato, handleToggleClickReativarContrato } from "../../../Components/GestaoContratosServices";

export const actionTemplateContratoJazigo = (
    value: any,
    setSelectedRow: (value: any) => void,
    setShowConfirmarModalCancelarContratoJazigo: (value: any) => void,
    setSelectedNodeKeys: (value: any) => void,
    onInputChange: (field: string, value: any) => void,
    setShowConfirmarModalReativarContrato: (value: any) => void,
    setShowModalEmitirContrato: (value: any) => void,
    setShowModalAnexo: (value: any) => void,
) => {
    const data = value.data ? value.data : value;
    const toast = useRef<Toast>(null);
    const commonStyle = {
        width: '35px',
        height: '35px',
        top: '0px',
        color: '#fff'
    };

    const items: any[] = [];

    if (!data?.inumado_id) {
        items.push({
            label: 'Cancelar',
            icon: 'pi pi-times',
            style: { ...commonStyle, backgroundColor: '#f44336' },
            command: () => {
                handleOpenModal(data, setSelectedRow, setShowConfirmarModalCancelarContratoJazigo);
            },
            tooltip: 'Cancelar',
        });
    }

    if (data.situacao_pagamento === 1) {
        items.push({
            label: 'Reativar',
            icon: 'pi pi-undo',
            style: { ...commonStyle, backgroundColor: '#289b1e' },
            command: () => {
                handleToggleClickReativarContrato(data, setSelectedNodeKeys, setShowConfirmarModalReativarContrato);
            },
            tooltip: 'Reativar'
        });
    } else {
        items.push({
            label: 'Emitir Contrato',
            icon: 'pi pi-print',
            style: { ...commonStyle, backgroundColor: '#289b1e' },
            command: () => {
                handleToggleClickEmitirContrato(data, onInputChange, setShowModalEmitirContrato);
            },
            tooltip: 'Emitir Contrato'
        });
    }

    items.push({
        label: 'Fazer Cessão de Direitos',
        icon: 'pi pi-arrow-right-arrow-left',
        style: { ...commonStyle, backgroundColor: '#007bff' },
        command: () => {
            setSessionEditData(data.id, '/carteira/contrato-transferencia/store');
        },
        tooltip: 'Fazer Cessão de Direitos',
    });

    items.push({
        label: 'Documentos',
        icon: 'pi pi-file',
        style: { ...commonStyle, backgroundColor: '#00BCD4' },
        command: () => {
            handleOpenModalAnexo(data, setSelectedNodeKeys, setShowModalAnexo);
        },
        tooltip: 'Documentos'
    });

    useEffect(() => {
        items.forEach((item, index) => {
            const element = document.querySelectorAll('.p-speeddial-action')[index + 1];
            if (element) {
                element.setAttribute('data-pr-tooltip', item.tooltip);
                element.setAttribute('data-pr-position', 'left');
            }
        });
    }, [items]);

    return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Toast ref={toast} />
            <Tooltip target="[data-pr-tooltip]" />
            <div style={{ position: 'relative' }}>
                <SpeedDial
                    model={items}
                    radius={45}
                    showIcon="pi pi-bars"
                    hideIcon="pi pi-times"
                    type="linear"
                    direction="left"
                    style={{ top: -5, right: 18 }}
                    buttonClassName="button-speed-dial"
                />
            </div>
        </div>
    );
};
