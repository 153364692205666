import React, { ReactNode, useEffect, useState } from 'react';
import logoQimob from '../../assets/images/animação_qimob.gif';
interface LoadingWrapperProps {
    children: ReactNode;
}
const LoadingWrapper: React.FC<LoadingWrapperProps> = ({ children }) => {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const images = Array.from(document.images);
        const totalImages = images.length;

        if (totalImages === 0) {
            setTimeout(() => {
                setIsLoading(false);
            }, 900); // Tempo mínimo de 2 segundos
            return;
        }

        let loadedImages = 0;

        const handleImageLoad = () => {
            loadedImages += 1;

            if (loadedImages === totalImages) {
                setTimeout(() => {
                    setIsLoading(false);
                }, 900); // Tempo mínimo de 2 segundos
            }
        };

        images.forEach((image) => {
            if (image.complete) {
                handleImageLoad();
            } else {
                image.addEventListener('load', handleImageLoad);
            }
        });

        return () => {
            images.forEach((image) => {
                image.removeEventListener('load', handleImageLoad);
            });
        };
    }, [children]);

    if (isLoading) {
        return <div className='loading'> <img className="logoQimobLoad" src={logoQimob} /></div>;
    }

    return <>{children}</>;
};

export default LoadingWrapper;
