import axios from "../../../services/api";
import { Observable, from, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import {
  url,
  url_financeiro,
} from "../../../environments/environments-develop";
import {
  ILancamentoFinanceiro,
  LancamentoFinanceiroModel,
} from "../Models/LancamentoFinanceiroModel";
import { ClienteModel, ICliente } from "../../Pessoas/Models/ClienteModel";

interface IApiResponse<T> {
  data: T;
  headers: {
    titles: string[];
  };
  meta: any;
}

class MovCaixaService {
  private resourceUrl = `${url}/api/v1/admin/financeiro/movimentacaofinanceira`;
  private resourceUrlFinanceiro = `${url}/api/v1/admin/financeiro`;
  private resourceUrlPessoa = `${url}/api/v1/admin/core/pessoa`;
  private resourceUrlAPIFinanceiro = `${url_financeiro}/api/v1/admin/financeiro/lancamentofinanceiro`;

  list(
    params: any = {},
    headers: any = null,
    updateTitles: (titles: string[]) => void,
    updatePagination: (totalPages: number) => void
  ): Observable<LancamentoFinanceiroModel[]> {
    return from(
      axios
        .get<IApiResponse<ILancamentoFinanceiro[]>>(this.resourceUrl, {
          params,
          headers,
        })
        .then((response) => {
          updateTitles(response.data.headers.titles);
          updatePagination(response.data.meta.pagination.total_pages);
          return response.data.data.map((item) =>
            new LancamentoFinanceiroModel().deserialize(item)
          );
        })
    ).pipe(catchError((error) => throwError(error)));
  }

  get(
    id: number,
    params: any = {},
    headers: any = null
  ): Observable<LancamentoFinanceiroModel> {
    return from(
      axios
        .get<ILancamentoFinanceiro>(`${this.resourceUrl}/${id}`, {
          params,
          headers,
        })
        .then((response) =>
          new LancamentoFinanceiroModel().deserialize(response.data)
        )
    ).pipe(catchError((error) => throwError(error)));
  }

  create(
    data: any,
    params: any = {},
    headers: any = null
  ): Observable<LancamentoFinanceiroModel> {
    return from(
      axios
        .post<ILancamentoFinanceiro>(this.resourceUrlAPIFinanceiro, data, {
          params,
          headers,
        })
        .then((response) =>
          new LancamentoFinanceiroModel().deserialize(response.data)
        )
    ).pipe(catchError((error) => throwError(error)));
  }

  update(
    id: number,
    data: any,
    params: any = {},
    headers: any = null
  ): Observable<LancamentoFinanceiroModel> {
    return from(
      axios
        .put<ILancamentoFinanceiro>(
          `${this.resourceUrlAPIFinanceiro}/${id}`,
          data,
          {
            params,
            headers,
          }
        )
        .then((response) =>
          new LancamentoFinanceiroModel().deserialize(response.data)
        )
    ).pipe(catchError((error) => throwError(error)));
  }

  estorno(
    id: number,
    params: any = {},
    headers: any = null
  ): Observable<LancamentoFinanceiroModel> {
    return from(
      axios
        .post<ILancamentoFinanceiro>(
          `${this.resourceUrlAPIFinanceiro}/estorno/${id}`,
          {
            params,
            headers,
          }
        )
        .then((response) =>
          new LancamentoFinanceiroModel().deserialize(response.data)
        )
    ).pipe(catchError((error) => throwError(error)));
  }

  excluir(params: any = {}, headers: any = null): Observable<any> {
    return from(
      axios
        .post(`${this.resourceUrl}/destroy-all`, { ids: params }, { headers })
        .then((response) => response.data)
    ).pipe(catchError((error) => throwError(error)));
  }

  buscarClienteList(params: any = {}, headers: any = null): Observable<any> {
    return from(
      axios
        .get<IApiResponse<any>>(
          this.resourceUrlPessoa + "/find-fornecedor-or-cliente",
          {
            params,
            headers,
          }
        )
        .then((response) => {
          return response.data.data;
        })
    ).pipe(catchError((error) => throwError(error)));
  }
}

export default new MovCaixaService();
