import { UsuarioData } from "../contexts/UsuarioContext";

export const changeUsuario = (
  field: string,
  value: string,
  usuarioData: any | undefined,
  updateUsuarioData: ((data: any) => void) | undefined,
) => {
  if (updateUsuarioData) {
    let newUsuario: UsuarioData;

    if (usuarioData) {
      if (field.startsWith('pessoa.')) {
        const pessoaField = field.replace('pessoa.', '');

        newUsuario = {
          ...usuarioData,
          pessoa: {
            ...usuarioData.pessoa,
            [pessoaField]: value,
          },
        };
      } else {
        newUsuario = {
          ...usuarioData,
          [field]: value,
        };
      }

      updateUsuarioData(newUsuario);
    } else {
      const newUsuario: UsuarioData = {
        nome: '',
        email: '',
        username: '',
        password: '',
        password_confirmation: '',
        status: '',
        foto: '',
        pessoa: {
          id: '',
          nome: '',
          email: '',
          cpf_cnpj: '',
          sexo: '',
          razao_social: '',
          inscricao_municipal: '',
          inscricao_estadual: '',
          filiacao_mae: '',
          filiacao_pai: '',
          rg: '',
          estado_civil: '',
          regime_uniao: '',
          data_nascimento: '',
          data_fundacao: '',
          enderecos: [],
          telefones: [],
        },
        user_grupos: []
      };

      updateUsuarioData(newUsuario);
    }
  }
};
