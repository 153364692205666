import api from "../api";
import TokenService from "./token.service";
import { OAUTH_CONFIG, url } from "../../environments/environments-develop";

const AuthService = {
  login: async (username: string, password: string) => {
    try {
      const response = await api.post(`${url}/api/v1/admin/core/auth/token`, {
        username,
        password,
        ...OAUTH_CONFIG,
      });
      if (response.data.access_token) {
        TokenService.setUser(response.data);
        TokenService.setLoginTime();

        const userFiliaisResponse = await api.get(
          `${url}/api/v1/admin/core/user-filiais`
        );
        const filiais = userFiliaisResponse.data.data || null;
        const firstFilialId = userFiliaisResponse.data.data[0] || null;

        if (firstFilialId) {
          TokenService.setFilialId(firstFilialId);
          TokenService.setFiliais(filiais);
          const contextFilialResponse = await api.get(
            `${url}/api/v1/admin/core/context-filiais/${firstFilialId.id}`
          );
          TokenService.setContextUser(contextFilialResponse);
          const includeParam = "pessoa.telefones,pessoa.enderecos";
          const userPerfilResponse = await api.get(
            `${url}/api/v1/admin/core/user/perfil?include=${includeParam}`
          );
          TokenService.setPerfil(userPerfilResponse.data);
          const userPermissoes: { data: Array<any> } =
            TokenService.getPerfil().data?.user_permissoes;
          let permissaoForm: Array<any> = [];

          for (const element of userPermissoes.data) {
            const existingPermissionIndex = permissaoForm.findIndex(
              (perm) => perm.name === element.grupo.data.nome
            );

            if (existingPermissionIndex !== -1) {
              permissaoForm[existingPermissionIndex].permission.push(
                element.permissoes.data.rotas.data
              );
            } else {
              permissaoForm.push({
                name: element.grupo.data.nome,
                permission: [element.permissoes.data.rotas.data],
              });
            }
          }
          TokenService.setPermissoes(permissaoForm);
        }
      }
      localStorage.setItem("isVisible", "false");
      const includeParam = "config_venda,config_carteira,pessoa.telefones";
      const retornoApi = await api.get(
        `${url}/api/v1/admin/vendas/empreendimentos?include=${includeParam}`
      );
      TokenService.setEmpreendimento(retornoApi.data.data[0]);
      return true;
    } catch (error) {
      console.error("Erro durante o login:", error);
      TokenService.clearLocalStorage();
      return error;
    }
  },

  logout: () => {
    TokenService.clearLocalStorage();
  },

  register: (username: string, email: string, password: string) => {
    return api.post("/auth/signup", {
      username,
      email,
      password,
    });
  },

  getCurrentUser: () => {
    return TokenService.getUser();
  },
};

export default AuthService;
