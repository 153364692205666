import { useEffect, useState } from "react";
import { Accordion, Button, Card, Col, Form, InputGroup, Row } from "react-bootstrap";
import { AlertService } from "../../../../components/AlertService";
import { url } from "../../../../environments/environments-develop";
import api from '../../../../services/api';
import { AutoComplete } from "primereact/autocomplete";
import { Button as ButtonAdd } from "primereact/button";
function CompradorForm(props: any) {
    const { compradorData } = props || [];
    const [table, setTable] = useState<any[]>([]);
    const [activeEventKey, setActiveEventKey] = useState<string | null>(null);
    const [listCompradores, setListCompradores] = useState([]);
    const [FilteredPessoa, setFilteredPessoa] = useState<any>(null);
    const [nomeComprador, setNomeComprador] = useState<any>(null);
    const handleChange = (index: number, field: string, value: any) => {
        props.onInputChange(index, field, value);
    };
    useEffect(() => {
        setTable(compradorData);
    }, [compradorData])
    useEffect(() => {
        if (compradorData?.length > 0) {
            compradorData.forEach((Comprador: any, index: number) => {
                Object.keys(Comprador).forEach((field) => {
                    handleChange(index, field, Comprador[field]);
                });
            });
        }
    }, [])
    
    async function InitCompradores() {
        try {
            const response = await api.get(`${url}/api/v1/admin/financeiro/cliente?include=pessoa.conjugue`);
            setListCompradores(response.data.data);
        } catch (error) {
            AlertService.error('Error', 'Ocorreu um erro ao buscar a lista de permissões. Erro:' + String(error));
        }
    }
    const search = (event: any) => {
        // Timeout to emulate a network connection
        setTimeout(() => {
            let _FilteredPessoa: any;

            if (!event.query.trim().length) {
                _FilteredPessoa = [...listCompradores];
            }
            else {
                if (event.query) {
                    _FilteredPessoa = listCompradores.filter((item: any) => {
                        return item.nome_cliente && item.nome_cliente.toLowerCase().startsWith(event.query.toLowerCase());
                        // Verifica se item.nome não é nulo ou indefinido antes de acessar toLowerCase()
                    });
                }
            }
            setFilteredPessoa(_FilteredPessoa);
        }, 250);
    }
    useEffect(() => {
        InitCompradores();
    }, []);

    const handleAddComprador = () => {
        props.onAddCompradores();
        setActiveEventKey((compradorData?.length || 0).toString());
    };

    const handleRemoveComprador = (index: number) => {
        props.onRemoveCompradores(index);
    };

    return (
        <>
            <Accordion
                style={{ minHeight: '350px', height: '-webkit-fill-available' }}
                activeKey={activeEventKey}
                onSelect={(key) => setActiveEventKey(key as string)}
            >
                <ButtonAdd
                    className="button-form"
                    style={{ marginTop: '10px', marginBottom: '10px' }}
                    icon="pi pi-plus"
                    onClick={() => handleAddComprador()}
                >
                    <p style={{ display: 'flex', justifyContent: 'center', marginTop: '13px', marginLeft: '10px' }}>Adicionar Comprador</p>
                </ButtonAdd>
                {(table || []).map((Comprador: any, index: number) => (
                    <Accordion.Item eventKey={index.toString()} key={index}>
                        <Accordion.Header>
                            Comprador {index + 1}
                        </Accordion.Header>
                        <Accordion.Body>
                            <Card.Body>
                                <Form.Group className="usuario-form">
                                    <Row className="mb-3">
                                        <Col md="4" style={{ display: 'flex', flexDirection: 'column' }}>
                                            <Form.Label>Comprador</Form.Label>
                                            <AutoComplete
                                                placeholder='Pesquisar nome'
                                                field="nome_cliente"
                                                style={{ height: "37px" }}
                                                value={Comprador?.nome_cliente ? Comprador.nome_cliente : ''}
                                                suggestions={FilteredPessoa}
                                                completeMethod={search}
                                                onChange={(e) => handleChange(index, 'nome_cliente', e.value)}
                                                onSelect={(e) => {
                                                    props.onInputChange(index, 'cliente_id', e.value.id);
                                                    props.onInputChange(index, 'nome_cliente', e.value.nome_cliente);
                                                }}
                                                dropdown
                                            />
                                        </Col>
                                        <Col md="2" style={{ padding: "0px", display: "flex", alignItems: "center", flexDirection: "column" }}>
                                            <Form.Label>Principal</Form.Label>
                                            <Form.Check
                                                style={{ display: "flex", alignItems: "center", justifyContent: "center", }}
                                                checked={compradorData[index]?.principal || false}
                                                onChange={(e) => handleChange(index, "principal", e.target.checked)}
                                                type="switch"
                                                id="custom-switch"
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="row-delete-button">
                                        <Button
                                            variant="danger"
                                            size="sm"
                                            style={{ width: '4%', marginRight: '10px' }}
                                            className="ms-2 button-delete-Endereco"
                                            onClick={() => handleRemoveComprador(index)}
                                        >
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="17"
                                                height="21"
                                                viewBox="0 0 17 21"
                                                fill="none"
                                            >
                                                <path
                                                    d="M1.57397 19.8265C1.57197 20.0893 1.67428 20.3422 1.85846 20.5296C2.04264 20.7171 2.29364 20.8238 2.55643 20.8265H13.9248C14.1876 20.8238 14.4386 20.7171 14.6228 20.5296C14.807 20.3422 14.9093 20.0893 14.9073 19.8265V6.49316H1.57397V19.8265ZM2.90731 7.8265H13.574V19.4932H2.90731V7.8265Z"
                                                    fill="white"
                                                />
                                                <path
                                                    d="M5.90734 9.15967H4.57401V17.493H5.90734V9.15967Z"
                                                    fill="white"
                                                />
                                                <path
                                                    d="M8.90734 9.15967H7.57401V17.493H8.90734V9.15967Z"
                                                    fill="white"
                                                />
                                                <path
                                                    d="M11.9073 9.15967H10.574V17.493H11.9073V9.15967Z"
                                                    fill="white"
                                                />
                                                <path
                                                    d="M11.2407 3.82617V1.82617C11.2407 1.26542 10.8453 0.826172 10.3407 0.826172H6.14066C5.636 0.826172 5.24066 1.26542 5.24066 1.82617V3.82617H0.240662V5.15951H16.2407V3.82617H11.2407ZM6.574 2.15951H9.90733V3.82617H6.574V2.15951Z"
                                                    fill="white"
                                                />
                                            </svg>
                                        </Button>
                                    </Row>
                                </Form.Group>
                            </Card.Body>
                        </Accordion.Body>
                    </Accordion.Item>
                ))}
            </Accordion>
        </>
    );
}

export default CompradorForm;
