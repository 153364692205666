import iconMenuTresPonto from './../../../../../assets/images/icon-menu-tres-pontos.png';
import { Button } from 'primereact/button';
import { useSidebarContext } from './../../../../contexts/SidebarContext';
import { Col, Form, NavLink } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import { AlertService } from '../../../../components/AlertService';
import api from '../../../../services/api';
import { url } from '../../../../environments/environments-develop';

interface ContextSearchProps {
    header?: string;
    filtrosData: {
        identificador2?: string;
        logradouro?: string;
        tipo_jazigo_id?: number;
        identificador1?: string;
    };
    onInputClearList: () => void;
    onInputChangeList: (field: string, value: any) => void;
    onInputChange: (field: string, value: any) => void;
}

function FiltroJazigo(props: ContextSearchProps) {
    const { tipo_jazigo_id, logradouro, identificador2, identificador1 } = props.filtrosData || {};
    const { isVisible, updateVisibility } = useSidebarContext();
    const [listTipoJazigo, setListTipoJazigo] = useState([]);

    const toggleVisibility = () => {
        const newValue = !isVisible;
        updateVisibility(newValue);
    };

    const handleChange = (field: string, value: any) => {
        props.onInputChange(field, value);
    };

    const handleChangeList = (field: string, value: any) => {
        props.onInputChangeList(field, value);
    };

    const handleClearList = () => {
        props.onInputClearList();
    };

    async function InitTipoJazigo() {
        try {
            const response = await api.get(`${url}/api/v1/admin/cemiterio/tipo-jazigo/`);
            setListTipoJazigo(response.data.data);
        } catch (error) {
            AlertService.error('Error', 'Ocorreu um erro ao buscar a lista de profissões. Erro:' + String(error));
        }
    };
    useEffect(() => {
        InitTipoJazigo();
    }, []);

    const handleFilter = () => {
        if (tipo_jazigo_id) {
            handleChangeList('tipo_jazigo_id', tipo_jazigo_id);
        }
        if (logradouro) {
            handleChangeList('logradouro', logradouro);
        }
        if (identificador2) {
            handleChangeList('identificador2', identificador2);
        }
        if (identificador1) {
            handleChangeList('identificador1', identificador1);
        }
    };

    return (
        <div className="contentSearch">
            <div className="contentSearch-header">
                <NavLink onClick={toggleVisibility}>
                    <img
                        className="menu-context"
                        src={iconMenuTresPonto}
                    />
                </NavLink>
                <Col className='text-center' style={{ fontSize: '20px', color: '#494949' }}>{props.header}</Col>
            </div>
            <div style={{ opacity: '34%' }} className="contentTraçoBodyHeader"></div>
            <div className="contentSearch-body">
                <div className='div-calendar'>
                    <Col md="2" style={{ marginRight: "8px" }}>
                        <Form.Label>Ala</Form.Label>
                        <Form.Control
                            value={logradouro ? logradouro : ''}
                            onChange={(e) => handleChange("logradouro", e.target.value)}
                        />
                    </Col>
                    <Col md="2" style={{ marginRight: "8px" }}>
                        <Form.Label>Quadra</Form.Label>
                        <Form.Control
                            value={identificador1 ? identificador1 : ''}
                            onChange={(e) => handleChange("identificador1", e.target.value)}
                        />
                    </Col>
                    <Col md="2" style={{ marginRight: "8px" }}>
                        <Form.Label>Lote</Form.Label>
                        <Form.Control
                            value={identificador2 ? identificador2 : ''}
                            onChange={(e) => handleChange("identificador2", e.target.value)}
                        />
                    </Col>
                    <Col md="2">
                        <Form.Label>Tipo Jazigo</Form.Label>
                        <Form.Select
                            value={tipo_jazigo_id ? tipo_jazigo_id : ''}
                            onChange={(e) => handleChange("tipo_jazigo_id", e.target.value)}
                        >
                            <option value="">Selecione</option>
                            {listTipoJazigo.map((item: any) => (
                                <option key={item.id} value={item.id}>{item.sigla}</option>
                            ))}
                        </Form.Select>
                    </Col>
                </div>
                <div className='div-filtros-avancados' style={{ marginTop: '35px' }}>
                    <Button
                        onClick={handleFilter}
                        style={{ height: '30px', borderRadius: '20px', padding: '5px 10px', marginRight: '5px', fontSize: '14px' }}
                        type="button"
                        severity="success"
                        icon="pi pi-filter"
                        rounded>
                        <p style={{ marginLeft: '5px', marginTop: '13px' }}>Pesquisar</p>
                    </Button>
                    <Button
                        onClick={handleClearList}
                        style={{ height: '30px', borderRadius: '20px', padding: '5px 10px', marginRight: '5px', fontSize: '14px' }}
                        type="button"
                        severity="danger"
                        icon="pi pi-delete-left"
                        rounded>
                        <p style={{ marginLeft: '5px', marginTop: '13px' }}>Limpar</p>
                    </Button>
                </div>
            </div>
        </div>
    );
}

export default FiltroJazigo;
