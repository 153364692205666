// TipoJazigoModel.ts

export namespace TipoJazigoModel {
  export interface ITipoJazigo {
    id: number;
    descricao: string;
    qtd_gavetas: number;
    modelo: string;
    sigla: string;
    material: string;
    observacao: string;
    empreendimento_id: number;
    filial_id: number;
  }

  export class TipoJazigo implements ITipoJazigo {
    id: number;
    descricao: string;
    qtd_gavetas: number;
    modelo: string;
    sigla: string;
    material: string;
    observacao: string;
    empreendimento_id: number;
    filial_id: number;

    constructor(data: ITipoJazigo) {
      this.id = data.id;
      this.descricao = data.descricao;
      this.qtd_gavetas = data.qtd_gavetas;
      this.modelo = data.modelo;
      this.sigla = data.sigla;
      this.material = data.material;
      this.observacao = data.observacao;
      this.empreendimento_id = data.empreendimento_id;
      this.filial_id = data.filial_id;
    }

    static deserialize(data: any): TipoJazigo {
      return new TipoJazigo({
        id: data.id,
        descricao: data.descricao,
        qtd_gavetas: data.qtd_gavetas,
        modelo: data.modelo,
        sigla: data.sigla,
        material: data.material,
        observacao: data.observacao,
        empreendimento_id: data.empreendimento_id,
        filial_id: data.filial_id,
      });
    }
  }
}
