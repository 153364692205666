import { isArray } from "util";
import { Deserializable } from "../interfaces/deserializable.model";

const PESSOAL = 0;
const RESIDENCIAL = 1;
const COMERCIAL = 2;
const RECADO = 3;

export class TelefoneModel implements Deserializable {
  id: number | null = null;
  ddd: number | null = null;
  numero: number | null = null;
  observacao: string | null = null;
  tipo_telefone: number | null = null;

  deserialize(input: any): this {
    if (!isArray(input)) {
      Object.assign(this, input);
      return this;
    }
    const array: TelefoneModel[] = [];
    input.forEach((item, key) => {
      array.push(Object.assign(new TelefoneModel(), item));
    });
    return array as any;
  }

  static getTipoTelefone(tipo: any) {
    switch (tipo) {
      case PESSOAL:
        return "Pessoal";
      case RESIDENCIAL:
        return "Residencial";
      case COMERCIAL:
        return "Comercial";
      case RECADO:
        return "Recado";
      default:
        return tipo;
    }
  }

  static getTelefoneSelect() {
    return [
      { label: "Pessoal", value: PESSOAL },
      { label: "Residencial", value: RESIDENCIAL },
      { label: "Comercial", value: COMERCIAL },
      { label: "Recado", value: RECADO },
    ];
  }
}
