import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

interface Contract {
    id: number;
    numero: string;
    data_venda: Date;
    status_name: string;
}

interface ModalConfirmarReativarContratoProps {
    showConfirmarModalReativarContrato: boolean;
    selectedNodeKeys: Contract[];
    handleShowConfirmarModalReativarContrato: (show: boolean) => void;
    handleShowModalReativarContrato: (show: boolean) => void;
}

const ModalConfirmarReativarContrato: React.FC<ModalConfirmarReativarContratoProps> = (props) => {
    return (
        <Modal show={props.showConfirmarModalReativarContrato} onHide={() => props.handleShowConfirmarModalReativarContrato(false)} size="xl">
            <Modal.Header closeButton>
                <Modal.Title className="text-center">Confirmar a reativação do seguinte contrato?</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <DataTable
                    stripedRows
                    value={props.selectedNodeKeys}
                    emptyMessage={<span style={{ display: 'flex', justifyContent: 'center', fontStyle: 'italic' }}>Nenhum item encontrado.</span>}
                >
                    <Column key="id" field="id" header="Id" style={{ textAlign: 'center', borderBottom: '1px solid #bdbdbd96' }} />
                    <Column key="numero" field="numero" header="Nº Contrato" style={{ textAlign: 'center', borderBottom: '1px solid #bdbdbd96' }} />
                    <Column key="data_venda" field="data_venda" header="Data Venda" style={{ textAlign: 'center', borderBottom: '1px solid #bdbdbd96' }} />
                    <Column key="status_name" field="status_name" header="Status" style={{ textAlign: 'center', borderBottom: '1px solid #bdbdbd96' }} />
                </DataTable>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => props.handleShowConfirmarModalReativarContrato(false)}>Cancelar</Button>
                <Button variant="success" onClick={() => { props.handleShowModalReativarContrato(true); props.handleShowConfirmarModalReativarContrato(false) }}>Confirmar</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ModalConfirmarReativarContrato;
