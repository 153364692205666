import iconMenuTresPonto from './../../../../assets/images/icon-menu-tres-pontos.png';
import { Button } from 'primereact/button';
import { useSidebarContext } from './../../../contexts/SidebarContext';
import { Col, Container, Form, Modal, NavLink, Row } from 'react-bootstrap';
import iconFiltroAvancado from './../../../../assets/images/icon-filtro-avancado.png';
import { useState } from 'react';

interface ContextSearchProps {
    header?: string;
    filtrosData: {
        identificador?: string;
        n_serie?: string;
        tipo?: number;
        status?: number;
        acessorios?: string;
        patrimonio?: string;
        descricao?: string;
    };
    onInputClearList: () => void;
    onInputChangeList: (field: string, value: any) => void;
    onInputChange: (field: string, value: any) => void;
}

function FiltroProduto(props: ContextSearchProps) {
    const { identificador, n_serie, tipo, status, acessorios, patrimonio, descricao} = props.filtrosData || {};
    const { isVisible, updateVisibility } = useSidebarContext();
    const [showModal, setShowModal] = useState(false);

    const toggleVisibility = () => {
        const newValue = !isVisible;
        updateVisibility(newValue);
    };

    const handleChange = (field: string, value: any) => {
        props.onInputChange(field, value);
    };

    const handleChangeList = (field: string, value: any) => {
        props.onInputChangeList(field, value);
    };

    const handleClearList = () => {
        setShowModal(false);
        props.onInputClearList();
    };

    const handleFilter = () => {
        setShowModal(false);
        if (identificador) {
            handleChangeList('identificador', identificador);
        }
        if (n_serie) {
            handleChangeList('n_serie', n_serie);
        }
        if (tipo) {
            handleChangeList('tipo', tipo);
        }
        if (status) {
            handleChangeList('status', status);
        }
        if (acessorios) {
            handleChangeList('acessorios', acessorios);
        }
        if (patrimonio) {
            handleChangeList('patrimonio', patrimonio);
        }
        if (descricao) {
            handleChangeList('descricao', descricao);
        }
    };

    return (
        <div className="contentSearch">
            <div className="contentSearch-header">
                <NavLink onClick={toggleVisibility}>
                    <img
                        className="menu-context"
                        src={iconMenuTresPonto}
                    />
                </NavLink>
                <Col className='text-center' style={{ fontSize: '20px', color: '#494949' }}>{props.header}</Col>
            </div>
            <div style={{ opacity: '34%' }} className="contentTraçoBodyHeader"></div>
            <div className="contentSearch-body">
                <div className='div-calendar'>
                    <Col md='4' style={{ marginRight: "8px" }}>
                        <Form.Label>Identificador</Form.Label>
                        <Form.Control
                            value={identificador ? identificador : ''}
                            onChange={(e) => handleChange("identificador", e.target.value)}
                        />
                    </Col>
                    <Col md='4'>
                        <Form.Label>Número de Série</Form.Label>
                        <Form.Control
                            value={n_serie ? n_serie : ''}
                            onChange={(e) => handleChange("n_serie", e.target.value)}
                        />
                    </Col>
                </div>
                <div className='div-filtros-avancados' style={{ marginTop: '35px' }}>
                    <Button
                        style={{ height: '30px', borderRadius: '20px', padding: '5px 10px', marginRight: '5px', fontSize: '14px' }}
                        type="button"
                        onClick={() => { setShowModal(true) }}
                        rounded outlined severity="secondary">
                        <img style={{ width: '25px', height: '35px' }} src={iconFiltroAvancado} />
                    </Button>
                    <Button
                        onClick={handleFilter}
                        style={{ height: '30px', borderRadius: '20px', padding: '5px 10px', marginRight: '5px', fontSize: '14px' }}
                        type="button"
                        severity="success"
                        icon="pi pi-filter"
                        rounded>
                        <p style={{ marginLeft: '5px', marginTop: '13px' }}>Pesquisar</p>
                    </Button>
                    <Button
                        onClick={handleClearList}
                        style={{ height: '30px', borderRadius: '20px', padding: '5px 10px', marginRight: '5px', fontSize: '14px' }}
                        type="button"
                        severity="danger"
                        icon="pi pi-delete-left"
                        rounded>
                        <p style={{ marginLeft: '5px', marginTop: '13px' }}>Limpar</p>
                    </Button>
                </div>
            </div>
            <Modal
                show={showModal}
                size='lg'
                onHide={() => setShowModal(false)}
                className='modal-filtro-avancado'
            >
                <Modal.Header closeButton>
                    <Modal.Title>Filtros Avançados</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Row>
                            <Col md={3}>
                                <Form.Label>Acessórios</Form.Label>
                                <Form.Control
                                    value={acessorios || ''}
                                    onChange={(e) => handleChange("acessorios", e.target.value)}
                                />
                            </Col>
                            <Col md={3}>
                                <Form.Label>Tipo</Form.Label>
                                <Form.Select
                                    value={tipo}
                                    required
                                    onChange={(e) => handleChange("tipo", e.target.value)}
                                >
                                    <option>Selecione...</option>
                                    <option value="1">Serviço</option>
                                    <option value="2">Mercadoria</option>
                                    <option value="3">Patrimônio</option>
                                </Form.Select>
                            </Col>
                            <Col md={3}>
                                <Form.Label>Status</Form.Label>
                                <Form.Select
                                    value={status}
                                    required
                                    onChange={(e) => handleChange("status", e.target.value)}
                                >
                                    <option>Selecione...</option>
                                    <option value="0">Inativo</option>
                                    <option value="1">Ativo</option>
                                </Form.Select>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={3}>
                                <Form.Label>Patrimônio</Form.Label>
                                <Form.Control
                                    value={patrimonio || ''}
                                    onChange={(e) => handleChange("patrimonio", e.target.value)}
                                />
                            </Col>
                            <Col md={6}>
                                <Form.Label>Descrição</Form.Label>
                                <Form.Control
                                    value={descricao || ''}
                                    onChange={(e) => handleChange("descricao", e.target.value)}
                                />
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        onClick={handleFilter}
                        style={{ marginTop: '3px', width: '100px', height: '30px', borderRadius: '5px', padding: '5px 10px', marginRight: '5px', fontSize: '14px', marginLeft: '10px' }}
                        type="button"
                        severity="success"
                        icon="pi pi-filter"
                        rounded>
                        <p style={{ marginLeft: '5px', marginTop: '15px' }}>Pesquisar</p>
                    </Button>
                    <Button
                        onClick={handleClearList}
                        style={{ marginTop: '3px', width: '100px', height: '30px', borderRadius: '5px', padding: '5px 10px', marginRight: '5px ', fontSize: '14px' }}
                        type="button"
                        severity="danger"
                        icon="pi pi-delete-left"
                        rounded>
                        <p style={{ marginLeft: '5px', marginTop: '15px' }}>Limpar</p>
                    </Button>
                </Modal.Footer>
            </Modal >
        </div>
    );
}

export default FiltroProduto;
