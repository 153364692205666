import { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import api from '../../../../services/api';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import CalendarIcon from '../../../../../assets/images/icon-calendar.png';
import pt from 'date-fns/locale/pt';
import { AutoComplete } from "primereact/autocomplete";
import { url } from "../../../../environments/environments-develop";
import { AlertService } from "../../../../components/AlertService";
function ClientesForm(props: any) {
    const { corretor_nome, busca, observacao, corretor_id, status, empreendimento_id, inibe_remessa, segurado, cad_desatualizado, negativado } = props.clienteData || {};
    const [listPermi, setListPermi] = useState([]);
    const [listCorretores, setListCorretores] = useState([]);
    const [prox_cobranca, setProxCobranca] = useState<Date | null>(props.clienteData && props.clienteData?.prox_cobranca ? new Date(props.clienteData?.prox_cobranca) : null);
    const [FilteredPessoa, setFilteredPessoa] = useState<any>(null);
    const handleChange = (field: string, value: any) => {
        if (field == 'corretor_id') {
            props.onInputChange('corretor_nome', value.nome);
            props.onInputChange(field, value.id);
        } else {
            props.onInputChange(field, value);
        }
    };

    async function listEmpreendimento() {
        try {
            const response = await api.get(`${url}/api/v1/admin/vendas/empreendimentos`);
            setListPermi(response.data.data);
        } catch (error) {
            AlertService.error('Error', 'Ocorreu um erro ao buscar a lista de permissões. Erro:' + String(error));
        }
    }
    async function InitCorretores() {
        try {
            const response = await api.get(`${url}/api/v1/admin/vendas/corretores?include=pessoa`);
            setListCorretores(response.data.data);
        } catch (error) {
            AlertService.error('Error', 'Ocorreu um erro ao buscar a lista de permissões. Erro:' + String(error));
        }
    }
    useEffect(() => {
        listEmpreendimento();
        InitCorretores();
    }, []);
    const search = (event: any) => {
        // Timeout to emulate a network connection
        setTimeout(() => {
            let _FilteredPessoa: any;

            if (!event.query.trim().length) {
                _FilteredPessoa = [...listCorretores];
            }
            else {
                if (event.query) {
                    _FilteredPessoa = listCorretores.filter((item: any) => {
                        return item.nome_corretor && item.nome_corretor.toLowerCase().startsWith(event.query.toLowerCase());
                        // Verifica se item.nome não é nulo ou indefinido antes de acessar toLowerCase()
                    });
                }
            }
            setFilteredPessoa(_FilteredPessoa);
        }, 250);
    }
    return (
        <>
            <Form.Group className="usuario-form">
                <Row className="mb-3">
                    <Col md="3" style={{ display: 'flex', flexDirection: 'column' }}>
                        <Form.Label>Corretor/Vendedor</Form.Label>
                        <AutoComplete
                            placeholder='Pesquisar nome'
                            field="nome_corretor"
                            style={{ height: "37px" }}
                            value={corretor_nome ? corretor_nome : (busca ? busca : (props?.clienteData?.corretor ? props?.clienteData?.corretor?.nome_corretor : ''))}
                            suggestions={FilteredPessoa}
                            completeMethod={search}
                            onChange={(e) =>
                                handleChange('busca', e.value)}
                            onSelect={(e) =>
                                handleChange('corretor_id', e.value)}
                            dropdown
                        />
                    </Col>
                    <Col md="2">
                        <Form.Label>Status</Form.Label>
                        <Form.Select value={status || ''} onChange={(e) => handleChange('status', e.target.value)}>
                            <option>Selecione...</option>
                            <option value={"1"}>Ativado</option>
                            <option value={"0"}>Desativado</option>
                        </Form.Select>
                    </Col>
                    <Col md="3" style={{ display: 'grid' }}>
                        <Form.Label>Data da proxima cobrança</Form.Label>
                        <DatePicker
                            selected={prox_cobranca}
                            onChange={(date: Date) => {
                                setProxCobranca(date);
                                handleChange('prox_cobranca', date.toISOString()); // Adapte conforme necessário
                            }}
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            fixedHeight
                            locale={pt}
                            customInput={
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <input
                                        type="text"
                                        placeholder="Proxima Cobrança"
                                        className="dateFormFinanceiro"
                                        value={prox_cobranca ? prox_cobranca.toLocaleDateString() : ''}
                                        readOnly
                                    />
                                    <img
                                        className="calendar-icon"
                                        src={CalendarIcon}
                                    />
                                </div>
                            }
                        />
                    </Col>
                    <Col md="2" style={{ padding: "0px", display: "flex", alignItems: "center", flexDirection: "column" }}>
                        <Form.Label>Cadastro desatualizado?</Form.Label>
                        <Form.Check
                            style={{ display: "flex", alignItems: "center", justifyContent: "center", }}
                            checked={cad_desatualizado || false}
                            onChange={(e) => handleChange("cad_desatualizado", e.target.checked)}
                            type="switch"
                            id="custom-switch"
                        />
                    </Col>
                    <Col md="2" style={{ padding: "0px", display: "flex", alignItems: "center", flexDirection: "column" }}>
                        <Form.Label>Negativado?</Form.Label>
                        <Form.Check
                            style={{ display: "flex", alignItems: "center", justifyContent: "center", }}
                            checked={negativado || false}
                            onChange={(e) => handleChange("negativado", e.target.checked)}
                            type="switch"
                            id="custom-switch"
                        />
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col md="2" style={{ padding: "0px", display: "flex", alignItems: "center", flexDirection: "column" }}>
                        <Form.Label>Segurado?</Form.Label>
                        <Form.Check
                            style={{ display: "flex", alignItems: "center", justifyContent: "center", }}
                            checked={segurado || false}
                            onChange={(e) => handleChange("segurado", e.target.checked)}
                            type="switch"
                            id="custom-switch"
                        />
                    </Col>
                    <Col md="2" style={{ padding: "0px", display: "flex", alignItems: "center", flexDirection: "column" }}>
                        <Form.Label>Inibir Remessa?</Form.Label>
                        <Form.Check
                            style={{ display: "flex", alignItems: "center", justifyContent: "center", }}
                            checked={inibe_remessa || false}
                            onChange={(e) => handleChange("inibe_remessa", e.target.checked)}
                            type="switch"
                            id="custom-switch"
                        />
                    </Col>
                    <Col>
                        <Form.Label>Observação</Form.Label>
                        <Form.Control value={observacao} onChange={(e) => handleChange('observacao', e.target.value)} />
                    </Col>
                </Row>
            </Form.Group>
        </>
    );
}

export default ClientesForm;
