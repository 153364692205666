import { Col, Form } from "react-bootstrap";
import EditSecaoJazigo from "../../../Formularios/EditSecaoJazigo";
import EditCompradores from "../../../Formularios/EditCompradores";
import EditSecaoParcelamento from "../../../Formularios/EditSecaoParcelamento";
import EditProdutosServicos from "../../../Formularios/EditProdutosServicos";
import EditSecaoEntrada from "../../../Formularios/EditSecaoEntrada";

interface ProgressBarFormProps {
    currentPart: number;
    formBasico: any;
    onInputChange: (field: string, value: string) => void;
}

const EditVendaForm: React.FC<ProgressBarFormProps> = ({
    currentPart,
    formBasico,
    onInputChange
}) => {

    return (
        <Form.Group className="pessoa-form" style={{ width: '-webkit-fill-available', height: '-webkit-fill-available' }}>
            <Col md="12">
                {currentPart === 0 && (
                    <EditSecaoJazigo
                        secaoImovelData={formBasico}
                        onInputChange={(field: string, value: string) => onInputChange(`${field}`, value)} />
                )}
                {currentPart === 1 && (
                    <EditCompradores
                        compradorData={formBasico?.cliente_compradores || []}
                    />
                )}
                {currentPart === 2 && (
                    <EditProdutosServicos
                        produtosServicosData={formBasico?.vendas_itens || []}
                        onInputChange={(field: string, value: string) => onInputChange(`${field}`, value)}
                    />
                )}
                {currentPart === 3 && (
                    <EditSecaoEntrada
                        secaoEntradaData={formBasico}
                        onInputChange={(field: string, value: string) => onInputChange(`${field}`, value)} />
                )}
                {currentPart === 4 && (
                    <EditSecaoParcelamento
                        EditSecaoParcelamentoData={formBasico}
                        onInputChange={(field: string, value: string) => onInputChange(`${field}`, value)} />
                )}
            </Col>
        </Form.Group>
    );
}

export default EditVendaForm;
