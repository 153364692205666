import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import { useAppContext } from '../../../../contexts/AppContext';
import { url } from '../../../../environments/environments-develop';
import { AlertService } from '../../../../components/AlertService';
import FormTipoJazigo from './forms';

interface PropsData {
    descricao?: string;
    qtd_gavetas?: number;
    modelo?: number;
    sigla?: string;
    material?: string;
    observacao?: string;
}
function EditTipoJazigo() {
    const { setUrl, setBody, setShowButtonSave, setTypeRequest, setItemsBreadsCrumbs } = useAppContext();
    const [Data, setData] = useState<PropsData>({});

    useEffect(() => {
        try {
            setItemsBreadsCrumbs([
                {
                    label: 'Cemitério',
                    url: '/cemiterio',
                },
                {
                    label: 'Estruturas'
                },
                {
                    label: 'Tipos de Jazigos',
                    url: '/cemiterio/tipo-jazigo',
                },
                {
                    label: 'Editar'
                }
            ]);
            const storedData = localStorage.getItem('dadosEdicao');
            if (storedData) {
                setData((prevGrupoData) => {
                    const dadosAtualizados = {
                        ...prevGrupoData,
                        ...JSON.parse(storedData),
                    };
                    return dadosAtualizados;
                });
                setTypeRequest('PUT');
                setUrl(`${url}/api/v1/admin/cemiterio/tipo-jazigo/${storedData && JSON.parse(storedData).id}`);
                setShowButtonSave(true);
            }
            return () => {
                localStorage.removeItem('dadosEdicao');
            };
        } catch (error) {
            AlertService.error("error", String(error))
        }
    }, []);

    useEffect(() => {
        setBody(Data)
    }, [Data]);

    const handleChange = (field: string, value: string) => {
        setData((prevGrupoData) => {
            return {
                ...prevGrupoData,
                [field]: value
            };
        });
        setTypeRequest('PUT');
    }
    return (
        <>
            <div className="body-content">
                <div className="form-create-group">
                    <div className="form-create-group-header">
                        <h3>Alterar Tipo de Jazigo</h3>
                    </div>
                </div>
                <div className="form-group-create">
                    <Container>
                        <FormTipoJazigo
                            initialRightValues={Data}
                            Data={Data}
                            onInputChange={(field: string, value: any) => handleChange(field, value)}
                        />
                    </Container>
                </div>
            </div>
        </>
    );
}

export default EditTipoJazigo;
