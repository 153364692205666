import { EnderecoData } from "../contexts/EnderecoContext";

export const changeEndereco = (
  data: any,
  updateData: any,
  index: number,
  field: string,
  value: any
) => {
  const enderecos = data?.pessoa?.enderecos;
  if (data && enderecos && index >= 0 && index < enderecos.length) {
    updateData((prevFormBasico: any) => {
      const updatedEnderecos = [...prevFormBasico.pessoa.enderecos];

      updatedEnderecos[index] = {
        ...updatedEnderecos[index],
        [field]: value,
      };
      return {
        ...prevFormBasico,
        pessoa: {
          ...prevFormBasico.pessoa,
          enderecos: updatedEnderecos,
        },
      };
    });
  }
};
export const changeEnderecoContaFinanceira = (
  data: any,
  updateData: any,
  field: string,
  value: any
) => {
  const enderecos = data.pessoa.enderecos ? data?.pessoa?.enderecos[0] : [];

  if (data && enderecos) {
    updateData((prevFormBasico: any) => {
      return {
        ...prevFormBasico,
        informacao_bancaria: {
          ...prevFormBasico.informacao_bancaria,
          pessoa: {
            ...prevFormBasico.informacao_bancaria.pessoa,
            enderecos: [
              {
                ...enderecos,
                [field]: value,
              },
            ],
          },
        },
      };
    });
  }
};

export const addEndereco = (
  data: any,
  updateData: ((data: any) => void) | undefined
) => {
  if (data && updateData) {
    const novoEndereco: EnderecoData = {
      apelido: "",
      logradouro: "",
      tipo_endereco: "",
      cep: "",
      numero: "",
      cidade: null,
      cidade_nome: "",
      cidade_id: "",
      complemento: "",
    };
    const enderecos = data?.pessoa?.enderecos ? data?.pessoa?.enderecos : [];
    updateData({
      ...data,
      pessoa: {
        ...data.pessoa,
        enderecos: [...enderecos, novoEndereco],
      },
    });
  }
};

export const removeEndereco = (
  data: any,
  updateData: ((data: any) => void) | undefined,
  index: number
) => {
  if (data && updateData) {
    const updatedEnderecos = [...data?.pessoa?.enderecos];
    updatedEnderecos.splice(index, 1);
    updateData({
      ...data,
      pessoa: {
        ...data?.pessoa,
        enderecos: updatedEnderecos,
      },
    });
  }
};
