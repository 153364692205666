import { Col, Form, InputGroup, Row } from "react-bootstrap";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import CalendarIcon from './../../../../../assets/images/icon-calendar.png';
import { useEffect, useState } from "react";
import { mask } from "../../../../contexts/CpfCnpj";
import pt from "date-fns/locale/pt";
import InputValidationMiddleware from "./../../../../validators/InputValidationMiddleware";
import { AlertService } from "../../../../components/AlertService";
import { AutoComplete } from "primereact/autocomplete";
import { url } from "../../../../environments/environments-develop";
import api from "../../../../services/api";
import { InputNumber } from "primereact/inputnumber";
import { Calendar } from "primereact/calendar";
import { addLocale } from "primereact/api";

interface FormData {
    [key: string]: any;
}

function CaracteristicaForm(props: any) {
    const [formData, setFormData] = useState<FormData>(props.caracteristicaData || {});
    const [FilteredNaturalidade, setFilteredNaturalidade] = useState<any>(null);
    const [FilteredProfissao, setFilteredProfissao] = useState<any>(null);
    const [FilteredReligiao, setFilteredReligiao] = useState<any>(null);
    const [listCidade, setListCidade] = useState([]);
    const [listProfissao, setListProfissao] = useState([]);
    const [listReligiao, setListReligiao] = useState([]);
    const [dataNascimento, setDataNascimento] = useState<Date | null>(null);

    addLocale('pt', {
        firstDayOfWeek: 0,
        dayNames: ['domingo', 'segunda-feira', 'terça-feira', 'quarta-feira', 'quinta-feira', 'sexta-feira', 'sábado'],
        dayNamesShort: ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sáb'],
        dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
        monthNames: ['janeiro', 'fevereiro', 'março', 'abril', 'maio', 'junho', 'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro'],
        monthNamesShort: ['jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez'],
        today: 'Hoje',
        clear: 'Limpar'
    });
    
    useEffect(() => {
        const fetchData = async () => {
            try {
                const cidades = await api.get(`${url}/api/v1/admin/core/cidades/listar-cidades`);
                const profissoes = await api.get(`${url}/api/v1/admin/cemiterio/listar-profissao`);
                const religioes = await api.get(`${url}/api/v1/admin/cemiterio/listar-religiao`);
                setListCidade(cidades.data.data);
                setListProfissao(profissoes.data.data);
                setListReligiao(religioes.data.data);
            } catch (error) {
                AlertService.error('Error', 'Ocorreu um erro ao buscar as listas. Erro: ' + String(error));
            }
        };
        fetchData();
    }, []);

    const searchNaturalidade = (event: any) => {
        setTimeout(() => {
            let _FilteredNaturalidade: any;

            if (!event.query.trim().length) {
                _FilteredNaturalidade = [...listCidade];
            } else {
                _FilteredNaturalidade = listCidade.filter((item: any) => {
                    return item?.nome_uf?.toLowerCase().startsWith(event.query.toLowerCase());
                });
            }
            setFilteredNaturalidade(_FilteredNaturalidade);
        }, 250);
    }

    const searchProfissao = (event: any) => {
        setTimeout(() => {
            let _FilteredProfissao: any;
            if (!event.query.trim().length) {
                _FilteredProfissao = [...listProfissao];
            } else {
                _FilteredProfissao = listProfissao.filter((item: any) => {
                    return item?.descricao?.toLowerCase().startsWith(event.query.toLowerCase());
                });
            }
            setFilteredProfissao(_FilteredProfissao);
        }, 250);
    }

    const searchReligiao = (event: any) => {
        setTimeout(() => {
            let _FilteredReligiao: any;
            if (!event.query.trim().length) {
                _FilteredReligiao = [...listReligiao];
            } else {
                _FilteredReligiao = listReligiao.filter((item: any) => {
                    return item?.descricao?.toLowerCase().startsWith(event.query.toLowerCase());
                });
            }
            setFilteredReligiao(_FilteredReligiao);
        }, 250);
    }

    const handleChangeNaturalidade = (field: string, value: any) => {
        props.onInputChangeNaturalidade('naturalidade_label', value);
        props.onInputChangeNaturalidade(field, value);
    };

    const handleChangeProfissao = (field: string, value: any) => {
        props.onInputChangeProfissao('descricao', value);
        props.onInputChangeProfissao(field, value);
    };

    const handleChangeReligiao = (field: string, value: any) => {
        props.onInputChangeReligiao('descricao', value);
        props.onInputChangeReligiao(field, value);
    };

    const handleChange = (field: string, value: any) => {
        if (field === 'origem' || field === 'plantonista' || field === 'guia') {
            if (value.length <= 100) {
                setFormData(prev => ({
                    ...prev,
                    [field]: value
                }));
                props.onInputChange(field, value);
            }
        }
        else if (field === 'pessoa.data_nascimento') {
            const date = new Date(value);
            if (isValidDate(date)) {
                setFormData(prev => ({
                    ...prev,
                    [field]: date.toISOString()
                }));
                props.onInputChange(field, date.toISOString());
            }
        }
        else if (field === 'idade' || field === 'peso') {
            const num = Number(value);
            if (!isNaN(num) && num >= 0 && num <= (field === 'idade' ? 150 : 500)) {
                setFormData(prev => ({
                    ...prev,
                    [field]: num
                }));
                props.onInputChange(field, num);
            }
        }
        else if (field === 'pessoa.estado_civil') {
            const validOptions = [1, 2, 3, 4, 5, 6];
            if (validOptions.includes(Number(value))) {
                setFormData(prev => ({
                    ...prev,
                    [field]: Number(value)
                }));
                props.onInputChange(field, Number(value));
            }
        }
        else {
            setFormData(prev => ({
                ...prev,
                [field]: value
            }));
            props.onInputChange(field, value);
        }
    };

    const isValidDate = (date: any): date is Date => {
        return date instanceof Date && !isNaN(date.getTime());
    };

    useEffect(() => {
        if (props.caracteristicaData) {
            setFormData(props.caracteristicaData);
        }
        if (props.caracteristicaData?.pessoa?.data_nascimento) {
            const nascimentoDate = new Date(props.caracteristicaData?.pessoa?.data_nascimento);
            if (isValidDate(nascimentoDate)) {
                setDataNascimento(nascimentoDate);
            }
        }
    }, [props.caracteristicaData]);

    return (
        <>
            <Form className="pessoa-form">
                <Col>
                    <Row className="mb-3">
                        <Col >
                            <Form.Label>Origem</Form.Label>
                            <InputGroup hasValidation>
                                <Form.Control
                                    value={formData.origem}
                                    onChange={(e) => handleChange("origem", e.target.value)}
                                />
                            </InputGroup>
                        </Col>
                        <Col >
                            <Form.Label>Plantonista</Form.Label>
                            <InputGroup hasValidation>
                                <Form.Control
                                    value={formData.plantonista}
                                    onChange={(e) => handleChange("plantonista", e.target.value)}
                                />
                            </InputGroup>
                        </Col>
                        <Col >
                            <Form.Group>
                                <Form.Label>Guia</Form.Label>
                                <InputGroup hasValidation>
                                    <Form.Control
                                        value={formData.guia}
                                        onChange={(e) => handleChange("guia", e.target.value)}
                                    />
                                </InputGroup>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col style={{ display: 'grid' }}>
                            <Form.Label>Data Nascimento</Form.Label>
                            <Calendar
                                value={dataNascimento || null}
                                onChange={(event: any) => {
                                    const selectedDates = event.value;
                                    setDataNascimento(selectedDates);
                                    handleChange('pessoa.data_nascimento', selectedDates ? selectedDates.toISOString() : null);
                                }}
                                placeholder="Data de Nascimento"
                                showButtonBar
                                selectionMode="single"
                                dateFormat="dd/mm/yy"
                                locale="pt"
                                readOnlyInput
                                style={{ textAlign: 'center', width: '100%' }}
                                panelClassName="custom-calendar-panel"
                                required
                            />
                        </Col>
                        <Col style={{ display: 'grid' }}>
                            <Form.Label>Idade</Form.Label>
                            <InputNumber
                                value={formData.idade || ''}
                                onChange={(e) => handleChange("idade", e.value)}
                                mode="decimal"
                                min={0}
                                max={999}
                            />
                        </Col>
                        <Col style={{ display: 'grid' }}>
                            <Form.Label>Peso</Form.Label>
                            <InputNumber
                                value={formData.peso}
                                onChange={(e) => handleChange("peso", e.value)}
                                min={0}
                                max={999}
                                suffix=" kg"
                            />
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col style={{ display: 'grid' }}>
                            <Form.Label>Naturalidade</Form.Label>
                            <AutoComplete
                                required
                                placeholder='Pesquisar Naturalidade'
                                field="nome_uf"
                                value={formData?.pessoa ? formData?.pessoa?.naturalidade_label : ''}
                                suggestions={FilteredNaturalidade}
                                completeMethod={searchNaturalidade}
                                onChange={(e) => handleChangeNaturalidade('naturalidade_id', e.target.value)}
                                dropdown
                            />
                        </Col>
                        <Col >
                            <Form.Label>Estado Civil</Form.Label>
                            <Form.Select value={formData?.pessoa?.estado_civil} onChange={(e) => handleChange('pessoa.estado_civil', e.target.value)}>
                                <option>Selecione...</option>
                                <option value="1">Solteiro</option>
                                <option value="2">Casado</option>
                                <option value="3">Viúvo</option>
                                <option value="4">Separado</option>
                                <option value="5">Divorciado</option>
                                <option value="6">União Estável</option>
                            </Form.Select>
                        </Col>

                        <Col style={{ display: 'grid' }}>
                            <Form.Label>Profissão</Form.Label>
                            <AutoComplete
                                placeholder='Pesquisar Profissão'
                                field="descricao"
                                value={formData?.profissao ? formData?.profissao?.descricao : ''}
                                suggestions={FilteredProfissao}
                                completeMethod={searchProfissao}
                                onChange={(e) => handleChangeProfissao('descricao', e.target.value)}
                                dropdown
                            />
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col style={{ display: 'grid' }}>
                            <Form.Label>Religião</Form.Label>
                            <AutoComplete
                                placeholder='Pesquisar Religião'
                                field="descricao"
                                value={formData?.religiao ? formData?.religiao?.descricao : ''}
                                suggestions={FilteredReligiao}
                                completeMethod={searchReligiao}
                                onChange={(e) => handleChangeReligiao('descricao', e.target.value)}
                                dropdown
                            />
                        </Col>
                        <Col >
                            <Form.Label>Filiação Pai</Form.Label>
                            <InputGroup hasValidation>
                                <Form.Control
                                    value={formData?.pessoa?.filiacao_pai}
                                    onChange={(e) => handleChange("pessoa.filiacao_pai", e.target.value)}
                                />
                            </InputGroup>
                        </Col>
                        <Col >
                            <Form.Label>Filiação Mãe</Form.Label>
                            <InputGroup hasValidation>
                                <Form.Control
                                    value={formData?.pessoa?.filiacao_mae}
                                    onChange={(e) => handleChange("pessoa.filiacao_mae", e.target.value)}
                                />
                            </InputGroup>
                        </Col>
                    </Row>
                </Col>
            </Form>
        </>
    );
};

export default CaracteristicaForm;
