import React, { useState, useEffect } from 'react';
import { Col, Form, Row } from 'react-bootstrap';

function ClassificacaoImovel(props: any) {
    const [formData, setFormData] = useState<any>({});

    useEffect(() => {
        setFormData(props.formData);
    }, [props.formData]);

    const handleFormChange = (field: string, value: any) => {
        props.onInputChange(field, value);
    }

    return (
        <>
            <Row className="group-form">
                <Col>
                    <Form.Label>Em qual Coluna está o id da tabela?</Form.Label>
                    <Form.Control
                        value={formData.id_externo || ''}
                        onChange={(e) => handleFormChange('id_externo', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está o Rótulo da Classificação do Imovel?</Form.Label>
                    <Form.Control
                        value={formData.rotulo || ''}
                        onChange={(e) => handleFormChange('rotulo', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está o valor por m² da Classificação do Imovel?</Form.Label>
                    <Form.Control
                        value={formData.valor_m2 || ''}
                        onChange={(e) => handleFormChange('valor_m2', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está o empreendimento?</Form.Label>
                    <Form.Control
                        value={formData.empreendimento_id || ''}
                        onChange={(e) => handleFormChange('empreendimento_id', e.target.value)} />
                </Col>
            </Row>
        </>
    );
}

export default ClassificacaoImovel;
