import { showToast } from "../../../../components/ToastComponent";
import api from "../../../../services/api";
import { AlertService } from "../../../../components/AlertService";
import { url } from "../../../../environments/environments-develop";

export const handleToggleClickEmitirContrato = (
  data: any,
  onInputChange: (field: string, value: any) => void,
  setShowModalEmitirContrato: (value: boolean) => void
) => {
  if (data) {
    onInputChange("numero_contrato", Number(data.numero));
    setShowModalEmitirContrato(true);
  } else {
    showToast("error", "Selecione um contrato para fazer a reativação!");
  }
};
export const fetchData = async (
  urlSearch: any,
  currentPage: any,
  setTitles: (value: any) => void,
  setTotalPages: (value: any) => void,
  setNodes: (value: any) => void
) => {
  try {
    const includeParam =
      "config_venda,config_carteira,pessoa.telefones,pessoa.telefones,pessoa.enderecos,tipo_label,filhos.filhos.filhos.filhos.filhos.filhos";
    const response = await api.get(
      `${url}${urlSearch}&include=${includeParam}&page=${currentPage + 1}`
    );
    if (response.status === 200) {
      const titles = response.data.headers.titles;
      setTitles(titles);
      const data = response.data.data;
      const totalPages = response.data.meta.pagination.total_pages;
      setTotalPages(totalPages);
      setNodes(data);
    }
  } catch (error: any) {
    let mensagens: string[] = [];
    mensagens.push(error?.response?.data?.error?.message);
    AlertService.error("Erro!", String(mensagens));
  }
};

export const setSessionEditData = async (
  value: any,
  urlSearchDataPut: any,
  url_redirect_edit: any
) => {
  try {
    const includeParam =
      "cliente_compradores,empreendimento,filial,corretor,imovel,coordenador";
    const response = await api.get(
      `${url}${urlSearchDataPut}${value}?include=${includeParam}`
    );
    if (response.status === 200) {
      await localStorage.setItem("dadosEdicao", JSON.stringify(response.data));
      window.location.href = url_redirect_edit;
    }
  } catch (error: any) {
    let mensagens: string[] = [];
    mensagens.push(error?.response?.data?.error?.message);
    AlertService.error("Erro!", String(mensagens));
  }
};

export const handleToggleClickCancelarContrato = (
  data: any,
  setSelectedNodeKeys: (value: any) => void,
  setShowConfirmarModalCancelarContrato: (value: boolean) => void
) => {
  if (data) {
    setSelectedNodeKeys([data]);
    setShowConfirmarModalCancelarContrato(true);
  } else {
    showToast("error", "Selecione um contrato para fazer o cancelamento!");
  }
};

export const handleToggleClickReativarContrato = (
  data: any,
  setSelectedNodeKeys: (value: any) => void,
  setShowConfirmarModalReativarContrato: (value: boolean) => void
) => {
  if (data) {
    setSelectedNodeKeys([data]);
    setShowConfirmarModalReativarContrato(true);
  } else {
    showToast("error", "Selecione um contrato para fazer a reativação!");
  }
};

export const getStatusColorCard = (status: any) => {
  switch (status) {
    case "Faltando Acerto":
      return "#ff9800";
    case "Acertado Intermediação":
      return "#ff9800";
    case "Cancelado":
      return "#f44336";
    case "Suspenso":
      return "#f44336";
    case "Vigente":
      return "#289b1e";
    case "Em distrato":
      return "#000";
    case "Quitado":
      return "#fff";
    default:
      return "#289b1e";
  }
};

export async function cancelamento(
  selectedNodeKeys: any,
  formCancelamento: any,
  setShowModalCancelarContrato: (value: boolean) => void
) {
  try {
    const response: any = await api.post(
      `${url}/api/v1/admin/vendas/cancelar-contrato/${selectedNodeKeys[0].id}`,
      formCancelamento
    );
    if (response.data.success === true) {
      setShowModalCancelarContrato(false);
      AlertService.sucess("Sucesso", "Contrato Cancelado com sucesso");
    }
  } catch (error: any) {
    let mensagens: string[] = [];
    mensagens.push(error?.response?.data?.error?.message);
    AlertService.error("Erro!", String(mensagens));
  }
}
export async function reativacao(
  selectedNodeKeys: any,
  formCancelamento: any,
  setShowModalReativarContrato: (value: boolean) => void
) {
  try {
    const response: any = await api.post(
      `${url}/api/v1/admin/vendas/reativar-contrato/${selectedNodeKeys[0].id}`,
      formCancelamento
    );
    if (response.data.success === true) {
      setShowModalReativarContrato(false);
      AlertService.sucess("Sucesso", "Contrato Cancelado com sucesso");
    }
  } catch (error: any) {
    let mensagens: string[] = [];
    mensagens.push(error?.response?.data?.error?.message);
    AlertService.error("Erro!", String(mensagens));
  }
}
export const handleOpenModalAnexo = (
  row: any,
  setSelectedRow: (value: any) => void,
  setShowModalAnexo: (value: any) => void
) => {
  setSelectedRow(row.id);
  setShowModalAnexo(true);
};
export async function emitirContrato(
  formEmissao: any,
  setShowModalCancelarContrato: (value: boolean) => void
) {
  try {
    const response: any = await api.post(
      `${url}/api/v1/admin/vendas/emitir-contratos`,
      formEmissao
    );
    if (response.status === 200) {
      const link = response.data.link;
      const nameFile = response.data.nameFile;
      downloadBoleto(link, nameFile);
      setShowModalCancelarContrato(false);
      AlertService.sucess("Sucesso", "Emissão do contrato feita com sucesso");
    }
  } catch (error: any) {
    let mensagens: string[] = [];
    mensagens.push(error?.response?.data?.error?.message);
    AlertService.error("Erro!", String(mensagens));
  }
}
function downloadBoleto(linkBoleto: string, nameFile: string): void {
  fetch(linkBoleto)
    .then((response) => response.blob())
    .then((blob) => {
      const urlCreate = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = urlCreate;
      link.setAttribute("download", `${nameFile}`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    })
    .catch((error) => {
      console.error("Erro ao baixar o arquivo:", error);
    });
}
 
