import axios from "../../../services/api";
import { Observable, from, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { url, url_financeiro } from "../../../environments/environments-develop";
import { IRenegociacao, RenegociacaoModel } from "../Models/RenegociacaoModel";
  
interface IApiResponse<T> {
  data: T;
  headers: {
    titles: string[];
  };
  meta: any;
}

class RenegociacaoService {
  private resourceUrl = `${url_financeiro}/api/v1/admin/financeiro/renegociacao`;

  list(
    params: any = {},
    headers: any = null,
    updateTitles: (titles: string[]) => void,
    updatePagination: (totalPages: number) => void
  ): Observable<RenegociacaoModel[]> {
    return from(
      axios
        .get<IApiResponse<IRenegociacao[]>>(this.resourceUrl, {
          params,
          headers,
        })
        .then((response) => {
          updateTitles(response.data.headers.titles);
          updatePagination(response.data.meta.pagination.total_pages);
          return response.data.data.map((item) =>
            new RenegociacaoModel().deserialize(item)
          );
        })
    ).pipe(catchError((error) => throwError(error)));
  }

  get(
    id: number,
    params: any = {},
    headers: any = null
  ): Observable<RenegociacaoModel> {
    return from(
      axios
        .get<IRenegociacao>(`${this.resourceUrl}/${id}`, {
          params,
          headers,
        })
        .then((response) =>
          new RenegociacaoModel().deserialize(response.data)
        )
    ).pipe(catchError((error) => throwError(error)));
  }

  create(
    data: any,
    params: any = {},
    headers: any = null
  ): Observable<RenegociacaoModel> {
    return from(
      axios
        .post<IRenegociacao>(this.resourceUrl, data, {
          params,
          headers,
        })
        .then((response) =>
          new RenegociacaoModel().deserialize(response.data)
        )
    ).pipe(catchError((error) => throwError(error)));
  }

  update(
    id: number,
    data: any,
    params: any = {},
    headers: any = null
  ): Observable<RenegociacaoModel> {
    return from(
      axios
        .put<IRenegociacao>(`${this.resourceUrl}/${id}`, data, {
          params,
          headers,
        })
        .then((response) =>
          new RenegociacaoModel().deserialize(response.data)
        )
    ).pipe(catchError((error) => throwError(error)));
  }

  excluir(params: any = {}, headers: any = null): Observable<any> {
    return from(
      axios
        .post(`${this.resourceUrl}/destroy-all`, { ids: params }, { headers })
        .then((response) => response.data)
    ).pipe(catchError((error) => throwError(error)));
  }
}

export default new RenegociacaoService();
