import React, { useEffect, useState } from 'react';
import { Col, Container, Form, Modal, Row } from 'react-bootstrap';
import { AlertService } from '../../../../../../components/AlertService';
import { url } from '../../../../../../environments/environments-develop';
import api from '../../../../../../services/api';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import pt from 'date-fns/locale/pt';
import CalendarIcon from '../../../../../../../assets/images/icon-calendar.png';
import { Button } from 'primereact/button';
import { format } from 'date-fns';

interface ModalInputExumaçãoProps {
    showModal: boolean;
    handleClose: () => void;
    gavetaSelect: any;
}

const ModalInputExumação: React.FC<ModalInputExumaçãoProps> = ({ showModal, handleClose, gavetaSelect }) => {
    const [show, setShow] = useState(showModal);
    const [formBasico, setFormBasico] = useState<any | null>(null);
    const [data_exumacao, setData_exumacao] = useState<any | null>(null);

    useEffect(() => {
        if (gavetaSelect) {
            setFormBasico({ 
                "imovel_id": gavetaSelect.imovel_id,
                "identificador_gaveta": gavetaSelect.identificador_gaveta
            });
        }
    }, [gavetaSelect]);

    useEffect(() => {
        setShow(showModal);
    }, [showModal]);

    const handleSaveButton = async (): Promise<void> => {
        try {
            const response = await api.put(`${url}/api/v1/admin/cemiterio/imovel-gaveta/exumacao`, formBasico);
            if (response.status === 200) {
                AlertService.sucess('Sucesso!', 'Salvo com sucesso!').then(() => {
                    setShow(false);
                    window.location.reload();
                });
            }
        } catch (error: any) {
            AlertService.infomessage('Erro!', String(error?.response?.data?.error?.message?.inumado_id)).then(() => {
                setShow(false);
            });
        }
    };

    const handleChange = (field: string, value: any) => {
        setFormBasico((prevForm: any) => ({
            ...prevForm,
            [field]: value
        }));
    };

    const formattedDate = gavetaSelect?.data_sepultamento
        ? format(new Date(gavetaSelect.data_sepultamento), 'dd/MM/yyyy')
        : '';
    
    return (
        <Modal
            show={show}
            onHide={handleClose}
            size="lg"
            className='modal-input-inumado'
        >
            <Modal.Header closeButton>
                <Modal.Title>Exumar Inumado</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container>
                    <Row className='mb-3'>
                        <Col md="3">
                            <Form.Label>ID Gaveta</Form.Label>
                            <Form.Control
                                disabled
                                value={gavetaSelect?.identificador_gaveta}
                            />
                        </Col>
                        <Col md='6'>
                            <Form.Label>Nome do Inumado</Form.Label>
                            <Form.Control
                                value={gavetaSelect?.ocupante}
                                disabled
                            />
                        </Col>
                        <Col md="3">
                            <Form.Label>Data Sepultamento</Form.Label>
                            <Form.Control
                                value={formattedDate}
                                disabled
                            />
                        </Col>
                    </Row>

                    <Row className='mb-3'>
                        <Col md="3">
                            <Form.Label>Data Exumação</Form.Label>
                            <Form.Group controlId="formGridState">
                                <DatePicker
                                    selected={data_exumacao ? new Date(data_exumacao) : null}
                                    onChange={(date: Date) => {
                                        const formattedDate = date.toISOString().split('T')[0];
                                        setData_exumacao(formattedDate);
                                        handleChange('data_exumacao', formattedDate);
                                    }}
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    fixedHeight
                                    locale={pt}
                                    customInput={
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <input
                                                type="date"
                                                style={{ width: '150px' }}
                                                className="dateFormFinanceiro"
                                                value={data_exumacao ? data_exumacao : ''}
                                                readOnly
                                            />
                                            <img
                                                className="calendar-icon"
                                                src={CalendarIcon}
                                            />
                                        </div>
                                    }
                                />
                            </Form.Group>
                        </Col>
                        <Col md='9'>
                            <Form.Label>Motivo</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={1}
                                onChange={(e) => handleChange('motivo_exumacao', e.target.value)}
                            />
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <div style={{ display: 'flex' }}>
                    <Button
                        className='button-save-or-update'
                        style={{ marginRight: '27px', borderRadius: '5px' }}
                        onClick={handleSaveButton}
                    >
                        Confirmar
                    </Button>
                </div>
            </Modal.Footer>
        </Modal>
    );
};

export default ModalInputExumação;
