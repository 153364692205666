import { Col, Form, Row } from "react-bootstrap";

function FilailForm(props: any) {
    const { nome_conta, matriz, tipo_empresa } = props.filialData || {};

    const handleChange = (field: string, value: any) => {
        props.onInputChange(field, value);
    };

    return (
        <>
            <Form.Group className="usuario-form">
                <Row className="mb-3">
                    <Col md="1">
                        <Form.Label>Matriz</Form.Label>
                        <Form.Select value={matriz} onChange={(e) => handleChange('matriz', e.target.value)}>
                            <option value={0}>Não</option>
                            <option value={1}>Sim</option>
                        </Form.Select>
                    </Col>
                    <Col md="3">
                        <Form.Label>Nome da Conta</Form.Label>
                        <Form.Control value={nome_conta} onChange={(e) => handleChange('nome_conta', e.target.value)} />
                    </Col>
                    <Col md="3">
                        <Form.Label>Tipo Empresa</Form.Label>
                        <Form.Select value={tipo_empresa || ''} onChange={(e) => handleChange('tipo_empresa', e.target.value)}>
                            <option>Selecione...</option>
                            <option value={1}>Imobiliario</option>
                            <option value={2}>Funerário</option>
                            <option value={3}>Rodoviário</option>
                        </Form.Select>
                    </Col>
                </Row>
            </Form.Group>
        </>
    );
}

export default FilailForm;
