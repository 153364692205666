function Legenda(props: any) {
    return (
        <div style={{ display: 'flex', justifyContent: 'space-between', margin: '14px' }}>
            <div style={{ display: 'flex' }}>
                <p style={{ margin: '0px' }}>Legenda: </p>
                <div style={{ display: 'flex', marginLeft: '8px', }}>
                    <div style={{
                        backgroundColor: `#ff9800`,
                        margin: '3px',
                        height: '5px',
                        marginRight: '8px',
                        border: `2px solid #ff9800`,
                        borderRadius: '12px',
                        boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.32)',
                        padding: '8px'
                    }}>
                    </div>
                    <p style={{ margin: '0px' }}>Faltando Acertar</p>
                </div>
                <div style={{ display: 'flex', marginLeft: '8px', }}>
                    <div style={{
                        backgroundColor: `#ff9800`,
                        margin: '3px',
                        height: '5px',
                        marginRight: '8px',
                        border: `2px solid #ff9800`,
                        borderRadius: '12px',
                        boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.32)',
                        padding: '8px'
                    }}>
                    </div>
                    <p style={{ margin: '0px' }}>Intermediação Acertada</p>
                </div>
                <div style={{ display: 'flex', marginLeft: '8px', }}>
                    <div style={{
                        backgroundColor: `#289b1e`,
                        margin: '3px',
                        height: '5px',
                        marginRight: '8px',
                        border: `2px solid #289b1e`,
                        borderRadius: '12px',
                        boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.32)',
                        padding: '8px'
                    }}>
                    </div>
                    <p style={{ margin: '0px' }}>Vigente</p>
                </div>
                <div style={{ display: 'flex', marginLeft: '8px', }}>
                    <div style={{
                        backgroundColor: `#f44336`,
                        margin: '3px',
                        marginRight: '8px',
                        height: '5px',
                        border: `2px solid #f44336`,
                        borderRadius: '12px',
                        boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.32)',
                        padding: '8px'
                    }}>
                    </div>
                    <p style={{ margin: '0px' }}>Suspenso</p>
                </div>
                <div style={{ display: 'flex', marginLeft: '8px', }}>
                    <div style={{
                        backgroundColor: `#f44336`,
                        margin: '3px',
                        marginRight: '8px',
                        height: '5px',
                        border: `2px solid #f44336`,
                        borderRadius: '12px',
                        boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.32)',
                        padding: '8px'
                    }}>
                    </div>
                    <p style={{ margin: '0px' }}>Cancelado</p>
                </div>
                <div style={{ display: 'flex', marginLeft: '8px', }}>
                    <div style={{
                        backgroundColor: `#000`,
                        margin: '3px',
                        marginRight: '8px',
                        height: '5px',
                        border: `2px solid #000`,
                        borderRadius: '12px',
                        boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.32)',
                        padding: '8px'
                    }}>
                    </div>
                    <p style={{ margin: '0px' }}>Jurídico/Distrato</p>
                </div>
                <div style={{ display: 'flex', marginLeft: '8px', }}>
                    <div style={{
                        backgroundColor: `#fff`,
                        margin: '3px',
                        marginRight: '8px',
                        height: '5px',
                        border: `2px solid #000`,
                        borderRadius: '12px',
                        boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.32)',
                        padding: '8px'
                    }}>
                    </div>
                    <p style={{ margin: '0px' }}>Quitado</p>
                </div>
            </div>
        </div>
    );
};

export default Legenda;
