import { Deserializable } from "../../../../core/interfaces/deserializable.model";
import { BaseModel } from "./BaseModel";
import { PessoaModel } from "./PessoaModel";

export interface ICorretor {
  status: boolean | null;
  pessoa: PessoaModel | PessoaModel[] | null;
  nome_fornecedor: string | null;
  email_fornecedor: string | null;
  telefone_fornecedor: string | null;
  created_at: Date | null;
  updated_at: Date | null;
  pessoa_id: Number | null;
  empreendimento_id: Number | null;
  filial_id: Number | null;
  creci: string | null;
  qtd_lt_reservado: Number | null;
  comissao: Number | null;
  qtd_max_lt_vendido: Number | null;
  qtd_max_lt_reservado: Number | null;
  qtd_lt_vendido: Number | null;
  observacao: string | null;
  imobiliaria: boolean | null;
}

export class CorretorModel
  extends BaseModel
  implements ICorretor, Deserializable
{
  status: boolean | null = null;
  pessoa: PessoaModel | PessoaModel[] | null = null;
  nome_fornecedor: string | null = null;
  email_fornecedor: string | null = null;
  telefone_fornecedor: string | null = null;
  pessoa_id: Number | null = null;
  empreendimento_id: Number | null = null;
  filial_id: Number | null = null;
  creci: string | null = null;
  qtd_lt_reservado: Number | null = null;
  comissao: Number | null = null;
  qtd_max_lt_vendido: Number | null = null;
  qtd_max_lt_reservado: Number | null = null;
  qtd_lt_vendido: Number | null = null;
  observacao: string | null = null;
  imobiliaria: boolean | null = null;
  created_at: Date | null = null;
  updated_at: Date | null = null;

  deserialize(input: any): this {
    Object.assign(this, input);
    if (input?.pessoa) {
      this.pessoa = new PessoaModel().deserialize(input.pessoa);
    } else if (input?.pessoa?.data) {
      this.pessoa = new PessoaModel().deserialize(input.pessoa.data);
    }
    return this;
  }
}
