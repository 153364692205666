import CalendarIcon from '../../../../../assets/images/icon-calendar.png';
import iconMenuTresPonto from '../../../../../assets/images/icon-menu-tres-pontos.png';
import { Button } from 'primereact/button';
import { useEffect, useState } from 'react';
import { Col, Form, InputGroup, NavLink, Row } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import pt from 'date-fns/locale/pt';
import 'react-autocomplete-input/dist/bundle.css';
import { useAppContext } from '../../../../contexts/AppContext';
import TokenService from '../../../../services/Auth/token.service';
import { url } from '../../../../environments/environments-develop';
import { AlertService } from '../../../../components/AlertService';
import api from '../../../../services/api';
import { abrirPDF } from '../../../../utils/PdfUtils';
import { useSidebarContext } from '../../../../contexts/SidebarContext';
import { InputMask } from 'primereact/inputmask';

function RelatorioTaxaManutencao() {
  const [listFiltros, setListFiltros] = useState<any>({});
  const { isVisible, updateVisibility } = useSidebarContext();
  const [contratos_com_entrada, setContratosComEntrada] = useState<string | null>(null);
  const { showSubSidebar } = useAppContext();
  const [tipo, setTipo] = useState<number>(1);
  const [vencimentoError, setVencimentoError] = useState<string | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const empreendimento_id = TokenService.getEmpreendimentoId().id
  const { setItemsBreadsCrumbs } = useAppContext();
  const toggleVisibility = () => {
    const newValue = !isVisible;
    updateVisibility(newValue);
  };
  useEffect(() => {
    setItemsBreadsCrumbs([
      {
        label: 'Cemitério',
        url: '/cemiterio',
      },
      {
        label: 'Relatórios Taxa de Manutenção',
        url: '/cemiterio/relatorios/taxa-manutencao',
      },
    ]);
  }, []);
  async function gerarPDF(): Promise<void> {
    try {
      setIsSubmitting(true);
      const consulta: any = {
        consulta: {
          order: "id;desc",
          filtro: {
            vencimento: listFiltros?.vencimento,
            empreendimento_id: TokenService.getEmpreendimentoId() ? TokenService.getEmpreendimentoId().id : null
          }
        }
      };
      const queryParams: any = {};
      Object.entries(consulta.consulta.filtro).forEach(([key, value]) => {
        if (value !== undefined && value !== null && value !== '') {
          queryParams[key] = value;
        }
      });
      const queryString = encodeURIComponent(JSON.stringify({
        order: consulta.consulta.order,
        filtro: queryParams
      }));
      const body = {
        tipo,
        empreendimento_id
      };
      const response = await api.post(
        `${url}/api/v1/admin/cemiterio/gerador-relatorio-taxa-manutencao?consulta=${queryString}`,
        body, {
        responseType: 'arraybuffer'
      }
      );
      if (response.status === 200) {
        handleOpenPDF(response.data, listFiltros);
        setIsSubmitting(false);
      } else if (response.status === 204) {
        AlertService.error("Erro!", "Nenhum registro encontrado para a data de vencimento informada.");
        setIsSubmitting(false);
      }
    } catch (error: any) {
      let mensagens: string[] = [];
      if (error?.response?.data?.error) {
        const errorData = error?.response?.data?.error;
        if (typeof errorData.message === 'string') {
          mensagens.push(errorData.message);
        }
        else if (typeof errorData.message === 'object') {
          for (const key in errorData.message) {
            if (errorData.message.hasOwnProperty(key)) {
              const messageArray = errorData.message[key];
              if (Array.isArray(messageArray)) {
                mensagens = mensagens.concat(messageArray);
              }
            }
          }
        }
      }
      if (mensagens.length === 0) {
        mensagens.push("Nenhum registro encontrado para a data de vencimento informada.");
      }
      AlertService.error("Erro!", mensagens.join("\n"));
      setIsSubmitting(false);
    }
  }

  const handleChange = (field: string, value: any) => {
    setListFiltros((prevGrupoData: any) => {
      return {
        ...prevGrupoData,
        [field]: value
      };
    });
  };

  const handleOpenPDF = (pdfBase64: string, listFiltros: any) => {
    abrirPDF(pdfBase64, listFiltros);
  }


  return (
    <div className="body-content">
      <div
        className={`${showSubSidebar ? "container-section-body" : "container-section-body-none"}`}
      >
        <div className="contentSearch">
          <div className="contentSearch-header">
            <NavLink onClick={toggleVisibility}>
              <img
                className="menu-context"
                src={iconMenuTresPonto}
              />
            </NavLink>
            <Col className='text-center' style={{ fontSize: '20px', color: '#494949' }}>Relatórios Taxa de Manutenção</Col>
          </div>
          <div style={{ opacity: '34%' }} className="contentTraçoBodyHeader"></div>
          <Form.Group className="imovel-form" style={{ marginLeft: '22px' }}>
            <Row className="mb-3">
              <Col md='2'>
                <Form.Label>Tipo de Relatório</Form.Label>
                <Form.Select value={tipo || ''} onChange={(e) => setTipo(Number(e.target.value))}>
                  <option>Selecione...</option>
                  <option value={1} key={1}>PDF</option>
                  <option value={2} key={2}>EXCEL</option>
                  <option value={3} key={3}>JSON</option>
                </Form.Select>
              </Col>
              <Col md="2">
                <Form.Label>Mês e Ano de Vencimento</Form.Label>
                <InputMask
                  mask="99/9999"
                  id="vencimento"
                  value={listFiltros.vencimento || ''}
                  onChange={(e) => {
                    const value = e.target.value;
                    const [mes] = (value ?? '').split('/');
                    if (mes && parseInt(mes, 10) > 12) {
                      setVencimentoError('Por favor, insira um mês válido (1-12).');
                    } else {
                      setVencimentoError(null);
                    }
                    handleChange('vencimento', value);
                  }}
                  placeholder="mm/aaaa"
                  className={`form-control ${vencimentoError ? 'is-invalid' : ''}`}
                />
                {vencimentoError && (
                  <div className="invalid-feedback" style={{ display: 'block' }}>
                    {vencimentoError}
                  </div>
                )}
              </Col>
            </Row>
          </Form.Group>
          <div className='div-filtros-avancados'>
            <Button
              onClick={() => { gerarPDF() }}
              style={{ height: '30px', marginBottom: '13px', marginRight: '24px', borderRadius: '20px', padding: '5px 10px', fontSize: '14px' }}
              type="button"
              severity="success"
              icon="pi pi-refresh"
              disabled={isSubmitting}
              rounded>
              <p style={{ marginLeft: '5px', marginTop: '13px' }}>{isSubmitting ? 'Gerando....' : 'Gerar Relatório'}</p>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RelatorioTaxaManutencao;