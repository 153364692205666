import { Col, Form, InputGroup, Row } from "react-bootstrap";
interface PropsData {
    Data: {
        nome?: string;
        descricao?: string;
        dia_public?: string;
    };
    initialRightValues: any;
    onInputChange: (field: string, value: any) => void;
};
function TipoIndiceForm(props: PropsData) {
    const { nome, descricao, dia_public } = props.Data || {};
    const handleChange = (field: string, value: any) => {
        props.onInputChange(field, value);
    };

    return (
        <>
            <Form.Group className="forms" controlId="validationCustomUsername">
                <Row className="mb-3">
                    <Col md="3">
                        <Form.Label className="mb-0">Nome</Form.Label>
                        <InputGroup hasValidation>
                            <Form.Control
                                required
                                value={nome || ''}
                                onChange={(e) => handleChange("nome", e.target.value)}
                                onBlur={(e) => handleChange("nome", e.target.value)}
                                placeholder="Preencha o nome do Tipo Indice"
                            />
                            <Form.Control.Feedback type="invalid">
                                Este campo é obrigatório.
                            </Form.Control.Feedback>
                        </InputGroup>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col md="3">
                        <Form.Label className="mb-0">Descrição</Form.Label>
                        <InputGroup hasValidation>
                            <Form.Control
                                required
                                value={descricao || ''}
                                onChange={(e) => handleChange("descricao", e.target.value)}
                                onBlur={(e) => handleChange("descricao", e.target.value)}
                                placeholder="Preencha com a Descrição"
                            />
                        </InputGroup>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col md="3">
                        <Form.Label className="mb-0">Dia da Publicação</Form.Label>
                        <InputGroup hasValidation>
                            <Form.Control
                                value={dia_public || ''}
                                onChange={(e) => handleChange("dia_public", e.target.value)}
                                onBlur={(e) => handleChange("dia_public", e.target.value)}
                                placeholder="Preencha com o Dia da Publicação"
                                required
                            />
                            <Form.Control.Feedback type="invalid">
                                Este campo é obrigatório.
                            </Form.Control.Feedback>
                        </InputGroup>
                    </Col>
                </Row>
            </Form.Group>
        </>
    );
}
export default TipoIndiceForm;
