import { Col, Form, Row } from "react-bootstrap";
import { useEffect, useState } from "react";
import TokenService from "../../../../services/Auth/token.service";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import CalendarIcon from '../../../../../assets/images/icon-calendar.png';
import pt from "date-fns/locale/pt";
import "react-datepicker/dist/react-datepicker.css";
import { InputNumber } from "primereact/inputnumber";
import { AutoComplete } from "primereact/autocomplete";
import { searchCorretor } from "../Services/VendaService";
import { InputText } from "primereact/inputtext";

function EditSecaoJazigo(props: any) {
    const [formData, setFormData] = useState<any>(props.secaoImovelData || {});
    const [data_venda, setDataVenda] = useState<any | null>(null);
    const [data_entrega, setDataEntrega] = useState<any | null>(null);
    const [data_prim_manutencao, setDataPrimManutencao] = useState<any | null>(null);
    const [FilteredCorretor, setFilteredCorretor] = useState<any>(null);
    const handleChange = (field: string, value: any) => {
        if (field == 'corretor_id') {
            props.onInputChange('nome_corretor', value?.nome ? value.nome : '');
            props.onInputChange(field, value?.id ? value.id : '');
        } else {
            props.onInputChange(field, value);
        }
    };

    useEffect(() => {
        setFormData((prevGrupoData: any) => {
            return {
                ...prevGrupoData,
                ...props.secaoImovelData
            };
        });
    }, [props.secaoImovelData]);

    useEffect(() => {
        setDataVenda(formData?.data_venda ? new Date(formData?.data_venda) : null);
        setDataEntrega(formData?.data_entrega ? new Date(formData?.data_entrega) : null);
        setDataPrimManutencao(formData?.data_prim_manutencao ? new Date(formData?.data_prim_manutencao) : null);

    }, [formData])
    const MoneyFormatter = (saldoDevedor: any) => {
        const formatMoney = (value: number): string => {
            return new Intl.NumberFormat('pt-BR', {
                style: 'currency',
                currency: 'BRL',
            }).format(value);
        };
        return formatMoney(saldoDevedor);
    };
    
    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            flex: '1',
            height: '-webkit-fill-available',
            marginTop: '6px',
            minHeight: '350px'
        }}>
            <div style={{ display: 'flex', width: '100%', backgroundColor: '#4949498a', paddingLeft: '20px', paddingBottom: '20px', paddingTop: '25px', borderRadius: '7px' }}>
                <div style={{ display: 'flex', width: '19%', height: '32px', paddingLeft: '0px', marginRight: '3px' }}>
                    <p style={{ fontSize: '14px !important', margin: '0px', padding: '3px', backgroundColor: '#494949', color: '#fff', border: '1px solid #494949', borderTopLeftRadius: '4px' }}>
                        <strong>Valor de Tabela</strong>
                    </p>
                    <p style={{ fontSize: '14px !important', margin: '0px', padding: '3px', backgroundColor: '#e8550f', color: '#fff', border: '1px solid #e8550f', borderBottomRightRadius: '4px' }}>
                        <strong>{MoneyFormatter(formData?.imovel?.imo_preco ?? 0)}</strong>
                    </p>
                </div>
                <div style={{ width: '20%', paddingLeft: '0px', marginRight: '14px' }}>
                    <span className="p-float-label">
                        <InputNumber
                            style={{ width: '100%', height: '38px' }}
                            value={formData?.valor_desconto ? Number(formData?.valor_desconto) : null}
                            onChange={(e: any) => handleChange('valor_desconto', e.value)}
                            mode="currency"
                            currency="BRL"
                            locale="pt-BR"
                        />
                        <label htmlFor="ac" >Valor Desconto Jazigo</label>
                    </span>
                </div>
                <div style={{ width: '40%', paddingLeft: '0px', marginRight: '8px' }}>
                    <span className="p-float-label">
                        <InputText
                            style={{ width: '100%', height: '38px', paddingTop: '10px !important' }}
                            value={formData?.justificativa_desc ?? ''}
                            onChange={(e) => handleChange('justificativa_desc', e.target.value)}
                        />
                        <label style={{ paddingTop: '2px ' }} htmlFor="ac" >Justificativa de Desconto</label>
                    </span>
                </div>
            </div>

            <hr />
            <Row className="mb-3">
                <Col md="3" style={{ display: 'flex', flexDirection: 'column', marginTop: '6px' }}>
                    <DatePicker
                        selected={data_venda ? new Date(Date.parse(data_venda)) : null}
                        onChange={(date: Date) => {
                            setDataVenda(date);
                            handleChange('data_venda', date);
                        }}
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        fixedHeight
                        locale={pt}
                        customInput={
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <input
                                    type="text"
                                    placeholder="Data da Venda"
                                    className={`dateFormFinanceiro ${!data_venda ? 'date-validation' : ''}`}
                                    value={data_venda ? data_venda.toLocaleDateString() : ''}
                                    readOnly
                                />
                                <img
                                    className="calendar-icon"
                                    src={CalendarIcon}
                                />
                            </div>
                        }
                    />
                </Col>
                {TokenService.getEmpreendimentoId()?.config_venda?.num_contrato_manual === true && (
                    <Col md="3" style={{ display: 'flex', flexDirection: 'column', marginTop: '6px' }}>
                        <span className="p-float-label">
                            <InputText
                                invalid={!formData?.numero}
                                value={formData?.numero ?? ''}
                                onChange={(e) => handleChange('numero', e.target.value)}
                            />
                            <label style={{ paddingTop: '2px ' }} htmlFor="ac" >N° Contrato</label>
                        </span>
                    </Col>
                )}
                <Col md="6" style={{ display: 'flex', flexDirection: 'column', marginTop: '6px' }}>
                    <span className="p-float-label">
                        <InputText
                            value={formData?.anotacoes ?? ''}
                            onChange={(e) => handleChange('anotacoes', e.target.value)} />
                        <label style={{ paddingTop: '2px ' }} htmlFor="ac">Anotações</label>
                    </span>
                </Col>
            </Row>
            <Row className="mb-3">
                <Col md="3" style={{ display: 'flex', flexDirection: 'column', marginTop: '14px' }}>
                    <span className="p-float-label">
                        <AutoComplete
                            invalid={!formData?.corretor_id}
                            field="nome_corretor"
                            style={{ height: "37px" }}
                            value={formData?.busca ? formData?.busca : formData?.corretor?.nome_corretor || ''}
                            suggestions={FilteredCorretor}
                            completeMethod={(e) => searchCorretor(e, 0, setFilteredCorretor, formData?.tipo_jazigo_id)}
                            onChange={(e) =>
                                handleChange('busca', e.value)}
                            onSelect={(e) =>
                                handleChange('corretor_id', e.value)}
                            dropdown
                        />
                        <label htmlFor="ac">Corretor</label>
                    </span>
                </Col>
                <Col md="3" style={{ display: 'flex', flexDirection: 'column', marginTop: '14px' }}>
                    <Form.Select isInvalid={!formData?.modo_jazigo} value={formData.modo_jazigo} onChange={(e) => handleChange('modo_jazigo', e.target.value)}>
                        <option>Selecione o Modo</option>
                        <option value={1}>Perpétuo</option>
                        <option value={2}>Temporário</option>
                    </Form.Select>
                </Col>
                <Col md="3" style={{ display: 'flex', flexDirection: 'column', marginTop: '14px' }}>
                    <Form.Select isInvalid={!formData?.tipo_jazigos_vendas} value={formData.tipo_jazigos_vendas} onChange={(e) => handleChange('tipo_jazigos_vendas', e.target.value)}>
                        <option>Selecione o Tipo da Venda</option>
                        <option value={1}>Preventivo</option>
                        <option value={2}>Imediato</option>
                    </Form.Select>
                </Col>
            </Row>
            <Row className="mb-3">
                <Col md="3" style={{ display: 'flex', flexDirection: 'column', marginTop: '14px' }}>
                    <Form.Select isInvalid={!formData?.manutencao_periodicidade} value={formData.manutencao_periodicidade} onChange={(e) => handleChange('manutencao_periodicidade', e.target.value)}>
                        <option>Selecione a Manutenção</option>
                        <option value={1}>Mensal</option>
                        <option value={2}>Bimestral</option>
                        <option value={3}>Trimestral</option>
                        <option value={4}>Semestral</option>
                        <option value={5}>Anual</option>
                    </Form.Select>
                </Col>
                <Col md="2" style={{ display: 'flex', flexDirection: 'column', marginTop: '14px' }}>
                    <span className="p-float-label">
                        <InputNumber
                            minFractionDigits={2}
                            maxFractionDigits={24}
                            style={{ width: '100%', height: '38px' }}
                            value={formData?.coef_manutencao ? Number(formData?.coef_manutencao) : null}
                            onChange={(e: any) => handleChange('coef_manutencao', e.value)}
                            locale="pt-BR" />
                        <label htmlFor="ac">Coef. de manutenção</label>
                    </span>
                </Col>
                <Col md="3" style={{ display: 'flex', flexDirection: 'column', marginTop: '14px' }}>
                    <DatePicker
                        selected={data_entrega ? new Date(Date.parse(data_entrega)) : null}
                        onChange={(date: Date) => {
                            setDataVenda(date);
                            handleChange('data_entrega', date);
                        }}
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        fixedHeight
                        locale={pt}
                        customInput={
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <input
                                    type="text"
                                    placeholder="Data da Entrega"
                                    className={`dateFormFinanceiro ${!data_entrega ? 'date-validation' : ''}`}
                                    value={data_entrega ? data_entrega.toLocaleDateString() : ''}
                                    readOnly
                                />
                                <img
                                    className="calendar-icon"
                                    src={CalendarIcon}
                                />
                            </div>
                        }
                    />
                </Col>
                <Col md="3" style={{ display: 'flex', flexDirection: 'column', marginTop: '14px' }}>
                    <DatePicker
                        selected={data_prim_manutencao ? new Date(Date.parse(data_prim_manutencao)) : null}
                        onChange={(date: Date) => {
                            setDataVenda(date);
                            handleChange('data_prim_manutencao', date);
                        }}
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        fixedHeight
                        locale={pt}
                        customInput={
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <input
                                    type="text"
                                    placeholder="Data da Primeira Manutenção"
                                    className={`dateFormFinanceiro ${!data_prim_manutencao ? 'date-validation' : ''}`}
                                    value={data_prim_manutencao ? data_prim_manutencao.toLocaleDateString() : ''}
                                    readOnly
                                />
                                <img
                                    className="calendar-icon"
                                    src={CalendarIcon}
                                />
                            </div>
                        }
                    />
                </Col>
            </Row>
        </div>
    );
}

export default EditSecaoJazigo;