import React, { useState, useEffect } from 'react';
import { Col, Form, Row } from 'react-bootstrap';

function Corretor(props: any) {
    const [formData, setFormData] = useState<any>({});

    useEffect(() => {
        setFormData(props.formData);
    }, [props.formData]);

    const handleFormChange = (field: string, value: any) => {
        props.onInputChange(field, value);
    }

    return (

        <>
            <Row className="group-form">
                <Col>
                    <Form.Label>Em qual Coluna está o id do Corretor?</Form.Label>
                    <Form.Control
                        value={formData.id_externo || ''}
                        onChange={(e) => handleFormChange('id_externo', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está o nome do Corretor?</Form.Label>
                    <Form.Control
                        value={formData.nome || ''}
                        onChange={(e) => handleFormChange('nome', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está o cpf do Corretor?</Form.Label>
                    <Form.Control
                        value={formData.cpf_cnpj || ''}
                        onChange={(e) => handleFormChange('cpf_cnpj', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está o email do Corretor?</Form.Label>
                    <Form.Control
                        value={formData.email || ''}
                        onChange={(e) => handleFormChange('email', e.target.value)} />
                </Col>
            </Row>
            <Row className="group-form">
                <Col>
                    <Form.Label>Em qual Coluna está a estado civil do Corretor?</Form.Label>
                    <Form.Control
                        value={formData.estado_civil || ''}
                        onChange={(e) => handleFormChange('estado_civil', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está o RG do Corretor?</Form.Label>
                    <Form.Control
                        value={formData.rg || ''}
                        onChange={(e) => handleFormChange('rg', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está o telefone para contato do Corretor?</Form.Label>
                    <Form.Control
                        value={formData.telefone || ''}
                        onChange={(e) => handleFormChange('telefone', e.target.value)} />
                </Col>
            </Row>
            <Row className="group-form">
                <Col>
                    <Form.Label>Em qual Coluna está o creci do Corretor?</Form.Label>
                    <Form.Control
                        value={formData.creci || ''}
                        onChange={(e) => handleFormChange('creci', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está a qtd de lote reservado?</Form.Label>
                    <Form.Control
                        value={formData.qtd_lt_reservado || ''}
                        onChange={(e) => handleFormChange('qtd_lt_reservado', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está a Comissão?</Form.Label>
                    <Form.Control
                        value={formData.comissao || ''}
                        onChange={(e) => handleFormChange('comissao', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está a Qtd maxima de lotes vendidos?</Form.Label>
                    <Form.Control
                        value={formData.qtd_max_lt_vendido || ''}
                        onChange={(e) => handleFormChange('qtd_max_lt_vendido', e.target.value)} />
                </Col>
            </Row>
            <Row className="group-form">
                <Col>
                    <Form.Label>Em qual Coluna está a Qtd de lotes vendidos?</Form.Label>
                    <Form.Control
                        value={formData.qtd_lt_vendido || ''}
                        onChange={(e) => handleFormChange('qtd_lt_vendido', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está do cor. bloqueado (COR_BLOQUEADO)?</Form.Label>
                    <Form.Control
                        value={formData.cor_bloqueado || ''}
                        onChange={(e) => handleFormChange('cor_bloqueado', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está da imobiliaria (COR_BLOQUEADO)?</Form.Label>
                    <Form.Control
                        value={formData.imobiliaria || ''}
                        onChange={(e) => handleFormChange('imobiliaria', e.target.value)} />
                </Col>
            </Row>
        </>
    );
}

export default Corretor;
