import { Col, Form, InputGroup, Row } from "react-bootstrap";
import TransferList from '../../../../components/TransferList';
interface PropsGroupPermission {
    grupoData: {
        nome?: string;
        descricao?: string;
        padrao?: boolean;
    };
    initialRightValues: any;
    onInputChange: (field: string, value: any) => void;
}
function groupPermission(props: PropsGroupPermission) {
    const { nome, descricao, padrao, } = props.grupoData || {};
    const grupos_permissoes = props.initialRightValues
    const handleChange = (field: string, value: any) => {
        props.onInputChange(field, value);
    };


    return (
        <>
            <Row className="group-form">
                <Form.Group as={Col} controlId="validationCustomUsername">
                    <Form.Label>Nome</Form.Label>
                    <InputGroup hasValidation>
                        <Form.Control
                            required
                            value={nome || ''}
                            onChange={(e) => handleChange("nome", e.target.value)}
                            onBlur={(e) => handleChange("nome", e.target.value)}
                        />
                        <Form.Control.Feedback type="invalid">
                            Este campo é Obrigatório.
                        </Form.Control.Feedback>
                    </InputGroup>
                </Form.Group>
                <Col>
                    <Form.Label>Descrição</Form.Label>
                    <Form.Control value={descricao || ''} onChange={(e) => handleChange("descricao", e.target.value)} />
                </Col>
                <Col md="1" style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
                    <Form.Label>Padrão</Form.Label>
                    <Form.Check
                        checked={padrao || false}
                        onChange={(e) => handleChange("padrao", e.target.checked)}
                        type="switch"
                        id="custom-switch"
                    />
                </Col>
            </Row>
            <Row>
                <Form.Label className="text-center">Definição de Permissões do Grupo</Form.Label>
                <TransferList initialRight={grupos_permissoes} onCheckBoxChange={(value: any) => handleChange("grupos_permissoes", value)}></TransferList>
            </Row>
        </>
    );
}

export default groupPermission;
