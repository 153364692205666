import NavDropdown from "react-bootstrap/NavDropdown";
import logo from '../../../../../assets/images/profile.png';
import iconConfig from '../../../../../assets/images/icon-config.png';
import iconNotification from '../../../../../assets/images/notificacao.png';
import { Link } from "react-router-dom";
import TokenService from "../../../../services/Auth/token.service";
import { Nav, Dropdown, Form } from 'react-bootstrap';
import { useEffect, useState } from "react";
import { Card } from "primereact/card";
import { Button } from "primereact/button";
import { Badge } from "primereact/badge";
import { AlertService } from "../../../../components/AlertService";
import { url } from "../../../../environments/environments-develop";
import api from "../../../../services/api";
import { formatDistanceToNow } from 'date-fns';
import { ptBR } from 'date-fns/locale';
function ProfileComponent({ menus }: any) {
  const [showDropdown, setShowDropdown] = useState(false);
  const [listNotificacoes, setListNotificacoes] = useState([]);
  const [menuConfig, setmenuConfig] = useState([]);
  const [notification, setNotification] = useState(false);
  const toggleDropdown = () => setShowDropdown(!showDropdown);

  const handleClear = () => setShowDropdown(!showDropdown);
  async function InitNotificacoes() {
    try {
      const response = await api.get(`${url}/api/v1/admin/core/notificacao?include=rotas`);
      setListNotificacoes(response.data.data);
    } catch (error) {
      AlertService.error('Error', 'Ocorreu um erro ao buscar a de clientes. Erro:' + String(error));
    }
  }
  useEffect(() => {
    setmenuConfig(menus.find((menu: any) => menu.icon == 'configuracoes'));
  }, []);
  const handleReload = () => {
    InitNotificacoes();
  };

  useEffect(() => {
    InitNotificacoes();
  }, []);

  const logout = () => {
    TokenService.clearLocalStorage();
  }

  const changeNotification = (
    index: number,
    field: string,
    value: any
  ) => {
    const updatedNotificacoes: any = [...listNotificacoes];
    updatedNotificacoes[index] = {
      ...updatedNotificacoes[index],
      [field]: value,
    };
    setListNotificacoes(updatedNotificacoes);
  };
  const setMenuSelect = (selectedOption: any) => {
    TokenService.setMenuSelect(selectedOption);
  }
  return (
    <Nav className="navbar-profile">
      <Nav.Item>
        <Dropdown className="dropdown-notification" style={{ position: "relative", top: "5px" }} show={showDropdown} onToggle={toggleDropdown}>
          <Dropdown.Toggle className="iconNavBar" variant="transparent" id="navbarNotificationDropdown">
            <img className="iconNotification" src={iconNotification} />
          </Dropdown.Toggle>
          <Dropdown.Menu variant="dark">
            <div className="notification-header">
              <h5 className="body-notificacao-title">
                Notificações
              </h5>
              <Button icon="pi pi-refresh" onClick={handleReload} className="button-reload-notificacao p-button-text p-button-rounded p-button-plain" />
            </div>
            <Dropdown.Divider />
            {listNotificacoes.length == 0 &&
              <Dropdown.Item disabled>
                <Card className="card-notificacao">
                  <p className="body-notificacao-default">
                    Nenhum item Encontrado
                  </p>
                </Card>
              </Dropdown.Item>
            }
            <div className="notification-body">
              {listNotificacoes.length > 0 && listNotificacoes.map((notificacao: any, index: number) => (
                <div className="card-notificacao" key={index}>
                  <div style={{ display: 'flex', paddingTop: '15px', justifyContent: 'space-between' }}>
                    <div style={{ display: 'flex' }}>
                      {/* {!notificacao.read_at &&
                        <Badge
                          style={{
                            alignContent: 'center',
                            alignItems: 'center',
                            // marginLeft: '8px',
                            marginTop: '8px',
                            marginRight: '5px'
                          }}
                          severity="success"
                        />
                      } */}
                      <p style={{ fontSize: '12px', fontWeight: 'bold', marginBottom: '5px' }}>{notificacao.titulo}</p>

                    </div>
                    {/* <Form.Check
                      checked={notificacao.checked}
                      onChange={(e) => changeNotification(index, 'checked', e.target.checked)}
                      className="check-notificacao"
                    /> */}
                  </div>
                  <p className="body-notificacao">
                    {notificacao.message}
                  </p>
                  <div>
                    <p className="body-notificacao" style={{ display: 'flex', justifyContent: 'end' }}>
                      {formatDistanceToNow(new Date(notificacao.created_at), { locale: ptBR, addSuffix: true })}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </Dropdown.Menu>
        </Dropdown>
      </Nav.Item >

      <Link to={"configuracoes"} className="iconNavBar" onClick={() => setMenuSelect(menuConfig)}>
        <div
          className="nav-link iconNavBar"
          id="navbarScrollingDropdown"
          role="button"
          aria-expanded="false"
        >
          <img
            alt="iconConfig"
            className="iconConfig"
            src={String(iconConfig)}
          />
        </div>
      </Link>

      <NavDropdown className="iconNavBar " title={<img
        alt="logo"
        className="imgProfile"
        style={{ width: 30 }}
        src={String(logo)}
      />} menuVariant="dark">
        <Nav className="dropdownPerfil" >
          <Link to={"usuario/perfil"} className="link-perfil" >
            Perfil
          </Link>
        </Nav>
        <Nav.Link href="/login" onClick={() => logout()} className="dropdownPerfil">
          Sair
        </Nav.Link>
      </NavDropdown>
    </Nav >
  );
}

export default ProfileComponent;
