import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import { useAppContext } from '../../../contexts/AppContext';
import { url } from '../../../environments/environments-develop';
import { AlertService } from '../../../components/AlertService';
import CentroCustoForm from './Formularios/CentroCustoForm';

interface PropsData {
    codigoConcat?: string;
    codigo?: string;
    nome?: string;
    tipo?: string;
    parent_id?: string;
    status?: boolean;
    recebe_lancamento?: boolean;
    contabil?: boolean;
}

function EditCentroCusto() {
    const { setTypeRequest, setUrl, setBody, setShowButtonSave, setItemsBreadsCrumbs } = useAppContext();
    const [formBasico, setFormBasico] = useState<PropsData>({});

    useEffect(() => {
        try {
            setItemsBreadsCrumbs([
                {
                    label: 'Financeiro',
                    url: '/financeiro',
                },
                {
                    label: 'Centro de Custo',
                    url: '/financeiro/centro-custo',
                },
                {
                    label: 'Editar'
                }
            ]);
            const storedData = localStorage.getItem('dadosEdicao');
            if (storedData) {
                setFormBasico((prevGrupoData) => {
                    const dadosAtualizados = {
                        ...prevGrupoData,
                        ...JSON.parse(storedData)
                    };
                    return dadosAtualizados;
                });
                setTypeRequest('PUT');
                setUrl(`${url}/api/v1/admin/financeiro/centro-custo/${storedData && JSON.parse(storedData).id}`);
                setShowButtonSave(true);
            }
            return () => {
                localStorage.removeItem('dadosEdicao');
            };
        } catch (error) {
            AlertService.error("error", String(error))
        }
    }, []);

    useEffect(() => {
        setBody(formBasico)
    }, [formBasico]);

    const handleChange = (field: string, value: string) => {
        setFormBasico((prevGrupoData) => {
            return {
                ...prevGrupoData,
                [field]: value
            };
        });
        setTypeRequest('PUT');
    }
    return (
        <>
            <div className="body-content">
                <div className="form-create-group">
                    <div className="form-create-group-header">
                        <h3>Alterar Centro de Custo</h3>
                    </div>
                </div>
                <div className="form-group-create">
                    <Container>
                        <CentroCustoForm
                            initialRightValues={[]}
                            Data={formBasico}
                            onInputChange={(field: string, value: string) => handleChange(`${field}`, value)}
                        />
                    </Container>
                </div>
            </div>
        </>
    );
}

export default EditCentroCusto;
