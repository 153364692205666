import React, { useState, useEffect } from 'react';
import { Button, Form, Modal, Row } from 'react-bootstrap';
import TokenService from '../../../../../../services/Auth/token.service';
import { AlertService } from '../../../../../../components/AlertService';
import '../index.css';
import { url } from '../../../../../../environments/environments-develop';
import api from '../../../../../../services/api';
import VendaForm from '../Formularios/VendaForm';
import { Steps } from 'primereact/steps';
import { showToast } from '../../../../../../components/ToastComponent';
import { addCompradores, removeCompradores } from '../../../../../../utils/CompradorUtils';
import Produto from '../../../../../Cemiterio/Produtos/index';

interface ModalVendaContratoProps {
    showModal: boolean;
    handleClose: () => void;
}

const ModalVendaContrato: React.FC<ModalVendaContratoProps> = ({ showModal, handleClose }) => {
    const [show, setShow] = useState(showModal);
    const [currentPart, setCurrentPart] = useState(0);
    const totalParts = 4;
    const [formBasico, setFormBasico] = useState<any>({});
    const [valorAcessorios, setValorAcessorios] = useState<any>(0);
    useEffect(() => {
        setShow(showModal);
        if (showModal === false) {
            setFormBasico(null);
            setCurrentPart(0);
            setValorAcessorios(0);
        }
    }, [showModal])
    useEffect(() => {
        if (formBasico?.imovel_id) {
            simularSeNecessario();
        }
    }, [
        formBasico?.tabela_preco_id,
        formBasico?.entrada,
        formBasico?.valor_desconto,
        formBasico?.qtd_parc_entrada,
        formBasico?.valor_prim_parc_entrada
    ]);
    const simularSeNecessario = async () => {
        try {
            const imovelId = formBasico?.imovel_id;
            const tabelaPrecoId = formBasico?.tabela_preco_id;
            const entrada = formBasico?.entrada;
            const valorDesconto = formBasico?.valor_desconto;
            const qtdParcelasEntrada = formBasico?.qtd_parc_entrada;
            const primeiraParcelaEntrada = formBasico?.valor_prim_parc_entrada;
            const produto_servicos = formBasico?.produto_servicos;
            const empreendimento_id = TokenService.getEmpreendimentoId()?.id;
            const paramsObj: Record<string, string | number> = {};

            if (imovelId) paramsObj['imovel_id'] = imovelId.toString();
            if (tabelaPrecoId) paramsObj['tabela_preco_id'] = tabelaPrecoId.toString();
            if (entrada) paramsObj['entrada'] = entrada.toString();
            if (valorDesconto) paramsObj['valor_desconto'] = valorDesconto.toString();
            if (qtdParcelasEntrada) paramsObj['qtd_parc_entrada'] = qtdParcelasEntrada.toString();
            if (primeiraParcelaEntrada) paramsObj['valor_prim_parc_entrada'] = primeiraParcelaEntrada.toString();
            if (produto_servicos) paramsObj['produtos_servicos'] = JSON.stringify(produto_servicos);
            if (empreendimento_id) paramsObj['empreendimento_id'] = empreendimento_id.toString();

            const urlParams = new URLSearchParams(paramsObj as Record<string, string>);

            const busca = `${url}/api/v1/admin/vendas/simular-venda-contrato?${urlParams}`;
            const response = await api.get(busca);

            if (response.status === 200) {
                Object.keys(response.data.data).forEach(key => {
                    handleChange(key, response.data.data[key]);
                });
            }
        } catch (error) {
            console.error('Error', 'Ocorreu um erro ao buscar a lista de permissões. Erro:' + String(error));
        }
    };
    useEffect(() => {
        const totalValorAcessorios = (formBasico?.produto_servicos || []).reduce((acc: number, form: any) => {
            if (form?.produto && form?.isTaxa != true) {
                return acc + ((form?.quantidade ?? 1) * (form?.produto?.valor ?? 0)) - (form?.tipo_desconto == 1 ? (form?.desconto / 100 * ((form?.produto?.valor ?? 0) * (form?.quantidade ?? 1))) : (form?.desconto * (form?.quantidade ?? 1)));
            }
            return acc;
        }, 0);

        setValorAcessorios(totalValorAcessorios);
    }, [formBasico?.produto_servicos]);

    useEffect(() => {
        setFormBasico((prevImovelData: any) => {
            return {
                ...prevImovelData,
                ['empreendimento_id']: TokenService.getEmpreendimentoId() ? TokenService.getEmpreendimentoId()?.id : null
            };
        });
    }, []);
    const validacao = () => {
        if (!formBasico) {
            showToast("error", "É necessario buscar um lote para prosseguir com a venda!")
            return false;
        }
        if (!formBasico?.imovel_id) {
            showToast("error", "O imóvel não foi encontrado ou não está disponível, Efetue a busca do imóvel corretamente!")
            return false;
        }
        if (!formBasico?.data_venda) {
            showToast("error", "O campo de data da venda é obrigatório!")
            return false;
        }
        if (!formBasico?.corretor_id) {
            showToast("error", "É necessario incluir um corretor para prosseguir, corrija o formulário e tente novamente!")
            return false;
        }
        if (!formBasico?.modo_jazigo) {
            showToast("error", "O campo Modo é obrigatório!")
            return false;
        }
        if (!formBasico?.tipo_jazigos_vendas) {
            showToast("error", "O campo Tipo de venda é obrigatório!")
            return false;
        }
        if (!formBasico?.manutencao_periodicidade) {
            showToast("error", "O campo Manutenção é obrigatório!")
            return false;
        }
        if (!formBasico?.data_entrega) {
            showToast("error", "O campo de data da entrega é obrigatório!")
            return false;
        }
        if (!formBasico?.data_prim_manutencao) {
            showToast("error", "O campo de data da primeira manutenção é obrigatório!")
            return false;
        }
        if (!formBasico?.cliente_compradores && currentPart === 1) {
            showToast("error", "É necessario incluir um comprador para prosseguir, corrija o formulário e tente novamente!")
            return false;
        }
        if (formBasico?.cliente_compradores && currentPart === 1) {
            const hasPrincipal = formBasico.cliente_compradores.some((client: any) => client.principal === true);

            if (!hasPrincipal) {
                showToast("error", "É necessário incluir um comprador principal para prosseguir, corrija o formulário e tente novamente!");
                return false;
            }
        }

        if (formBasico?.qtd_parc_entrada > TokenService.getEmpreendimentoId()?.config_venda?.qtd_parc_entrada && currentPart === 3) {
            showToast('error', 'Quantidade de parcelas da entrada não pode ser maior que a configurada no empreendimento!');
            return false;
        }
        return true;
    }
    const handleNext = () => {
        const valid = validacao();
        if (valid) {
            if (currentPart < totalParts) {
                setCurrentPart((prevPart) => prevPart + 1);
            }
        }
    };
    const handleChange = (field: string, value: any) => {
        setFormBasico((prevGrupoData: any) => {
            return {
                ...prevGrupoData,
                [field]: value
            };
        });
    }

    const handleBack = () => {
        if (currentPart > 0) {
            setCurrentPart((prevPart) => prevPart - 1);
        }
    };

    const handleSaveButton = async (): Promise<void> => {
        try {
            let data = {
                "data_venda": formBasico?.data_venda ? new Date(formBasico?.data_venda) : new Date().toUTCString(),
                "numero": formBasico?.numero ?? null,
                "imovel_id": formBasico?.imovel_id ?? null,
                "entrada": formBasico?.entrada ?? null,
                "tabela_preco_id": formBasico?.tabela_preco_id ?? null,
                "anotacoes": formBasico?.anotacoes ?? null,
                "valor_desconto": Number(formBasico?.valor_desconto) ?? null,
                "justificativa_desc": formBasico?.justificativa_desc ?? null,
                "status": 1,
                "tipo_contrato": 1,
                "data_prim_parcela": formBasico?.data_prim_parcela ? new Date(formBasico?.data_prim_parcela) : null,
                "venc_prim_parc_entrada": formBasico?.venc_prim_parc_entrada ? new Date(formBasico?.venc_prim_parc_entrada) : null,
                "valor_prim_parc_entrada": formBasico?.valor_prim_parc_entrada ?? null,
                "qtd_parc_entrada": formBasico?.qtd_parc_entrada ?? 0,
                "coordenador_id": formBasico?.coordenador_id ?? null,
                "corretor_id": formBasico?.corretor_id ?? null,
                "tipo_garantia": formBasico?.tipo_garantia ?? null,
                "clausula_especial": null,
                "gerou_lancamento": TokenService.getEmpreendimentoId()?.gerar_parcelas_contas_receber ?? false,
                "indice_reajuste_id": null,
                "periodicidade_reajuste": 12,
                "empreendimento_id": TokenService.getEmpreendimentoId()?.id,
                "cliente_compradores": formBasico?.cliente_compradores ?? [],
                "data_prim_manutencao": formBasico?.data_prim_manutencao ?? null,
                "data_entrega": formBasico?.data_entrega ?? null,
                "modo_jazigo": formBasico?.modo_jazigo ?? null,
                "tipo_jazigos_vendas": formBasico?.tipo_jazigos_vendas ?? null,
                "coef_manutencao": formBasico?.coef_manutencao ?? null,
                "manutencao_periodicidade": formBasico?.manutencao_periodicidade ?? null,
                "produto_servicos": formBasico?.produto_servicos ?? [],
            }
            const response = await api.post(`${url}/api/v1/admin/cemiterio/contrato-jazigo`, data);
            if (response.status === 200) {
                AlertService?.sucess('Sucesso!', 'Salvo com sucesso!').then(() => {
                    setShow(false);
                    window.location.reload();
                });
            }
        } catch (error: any) {
            let mensagens: string[] = [];
            Object.keys(error?.response?.data?.error?.message).forEach(key => {
                mensagens.push('\n' + error?.response?.data?.error?.message[key][0]);
            });
            AlertService.error('Erro!', String(mensagens));
        }
    };
    const items = [
        {
            label: 'Jazigo',
            command: (event: any) => {
                if (validacao()) {
                    setCurrentPart(event.index)
                }
            }
        },
        {
            label: 'Compradores',
            command: (event: any) => {
                if (validacao()) {
                    setCurrentPart(event.index)
                } else {
                    return
                }
            }
        },
        {
            label: 'Produtos|Serviços',
            command: (event: any) => {
                if (validacao()) {
                    setCurrentPart(event.index)
                } else {
                    return
                }
            }
        },
        {
            label: 'Entrada',
            command: (event: any) => {
                if (validacao()) {
                    setCurrentPart(event.index)
                }
            }
        },
        {
            label: 'Parcelamento',
            command: (event: any) => {
                if (validacao()) {
                    setCurrentPart(event.index)
                } else {
                    return
                }
            }
        }
    ];

    const MoneyFormatter = (saldoDevedor: any) => {
        const formatMoney = (value: number): string => {
            return new Intl.NumberFormat('pt-BR', {
                style: 'currency',
                currency: 'BRL',
            }).format(value);
        };
        return formatMoney(saldoDevedor);
    };

    const handleCompradoresInputChange = (index: number, field: string, value: any) => {
        const clientes = formBasico?.cliente_compradores;
        if (formBasico && clientes && index >= 0 && index < clientes.length) {
            setFormBasico((prevFormBasico: any) => {
                const updatedClientes = [...prevFormBasico.cliente_compradores];

                updatedClientes[index] = {
                    ...updatedClientes[index],
                    [field]: value,
                };
                return {
                    ...prevFormBasico,
                    cliente_compradores: updatedClientes,
                };
            });
        }
    };
    const handleAddCompradores = () => {
        addCompradores(formBasico, setFormBasico);
    };
    const handleRemoveCompradores = (index: number) => {
        removeCompradores(formBasico, setFormBasico, index);
    };

    return (
        <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            size="xl"
        >
            <Modal.Header style={{ display: 'flex', flexDirection: 'column' }}>
                <Modal.Title style={{ marginBottom: '25px', color: '#494949' }}>Venda de Jazigo</Modal.Title>
                <div style={{ display: 'block', width: '-webkit-fill-available' }}>
                    <Steps model={items} activeIndex={currentPart} readOnly={false} />
                </div>
            </Modal.Header>
            <Modal.Body style={{ padding: '0px' }}>
                <VendaForm
                    onInputChange={(field: string, value: string) => handleChange(`${field}`, value)}
                    onCompradoresInputChange={(index: number, field: string, value: any) => handleCompradoresInputChange(index, field, value)}
                    onAddCompradores={handleAddCompradores}
                    onRemoveCompradores={handleRemoveCompradores}
                    currentPart={currentPart}
                    formBasico={formBasico}
                />
            </Modal.Body>
            <Modal.Footer style={{ display: 'flex', flexDirection: 'column', marginRight: '10px', marginBottom: '10px', padding: '0px' }}>
                {formBasico?.qtd_gaveta && (
                    <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', backgroundColor: '#4949498a', paddingBottom: '2px', paddingTop: '2px', borderRadius: '7px' }}>
                        <label
                            htmlFor="ac"
                            style={{ display: 'flex', margin: '8px', color: '' }}
                        >
                            <p style={{ fontWeight: 'bolder', fontSize: '12px !important', margin: '0px', padding: '3px', backgroundColor: '#494949', color: '#fff', border: '1px solid #494949', borderTopLeftRadius: '4px' }}>
                                Jazigo
                            </p>
                            <p style={{ width: '100%', fontWeight: 'bolder', fontSize: '12px !important', margin: '0px', padding: '3px', backgroundColor: '#e8550f', color: '#fff', border: '1px solid #e8550f', borderBottomRightRadius: '4px' }}>
                                {formBasico?.unidade} - {formBasico?.qtd_gaveta} GV
                            </p>
                        </label>
                        <label
                            htmlFor="ac"
                            style={{ display: 'flex', margin: '8px', color: '' }}
                        >
                            <p style={{ width: '100%', fontWeight: 'bolder', fontSize: '12px !important', margin: '0px', padding: '3px', backgroundColor: '#494949', color: '#fff', border: '1px solid #494949', borderTopLeftRadius: '4px' }}>
                                Jazigo
                            </p>
                            <p style={{ width: '100%', fontWeight: 'bolder', fontSize: '12px !important', margin: '0px', padding: '3px', backgroundColor: '#e8550f', color: '#fff', border: '1px solid #e8550f', borderBottomRightRadius: '4px' }}>
                                {MoneyFormatter(formBasico?.imo_preco)}
                            </p>
                        </label>
                        <label
                            htmlFor="ac"
                            style={{ display: 'flex', margin: '8px', color: '' }}
                        >
                            <p style={{ width: '100%', fontWeight: 'bolder', fontSize: '12px !important', margin: '0px', padding: '3px', backgroundColor: '#494949', color: '#fff', border: '1px solid #494949', borderTopLeftRadius: '4px' }}>
                                Acessórios
                            </p>
                            <p style={{ width: '100%', fontWeight: 'bolder', fontSize: '12px !important', margin: '0px', padding: '3px', backgroundColor: '#e8550f', color: '#fff', border: '1px solid #e8550f', borderBottomRightRadius: '4px' }}>
                                {MoneyFormatter(valorAcessorios ?? 0)}
                            </p>
                        </label>
                        <label
                            htmlFor="ac"
                            style={{ display: 'flex', margin: '8px' }}
                        >
                            <p style={{ width: '100%', fontWeight: 'bolder', fontSize: '12px !important', margin: '0px', padding: '3px', backgroundColor: '#494949', color: '#fff', border: '1px solid #494949', borderTopLeftRadius: '4px' }}>
                                Entrada
                            </p>
                            <p style={{ width: '100%', fontWeight: 'bolder', fontSize: '12px !important', margin: '0px', padding: '3px', backgroundColor: '#e8550f', color: '#fff', border: '1px solid #e8550f', borderBottomRightRadius: '4px' }}>
                                {MoneyFormatter(formBasico?.entrada ?? 0)}
                            </p>
                        </label>
                        <label
                            htmlFor="ac"
                            style={{ display: 'flex', margin: '8px' }}
                        >
                            <p style={{ width: '100%', fontWeight: 'bolder', fontSize: '12px !important', margin: '0px', padding: '3px', backgroundColor: '#494949', color: '#fff', border: '1px solid #494949', borderTopLeftRadius: '4px' }}>
                                Desconto
                            </p>
                            <p style={{ width: '100%', fontWeight: 'bolder', fontSize: '12px !important', margin: '0px', padding: '3px', backgroundColor: '#e8550f', color: '#fff', border: '1px solid #e8550f', borderBottomRightRadius: '4px' }}>
                                {MoneyFormatter(formBasico?.valor_desconto ?? 0)}
                            </p>
                        </label>
                        <label
                            style={{ display: 'flex', margin: '8px', marginRight: '15px' }}
                        >
                            <p style={{ fontWeight: 'bolder', fontSize: '12px !important', margin: '0px', padding: '3px', backgroundColor: '#494949', color: '#fff', border: '1px solid #494949', borderTopLeftRadius: '4px' }}>
                                Parcelamento
                            </p>
                            <p style={{ width: '100%', fontWeight: 'bolder', fontSize: '12px !important', margin: '0px', padding: '3px', backgroundColor: '#e8550f', color: '#fff', border: '1px solid #e8550f', borderBottomRightRadius: '4px' }}>
                                {formBasico?.quantidade_parcelas ?? '1'} x {formBasico?.valor_parcelas ? MoneyFormatter(formBasico?.valor_parcelas) : 'R$ 0,00'}
                            </p>
                        </label>
                    </div>
                )}
                <div style={{ display: 'flex', justifyContent: 'end', paddingTop: '15px' }}>
                    <Button onClick={() => { handleClose() }} className="button-back-form" variant="primary">
                        Fechar
                    </Button>
                    {currentPart !== 0 && (
                        <Button onClick={() => { handleBack() }} className="button-back-form" variant="primary">
                            Voltar
                        </Button>
                    )}
                    {currentPart !== totalParts && (
                        <Button className="button-next-form" variant="primary" onClick={handleNext}>
                            Próximo
                        </Button>
                    )}
                    {currentPart === totalParts && (<Button className='button-save-or-update' onClick={handleSaveButton} variant="success">Salvar</Button>)}
                </div>
            </Modal.Footer>
        </Modal>
    );
}

export default ModalVendaContrato;
