import { ReactNode } from "react";
import LogoComponent from "./components/LogoComponent";
import ProfileComponent from "./components/ProfileComponent";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import SelectComponent from "../../../components/SelectComponent";

interface FilhoProps {
  children: ReactNode;
  empreendimento: any;
  onEmpreendimentoChange: (index: number) => void;
  menuProps: any;
}

const NavBarComponent: React.FC<FilhoProps> = ({ menuProps, children, empreendimento, onEmpreendimentoChange }) => {
  return (
    <Navbar variant="dark" className="navbar-header" bg="#494949" expand="lg">
      <Container fluid>
        <Navbar.Brand href="#home">
          <LogoComponent empreendimento={empreendimento}></LogoComponent>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbar-dark-example" />
        <Navbar.Collapse id="navbar-dark-example">
          <SelectComponent selects={empreendimento} onSelect={onEmpreendimentoChange} />
          {children}
          <ProfileComponent menus={menuProps} />
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavBarComponent;