import iconMenuTresPonto from '../../../../assets/images/icon-menu-tres-pontos.png';
import { Button } from 'primereact/button';
import { useEffect, useState } from 'react';
import { useSidebarContext } from '../../../contexts/SidebarContext';
import { Col, Form, InputGroup, NavLink, Row } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import CalendarIcon from '../../../../assets/images/icon-calendar.png';
import pt from 'date-fns/locale/pt';
import 'react-autocomplete-input/dist/bundle.css';
import { AlertService } from '../../../components/AlertService';
import TokenService from '../../../services/Auth/token.service';
import { useAppContext } from '../../../contexts/AppContext';
import { url } from '../../../environments/environments-develop';
import api from '../../../services/api';
import MultiSelectDropdown from '../../../components/MultiSelectCheckbox';
import { abrirPDF } from '../../../utils/PdfUtils';
import { Calendar } from 'primereact/calendar';
import { Skeleton } from 'primereact/skeleton';
import { addLocale } from 'primereact/api';
import { AutoComplete } from 'primereact/autocomplete';
import { searchCliente } from '../../Financeiro/ContasReceber/Service/ContasReceberService';


function RelatorioVendaIntermed() {
  const [listFiltros, setListFiltros] = useState<any>({});
  const [filtros, setFiltros] = useState<string>();
  const { isVisible, updateVisibility } = useSidebarContext();
  const [dataInicio, setDataInicio] = useState<string | null>(null);
  const [dataFim, setDataFim] = useState<string | null>(null);
  const [contratos_com_entrada, setContratosComEntrada] = useState<string | null>(null);
  const { showSubSidebar, setItemsBreadsCrumbs } = useAppContext();
  const [listCompradores, setListCompradores] = useState([]);
  const [listCorretores, setListCorretores] = useState([]);
  const [loading, setLoading] = useState(false);
  const [FilteredCliente, setFilteredCliente] = useState<any>(null);
  const [FilteredCorretor, setFilteredCorretor] = useState<any>(null);
  useEffect(() => {
    setItemsBreadsCrumbs([
      {
        label: 'Carteira',
        url: '/carteira',
      },
      {
        label: 'Relatórios de Vendas e intermediação',
        url: '/carteira',
      }
    ]);
  }, []);
  const consultaPorPeriodo = [
    { label: 'Data da Venda', value: 'vendas.contrato.data_venda' },
    { label: 'Data Proximo Reajuste', value: 'vendas.contrato.prox_reajuste' },
    { label: 'Data do Acerto', value: 'vendas.contrato.data_acerto' }
  ];
  const ordenacao = [
    { label: 'Contrato', value: 'vendas.contrato.id' },
    { label: 'Quadra e Lote', value: 'vendas.imoveis.identificador1' },
    { label: 'Proximo Reajuste', value: 'vendas.contrato.prox_reajuste' },
    { label: 'Data Venda', value: 'vendas.contrato.data_venda' },
    { label: 'Nome Comprador', value: 'core.pessoas.nome' },
  ];
  const toggleVisibility = () => {
    const newValue = !isVisible;
    updateVisibility(newValue);
  };
  async function gerarPDF(): Promise<void> {
    try {
      const consulta: any = {
        consulta: {
          order: (listFiltros?.ordenacao ? listFiltros.ordenacao : "vendas.contrato.id") + ";asc",
          filtro: {
            'vendas.cliente_compradores.cliente_id': listFiltros.cliente_id,
            'vendas.contrato.corretor_id': listFiltros.corretor_id,
            'vendas.contrato.entrada': contratos_com_entrada ? 0 : null,
            tipo_consulta: listFiltros.tipo_consulta,
            data_inicial: listFiltros?.dataInicio,
            data_final: listFiltros?.dataFim,
            tipo_relatorio: listFiltros.tipo_relatorio,
            empreendimento_id: TokenService.getEmpreendimentoId() ? TokenService.getEmpreendimentoId().id : null
          }
        }
      };

      const filtrarCampos = (obj: any) => {
        return Object.entries(obj)
          .filter(([_, value]) => value !== undefined && value !== null && value !== '')
          .reduce((acc, [key, value]) => {
            if (typeof value === 'object' && !Array.isArray(value)) {
              acc[key] = filtrarCampos(value);
            } else {
              acc[key] = value;
            }
            return acc;
          }, {} as any);
      };

      const consultaFiltrada = filtrarCampos(consulta);

      const queryParams: any = {};

      const camposEnviar = ['consulta'];

      camposEnviar.forEach((key: any) => {
        const value = consultaFiltrada[key];
        if (value !== undefined && value !== null && value !== '') {
          if (key === 'consulta') {
            queryParams[key] = JSON.stringify(value);
          } else {
            queryParams[key] = value;
          }
        }
      });

      const queryString = Object.entries(queryParams)
        .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(String(value))}`)
        .join('&');

      const response = await api.get(`${url}/api/v1/admin/relatorio/modelo-relatorio/gerar-relatorio/1?${queryString}`, {
        responseType: 'arraybuffer',
      });

      if (response.status === 200) {
        handleOpenPDF(response.data, listFiltros);
      }

    } catch (error: any) {
      AlertService.error('Erro!', String(error.response?.data?.message) || 'Erro desconhecido');
    }
  }

  const search = (event: any) => {
    setTimeout(() => {
      let _FilteredPessoa: any;

      if (!event.query.trim().length) {
        _FilteredPessoa = [...listCorretores];
      }
      else {
        if (event.query) {
          _FilteredPessoa = listCorretores.filter((item: any) => {
            return item.nome_corretor && item.nome_corretor.toLowerCase().startsWith(event.query.toLowerCase());
          });
        }
      }
      setFilteredCorretor(_FilteredPessoa);
    }, 250);
  }
  useEffect(() => {
    InitCorretores();
    InitCompradores();
  }, []);

  const handleChange = (field: string, value: any) => {
    setListFiltros((prevGrupoData: any) => {
      return {
        ...prevGrupoData,
        [field]: value
      };
    });
  };

  async function InitCompradores() {
    try {
      const response = await api.get(`${url}/api/v1/admin/financeiro/cliente?include=pessoa.conjugue`);
      setListCompradores(response.data.data);
    } catch (error) {
      AlertService.error('Error', 'Ocorreu um erro ao buscar a de clientes. Erro:' + String(error));
    }
  }
  async function InitCorretores() {
    try {
      const response = await api.get(`${url}/api/v1/admin/vendas/corretores?include=pessoa`);
      setListCorretores(response.data.data);
    } catch (error) {
      AlertService.error('Error', 'Ocorreu um erro ao buscar a lista de permissões. Erro:' + String(error));
    }
  }

  const handleOpenPDF = (pdfBase64: string, listFiltros: any) => {
    abrirPDF(pdfBase64, listFiltros);
  }
  addLocale('pt', {
    firstDayOfWeek: 0,
    dayNames: ['domingo', 'segunda-feira', 'terça-feira', 'quarta-feira', 'quinta-feira', 'sexta-feira', 'sábado'],
    dayNamesShort: ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sáb'],
    dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
    monthNames: ['janeiro', 'fevereiro', 'março', 'abril', 'maio', 'junho', 'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro'],
    monthNamesShort: ['jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez'],
    today: 'Hoje',
    clear: 'Limpar'
  });

  return (
    <div className="body-content">
      <div
        className={`${showSubSidebar ? "container-section-body" : "container-section-body-none"}`}
      >
        <div className="contentSearch">
          <div className="contentSearch-header">
            <NavLink onClick={toggleVisibility}>
              <img
                className="menu-context"
                src={iconMenuTresPonto}
              />
            </NavLink>
            <Col className='text-center' style={{ fontSize: '20px', color: '#494949' }}>Relatórios de Vendas e intermediação</Col>
          </div>
          <div style={{ opacity: '34%' }} className="contentTraçoBodyHeader"></div>
          <Form.Group className="imovel-form" style={{ marginLeft: '22px' }}>
            <Row className="mb-3">
              {!loading ? (<Col md="3" style={{ display: 'flex', flexDirection: 'column' }}>
                <Form.Label>Tipo de data</Form.Label>
                <Form.Select value={listFiltros.tipo_consulta || ''} onChange={(e) => handleChange('tipo_consulta', e.target.value)}>
                  <option>Selecione...</option>
                  {consultaPorPeriodo.map((item: any) => (
                    <option key={item.value} value={item.value}>{item.label}</option>
                  ))}
                </Form.Select>
              </Col>) : (<Skeleton />)}
              {!loading ? (<Col md={3} style={{ display: 'flex', flexDirection: 'column' }}>
                <Form.Label>
                  Data de Inicio
                </Form.Label>
                <div className=" flex justify-center">
                  <Calendar
                    value={dataInicio ? new Date(dataInicio) : null}
                    onChange={(event: any) => {
                      const selectedDates = event.value;
                      setDataInicio(selectedDates);
                      handleChange('dataInicio', selectedDates ? selectedDates.toISOString() : null);
                    }}
                    placeholder="Data de inicio"
                    showButtonBar
                    selectionMode="single"
                    dateFormat="dd/mm/yy"
                    locale="pt"
                    readOnlyInput
                    style={{ textAlign: 'center', width: '100%' }}
                    panelClassName="custom-calendar-panel"
                    required
                  />
                </div>
              </Col>) : (<Skeleton />)}
              {!loading ? (<Col md={3} style={{ display: 'flex', flexDirection: 'column' }}>
                <Form.Label>
                  Data de Fim
                </Form.Label>
                <div className=" flex justify-center">
                  <Calendar
                    value={dataFim ? new Date(dataFim) : null}
                    onChange={(event: any) => {
                      const selectedDates = event.value;
                      setDataFim(selectedDates);
                      handleChange('dataFim', selectedDates ? selectedDates.toISOString() : null);
                    }}
                    placeholder="Data Final"
                    showButtonBar
                    selectionMode="single"
                    dateFormat="dd/mm/yy"
                    locale="pt"
                    readOnlyInput
                    style={{ textAlign: 'center', width: '100%' }}
                    panelClassName="custom-calendar-panel"
                    required
                  />
                </div>
              </Col>) : (<Skeleton />)}

              {!loading ? (
                <Col md="3" style={{ display: 'flex', flexDirection: 'column' }}>
                  <Form.Label>Cliente|Devedor</Form.Label>
                  <AutoComplete
                    field="nome_cliente"
                    style={{ height: "37px" }}
                    value={listFiltros?.cliente}
                    suggestions={FilteredCliente}
                    completeMethod={(e) => searchCliente(e, setFilteredCliente)}
                    onChange={(e) => {
                      setListFiltros({ ...listFiltros, cliente: e.value })
                    }}
                    onSelect={(e) => {
                      setListFiltros({ ...listFiltros, cliente_id: e.value.id, cliente: e.value })
                    }}
                    dropdown
                  />
                </Col>) : (<Skeleton />)}
            </Row>
            <Row className="mb-3">
              {!loading ? (<Col md="3" style={{ display: 'flex', flexDirection: 'column' }}>
                <Form.Label>Ordenação</Form.Label>
                <Form.Select value={listFiltros.ordenacao || ''} onChange={(e) => handleChange('ordenacao', e.target.value)}>
                  <option>Selecione...</option>
                  {ordenacao.map((item: any) => (
                    <option key={item.value} value={item.value}>{item.label}</option>
                  ))}
                </Form.Select>
              </Col>) : (<Skeleton />)}
              {!loading ? (<Col md="3" style={{ display: 'flex', flexDirection: 'column' }}>
                <Form.Label>Corretor/Vendedor</Form.Label>
                <AutoComplete
                  placeholder='Pesquisar nome'
                  field="nome_corretor"
                  style={{ height: "37px" }}
                  value={listFiltros.corretor}
                  suggestions={FilteredCorretor}
                  completeMethod={search}
                  onChange={(e) => {
                    setListFiltros({ ...listFiltros, corretor: e.value })
                  }}
                  onSelect={(e) => {
                    setListFiltros({ ...listFiltros, corretor_id: e.value.id, corretor: e.value })
                  }}
                  dropdown
                />
              </Col>) : (<Skeleton />)}
              {!loading ? (<Col md="3" style={{ display: 'flex', flexDirection: 'column' }}>
                <Form.Label>Tipo de Relatório</Form.Label>
                <Form.Select value={listFiltros.tipo_relatorio || ''} isInvalid={!listFiltros.tipo_relatorio} onChange={(e) => handleChange('tipo_relatorio', e.target.value)}>
                  <option>Selecione...</option>
                  <option value={1} key={1}>PDF</option>
                  <option value={2} key={2}>EXCEL</option>
                </Form.Select>
              </Col>) : (<Skeleton />)}
              {!loading ? (<Col md="3" style={{ display: 'flex', flexDirection: 'column' }}>
                <Form.Label>Apenas Contratos com Entradas?</Form.Label>
                <Form.Select value={contratos_com_entrada || ''} onChange={(e) => setContratosComEntrada(e.target.value)}>
                  <option>Selecione...</option>
                  <option value={1}>Sim</option>
                  <option value={2}>Não</option>
                </Form.Select>
              </Col>) : (<Skeleton />)}
            </Row>
          </Form.Group>
          <div className='div-filtros-avancados'>
            {listFiltros.tipo_relatorio &&
              (!loading ? (<Button
                onClick={() => gerarPDF()}
                style={{ height: '30px', borderRadius: '8px', padding: '5px 10px', marginRight: '5px', fontSize: '14px' }}
                type="button"
                severity="success"
                icon="pi pi-search"
                rounded>
                <p style={{ marginLeft: '5px', marginTop: '13px' }}>Gerar Relatório</p>
              </Button>) : (<Skeleton />))}
            {!loading ? (<Button
              style={{ height: '30px', borderRadius: '8px', padding: '5px 10px', marginRight: '25px', fontSize: '14px' }}
              type="button"
              severity="danger"
              icon="pi pi-delete-left"
              rounded
              onClick={() => {
                setListFiltros({});
                setDataInicio(null);
                setDataFim(null);
              }}
            >
              <p style={{ marginLeft: '5px', marginTop: '13px' }}>Limpar</p>
            </Button>) : (<Skeleton />)}
          </div>
        </div>
      </div>
    </div>
  );
}

export default RelatorioVendaIntermed;