import React, { useState, useEffect } from 'react';
import { AlertService } from './AlertService';
import { url } from '../environments/environments-develop';
import api from '../services/api';
import "primereact/resources/themes/lara-light-cyan/theme.css";
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import 'primereact/resources/themes/lara-light-cyan/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { DataTable } from 'primereact/datatable';
import { useAppContext } from '../contexts/AppContext';
import TokenService from '../services/Auth/token.service';
import { Modal } from 'react-bootstrap';

const ExclusaoLotesTableComponent: React.FC<any> = ({ urlSearch }) => {
    const { setBody, setUrl, setShowButtonSave } = useAppContext();
    const [currentPage, setCurrentPage] = useState(0);
    const [nodes, setNodes] = useState<any | null>(null);
    const [totalPages, setTotalPages] = useState(0);
    const [rowClick, setRowClick] = useState(true);
    const [selectedImoveis, setSelectedImoveis] = useState(null);
    const [formBasico, setFormBasico] = useState<any>({});
    const [showModalConfirmarExclusao, setShowModalConfirmarExclusao] = useState(false);
    useEffect(() => {
        fetchData();
    }, [currentPage, urlSearch]);
    useEffect(() => {
        fetchData();
    }, []);
    const fetchData = async () => {
        try {
            const response = await api.get(`${url}${urlSearch}`);
            if (response.status === 200) {
                const data = response.data.data;
                setNodes(data);
            }
        } catch (error: any) {
            let mensagens: string[] = [];
            mensagens.push(error?.response?.data?.error?.message);
            AlertService.error('Erro!', String(mensagens));
        }
    };


    const handleChange = (value: any) => {
        let imoveisIds: number[] = [];

        value.forEach((item: any) => {
            let imovel_id = item.id;
            imoveisIds.push(imovel_id);
        });

        setFormBasico((prevData: any) => {
            return {
                ...prevData,
                empreendimento_id: TokenService.getEmpreendimentoId() ? TokenService.getEmpreendimentoId().id : null,
                imoveis: imoveisIds
            };
        });
    };
    const confirmarExclusao = async () => {
        try {
            const response = await api.post(`${url}/api/v1/admin/core/exclusao-lotes`, formBasico, {
                responseType: 'arraybuffer',
            });
            if (response.status === 200) {
                var file = null;
                file = new Blob([response.data], {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                });
                const fileURL = URL.createObjectURL(file);
                window.open(fileURL, '_blank');
            }
        } catch (error: any) {
            let mensagens: string[] = [];
            mensagens.push(error?.response?.data?.error?.message);
            AlertService.error('Erro!', String(mensagens));
        }
    };

    useEffect(() => {
        setBody(formBasico)
    }, [formBasico]);
    return (
        <div className="card">
            <DataTable
                value={nodes ? nodes : []}
                paginator
                rows={8}
                rowsPerPageOptions={[8, 16, 25, 50]}
                selectionMode={rowClick ? null : 'checkbox'}
                selection={selectedImoveis}
                onSelectionChange={(e: any) => { setSelectedImoveis(e.value), handleChange(e.value) }}
                dataKey="id"
                tableStyle={{ minWidth: '50rem' }}
                emptyMessage={<span style={{ display: 'flex', justifyContent: 'center', fontStyle: 'italic' }}>Nenhum item encontrado.</span>}
            >
                <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
                <Column field="id" header="Id"></Column>
                <Column field="unidade" header="Unidade"></Column>
                <Column field="area_label" header="Área"></Column>
                <Column field="status_name" header="Status"></Column>
                <Column field="qtd_max_parcelas" header="Qtd. Parcelas"></Column>
                <Column field="classificacao_preco_rotulo" header="Classif.Preço"></Column>
                <Column field="construcao_label" header="Const."></Column>
            </DataTable>
            <div style={{ display: 'flex', justifyContent: 'right' }}>
                <Button
                    style={{ height: '30px', borderRadius: '20px', padding: '5px 10px', margin: '15px', fontSize: '14px' }}
                    icon="pi pi-plus"
                    label="Confirmar"
                    severity="danger"
                    className="p-button-raised"
                    onClick={() => { setShowModalConfirmarExclusao(true) }}
                ></Button>
            </div>
            <Modal show={showModalConfirmarExclusao} style={{ padding: '0px !important' }} size='xl' onHide={() => setShowModalConfirmarExclusao(false)}>
                <Modal.Header closeButton>
                    <Modal.Title className='text-center'>Confirmar a Exclusão dos seguintes lotes?</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ padding: '0px !important' }}>
                    <DataTable
                        paginator
                        rows={8}
                        rowsPerPageOptions={[8, 16, 25, 50]}
                        stripedRows
                        lazy
                        value={selectedImoveis ? selectedImoveis : []}
                        tableStyle={{ minWidth: '50rem' }}
                    >
                        <Column field="id" header="Id" style={{ textAlign: 'center', borderBottom: '1px solid #bdbdbd96' }}></Column>
                        <Column field="unidade" header="Unidade" style={{ textAlign: 'center', borderBottom: '1px solid #bdbdbd96' }}></Column>
                        <Column field="area_label" header="Área" style={{ textAlign: 'center', borderBottom: '1px solid #bdbdbd96' }}></Column>
                        <Column field="status_name" header="Status" style={{ textAlign: 'center', borderBottom: '1px solid #bdbdbd96' }}></Column>
                        <Column field="qtd_max_parcelas" header="Qtd. Parcelas" style={{ textAlign: 'center', borderBottom: '1px solid #bdbdbd96' }}></Column>
                        <Column field="classificacao_preco_rotulo" header="Classif.Preço" style={{ textAlign: 'center', borderBottom: '1px solid #bdbdbd96' }}></Column>
                        <Column field="construcao_label" header="Const." style={{ textAlign: 'center', borderBottom: '1px solid #bdbdbd96' }}></Column>
                    </DataTable>
                </Modal.Body>
                <Modal.Footer>
                    <Button severity="success" onClick={() => { setShowModalConfirmarExclusao(false), confirmarExclusao(); }}>Confirmar</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default ExclusaoLotesTableComponent;
