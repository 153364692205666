import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Routes from './app/routes/Routes';
import 'primereact/resources/themes/saga-blue/theme.css';   // Tema principal do PrimeReact
import 'primereact/resources/primereact.min.css';          // CSS principal do PrimeReact
import 'primeicons/primeicons.css';
import './index.css';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <BrowserRouter>
    <ToastContainer />
    <Routes />
  </BrowserRouter>
);

const body = document.getElementsByTagName('body')[0];

const bootstrapCssLink = document.createElement('link');
bootstrapCssLink.rel = 'stylesheet';
bootstrapCssLink.href = 'https://cdn.jsdelivr.net/npm/bootstrap@5.3.2/dist/css/bootstrap.min.css';
bootstrapCssLink.integrity = 'sha384-T3c6CoIi6uLrA9TneNEoa7RxnatzjcDSCmG1MXxSR1GAsXEV/Dwwykc2MPK8M2HN';
bootstrapCssLink.crossOrigin = 'anonymous';
body.appendChild(bootstrapCssLink);

const popperScript = document.createElement('script');
popperScript.src = 'https://cdn.jsdelivr.net/npm/@popperjs/core@2.11.8/dist/umd/popper.min.js';
popperScript.integrity = 'sha384-I7E8VVD/ismYTF4hNIPjVp/Zjvgyol6VFvRkX/vR+Vc4jQkC+hVqc2pM8ODewa9r';
popperScript.crossOrigin = 'anonymous';
body.appendChild(popperScript);

const bootstrapScript = document.createElement('script');
bootstrapScript.src = 'https://cdn.jsdelivr.net/npm/bootstrap@5.3.2/dist/js/bootstrap.min.js';
bootstrapScript.integrity = 'sha384-BBtl+eGJRgqQAUMxJ7pMwbEyER4l1g+O15P+16Ep7Q9Q+zqX6gSbd85u4mG4QzX+';
bootstrapScript.crossOrigin = 'anonymous';
body.appendChild(bootstrapScript);

reportWebVitals();
