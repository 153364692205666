import iconMenuTresPonto from '../../../../../assets/images/icon-menu-tres-pontos.png';
import { Button } from 'primereact/button';
import 'react-autocomplete-input/dist/bundle.css';
import { useState } from 'react';
import { useSidebarContext } from '../../../../contexts/SidebarContext';
import { Row, Col, Form, NavLink, Modal, Container, InputGroup } from 'react-bootstrap';
import iconFiltroAvancado from '../../../../../assets/images/icon-filtro-avancado.png';
import DatePicker from 'react-datepicker';
import pt from 'date-fns/locale/pt';
import 'react-datepicker/dist/react-datepicker.css';
import CalendarIcon from '../../../../../assets/images/icon-calendar.png';


interface ContextSearchProps {
    header?: string;
    filtrosData: {
        numero?: string;
        observacao?: string;
        status?: number;
        vencimento?: string;
        data?: string;
        cpf_cnpj?: string;
        nome?: string;
        cliente_id?: number
    };
    onInputClearList: () => void;
    onInputChangeList: (field: string, value: any) => void;
    onInputChange: (field: string, value: any) => void;
}

function FiltroGestaoContratos(props: ContextSearchProps) {
    const { numero, observacao, status, vencimento, data, cpf_cnpj, nome, cliente_id } = props.filtrosData || {};
    const { isVisible, updateVisibility } = useSidebarContext();
    const [showModal, setShowModal] = useState(false);
    const [dataData, setData] = useState<string | null>(
        props.filtrosData && typeof props.filtrosData.data === 'string'
            ? props.filtrosData.data
            : null
    );

    const [dataVencimento, setVencimento] = useState<string | null>(
        props.filtrosData && typeof props.filtrosData.vencimento === 'string'
            ? props.filtrosData.vencimento
            : null
    );


    const toggleVisibility = () => {
        const newValue = !isVisible;
        updateVisibility(newValue);
    };

    const handleChange = (field: string, value: any) => {
        props.onInputChange(field, value);
    };

    const handleChangeList = (field: string, value: any) => {
        props.onInputChangeList(field, value);
    };

    const handleClearList = () => {
        setShowModal(false);
        setData(null);
        setVencimento(null);
        props.onInputClearList();
    };

    const handleFilter = () => {
        setShowModal(false);
        if (numero) {
            handleChangeList('numero', numero);
        }
        if (vencimento) {
            handleChangeList('vencimento', vencimento);
        }
        if (status) {
            handleChangeList('status', status);
        }
        if (data) {
            handleChangeList('data', data);
        }
        if (cpf_cnpj) {
            handleChangeList('cpf_cnpj', cpf_cnpj);
        }
        if (nome) {
            handleChangeList('nome', nome);
        }
        if (observacao) {
            handleChangeList('observacao', observacao);
        }
        if (cliente_id) {
            handleChangeList('cliente_id', cliente_id);
        }
    };

    return (
        <div className="contentSearch">
            <div className="contentSearch-header">
                <NavLink onClick={toggleVisibility}>
                    <img
                        className="menu-context"
                        src={iconMenuTresPonto}
                    />
                </NavLink>
                <Col className='text-center' style={{ fontSize: '20px', color: '#494949' }}>{props.header}</Col>
            </div>
            <div style={{ opacity: '34%' }} className="contentTraçoBodyHeader"></div>
            <div className="contentSearch-body">
                <div className='div-calendar'>
                    <Col md="4" style={{ marginRight: "8px" }}>
                        <Form.Label>N° Contrato</Form.Label>
                        <Form.Control
                            value={numero || ''}
                            onChange={(e) => handleChange("numero", e.target.value)}
                        />
                    </Col>
                    <Col md="4" style={{ marginRight: "8px" }}>
                        <Form.Label>Status</Form.Label>
                        <Form.Select value={status || ''} onChange={(e) => handleChange('status', e.target.value)}>
                            <option>Selecione...</option>
                            <option value={"1"}>Aguardando</option>
                            <option value={"2"}>Aprovado</option>
                            <option value={"3"}>Rejeitado</option>
                        </Form.Select>
                    </Col>
                </div>
                <div className='div-filtros-avancados' style={{ marginTop: '35px' }}>
                    {/* <Button
                        style={{ height: '30px', borderRadius: '20px', padding: '5px 10px', marginRight: '5px', fontSize: '14px' }}
                        type="button"
                        onClick={() => { setShowModal(true) }}
                        rounded outlined severity="secondary">
                        <img style={{ width: '25px', height: '35px' }} src={iconFiltroAvancado} />
                    </Button> */}
                    <Button
                        onClick={handleFilter}
                        style={{ height: '30px', borderRadius: '20px', padding: '5px 10px', marginRight: '5px', fontSize: '14px' }}
                        type="button"
                        severity="success"
                        icon="pi pi-filter"
                        rounded>
                        <p style={{ marginLeft: '5px', marginTop: '13px' }}>Pesquisar</p>
                    </Button>
                    <Button
                        onClick={handleClearList}
                        style={{ height: '30px', borderRadius: '20px', padding: '5px 10px', marginRight: '5px', fontSize: '14px' }}
                        type="button"
                        severity="danger"
                        icon="pi pi-delete-left"
                        rounded>
                        <p style={{ marginLeft: '5px', marginTop: '13px' }}>Limpar</p>
                    </Button>
                </div>
            </div>
            <Modal
                show={showModal}
                size='lg'
                onHide={() => setShowModal(false)}
                className='modal-filtro-avancado'
            >
                <Modal.Header closeButton>
                    <Modal.Title>Filtros Avançados</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Row>
                            <Col md={3}>
                                <Form.Label>Cpf ou CNPJ</Form.Label>
                                <Form.Control
                                    value={cpf_cnpj || ''}
                                    onChange={(e) => handleChange("cpf_cnpj", e.target.value)}
                                />
                            </Col>
                            <Col md={3}>
                                <Form.Label>Nome</Form.Label>
                                <Form.Control
                                    value={nome || ''}
                                    onChange={(e) => handleChange("nome", e.target.value)}
                                />
                            </Col>
                            <Col md={3}>
                                <Form.Label>Observação</Form.Label>
                                <Form.Control
                                    value={observacao || ''}
                                    onChange={(e) => handleChange("observacao", e.target.value)}
                                />
                            </Col>
                            <Col md={3}>
                                <Form.Label>Cliente</Form.Label>
                                <Form.Control
                                    value={cliente_id || ''}
                                    onChange={(e) => handleChange("cliente_id", e.target.value)}
                                />
                            </Col>
                            <Col md={3}>
                                <Form.Label>Data de Vencimento</Form.Label>
                                <Form.Group controlId="formGridState" >
                                    <DatePicker
                                        selected={vencimento ? new Date(vencimento) : null}
                                        onChange={(date: Date) => {
                                            const formattedDate = date.toISOString().split('T')[0];
                                            setVencimento(formattedDate);
                                            handleChange('vencimento', formattedDate);
                                        }}
                                        peekNextMonth
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                        fixedHeight
                                        locale={pt}
                                        customInput={
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <input
                                                    type="date"
                                                    placeholder="Data Inicio"
                                                    className="dateFormFinanceiro"
                                                    value={vencimento ? vencimento : ''}
                                                    readOnly
                                                />
                                                <img
                                                    className="calendar-icon"
                                                    src={CalendarIcon}
                                                />
                                            </div>
                                        }
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={3}>
                                <Form.Label>Data </Form.Label>
                                <Form.Group controlId="formGridState" >
                                    <DatePicker
                                        selected={data ? new Date(data) : null}
                                        onChange={(date: Date) => {
                                            const formattedDate = date.toISOString().split('T')[0];
                                            setData(formattedDate);
                                            handleChange('data', formattedDate);
                                        }}
                                        peekNextMonth
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                        fixedHeight
                                        locale={pt}
                                        customInput={
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <input
                                                    type="date"
                                                    placeholder="Data Fim"
                                                    className="dateFormFinanceiro"
                                                    value={data ? String(data) : ''}
                                                    readOnly
                                                />
                                                <img
                                                    className="calendar-icon"
                                                    src={CalendarIcon}
                                                />
                                            </div>
                                        }
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        onClick={handleFilter}
                        style={{ marginTop: '3px', width: '100px', height: '30px', borderRadius: '5px', padding: '5px 10px', marginRight: '5px', fontSize: '14px', marginLeft: '10px' }}
                        type="button"
                        severity="success"
                        icon="pi pi-filter"
                        rounded>
                        <p style={{ marginLeft: '5px', marginTop: '15px' }}>Pesquisar</p>
                    </Button>
                    <Button
                        onClick={handleClearList}
                        style={{ marginTop: '3px', width: '100px', height: '30px', borderRadius: '5px', padding: '5px 10px', marginRight: '5px ', fontSize: '14px' }}
                        type="button"
                        severity="danger"
                        icon="pi pi-delete-left"
                        rounded>
                        <p style={{ marginLeft: '5px', marginTop: '15px' }}>Limpar</p>
                    </Button>
                </Modal.Footer>
            </Modal >
        </div >
    );
}

export default FiltroGestaoContratos;
