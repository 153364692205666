import iconMenuTresPonto from './../../../../assets/images/icon-menu-tres-pontos.png';
import { Button } from 'primereact/button';
import React, { useState } from 'react';
import { useSidebarContext } from '../../../contexts/SidebarContext';
import { Col, Form, NavLink } from 'react-bootstrap';
import 'react-autocomplete-input/dist/bundle.css';

interface ContextSearchProps {
    header?: string;
    filtrosData: {
        nome?: string | null;
        dataInicio?: string;
        dataFim?: string;
    };
    onInputClearList: () => void;
    onInputChangeList: (field: string, value: any) => void;
    onInputChange: (field: string, value: any) => void;
}

function ContentSearchTipoImoveis(props: ContextSearchProps) {
    const { nome } = props.filtrosData || {};
    const { isVisible, updateVisibility } = useSidebarContext();
    const [dataInicio, setDataInicio] = useState<string | null>(
        props.filtrosData && typeof props.filtrosData.dataInicio === 'string'
            ? props.filtrosData.dataInicio
            : null
    );

    const [dataFim, setDataFim] = useState<string | null>(
        props.filtrosData && typeof props.filtrosData.dataFim === 'string'
            ? props.filtrosData.dataFim
            : null
    );

    const toggleVisibility = () => {
        const newValue = !isVisible;
        updateVisibility(newValue);
    };

    const handleChange = (field: string, value: any) => {
        props.onInputChange(field, value);
    };

    const handleChangeList = (field: string, value: any) => {
        props.onInputChangeList(field, value);
    };

    const handleClearList = () => {
        setDataInicio(null);
        setDataFim(null);
        props.onInputClearList();
    };

    const handleFilter = () => {
        if (nome) {
            handleChangeList('nome', nome);
        }
        if (dataInicio) {
            handleChangeList('dataInicio', dataInicio);
        }
        if (dataFim) {
            handleChangeList('dataFim', dataFim);
        }
    };

    return (
        <div className="contentSearch">
            <div className="contentSearch-header">
                <NavLink onClick={toggleVisibility}>
                    <img
                        className="menu-context"
                        src={iconMenuTresPonto}
                    />
                </NavLink>
                <Col className='text-center' style={{ fontSize: '20px', color: '#494949' }}>{props.header}</Col>
            </div>
            {/* <div style={{ opacity: '34%' }} className="contentTraçoBodyHeader"></div>
            <div className="contentSearch-body">
                <div className='filter-campos'>
                    <div className='div-calendar' style={{ height: "37px", marginRight: '12px' }}>
                        <Form.Control
                            value={nome ? nome : ''}
                            placeholder='Nome Tipo Imovel'
                            onChange={(e) => handleChange("Nome Tipo Imovel", e.target.value)}
                        />
                    </div>
                    <div className='div-calendar' style={{ marginTop: '5px', textAlign:'center', alignItems:'center',height: "37px", marginRight: '12px' }}>
                        <Form.Label>Status</Form.Label>
                        <Form.Check
                            style={{ marginBottom: '8px', marginLeft: '12px'}}
                            onChange={(e) => handleChange("status", e.target.checked)}
                            type="switch"
                            id="custom-switch"
                        />
                    </div>
                </div>
                <div className='div-filtros-avancados'>
                    <Button
                        onClick={handleFilter}
                        style={{ height: '30px', borderRadius: '5px', padding: '5px 10px', marginRight: '5px', fontSize: '14px' }}
                        type="button"
                        severity="success"
                        icon="pi pi-filter"
                        rounded>
                        <p style={{ marginLeft: '5px', marginTop: '13px' }}>Pesquisar</p>
                    </Button>
                    <Button
                        onClick={handleClearList}
                        style={{ height: '30px', borderRadius: '5px', padding: '5px 10px', marginRight: '5px', fontSize: '14px' }}
                        type="button"
                        severity="danger"
                        icon="pi pi-delete-left"
                        rounded>
                        <p style={{ marginLeft: '5px', marginTop: '13px' }}>Limpar</p>
                    </Button>
                </div>
            </div> */}
        </div>
    );
}

export default ContentSearchTipoImoveis;
