import { useEffect } from 'react';
import TokenService from '../../../services/Auth/token.service';
import Cemiterio from './Pages/Cemiterio';
import Imobiliaria from './Pages/Imobiliaria';
import { useAppContext } from '../../../contexts/AppContext';

function NovaVenda() {
    const filial = TokenService.getFilialId();
    const { setItemsBreadsCrumbs } = useAppContext();

    if (filial?.tipo_empresa === 1) {
        useEffect(() => {
            setItemsBreadsCrumbs([
                {
                    label: 'Vendas',
                    url: '/vendas',
                },
                {
                    label: 'Novas Vendas',
                    url: '/venda/gerar-venda',
                },
            ]);
        }, []);
        return <Imobiliaria />;
    } else if (filial?.tipo_empresa === 2) {
        useEffect(() => {
            setItemsBreadsCrumbs([
                {
                    label: 'Vendas',
                    url: '/vendas',
                },
                {
                    label: 'Lista de Vendas',
                    url: '/venda/gerar-venda',
                },
            ]);
        }, []);
        return <Cemiterio />;
    }

    return null;
}

export default NovaVenda;
