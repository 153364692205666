import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Button, Modal } from 'react-bootstrap';
import { AlertService } from '../../../../components/AlertService';
import api from '../../../../services/api';
import { url } from '../../../../environments/environments-develop';
import { set } from 'date-fns';

interface ModalConfirmarExclusãoProps {
    selectedData: any;
    showModalConfirmarExclusao: boolean;
    setShowModalConfirmarExclusao: (show: boolean) => void;
}

function ModalConfirmarExclusão({
    selectedData,
    setShowModalConfirmarExclusao,
    showModalConfirmarExclusao
}: ModalConfirmarExclusãoProps) {

    const handleDelete = async () => {
        if (selectedData?.id) {
            try {
                const response = await api.post(`${url}/api/v1/admin/financeiro/plano-conta/delete-plano-conta`, {
                    plano_conta_id: selectedData.id
                });
                if (response.status === 200) {
                    AlertService.sucess('Sucesso', 'Item excluído com sucesso!');
                    setShowModalConfirmarExclusao(false);
                    window.location.reload();
                }
            } catch (error: any) {
                setShowModalConfirmarExclusao(false);
                AlertService.error('Erro ao excluir item!', error?.response?.data?.error?.message?.plano_conta_id?.[0] || error.message);
            }
        }
    };

    return (
        <Modal
            show={showModalConfirmarExclusao}
            size='xl'
            onHide={() => setShowModalConfirmarExclusao(false)}
        >
            <Modal.Header closeButton>
                <Modal.Title className='text-center'>
                    Confirmar a Exclusão desse Plano de Conta?
                </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ padding: '0px !important' }}>
                <DataTable
                    stripedRows
                    lazy
                    value={[{
                        codigo: selectedData?.codigo,
                        nome: selectedData?.nome,
                        tipo_name: selectedData?.tipo_name,
                        status_label: selectedData?.status_label
                    }]}
                    tableStyle={{ minWidth: '50rem' }}
                    emptyMessage={
                        <span style={{ display: 'flex', justifyContent: 'center', fontStyle: 'italic' }}>
                            Nenhum item encontrado.
                        </span>
                    }
                >
                    <Column
                        key={'codigo'}
                        field={'codigo'}
                        header={'Código'}
                        style={{ textAlign: 'center', borderBottom: '1px solid #bdbdbd96' }}
                    />
                    <Column
                        key={'nome'}
                        field={'nome'}
                        header={'Nome'}
                        style={{ textAlign: 'center', borderBottom: '1px solid #bdbdbd96' }}
                    />
                    <Column
                        key={'tipo_name'}
                        field={'tipo_name'}
                        header={'Tipo'}
                        style={{ textAlign: 'center', borderBottom: '1px solid #bdbdbd96' }}
                    />
                    <Column
                        key={'status_label'}
                        field={'status_label'}
                        header={'Status'}
                        style={{ textAlign: 'center', borderBottom: '1px solid #bdbdbd96' }}
                    />
                </DataTable>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="success" onClick={handleDelete}>
                    Confirmar
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default ModalConfirmarExclusão;
