import { Col, Form, InputGroup, Row } from "react-bootstrap";
import { useEffect, useState } from "react";
import InputValidationMiddleware from "./../../../../validators/InputValidationMiddleware";

interface FormData {
    [key: string]: any;
}

function InformacoesForm(props: any) {
    const [formData, setFormData] = useState<FormData>(props.infoData || {});

    const handleChange = (field: string, value: any) => {
        if (field === 'numero_registro_proprio' || field === 'folha_registro_proprio') {
            if (value.length <= 20) { 
                setFormData(prev => ({
                    ...prev,
                    [field]: value
                }));
                props.onInputChange(field, value);
            }
        } else {
            setFormData(prev => ({
                ...prev,
                [field]: value
            }));
            props.onInputChange(field, value);
        }
    };
    
    useEffect(() => {
        if (props.infoData) {
            setFormData(props.infoData);
        }
    }, [props.infoData]);

    return (
        <InputValidationMiddleware>
            <Form.Group className="pessoa-form">
                <Col>
                    <Row>
                        <Col md="6">
                            <Form.Label>Número Registro Próprio</Form.Label>
                            <InputGroup hasValidation>
                                <Form.Control
                                    value={formData.numero_registro_proprio}
                                    onChange={(e) => handleChange("numero_registro_proprio", e.target.value)}
                                />
                            </InputGroup>
                        </Col>
                        <Col md="6">
                            <Form.Label>Folha Registro Próprio</Form.Label>
                            <InputGroup hasValidation>
                                <Form.Control
                                    value={formData.folha_registro_proprio}
                                    onChange={(e) => handleChange("folha_registro_proprio", e.target.value)}
                                />
                            </InputGroup>
                        </Col>
                    </Row>
                </Col>
            </Form.Group>
        </InputValidationMiddleware>
    );
};

export default InformacoesForm;
