import { useAppContext } from '../../../contexts/AppContext';
import ContentSearch from "../../Layout/navbar-layout/components/ContentSearch";
import { useEffect, useState } from 'react';
import { AlertService } from '../../../components/AlertService';
import TokenService from '../../../services/Auth/token.service';
import TableComponents from './Componentes/TableComponents';

function Usuario() {
  const { showSubSidebar, setItemsBreadsCrumbs } = useAppContext();
  const [listFiltros, setListFiltros] = useState<any>({});
  const [filtros, setFiltros] = useState<string>();
  const [formBasico, setFormBasico] = useState<any>({});

  useEffect(() => {
    setItemsBreadsCrumbs([
      {
        label: 'Configurações',
        url: '/configuracoes',
      },
      {
        label: 'Lista de Grupo de Permissões',
        url: '/grupo',
      },
    ]);
  }, []);

  async function montarFiltro(): Promise<void> {
    try {
      const consulta: any = {
        consulta: {
          order: "id;desc",
          filtro: {
            "nome": listFiltros ? listFiltros.nome : null,
            "data_inicial": listFiltros ? listFiltros.dataInicio : null,
            "data_final": listFiltros ? listFiltros.dataFim : null,
            "empreendimento_id": TokenService.getEmpreendimentoId() ? TokenService.getEmpreendimentoId().id : null
          }
        }
      };

      const queryParams: any = {};

      const camposEnviar = ['consulta'];

      camposEnviar.forEach((key: any) => {
        const value = consulta[key];
        if (value !== undefined && value !== null && value !== '') {
          if (key === 'consulta') {
            // Converte o objeto consulta em uma string JSON e codifica para URI
            queryParams[key] = JSON.stringify(value);
          } else {
            queryParams[key] = value;
          }
        }
      });

      const queryString = Object.entries(queryParams)
        .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(String(value))}`)
        .join('&');
      setFiltros(queryString);
    } catch (error: any) {
      AlertService.error('Erro!', String(error.response?.data?.message) || 'Erro desconhecido');
    }
  }

  useEffect(() => {
    montarFiltro();
  }, [listFiltros]);
  const handleChange = (field: string, value: string) => {
    setFormBasico((prevGrupoData: any) => {
      return {
        ...prevGrupoData,
        [field]: value
      };
    });
  };
  const handleChangeList = (field: string, value: string) => {
    setListFiltros((prevGrupoData: any) => {
      return {
        ...prevGrupoData,
        [field]: value
      };
    });
  };
  const handleClearList = () => {
    setListFiltros(null);
    setFormBasico(null);
    montarFiltro();
  };

  return (
    <div className="body-content">
      <div
        className={`${showSubSidebar ? "container-section-header" : "container-section-header-none"}`}
      >
        <ContentSearch
          onInputChange={(field: string, value: string) => handleChange(`${field}`, value)}
          onInputChangeList={(field: string, value: string) => handleChangeList(`${field}`, value)}
          onInputClearList={() => handleClearList()}
          filtrosData={formBasico}
          header="Grupo de Permissões"
        />
      </div>
      <div
        className={`${showSubSidebar ? "container-section-body" : "container-section-body-none"}`}
      >
        <TableComponents
          key={filtros}
          include="grupo_permissoes"
          urlRedirectCreated="/grupo/store"
          urlSearch={`/api/v1/admin/core/grupo?${filtros}`}
          urlSearchDataPut="/api/v1/admin/core/grupo/"
          url_redirect_edit="/grupo/update"
        />
      </div>
    </div>
  );
}

export default Usuario;
