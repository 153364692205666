import React, { useEffect, useState } from 'react';
import { Modal, Container, Form, Row, Col } from 'react-bootstrap';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import TokenService from '../../../../services/Auth/token.service';
import { InputNumber } from 'primereact/inputnumber';
import { addLocale } from 'primereact/api';
import { url } from '../../../../environments/environments-develop';
import { AlertService } from '../../../../components/AlertService';
import api from '../../../../services/api';
import { Calendar } from 'primereact/calendar';

interface ModalImoveisGeradosProps {
    nodes: any[];
    showModal: boolean;
    onHide: () => void;
    onHideForm: () => void;
    setNodes: (nodes: any[]) => void;
}

const ModalImoveisGerados: React.FC<ModalImoveisGeradosProps> = ({
    nodes,
    showModal,
    onHide,
    onHideForm,
    setNodes,
}) => {
    const [tipoImovel, setTipoImovel] = useState<any>([]);
    const [subTipoImovel, setSubTipoImovel] = useState<any>([]);
    const [classImovel, setClassImovel] = useState<any>([]);
    const [ult_pgto_iptu, setUltPgtoIptu] = useState<Date | null>(null);
    const [editedData, setEditedData] = useState<any>(null);
    const [editModalVisible, setEditModalVisible] = useState<boolean>(false);
    const [editingIndex, setEditingIndex] = useState<number | null>(null);

    const handleEditClick = (rowData: any, rowIndex: number) => {
        setEditedData({ ...rowData });
        setEditingIndex(rowIndex);
        setEditModalVisible(true);
    };

    const handleSave = () => {
        if (editedData && editingIndex !== null) {
            const updatedNodes = [...nodes];

            updatedNodes[editingIndex] = editedData;

            setNodes(updatedNodes);

            setEditModalVisible(false);
        }
    };

    const salvarImoveisGerados = async (): Promise<void> => {
        try {
            let data = {
                "imoveis": nodes ?? [],
                "empreendimento_id": TokenService.getEmpreendimentoId()?.id ?? null,
            }
            const response = await api.post(`${url}/api/v1/admin/vendas/imovel-grupos`, data);
            if (response.status === 200) {
                AlertService.sucess('Sucesso!', 'Salvo com sucesso!').then(() => {
                    onHide();
                    onHideForm();
                    window.location.reload();
                });
            }
        } catch (error: any) {
            AlertService.error('Erro!', String(error?.response?.data?.error?.message));
        }
    };
    const actionTemplate = (rowData: any, rowIndex: number) => {
        const handleRemove = () => {
            const updatedNodes = nodes.filter((_: any, idx: number) => idx !== rowIndex);
            setNodes(updatedNodes);
        };
        return (
            <Col>
                <Button
                    style={{ width: '30px', height: '30px', borderRadius: '20px', padding: '5px 10px', marginRight: '5px', fontSize: '14px' }}
                    type="button"
                    icon="pi pi-pencil"
                    severity="warning"
                    title='Editar'
                    onClick={() => handleEditClick(rowData, rowIndex)}
                    rounded
                />
                <Button
                    style={{ width: '30px', height: '30px', borderRadius: '20px', padding: '5px 10px', fontSize: '14px' }}
                    type="button"
                    icon="pi pi-trash"
                    severity="danger"
                    title='Remover'
                    onClick={handleRemove}
                    rounded
                />
            </Col>
        );
    };
    useEffect(() => {
        const fetchData = async () => {
            try {
                const consulta: any = {
                    consulta: {
                        order: "id;desc",
                        filtro: {
                            "empreendimento_id": TokenService.getEmpreendimentoId() ? TokenService.getEmpreendimentoId().id : null
                        }
                    }
                };

                const queryParams: any = {};

                const camposEnviar = ['consulta'];

                camposEnviar.forEach((key: any) => {
                    const value = consulta[key];
                    if (value !== undefined && value !== null && value !== '') {
                        if (key === 'consulta') {
                            queryParams[key] = JSON.stringify(value);
                        } else {
                            queryParams[key] = value;
                        }
                    }
                });

                const queryString = Object.entries(queryParams)
                    .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(String(value))}`)
                    .join('&');

                const [responseTiposImovel, responseSubtiposImovel, responseClassImovel] = await Promise.all([
                    api.get(`${url}/api/v1/admin/vendas/tipoimovel?${queryString}`),
                    api.get(`${url}/api/v1/admin/vendas/subtipoimovel?${queryString}`),
                    api.get(`${url}/api/v1/admin/vendas/classificacaoimoveis/index-rotulos?${queryString}`)
                ]);
                if (responseTiposImovel.status === 200) {
                    setTipoImovel(responseTiposImovel.data.data);
                }
                if (responseSubtiposImovel.status === 200) {
                    setSubTipoImovel(responseSubtiposImovel.data.data);
                }
                if (responseClassImovel.status === 200) {
                    setClassImovel(responseClassImovel.data.data);
                }
            } catch (error) {
                AlertService.error('Erro!', String('Erro desconhecido'));
            }
        };
        fetchData();
    }, []);
    addLocale('pt', {
        firstDayOfWeek: 0,
        dayNames: ['domingo', 'segunda-feira', 'terça-feira', 'quarta-feira', 'quinta-feira', 'sexta-feira', 'sábado'],
        dayNamesShort: ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sáb'],
        dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
        monthNames: ['janeiro', 'fevereiro', 'março', 'abril', 'maio', 'junho', 'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro'],
        monthNamesShort: ['jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez'],
        today: 'Hoje',
        clear: 'Limpar'
    });
    return (
        <>
            <Modal
                show={showModal}
                onHide={() => onHide()}
                size='xl'
                className="modal-input-venda"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Imoveis Gerados</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Form.Group className="imovel-form" style={{ overflowX: "hidden" }}>
                            <DataTable
                                value={nodes ? nodes : []}
                                paginator
                                rows={8}
                                selectionMode="single"
                                dataKey="index"
                                tableStyle={{ minWidth: '50rem' }}
                                emptyMessage={<span style={{ display: 'flex', justifyContent: 'center', fontStyle: 'italic' }}>Nenhum item encontrado.</span>}
                            >
                                <Column field="identificador1" header={TokenService.getEmpreendimentoId() ? TokenService.getEmpreendimentoId().alias_identificador1 : 'Identificador 1'}></Column>
                                <Column field="identificador2" header={TokenService.getEmpreendimentoId() ? TokenService.getEmpreendimentoId().alias_identificador2 : 'Identificador 2'}></Column>
                                <Column field="area" header="Área"></Column>
                                <Column field="imo_intermed" header="Intermed"></Column>
                                <Column field="imo_preco" header="Vlr.Imovel"></Column>
                                <Column field="observacao" header="Observação"></Column>
                                <Column body={(rowData, options) => actionTemplate(rowData, options.rowIndex)} header="Menu" />
                            </DataTable>
                        </Form.Group>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        style={{ borderRadius: '20px' }}
                        severity="danger"
                        label="Cancelar Alteração"
                        onClick={() => onHide()}
                    />
                    <Button
                        style={{ borderRadius: '20px' }}
                        severity="success"
                        label="Salvar Alteração"
                        onClick={salvarImoveisGerados}
                    />
                </Modal.Footer>
            </Modal>

            {editModalVisible && (
                <Modal
                    show={editModalVisible}
                    onHide={() => setEditModalVisible(false)}
                    size='xl'
                    className="modal-input-venda"
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Edit Imovel</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Container>
                            <Form.Group className="imovel-form" style={{ overflowX: "hidden" }}>
                                <Form.Group className="imovel-form">
                                    <Row className="mb-3">
                                        <Col>
                                            <Form.Label>
                                                {TokenService.getEmpreendimentoId() ? TokenService.getEmpreendimentoId().alias_identificador1 : 'Identificador 1'}
                                            </Form.Label>
                                            <Form.Control value={editedData?.identificador1}
                                                onChange={(e) => setEditedData({ ...editedData, identificador1: e.target.value })}
                                            />
                                        </Col>
                                        <Col controlId="formIdentificador2">
                                            <Form.Label>
                                                {TokenService.getEmpreendimentoId() ? TokenService.getEmpreendimentoId().alias_identificador2 : 'Identificador 2'}
                                            </Form.Label>
                                            <Form.Control
                                                type="text"
                                                value={editedData?.identificador2 || ''}
                                                onChange={(e) => setEditedData({ ...editedData, identificador2: e.target.value })}
                                            />
                                        </Col>
                                        <Col>
                                            <Form.Label>Logradouro</Form.Label>
                                            <Form.Control
                                                value={editedData?.logradouro}
                                                onChange={(e) => setEditedData({ ...editedData, logradouro: e.target.value })}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Form.Label>Area</Form.Label>
                                            <Form.Control
                                                value={editedData?.area}
                                                onChange={(e) => setEditedData({ ...editedData, area: e.target.value })}
                                            />
                                        </Col>
                                        <Col>
                                            <Form.Label>INCDEC</Form.Label>
                                            <Form.Control
                                                value={editedData?.incdec}
                                                onChange={(e) => setEditedData({ ...editedData, incdec: e.target.value })}
                                            />
                                        </Col>
                                        <Col>
                                            <Form.Label>Status</Form.Label>
                                            <Form.Select
                                                value={editedData?.status || ''}
                                                onChange={(e) => setEditedData({ ...editedData, status: e.target.value })}
                                            >
                                                <option>Selecione...</option>
                                                <option value={"1"}>Disponível</option>
                                                <option value={"2"}>Reservado</option>
                                                <option value={"3"}>Reserva Fixa</option>
                                                <option value={"4"}>Negociado</option>
                                                <option value={"5"}>Vendido</option>
                                                <option value={"6"}>Reserva Técnica</option>
                                                <option value={"7"}>Reserva Diretoria</option>
                                            </Form.Select>
                                        </Col>
                                    </Row>
                                </Form.Group>
                                <Form.Group className="imovel-form">
                                    <Row>
                                        <h3 className="label-form-create-imovel">Detalhes </h3>
                                    </Row>
                                    <Row className="mb-3">
                                        <Col>
                                            <Form.Label>Frente</Form.Label>
                                            <Form.Control
                                                value={editedData?.frente}
                                                onChange={(e) => setEditedData({ ...editedData, frente: e.target.value })}
                                            />
                                        </Col>
                                        <Col>
                                            <Form.Label>Fundo</Form.Label>
                                            <Form.Control
                                                value={editedData?.fundo}
                                                onChange={(e) => setEditedData({ ...editedData, fundo: e.target.value })}
                                            />
                                        </Col>
                                        <Col>
                                            <Form.Label>Confrontante do Fundo </Form.Label>
                                            <Form.Control
                                                value={editedData?.confr_fundo}
                                                onChange={(e) => setEditedData({ ...editedData, confr_fundo: e.target.value })}
                                            />
                                        </Col>
                                        <Col>
                                            <Form.Label>Lado direto</Form.Label>
                                            <Form.Control
                                                value={editedData?.lado_direito}
                                                onChange={(e) => setEditedData({ ...editedData, lado_direito: e.target.value })}
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <Col>
                                            <Form.Label>Confrontante do Lado Direito</Form.Label>
                                            <Form.Control
                                                value={editedData?.confr_lado_direito}
                                                onChange={(e) => setEditedData({ ...editedData, confr_lado_direito: e.target.value })}
                                            />
                                        </Col>
                                        <Col>
                                            <Form.Label>Lado Esquerdo</Form.Label>
                                            <Form.Control
                                                value={editedData?.lado_esquerdo}
                                                onChange={(e) => setEditedData({ ...editedData, lado_esquerdo: e.target.value })}
                                            />
                                        </Col>
                                        <Col>
                                            <Form.Label>Confrontante do Lado Esquerdo </Form.Label>
                                            <Form.Control
                                                value={editedData?.confr_lado_esquerdo}
                                                onChange={(e) => setEditedData({ ...editedData, confr_lado_esquerdo: e.target.value })}
                                            />
                                        </Col>
                                        <Col>
                                            <Form.Label>Chanfro</Form.Label>
                                            <Form.Control
                                                value={editedData?.chanfro}
                                                onChange={(e) => setEditedData({ ...editedData, chanfro: e.target.value })}
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <Col>
                                            <Form.Label>Preço do Imóvel</Form.Label>
                                            <InputNumber
                                                style={{ width: '100%', height: '38px' }}
                                                value={editedData?.imo_preco}
                                                onChange={(e) => setEditedData({ ...editedData, imo_preco: e.value })}
                                                mode="currency" currency="BRL" locale="pt-BR"
                                            />
                                        </Col>
                                        <Col>
                                            <Form.Label>Valor da Intermediação do Imóvel</Form.Label>
                                            <InputNumber
                                                style={{ width: '100%', height: '38px' }}
                                                value={editedData?.imo_intermed}
                                                onChange={(e) => setEditedData({ ...editedData, imo_intermed: e.value })}
                                                mode="currency" currency="BRL" locale="pt-BR"
                                            />
                                        </Col>
                                        <Col>
                                            <Form.Label>Matrícula do Imóvel </Form.Label>
                                            <Form.Control
                                                value={editedData?.imo_matricula}
                                                onChange={(e) => setEditedData({ ...editedData, imo_matricula: e.target.value })}
                                            />
                                        </Col>
                                        <Col >
                                            <Form.Label>Data do Ult. Pgto do Iptu</Form.Label>
                                            <Calendar
                                                value={ult_pgto_iptu || null}
                                                onChange={(event: any) => {
                                                    const selectedDates = event.value;
                                                    setUltPgtoIptu(selectedDates);
                                                    setEditedData({ ...editedData, ult_pgto_iptu: selectedDates ? selectedDates.toISOString() : null })
                                                }}
                                                placeholder="Data do Ult. Pgto do Iptu"
                                                showButtonBar
                                                selectionMode="single"
                                                dateFormat="dd/mm/yy"
                                                locale="pt"
                                                readOnlyInput
                                                style={{ textAlign: 'center', width: '100%' }}
                                                panelClassName="custom-calendar-panel"
                                                required
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <Col>
                                            <Form.Label>Tipo Imóvel</Form.Label>
                                            <Form.Select value={editedData?.tipo_imovel_id}
                                                onChange={(e) => setEditedData({ ...editedData, tipo_imovel_id: e.target.value })}>
                                                <option>Selecione...</option>
                                                {tipoImovel.map((tipoimovel: any) => (
                                                    <option value={tipoimovel.id}>{tipoimovel.nome}</option>
                                                ))}
                                            </Form.Select>
                                        </Col>
                                        <Col>
                                            <Form.Label>SubTipo Imovel</Form.Label>
                                            <Form.Select value={editedData?.sub_tipo_imovel_id}
                                                onChange={(e) => setEditedData({ ...editedData, sub_tipo_imovel_id: e.target.value })}>
                                                <option>Selecione...</option>
                                                {subTipoImovel.map((subtipo: any) => (
                                                    <option value={subtipo.id}>{subtipo.nome}</option>
                                                ))}
                                            </Form.Select>
                                        </Col>
                                        <Col>
                                            <Form.Label>Classificação do Imóvel</Form.Label>
                                            <Form.Select value={editedData?.classif_preco_id}
                                                onChange={(e) => setEditedData({ ...editedData, classif_preco_id: e.target.value })}>
                                                <option>Selecione...</option>
                                                {classImovel.map((classificacao: any) => (
                                                    <option value={classificacao.id}>{classificacao.rotulo}</option>
                                                ))}
                                            </Form.Select>
                                        </Col>
                                        <Col>
                                            <Form.Label>Observação</Form.Label>
                                            <Form.Control
                                                value={editedData?.observacao}
                                                onChange={(e) => setEditedData({ ...editedData, observacao: e.target.value })}
                                            />
                                        </Col>
                                    </Row>
                                </Form.Group >
                            </Form.Group>
                        </Container>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            style={{ borderRadius: '20px' }}
                            severity="danger"
                            label="Cancelar Alteração"
                            onClick={() => setEditModalVisible(false)}
                        />
                        <Button
                            style={{ borderRadius: '20px' }}
                            severity="success"
                            label="Salvar Alteração"
                            onClick={handleSave}
                        />
                    </Modal.Footer>
                </Modal>
            )}
        </>
    );
};

export default ModalImoveisGerados;
