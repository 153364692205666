import React, { useState, useEffect } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import SecaoIntermediacao from '../../../Vendas/NovaVenda/Formularios/SecaoIntermediacao';

function ImovelImport(props: any) {
    const [formData, setFormData] = useState<any>({});

    useEffect(() => {
        setFormData(props.formData);
    }, [props.formData]);

    const handleFormChange = (field: string, value: any) => {
        props.onInputChange(field, value);
    }

    return (
        <>
            <Row className="group-form">
                <Col>
                    <Form.Label>Em qual coluna está a id do lote?</Form.Label>
                    <Form.Control
                        value={formData.id_externo || ''}
                        onChange={(e) => handleFormChange('id_externo', e.target.value)}
                    />
                </Col>
                <Col>
                    <Form.Label>Em qual coluna está o número da Quadra?</Form.Label>
                    <Form.Control
                        value={formData.identificador1 || ''}
                        onChange={(e) => handleFormChange('identificador1', e.target.value)}
                    />
                </Col>
                <Col>
                    <Form.Label>Em qual coluna está o número do Lote?</Form.Label>
                    <Form.Control
                        value={formData.identificador2 || ''}
                        onChange={(e) => handleFormChange('identificador2', e.target.value)}
                    />
                </Col>
                <Col>
                    <Form.Label>Em quais Colunas está a classificação do lote?</Form.Label>
                    <Form.Control
                        value={formData.classif_preco || ''}
                        onChange={(e) => handleFormChange('classif_preco', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em quais Colunas está o status do lote?</Form.Label>
                    <Form.Control
                        value={formData.status || ''}
                        onChange={(e) => handleFormChange('status', e.target.value)} />
                </Col>
            </Row>
            <Row className="group-form">
                <Col>
                    <Form.Label>Em qual Coluna está o id do corretor?</Form.Label>
                    <Form.Control
                        value={formData.corretor_id || ''}
                        onChange={(e) => handleFormChange('corretor_id', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em quais Colunas está a área do lote?</Form.Label>
                    <Form.Control
                        value={formData.area || ''}
                        onChange={(e) => handleFormChange('area', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está o logradouro</Form.Label>
                    <Form.Control
                        value={formData.logradouro || ''}
                        onChange={(e) => handleFormChange('logradouro', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está à metragem da frente do lote?</Form.Label>
                    <Form.Control
                        value={formData.frente || ''}
                        onChange={(e) => handleFormChange('frente', e.target.value)} />
                </Col>
            </Row>
            <Row className="group-form">
                <Col>
                    <Form.Label>Em quais Colunas está o INCDEC?</Form.Label>
                    <Form.Control
                        value={formData.incdec || ''}
                        onChange={(e) => handleFormChange('incdec', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está à metragem do fundo do lote?</Form.Label>
                    <Form.Control
                        value={formData.fundo || ''}
                        onChange={(e) => handleFormChange('fundo', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está o confrantante do fundo?</Form.Label>
                    <Form.Control
                        value={formData.confr_fundo || ''}
                        onChange={(e) => handleFormChange('confr_fundo', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está metragem do lado esquerdo</Form.Label>
                    <Form.Control
                        value={formData.lado_esquerdo || ''}
                        onChange={(e) => handleFormChange('lado_esquerdo', e.target.value)} />
                </Col>
            </Row>
            <Row className="group-form">
                <Col>
                    <Form.Label>Em qual Coluna está o confrantante do lado esquerdo?</Form.Label>
                    <Form.Control
                        value={formData.confr_lado_esquerdo || ''}
                        onChange={(e) => handleFormChange('confr_lado_esquerdo', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está a metragem do lado direito?</Form.Label>
                    <Form.Control
                        value={formData.lado_direito || ''}
                        onChange={(e) => handleFormChange('lado_direito', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está o confrantante do lado direito?</Form.Label>
                    <Form.Control
                        value={formData.confr_lado_direito || ''}
                        onChange={(e) => handleFormChange('confr_lado_direito', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está o id do empreendimento?</Form.Label>
                    <Form.Control
                        value={formData.empreendimento_id || ''}
                        onChange={(e) => handleFormChange('empreendimento_id', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está a data da venda?</Form.Label>
                    <Form.Control
                        value={formData.data_venda || ''}
                        onChange={(e) => handleFormChange('data_venda', e.target.value)} />
                </Col>
            </Row>
            <Row className="group-form">
                <Col>
                    <Form.Label>Em qual Coluna está a metragem do chanfro?</Form.Label>
                    <Form.Control
                        value={formData.chanfro || ''}
                        onChange={(e) => handleFormChange('chanfro', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está a intermediação?</Form.Label>
                    <Form.Control
                        value={formData.imo_intermed || ''}
                        onChange={(e) => handleFormChange('imo_intermed', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está o Valor do Imóvel?</Form.Label>
                    <Form.Control
                        value={formData.imo_preco || ''}
                        onChange={(e) => handleFormChange('imo_preco', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está o Intermediação reduzida</Form.Label>
                    <Form.Control
                        value={formData.intermed_reduzida || ''}
                        onChange={(e) => handleFormChange('intermed_reduzida', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está a Data da reserva</Form.Label>
                    <Form.Control
                        value={formData.data_reserva || ''}
                        onChange={(e) => handleFormChange('data_reserva', e.target.value)} />
                </Col>
            </Row>
        </>
    );
}

export default ImovelImport;
