import { Col, Form, Row } from "react-bootstrap";
import { AlertService } from "../../../../components/AlertService";
import { useEffect, useState } from "react";
import { url } from "../../../../environments/environments-develop";
import api from "../../../../services/api";
import TokenService from "../../../../services/Auth/token.service";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import CalendarIcon from '../../../../../assets/images/icon-calendar.png';
import pt from "date-fns/locale/pt";
import "react-datepicker/dist/react-datepicker.css";
import { InputNumber, InputNumberValueChangeEvent } from "primereact/inputnumber";
import { showToast } from "../../../../components/ToastComponent";
import { Button } from "primereact/button";
import { AutoComplete } from "primereact/autocomplete";
import { searchCorretor } from "../Services/VendaService";
interface PropsData {
    secaoImovelData: {
        identificador1?: string;
        identificador2?: string;
        numero?: string;
        anotacoes?: string;
        valor_desconto?: string;
        justificativa_desc?: string;
        corretor_id?: string;
        data_venda?: any;
        imovel_id?: any;
        nome_corretor?: any;
        busca?: any;
    };
    onInputChange: (field: string, value: any) => void;
}
function SecaoImovel(props: PropsData,) {
    const [formData, setFormData] = useState<any>(props.secaoImovelData || {});
    const [data_venda, setDataVenda] = useState<any | null>(null);
    const [listCorretores, setListCorretores] = useState([]);
    const [imovelDisponivel, setImovelDIsponivel] = useState<any>(formData?.identificador1 && formData?.identificador2 ? true : false);
    const [FilteredPessoa, setFilteredPessoa] = useState<any>(null);

    const handleChange = (field: string, value: any) => {
        if (field == 'corretor_id') {
            props.onInputChange('nome_corretor', value?.nome ? value.nome : '');
            props.onInputChange(field, value?.id ? value.id : '');
        } else {
            props.onInputChange(field, value);
        }
    };
    useEffect(() => {
        setFormData((prevGrupoData: any) => {
            return {
                ...prevGrupoData,
                ...props.secaoImovelData
            };
        });
    }, [props.secaoImovelData]);
    async function InitCorretores() {
        try {
            const response = await api.get(`${url}/api/v1/admin/vendas/corretores?include=pessoa`);
            setListCorretores(response.data.data);
        } catch (error) {
            AlertService.error('Error', 'Ocorreu um erro ao buscar a lista de permissões. Erro:' + String(error));
        }
    }
    useEffect(() => {
        if (formData?.imovel_id) {
            setImovelDIsponivel(true);
        }
    }, [formData?.imovel_id]);
    useEffect(() => {
        InitCorretores();
    }, []);

    useEffect(() => {
        setDataVenda(formData?.data_venda ? new Date(formData?.data_venda) : null);
    }, [formData])
    async function actionButton(): Promise<void> {
        try {
            var response = await api.get(`${url}/api/v1/admin/vendas/contrato-verificar-disponibilidade-imovel?identificador1=${props.secaoImovelData.identificador1}&identificador2=${props.secaoImovelData.identificador2}&empreendimento_id=${TokenService.getEmpreendimentoId()?.id}`);
            if (response.status === 200) {
                if (response.data.data.status === 1) {
                    Object.keys(response.data.data).forEach(key => {
                        if (key === 'id') {
                            handleChange('imovel_id', response.data.data[key])
                        } else if (key === 'qtd_max_parcelas') {
                            handleChange('qtd_parcelas', response.data.data[key])
                        } else {
                            handleChange(key, response.data.data[key])
                        }
                    })
                    setImovelDIsponivel(true);
                    showToast('success', 'Este Imovel está disponível para venda!');
                } else {
                    setImovelDIsponivel(false);
                    showToast('error', `Este Imovel se encontra ${response.data.data.status_enum.data.descricao}, por isso não é possivel prosseguir com a venda!`);
                }
            }
        } catch (error: any) {
            setImovelDIsponivel(false);
            let mensagens: string[] = [];
            mensagens.push(error?.response?.data?.error?.message);
            showToast('error', String(mensagens));
        }
    }

    const search = (event: any) => {
        setTimeout(() => {
            let _FilteredPessoa: any;

            if (!event.query.trim().length) {
                _FilteredPessoa = [...listCorretores];
            }
            else {
                if (event.query) {
                    _FilteredPessoa = listCorretores.filter((item: any) => {
                        return item.nome_corretor && item.nome_corretor.toLowerCase().startsWith(event.query.toLowerCase());
                        // Verifica se item.nome não é nulo ou indefinido antes de acessar toLowerCase()
                    });
                }
            }
            setFilteredPessoa(_FilteredPessoa);
        }, 250);
    }
    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            flex: '1',
            height: '-webkit-fill-available',
            marginRight: '25px'
        }}>
            <Row style={{ background: '#282c34', padding: '12px', borderRadius: '20px', display: 'flex', justifyContent: 'center', textAlign: 'center', boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.32)', marginBottom: '25px' }}>
                <Form.Label style={{ color: '#fff', fontSize: '18px' }}>Verificar disponibilidade do imóvel</Form.Label>
                <Col>
                    <Form.Label style={{ color: '#fff' }}>{TokenService.getEmpreendimentoId() !== null ? TokenService.getEmpreendimentoId()?.alias_identificador1 : 'Identificador 1'} </Form.Label>
                    <Form.Control value={formData?.identificador1 ?? ''} onChange={(e) => handleChange('identificador1', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label style={{ color: '#fff' }}>{TokenService.getEmpreendimentoId() !== null ? TokenService.getEmpreendimentoId()?.alias_identificador2 : 'Identificador 2'}</Form.Label>
                    <Form.Control value={formData?.identificador2 ?? ''} onChange={(e) => handleChange('identificador2', e.target.value)} />
                </Col>
                <Form.Label></Form.Label>
                <Button style={{
                    display: 'flex',
                    height: '40px',
                    marginTop: '5px',
                    background: '#01c209',
                    borderRadius: '42px',
                    border: '1px solid #01c209',
                    justifyContent: 'center',
                    alignContent: 'center',
                    alignItems: 'center'
                }} onClick={() => actionButton()} icon="pi pi-key">
                    <p style={{ display: 'flex', justifyContent: 'center', marginTop: '13px', marginLeft: '10px' }}>Verificar</p>
                </Button>
            </Row>
            {imovelDisponivel && <Row className="mb-3">
                <Col md="3" style={{ display: 'grid' }}>
                    <Form.Label>Data da Venda</Form.Label>
                    <DatePicker
                        selected={data_venda ? new Date(Date.parse(data_venda)) : null}
                        onChange={(date: Date) => {
                            setDataVenda(date);
                            handleChange('data_venda', date);
                        }}
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        fixedHeight
                        locale={pt}
                        customInput={
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <input
                                    type="text"
                                    placeholder="Data da Venda"
                                    className="dateFormFinanceiro"
                                    value={data_venda ? data_venda.toLocaleDateString() : ''}
                                    readOnly
                                />
                                <img
                                    className="calendar-icon"
                                    src={CalendarIcon}
                                />
                            </div>
                        }
                    />

                </Col>
                {TokenService.getEmpreendimentoId()?.config_venda?.num_contrato_manual === true && (
                    <Col>
                        <Form.Label>N° Contrato</Form.Label>
                        <Form.Control value={formData?.numero ?? ''} onChange={(e) => handleChange('numero', e.target.value)} />
                    </Col>
                )}
                <Col>
                    <Form.Label>Anotações</Form.Label>
                    <Form.Control value={formData?.anotacoes ?? ''} onChange={(e) => handleChange('anotacoes', e.target.value)} />
                </Col>
            </Row>}
            {imovelDisponivel && <Row>
                <Col md="3" style={{ display: 'flex', flexDirection: 'column' }}>
                    <Form.Label>Corretor</Form.Label>
                    <AutoComplete
                        placeholder='Pesquisar nome'
                        field="nome_corretor"
                        style={{ height: "37px" }}
                        value={formData?.nome_corretor ? formData?.nome_corretor : (formData?.busca ? formData?.busca : '')}
                        suggestions={FilteredPessoa}
                        completeMethod={search}
                        onChange={(e) =>
                            handleChange('busca', e.value)}
                        onSelect={(e) =>
                            handleChange('corretor_id', e.value)}
                        dropdown
                    />
                </Col>
                <Col>
                    <Form.Label>Valor de Desconto do Imóvel</Form.Label>
                    <InputNumber style={{ width: '100%', height: '38px' }} value={formData?.valor_desconto ? Number(formData?.valor_desconto) : 0} onChange={(e: any) => handleChange('valor_desconto', e.value)}
                        mode="currency" currency="BRL" locale="pt-BR" />
                </Col>
                <Col>
                    <Form.Label>Justificativa de Desconto</Form.Label>
                    <Form.Control value={formData?.justificativa_desc ?? ''} onChange={(e) => handleChange('justificativa_desc', e.target.value)} />
                </Col>
            </Row>}
        </div>
    );
}

export default SecaoImovel;