import React, { ReactNode, createContext, useContext, useState } from 'react';
import TokenService from '../services/Auth/token.service';

interface EnderecoData {
    apelido: string;
    logradouro: string,
    tipo_endereco: string,
    cep: string,
    numero: string,
    cidade_nome: string,
    cidade_id: string,
    complemento: string
}

interface TelefoneData {
    nome_contato: string,
    ddd: string,
    numero: string,
    tipo_telefone: number,
    observacao: string,
}

interface PerfilData {
    nome: string,
    email: string,
    username: string,
    cpfCnpj: string,
    endereco: string,
    estado: string,
    cidade: string,
    cep: string,
    status: string,
    pessoa: {
        id: string,
        nome: string,
        email: string,
        cpf_cnpj: string,
        sexo: string,
        razao_social: string,
        inscricao_municipal: string,
        inscricao_estadual: string,
        filiacao_mae: string,
        filiacao_pai: string,
        rg: string,
        estado_civil: string,
        regime_uniao: string,
        data_nascimento: string,
        enderecos: EnderecoData[],
        telefones: TelefoneData[],
    }
}
interface PerfilContextProps {
    perfilData: PerfilData;
    updatePerfilData: (newData: any) => void;
};

const PerfilContext = createContext<PerfilContextProps | undefined>(undefined);

export const useAppContext = () => {
    const context = useContext(PerfilContext);
    if (!context) {
        throw new Error('useAppContext must be used within an AppProvider');
    }
    return context;
};

interface AppProviderProps {
    children: ReactNode;
}

export const PerfilProvider: React.FC<AppProviderProps> = ({ children }) => {
    const perfil = TokenService.getPerfil();
    const [perfilData, setPerfilData] = useState<PerfilData>({
        nome: perfil ? perfil.data.nome : '',
        email: perfil ? perfil.data.email : '',
        username: perfil ? perfil.data.username : '',
        cpfCnpj: perfil ? perfil.data.cpf : '',
        endereco: perfil ? perfil.data.endereco : '',
        estado: perfil ? perfil.data.estado : '',
        cidade: perfil ? perfil.data.cidade : '',
        cep: perfil ? perfil.data.cep : '',
        status: perfil ? perfil.data.status : '',
        pessoa: {
            id: perfil ? perfil?.data.pessoa_id : '',
            nome: perfil ? perfil?.data?.pessoa?.data?.nome : '',
            email: perfil ? perfil?.data?.pessoa?.data?.email : '',
            cpf_cnpj: perfil ? perfil?.data?.pessoa?.data?.cpf_cnpj : '',
            sexo: perfil ? perfil?.data?.pessoa?.data?.sexo : '',
            razao_social: perfil ? perfil?.data?.pessoa?.data?.razao_social : '',
            inscricao_municipal: perfil ? perfil?.data?.pessoa?.data?.inscricao_municipal : '',
            inscricao_estadual: perfil ? perfil?.data?.pessoa?.data?.inscricao_estadual : '',
            filiacao_mae: perfil ? perfil?.data?.pessoa?.data?.filiacao_mae : '',
            filiacao_pai: perfil ? perfil?.data?.pessoa?.data?.filiacao_pai : '',
            rg: perfil ? perfil?.data?.pessoa?.data?.rg : '',
            estado_civil: perfil ? perfil?.data?.pessoa?.data?.estado_civil : '',
            regime_uniao: perfil ? perfil?.data?.pessoa?.data?.regime_uniao : '',
            data_nascimento: perfil ? perfil?.data?.pessoa?.data?.data_nascimento : '',
            enderecos: perfil ? (perfil?.data?.pessoa?.data?.enderecos?.data || []).map((endereco: any) => ({
                apelido: endereco?.apelido || '',
                logradouro: endereco?.logradouro || '',
                tipo_endereco: endereco?.tipo_endereco || '',
                cep: endereco?.cep || '',
                numero: endereco?.numero || '',
                cidade_nome: endereco?.cidade_nome || '',
                cidade_id: endereco?.cidade_id || '',
                complemento: endereco?.complemento || '',
            })) : [],
            telefones: perfil ? (perfil?.data?.pessoa?.data?.telefones?.data || []).map((telefone: any) => ({
                nome_contato: telefone?.nome_contato || '',
                ddd: telefone?.ddd || '',
                tipo_telefone: telefone?.tipo_telefone || 1,
                numero: telefone?.numero || '',
                observacao: telefone?.observacao || '',
            })) : [],
        },
    });


    const updatePerfilData = (newData: PerfilData) => {
        setPerfilData(newData);
    };

    return (
        <PerfilContext.Provider value={{ perfilData, updatePerfilData }}>
            {children}
        </PerfilContext.Provider>
    );
};

export const usePerfilContext = () => {
    return useContext(PerfilContext);
};
