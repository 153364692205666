import axios from "../../../services/api";
import { Observable, from, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { url } from "../../../environments/environments-develop";
import { BancoModel, IBanco } from "../Models/BancoModel";

interface IApiResponse<T> {
  data: T;
  headers: {
    titles: string[];
  };
  meta: any;
}

class BancoService {
  private resourceUrl = url + "/api/v1/admin/financeiro";

  encontrar_banco(
    params: any = {},
    headers: any = null
  ): Observable<BancoModel[]> {
    return from(
      axios
        .get<IApiResponse<IBanco[]>>(this.resourceUrl + "/select-banco", {
          params,
          headers,
        })
        .then((response) => {
          return response.data.data.map((item) =>
            new BancoModel().deserialize(item)
          );
        })
    ).pipe(catchError((error) => throwError(error)));
  }
}

export default new BancoService();
