import React, { useEffect, useRef, useState } from 'react';
import { Toast } from 'primereact/toast';
import { FileUpload } from 'primereact/fileupload';
import { Button } from 'primereact/button';

interface PropsData {
    Data: {
        anexo?: Array<any>;
    };
    onInputChange: (field: string, value: any) => void;
}

interface Anexo {
    nome: string;
    alias: string;
    extensao: string;
    conteudo: string;
    url?: string;
    objectURL?: string;
}

function AnexoForm(props: any) {
    const [anexo, setAnexos] = useState<Anexo[]>([]);
    const toast = useRef<Toast>(null);
    const fileUploadRef = useRef<FileUpload>(null);
    useEffect(() => {
        const dados = props.Data.anexo;

        if (dados) {
            if (dados && dados.data) {
                const anexosFormatados = dados.data.map((item: any) => ({
                    id: item.id,
                    nome: item.nome,
                    alias: item.alias,
                    extensao: item.extensao,
                    conteudo: item.url,
                    url: item.url,
                    objectURL: item.url_thumb
                }));
                setAnexos(anexosFormatados);
            }
        }
    }, [props.Data.anexo]);

    const handleFileSelect = (e: { files: File[] }) => {
        e.files.forEach(file => {
            const isDuplicate = anexo.some(anexoExistente => anexoExistente.nome === file.name);

            if (!isDuplicate) {
                const reader = new FileReader();
                reader.onload = () => {
                    const conteudo = reader.result as string;
                    const novoAnexo: Anexo = {
                        nome: file.name,
                        alias: file.name,
                        extensao: file.name.split('.').pop() || '',
                        conteudo: conteudo,
                    };
                    setAnexos(anexosAnteriores => [...anexosAnteriores, novoAnexo]);
                };
                reader.readAsDataURL(file);
            }
        });
    };



    useEffect(() => {
        props.onInputChange('anexo', anexo);
    }, [anexo]);

    const emptyTemplate = () => (
        <div className="flex align-items-center flex-column">
            <i className="pi pi-image mt-3 p-5" style={{ fontSize: '5em', borderRadius: '50%', backgroundColor: 'var(--surface-b)', color: 'var(--surface-400)' }}></i>
            <span style={{ fontSize: '1.3em', color: 'var(--surface-600)' }}>
                Arraste e solte a imagem aqui
            </span>
        </div>
    );

    const chooseOptions = {
        icon: 'pi pi-fw pi-images',
        iconOnly: true,
        className: 'custom-choose-btn p-button-rounded p-button-outlined',
        style: { borderRadius: '50%' }
    };

    const cancelOptions = {
        icon: 'pi pi-fw pi-times',
        iconOnly: true,
        className: 'custom-cancel-btn p-button-danger p-button-rounded p-button-outlined',
        style: { borderRadius: '50%' }
    };

    const headerTemplate = (options: { className: any; chooseButton: any; cancelButton: any; }) => {
        const { className, chooseButton, cancelButton } = options;
        return (
            <div className={className} style={{ backgroundColor: 'transparent', display: 'flex', alignItems: 'center' }}>
                {chooseButton}
                {cancelButton}
            </div>
        );
    };

    const onRemove = (file: Anexo) => {
        setAnexos(anexosAnteriores => anexosAnteriores.filter(anexo => anexo.nome !== file.nome));
        if (toast.current) {
            toast.current.show({ severity: 'info', summary: 'Arquivo removido', detail: file.nome });
        }
    };
    const onTemplateRemove = (file: File, callback: Function) => {
        setAnexos(anexosAnteriores => anexosAnteriores.filter(anexo => anexo.nome !== file.name));
        if (toast.current) {
            toast.current.show({ severity: 'info', summary: 'Arquivo removido', detail: file.name });
        }
        callback();
    }
    const itemTemplate = (file: any, props: any) => (
        <div className="flex flex-wrap" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <div className="d-flex justify-content-left" style={{ width: '40%' }}>
                <img style={{ marginRight: '15px' }} alt={file.name} role="presentation" src={file.objectURL} width={100} />
                <span className="flex text-left flex-column  ml-3">
                    <label className="d-flex justify-content-left">{file.alias || file.name}</label>
                    <br />
                    <small className="d-flex justify-content-left">{file.size} Bytes</small>
                    <br />
                    <small className="d-flex justify-content-left">{new Date().toLocaleDateString()}</small>
                </span>
            </div>
            <Button
                type="button"
                icon="pi pi-times"
                style={{ borderRadius: '50%', alignItems: 'center' }}
                className="p-button-outlined p-button-rounded p-button-danger ml-auto"
                onClick={() => onTemplateRemove(file, props.onRemove)}
            />
        </div>
    );
    const redirectFile = (url_redirect: string, nameFile: string) => {
        const url = new URL(url_redirect);

        if (!/^https?:$/.test(url.protocol)) {
            throw new Error('URL com protocolo inválido');
        }
        window.open(url.href, '_blank');
    };

    return (
        <div>
            <div className="flex flex-wrap">
                {anexo.map((file: any) => (
                    file?.objectURL ? (
                        <div key={file.nome} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexDirection: 'column', backgroundColor: '#494949', marginRight: '10px', marginBottom: '10px', padding: '0px' }}>
                            <div style={{ backgroundColor: '#b9b7b7', display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', paddingLeft: '5px', paddingBottom: '2px', paddingTop: '2px' }}>
                                <a
                                    type='submit'
                                    onClick={() => redirectFile(file.conteudo, file.alias)}
                                    style={{ display: 'flex', margin: '8px' }}
                                >
                                    <strong>{file.alias || file.nome}</strong>
                                </a>
                                <Button
                                    icon="pi pi-times"
                                    style={{ backgroundColor: '#4949498a', marginRight: '15px', width: '30px', height: '30px', borderRadius: '7px' }}
                                    onClick={() => onRemove(file)}
                                />
                            </div>
                        </div>
                    ) : null
                ))}
            </div>
            <Toast ref={toast}></Toast>
            <FileUpload ref={fileUploadRef} name="demo[]" multiple
                onSelect={handleFileSelect}
                onClear={() => setAnexos([])}
                headerTemplate={headerTemplate}
                itemTemplate={itemTemplate}
                emptyTemplate={emptyTemplate}
                chooseOptions={chooseOptions}
                cancelOptions={cancelOptions}
            />
        </div>
    );
}

export default AnexoForm;
