import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import CalendarIcon from '../../../../../../../assets/images/icon-calendar.png';
import pt from "date-fns/locale/pt";
import { cancelamento } from '../Service/VendaService';
import { useState } from 'react';

interface ModalCancelarContratoJazigoProps {
    showCancelarContratoJazigo: boolean;
    handleShowModalCancelarContratoJazigo: any;
    selectedRow: any;
    onInputChange: (key: string, value: any) => void;
}

function ModalCancelarContratoJazigo({
    selectedRow,
    showCancelarContratoJazigo,
    handleShowModalCancelarContratoJazigo,
    onInputChange
}: ModalCancelarContratoJazigoProps) {

    const [formCancelamento, setFormCancelamento] = useState({
        tipo: 0,
        motivo_cancelamento: '',
        devolucao_intermediacao: '',
        qtd_parcelas_pagas: ''
    });
    const [dataCancelamento, setDataCancelamento] = useState<Date | null>(null);
    const [dataPrimeiraParcelaPaga, setDataPrimeiraParcelaPaga] = useState<Date | null>(null);

    function handleDataCancelamento(date: Date) {
        throw new Error('Function not implemented.');
    }

    function handleDataPrimeiraParcelaPaga(date: Date) {
        throw new Error('Function not implemented.');
    }

    return (
        <Modal
            show={showCancelarContratoJazigo}
            size='xl'
            onHide={() => handleShowModalCancelarContratoJazigo(false)}
        >
            <Modal.Header closeButton>
                <Modal.Title className='text-center'>Preencha o formulário a seguir para seguir com o cancelamento.</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row className="group-form">
                    <Form.Group as={Col} controlId="formGridState">
                        <Form.Label>Tipo</Form.Label>
                        <Form.Select value={formCancelamento?.tipo ? formCancelamento?.tipo : ''} onChange={(e) =>onInputChange('tipo', Number(e.target.value))}>
                            <option>Selecione...</option>
                            <option value={1}>Erro de digitação</option>
                            <option value={2}>Contrato em negociação</option>
                            <option value={3}>Contratos Recebidos sem Devolução de Valores</option>
                            <option value={4}>Contratos Recebidos com Devolução de Valores</option>
                            <option value={5}>Cancelamento para Troca</option>
                        </Form.Select>
                    </Form.Group>
                    <Form.Group as={Col} controlId="formGridState" style={{ display: 'grid' }}>
                        <Form.Label>Data de Cancelamento</Form.Label>
                        <DatePicker
                            selected={dataCancelamento}
                            onChange={(date: Date) => {
                                handleDataCancelamento(date);
                                onInputChange('dataCancelamento', date.toISOString());
                            }}
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            fixedHeight
                            locale={pt}
                            customInput={
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <input
                                        type="text"
                                        placeholder="Data de Cancelamento"
                                        className="dateFormFinanceiro"
                                        value={dataCancelamento ? dataCancelamento.toLocaleDateString() : ''}
                                        readOnly
                                    />
                                    <img
                                        className="calendar-icon"
                                        src={CalendarIcon}
                                    />
                                </div>
                            }
                        />
                    </Form.Group>
                    <Col>
                        <Form.Label>Motivo do cancelamento</Form.Label>
                        <Form.Control value={formCancelamento.motivo_cancelamento || ''} onChange={(e) =>onInputChange("motivo_cancelamento", e.target.value)} />
                    </Col>
                </Row>
                <Row className="group-form">
                    <Col>
                        <Form.Label>Intermediação a Creditar / Devolver</Form.Label>
                        <Form.Control value={formCancelamento.devolucao_intermediacao || ''} onChange={(e) =>onInputChange("devolucao_intermediacao", e.target.value)} />
                    </Col>
                    <Col>
                        <Form.Label>Qtd de parcelas pagas</Form.Label>
                        <Form.Control value={formCancelamento.qtd_parcelas_pagas || ''} onChange={(e) =>onInputChange("devolucao_intermediacao", e.target.value)} />
                    </Col>
                    <Form.Group as={Col} controlId="formGridState" style={{ display: 'grid' }}>
                        <Form.Label>Data da Primeira parcela Paga</Form.Label>
                        <DatePicker
                            selected={dataPrimeiraParcelaPaga}
                            onChange={(date: Date) => {
                               handleDataPrimeiraParcelaPaga(date);
                               onInputChange('dataCancelamento', date.toISOString());
                            }}
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            fixedHeight
                            locale={pt}
                            customInput={
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <input
                                        type="text"
                                        placeholder="Data da Primeira Parcela"
                                        className="dateFormFinanceiro"
                                        value={dataPrimeiraParcelaPaga ?dataPrimeiraParcelaPaga.toLocaleDateString() : ''}
                                        readOnly
                                    />
                                    <img
                                        className="calendar-icon"
                                        src={CalendarIcon}
                                    />
                                </div>
                            }
                        />
                    </Form.Group>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="success"
                    onClick={() => { cancelamento(selectedRow,formCancelamento,handleShowModalCancelarContratoJazigo) }}
                >
                    Confirmar
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ModalCancelarContratoJazigo;
