import { Col, Form, InputGroup, Row } from "react-bootstrap";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import CalendarIcon from '../../../../../assets/images/icon-calendar.png';
import { useEffect, useState } from "react";
import pt from "date-fns/locale/pt";

import CompradorForm from "../Components/Compradores";
import { InputNumber } from "primereact/inputnumber";

function CessaoDeDireitoForm(props: any) {

    const { numero, unidade, contrato_cliente, local_pagamento, valor_servico, observacao } = props.formBasico || {};
    const [vencimento, setVencimentoData] = useState<Date | null>(null);
    const [data, setData] = useState<Date | null>(null);

    const handleChange = (field: string, value: any) => {
        props.onInputChange(field, value);
    };

    return (
        <div style={{ margin: '15px' }}>
            <Row className="mb-3">
                {props.formCompradores && props.formCompradores.map((comprador: any, key: any) => (
                    <Row key={comprador.cliente_id}>
                        <Col md="4">
                            <Form.Label>Nº {key + 1} | Comprador</Form.Label>
                            <p>{comprador?.cliente_name}</p>
                        </Col>
                        <Col md="3">
                            <Form.Label>Principal</Form.Label>
                            <p>{comprador?.principal === 1 ? 'Sim' : 'Não'}</p>
                        </Col>
                    </Row>
                ))}
            </Row>
            <Row className="mb-3">
                <Col md="2">
                    <Form.Label>Número de contrato </Form.Label>
                    <Form.Control
                        value={numero}
                        onChange={(e) => handleChange("numero", e.target.value)}
                        disabled
                    />
                </Col>
                <Col md="2">
                    <Form.Label>Unidade</Form.Label>
                    <Form.Control
                        value={unidade}
                        onChange={(e) => handleChange("unidade", e.target.value)}
                        disabled
                    />
                </Col>
                <Col md="2">
                    <Form.Label>Local de Pagamento </Form.Label>
                    <Form.Control
                        value={local_pagamento}
                        onChange={(e) => handleChange("local_pagamento", e.target.value)}
                    />
                </Col>
                <Col md="2">
                    <Form.Label>Valor do Serviço</Form.Label>
                    <InputNumber
                        style={{ width: '100%', height: '38px' }}
                        value={valor_servico}
                        onChange={(e) => handleChange("valor_servico", e.value)}
                        mode="currency"
                        currency="BRL"
                        locale="pt-BR"
                    />
                </Col>
                <Col md="2" controlId="formGridState" style={{ display: 'grid' }}>
                    <Form.Label>Data do processo</Form.Label>
                    <DatePicker
                        selected={data || null}
                        onChange={(date: Date) => {
                            setData(date);
                            handleChange('data', date ? date.toISOString() : '');
                        }}
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        fixedHeight
                        locale={pt}
                        customInput={
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <input
                                    type="text"
                                    placeholder="Data"
                                    className="dateFormFinanceiro"
                                    value={data ? data.toLocaleDateString() : ''}
                                    readOnly
                                />
                                <img
                                    className="calendar-icon"
                                    src={CalendarIcon}
                                />
                            </div>
                        }
                    />
                </Col>
                <Col md="2">
                    <Form.Label>Vencimento Taxa Temporária</Form.Label>
                    <DatePicker
                        selected={vencimento || null}
                        onChange={(date: Date) => {
                            setVencimentoData(date);
                            handleChange('vencimento', date ? date.toISOString() : '');
                        }}
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        fixedHeight
                        locale={pt}
                        customInput={
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <input
                                    type="text"
                                    placeholder="vencimento"
                                    className="dateFormFinanceiro"
                                    value={vencimento ? vencimento.toLocaleDateString() : ''}
                                    readOnly
                                />
                                <img
                                    className="calendar-icon"
                                    src={CalendarIcon}
                                />
                            </div>
                        }
                    />
                </Col>
            </Row>
            <Row className="mb-3">
                <Col md="12">
                    <Form.Label>Observação</Form.Label>
                    <Form.Control
                        as={'textarea'}
                        value={observacao}
                        onChange={(e) => handleChange("observacao", e.target.value)}
                    />
                </Col>
            </Row>
            <Row className="mb-3">
                <CompradorForm
                    compradorData={contrato_cliente || []}
                    onInputChange={(index: number, field: string, value: any) => props.onInputCompradores(index, field, value)}
                    onAddCompradores={props.onAddCompradores}
                    onRemoveCompradores={(index: number) => props.onRemoveCompradores(index)}
                />
            </Row>
        </div>
    );
}

export default CessaoDeDireitoForm;
