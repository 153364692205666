import React, { ReactNode, useEffect } from "react";
import { Col, Row, Button, Form } from "react-bootstrap";
import { useAppContext } from "../../../../contexts/AppContext";
import styled from 'styled-components';

interface ProgressBarFormProps {
    currentPart: number;
    totalParts: number;
    handleBack: () => void;
    handleNext: () => void;
    steps: string[];
    children: ReactNode;
}

const ProgressBarFormVenda: React.FC<ProgressBarFormProps> = ({
    currentPart,
    totalParts,
    handleBack,
    handleNext,
    steps,
    children
}) => {
    const { showButtonSave, setShowButtonSave } = useAppContext();

    const activeSaveButton = (value: boolean) => {
        setShowButtonSave(value);
    };
    const renderCirclesAndLines = () => {
        const elements = [];

        for (let i = 1; i <= totalParts; i++) {
            const isCurrentStep = currentPart >= i;
            const isLastStep = i === totalParts;

            elements.push(
                <React.Fragment key={i}>
                    <div
                        className={`number-circle ${isCurrentStep ? 'number-active' : ''} d-flex align-items-center justify-content-center font-weight-bold`}
                    >
                        {steps[i - 1]}
                    </div>
                    {i < totalParts && (
                        <div className={`line-bar ${isCurrentStep ? 'line-bar-active' : ''}`}></div>
                    )}
                </React.Fragment>
            );
        }

        return elements;
    };
    useEffect(() => {
        if (currentPart === totalParts && !showButtonSave) {
            activeSaveButton(true);
        }
    }, [currentPart])
    return (
        <>
            <Col md="12" style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                <div className="pt-4 mb-4 progressBar">
                    {renderCirclesAndLines()}
                </div>
                {children}

                <Col md="12" className="d-flex justify-content-end botoes-progress-bar" style={{ margin: '10px' }}>
                    <Form.Group className="mb-3 foto-perfil-form" style={{ marginRight: '36px', marginBottom: '15px !important' }}>
                        {currentPart !== 1 && (
                            <Button onClick={() => { activeSaveButton(false); handleBack() }} className="button-back-form" variant="primary">
                                Anterior
                            </Button>
                        )}
                        {currentPart !== totalParts && (
                            <Button className="button-next-form" variant="primary" onClick={handleNext}>
                                Próximo
                            </Button>
                        )}
                    </Form.Group>
                </Col>
            </Col>

        </>
    );
};

export default ProgressBarFormVenda;
