import { Button, Col, Form, InputGroup, Row } from "react-bootstrap";
import 'react-datepicker/dist/react-datepicker.css';
import { useState } from "react";
import 'react-autocomplete-input/dist/bundle.css';
import InformacaoBancariaForm from "./InformacaoBancariaForm";
import PessoaFormContaFInanceira from "./PessoaFormContaFInanceira";
import { InputNumber } from "primereact/inputnumber";

function ContaFinanceiraForm(props: any) {
    const { nome, saldo, id_externo, tipo_conta, limite, emite_boleto, status, telefone_contato, descricao, seq_nosso_numero } = props.formBasico;

    const [criaPessoa, setCriaPessoa] = useState(false);

    const handleRemovePessoa = () => {
        setCriaPessoa(false);
    }

    const handleAddPessoa = () => {
        setCriaPessoa(true);
    }

    const handleChange = (field: string, value: any) => {
        props.onInputChange(field, value);
    };

    return (
        <Form.Group className="imovel-form">
            <Form.Group className="imovel-form">
                <Row className="mb-3">
                    <Col>
                        <Form.Label>Nome</Form.Label>
                        <Form.Control value={nome} isInvalid={!nome} onChange={(e) => handleChange('nome', e.target.value)} />
                    </Col>
                    <Col>
                        <Form.Label>Saldo</Form.Label>
                        <InputNumber
                            value={saldo}
                            onChange={(e) => handleChange('saldo', e.value)}
                            minFractionDigits={2}
                            maxFractionDigits={24}
                            style={{ width: '100%', height: '38px' }}
                            locale="pt-BR" />
                    </Col>
                    <Form.Group as={Col} controlId="formGridState">
                        <Form.Label>Tipo de Conta</Form.Label>
                        <Form.Select value={tipo_conta || ''} isInvalid={!tipo_conta} onChange={(e) => handleChange('tipo_conta', e.target.value)}>
                            <option>Selecione...</option>
                            <option value={"1"}>Conta Corrente</option>
                            <option value={"2"}>Conta Poupança</option>
                            <option value={"3"}>Conta Caixa</option>
                            <option value={"4"}>Conta Cofre</option>
                        </Form.Select>
                    </Form.Group>
                    <Col>
                        <Form.Label>Limite</Form.Label>
                        <InputNumber
                            value={limite}
                            onChange={(e) => handleChange('limite', e.value)}
                            minFractionDigits={2}
                            maxFractionDigits={24}
                            style={{ width: '100%', height: '38px' }}
                            locale="pt-BR" />
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col md="2">
                        <Form.Label>Status</Form.Label>
                        <Form.Select value={status || ''} isInvalid={!status} onChange={(e) => handleChange('status', e.target.value)}>
                            <option>Selecione...</option>
                            <option value={"1"}>Inativo</option>
                            <option value={"2"}>Ativado</option>
                            <option value={"3"}>Desativado</option>
                        </Form.Select>
                    </Col>
                    <Col md="2">
                        <Form.Label>Telefone de Contato</Form.Label>
                        <InputNumber
                            value={telefone_contato ? telefone_contato : null}
                            onChange={(e) => handleChange("telefone_contato", e.value)}
                            useGrouping={false}
                            min={0}
                            max={999999999999}
                        />
                    </Col>
                    <Col md="1">
                        <Form.Label>Id Externo</Form.Label>
                        <InputNumber
                            value={id_externo ? id_externo : null}
                            onChange={(e) => handleChange("id_externo", e.value)}
                            useGrouping={false}
                            min={0}
                            max={999999999999}
                        />
                    </Col>
                    <Col md='4'>
                        <Form.Label>Descrição</Form.Label>
                        <Form.Control value={descricao} onChange={(e) => handleChange('descricao', e.target.value)} />
                    </Col>
                    <Col md="1">
                        <Form.Label>Emite Boleto?</Form.Label>
                        <Form.Check
                            style={{ display: "flex", alignItems: "center", justifyContent: "center", }}
                            checked={emite_boleto || false}
                            onChange={(e) => handleChange("emite_boleto", e.target.checked)}
                            type="switch"
                            id="custom-switch"
                        />
                    </Col>
                    {emite_boleto && (
                        <Col md={2}>
                            <Form.Label>Seq. Nosso Número</Form.Label>
                            <Form.Control
                                type="number"
                                value={seq_nosso_numero}
                                onChange={(e) => handleChange('seq_nosso_numero', e.target.value)}
                            />
                        </Col>
                    )}
                </Row>
            </Form.Group>

            {tipo_conta && tipo_conta !== '3' && tipo_conta !== '4' && (
                <InformacaoBancariaForm
                    formBasico={props.formBasico.informacao_bancaria ? props.formBasico.informacao_bancaria : {}}
                    onInputChangeInformacaoBancaria={(field: string, value: any) => props.onInputChangeInformacaoBancaria(`${field}`, value)}
                    handleAddPessoa={() => handleAddPessoa()}
                    handleRemovePessoa={() => handleRemovePessoa()}
                    emiteBoleto={emite_boleto}
                />
            )}
            {criaPessoa && (
                <>
                    <PessoaFormContaFInanceira
                        formBasico={props.formBasico.informacao_bancaria && props.formBasico.informacao_bancaria.pessoa ? props.formBasico.informacao_bancaria.pessoa : {}}
                        onInputChangePessoaForm={(field: string, value: any) => props.onInputChangePessoaForm(`${field}`, value)}
                        onInputChangeEnderecos={(field: string, value: any) => props.onInputChangeEnderecos(`${field}`, value)}
                        onInputChangeTelefones={(field: string, value: any) => props.onInputChangeTelefones(`${field}`, value)}
                        onInputChangeBairro={(field: string, value: any) => props.onInputChangeBairro(`${field}`, value)}
                    />
                    <Row className="row-delete-button">
                        <Button
                            variant="danger"
                            size="sm"
                            className="ms-2 button-delete-endereco"
                            onClick={() => handleRemovePessoa()}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="21" viewBox="0 0 17 21" fill="none">
                                <path d="M1.57397 19.8265C1.57197 20.0893 1.67428 20.3422 1.85846 20.5296C2.04264 20.7171 2.29364 20.8238 2.55643 20.8265H13.9248C14.1876 20.8238 14.4386 20.7171 14.6228 20.5296C14.807 20.3422 14.9093 20.0893 14.9073 19.8265V6.49316H1.57397V19.8265ZM2.90731 7.8265H13.574V19.4932H2.90731V7.8265Z" fill="white" />
                                <path d="M5.90734 9.15967H4.57401V17.493H5.90734V9.15967Z" fill="white" />
                                <path d="M8.90734 9.15967H7.57401V17.493H8.90734V9.15967Z" fill="white" />
                                <path d="M11.9073 9.15967H10.574V17.493H11.9073V9.15967Z" fill="white" />
                                <path d="M11.2407 3.82617V1.82617C11.2407 1.26542 10.8453 0.826172 10.3407 0.826172H6.14066C5.636 0.826172 5.24066 1.26542 5.24066 1.82617V3.82617H0.240662V5.15951H16.2407V3.82617H11.2407ZM6.574 2.15951H9.90733V3.82617H6.574V2.15951Z" fill="white" />
                            </svg>
                        </Button>
                    </Row>
                </>
            )}
        </Form.Group>
    );
}

export default ContaFinanceiraForm;
