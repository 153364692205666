import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { AlertService } from './AlertService';
import api from '../services/api';
import { url } from '../environments/environments-develop';
import ModalAniversarioForm from './ModalAniversarioForm';

interface ModalAniversarioProps {
    showModal: boolean;
    handleClose: () => void;
    lancamentSelected: any;
    typeLancamento: any;
    urlSearchDataPut: any;
}

const ModalAniversario: React.FC<ModalAniversarioProps> = ({ showModal, urlSearchDataPut, handleClose, lancamentSelected, typeLancamento }) => {
    const [show, setShow] = useState(showModal);
    const [valorDocumento, setValorDocumento] = useState(showModal);
    const [saldoDocumento, setSaldoDocumento] = useState(showModal);
    const [lancamento_id, setLancamentoId] = useState(lancamentSelected);
    const [formBasico, setFormBasico] = useState<any | null>(null);
    const [formAniversarioMensal, setFormAniversarioMensal] = useState<any | null>(null);
    async function setSessionEditData(value: any): Promise<void> {
        try {
            const includeParam = 'config_venda,config_carteira,pessoa.telefones,pessoa.enderecos,grupo_permissoes';
            const response = await api.get(`${url}${urlSearchDataPut}${value}?include=${includeParam}`);
            if (response.status === 200) {
                setValorDocumento(response.data.valor_original)
                setSaldoDocumento(response.data.saldo)
                setFormBasico((prevGrupoData: any) => {
                    const dadosAtualizados = {
                        ...prevGrupoData,
                        cliente_id: response.data.cliente_id,
                        cliente_nome: response.data.cliente_nome,
                        lancamento_id: response.data.id,
                        data_vencimento: response.data.data_vencimento,
                        justificativa: response.data.justificativa,
                        numero_documento: response.data.numero_documento,
                        valor_original: response.data.valor_original,
                    };
                    return dadosAtualizados;
                });
                setFormAniversarioMensal((prevGrupoData: any) => {
                    const dadosAtualizados = {
                        ...prevGrupoData,
                        lancamento_financeiro_id: response.data.id,
                        alterar_parcelas_banco: false
                    };
                    return dadosAtualizados;
                });
            }
        } catch (error: any) {
            let mensagens: string[] = [];
            mensagens.push(error?.response?.data?.error?.message);
            AlertService.error('Erro!', String(mensagens));
        }
    }
    useEffect(() => {
        if (lancamentSelected) {
            setSessionEditData(lancamentSelected);
        }
    }, [lancamentSelected]);

    useEffect(() => {
        setShow(showModal);
    }, [showModal]);

    const handleSaveButton = async (): Promise<void> => {
        try {
            const response = await api.post(`${url}/api/v1/admin/financeiro/lancamentofinanceiro/alterar-aniversario-mensal`, formAniversarioMensal);
            if (response.status === 200) {
                AlertService.sucess('Sucesso!', 'Salvo com sucesso!').then(() => {
                    setShow(false);
                    window.location.reload();
                });
            }
        } catch (error: any) {
            AlertService.infomessage('Erro!', String(error.message)).then(() => {
                setShow(false);
                window.location.reload();
            });
        }
    };

    const handleChange = (field: string, value: any) => {
        setFormBasico((prevForm: any) => {
            return {
                ...prevForm,
                [field]: value,
            };
        });
        setFormAniversarioMensal((prevForm: any) => {
            return {
                ...prevForm,
                [field]: value,
            };
        });
    };

    return (
        <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            size='xl'
        >
            <Modal.Header closeButton>
                <Modal.Title>Aniversário Mensal</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ModalAniversarioForm
                    formBasico={formBasico}
                    handleChange={handleChange}
                    handleSaveButton={handleSaveButton}
                />
            </Modal.Body>
            <Modal.Footer>
                <div style={{ display: 'flex' }}>
                    <Button className='button-save-or-update' style={{ marginRight: '27px' }} onClick={handleSaveButton} variant="success">Confirmar</Button>
                </div>
            </Modal.Footer>
        </Modal>
    );
};

export default ModalAniversario;
