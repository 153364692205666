import { Col, Form, FormGroup, InputGroup, Row } from "react-bootstrap";
import { useState, useEffect } from "react";
import api from "../../../../services/api";
import { url } from "../../../../environments/environments-develop";
import { AlertService } from "../../../../components/AlertService";
import TokenService from "../../../../services/Auth/token.service";
import { InputNumber } from "primereact/inputnumber";

interface PropsData {
    Data: {
        quantidade?: number;
        identificador1?: string;
        identificador2?: string;
        tipo_jazigo_id?: number;
        tipo_imovel_id?: number;
        sub_tipo_imovel_id?: number;
        logradouro?: string;
        tipo_particular_coletivo?: number;
        imo_preco?: number;
        construcao?: boolean;
    };
    initialRightValues: any;
    onInputChange: (field: string, value: any) => void;
}
function FormJazigoGroup(props: PropsData) {
    const { 
        quantidade, 
        identificador1, 
        identificador2, 
        tipo_jazigo_id, 
        tipo_imovel_id, 
        sub_tipo_imovel_id, 
        logradouro, 
        tipo_particular_coletivo,
        imo_preco,
        construcao
    } = props.Data || {};
    const [listTipoJazigo, setListTipoJazigo] = useState([]);
    const [listSubTipoImovel, setListSubTipoImovel] = useState([]);
    const [listTipoImovel, setListTipoImovel] = useState([]);

    const buscarListas = async () => {
        try {
            const tipoJazigo = await api.get(`${url}/api/v1/admin/cemiterio/tipo-jazigo/`);
            const tipoImovel = await api.get(`${url}/api/v1/admin/vendas/tipoimovel/`);
            const subTipoImovel = await api.get(`${url}/api/v1/admin/vendas/subtipoimovel/`);
            setListTipoJazigo(tipoJazigo.data.data);
            setListTipoImovel(tipoImovel.data.data);
            setListSubTipoImovel(subTipoImovel.data.data);
        } catch (error) {
            AlertService.error('Error', 'Ocorreu um erro ao buscar as listas. Erro: ' + String(error));
        }
    };

    useEffect(() => {
        buscarListas();
    }, []);

    const handleChange = (field: string, value: any) => {
        if (field === 'identificador1') {
            if (value.length <= 60) {
                props.onInputChange(field, value);
            }
        } else if(field ==='identificador2'){
            if (value.length <= 10) {
                props.onInputChange(field, value);
            }
        } else if (field === 'logradouro') {
            if (value.length <= 120) {
                props.onInputChange(field, value);
            }
        } else if (field === 'quantidade') {
            if (/^\d+$/.test(value) && value <= 1000) {
                props.onInputChange(field, value);
            }
        } else if (field === 'imo_preco') {
            if (!isNaN(value) && value >= 0) {
                props.onInputChange(field, value);
            }
        } else if (field === 'construcao') {
            props.onInputChange(field, value === 'true' || value === true);
        } else {
            props.onInputChange(field, value);
        }
    };

    return (
        <>
            <FormGroup className="forms">
                <Row className="mb-3">
                    <Col md="3">
                        <Form.Label className="mb-0">{TokenService.getEmpreendimentoId() !== null ? TokenService.getEmpreendimentoId()?.alias_identificador1 : 'Identificador 1'}</Form.Label>
                        <Form.Control
                            value={identificador1}
                            isInvalid={!identificador1}
                            onChange={(e) => handleChange("identificador1", e.target.value)}
                        />
                    </Col>
                    <Col md='3'>
                        <Form.Label className="mb-0">Ala</Form.Label>
                        <Form.Control
                            value={logradouro}
                            isInvalid={!logradouro}
                            onChange={(e) => handleChange("logradouro", e.target.value)}
                        />
                    </Col>
                    <Col md="3">
                        <Form.Label className="mb-0">{TokenService.getEmpreendimentoId() !== null ? TokenService.getEmpreendimentoId()?.alias_identificador2 : 'Identificador 2'} inicial</Form.Label>
                        <Form.Control
                            value={identificador2}
                            isInvalid={!identificador2}
                            onChange={(e) => handleChange("identificador2", e.target.value)}
                        />
                    </Col>
                    <Col md='3'>
                        <Form.Label className="mb-0">Quantidade</Form.Label>
                        <Form.Control
                            value={quantidade}
                            isInvalid={!quantidade}
                            onChange={(e) => handleChange("quantidade", e.target.value)}
                        />
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col>
                        <Form.Label className="mb-0">Tipo Jazigo</Form.Label>
                        <Form.Select
                            value={tipo_jazigo_id}
                            isInvalid={!tipo_jazigo_id}
                            onChange={(e) => handleChange("tipo_jazigo_id", e.target.value)}
                        >
                            <option>Selecione...</option>
                            {listTipoJazigo.map((item: any) => (
                                <option key={item.id} value={item.id}>
                                    {item.sigla}
                                </option>
                            ))}
                        </Form.Select>
                    </Col>
                    <Col>
                        <Form.Label className="mb-0">Tipo </Form.Label>
                        <Form.Select
                            value={tipo_particular_coletivo}
                            isInvalid={!tipo_particular_coletivo}
                            onChange={(e) => handleChange("tipo_particular_coletivo", e.target.value)}
                        >
                            <option>Selecione...</option>
                            <option value="0">Coletivo</option>
                            <option value="1">Particular</option>
                        </Form.Select>
                    </Col>
                    <Col>
                        <Form.Label className="mb-0">Tipo Imóvel</Form.Label>
                        <Form.Select
                            value={tipo_imovel_id}
                            isInvalid={!tipo_imovel_id}
                            onChange={(e) => handleChange("tipo_imovel_id", e.target.value)}
                        >
                            <option>Selecione...</option>
                            {listTipoImovel.map((item: any) => (
                                <option key={item.id} value={item.id}>
                                    {item.nome}
                                </option>
                            ))}
                        </Form.Select>
                    </Col>
                    <Col>
                        <Form.Label className="mb-0">Sub Tipo Imóvel</Form.Label>
                        <Form.Select
                            value={sub_tipo_imovel_id}
                            onChange={(e) => handleChange("sub_tipo_imovel_id", e.target.value)}
                        >
                            <option>Selecione...</option>
                            {listSubTipoImovel.map((item: any) => (
                                <option key={item.id} value={item.id}>
                                    {item.nome}
                                </option>
                            ))}
                        </Form.Select>
                    </Col>
                    <Col style={{ display: 'grid' }}>
                        <Form.Label className="mb-0">Preço</Form.Label>
                        <InputNumber
                            value={imo_preco}
                            invalid={!imo_preco}
                            onChange={(e) => handleChange("imo_preco", e.value)}
                            mode="currency"
                            currency="BRL"
                            locale="pt-BR"
                        />
                    </Col>
                    <Col md="1">
                        <Form.Label className="mb-0">Construção</Form.Label>
                        <Form.Check
                            checked={construcao}
                            style={{ marginTop: '6px', marginLeft: '14px' }}
                            onChange={(e) => handleChange("construcao", e.target.value)}
                            type="switch"
                            id="custom-switch"
                        />
                    </Col>
                </Row>
            </FormGroup>
        </>
    );
}

export default FormJazigoGroup;
