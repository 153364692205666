import React, { useState, useEffect } from 'react';
import { Col, Form, Row } from 'react-bootstrap';

function ContaRec(props: any) {
    const [formData, setFormData] = useState<any>({});

    useEffect(() => {
        setFormData(props.formData);
    }, [props.formData]);

    const handleFormChange = (field: string, value: any) => {
        props.onInputChange(field, value);
    }

    return (
        <>
            <Row className="group-form">
                <Col>
                    <Form.Label>Em qual Coluna está id do cliente?</Form.Label>
                    <Form.Control
                        value={formData.cliente_id || ''}
                        onChange={(e) => handleFormChange('cliente_id', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está id do empreendimento?</Form.Label>
                    <Form.Control
                        value={formData.empreendimento_id || ''}
                        onChange={(e) => handleFormChange('empreendimento_id', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está id da conta?</Form.Label>
                    <Form.Control
                        value={formData.conta_financeiro_id || ''}
                        onChange={(e) => handleFormChange('conta_financeiro_id', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está a descricao?</Form.Label>
                    <Form.Control
                        value={formData.descricao || ''}
                        onChange={(e) => handleFormChange('descricao', e.target.value)} />
                </Col>
            </Row>
            <Row className="group-form">
                <Col>
                    <Form.Label>Em qual Coluna está a data de emissao?</Form.Label>
                    <Form.Control
                        value={formData.data_emissao || ''}
                        onChange={(e) => handleFormChange('data_emissao', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está a data de vencimento?</Form.Label>
                    <Form.Control
                        value={formData.data_vencimento || ''}
                        onChange={(e) => handleFormChange('data_vencimento', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está o valor original?</Form.Label>
                    <Form.Control
                        value={formData.valor_original || ''}
                        onChange={(e) => handleFormChange('valor_original', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está o valor pago?</Form.Label>
                    <Form.Control
                        value={formData.valor_pago || ''}
                        onChange={(e) => handleFormChange('valor_pago', e.target.value)} />
                </Col>
            </Row>
            <Row className="group-form">
                <Col>
                    <Form.Label>Em qual Coluna está o id do contas a receber?</Form.Label>
                    <Form.Control
                        value={formData.id_externo || ''}
                        onChange={(e) => handleFormChange('id_externo', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está o numero do documento?</Form.Label>
                    <Form.Control
                        value={formData.numero_documento || ''}
                        onChange={(e) => handleFormChange('numero_documento', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está a data do arquivo de remessa?</Form.Label>
                    <Form.Control
                        value={formData.data_arq_remessa || ''}
                        onChange={(e) => handleFormChange('data_arq_remessa', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está a data de vencimento prorrogada?</Form.Label>
                    <Form.Control
                        value={formData.data_vencimento_prorrogado || ''}
                        onChange={(e) => handleFormChange('data_vencimento_prorrogado', e.target.value)} />
                </Col>
            </Row>
            <Row className="group-form">
                <Col>
                    <Form.Label>Em qual Coluna está a data de pagamento do lançamento?</Form.Label>
                    <Form.Control
                        value={formData.data_pagamento || ''}
                        onChange={(e) => handleFormChange('data_pagamento', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está a data de credito?</Form.Label>
                    <Form.Control
                        value={formData.data_credito || ''}
                        onChange={(e) => handleFormChange('data_credito', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está os outros valores?</Form.Label>
                    <Form.Control
                        value={formData.valor_outros || ''}
                        onChange={(e) => handleFormChange('valor_outros', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está o valor de desconto?</Form.Label>
                    <Form.Control
                        value={formData.valor_desconto || ''}
                        onChange={(e) => handleFormChange('valor_desconto', e.target.value)} />
                </Col>
            </Row>
            <Row className="group-form">
                <Col>
                    <Form.Label>Em qual Coluna está os valores de juros?</Form.Label>
                    <Form.Control
                        value={formData.valor_juros || ''}
                        onChange={(e) => handleFormChange('valor_juros', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está os valores de multas?</Form.Label>
                    <Form.Control
                        value={formData.valor_multa || ''}
                        onChange={(e) => handleFormChange('valor_multa', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está os id de Tipo Parcela (CTR_TIPO)?</Form.Label>
                    <Form.Control
                        value={formData.tipo_parcela || ''}
                        onChange={(e) => handleFormChange('tipo_parcela', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está os id de Local de cobrança (CTR_LOCAL)?</Form.Label>
                    <Form.Control
                        value={formData.situacao_pagamento || ''}
                        onChange={(e) => handleFormChange('situacao_pagamento', e.target.value)} />
                </Col>
            </Row>
            <Row className="group-form">
                <Col>
                    <Form.Label>Em qual Coluna está os Tipo de documento (CTR_TIPO_DOC)?</Form.Label>
                    <Form.Control
                        value={formData.tipo_documento || ''}
                        onChange={(e) => handleFormChange('tipo_documento', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está o nosso número (CTR_NOSSO_NO_ALTERNATIVO)?</Form.Label>
                    <Form.Control
                        value={formData.nosso_numero || ''}
                        onChange={(e) => handleFormChange('nosso_numero', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está o codigo de barras (CTR_CODIGO_BARRAS)?</Form.Label>
                    <Form.Control
                        value={formData.codigo_barras || ''}
                        onChange={(e) => handleFormChange('codigo_barras', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está o id da venda (VEN_ID)?</Form.Label>
                    <Form.Control
                        value={formData.venda_id || ''}
                        onChange={(e) => handleFormChange('venda_id', e.target.value)} />
                </Col>
            </Row>
        </>
    );
}

export default ContaRec;
