import { useEffect, useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { listGavetas } from '../Service/VendaService';
import '../index.css';
import { Button, Modal } from 'react-bootstrap';
import { MDBCol, MDBPageItem, MDBPageNav, MDBPagination, MDBRow } from 'mdbreact';
import { Skeleton } from 'primereact/skeleton';
import { actionTemplateJazigoEstrutura } from './actionTemplateJazigoEstrutura';
import ModalInputInumado from './ModalInputInumado';
import ModalExumacao from './ModalExumação';
interface ModalJazigosEstruturaProps {
    imovelSelected: any;
    showModal: boolean;
    handleClose: () => void;
    onVerified: () => void;
    notVerified: () => void;
}

const ModalJazigosEstrutura: React.FC<ModalJazigosEstruturaProps> = ({ imovelSelected, showModal, handleClose, onVerified, notVerified }) => {
    const [nodes, setNodes] = useState<any[] | null>([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [titles, setTitles] = useState<any[]>([]);
    const [pagination, setPagination] = useState<any>();
    const [isLoading, setLoading] = useState(false);
    const [show, setShow] = useState(showModal);
    const [selectedRow, setSelectedRow] = useState<any>(null);
    const [showModalInputInumado, setShowModalInputInumado] = useState(false);
    const [availableSlots, setAvailableSlots] = useState(0);
    const [showModalExumacao, setShowModalExumacao] = useState(false);

    useEffect(() => {
        if (imovelSelected) {
            listGavetas(imovelSelected.imovel_id, currentPage, setLoading, setNodes, setTitles, setPagination);
        }
    }, [imovelSelected]);

    useEffect(() => {
        setShow(showModal);
    }, [showModal])

    useEffect(() => {
        if (nodes && nodes?.length > 0) {
            onVerified();
        } else {
            notVerified();
        }
    }, [nodes, imovelSelected])

    const renderPageNumbers = () => {
        const pageNumbers = [];
        for (let i = 0; i < pagination; i++) {
            if (i < 3 || i === pagination - 1 || (i >= currentPage - 1 && i <= currentPage + 1)) {
                pageNumbers.push(
                    <MDBPageItem key={i} active={i === currentPage}>
                        <MDBPageNav className="page-link" onClick={() => setCurrentPage(i)}>
                            <span key={i} onClick={() => setCurrentPage(i)} style={{ cursor: 'pointer', margin: '0 5px', fontWeight: i === currentPage ? 'bold' : 'normal' }}>
                                {i + 1}
                            </span>
                        </MDBPageNav>
                    </MDBPageItem>
                );
            }
        }
        return pageNumbers;
    };

    useEffect(() => {
        let count = 0;
        let index = 0;
        while (index < (nodes?.length ?? 0)) {
            if (nodes && nodes[index]?.inumado_id === null) {
                count++;
            }
            index++;
        };
        setAvailableSlots(count);
    }, [nodes]);

    return (
        <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            size="xl"
        >
            <Modal.Header style={{ display: 'flex', flexDirection: 'column' }}>
                <Modal.Title>{`Jazigo | Estrutura - ${imovelSelected?.unidade_imovel}`}</Modal.Title>
                <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                    <div style={{ marginInlineEnd: '1' }}>
                        <span style={{ fontWeight: 'bold' }}>Cliente: </span>
                        {imovelSelected?.cliente_nome}
                    </div>
                    <div style={{ marginInlineEnd: '1' }}>
                        <span style={{ fontWeight: 'bold' }}>Disponível: </span>
                        {availableSlots} {availableSlots === 1 ? 'unidade' : 'unidades'}
                    </div>
                </div>
            </Modal.Header>
            <Modal.Body>
                <div
                    style={{ paddingLeft: '22px', paddingRight: '22px' }}
                    className="container-section-table"
                >
                    <div className='table-card-jazigo'>
                        <DataTable
                            stripedRows
                            lazy
                            value={nodes ? nodes : []}
                            emptyMessage={<span style={{ display: 'flex', justifyContent: 'center', fontStyle: 'italic' }}>Nenhum item encontrado.</span>}
                        >
                            {titles && titles.map((col: any) => (
                                <Column
                                    style={{ textAlign: 'center', borderBottom: '1px solid #bdbdbd96' }}
                                    key={col.dataField}
                                    field={col.dataField}
                                    header={col.heading}
                                    expander={col.expander}
                                    body={isLoading && (<Skeleton />)}
                                />
                            ))}
                            <Column
                                style={{ textAlign: 'center', borderBottom: '1px solid #bdbdbd96' }}
                                className='menu-context-grid-inumado-gaveta'
                                body={(e) => actionTemplateJazigoEstrutura(
                                    e,
                                    setSelectedRow,
                                    setShowModalInputInumado,
                                    setShowModalExumacao
                                )}
                                header="Menu"
                            />
                        </DataTable>
                    </div>
                    <ModalInputInumado
                        showModal={showModalInputInumado}
                        handleClose={() => setShowModalInputInumado(false)}
                        gavetaSelect={selectedRow}
                    />
                    <ModalExumacao
                        showModal={showModalExumacao}
                        handleClose={() => setShowModalExumacao(false)}
                        gavetaSelect={selectedRow}
                    />
                    <div className="pagination-footer">
                        <MDBRow>
                            <MDBCol>
                                <MDBPagination circle>
                                    <MDBPageItem disabled={currentPage === 0}>
                                        <MDBPageNav className="page-link" aria-label="Previous" onClick={() => setCurrentPage(currentPage - 1)}>
                                            <span aria-hidden="true">&laquo;</span>
                                        </MDBPageNav>
                                    </MDBPageItem>
                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                        {renderPageNumbers()}
                                    </div>
                                    <MDBPageItem disabled={currentPage === pagination - 1}>
                                        <MDBPageNav className="page-link" aria-label="Next" onClick={() => setCurrentPage(currentPage + 1)}>
                                            <span aria-hidden="true">&raquo;</span>
                                        </MDBPageNav>
                                    </MDBPageItem>
                                </MDBPagination>
                            </MDBCol>
                        </MDBRow>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer style={{ display: 'flex', flexDirection: 'column' }}>

                <div style={{ display: 'flex', justifyContent: 'end', paddingTop: '15px' }}>
                    <Button onClick={() => { handleClose() }} className="button-back-form" variant="primary">
                        Fechar
                    </Button>
                </div>
            </Modal.Footer>
        </Modal>
    );
}

export default ModalJazigosEstrutura;
