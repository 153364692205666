import axios from "../../../services/api";
import { Observable, from, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { ClienteModel, ICliente } from "../Models/ClienteModel";
import { url } from "../../../environments/environments-develop";

interface IApiResponse<T> {
  data: T;
  headers: {
    titles: string[];
  };
  meta: any;
}

class ClienteService {
  private resourceUrl = `${url}/api/v1/admin/financeiro/cliente`;

  list(params: any = {}, headers: any = null): Observable<ClienteModel[]> {
    return from(
      axios
        .get<IApiResponse<ICliente[]>>(this.resourceUrl, {
          params,
          headers,
        })
        .then((response) => {
          return response.data.data.map((item) =>
            new ClienteModel().deserialize(item)
          );
        })
    ).pipe(catchError((error) => throwError(error)));
  }

  listAll(
    params: any = {},
    headers: any = null,
    updateTitles: (titles: string[]) => void,
    updatePagination: (totalPages: number) => void
  ): Observable<ClienteModel[]> {
    return from(
      axios
        .get<IApiResponse<ICliente[]>>(this.resourceUrl, {
          params,
          headers,
        })
        .then((response) => {
          updateTitles(response.data.headers.titles);
          updatePagination(response.data.meta.pagination.total_pages);
          return response.data.data.map((item) =>
            new ClienteModel().deserialize(item)
          );
        })
    ).pipe(catchError((error) => throwError(error)));
  }

  get(
    id: number,
    params: any = {},
    headers: any = null
  ): Observable<ClienteModel> {
    return from(
      axios
        .get<ICliente>(`${this.resourceUrl}/${id}`, {
          params,
          headers,
        })
        .then((response) => new ClienteModel().deserialize(response.data))
    ).pipe(catchError((error) => throwError(error)));
  }

  create(
    data: any,
    params: any = {},
    headers: any = null
  ): Observable<ClienteModel> {
    return from(
      axios
        .post<ICliente>(this.resourceUrl, data, {
          params,
          headers,
        })
        .then((response) => new ClienteModel().deserialize(response.data))
    ).pipe(catchError((error) => throwError(error)));
  }

  update(
    id: number,
    data: any,
    params: any = {},
    headers: any = null
  ): Observable<ClienteModel> {
    return from(
      axios
        .put<ICliente>(`${this.resourceUrl}/${id}`, data, {
          params,
          headers,
        })
        .then((response) => new ClienteModel().deserialize(response.data))
    ).pipe(catchError((error) => throwError(error)));
  }

  estorno(
    id: number,
    params: any = {},
    headers: any = null
  ): Observable<ClienteModel> {
    return from(
      axios
        .post<ICliente>(`${this.resourceUrl}/estorno/${id}`, {
          params,
          headers,
        })
        .then((response) => new ClienteModel().deserialize(response.data))
    ).pipe(catchError((error) => throwError(error)));
  }

  excluir(params: any = {}, headers: any = null): Observable<any> {
    return from(
      axios
        .post(`${this.resourceUrl}/destroy-all`, { ids: params }, { headers })
        .then((response) => response.data)
    ).pipe(catchError((error) => throwError(error)));
  }
}

export default new ClienteService();
