import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import CalendarIcon from '../../../../../assets/images/icon-calendar.png';
import pt from "date-fns/locale/pt";
import { cancelamento, reativacao } from './GestaoContratosServices';

interface ModalReativarContratoProps {
    showModalReativarContrato: boolean;
    formCancelamento: {
        tipo: string;
        justificativa: string;
        devolucao_intermediacao: string;
        qtd_parcelas_pagas: string;
    };
    dataCancelamento: Date | null;
    dataPrimeiraParcelaPaga: Date | null;
    selectedNodeKeys: string[];
    handleShowModalReativarContrato: (show: boolean) => void;
    onInputChange: (field: string, value: any) => void;
    handleDataCancelamento: (date: Date) => void;
    handleDataPrimeiraParcelaPaga: (date: Date) => void;
}

const ModalReativarContrato: React.FC<ModalReativarContratoProps> = (props) => {
    const handleTipoChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        props.onInputChange('tipo', e.target.value);
    };

    const handleJustificativaChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        props.onInputChange('justificativa', e.target.value);
    };

    const handleDevolucaoIntermediacaoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        props.onInputChange('devolucao_intermediacao', e.target.value);
    };

    const handleQtdParcelasPagasChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        props.onInputChange('qtd_parcelas_pagas', e.target.value);
    };

    return (
        <Modal show={props.showModalReativarContrato} size='xl' style={{ padding: '0px !important' }} onHide={() => props.handleShowModalReativarContrato(false)}>
            <Modal.Header closeButton>
                <Modal.Title className='text-center'>Preencha o formulario a seguir para seguir com a reativação.</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ padding: '0px !important' }}>
                <Row className="group-form">
                    <Form.Group as={Col} controlId="formGridState">
                        <Form.Label>Tipo</Form.Label>
                        <Form.Select value={props.formCancelamento.tipo || ''} onChange={handleTipoChange}>
                            <option>Selecione...</option>
                            <option value="1">Erro de digitação</option>
                            <option value="2">Contrato em negociação</option>
                            <option value="3">Contratos Recebidos sem Devolução de Valores</option>
                            <option value="4">Contratos Recebidos com Devolução de Valores</option>
                            <option value="5">Cancelamento para Troca</option>
                        </Form.Select>
                    </Form.Group>
                    <Form.Group as={Col} controlId="formGridDateCancelamento" style={{ display: 'grid' }}>
                        <Form.Label>Data de Cancelamento</Form.Label>
                        <DatePicker
                            selected={props.dataCancelamento}
                            onChange={(date: Date) => {
                                props.handleDataCancelamento(date);
                                props.onInputChange('data_cancelamento', date.toISOString());
                            }}
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            fixedHeight
                            locale={pt}
                            customInput={
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <input
                                        type="text"
                                        placeholder="Data de Cancelamento"
                                        className="dateFormFinanceiro"
                                        value={props.dataCancelamento ? props.dataCancelamento.toLocaleDateString() : ''}
                                        readOnly
                                    />
                                    <img className="calendar-icon" src={CalendarIcon} alt="Calendar Icon" />
                                </div>
                            }
                        />
                    </Form.Group>
                    <Col>
                        <Form.Label>Justificativa</Form.Label>
                        <Form.Control value={props.formCancelamento.justificativa || ''} onChange={handleJustificativaChange} />
                    </Col>
                </Row>
                <Row className="group-form">
                    <Col>
                        <Form.Label>Intermediação a Creditar / Devolver</Form.Label>
                        <Form.Control value={props.formCancelamento.devolucao_intermediacao || ''} onChange={handleDevolucaoIntermediacaoChange} />
                    </Col>
                    <Col>
                        <Form.Label>Qtd de parcelas pagas</Form.Label>
                        <Form.Control value={props.formCancelamento.qtd_parcelas_pagas || ''} onChange={handleQtdParcelasPagasChange} />
                    </Col>
                    <Form.Group as={Col} controlId="formGridDatePrimeiraParcela" style={{ display: 'grid' }}>
                        <Form.Label>Data da Primeira parcela Paga</Form.Label>
                        <DatePicker
                            selected={props.dataPrimeiraParcelaPaga}
                            onChange={(date: Date) => {
                                props.handleDataPrimeiraParcelaPaga(date);
                                props.onInputChange('data_primeira_parcela_paga', date.toISOString());
                            }}
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            fixedHeight
                            locale={pt}
                            customInput={
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <input
                                        type="text"
                                        placeholder="Data da Primeira Parcela"
                                        className="dateFormFinanceiro"
                                        value={props.dataPrimeiraParcelaPaga ? props.dataPrimeiraParcelaPaga.toLocaleDateString() : ''}
                                        readOnly
                                    />
                                    <img className="calendar-icon" src={CalendarIcon} alt="Calendar Icon" />
                                </div>
                            }
                        />
                    </Form.Group>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="success" onClick={() => reativacao(props.selectedNodeKeys, props.formCancelamento, props.handleShowModalReativarContrato)}>Confirmar</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ModalReativarContrato;
