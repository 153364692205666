import axios from "../../../services/api";
import { Observable, from, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { url } from "../../../environments/environments-develop";
import { ContratoModel } from "../Models/ContratoModel";

interface IApiResponse<T> {
  data: T;
  headers: {
    titles: string[];
  };
  meta: any;
}

class ContratoService {
  private resourceUrl = url + "/api/v1/admin/vendas/contrato";
  private resourceUrlVenda = url + "/api/v1/admin/vendas";
  private resourceUrlContratoJazigo =
    url + "/api/v1/admin/cemiterio/contrato-jazigo";
  private resourceUrlCemiterio = url + "/api/v1/admin/cemiterio";

  list(
    params: any = {},
    headers: any = null,
    updateTitles: (titles: string[]) => void,
    updatePagination: (totalPages: number) => void
  ): Observable<ContratoModel.ContratoModel[]> {
    return from(
      axios
        .get<IApiResponse<ContratoModel.IContrato[]>>(this.resourceUrl, {
          params,
          headers,
        })
        .then((response) => {
          updateTitles(response.data.headers.titles);
          updatePagination(response.data.meta.pagination.total_pages);
          return response.data.data.map((item) =>
            ContratoModel.ContratoModel.deserialize(item)
          );
        })
    ).pipe(catchError((error) => throwError(error)));
  }

  get(
    id: number,
    params: any = {},
    headers: any = null
  ): Observable<ContratoModel.ContratoModel> {
    return from(
      axios
        .get<ContratoModel.IContrato>(`${this.resourceUrl}/${id}`, {
          params,
          headers,
        })
        .then((response) =>
          ContratoModel.ContratoModel.deserialize(response.data)
        )
    ).pipe(catchError((error) => throwError(error)));
  }

  create(
    data: any,
    params: any = {},
    headers: any = null
  ): Observable<ContratoModel.ContratoModel> {
    return from(
      axios
        .post<ContratoModel.IContrato>(this.resourceUrl, data, {
          params,
          headers,
        })
        .then((response) =>
          ContratoModel.ContratoModel.deserialize(response.data)
        )
    ).pipe(catchError((error) => throwError(error)));
  }

  update(
    id: number,
    data: any,
    params: any = {},
    headers: any = null
  ): Observable<ContratoModel.ContratoModel> {
    return from(
      axios
        .put<ContratoModel.IContrato>(`${this.resourceUrl}/${id}`, data, {
          params,
          headers,
        })
        .then((response) =>
          ContratoModel.ContratoModel.deserialize(response.data)
        )
    ).pipe(catchError((error) => throwError(error)));
  }

  excluir(params: any = {}, headers: any = null): Observable<any> {
    return from(
      axios
        .post(`${this.resourceUrl}/destroy-all`, { ids: params }, { headers })
        .then((response) => response.data)
    ).pipe(catchError((error) => throwError(error)));
  }

  simularParcelaImovel(
    params: any = {},
    headers: any = null
  ): Observable<ContratoModel.ContratoModel[]> {
    return from(
      axios
        .get<IApiResponse<ContratoModel.IContrato[]>>(
          this.resourceUrlVenda + "/contrato-simular-parcela-imovel",
          {
            params,
            headers,
          }
        )
        .then((response) => {
          return response.data.data.map((item) =>
            ContratoModel.ContratoModel.deserialize(item)
          );
        })
    ).pipe(catchError((error) => throwError(error)));
  }

  ContratoJazigoSimularParcelaImovel(
    params: any = {},
    headers: any = null
  ): Observable<ContratoModel.ContratoModel[]> {
    return from(
      axios
        .get<IApiResponse<ContratoModel.IContrato[]>>(
          this.resourceUrlCemiterio + "/contrato-jazigo-simular-parcela-imovel",
          {
            params,
            headers,
          }
        )
        .then((response) => {
          return response.data.data.map((item) =>
            ContratoModel.ContratoModel.deserialize(item)
          );
        })
    ).pipe(catchError((error) => throwError(error)));
  }
  SimularVendaContrato(
    params: any = {},
    headers: any = null
  ): Observable<any[]> {
    return from(
      axios
        .get<any[]>(this.resourceUrlVenda + "/simular-venda-contrato", {
          params,
          headers,
        })
        .then((response) => {
          return response.data;
        })
    ).pipe(catchError((error) => throwError(error)));
  }

  VendaAvulsaList(
    params: any = {},
    headers: any = null,
    updateTitles: (titles: string[]) => void,
    updatePagination: (totalPages: number) => void
  ): Observable<any[]> {
    return from(
      axios
        .get<IApiResponse<any[]>>(this.resourceUrlVenda + "/venda-avulsa", {
          params,
          headers,
        })
        .then((response) => {
          updateTitles(response.data.headers.titles);
          updatePagination(response.data.meta.pagination.total_pages);
          return response.data.data;
        })
    ).pipe(catchError((error) => throwError(error)));
  }

  listContratoJazigo(
    params: any = {},
    headers: any = null,
    updateTitles: (titles: string[]) => void,
    updatePagination: (totalPages: number) => void
  ): Observable<ContratoModel.ContratoModel[]> {
    return from(
      axios
        .get<IApiResponse<ContratoModel.IContrato[]>>(
          this.resourceUrlContratoJazigo,
          {
            params,
            headers,
          }
        )
        .then((response) => {
          updateTitles(response.data.headers.titles);
          updatePagination(response.data.meta.pagination.total_pages);
          return response.data.data.map((item) =>
            ContratoModel.ContratoModel.deserialize(item)
          );
        })
    ).pipe(catchError((error) => throwError(error)));
  }
}

export default new ContratoService();
