import React, { useState, useEffect } from 'react';
import { Col, Form, Row } from 'react-bootstrap';

function ImportacaoGavetas(props: any) {
    const [formData, setFormData] = useState<any>({});

    const handleFormChange = (field: string, value: any) => {
        props.onInputChange(field, value);
    }
    useEffect(() => {
        setFormData(props.formData);
    }, [props.formData]);
    return (
        <>
            <Row className="group-form">
                <Col>
                    <Form.Label>Em qual Coluna está o id do jazigo?</Form.Label>
                    <Form.Control
                        value={formData.jazigo_id || ''}
                        onChange={(e) => handleFormChange('jazigo_id', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está o id da gaveta?</Form.Label>
                    <Form.Control
                        value={formData.identificador_gaveta || ''}
                        onChange={(e) => handleFormChange('identificador_gaveta', e.target.value)} />
                </Col>
            </Row>
        </>
    );
}

export default ImportacaoGavetas;
