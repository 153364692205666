import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import { useAppContext } from '../../../contexts/AppContext';
import { url } from '../../../environments/environments-develop';
import TokenService from '../../../services/Auth/token.service';
import ModeloContratoForm from './Formularios/ModeloContratoForm';

interface PropsData {
    nome?: string;
    descricao?: string;
    status?: boolean;
    empreendimento_id?: number;
    anexo?: AnexosPropsData;
};
interface AnexosPropsData {
    nome?: string;
    diretorio: null;
    alias?: string;
    extensao?: string;
    conteudo?: string;
};

function CreateModeloContrato() {
    const { setUrl, setBody, setShowButtonSave, setItemsBreadsCrumbs } = useAppContext();
    const [formBasico, setFormBasico] = useState<PropsData>({
        status: false
    });

    useEffect(() => {
        setItemsBreadsCrumbs([
            {
                label: 'Carteira',
                url: '/carteira',
            },
            {
                label: 'Modelos de Contratos',
                url: '/modelo-contrato',
            },
            {
                label: 'Criar'
            }
        ]);
        setUrl(`${url}/api/v1/admin/vendas/modelo-contratos`);
        const empreendimentoId = TokenService.getEmpreendimentoId()?.id;
        if (empreendimentoId) {
            setFormBasico((prevImovelData) => {
                return {
                    ...prevImovelData,
                    empreendimento_id: empreendimentoId
                };
            });
        } else {
            console.error('ID do empreendimento não encontrado.');
        }
    }, []);
    const handleChange = (field: string, value: string) => {
        setFormBasico((prevGrupoData) => {
            return {
                ...prevGrupoData,
                [field]: value
            };
        });
    };
    useEffect(() => {
        setBody({
            nome: formBasico.nome,
            descricao: formBasico.descricao,
            status: formBasico.status,
            empreendimento_id: formBasico.empreendimento_id,
            anexo: formBasico.anexo
        });
    }, [formBasico]);

    useEffect(() => {
        if (formBasico.nome || formBasico.empreendimento_id || formBasico.anexo) {
            setShowButtonSave(true);
        };
    }, [formBasico]);

    return (
        <>
            <div className="body-content">
                <div className="form-create-group">
                    <div className="form-create-group-header">
                        <h3>Cadastrar Modelo de Contrato</h3>
                    </div>
                </div>
                <div className="form-group-create">
                    <Container>
                        <ModeloContratoForm
                            initialRightValues={[]}
                            Data={formBasico}
                            onInputChange={(field: string, value: string) => handleChange(`${field}`, value)}
                        />
                    </Container>
                </div>
            </div>
        </>
    );
}

export default CreateModeloContrato;
