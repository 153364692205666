import React, { ReactNode, createContext, useContext, useState, useEffect } from 'react';
import { url } from '../environments/environments-develop';
import api from '../services/api';
import { showToast } from '../components/ToastComponent';
import { PessoaProps } from './PessoaContext';

export interface UsuarioData {
    nome: string | null;
    email: string | null;
    username: string | null;
    password: string | null;
    password_confirmation: string | null;
    status: string | null;
    foto: string | null;
    pessoa: PessoaProps;
    user_grupos: []
}

interface UsuarioContextProps {
    usuarioData: UsuarioData | null;
    updateUsuarioData: (newData: any) => void;
    usuarioEditId: string;
    setUsuarioEditId: React.Dispatch<React.SetStateAction<string>>;
}

const UsuarioContext = createContext<UsuarioContextProps | undefined>(undefined);

export const useUsuarioContext = () => {
    return useContext(UsuarioContext);
};

interface AppProviderProps {
    children: ReactNode;
}

async function fetchUsuario(id: any): Promise<UsuarioData | null> {
    try {
        const retorno = await api.get(`${url}/api/v1/admin/core/user/${id}`);
        return retorno.data as UsuarioData;
    } catch (error) {
        console.error('Erro na chamada da API:', error);
        return null;
    }
}

export const UsuarioProvider: React.FC<AppProviderProps> = ({ children }) => {
    const [usuarioEditId, setUsuarioEditId] = useState("");
    const [usuarioData, setUsuarioData] = useState<UsuarioData | null>(null);
    useEffect(() => {
        async function fetchData() {
            try {
                if (usuarioEditId) {
                    const usuario = await fetchUsuario(usuarioEditId);

                    if (usuario) {
                        setUsuarioData(usuario);
                    }
                }
            } catch (error) {
                showToast('error', 'Erro ao obter dados:' + error);
            }

        }
        fetchData();
    }, [usuarioEditId]);
    const updateUsuarioData = (newData: UsuarioData) => {
        setUsuarioData(newData);
    };

    return (
        <UsuarioContext.Provider value={{ usuarioData, updateUsuarioData, usuarioEditId, setUsuarioEditId }}>
            {children}
        </UsuarioContext.Provider>
    );
};