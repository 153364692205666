import React, { useEffect, useRef, useState } from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import { AlertService } from './AlertService';
import { url } from '../environments/environments-develop';
import api from '../services/api';
import { InputNumber } from 'primereact/inputnumber';
import TokenService from '../services/Auth/token.service';
import { FileUpload } from 'primereact/fileupload';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';

interface ModalBaixaManualFormProps {
    formBasico: any;
    handleChange: (field: string, value: any, paymentIndex?: number, paymentField?: string) => void;
    handleSaveButton: () => Promise<void>;
    handlePagamentoAtualizado: (value: any) => void;
    handleTaxasJuros: (value: any) => void;
}

interface Anexo {
    nome: string;
    alias: string;
    extensao: string;
    conteudo: string;
    url?: string;
    objectURL?: string;
}

const ModalBaixaManualForm: React.FC<ModalBaixaManualFormProps> = ({ formBasico, handleChange, handleSaveButton, handlePagamentoAtualizado, handleTaxasJuros }) => {
    const [listContaFinanceira, setListContaFinanceira] = useState([]);
    const [anexo, setAnexos] = useState<Anexo[]>([]);
    const [valorPagamento, setValor] = useState<any>(null);
    const fileUploadRef = useRef<FileUpload>(null);
    const toast = useRef<Toast>(null);

    const fetchContaFinanceira = async () => {
        try {
            const response = await api.get(`${url}/api/v1/admin/financeiro/conta-financeira`);
            setListContaFinanceira(response.data.data);
        } catch (error) {
            AlertService.error('Error', 'Ocorreu um erro ao buscar a lista de permissões. Erro:' + String(error));
        }
    };

    useEffect(() => {
        fetchContaFinanceira();
    }, []);

    const handleFileSelect = (e: { files: File[] }) => {
        e.files.forEach(file => {
            const isDuplicate = anexo.some(anexoExistente => anexoExistente.nome === file.name);
            if (!isDuplicate) {
                const reader = new FileReader();
                reader.onload = () => {
                    const conteudo = reader.result as string;
                    const novoAnexo: Anexo = {
                        nome: file.name,
                        alias: file.name,
                        extensao: file.name.split('.').pop() || '',
                        conteudo: conteudo,
                    };
                    setAnexos(anexosAnteriores => [...anexosAnteriores, novoAnexo]);
                };
                reader.readAsDataURL(file);
            }
        });
    };

    const emptyTemplate = () => (
        <div className="flex align-items-center flex-column">
            <i className="pi pi-image mt-3 p-5" style={{ fontSize: '5em', borderRadius: '50%', backgroundColor: 'var(--surface-b)', color: 'var(--surface-400)' }}></i>
            <span style={{ fontSize: '1.3em', color: 'var(--surface-600)' }}>Arraste e solte a imagem aqui</span>
        </div>
    );

    const chooseOptions = {
        icon: 'pi pi-fw pi-images',
        iconOnly: true,
        className: 'custom-choose-btn p-button-rounded p-button-outlined',
        style: { borderRadius: '50%' }
    };

    const cancelOptions = {
        icon: 'pi pi-fw pi-times',
        iconOnly: true,
        className: 'custom-cancel-btn p-button-danger p-button-rounded p-button-outlined',
        style: { borderRadius: '50%' }
    };

    const headerTemplate = (options: { className: any; chooseButton: any; cancelButton: any; }) => {
        const { className, chooseButton, cancelButton } = options;
        return (
            <div className={className} style={{ backgroundColor: 'transparent', display: 'flex', alignItems: 'center' }}>
                {chooseButton}
                {cancelButton}
            </div>
        );
    };

    const onTemplateRemove = (file: File, callback: Function) => {
        setAnexos(anexosAnteriores => anexosAnteriores.filter(anexo => anexo.nome !== file.name));
        if (toast.current) {
            toast.current.show({ severity: 'info', summary: 'Arquivo removido', detail: file.name });
        }
        callback();
    };

    const itemTemplate = (file: any, props: any) => (
        <div className="flex flex-wrap" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <div className="d-flex justify-content-left" style={{ width: '40%' }}>
                <img style={{ marginRight: '15px' }} alt={file.name} role="presentation" src={file.objectURL} width={100} />
                <span className="flex text-left flex-column  ml-3">
                    <label className="d-flex justify-content-left">{file.alias || file.name}</label>
                    <br />
                    <small className="d-flex justify-content-left">{file.size} Bytes</small>
                    <br />
                    <small className="d-flex justify-content-left">{new Date().toLocaleDateString()}</small>
                </span>
            </div>
            <Button
                type="button"
                icon="pi pi-times"
                style={{ borderRadius: '50%', alignItems: 'center' }}
                className="p-button-outlined p-button-rounded p-button-danger ml-auto"
                onClick={() => onTemplateRemove(file, props.onRemove)}
            />
        </div>
    );

    useEffect(() => {
        if (anexo.length > 0) {
            handleChange('anexo', anexo);
        }
    }, [anexo]);
    useEffect(() => {
        formBasico.pagamentos.map((pagamento: any, index: number) => {
            if (pagamento.valor) {
                let valorNominal = pagamento.valor ?? null;
                valorNominal = typeof valorNominal === 'string'
                    ? (valorNominal ? parseFloat(valorNominal.replace(',', '.')) : null)
                    : (valorNominal ? valorNominal : null);
                setValor(valorNominal);
            }
        })
    }, [formBasico.pagamentos]);
    useEffect(() => {
        formBasico.pagamentos.map((pagamento: any, index: number) => {
            let valorNominal = pagamento.valor ?? null;
            valorNominal = typeof valorNominal === 'string'
                ? (valorNominal ? parseFloat(valorNominal.replace(',', '.')) : null)
                : (valorNominal ? valorNominal : null);
            handlePagamentoAtualizado(valorNominal);

            if (new Date(formBasico?.lancamento?.data_vencimento) < new Date(formBasico?.data_baixa)) {
                const dataVencimento = new Date(formBasico?.lancamento?.data_vencimento);
                const dataPagamento = new Date(formBasico?.data_baixa);
                const diferencaDias = Math.ceil((dataPagamento.getTime() - dataVencimento.getTime()) / (1000 * 60 * 60 * 24));

                const empreendimento = TokenService.getEmpreendimentoId();
                const multa = parseFloat(empreendimento?.config_carteira?.multa);
                const juros = parseFloat(empreendimento?.config_carteira?.juros);

                const multaCalculada = ((multa ? multa : 0) / 100) * valorNominal;
                const jurosCalculada = (((juros ? juros : 0) / 30) / 100) * valorNominal;
                const jurosAtualizado = jurosCalculada * diferencaDias;

                let valorJurosMultas = (jurosAtualizado + multaCalculada).toFixed(2);
                handleTaxasJuros(valorJurosMultas);

                valorNominal = (parseFloat(valorNominal) + jurosAtualizado + multaCalculada).toFixed(2); // Atualizando valor de pagamento com juros e multa

                setValor(valorNominal);
                handleChange('valor', valorNominal, index, 'valor')
                handlePagamentoAtualizado(valorNominal);
            } else {
                handleTaxasJuros("0,00");
                handlePagamentoAtualizado(valorNominal);
            }
        })
    }, [formBasico?.lancamento?.data_vencimento, formBasico?.data_baixa]);
    return (
        <Form onSubmit={handleSaveButton}>
            <Row className="group-form">
                {formBasico.pagamentos.map((pagamento: any, index: number) => {

                    return (
                        <React.Fragment key={index}>
                            <Form.Group as={Col} controlId="formGridState">
                                <Form.Label>Meio de Pagamento</Form.Label>
                                <Form.Select
                                    value={pagamento.meio_pagamento}
                                    onChange={(e) => handleChange('meio_pagamento', e.target.value, index, 'meio_pagamento')}
                                >
                                    <option>Selecione...</option>
                                    <option value="1">Dinheiro</option>
                                    <option value="3">Transferência Bancária</option>
                                    <option value="12">PIX</option>
                                    <option value="7">Depósito</option>
                                    <option value="2">Cheque</option>
                                    <option value="4">Outros</option>
                                    <option value="8">Boleto</option>
                                    <option value="0">Cartão Débito</option>
                                    <option value="10">Cartão Crédito</option>
                                </Form.Select>
                            </Form.Group>
                            <Col>
                                <Form.Label>Conta Financeira</Form.Label>
                                <Form.Select
                                    value={pagamento.conta_financeiro_id}
                                    onChange={(e) => handleChange('conta_financeiro_id', e.target.value, index, 'conta_financeiro_id')}
                                >
                                    <option>Selecione...</option>
                                    {listContaFinanceira?.map((item: any) => (
                                        <option value={item.id} key={item.id}>{item.nome}</option>
                                    ))}
                                </Form.Select>
                            </Col>
                            <Col>
                                <Form.Label>Valor Pago</Form.Label>
                                <InputNumber
                                    invalid={!valorPagamento}
                                    value={typeof valorPagamento === 'string'
                                        ? (valorPagamento ? parseFloat(valorPagamento.replace(',', '.')) : null)
                                        : (valorPagamento ? valorPagamento : null)}
                                    onChange={(e) => handleChange('valor', e.value, index, 'valor')}
                                    minFractionDigits={2}
                                    maxFractionDigits={24}
                                    style={{ width: '100%', height: '38px' }}
                                    locale="pt-BR"
                                />
                                {new Date(formBasico?.lancamento?.data_vencimento) < new Date(formBasico.data_baixa) && (<span style={{ color: 'red', fontSize: '11px' }}>Valor do Lançamento sofreu alteração devido aos dias em atraso</span>)}
                            </Col>
                        </React.Fragment>
                    );
                })}
                <Col md="3">
                    <Form.Label>Data do Pagamento</Form.Label>
                    <Form.Control
                        type="date"
                        value={formBasico.data_baixa}
                        onChange={(e) => handleChange('data_baixa', e.target.value)}
                    />
                </Col>
            </Row>
            <Row className="group-form">
                <Col>
                    <Form.Label>Justificativa</Form.Label>
                    <Form.Control
                        as="textarea"
                        rows={3}
                        value={formBasico?.lancamento ? formBasico?.lancamento?.justificativa : formBasico?.justificativa}
                        onChange={(e) => handleChange('justificativa', e.target.value)}
                    />
                </Col>
                <Col>
                    <Form.Label>Descrição do Caixa</Form.Label>
                    <Form.Control
                        as="textarea"
                        placeholder="PGTO REF [Serviço/Produto] [VENC 99/99/9999] [PARC 1/9]"
                        rows={3}
                        value={formBasico?.lancamento ? formBasico?.lancamento?.descricao_caixa : formBasico.descricao_caixa}
                        onChange={(e) => handleChange('descricao_caixa', e.target.value)}
                    />
                </Col>
            </Row>
            <Row className="group-form">
                <Form.Group as={Col} controlId="documentos">
                    <Form.Label>Documentos</Form.Label>
                    <FileUpload ref={fileUploadRef} name="demo[]" multiple
                        onSelect={handleFileSelect}
                        onClear={() => setAnexos([])}
                        headerTemplate={headerTemplate}
                        itemTemplate={itemTemplate}
                        emptyTemplate={emptyTemplate}
                        chooseOptions={chooseOptions}
                        cancelOptions={cancelOptions}
                    />
                </Form.Group>
            </Row>
        </Form>
    );
};

export default ModalBaixaManualForm;
