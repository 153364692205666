import { Col, Form, InputGroup, Row } from "react-bootstrap";
interface PropsData {
    Data: {
        num_parcela?: string;
        desconto?: string;
        premiacao?: string;
        comiss_corretor?: string;
        tipo_reaj?: number;
    };
    initialRightValues: any;
    onInputChange: (field: string, value: any) => void;
}
function TabelaPrecoForm(props: PropsData) {
    const { num_parcela, desconto, premiacao, comiss_corretor, tipo_reaj } = props.Data || {};
    const handleChange = (field: string, value: any) => {
        props.onInputChange(field, value);
    };

    return (
        <>
            <Form.Group className="forms" controlId="validationCustomUsername">
                <Row className="mb-3">
                    <Col md="2">
                        <Form.Label className="mb-0">N° Parcelas</Form.Label>
                        <InputGroup hasValidation>
                            <Form.Control
                                required
                                value={num_parcela || ''}
                                onChange={(e) => handleChange("num_parcela", e.target.value)}
                                onBlur={(e) => handleChange("num_parcela", e.target.value)}
                            />
                            <Form.Control.Feedback type="invalid">
                                Este campo é obrigatório.
                            </Form.Control.Feedback>
                        </InputGroup>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col md="4">
                        <Form.Label className="mb-0">Percentual de Desconto / Taxa de Juros</Form.Label>
                        <Form.Control value={desconto || ''} onChange={(e) => handleChange("desconto", e.target.value)} />
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col md="2">
                        <Form.Label className="mb-0">Premiação</Form.Label>
                        <Form.Control value={premiacao || ''} onChange={(e) => handleChange("premiacao", e.target.value)} />
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col md="3">
                        <Form.Label className="mb-0">Comissão do Corretor</Form.Label>
                        <Form.Control value={comiss_corretor || ''} onChange={(e) => handleChange("comiss_corretor", e.target.value)} />
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col md="2">
                        <Form.Label className="mb-0">Tipo de Reajuste</Form.Label>
                        <Form.Select value={tipo_reaj} onChange={(e) => handleChange('tipo_reaj', e.target.value)}>
                            <option>Selecione...</option>
                            <option value={1}>Sem Reajuste</option>
                            <option value={2}>IGPM</option>
                            <option value={3}>INCC</option>
                        </Form.Select>
                    </Col>
                </Row>
            </Form.Group>
        </>
    );
};
export default TabelaPrecoForm;
