// JazigoModel.ts

  export interface IJazigo {
    id: number | null;
    codigo: string | null;
    quadra: string | null;
    ala: string | null;
    jazigo: string | null;
    tipo: number | null;
    tipo_jazigos_id: number | null;
    empreendimento_id: number | null;
    filial_id: number | null;
  }

  export class JazigoModel implements IJazigo {
    id: number | null = null;
    codigo: string | null = null;
    quadra: string | null = null;
    ala: string | null = null;
    jazigo: string | null = null;
    tipo: number | null = null;
    tipo_jazigos_id: number | null = null;
    empreendimento_id: number | null = null;
    filial_id: number | null = null;
    
    deserialize(input: any): this {
      Object.assign(this, input);
      return this;
    }
  }
