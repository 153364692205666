import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import noProfile from '../../../assets/images/profile.png';

interface FotoPerfilFormProps {
  width: number;
  height: number;
  texto: string;
  foto: string | null | undefined;
}

function FotoPerfilForm({ width, height, texto, foto }: FotoPerfilFormProps) {
  const [profileImage, setProfileImage] = useState<string | null>(
    foto || String(noProfile)
  );

  useEffect(() => {
    if (foto) {
      setProfileImage(foto);
    }
  }, [foto]);

  const handleImageUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        if (typeof reader.result === 'string') {
          setProfileImage(reader.result);
        }
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <>
      <Form.Group className="mb-3 " style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
        <img
          alt="foto de perfil"
          className="imgNoProfile"
          style={{ width: width, height: height }}
          src={profileImage || ''}
        />
        <Form.Control
          id="inputFile"
          type="file"
          onChange={handleImageUpload}
          accept="image/*"
          style={{ display: 'none' }}
        />
        <Button as="label" className="button-file" htmlFor="inputFile">
          {texto}
        </Button>
      </Form.Group>
    </>
  );
}

export default FotoPerfilForm;
