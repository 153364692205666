import React, { ReactNode, createContext, useContext, useState, useEffect } from 'react';
import { EnderecoData } from './EnderecoContext';
import { TelefoneData } from './TelefoneContext';

export interface PessoaProps {
    id: string | null;
    nome: string | null;
    email: string | null;
    cpf_cnpj: string | null;
    sexo: string | null;
    razao_social: string | null;
    inscricao_municipal: string | null;
    inscricao_estadual: string | null;
    filiacao_mae: string | null;
    filiacao_pai: string | null;
    rg: string | null;
    estado_civil: string | null;
    regime_uniao: string | null;
    data_nascimento: string | null;
    data_fundacao: string | null;
    enderecos: EnderecoData[];
    telefones: TelefoneData[];
}

interface PessoaContextProps {
    pessoaData: PessoaProps | null;
    updatePessoaData: (newData: PessoaProps) => void;
};

const PessoaContext = createContext<PessoaContextProps | undefined>(undefined);

interface AppProviderProps {
    children: ReactNode;
}

export const PessoaProvider: React.FC<AppProviderProps> = ({ children }) => {
    const [pessoaData, setPessoaData] = useState<PessoaProps | null>(null);

    const updatePessoaData = (newData: PessoaProps) => {
        setPessoaData(newData);
    };

    return (
        <PessoaContext.Provider value={{ pessoaData, updatePessoaData }}>
            {children}
        </PessoaContext.Provider>
    );
};

export const usePessoaContext = () => {
    return useContext(PessoaContext);
};
