import { useAppContext } from '../../../contexts/AppContext';
import { useAppContext as useResponseContext } from '../../../contexts/ResponseContext';
import { useEffect, useState } from 'react';
import { getStatusColorCard, loadData, setSessionEditData } from './Service/ImovelService';
import { Button } from 'primereact/button';
import Paginacao from './Components/Paginacao';
import { Column } from 'primereact/column';
import { Skeleton } from 'primereact/skeleton';
import { DataTable } from 'primereact/datatable';
import Legenda from './Components/Legenda';
import FiltroAvancadoModal from './Components/ModalFiltroAvancado';
import { Col, Container, Form, NavLink, Row } from 'react-bootstrap';
import { useSidebarContext } from '../../../contexts/SidebarContext';
import ModalMemorialSheet from './Components/ModalMemorialSheet';
import iconMenuTresPonto from '../../../../assets/images/icon-menu-tres-pontos.png';
import iconFiltroAvancado from '../../../../assets/images/icon-filtro-avancado.png';
import ModalImovelGrupo from './Components/ModalImovelGrupo';
import { actionTemplate } from './Components/actionTemplate';
import ModalEmitirMemorialSheet from './Components/ModalEmitirMemorialSheet';
import { FaSort, FaSortDown, FaSortUp } from 'react-icons/fa';

function Imoveis() {
  const { showSubSidebar, setItemsBreadsCrumbs } = useAppContext();
  const [listFiltros, setListFiltros] = useState<any>({});
  const [formBasico, setFormBasico] = useState<any>({});
  const [currentPage, setCurrentPage] = useState(0);
  const [nodes, setNodes] = useState<any | null>(null);
  const { titles, updateTitles, pagination, updatePagination } = useResponseContext();
  const { setContextItemsButtonMenu } = useAppContext();
  const [show, setShow] = useState(false);
  const [showModalImovelGrupo, setShowModalImovelGrupo] = useState(false);
  const [showModalEmitirMemorialSheet, setShowModalEmitirMemorialSheet] = useState(false);
  const { setUrl_button_create } = useAppContext();
  const [selectedRow, setSelectedRow] = useState<any>(null);
  const [showModalFiltro, setShowModalFiltro] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [itemsPerPage, setItemsPerPage] = useState<number>(8);
  const url_redirect_edit = "/imovel/update";
  const [sortOptions, setSortOptions] = useState<{ field: string, order: number } | null>(null);
  const [showModal, setShowModal] = useState(false);
  const { isVisible, updateVisibility } = useSidebarContext();
  const [showModalMemorialSheet, setShowModalMemorialSheet] = useState(false);

  useEffect(() => {
    loadData(listFiltros, itemsPerPage, currentPage, setLoading, setNodes, updateTitles, updatePagination, setShowModalFiltro, sortOptions);
  }, [currentPage, sortOptions, itemsPerPage]);

  const statusTemplate = (value: any) => {
    const statusColor = getStatusColorCard(value?.status_name);

    return (
      <div
        style={{
          backgroundColor: statusColor,
          margin: "3px 8px 12px 3px",
          border: `2px solid ${statusColor}`,
          borderRadius: "12px",
          boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.32)",
          padding: "8px",
        }}
      ></div>
    );
  };

  useEffect(() => {
    const commonStyle = {
      width: "30px",
      height: "30px",
      top: "-2px",
      color: "#fff",
    };
    const items = [
      {
        label: "Criar Imóvel",
        icon: "pi pi-user-plus",
        name: "Criar Imóvel",
        style: { ...commonStyle, backgroundColor: "#19c022", display: "flex" },
        command: () => {
          window.location.href = "/imovel/store";
        },
        tooltip: "Criar Imóvel",
      },
      {
        label: "Criar Imóvel em Grupos",
        icon: "pi pi-users",
        style: { ...commonStyle, backgroundColor: "#007bff", display: "flex" },
        command: () => {
          setShowModalImovelGrupo(true);
        },
        tooltip: "Criar Imóvel em Grupos",
      },
      {
        label: "Gerar Memorial Descritivo",
        icon: "pi pi-file",
        style: { ...commonStyle, backgroundColor: "#494949", display: "flex" },
        command: () => {
          setShowModalEmitirMemorialSheet(true);
        },
        tooltip: "Gerar Memorial Descritivo",
      },
    ];
    setContextItemsButtonMenu(items);
    setItemsBreadsCrumbs([
      {
        label: 'Empreendimento',
        url: '/empreendimento',
      },
      {
        label: 'Lista de Imóveis',
        url: '/imovel',
      },
    ]);
  }, []);

  const handleChange = (field: string, value: string) => {
    setFormBasico((prevGrupoData: any) => {
      return {
        ...prevGrupoData,
        [field]: value
      };
    });
  };

  const handleChangeList = (field: string, value: string) => {
    setListFiltros((prevGrupoData: any) => {
      return {
        ...prevGrupoData,
        [field]: value
      };
    });
  };
  const handleClearList = () => {
    setListFiltros(null);
    setFormBasico(null);
  };

  const toggleVisibility = () => {
    const newValue = !isVisible;
    updateVisibility(newValue);
  };


  const handleFilter = () => {
    setShowModal(false);
    if (listFiltros?.identificador1) {
      handleChangeList('identificador1', listFiltros?.identificador1);
    }
    if (listFiltros?.identificador2) {
      handleChangeList('identificador2', listFiltros?.identificador2);
    }
    if (status) {
      handleChangeList('status', status);
    }
    []
  };

  const handleSort = (field: string) => {
    let order = 1;
    if (sortOptions && sortOptions.field === field) {
      order = sortOptions.order === 1 ? -1 : sortOptions.order === -1 ? 0 : 1;
    }
    setSortOptions({ field, order });
  };

  const getSortIcon = (field: string) => {
    if (sortOptions?.field === field) {
      return sortOptions.order === 1 ? <FaSortUp /> : sortOptions.order === -1 ? <FaSortDown /> : <FaSort style={{ color: '#b8b8b8' }} />;
    }
    return <FaSort style={{ color: '#b8b8b8' }} />;
  };

  return (
    <div className="body-content">
      <div
        className={`${showSubSidebar ? "container-section-header" : "container-section-header-none"}`}
      >
        <div className="contentSearch">
          <div className="contentSearch-header">
            <NavLink onClick={toggleVisibility}>
              <img
                style={{ width: '23px' }}
                src={iconMenuTresPonto}
              />
            </NavLink>
            <Col className='text-center' style={{ fontSize: '20px', color: '#494949' }}>Imóveis</Col>
            <>
              <a type='button' onClick={() => setShowModalMemorialSheet(true)} >
                <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="32" height="32" viewBox="0 0 32 32" fill="none">
                  <path opacity="0.7" d="M0 0H32V32H0V0Z" fill="url(#pattern0)" />
                  <defs>
                    <pattern id="pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
                      <use xlinkHref="#image0_260_103" transform="scale(0.0104167)" />
                    </pattern>
                    <image id="image0_260_103" width="96" height="96" xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAABgCAYAAADimHc4AAAACXBIWXMAAAsTAAALEwEAmpwYAAAElElEQVR4nO2cS4gdRRSGv7wmJmYiGomKgrqSmAQXLoxLI0giiBpUFJEgIogSiSgSoosGN6IgiRglGwWXISIuxJUiYhaiC1+5BkUhPkMETVTwgeZIQw00h5q53XdudZ2qrh9q09Wnq+v/qrvrdS8UFRUVFRUVFRUVFRUtXpuB/cDnwB+AGEtVrpBXAi8C/xkwWYYGoTb/HQPGylAhvGTAUBkqhM2JvHYkVwj7DZgoi0xPk7COGjBQhvwk/G7APBnykzCuUrEluUPIDYCkBiFHAJIShFwBSCoQcgYgKUDIHYBYhzAEAGIZwlAAiFUIQwIgFiEMDYBYgzBEAGIJwlABiBUIQwYgGJD1G5QCoAAIqvIERJZ1ANnXz/wN5l4/8zeYe/3M32Dq9bO+11Mip2BKaa+n5AYgxb2ekhOAVPd6Sg4AUt/rKakDyGGvp6QMIJe9npIqgJz2ekqKAMYVeLcn5g3Pea97ztvZogIyYap7bbcBFwPLgHXAFuAZ4NecAHwJLFcxN3jOu16dswL4OhCAR8fU6VLg01wA1OleFbMEONbIP+qONXV/ywpIx/RKy3pdDpzOBcA3wIyK29XIf9AzsDseCMAGFX8T8BZwCNik8p7NBUCdHlBxa4HfXCubVXkPdaiAdEi/qNjLgH8a+V+o/GumbP5oCn5PbMB3wFkq9gDwvDq2CvghEIBvVey1nnPWN/LPmbL5F0zJ84kNeFjFXglcoY490vERlg7pb9UhmHGzttNs5b2a39WAn4DVC1zrbOBEQAAC3Kji6xb/ZqrmT2LAYwtc6/EW8Yst/2P3kcfzvn8NOJOS+ZMYcNK1dK01wM89ABDX4s+dpz7bge9TMX8SA75ygyxaDrxCABDgR+B2z/hj7rX0WQrmT2LAPQtca2ePAMSlT4AdHhD1QOyUdfOZ4ObqeZf5tMz1xfsEII25oQvVtZ+ybn5XA+7wfPiuVsfuDAjgPeBgI+nR9rvq2ldZN5+Oj/pSFfuqZ25m6ZiJsMUAeELFvqzyz7iB4JxmrZtPh8rf7PnQ/QX8CZyv8m4JBOCQir3VM3Pb1CXWzadlxT/0fOT2NvL3qLwlLqYNgHFqxtaLR+epcnYDR4DDnsm4u6yb3xbANhWz3M3LzOUf93yctwcAIG7+qY3qe/zIuvm0MP99T8wOz3n160DrSAAAAjw5T/9/Tivdt8m8+W0AXOeJedtzXn1Ma2sgAAJ8ANzn1gdmXdro1ilGqZg/tEX5Y8BFHbyp+vhh3lC2pYw6tvyqr78322fAHDHY8vU1gj0JddftXwMmicGWr1OwJ+GAAaPEuPlBIczM07NJOY0CmB8cwguZvI5GAc0PCgHXl37OLWik2EUd9WB+cAgLadxNpaQqxT/wyAVANcWnrlcIOQCoArz6eoOQOoAq4PenFwgpA6gCmt8bhFQBVD2Y3wuEFAFUPZofHEJqAKoI5geFEHtwFlqxyze/fhBascs3v34QWrHLN79+EFqxyze/fhBascs3v34QWrHLN79+EFqxyze/fhBascsfvKQAiKsCILIKgMgqACKrAIisAiCyCgAyBvA/T9re9EmJb+kAAAAASUVORK5CYII=" />
                  </defs>
                </svg>
              </a>
              <ModalMemorialSheet
                showModal={showModalMemorialSheet}
                onHide={() => setShowModalMemorialSheet(false)}
              />
            </>
          </div>
          <div style={{ opacity: '34%' }} className="contentTraçoBodyHeader"></div>
          <div style={{
            display: "flex",
            justifyContent: 'space-between'
          }}>
            <Row style={{ margin: '10px' }}>
              <Col md={4}>
                <Form.Label>Quadra</Form.Label>
                <Form.Control
                  value={listFiltros?.identificador1 || ''}
                  onChange={(e) => setListFiltros({ ...listFiltros, identificador1: e.target.value })}
                />
              </Col>
              <Col md={4}>
                <Form.Label>Lote</Form.Label>
                <Form.Control
                  value={listFiltros?.identificador2 || ''}
                  onChange={(e) => setListFiltros({ ...listFiltros, identificador2: e.target.value })}
                />
              </Col>
            </Row>
            <Row style={{ marginTop: '40px', display: 'flex', justifyContent: 'right' }}>
              <Col style={{ display: 'flex', justifyContent: 'right' }} className="mb-2">
                <Button
                  style={{ marginRight: '5px', height: '30px', borderRadius: '8px', fontSize: '14px' }}
                  type="button"
                  rounded outlined severity="secondary"
                  onClick={() => setShowModalFiltro(true)}
                >
                  <img style={{ width: '25px', height: '35px' }} src={iconFiltroAvancado} />
                </Button>
                <Button
                  style={{ marginRight: '5px', height: '30px', borderRadius: '8px', fontSize: '14px' }}
                  type="button"
                  severity="success"
                  icon="pi pi-filter"
                  rounded
                  onClick={() => loadData(listFiltros, itemsPerPage, currentPage, setLoading, setNodes, updateTitles, updatePagination, setShowModalFiltro)}
                >
                  <p className="d-inline-block" style={{ marginLeft: '5px', marginTop: '13px' }}>Pesquisar</p>
                </Button>
                <Button
                  style={{ height: '30px', borderRadius: '8px', fontSize: '14px', marginRight: '15px' }}
                  type="button"
                  severity="danger"
                  icon="pi pi-delete-left"
                  rounded
                  onClick={() => {
                    setListFiltros({});
                    loadData({}, currentPage, itemsPerPage, setLoading, setNodes, updateTitles, updatePagination, setShowModalFiltro);
                  }}
                >
                  <p className="d-inline-block" style={{ marginLeft: '5px', marginTop: '13px' }}>Limpar</p>
                </Button>
              </Col>
            </Row>
          </div>
          <FiltroAvancadoModal
            showModal={showModalFiltro}
            onHide={() => setShowModalFiltro(false)}
            handleClose={() => setShowModalFiltro(false)}
            listFiltros={listFiltros}
            setListFiltros={setListFiltros}
            loadData={loadData}
            setLoading={setLoading}
            currentPage={currentPage}
            setNodes={setNodes}
            updateTitles={updateTitles}
            updatePagination={updatePagination}
            setShowModalFiltro={setShowModalFiltro}
            itemsPerPage={itemsPerPage}
          />
          <ModalImovelGrupo
            showModal={showModalImovelGrupo}
            onHide={() => setShowModalImovelGrupo(false)}
          />
          <ModalEmitirMemorialSheet
            showModal={showModalEmitirMemorialSheet}
            onHide={() => setShowModalEmitirMemorialSheet(false)}
          />
        </div >
      </div>
      <div
        className={`${showSubSidebar ? "container-section-body" : "container-section-body-none"}`}
      >
        <div className="card" style={{ paddingRight: '12px' }}>
          <Legenda />
          <DataTable
            lazy
            size="small"
            stripedRows
            value={nodes ? nodes : []}
            tableStyle={{ minWidth: '50rem' }}
            emptyMessage={isLoading ? (<Skeleton />) : (<span style={{ display: 'flex', justifyContent: 'center', fontStyle: 'italic' }}>Nenhum item encontrado.</span>)}
          >
            {nodes && (
              <Column
                style={{ width: '5px' }}
                body={(e) => statusTemplate(e)}
                headerClassName=""
              />
            )}
            <Column
              style={{ textAlign: 'right' }}
              key={'id'}
              field={'id'}
              headerClassName="righted-header"
              header={<div className="righted-content" onClick={() => handleSort('id')}>Id{getSortIcon('id')}</div>}
              body={isLoading && (<Skeleton />)}
            />
            <Column
              style={{ textAlign: 'right' }}
              key={'unidade'}
              field={'unidade'}
              headerClassName="righted-header"
              header={<div className="righted-content" onClick={() => handleSort('vendas.imoveis.unidade')}>Unidade{getSortIcon('vendas.imoveis.unidade')}</div>}
              body={isLoading && (<Skeleton />)}
            />
            <Column
              style={{ textAlign: 'right' }}
              key={'area_label'}
              field={'area_label'}
              headerClassName="righted-header"
              header={<div className="righted-content" onClick={() => handleSort('vendas.imoveis.area')}>Área{getSortIcon('vendas.imoveis.area')}</div>}
              body={isLoading && (<Skeleton />)}
            />
            <Column
              style={{ textAlign: 'left' }}
              key={'status_name'}
              field={'status_name'}
              headerClassName="lefted-header"
              header={<div className="lefted-content" onClick={() => handleSort('vendas.imoveis.status')}>Status{getSortIcon('vendas.imoveis.status')}</div>}
              body={isLoading && (<Skeleton />)}
            />
            <Column
              style={{ textAlign: 'right' }}
              key={'imo_preco_format'}
              field={'imo_preco_format'}
              headerClassName="righted-header"
              header={<div className="righted-content" onClick={() => handleSort('vendas.imoveis.imo_preco')}>Preço{getSortIcon('vendas.imoveis.imo_preco')}</div>}
              body={isLoading && (<Skeleton />)}
            />
            <Column
              style={{ textAlign: 'right' }}
              key={'imo_intermed_format'}
              field={'imo_intermed_format'}
              headerClassName="righted-header"
              header={<div className="righted-content" onClick={() => handleSort('vendas.imoveis.imo_intermed')}>Interm.{getSortIcon('vendas.imoveis.imo_intermed')}</div>}
              body={isLoading && (<Skeleton />)}
            />
            <Column
              style={{ textAlign: 'right' }}
              key={'qtd_max_parcelas'}
              field={'qtd_max_parcelas'}
              headerClassName="righted-header"
              header={<div className="righted-content" >Qtd. Parcelas</div>}
              body={isLoading && (<Skeleton />)}
            />
            <Column
              style={{ textAlign: 'right' }}
              key={'valor_parcelas_calc'}
              field={'valor_parcelas_calc'}
              headerClassName="righted-header"
              header={<div className="righted-content">Preço Parcelas</div>}
              body={isLoading && (<Skeleton />)}
            />
            <Column
              style={{ textAlign: 'left' }}
              key={'classificacao_preco_rotulo'}
              field={'classificacao_preco_rotulo'}
              headerClassName="lefted-header"
              header={<div className="lefted-content">Classif.Preço</div>}
              body={isLoading && (<Skeleton />)}
            />
            <Column
              style={{ textAlign: 'left' }}
              key={'construcao_label'}
              field={'construcao_label'}
              headerClassName="lefted-header"
              header={<div className="lefted-content" onClick={() => handleSort('vendas.imoveis.construcao')}>Construção{getSortIcon('vendas.imoveis.construcao')}</div>}
              body={isLoading && (<Skeleton />)}
            />
            <Column
              style={{ textAlign: 'right' }}
              body={(e) => actionTemplate(e,
                url_redirect_edit,
                setSelectedRow
              )}
              headerClassName="righted-header"
              header={<div className="righted-content">Menu</div>}
            />
          </DataTable>
          <Paginacao
            totalPages={pagination}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            itemsPerPage={itemsPerPage}
            setItemsPorPagina={setItemsPerPage}
          />
        </div>
      </div>
    </div>
  );
}

export default Imoveis;
