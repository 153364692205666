import { useEffect, useState } from "react";
import { useAppContext } from '../../../contexts/AppContext';
import { AlertService } from '../../../components/AlertService';
import TokenService from '../../../services/Auth/token.service';
import { Col } from 'react-bootstrap';
import TableCentroCusto from "./Components/TableCentroCusto";

function CentroCusto() {
  const { showSubSidebar, setItemsBreadsCrumbs } = useAppContext();
  const [listFiltros, setListFiltros] = useState<any>({});
  const [filtros, setFiltros] = useState<string>();
  const [formBasico, setFormBasico] = useState<any>({});
  useEffect(() => {
    setItemsBreadsCrumbs([
      {
        label: 'Financeiro',
        url: '/financeiro',
      },
      {
        label: 'Lista de Centros de Custos',
        url: '/financeiro/centro-custo',
      }
    ]);
  }, []);
  async function montarFiltro(): Promise<void> {
    try {
      const consulta: any = {
        consulta: {
          order: "id;desc",
          filtro: {
            "financeiro.centro_custos.nome": listFiltros ? listFiltros.nome : null,
            "financeiro.centro_custos.tipo": listFiltros ? listFiltros.tipo : null,
            "empreendimento_id": TokenService.getEmpreendimentoId() ? TokenService.getEmpreendimentoId().id : null
          }
        }
      };

      const queryParams: any = {};

      const camposEnviar = ['consulta'];

      camposEnviar.forEach((key: any) => {
        const value = consulta[key];
        if (value !== undefined && value !== null && value !== '') {
          if (key === 'consulta') {
            queryParams[key] = JSON.stringify(value);
          } else {
            queryParams[key] = value;
          }
        }
      });

      const queryString = Object.entries(queryParams)
        .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(String(value))}`)
        .join('&');
      setFiltros(queryString);
    } catch (error: any) {
      AlertService.error('Erro!', String(error.response?.data?.message) || 'Erro desconhecido');
    }
  }

  useEffect(() => {
    montarFiltro();
  }, [listFiltros]);
  const handleChange = (field: string, value: string) => {
    setFormBasico((prevGrupoData: any) => {
      return {
        ...prevGrupoData,
        [field]: value
      };
    });
  };
  const handleChangeList = (field: string, value: string) => {
    setListFiltros((prevGrupoData: any) => {
      return {
        ...prevGrupoData,
        [field]: value
      };
    });
  };
  const handleClearList = () => {
    setListFiltros(null);
    setFormBasico(null);
    montarFiltro();
  };
  return (
    <div className="body-content">
      <div
        className={`${showSubSidebar ? "container-section-body" : "container-section-body-none"}`}
      >

        <div className="contentSearch-header">
          <Col className='text-center' style={{ fontSize: '20px', color: '#494949' }}>Centro Custo</Col>
        </div>
        <div className="contentTraçoBodyHeader" />

        <TableCentroCusto
          key={filtros}
          urlRedirectCreated="/financeiro/centro-custo/store"
          urlSearch={`/api/v1/admin/financeiro/centro-custo?${filtros}`}
          urlSearchDataPut="/api/v1/admin/financeiro/centro-custo/"
          url_redirect_edit="/financeiro/centro-custo/update"
        />
      </div>
    </div>
  );
}

export default CentroCusto;