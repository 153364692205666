import { Deserializable } from "../../../../core/interfaces/deserializable.model";
import { BaseModel } from "./BaseModel";
import { PessoaModel } from "./PessoaModel";

export interface ICliente {
  status: boolean | null;
  pessoa: PessoaModel | PessoaModel[] | null;
  nome_cliente: string | null;
  email_cliente: string | null;
  nome_fornecedor: string | null;
  email_fornecedor: string | null;
  created_at: Date | null;
  updated_at: Date | null;
}

export class ClienteModel
  extends BaseModel
  implements ICliente, Deserializable
{
  status: boolean | null = null;
  pessoa: PessoaModel | PessoaModel[] | null = null;
  nome_cliente: string | null = null;
  email_cliente: string | null = null;
  nome_fornecedor: string | null = null;
  email_fornecedor: string | null = null;
  created_at: Date | null = null;
  updated_at: Date | null = null;

  deserialize(input: any): this {
    Object.assign(this, input);
    if (input?.pessoa?.data) {
      this.pessoa = new PessoaModel().deserialize(input.pessoa.data);
    } else if (input?.pessoa) {
      this.pessoa = new PessoaModel().deserialize(input.pessoa);
    }
    return this;
  }

  static getTipoCliente(tipo: number): string {
    switch (tipo) {
      case 0:
        return "Cliente";
      case 1:
        return "Fornecedor";
      default:
        return tipo.toString();
    }
  }

  static getTipo(): { label: string; value: number }[] {
    return [
      { label: "Cliente", value: 0 },
      { label: "Fornecedor", value: 1 },
    ];
  }
}
