import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import { useAppContext } from '../../../contexts/AppContext';
import TokenService from '../../../services/Auth/token.service';
import CessaoDeDireitoForm from './Formularios/CessaoDeDireitoForm';
import { url } from '../../../environments/environments-develop';
import api from "../../../services/api";
import CessaoDeDireitoShow from './Formularios/CessaoDeDireitoShow';

function ShowCessaoDeDireito() {
    const { setItemsBreadsCrumbs } = useAppContext();
    const [formBasico, setFormBasico] = useState<any>({});
    const [formCompradores, setFormCompradores] = useState<any>(null);
    const listaClientesContratos = async (numero: any) => {
        try {
            const response = await api.get(`${url}/api/v1/admin/vendas/contrato-lista-clientes/${numero}/${TokenService.getEmpreendimentoId()?.id}`);
            let compradores: any[] = [];
            response.data.data.forEach((item: any) => {
                compradores.push({
                    cliente_id: item.cliente_id,
                    cliente_name: item.cliente_name,
                    principal: item.principal
                });
            });
            setFormCompradores(compradores);
        } catch (error) {
            console.error("warn", "Pessoa ainda não cadastrada no sistema!")
        }
    }

    useEffect(() => {
        setItemsBreadsCrumbs([
            {
                label: 'Carteira',
                url: '/carteira',
            },
            {
                label: 'Cessão de Direito',
                url: '/carteira/contrato-transferencia',
            },
            {
                label: 'Detalhes'
            }
        ]);
        const storedData = localStorage.getItem('dadosEdicao');
        if (storedData) {
            setFormBasico((prevGrupoData: any) => {
                const dadosAtualizados = {
                    ...prevGrupoData,
                    ...JSON.parse(storedData),
                    ['numero']: JSON.parse(storedData)?.numero,
                    ['unidade']: JSON.parse(storedData)?.unidade,
                    ['empreendimento_id']: JSON.parse(storedData)?.id,
                    ['valor_servico']: JSON.parse(storedData)?.valor_servico
                };
                return dadosAtualizados;
            });
            listaClientesContratos(JSON.parse(storedData).numero);
        }
    }, []);

    return (
        <>
            <div className="body-content">
                <div className="form-create-group">
                    <div className="form-create-group-header">
                        <h3>Cessão de Direitos</h3>
                    </div>
                </div>
                <div className="form-group-create">
                    <Container>
                        <CessaoDeDireitoShow
                            key={formBasico}
                            formBasico={formBasico}
                            formCompradores={formCompradores}
                        />
                    </Container>
                </div>
            </div>
        </>
    );
}

export default ShowCessaoDeDireito;
