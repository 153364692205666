import { Button } from 'primereact/button';
import { useEffect, useState } from 'react';
import { Col, Form, InputGroup, NavLink, Row } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import CalendarIcon from '../../../../../assets/images/icon-calendar.png';
import iconMenuTresPonto from '../../../../../assets/images/icon-menu-tres-pontos.png';
import pt from 'date-fns/locale/pt';
import 'react-autocomplete-input/dist/bundle.css';
import { useAppContext } from '../../../../contexts/AppContext';
import TokenService from '../../../../services/Auth/token.service';
import { url } from '../../../../environments/environments-develop';
import { AlertService } from '../../../../components/AlertService';
import api from '../../../../services/api';
import { abrirPDF } from '../../../../utils/PdfUtils';
import { useSidebarContext } from '../../../../contexts/SidebarContext';

function RelatorioSepultados() {
    const [listFiltros, setListFiltros] = useState<any>({});
    const { isVisible, updateVisibility } = useSidebarContext();
    const { showSubSidebar, setItemsBreadsCrumbs } = useAppContext();
    const [listCompradores, setListCompradores] = useState([]);
    const [tipo, setTipo] = useState<number>(1);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const toggleVisibility = () => {
        const newValue = !isVisible;
        updateVisibility(newValue);
    };

    useEffect(() => {
        setItemsBreadsCrumbs([
            {
                label: 'Cemitério',
                url: '/cemiterio',
            },
            {
                label: 'Relatórios Sepultados',
                url: '/cemiterio/relatorios/sepultados',
            },
        ]);
    }, []);
    async function gerarPDF(): Promise<void> {
        try {
            setIsSubmitting(true);
            const consulta: any = {
                consulta: {
                    order: "id;desc",
                    filtro: {
                        cliente_id: listFiltros?.cliente_id,
                        situacao: listFiltros?.situacao,
                        quadra: listFiltros?.quadra,
                        lote: listFiltros?.lote,
                        vencimento: listFiltros?.vencimento,
                        empreendimento_id: TokenService.getEmpreendimentoId() ? TokenService.getEmpreendimentoId().id : null
                    }
                }
            };
            const queryParams: any = {};
            Object.entries(consulta.consulta.filtro).forEach(([key, value]) => {
                if (value !== undefined && value !== null && value !== '') {
                    queryParams[key] = value;
                }
            });
            const queryString = encodeURIComponent(JSON.stringify({
                order: consulta.consulta.order,
                filtro: queryParams
            }));
            const body = {
                tipo
            };
            const response = await api.post(
                `${url}/api/v1/admin/cemiterio/gerador-relatorio-sepultado?consulta=${queryString}`,
                body, {
                responseType: 'arraybuffer'
            }
            );
            if (response.status === 200) {
                handleOpenPDF(response.data, listFiltros);
                setIsSubmitting(false);
            }
        } catch (error: any) {
            let mensagens: string[] = [];
            if (error?.response?.data?.error) {
                const errorData = error?.response?.data?.error;
                if (typeof errorData.message === 'string') {
                    mensagens.push(errorData.message);
                }
                else if (typeof errorData.message === 'object') {
                    for (const key in errorData.message) {
                        if (errorData.message.hasOwnProperty(key)) {
                            const messageArray = errorData.message[key];
                            if (Array.isArray(messageArray)) {
                                mensagens = mensagens.concat(messageArray);
                            }
                        }
                    }
                }
            }
            if (mensagens.length === 0) {
                mensagens.push("Não foram encontrados inumados nessas condições.");
            }
            AlertService.error("Erro!", mensagens.join("\n"));
            setIsSubmitting(false);
        }
    }

    useEffect(() => {
        InitCompradores();
    }, []);

    const handleChange = (field: string, value: any) => {
        setListFiltros((prevGrupoData: any) => {
            return {
                ...prevGrupoData,
                [field]: value
            };
        });
    };

    async function InitCompradores() {
        try {
            const response = await api.get(`${url}/api/v1/admin/financeiro/cliente?include=pessoa.conjugue`);
            setListCompradores(response.data.data);
        } catch (error) {
            AlertService.error('Error', 'Ocorreu um erro ao buscar a de clientes. Erro:' + String(error));
        }
    }

    const handleOpenPDF = (pdfBase64: string, listFiltros: any) => {
        abrirPDF(pdfBase64, listFiltros);
    }

    return (
        <div className="body-content">
            <div
                className={`${showSubSidebar ? "container-section-body" : "container-section-body-none"}`}
            >
                <div className="contentSearch">
                    <div className="contentSearch-header">
                        <NavLink onClick={toggleVisibility}>
                            <img
                                className="menu-context"
                                src={iconMenuTresPonto}
                            />
                        </NavLink>
                        <Col className='text-center' style={{ fontSize: '20px', color: '#494949' }}>Relatórios Sepultados</Col>
                    </div>
                    <div style={{ opacity: '34%' }} className="contentTraçoBodyHeader"></div>
                    <Form.Group className="imovel-form" style={{ marginLeft: '22px' }}>
                        <Row className="mb-3">
                            <Col>
                                <Form.Label>Cliente</Form.Label>
                                <InputGroup>
                                    <Form.Select value={listFiltros ? listFiltros?.cliente_id : ''} onChange={(e) => handleChange('cliente_id', e.target.value)}>
                                        <option>Selecione...</option>
                                        {listCompradores?.map((item: any, key: any) => (
                                            <option key={key} value={item.id}>
                                                {item.nome_cliente}
                                            </option>
                                        ))}
                                    </Form.Select>
                                </InputGroup>
                            </Col>
                            <Col>
                                <Form.Label>Situação</Form.Label>
                                <Form.Select value={listFiltros?.situacao || ''} onChange={(e) => handleChange('situacao', e.target.value)}>
                                    <option>Selecione...</option>
                                    <option value={1} key={1}>Ativo</option>
                                    <option value={2} key={2}>Cancelado</option>
                                    <option value={3} key={3}>Quitado</option>
                                </Form.Select>
                            </Col>
                            <Col>
                                <Form.Label>Quadra</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={listFiltros?.quadra || ''}
                                    onChange={(e) => handleChange('quadra', e.target.value)}
                                />
                            </Col>
                            <Col>
                                <Form.Label>Lote/Ala</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={listFiltros?.ala || ''}
                                    onChange={(e) => handleChange('lote', e.target.value)}
                                />
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col md='3'>
                                <Form.Label>Tipo de Relatório</Form.Label>
                                <Form.Select value={tipo || ''} onChange={(e) => setTipo(Number(e.target.value))}>
                                    <option>Selecione...</option>
                                    <option value={1} key={1}>PDF</option>
                                    <option value={2} key={2}>EXCEL</option>
                                    <option value={3} key={3}>JSON</option>
                                </Form.Select>
                            </Col>
                        </Row>
                    </Form.Group>
                    <div className='div-filtros-avancados'>
                        <Button
                            onClick={() => { gerarPDF() }}
                            style={{ height: '30px', marginBottom: '13px', marginRight: '24px', borderRadius: '20px', padding: '5px 10px', fontSize: '14px' }}
                            type="button"
                            severity="success"
                            icon="pi pi-refresh"
                            disabled={isSubmitting}
                            rounded>
                            <p style={{ marginLeft: '5px', marginTop: '13px' }}>{isSubmitting ? 'Gerando....' : 'Gerar Relatório'}</p>
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default RelatorioSepultados;