import { useEffect, useState } from "react";
import { Accordion, Card, Col, Form, Row } from "react-bootstrap";
import api from "../../../../services/api";
import { url } from "../../../../environments/environments-develop";
import { Button } from "primereact/button";
import { AlertService } from "../../../../components/AlertService";

function GrupoFIlial(props: any) {
    const [activeEventKey, setActiveEventKey] = useState<string | null>(null);
    const [formData, setFormData] = useState<any>([]);
    const [listaFiliais, setListaFiliais] = useState<any>([]);
    const [listaGrupos, setListaGrupos] = useState<any>([]);

    const handleChange = (index: number, field: string, value: string) => {
        props.onInputChange(index, field, value);
    };

    async function InitFiliais() {
        try {
            const response = await api.get(`${url}/api/v1/admin/core/user-filiais`);
            setListaFiliais(response.data.data);
        } catch (error) {
            AlertService.error('Error', 'Ocorreu um erro ao buscar a de clientes. Erro:' + String(error));
        }
    }

    async function InitGruposPermissoes() {
        try {
            const response = await api.get(`${url}/api/v1/admin/core/grupo`);
            setListaGrupos(response.data.data);
        } catch (error) {
            AlertService.error('Error', 'Ocorreu um erro ao buscar a de clientes. Erro:' + String(error));
        }
    }
    useEffect(() => {
        if (props.formData) {
            setFormData(props.formData);
        }
    }, [props.formData])
    useEffect(() => {
        InitGruposPermissoes();
        InitFiliais();
        formData.forEach((grupo: any, index: number) => {
            Object.keys(grupo).forEach((field) => {
                handleChange(index, field, grupo[field]);
            });
        });
    }, []);

    const handleAddEndereco = () => {
        props.onAddEndereco();
        setActiveEventKey((formData?.length || 0).toString());
    };

    const handleRemoveEndereco = (index: number) => {
        props.onRemoveEndereco(index);
    };

    return (
        <>
            <Accordion
                style={{ height: '-webkit-fill-available' }}
                activeKey={activeEventKey}
                onSelect={(key) => setActiveEventKey(key as string)}
            >
                <Button type="button"
                    severity="success"
                    size="small"
                    icon="pi pi-plus"
                    raised={true}
                    label="Adicionar"
                    rounded={true}
                    className="ms-2 button-add-endereco p-button-rounded p-button-text"
                    style={{ float: "right", marginRight: '32px' }}
                    onClick={handleAddEndereco}>
                </Button>

                {formData.map((grupo: any, index: number) => (
                    <Accordion.Item eventKey={index.toString()} key={index}>
                        <Accordion.Header>
                            Afiliação {index}
                        </Accordion.Header>
                        <Accordion.Body>
                            <Card.Body>
                                <Form.Group className="usuario-form">
                                    <Row className="mb-3">
                                        <Col>
                                            <Form.Label>Filial</Form.Label>
                                            <Form.Select value={grupo.filial_id} onChange={(e) => handleChange(index, 'filial_id', e.target.value)}>
                                                <option>Selecione..</option>
                                                {listaFiliais.map((item: any) => (
                                                    <option value={item.id}>{item.nome_conta}</option>
                                                ))}
                                            </Form.Select>
                                        </Col>
                                        <Col>
                                            <Form.Label>Grupo de Permissões</Form.Label>
                                            <Form.Select value={grupo.grupo_id} onChange={(e) => handleChange(index, 'grupo_id', e.target.value)}>
                                                <option>Selecione..</option>
                                                {listaGrupos.map((item: any) => (
                                                    <option value={item.id}>{item.nome}</option>
                                                ))}
                                            </Form.Select>
                                        </Col>
                                    </Row>
                                    <Row className="row-delete-button">
                                        <Button
                                            severity="danger"
                                            size="small"
                                            className="ms-2 button-delete-endereco p-button-rounded p-button-text"
                                            icon="pi pi-trash"
                                            onClick={() => handleRemoveEndereco(index)}
                                        >
                                        </Button>
                                    </Row>
                                </Form.Group>
                            </Card.Body>
                        </Accordion.Body>
                    </Accordion.Item>
                ))}
            </Accordion>
        </>
    );
}

export default GrupoFIlial;
