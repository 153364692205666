import React, { useEffect, useState } from 'react';
import { Modal, Container, Col, Form, Row } from 'react-bootstrap';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import 'react-datepicker/dist/react-datepicker.css';
import { showToast } from '../../../../components/ToastComponent';
import api from '../../../../services/api';
import { url } from '../../../../environments/environments-develop';
import TokenService from '../../../../services/Auth/token.service';
import { MDBCol, MDBPageItem, MDBPageNav, MDBPagination, MDBRow } from 'mdbreact';
import iconFiltroAvancado from '../../../../../assets/images/icon-filtro-avancado.png';
import { AutoComplete } from 'primereact/autocomplete';
import { Calendar } from 'primereact/calendar';
import { addLocale } from 'primereact/api';
import { searchCliente } from '../../CessaoDeDireito/Service/CessaoDeDireitoService';

interface ModalReajustesProps {
  showModal: boolean;
  onHide: () => void;
}

const ModalReajustes: React.FC<ModalReajustesProps> = ({ showModal, onHide }) => {
  const [reajustes, setReajustes] = useState<any[]>([]);
  const [selectedReajuste, setSelectedReajuste] = useState<any>(null);
  const empreendimentoId = TokenService.getEmpreendimentoId().id;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [listFiltros, setListFiltros] = useState<any>({});
  const [cliente, setCliente] = useState<string>('');
  const [FilteredPessoa, setFilteredPessoa] = useState<any[]>([]);
  const [filtros, setFiltros] = useState<string>('');
  const [itemsPerPage, setItemsPerPage] = useState<number>(8);
  const [dataInicio, setDataInicio] = useState<Date | null>(null);
  const [dataFim, setDataFim] = useState<Date | null>(null);
  const [tipoConsulta, setTipoConsulta] = useState<string>('');
  const [showModalFiltro, setShowModalFiltro] = useState(false);

  const handleItemsPerPageChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setItemsPerPage(Number(event.target.value));
  };

  useEffect(() => {
    if (showModal) {
      montarFiltro();
    }
  }, [showModal, currentPage, itemsPerPage]);

  addLocale('pt', {
    firstDayOfWeek: 0,
    dayNames: ['domingo', 'segunda-feira', 'terça-feira', 'quarta-feira', 'quinta-feira', 'sexta-feira', 'sábado'],
    dayNamesShort: ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sáb'],
    dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
    monthNames: ['janeiro', 'fevereiro', 'março', 'abril', 'maio', 'junho', 'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro'],
    monthNamesShort: ['jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez'],
    today: 'Hoje',
    clear: 'Limpar'
  });

  async function montarFiltro(): Promise<void> {
    try {
      const consulta: any = {
        consulta: {
          order: "id;asc",
          filtro: {
            ...listFiltros,
            empreendimento_id: empreendimentoId,
            data_inicio: dataInicio ? dataInicio.toISOString().split('T')[0] : undefined,
            data_fim: dataFim ? dataFim.toISOString().split('T')[0] : undefined,
            tipo_consulta: tipoConsulta || undefined,
            status: listFiltros?.status || undefined,
          },
        },
      };
      const queryParams: any = {};
      const camposEnviar = ['consulta'];
      camposEnviar.forEach((key: any) => {
        const value = consulta[key];
        if (value !== undefined && value !== null && value !== '') {
          if (key === 'consulta') {
            queryParams[key] = JSON.stringify(value);
          } else {
            queryParams[key] = value;
          }
        }
      });
      const queryString = Object.entries(queryParams)
        .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(String(value))}`)
        .join('&');
      setFiltros(queryString);
      fetchReajustes(currentPage);
    } catch (error: any) {
      showToast('error', 'Erro ao montar filtro: ' + String(error.response?.data?.message) || 'Erro desconhecido');
    }
  }

  const handleFilter = () => {
    setCurrentPage(0);
    montarFiltro();
  };

  const handleClearFilters = () => {
    setListFiltros({});
    setCliente('');
    setCurrentPage(0);
    setFiltros('');
    setDataInicio(null);
    setDataFim(null);
    setTipoConsulta('');
    fetchReajustes(0);
  };

  const fetchReajustes = async (page: number) => {
    setIsLoading(true);
    try {
      const response = await api.get(`${url}/api/v1/admin/financeiro/listagem-reajustes?${filtros}&page=${page + 1}&totalitems=${itemsPerPage}`);
      if (response.status === 200) {
        setReajustes(response.data.data);
        setTotalPages(response.data.meta.pagination.total_pages);
      }
    } catch (error: any) {
      showToast('error', 'Erro ao buscar reajustes: ' + error?.response?.data?.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleReverterReajuste = async () => {
    if (!selectedReajuste) {
      showToast('error', 'Selecione um reajuste para reverter.');
      return;
    }
    const payload = {
      empreendimento_id: empreendimentoId,
      reajuste_id: selectedReajuste.id,
    };
    try {
      const response = await api.post(`${url}/api/v1/admin/financeiro/reverter-reajuste-unitario`, payload);
      if (response.status === 200) {
        showToast('success', 'Reajuste revertido com sucesso!');
        onHide();
      }
    } catch (error: any) {
      showToast('error', 'Erro ao reverter reajuste: ' + error?.response?.data?.message);
    }
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    for (let i = 0; i < totalPages; i++) {
      if (i < 3 || i === totalPages - 1 || (i >= currentPage - 1 && i <= currentPage + 1)) {
        pageNumbers.push(
          <MDBPageItem key={i} active={i === currentPage}>
            <MDBPageNav className="page-link" onClick={() => setCurrentPage(i)}>
              <span style={{ cursor: 'pointer', margin: '0 5px', fontWeight: i === currentPage ? 'bold' : 'normal' }}>
                {i + 1}
              </span>
            </MDBPageNav>
          </MDBPageItem>
        );
      }
    }
    return pageNumbers;
  };

  return (
    <Modal show={showModal} size="xl" onHide={() => onHide()}>
      <Modal.Header closeButton>
        <Modal.Title>Reajustes</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <Row>
            <Col md="2" style={{ display: 'flex', flexDirection: 'column' }}>
              <Form.Label>Cliente | Beneficiário</Form.Label>
              <AutoComplete
                field="nome_cliente"
                value={cliente}
                suggestions={FilteredPessoa}
                completeMethod={(e) => searchCliente(e, setFilteredPessoa)}
                onChange={(e) => {
                  setListFiltros({ ...listFiltros, cliente: e.value });
                  setCliente(e.value);
                }}
                onSelect={(e) => {
                  setListFiltros({ ...listFiltros, cliente_id: e.value.id, cliente: e.value });
                  setCliente(e.value.nome_cliente);
                }}
                dropdown
              />
            </Col>
            <Col md={2} style={{ display: 'flex', flexDirection: 'column' }}>
              <Form.Label>N° Contrato</Form.Label>
              <Form.Control
                type="text"
                value={listFiltros?.numero}
                onChange={(e) => setListFiltros({ ...listFiltros, numero: e.target.value })}
              />
            </Col>
            <Col md={2} style={{ display: 'flex', flexDirection: 'column' }}>
              <Form.Label>Status</Form.Label>
              <Form.Select
                value={listFiltros?.status}
                onChange={(e) => setListFiltros({ ...listFiltros, status: e.target.value })}
              >
                <option value=''>Selecione...</option>
                <option value={1}>Homologado</option>
                <option value={2}>Desfazer</option>
                <option value={3}>Desfeito</option>
                <option value={4}>Desfazendo</option>
              </Form.Select>
            </Col>
            <Col className='div-filtros-avancados' style={{ marginTop: '22px' }}>
              <Button
                style={{ marginRight: '5px', height: '30px', borderRadius: '8px', fontSize: '14px' }}
                type="button"
                rounded outlined severity="secondary"
                onClick={() => setShowModalFiltro(true)}
              >
                <img style={{ width: '25px', height: '35px' }} src={iconFiltroAvancado} />
              </Button>
              <Button
                style={{ position: 'relative', float: 'right', height: '30px', borderRadius: '7px', marginRight: '5px' }}
                type="button"
                icon="pi pi-search"
                severity="success"
                onClick={handleFilter}
                rounded>
                Filtrar
              </Button>
              <Button
                style={{ height: '30px', borderRadius: '7px', padding: '5px 10px', marginRight: '5px', fontSize: '14px' }}
                type="button"
                severity="danger"
                icon="pi pi-delete-left"
                rounded
                onClick={handleClearFilters}
              >
                <p style={{ marginLeft: '5px', marginTop: '13px' }}>Limpar</p>
              </Button>
            </Col>
          </Row>
          <hr />
          <div className="card">
            <DataTable
              lazy
              stripedRows
              size="small"
              value={reajustes}
              tableStyle={{ minWidth: '50rem' }}
              emptyMessage={isLoading ? (<span>Carregando...</span>) : (<span style={{ display: 'flex', justifyContent: 'center', fontStyle: 'italic' }}>Nenhum item encontrado.</span>)}
              selectionMode="single"
              selection={selectedReajuste}
              onSelectionChange={(e) => setSelectedReajuste(e.value)}
            >
              <Column
                style={{ textAlign: 'right', width: '7%' }}
                key={'id'}
                field={'id'}
                headerClassName="righted-header"
                header={<div className="righted-content">Código</div>}
              />
              <Column
                style={{ textAlign: 'left' }}
                key={'valor_original'}
                field={'valor_original'}
                headerClassName="lefted-header"
                header={<div className="lefted-content">Valor Original</div>}
              />
              <Column
                style={{ textAlign: 'left' }}
                key={'valor_reajustado'}
                field={'valor_reajustado'}
                headerClassName="lefted-header"
                header={<div className="lefted-content">Valor Reajustado</div>}
              />
              <Column
                style={{ textAlign: 'left' }}
                key={'data_anterior_reajuste'}
                field={'data_anterior_reajuste'}
                headerClassName="lefted-header"
                header={<div className="lefted-content">Data Anterior Reajuste</div>}
              />
              <Column
                style={{ textAlign: 'left' }}
                key={'data_novo_reajuste'}
                field={'data_novo_reajuste'}
                headerClassName="lefted-header"
                header={<div className="lefted-content">Data Novo Reajuste</div>}
              />
              <Column
                style={{ textAlign: 'left' }}
                key={'data_reajuste'}
                field={'data_reajuste'}
                headerClassName="lefted-header"
                header={<div className="lefted-content">Data Reajuste</div>}
              />
              <Column
                style={{ textAlign: 'left' }}
                key={'cliente_principal'}
                field={'cliente_principal'}
                headerClassName="lefted-header"
                header={<div className="lefted-content">Cliente</div>}
              />
              <Column
                style={{ textAlign: 'left' }}
                key={'contrato_numero'}
                field={'contrato_numero'}
                headerClassName="lefted-header"
                header={<div className="lefted-content">Contrato Nº</div>}
              />
              <Column
                style={{ textAlign: 'left' }}
                key={'status_label'}
                field={'status_label'}
                headerClassName="lefted-header"
                header={<div className="lefted-content">Status</div>}
              />
            </DataTable>
          </div>
          <div className="pagination-footer">
            <MDBRow>
              <MDBCol>
                <MDBPagination circle>
                  <MDBPageItem disabled={currentPage === 0}>
                    <MDBPageNav className="page-link" aria-label="Previous" onClick={() => setCurrentPage(currentPage - 1)}>
                      <span aria-hidden="true">&laquo;</span>
                    </MDBPageNav>
                  </MDBPageItem>
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    {renderPageNumbers()}
                  </div>
                  <MDBPageItem disabled={currentPage === totalPages - 1}>
                    <MDBPageNav className="page-link" aria-label="Next" onClick={() => setCurrentPage(currentPage + 1)}>
                      <span aria-hidden="true">&raquo;</span>
                    </MDBPageNav>
                  </MDBPageItem>
                </MDBPagination>
              </MDBCol>
              <MDBCol>
                <Form.Select
                  value={itemsPerPage}
                  onChange={handleItemsPerPageChange}
                >
                  <option value={8}>8</option>
                  <option value={16}>16</option>
                  <option value={32}>32</option>
                  <option value={64}>64</option>
                </Form.Select>
              </MDBCol>
            </MDBRow>
          </div>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button
          style={{ height: '30px', borderRadius: '7px', padding: '5px 10px', marginRight: '5px', fontSize: '14px' }}
          severity="success"
          label="Reverter Reajuste"
          onClick={handleReverterReajuste}
          disabled={!selectedReajuste}
        />
        <Button
          style={{ height: '30px', borderRadius: '7px', padding: '5px 10px', marginRight: '5px', fontSize: '14px' }}
          severity="danger"
          label="Cancelar"
          onClick={onHide}
        />
      </Modal.Footer>

      <Modal show={showModalFiltro} size='lg' onHide={() => setShowModalFiltro(false)} className='modal-filtro-avancado'>
        <Modal.Header closeButton>
          <Modal.Title>Filtros Avançados</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row>
              <Col md={3}>
                <Form.Label>Tipo Consulta</Form.Label>
                <Form.Select
                  value={tipoConsulta}
                  onChange={(e) => setTipoConsulta(e.target.value)}
                >
                  <option>Selecione...</option>
                  <option value={'data_anterior_reajuste'}>Data Anterior Reajuste</option>
                  <option value={'data_novo_reajuste'}>Data Novo Reajuste</option>
                  <option value={'data_reajuste'}>Data Reajuste</option>
                </Form.Select>
              </Col>
              <Col md={3}>
                <Form.Label>Data Início</Form.Label>
                <Calendar
                  value={dataInicio}
                  onChange={(event: any) => setDataInicio(event.value)}
                  placeholder="Data Início"
                  showButtonBar
                  selectionMode="single"
                  dateFormat="dd/mm/yy"
                  locale="pt"
                  readOnlyInput
                  style={{ textAlign: 'center', width: '100%', height: '29px' }}
                  panelClassName="custom-calendar-panel"
                />
              </Col>
              <Col md={3}>
                <Form.Label>Data Fim</Form.Label>
                <Calendar
                  value={dataFim}
                  onChange={(event: any) => setDataFim(event.value)}
                  placeholder="Data Fim"
                  showButtonBar
                  selectionMode="single"
                  dateFormat="dd/mm/yy"
                  locale="pt"
                  readOnlyInput
                  style={{ textAlign: 'center', width: '100%' }}
                  panelClassName="custom-calendar-panel"
                />
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              setShowModalFiltro(false);
              handleFilter();
            }}
            style={{ marginTop: '3px', width: '100px', height: '30px', borderRadius: '5px', padding: '5px 10px', marginRight: '5px', fontSize: '14px', marginLeft: '10px' }}
            type="button"
            severity="success"
            icon="pi pi-filter"
            rounded>
            <p style={{ marginLeft: '5px', marginTop: '15px' }}>Pesquisar</p>
          </Button>
          <Button
            onClick={() => {
              handleClearFilters();
              setShowModalFiltro(false);
            }}
            style={{ marginTop: '3px', width: '100px', height: '30px', borderRadius: '5px', padding: '5px 10px', marginRight: '5px ', fontSize: '14px' }}
            type="button"
            severity="danger"
            icon="pi pi-delete-left"
            rounded>
            <p style={{ marginLeft: '5px', marginTop: '15px' }}>Limpar</p>
          </Button>
        </Modal.Footer>
      </Modal>
    </Modal>
  );
};

export default ModalReajustes;
