import { useEffect, useRef } from "react";
import { setSessionEditData } from '../Service/InumadoService';
import { Tooltip } from 'primereact/tooltip';
import { Toast } from 'primereact/toast';
import { SpeedDial } from "primereact/speeddial";

export const actionTemplate = (
    value: any,
    url_redirect_edit: any,
    setSelectedRow: (value: any) => void
) => {
    const data = value.data ? value.data : value;
    const toast = useRef<Toast>(null);
    const commonStyle = {
        width: '35px',
        height: '35px',
        top: '0px',
        color: '#fff'
    };

    const items: any[] = [
        {
            label: 'Editar',
            icon: 'pi pi-pencil',
            style: { backgroundColor: '#007bff', color: '#fff', top: '0px', width: '35px', height: '35px', display: `${data.situacao_pagamento === 1 ? 'none' : 'flex'}` },
            command: () => {
                setSessionEditData(data.id, url_redirect_edit);
            },
            tooltip: 'Editar'
        }
    ];

    useEffect(() => {
        items.forEach((item, index) => {
            const element = document.querySelectorAll('.p-speeddial-action')[index];
            if (element) {
                element.setAttribute('data-pr-tooltip', item.tooltip);
                element.setAttribute('data-pr-position', 'left');
            }
        });
    }, [items]);

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Toast ref={toast} />
            <Tooltip target="[data-pr-tooltip]" />
            <div style={{ position: 'relative' }}>
                <SpeedDial
                    model={items}
                    radius={45}
                    showIcon="pi pi-ellipsis-v"
                    hideIcon="pi pi-times"
                    type="linear"
                    direction="left"
                    style={{ top: 'calc(40%)', right: 18 }}
                    buttonClassName="button-speed-dial"
                />
            </div>
        </div>
    );
};