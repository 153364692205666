function Legenda() {
    return (
        <div style={{ display: 'flex', justifyContent: 'space-between', margin: '14px' }}>
            <div style={{ display: 'flex' }}>
                <p style={{ margin: '0px' }}>Legenda: </p>
                <div style={{ display: 'flex', marginLeft: '8px', }}>
                    <div style={{
                        backgroundColor: `#289b1e`,
                        margin: '3px',
                        height: '5px',
                        marginRight: '8px',
                        border: `2px solid #289b1e`,
                        borderRadius: '12px',
                        boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.32)',
                        padding: '8px'
                    }}>
                    </div>
                    <p style={{ margin: '0px' }}>Disponível</p>
                </div>
                <div style={{ display: 'flex', marginLeft: '8px', }}>
                    <div style={{
                        backgroundColor: `#f5da41`,
                        margin: '3px',
                        height: '5px',
                        marginRight: '8px',
                        border: `2px solid #f5da41`,
                        borderRadius: '12px',
                        boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.32)',
                        padding: '8px'
                    }}>
                    </div>
                    <p style={{ margin: '0px' }}>Reservado</p>
                </div>
                <div style={{ display: 'flex', marginLeft: '8px', }}>
                    <div style={{
                        backgroundColor: `#a8a8a8`,
                        margin: '3px',
                        marginRight: '8px',
                        height: '5px',
                        border: `2px solid #a8a8a8`,
                        borderRadius: '12px',
                        boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.32)',
                        padding: '8px'
                    }}>
                    </div>
                    <p style={{ margin: '0px' }}>Negociado</p>
                </div>
                <div style={{ display: 'flex', marginLeft: '8px', }}>
                    <div style={{
                        backgroundColor: `#ff3232`,
                        margin: '3px',
                        marginRight: '8px',
                        height: '5px',
                        border: `2px solid #ff3232`,
                        borderRadius: '12px',
                        boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.32)',
                        padding: '8px'
                    }}>
                    </div>
                    <p style={{ margin: '0px' }}>Vendido</p>
                </div>
                <div style={{ display: 'flex', marginLeft: '8px', }}>
                    <div style={{
                        backgroundColor: `#fff`,
                        margin: '3px',
                        marginRight: '8px',
                        height: '5px',
                        border: `2px solid #000`,
                        borderRadius: '12px',
                        boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.32)',
                        padding: '8px'
                    }}>
                    </div>
                    <p style={{ margin: '0px' }}>Reserva Técnica</p>
                </div>
                <div style={{ display: 'flex', marginLeft: '8px', }}>
                    <div style={{
                        backgroundColor: `#fff`,
                        margin: '3px',
                        marginRight: '8px',
                        height: '5px',
                        border: `2px solid #000`,
                        borderRadius: '12px',
                        boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.32)',
                        padding: '8px'
                    }}>
                    </div>
                    <p style={{ margin: '0px' }}>Reserva Diretoria</p>
                </div>
            </div>
        </div>
    );
};

export default Legenda;
