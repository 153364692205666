import React, { useEffect, useState } from 'react';
import { Modal, Container, Row, Col, Form } from 'react-bootstrap';
import { Button } from 'primereact/button';
import 'react-datepicker/dist/react-datepicker.css';
import { AlertService } from '../../../../components/AlertService';
import api from '../../../../services/api';
import { url } from '../../../../environments/environments-develop';
import { addLocale } from "primereact/api";
import { Calendar } from "primereact/calendar";
import { AutoComplete } from 'primereact/autocomplete';
import { useSidebarContext } from '../../../../contexts/SidebarContext';

interface FiltroAvancadoModalProps {
    showModal: boolean;
    onHide: () => void;
    listFiltros: any;
    setListFiltros: any | null;
    loadData: (filtros: any, itemsPerPage: any, currentPage: number, setLoading: (nodes: any) => void, setNodes: (nodes: any) => void, updateTitles: (titles: string[]) => void, updatePagination: (pages: number) => void, setShowModalFiltro: (show: boolean) => void) => void;
    currentPage: number;
    setLoading: (nodes: any) => void;
    setNodes: (nodes: any) => void;
    updateTitles: (titles: string[]) => void;
    updatePagination: (pages: number) => void;
    setShowModalFiltro: (show: boolean) => void;
    handleClose: () => void;
    itemsPerPage: any;
}

const FiltroAvancadoModal: React.FC<FiltroAvancadoModalProps> = ({
    showModal,
    onHide,
    listFiltros,
    setListFiltros,
    setLoading,
    loadData,
    currentPage,
    setNodes,
    updateTitles,
    updatePagination,
    setShowModalFiltro,
    itemsPerPage
}) => {
    const { campo_busca, nome_corretor, corretor_id, logradouro, observacao, construcao, tipo_imovel_id, sub_tipo_imovel_id, status } = listFiltros || {};
    const [subTipoImovel, setSubTipoImovel] = useState<any>([]);
    const [tipoImovel, setTipoImovel] = useState<any>([]);
    const [FilteredCorretores, setFilteredCorretores] = useState<any>(null);

    async function search(event: any) {
        let _FilteredPessoa: any;

        if (event.query) {
            try {
                _FilteredPessoa = await api.get(`${url}/api/v1/admin/vendas/corretores/buscarCorrretoresList/${event.query.toLowerCase()}`);
            } catch (error) {
                console.error('Erro ao buscar corretores:', error);
                _FilteredPessoa = [];
            }
        } else {
            _FilteredPessoa = [];
        }
        setFilteredCorretores(_FilteredPessoa ? _FilteredPessoa?.data?.data : []);
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [responseTiposImovel, responseSubtiposImovel] = await Promise.all([
                    api.get(`${url}/api/v1/admin/vendas/tipoimovel`),
                    api.get(`${url}/api/v1/admin/vendas/subtipoimovel`)
                ]);
                if (responseTiposImovel.status === 200) {
                    setTipoImovel(responseTiposImovel.data.data);
                }
                if (responseSubtiposImovel.status === 200) {
                    setSubTipoImovel(responseSubtiposImovel.data.data);
                }
            } catch (error) {
                AlertService.error('Erro!', String('Erro desconhecido'));
            }
        };
        fetchData();
    }, []);

    return (
        <Modal
            show={showModal}
            size='lg'
            onHide={() => onHide()}
            className='modal-filtro-avancado'
        >
            <Modal.Header closeButton>
                <Modal.Title>Filtros Avançados</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container>
                    <Form.Group className="imovel-form">
                        <Row className='mb-3'>
                            <Col md={4} style={{ display: 'flex', flexDirection: 'column' }}>
                                <Form.Label>Corretor</Form.Label>
                                <AutoComplete
                                    placeholder='Pesquisar nome/cpf/cnpj'
                                    field="nome_corretor"
                                    value={nome_corretor ? nome_corretor : campo_busca}
                                    suggestions={FilteredCorretores}
                                    completeMethod={search}
                                    onChange={(e) =>
                                        setListFiltros({ ...listFiltros, campo_busca: e.value })}
                                    onSelect={(e) =>
                                        setListFiltros({ ...listFiltros, corretor_id: e.value })}
                                />
                            </Col>
                            <Col md={4} style={{ display: 'flex', flexDirection: 'column' }}>
                                <Form.Label>Logradouro</Form.Label>
                                <Form.Control
                                    value={logradouro ? logradouro : ''}
                                    onChange={(e) => setListFiltros({ ...listFiltros, logradouro: e.target.value })}
                                />
                            </Col>
                            <Col md={4} style={{ display: 'flex', flexDirection: 'column' }}>
                                <Form.Label>Observação</Form.Label>
                                <Form.Control
                                    value={observacao ? observacao : ''}
                                    onChange={(e) => setListFiltros({ ...listFiltros, observacao: e.target.value })}
                                />
                            </Col>
                        </Row>
                        <Row className='mb-3'>
                            <Col md={4} style={{ display: 'flex', flexDirection: 'column' }}>
                                <Form.Label>Tipo Imóvel</Form.Label>
                                <Form.Select value={tipo_imovel_id || ''} onChange={(e) => setListFiltros({ ...listFiltros, tipo_imovel_id: e.target.value })}>
                                    <option>Selecione...</option>
                                    {tipoImovel.map((tipoimovel: any) => (
                                        <option value={tipoimovel.id}>{tipoimovel.nome}</option>
                                    ))}
                                </Form.Select>
                            </Col>
                            <Col md={4} style={{ display: 'flex', flexDirection: 'column' }}>
                                <Form.Label>SubTipo Imóvel</Form.Label>
                                <Form.Select value={sub_tipo_imovel_id || ''} onChange={(e) => setListFiltros({ ...listFiltros, sub_tipo_imovel_id: e.target.value })}>
                                    <option>Selecione...</option>
                                    {subTipoImovel.map((subtipo: any) => (
                                        <option value={subtipo.id}>{subtipo.nome}</option>
                                    ))}
                                </Form.Select>
                            </Col>
                            <Col md={4} style={{ display: 'flex', flexDirection: 'column' }}>
                                <Form.Label>Status</Form.Label>
                                <Form.Select value={status || ''} onChange={(e) => setListFiltros({ ...listFiltros, status: e.target.value })}>
                                    <option>Selecione...</option>
                                    <option value={"1"}>Disponível</option>
                                    <option value={"2"}>Reservado</option>
                                    <option value={"3"}>Reserva Fixa</option>
                                    <option value={"4"}>Negociado</option>
                                    <option value={"5"}>Vendido</option>
                                    <option value={"6"}>Reserva Técnica</option>
                                    <option value={"7"}>Reserva Diretoria</option>
                                </Form.Select>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={4} style={{ display: 'flex', flexDirection: 'column' }}>
                                <Form.Label>Construção</Form.Label>
                                <Form.Check
                                    style={{ display: "flex" }}
                                    checked={construcao || false}
                                    onChange={(e) => setListFiltros({ ...listFiltros, construcao: e.target.checked })}
                                    type="switch"
                                    id="custom-switch"
                                />
                            </Col>
                        </Row>
                    </Form.Group>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    style={{ height: '30px', borderRadius: '20px', padding: '5px 10px', marginRight: '5px', fontSize: '14px' }}
                    type="button"
                    severity="success"
                    icon="pi pi-filter"
                    rounded
                    onClick={() => loadData(listFiltros, itemsPerPage, currentPage, setLoading, setNodes, updateTitles, updatePagination, setShowModalFiltro)}
                >
                    <p style={{ marginLeft: '5px', marginTop: '13px' }}>Pesquisar</p>
                </Button>
                <Button
                    style={{ height: '30px', borderRadius: '20px', padding: '5px 10px', marginRight: '5px', fontSize: '14px' }}
                    type="button"
                    severity="danger"
                    icon="pi pi-delete-left"
                    rounded
                    onClick={() => {
                        setListFiltros({});
                        loadData(listFiltros, itemsPerPage, currentPage, setLoading, setNodes, updateTitles, updatePagination, setShowModalFiltro)
                    }}
                >
                    <p style={{ marginLeft: '5px', marginTop: '13px' }}>Limpar</p>
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default FiltroAvancadoModal;
