import React, { useState, useEffect, useRef } from 'react';
import { Column } from 'primereact/column';
import { MDBCol, MDBPageItem, MDBPageNav, MDBPagination, MDBRow } from 'mdbreact';
import { DataTable } from 'primereact/datatable';
import { useAppContext } from '../../../../contexts/AppContext';
import { Toast } from 'primereact/toast';
import { fetchData } from './GestaoContratosServices';
import ModalReativarContrato from './ModalReativarContrato';
import ModalConfirmarReativarContrato from './ModalConfirmarReativarContrato';
import Legenda from './Legenda';
import { actionTemplate } from './actionTemplate';
import { togglerStatus } from './TogglerStatus';
import Paginacao from './Paginacao';
import ModalConfirmarCancelarContrato from './ModalConfirmarCancelarContrato';
import ModalCancelarContrato from './ModalCancelarContrato';
import ModalEmitirContrato from './ModalEmitirContrato';
import ModalAnexo from './ModalAnexo';
import { Skeleton } from 'primereact/skeleton';
import { FaSort, FaSortDown, FaSortUp } from 'react-icons/fa';

interface TableComponentsContratoArvoreListProps {
    urlSearch: string;
    urlSearchDataPut: string;
    url_redirect_edit: string;
    urlRedirectCreated: string;
    ordernacao: any;
}

const TableComponentsContrato: React.FC<TableComponentsContratoArvoreListProps> = ({ ordernacao, urlRedirectCreated, urlSearch, urlSearchDataPut, url_redirect_edit }) => {
    const [currentPage, setCurrentPage] = useState(0);
    const [nodes, setNodes] = useState<any | null>(null);
    const [titles, setTitles] = useState<any | null>(null);
    const [totalPages, setTotalPages] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState<number>(8);
    const { setUrl_button_create } = useAppContext();
    const [formCancelamento, setFormCancelamento] = useState<any>({});
    const [formEmissao, setFormEmissao] = useState<any>({});
    const [selectedNodeKeys, setSelectedNodeKeys] = useState<any>(null);
    const [showModalCancelarContrato, setShowModalCancelarContrato] = useState<boolean>(false);
    const [showConfirmarModalCancelarContrato, setShowConfirmarModalCancelarContrato] = useState<boolean>(false);
    const [showConfirmarModalReativarContrato, setShowConfirmarModalReativarContrato] = useState<boolean>(false);
    const [showModalReativarContrato, setShowModalReativarContrato] = useState<boolean>(false);
    const [showModalEmitirContrato, setShowModalEmitirContrato] = useState(false);
    const [dataCancelamento, setDataCancelamento] = useState<Date | null>(null);
    const [dataPrimeiraParcelaPaga, setDataPrimeiraParcelaPaga] = useState<Date | null>(null);
    const [showModalAnexo, setShowModalAnexo] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const toast = useRef<any>(null);
    const [dados, setDados] = useState({});
    const [sortOptions, setSortOptions] = useState<{ field: string, order: number } | null>(null);
    useEffect(() => {
        setUrl_button_create(urlRedirectCreated);
    }, [])
    useEffect(() => {
        fetchData(urlSearch, setLoading, currentPage, setTitles, setTotalPages, setNodes, itemsPerPage);
    }, [currentPage, urlSearch, sortOptions,itemsPerPage]);

    const handleData = (newData: any) => {
        setDados(newData);
    };

    const handleChange = (field: string, value: any) => {
        setFormCancelamento((prevGrupoData: any) => {
            return {
                ...prevGrupoData,
                [field]: value
            };
        });
    };

    const handleChangeEmitirContrato = (field: string, value: any) => {
        setFormEmissao((prevGrupoData: any) => {
            return {
                ...prevGrupoData,
                [field]: value
            };
        });
    };

    const handleSort = (field: string) => {
        let order = 1;
        if (sortOptions && sortOptions.field === field) {
            order = sortOptions.order === 1 ? -1 : sortOptions.order === -1 ? 0 : 1;
        }
        setSortOptions({ field, order });
    };

    const getSortIcon = (field: string) => {
        if (sortOptions?.field === field) {
            return sortOptions.order === 1 ? <FaSortUp /> : sortOptions.order === -1 ? <FaSortDown /> : <FaSort style={{ color: '#b8b8b8' }} />;
        }
        return <FaSort style={{ color: '#b8b8b8' }} />;
    };

    const sortParam = sortOptions ? `${sortOptions.field};${sortOptions.order === 1 ? 'asc' : 'desc'}` : null;

    useEffect(() => {
        if (sortParam) {
            ordernacao(sortParam);
        }
    }, [sortOptions]);

    return (
        <div className="card">
            <Toast ref={toast} />
            <Legenda />
            <DataTable
                stripedRows
                size="small"
                lazy
                value={nodes ? nodes : []}
                tableStyle={{ minWidth: '50rem' }}
                emptyMessage={<span style={{ display: 'flex', justifyContent: 'center', fontStyle: 'italic' }}>Nenhum item encontrado.</span>}
            >
                <Column
                    style={{ width: '5px' }}
                    body={(e) => togglerStatus(e)}
                    headerClassName=""
                />
                <Column
                    style={{ textAlign: 'right' }}
                    key={'id'}
                    field={'id'}
                    headerClassName="righted-header"
                    header={<div className="lefted-content" onClick={() => handleSort('vendas.contrato.id')}>Id{getSortIcon('vendas.contrato.id')}</div>}
                    body={isLoading && (<Skeleton />)}
                />
                <Column
                    style={{ textAlign: 'right' }}
                    key={'numero'}
                    field={'numero'}
                    headerClassName="righted-header"
                    header={<div className="righted-content" onClick={() => handleSort('vendas.contrato.numero')}>Nº.Contrato{getSortIcon('vendas.contrato.numero')}</div>}
                    body={isLoading && (<Skeleton />)}
                />
                <Column
                    style={{ textAlign: 'left' }}
                    key={'cliente_nome'}
                    field={'cliente_nome'}
                    headerClassName="lefted-header"
                    header={<div className="lefted-content"onClick={() => handleSort('core.pessoas.nome')}>Cliente{getSortIcon('core.pessoas.nome')}</div>}
                    body={isLoading && (<Skeleton />)}
                />
                <Column
                    style={{ textAlign: 'left' }}
                    key={'data_venda_format'}
                    field={'data_venda_format'}
                    headerClassName="lefted-header"
                    header={<div className="lefted-content" onClick={() => handleSort('vendas.contrato.data_venda')}>Dt.Venda{getSortIcon('vendas.contrato.data_venda')}</div>}
                    body={isLoading && (<Skeleton />)}
                />
                <Column
                    style={{ textAlign: 'right' }}
                    key={'unidade'}
                    field={'unidade'}
                    headerClassName="righted-header"
                    header={<div className="righted-content" onClick={() => handleSort('imoveis.unidade')}>Unidade{getSortIcon('imoveis.unidade')}</div>}
                    body={isLoading && (<Skeleton />)}
                />
                <Column
                    style={{ textAlign: 'left' }}
                    key={'status_name'}
                    field={'status_name'}
                    headerClassName="lefted-header"
                    header={<div className="lefted-content" onClick={() => handleSort('vendas.contrato.status')}>Status{getSortIcon('vendas.contrato.status')}</div>}
                    body={isLoading && (<Skeleton />)}
                />
                <Column
                    style={{ textAlign: 'left' }}
                    key={'data_ult_reajuste_format'}
                    field={'data_ult_reajuste_format'}
                    headerClassName="lefted-header"
                    header={<div className="lefted-content" onClick={() => handleSort('vendas.contrato.data_ult_reajuste')}>Ult.Reajuste{getSortIcon('vendas.contrato.data_ult_reajuste')}</div>}
                    body={isLoading && (<Skeleton />)}
                />
                <Column
                    style={{ textAlign: 'left' }}
                    key={'prox_reajuste_format'}
                    field={'prox_reajuste_format'}
                    headerClassName="lefted-header"
                    header={<div className="lefted-content" onClick={() => handleSort('vendas.contrato.prox_reajuste')}>Prox.Reajuste{getSortIcon('vendas.contrato.prox_reajuste')}</div>}
                    body={isLoading && (<Skeleton />)}
                />
                <Column
                    style={{ textAlign: 'right' }}
                    key={'qtd_parcelas'}
                    field={'qtd_parcelas'}
                    headerClassName="righted-header"
                    header={<div className="righted-content" onClick={() => handleSort('vendas.contrato.qtd_parcelas')}>Qtd.Parcelas{getSortIcon('vendas.contrato.qtd_parcelas')}</div>}
                    body={isLoading ? <Skeleton /> : null}
                />

                <Column
                    style={{ textAlign: 'right' }}
                    key={'valor_total_format'}
                    field={'valor_total_format'}
                    headerClassName="righted-header"
                    header={<div className="righted-content" onClick={() => handleSort('vendas.contrato.valor_total')}>Vlr.Total{getSortIcon('vendas.contrato.valor_total')}</div>}
                    body={isLoading && (<Skeleton />)}
                />
                <Column
                    className='menu-context-grid-contrato'
                    body={(e) => actionTemplate(
                        e,
                        urlSearchDataPut,
                        url_redirect_edit,
                        setSelectedNodeKeys,
                        (field: string, value: any) => handleChangeEmitirContrato(field, value),
                        setShowConfirmarModalCancelarContrato,
                        setShowConfirmarModalReativarContrato,
                        setShowModalEmitirContrato,
                        setShowModalAnexo
                    )}
                    header="Menu"
                    headerClassName=""
                />
            </DataTable>
            <ModalEmitirContrato
                showModalEmitirContrato={showModalEmitirContrato}
                selectedNodeKeys={selectedNodeKeys}
                formEmissao={formEmissao}
                onInputChange={(field: string, value: any) => handleChangeEmitirContrato(field, value)}
                handleShowModalEmitirContrato={setShowModalEmitirContrato}
            />

            <ModalAnexo
                showModalAnexo={showModalAnexo}
                selectedNodeKeys={selectedNodeKeys}
                handleShowModalAnexo={setShowModalAnexo}
            />

            <ModalConfirmarCancelarContrato
                showConfirmarModalCancelarContrato={showConfirmarModalCancelarContrato}
                selectedNodeKeys={selectedNodeKeys}
                handleShowConfirmarModalCancelarContrato={setShowConfirmarModalCancelarContrato}
                handleShowModalCancelarContrato={setShowModalCancelarContrato}
                handleData={handleData}
            />

            <ModalCancelarContrato
                showModalCancelarContrato={showModalCancelarContrato}
                selectedNodeKeys={selectedNodeKeys}
                dados={dados}
                formCancelamento={formCancelamento}
                dataCancelamento={dataCancelamento}
                dataPrimeiraParcelaPaga={dataPrimeiraParcelaPaga}
                handleDataPrimeiraParcelaPaga={setDataPrimeiraParcelaPaga}
                handleDataCancelamento={setDataCancelamento}
                onInputChange={(field: string, value: any) => handleChange(field, value)}
                handleShowModalCancelarContrato={setShowModalCancelarContrato}
            />

            <ModalReativarContrato
                showModalReativarContrato={showModalReativarContrato}
                handleShowModalReativarContrato={setShowModalReativarContrato}
                selectedNodeKeys={selectedNodeKeys}
                formCancelamento={formCancelamento}
                dataCancelamento={dataCancelamento}
                handleDataCancelamento={setDataCancelamento}
                dataPrimeiraParcelaPaga={dataPrimeiraParcelaPaga}
                handleDataPrimeiraParcelaPaga={setDataPrimeiraParcelaPaga}
                onInputChange={(field: string, value: any) => handleChange(field, value)}
            />

            <ModalConfirmarReativarContrato
                showConfirmarModalReativarContrato={showConfirmarModalReativarContrato}
                selectedNodeKeys={selectedNodeKeys}
                handleShowConfirmarModalReativarContrato={setShowConfirmarModalReativarContrato}
                handleShowModalReativarContrato={setShowModalReativarContrato}
            />
            <Paginacao
                totalPages={totalPages}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                itemsPerPage={itemsPerPage}
                setItemsPorPagina={setItemsPerPage}
            />
        </div >
    );
};

export default TableComponentsContrato;
