import React, { useEffect, useState } from 'react';
import { Modal, Container, Row, Col, Form } from 'react-bootstrap';
import { Button } from 'primereact/button';
import 'react-datepicker/dist/react-datepicker.css';
import { AlertService } from '../../../../components/AlertService';
import api from '../../../../services/api';
import { url } from '../../../../environments/environments-develop';
import { addLocale } from "primereact/api";
import { Calendar } from "primereact/calendar";
import { AutoComplete } from 'primereact/autocomplete';
import { useSidebarContext } from '../../../../contexts/SidebarContext';
import TokenService from '../../../../services/Auth/token.service';
import { InputNumber } from 'primereact/inputnumber';
import { showToast } from '../../../../components/ToastComponent';
import ModalImoveisGerados from './ModalImoveisGerados';

interface ModalImovelGrupoProps {
    showModal: boolean;
    onHide: () => void;
}

const ModalImovelGrupo: React.FC<ModalImovelGrupoProps> = ({
    showModal,
    onHide,
}) => {
    const [imovelData, setImovelData] = useState<any>({
        status: "1",
        incremento: 1
    });
    const [tipoImovel, setTipoImovel] = useState<any>([]);
    const [subTipoImovel, setSubTipoImovel] = useState<any>([]);
    const [classImovel, setClassImovel] = useState<any>([]);
    const [ult_pgto_iptu, setUltPgtoIptu] = useState<Date | null>(null);
    const [duplicatedImoveis, setDuplicatedImoveis] = useState<any[]>([]);
    const [showModalImoveisGerados, setShowModalImoveisGerados] = useState(false);
    const [validated, setValidated] = useState(false);

    const handleChange = (field: string, value: any) => {
        setImovelData((prevGrupoData: any) => {
            return {
                ...prevGrupoData,
                [field]: value
            };
        });
    };

    const validateFields = () => {
        const requiredFields = [
            'identificador1', 'loteInicial', 'loteFinal', 'tipo_imovel_id'
        ];
        for (const field of requiredFields) {
            if (!imovelData[field]) {
                return false;
            }
        }
        return true;
    };
    console.log(imovelData);
    const validateClassificacao = () => {
        const preco = imovelData.imo_preco;
        const classif = imovelData.classif_preco_id;
        if (preco && classif) {
            return true;
        }
        if (preco || classif) {
            return true;
        }
        return false;
    };

    const duplicateImoveis = () => {
        if (!validateFields()) {
            setValidated(true);
            showToast('error', 'Por favor, preencha o valor do imóvel ou informe a sua classificação.');
            return;
        };

        const loteInicial = parseInt(imovelData.loteInicial);
        const loteFinal = parseInt(imovelData.loteFinal);

        if (isNaN(loteInicial) || isNaN(loteFinal)) {
            showToast('error', 'Lote inicial ou final inválido.');
            return;
        };

        const newImoveis = [];

        for (let i = loteInicial, step = 0; i <= (loteInicial + loteFinal - 1); i++, step++) {
            newImoveis.push({
                ...imovelData,
                identificador2: (loteInicial + (step * imovelData.incremento)).toString(),
            });
        }


        setDuplicatedImoveis(newImoveis);
        setShowModalImoveisGerados(true);
    };
    useEffect(() => {
        const ultPgtoIptuValue = imovelData.ult_pgto_iptu;
        if (ultPgtoIptuValue) {
            const parsedDate = new Date(ultPgtoIptuValue);
            if (!isNaN(parsedDate.getTime())) {
                setUltPgtoIptu(parsedDate);
            } else {
                console.error("Invalid date format for ult_pgto_iptu:", ultPgtoIptuValue);
            }
        }
    }, [imovelData.ult_pgto_iptu]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const consulta: any = {
                    consulta: {
                        order: "id;desc",
                        filtro: {
                            "empreendimento_id": TokenService.getEmpreendimentoId() ? TokenService.getEmpreendimentoId().id : null
                        }
                    }
                };

                const queryParams: any = {};

                const camposEnviar = ['consulta'];

                camposEnviar.forEach((key: any) => {
                    const value = consulta[key];
                    if (value !== undefined && value !== null && value !== '') {
                        if (key === 'consulta') {
                            queryParams[key] = JSON.stringify(value);
                        } else {
                            queryParams[key] = value;
                        }
                    }
                });

                const queryString = Object.entries(queryParams)
                    .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(String(value))}`)
                    .join('&');

                const [responseTiposImovel, responseSubtiposImovel, responseClassImovel] = await Promise.all([
                    api.get(`${url}/api/v1/admin/vendas/tipoimovel?${queryString}`),
                    api.get(`${url}/api/v1/admin/vendas/subtipoimovel?${queryString}`),
                    api.get(`${url}/api/v1/admin/vendas/classificacaoimoveis/index-rotulos?${queryString}`)
                ]);
                if (responseTiposImovel.status === 200) {
                    setTipoImovel(responseTiposImovel.data.data);
                }
                if (responseSubtiposImovel.status === 200) {
                    setSubTipoImovel(responseSubtiposImovel.data.data);
                }
                if (responseClassImovel.status === 200) {
                    setClassImovel(responseClassImovel.data.data);
                }
            } catch (error) {
                AlertService.error('Erro!', String('Erro desconhecido'));
            }
        };
        fetchData();
    }, []);

    addLocale('pt', {
        firstDayOfWeek: 0,
        dayNames: ['domingo', 'segunda-feira', 'terça-feira', 'quarta-feira', 'quinta-feira', 'sexta-feira', 'sábado'],
        dayNamesShort: ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sáb'],
        dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
        monthNames: ['janeiro', 'fevereiro', 'março', 'abril', 'maio', 'junho', 'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro'],
        monthNamesShort: ['jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez'],
        today: 'Hoje',
        clear: 'Limpar'
    });

    return (
        <Modal
            show={showModal}
            size='xl'
            onHide={() => onHide()}
        >
            <Modal.Header closeButton>
                <Modal.Title>Imoveis em grupo</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container>
                    <Form.Group className="imovel-form" style={{ overflowX: "hidden" }}>
                        <Form.Group className="imovel-form">
                            <Row className="mb-3">
                                <Col>
                                    <Form.Label>
                                        {TokenService.getEmpreendimentoId() ? TokenService.getEmpreendimentoId().alias_identificador1 : 'Identificador 1'}
                                    </Form.Label>
                                    <Form.Control
                                        value={imovelData?.identificador1}
                                        onChange={(e) => handleChange('identificador1', e.target.value)}
                                        isInvalid={!imovelData?.identificador1}
                                    />
                                </Col>
                                <Col>
                                    <Form.Label>
                                        {TokenService.getEmpreendimentoId() ? TokenService.getEmpreendimentoId().alias_identificador2 + ' Inicial' : 'Identificador 2 Inicial'}
                                    </Form.Label>
                                    <Form.Control
                                        value={imovelData?.loteInicial}
                                        onChange={(e) => handleChange('loteInicial', e.target.value)}
                                        isInvalid={!imovelData?.loteInicial}
                                    />
                                </Col>
                                <Col>
                                    <Form.Label>
                                        Quantidade de lotes
                                    </Form.Label>
                                    <Form.Control
                                        value={imovelData?.loteFinal}
                                        onChange={(e) => handleChange('loteFinal', e.target.value)}
                                        isInvalid={!imovelData?.loteFinal}
                                    />
                                </Col>
                                <Col>
                                    <Form.Label>
                                        Incremento
                                    </Form.Label>
                                    <Form.Control
                                        type="number"
                                        value={imovelData.incremento ?? 1}
                                        onChange={(e) => handleChange("incremento", Number(e.target.value))}
                                        isInvalid={!imovelData.incremento}
                                    />
                                </Col>

                            </Row>
                            <Row>
                                <Col>
                                    <Form.Label>Logradouro</Form.Label>
                                    <Form.Control value={imovelData?.logradouro} onChange={(e) => handleChange('logradouro', e.target.value)} />
                                </Col>
                                <Col>
                                    <Form.Label>Área ( m² )</Form.Label>
                                    <InputNumber
                                        style={{ width: '100%', height: '38px' }}
                                        value={imovelData?.area ? imovelData?.area : null}
                                        onChange={(e) => handleChange('area', e.value)}
                                        locale="pt-BR"
                                        minFractionDigits={2}
                                        maxFractionDigits={24}
                                        suffix=" m²"
                                        invalid={!imovelData?.area}
                                    />
                                </Col>
                                <Col>
                                    <Form.Label>INCDEC</Form.Label>
                                    <InputNumber
                                        style={{ width: '100%', height: '38px' }}
                                        value={imovelData?.incdec ? imovelData?.incdec : null}
                                        onChange={(e) => handleChange('incdec', e.value)}
                                        locale="pt-BR"
                                        minFractionDigits={2}
                                        maxFractionDigits={24}
                                    />
                                </Col>
                                <Form.Group as={Col} controlId="formGridState">
                                    <Form.Label>Status</Form.Label>
                                    <Form.Select value={imovelData?.status || ''} onChange={(e) => handleChange('status', e.target.value)}>
                                        <option>Selecione...</option>
                                        <option value={"1"}>Disponível</option>
                                        <option value={"2"}>Reservado</option>
                                        <option value={"3"}>Reserva Fixa</option>
                                        <option value={"4"}>Negociado</option>
                                        <option value={"5"}>Vendido</option>
                                        <option value={"6"}>Reserva Técnica</option>
                                        <option value={"7"}>Reserva Diretoria</option>
                                    </Form.Select>
                                </Form.Group>
                            </Row>
                        </Form.Group>
                        <Form.Group className="imovel-form">
                            <Row>
                                <h3 className="label-form-create-imovel">Detalhes </h3>
                            </Row>
                            <Row className="mb-3">
                                <Col>
                                    <Form.Label>Frente</Form.Label>
                                    <InputNumber
                                        style={{ width: '100%', height: '38px' }}
                                        value={imovelData?.frente ? imovelData?.frente : null}
                                        onChange={(e) => handleChange('frente', e.value)}
                                        locale="pt-BR"
                                        minFractionDigits={2}
                                        maxFractionDigits={24}
                                    />
                                </Col>
                                <Col>
                                    <Form.Label>Fundo</Form.Label>
                                    <InputNumber
                                        style={{ width: '100%', height: '38px' }}
                                        value={imovelData?.fundo ? imovelData?.fundo : null}
                                        onChange={(e) => handleChange('fundo', e.value)}
                                        locale="pt-BR"
                                        minFractionDigits={2}
                                        maxFractionDigits={24}
                                    />
                                </Col>
                                <Col>
                                    <Form.Label>Confrontante do Fundo </Form.Label>
                                    <Form.Control value={imovelData?.confr_fundo} onChange={(e) => handleChange('confr_fundo', e.target.value)} />
                                </Col>
                                <Col>
                                    <Form.Label>Lado direto</Form.Label>
                                    <InputNumber
                                        style={{ width: '100%', height: '38px' }}
                                        value={imovelData?.lado_direito ? imovelData?.lado_direito : null}
                                        onChange={(e) => handleChange('lado_direito', e.value)}
                                        locale="pt-BR"
                                        minFractionDigits={2}
                                        maxFractionDigits={24}
                                    />
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Col>
                                    <Form.Label>Confrontante do Lado Direito</Form.Label>
                                    <Form.Control value={imovelData?.confr_lado_direito} onChange={(e) => handleChange('confr_lado_direito', e.target.value)} />
                                </Col>
                                <Col>
                                    <Form.Label>Lado Esquerdo</Form.Label>
                                    <InputNumber
                                        style={{ width: '100%', height: '38px' }}
                                        value={imovelData?.lado_esquerdo ? imovelData?.lado_esquerdo : null}
                                        onChange={(e) => handleChange('lado_esquerdo', e.value)}
                                        locale="pt-BR"
                                        minFractionDigits={2}
                                        maxFractionDigits={24}
                                    />
                                </Col>
                                <Col>
                                    <Form.Label>Confrontante do Lado Esquerdo </Form.Label>
                                    <Form.Control value={imovelData?.confr_lado_esquerdo} onChange={(e) => handleChange('confr_lado_esquerdo', e.target.value)} />
                                </Col>
                                <Col>
                                    <Form.Label>Chanfro</Form.Label>
                                    <InputNumber
                                        style={{ width: '100%', height: '38px' }}
                                        value={imovelData?.chanfro ? imovelData?.chanfro : null}
                                        onChange={(e) => handleChange('chanfro', e.value)}
                                        locale="pt-BR"
                                        minFractionDigits={2}
                                        maxFractionDigits={24}
                                    />
                                </Col>

                            </Row>
                            <Row className="mb-3">
                                <Col>
                                    <Form.Label>Preço do Imóvel</Form.Label>
                                    <InputNumber
                                        style={{ width: '100%', height: '38px' }}
                                        value={imovelData?.imo_preco}
                                        onChange={(e) => handleChange('imo_preco', e.value)}
                                        mode="currency" currency="BRL" locale="pt-BR"
                                        invalid={!validateClassificacao()}
                                    />
                                </Col>
                                <Col>
                                    <Form.Label>Valor da Intermediação do Imóvel</Form.Label>
                                    <InputNumber
                                        style={{ width: '100%', height: '38px' }}
                                        value={imovelData?.imo_intermed}
                                        onChange={(e) => handleChange('imo_intermed', e.value)}
                                        mode="currency" currency="BRL" locale="pt-BR"
                                    />
                                </Col>
                                <Col>
                                    <Form.Label>Matrícula do Imóvel </Form.Label>
                                    <Form.Control value={imovelData?.imo_matricula} onChange={(e) => handleChange('imo_matricula', e.target.value)} />
                                </Col>

                                <Col >
                                    <Form.Label>Data do Ult. Pgto do Iptu</Form.Label>
                                    <Calendar
                                        value={ult_pgto_iptu || null}
                                        onChange={(event: any) => {
                                            const selectedDates = event.value;
                                            setUltPgtoIptu(selectedDates);
                                            handleChange('ult_pgto_iptu', selectedDates ? selectedDates.toISOString() : null);
                                        }}
                                        placeholder="Data do Ult. Pgto do Iptu"
                                        showButtonBar
                                        selectionMode="single"
                                        dateFormat="dd/mm/yy"
                                        locale="pt"
                                        readOnlyInput
                                        style={{ textAlign: 'center', width: '100%' }}
                                        panelClassName="custom-calendar-panel"
                                        required
                                    />
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Col>
                                    <Form.Label>Tipo Imóvel</Form.Label>
                                    <Form.Select
                                        value={imovelData?.tipo_imovel_id}
                                        onChange={(e) => handleChange('tipo_imovel_id', e.target.value)}
                                        isInvalid={!imovelData?.tipo_imovel_id}
                                    >
                                        <option>Selecione...</option>
                                        {tipoImovel.map((tipoimovel: any) => (
                                            <option value={tipoimovel.id}>{tipoimovel.nome}</option>
                                        ))}
                                    </Form.Select>
                                </Col>
                                <Col>
                                    <Form.Label>SubTipo Imovel</Form.Label>
                                    <Form.Select value={imovelData?.sub_tipo_imovel_id} onChange={(e) => handleChange('sub_tipo_imovel_id', e.target.value)}>
                                        <option>Selecione...</option>
                                        {subTipoImovel.map((subtipo: any) => (
                                            <option value={subtipo.id}>{subtipo.nome}</option>
                                        ))}
                                    </Form.Select>                    </Col>
                                <Col>
                                    <Form.Label>Classificação do Imóvel</Form.Label>
                                    <Form.Select
                                        value={imovelData?.classif_preco_id}
                                        onChange={(e) => handleChange('classif_preco_id', e.target.value)}
                                        isInvalid={!validateClassificacao()}
                                    >
                                        <option>Selecione...</option>
                                        {classImovel.map((classificacao: any) => (
                                            <option value={classificacao.id}>{classificacao.rotulo}</option>
                                        ))}
                                    </Form.Select>
                                </Col>
                                <Col>
                                    <Form.Label>Observação</Form.Label>
                                    <Form.Control value={imovelData?.observacao} onChange={(e) => handleChange('observacao', e.target.value)} />
                                </Col>
                            </Row>
                        </Form.Group >
                    </Form.Group>
                    <ModalImoveisGerados
                        nodes={duplicatedImoveis}
                        showModal={showModalImoveisGerados}
                        onHide={() => setShowModalImoveisGerados(false)}
                        onHideForm={onHide}
                        setNodes={setDuplicatedImoveis}
                    />
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    style={{ borderRadius: '20px' }}
                    severity="success"
                    label="Gerar Imóveis"
                    onClick={duplicateImoveis}
                />
            </Modal.Footer>
        </Modal>
    );
};

export default ModalImovelGrupo;
