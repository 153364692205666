import iconMenuTresPonto from '../../../../../assets/images/icon-menu-tres-pontos.png';
import { Button } from 'primereact/button';
import React, { useState } from 'react';
import { useSidebarContext } from '../../../../contexts/SidebarContext';
import { Col, Form, NavLink } from 'react-bootstrap';
import 'react-autocomplete-input/dist/bundle.css';

interface ContextSearchProps {
    header?: string;
    filtrosData: {
        nome: string | null;
        cpf_cnpj: string | null;
        email: string | null;
        creci: string | null;
    };
    onInputClearList: () => void;
    onInputChangeList: (field: string, value: any) => void;
    onInputChange: (field: string, value: any) => void;
}

function ContentSearchCorretores(props: ContextSearchProps) {
    const { nome, cpf_cnpj, email, creci } = props.filtrosData || {};
    const { isVisible, updateVisibility } = useSidebarContext();

    const toggleVisibility = () => {
        const newValue = !isVisible;
        updateVisibility(newValue);
    };

    const handleChange = (field: string, value: any) => {
        props.onInputChange(field, value);
    };

    const handleChangeList = (field: string, value: any) => {
        props.onInputChangeList(field, value);
    };

    const handleClearList = () => {
        props.onInputClearList();
    };

    const handleFilter = () => {
        if (nome) {
            handleChangeList('nome', nome);
        }
        if (cpf_cnpj) {
            handleChangeList('cpf_cnpj', cpf_cnpj);
        }
        if (email) {
            handleChangeList('email', email);
        }
        if (creci) {
            handleChangeList('creci', creci);
        }
    };

    return (
        <div className="contentSearch">
            <div className="contentSearch-header">
                <NavLink onClick={toggleVisibility}>
                    <img
                        className="menu-context"
                        src={iconMenuTresPonto}
                    />
                </NavLink>
                <Col className='text-center' style={{ fontSize: '20px', color: '#494949' }}>{props.header}</Col>
            </div>
            <div style={{ opacity: '34%' }} className="contentTraçoBodyHeader"></div>
            <div className="contentSearch-body">
                <div className='div-calendar'>
                    <Col md="3" style={{ marginRight: "8px" }}>
                        <Form.Label>Nome</Form.Label>
                        <Form.Control
                            value={nome ? nome : ''}
                            onChange={(e) => handleChange("nome", e.target.value)}
                        />
                    </Col>
                    <Col md="3" style={{ marginRight: "8px" }}>
                        <Form.Label>Cpf/Cnpj</Form.Label>
                        <Form.Control
                            value={cpf_cnpj ? cpf_cnpj : ''}
                            onChange={(e) => handleChange("cpf_cnpj", e.target.value)}
                            onBlur={(e) => handleChange("cpf_cnpj", e.target.value)}

                        />
                    </Col>
                    <Col md="3" style={{ marginRight: "8px" }}>
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                            value={email ? email : ''}
                            type='email'
                            onChange={(e) => handleChange("email", e.target.value)}
                        />
                    </Col>
                    <Col md="2" style={{ marginRight: "8px" }}>
                        <Form.Label>Creci</Form.Label>
                        <Form.Control
                            value={creci ? creci : ''}
                            onChange={(e) => handleChange("creci", e.target.value)}
                        />
                    </Col>
                </div>
                <div className='div-filtros-avancados' style={{ marginTop: "35px" }} >
                    <Button
                        onClick={handleFilter}
                        style={{ height: '30px', borderRadius: '20px', padding: '5px 10px', marginRight: '5px', fontSize: '14px' }}
                        type="button"
                        severity="success"
                        icon="pi pi-filter"
                        rounded>
                        <p style={{ marginLeft: '5px', marginTop: '15px' }}>Pesquisar</p>
                    </Button>
                    <Button
                        onClick={handleClearList}
                        style={{ height: '30px', borderRadius: '20px', padding: '5px 10px', marginRight: '5px', fontSize: '14px' }}
                        type="button"
                        severity="danger"
                        icon="pi pi-delete-left"
                        rounded>
                        <p style={{ marginLeft: '5px', marginTop: '15px' }}>Limpar</p>
                    </Button>
                </div>
            </div>
        </div>
    );
}

export default ContentSearchCorretores;
