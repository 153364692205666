import { Button } from "react-bootstrap";
import api from "../services/api";
import { useAppContext } from "../contexts/AppContext";
import { AlertService } from "./AlertService";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { showToast } from "./ToastComponent";

function ButtonSaveOrUpdateComponent() {
  const { url, body, typeRequest, validationRequest } = useAppContext();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();

  async function actionButton(): Promise<void> {
    if (isSubmitting) return;
    if (!validationRequest) {
      return showToast('warn', 'Preencha os campos obrigatórios!');
    }
    setIsSubmitting(true);
    try {
      let response = null;
      switch (typeRequest) {
        case "PUT":
          response = await api.put(`${url}`, body);
          break;
        case "POST":
          response = await api.post(`${url}`, body);
          break;
        default:
          response = await api.post(`${url}`, body);
          break;
      }

      if (response.status === 200) {
        AlertService.sucess('Sucesso!', 'Salvo com sucesso!').then(() => {
          window.history.back();
          setTimeout(() => {
            window.location.reload();
          }, 2000)
        });
      }
    } catch (error: any) {
      let mensagens: string[] = [];
      Object.keys(error?.response?.data?.error?.message).forEach(key => {
        mensagens.push('\n' + error?.response?.data?.error?.message[key][0]);
      });
      AlertService.error('Erro!', String(mensagens));
    } finally {
      setIsSubmitting(false);
    }
  }

  const goBack = () => {
    window.history.back();
  }

  return (
    <>
      <Button
        disabled={isSubmitting}
        onClick={() => goBack()}
        variant="secondary"
        style={{ marginRight: '10px' }}
      >
        Voltar
      </Button>
      <Button
        className="button-save-or-update"
        disabled={isSubmitting}
        onClick={() => actionButton()}
        variant="success"
      >
        {isSubmitting ? 'Enviando...' : 'Salvar'}
      </Button>
    </>
  );
}

export default ButtonSaveOrUpdateComponent;
