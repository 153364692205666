import React from 'react';

interface CircleChartProps {
  label: string;
  value: number;
  total: number;
  color: string;
  size?: number;
}

const CircleChart: React.FC<CircleChartProps> = ({ label, value, total, color, size = 130 }) => {
  const borderWidth = 10;
  const radius = (size - borderWidth) / 2;
  const circumference = 2 * Math.PI * radius;

  const calculateProgress = (value: number) => {
    if (!total || total === 0) return circumference;
    const percentage = (value / total) * 100;
    return circumference - (percentage / 100) * circumference;
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '10px' }}>
      <div style={{ position: 'relative', width: size, height: size }}>
        <svg width={size} height={size}>
          <circle
            cx={size / 2}
            cy={size / 2}
            r={radius}
            fill="none"
            stroke="#f0f0f0"
            strokeWidth={borderWidth}
          />
          <circle
            cx={size / 2}
            cy={size / 2}
            r={radius}
            fill="none"
            stroke={color}
            strokeWidth={borderWidth}
            strokeDasharray={circumference}
            strokeDashoffset={calculateProgress(value)}
            strokeLinecap="round"
            style={{ transition: 'stroke-dashoffset 0.5s ease' }} 
          />
        </svg>
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 1,
            textAlign: 'center',
          }}
        >
          <h6 style={{ fontSize: '18px', margin: '0' }}>{value}</h6>
        </div>
      </div>
      <p style={{ width: 'max-content', marginTop: '8px', fontWeight: 'bold', fontSize: '14px', textAlign: 'center' }}>
        {label}
      </p>
    </div>
  );
};

export default CircleChart;
