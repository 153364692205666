export const addTelefone = (
  data: any,
  updateData: ((data: any) => void) | undefined
) => {
  if (updateData && data) {
    const novoTelefone: any = {
      nome_contato: "",
      ddd: "",
      numero: "",
      tipo_telefone: 1,
      observacao: "",
    };
    const telefones =
      (data?.pessoa?.telefones?.data
        ? data?.pessoa?.telefones?.data
        : data?.pessoa?.telefones) || [];
    updateData({
      ...data,
      pessoa: {
        ...data.pessoa,
        telefones: [...telefones, novoTelefone],
      },
    });
  }
};

export const removeTelefone = (
  data: any,
  updateData: ((data: any) => void) | undefined,
  index: number
) => {
  const telefones = data?.pessoa?.telefones?.data
    ? data?.pessoa?.telefones?.data
    : data?.pessoa?.telefones;
  if (data && updateData) {
    const updatedTelefones = [...telefones];
    updatedTelefones.splice(index, 1);

    updateData({
      ...data,
      pessoa: {
        ...data.pessoa,
        telefones: updatedTelefones,
      },
    });
  }
};

export const changeTelefone = (
  data: any,
  updateData: ((data: any) => void) | undefined,
  index: number,
  field: string,
  value: string
) => {
  const telefones = data?.pessoa?.telefones?.data
    ? data?.pessoa?.telefones?.data
    : data?.pessoa?.telefones;
  if (data && telefones && updateData) {
    const updatedTelefones = data?.pessoa?.telefones?.data
      ? [...data.pessoa.telefones?.data]
      : [...data.pessoa.telefones];
    updatedTelefones[index] = {
      ...updatedTelefones[index],
      [field]: value,
    };
    updateData({
      ...data,
      pessoa: {
        ...data.pessoa,
        telefones: updatedTelefones,
      },
    });
  }
};
