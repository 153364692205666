// financeiroModels.ts

import { Deserializable } from "../../../../core/interfaces/deserializable.model";
import { BaseModel } from "../../Pessoas/Models/BaseModel";
import { ClienteModel } from "../../Pessoas/Models/ClienteModel";

export interface IRenegociacao{
  contrato_id: number | null;
  cliente_id: number | null;
  cliente: ClienteModel | ClienteModel[] | null;
  user_id: number | null;
  empreendimento_id: number | null;
  filial_id: number | null;
  numero_parcela: number | null;
  vincular_homologacao: number | null;
  data_assinatura_contrato: Date | null;
  data_novo_reajuste: Date | null;
  data_anterior_reajuste: Date | null;
  valor_primeira_parcela: number | null;
  data_primeiro_vencimento: Date | null;
  nova_quantidade_parcelas: number | null;
  valor_final: number | null;
  valor_desconto: number | null;
  valor_juros: number | null;
  valor_multa: number | null;
  valor_total_sem_juros: number | null;
  quantidade_parcelas_renegociadas: number | null;
  parcelas_negociadas: number | null;
  data_renegociacao: Date | null;
}

export class RenegociacaoModel
  extends BaseModel
  implements IRenegociacao, Deserializable
{
  contrato_id: number | null = null;
  cliente_id: number | null = null;
  cliente: ClienteModel | ClienteModel[] | null = null;
  user_id: number | null = null;
  empreendimento_id: number | null = null;
  filial_id: number | null = null;
  numero_parcela: number | null = null;
  vincular_homologacao: number | null = null;
  data_assinatura_contrato: Date | null = null;
  data_novo_reajuste: Date | null = null;
  data_anterior_reajuste: Date | null = null;
  valor_primeira_parcela: number | null = null;
  data_primeiro_vencimento: Date | null = null;
  nova_quantidade_parcelas: number | null = null;
  valor_final: number | null = null;
  valor_desconto: number | null = null;
  valor_juros: number | null = null;
  valor_multa: number | null = null;
  valor_total_sem_juros: number | null = null;
  quantidade_parcelas_renegociadas: number | null = null;
  parcelas_negociadas: number | null = null;
  data_renegociacao: Date | null = null;

  deserialize(input: any): this {
    Object.assign(this, input);
    if (input?.cliente) {
      this.cliente = new ClienteModel().deserialize(input.cliente);
    } else if (input?.cliente?.data) {
      this.cliente = new ClienteModel().deserialize(input.cliente.data);
    }

    return this;
  }
}
