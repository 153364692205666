// SidebarContext.tsx
import React, { ReactNode, createContext, useContext, useEffect, useState } from 'react';
import { useAppContext } from './AppContext';

interface SidebarContextProps {
  isVisible: boolean;
  updateVisibility: (newValue: boolean) => void;
}

const SidebarContext = createContext<SidebarContextProps | undefined>(undefined);
interface ContextProps {
  children: ReactNode;
}
export const SidebarProvider: React.FC<ContextProps> = ({ children }) => {
  const [isVisible, setIsVisible] = useState(() => {
    const storedValue = localStorage.getItem('isVisible');
    return storedValue ? JSON.parse(storedValue) : true;
  });
  useEffect(() => {
    setShowSubSidebar(isVisible);
  }, [])
  const { setShowSubSidebar } = useAppContext();
  const updateVisibility = (newValue: boolean) => {
    setIsVisible(newValue);
    setShowSubSidebar(newValue);
    localStorage.setItem('isVisible', JSON.stringify(newValue));
  };

  const contextValue: SidebarContextProps = {
    isVisible,
    updateVisibility,
  };

  return <SidebarContext.Provider value={contextValue}>{children}</SidebarContext.Provider>;
};

export const useSidebarContext = () => {
  const context = useContext(SidebarContext);
  if (!context) {
    throw new Error('useSidebarContext deve ser usado dentro de um AppProvider');
  }
  return context;
};
