export const changeCompradores = (
  data: any,
  updateData: ((data: any) => void) | undefined,
  index: number,
  field: string,
  value: any
) => {
  if (updateData) {
    // Verificar se data e cliente_compradores não são nulos ou indefinidos
    if (!data || !data.cliente_compradores) {
      console.error("Data or cliente_compradores is undefined");
      return;
    }

    // Clonar o array de compradores
    const updatedCompradores = [...data.cliente_compradores];

    // Verificar se o índice está dentro dos limites do array
    if (index < 0 || index >= updatedCompradores.length) {
      console.error("Index out of bounds");
      return;
    }

    // Atualizar o campo especificado
    updatedCompradores[index] = {
      ...updatedCompradores[index],
      [field]: value,
    };

    // Chamar updateData com os dados atualizados
    updateData({
      ...data,
      cliente_compradores: updatedCompradores,
    });
  }
};

export const addCompradores = (
  data: any,
  updateData: ((data: any) => void) | undefined
) => {
  if (data && updateData) {
    const novoCompradores: any = {
      cliente_id: "",
      conjugue: "",
      principal: false,
    };
    const compradores =
      (data?.cliente_compradores ? data?.cliente_compradores : data) || [];
    if (data?.cliente_compradores) {
      updateData((prevData: any) => {
        return {
          ...prevData,
          cliente_compradores: [...compradores, novoCompradores],
        };
      });
    } else {
      updateData((prevData: any) => {
        return {
          ...prevData,
          cliente_compradores: [novoCompradores],
        };
      });
    }
  }
};

export const removeCompradores = (
  data: any,
  updateData: ((data: any) => void) | undefined,
  index: number
) => {
  if (data && updateData) {
    const updatedCompradores = [...data?.cliente_compradores];
    updatedCompradores.splice(index, 1);
    updateData({
      ...data,
      cliente_compradores: updatedCompradores,
    });
  }
};
