// ReligiaoModel.ts

export interface IReligiao {
    id: number | null;
    descricao: string | null;
}

export class ReligiaoModel implements IReligiao {
    id: number | null = null;
    descricao: string | null = null;

    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
      }
}
