import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Button, Modal } from 'react-bootstrap';
import { url } from '../../../../environments/environments-develop';
import { AlertService } from '../../../../components/AlertService';
import api from '../../../../services/api';
import { useState } from 'react';

interface ModalConfirmarCancelarContratoProps {
    showConfirmarModalCancelarContrato: boolean;
    selectedNodeKeys: any[];
    handleShowConfirmarModalCancelarContrato: (show: boolean) => void;
    handleShowModalCancelarContrato: (show: boolean) => void;
    handleData: (newData: any) => void;
}

function ModalConfirmarCancelarContrato({
    showConfirmarModalCancelarContrato,
    selectedNodeKeys,
    handleShowConfirmarModalCancelarContrato,
    handleShowModalCancelarContrato,
    handleData,
}: ModalConfirmarCancelarContratoProps) {
    async function buscar() {
        try {
            const response = await api.get(`${url}/api/v1/admin/vendas/detalhes-cancelamento-contrato/${selectedNodeKeys?.[0]?.id}`);
            handleData(response?.data);
        } catch (error) {
            AlertService.error('Erro', 'Não foi possível atualizar os dados: ' + String(error));
        }
    };

    return (
        <Modal
            show={showConfirmarModalCancelarContrato}
            size='xl'
            onHide={() => handleShowConfirmarModalCancelarContrato(false)}
        >
            <Modal.Header closeButton>
                <Modal.Title className='text-center'>
                    Confirmar o cancelamento do seguinte contrato?
                </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ padding: '0px !important' }}>
                <DataTable
                    stripedRows
                    lazy
                    value={selectedNodeKeys ? selectedNodeKeys : []}
                    tableStyle={{ minWidth: '50rem' }}
                    emptyMessage={
                        <span style={{ display: 'flex', justifyContent: 'center', fontStyle: 'italic' }}>
                            Nenhum item encontrado.
                        </span>
                    }
                >
                    <Column
                        key={'id'}
                        field={'id'}
                        header={'Id'}
                        style={{ textAlign: 'center', borderBottom: '1px solid #bdbdbd96' }}
                    />
                    <Column
                        key={'numero'}
                        field={'numero'}
                        header={'Nº Contrato'}
                        style={{ textAlign: 'center', borderBottom: '1px solid #bdbdbd96' }}
                    />
                    <Column
                        key={'data_venda'}
                        field={'data_venda'}
                        header={'Data Venda'}
                        style={{ textAlign: 'center', borderBottom: '1px solid #bdbdbd96' }}
                    />
                    <Column
                        key={'status_name'}
                        field={'status_name'}
                        header={'Status'}
                        style={{ textAlign: 'center', borderBottom: '1px solid #bdbdbd96' }}
                    />
                </DataTable>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="success"
                    onClick={() => {
                        handleShowModalCancelarContrato(true);
                        handleShowConfirmarModalCancelarContrato(false);
                        buscar();
                    }}
                >
                    Confirmar
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default ModalConfirmarCancelarContrato;
