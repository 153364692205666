import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import { useAppContext } from '../../../../contexts/AppContext';
import { url } from '../../../../environments/environments-develop';
import TokenService from '../../../../services/Auth/token.service';
import FormTipoJazigo from './forms';

interface PropsData {
    descricao?: string;
    qtd_gavetas?: number;
    modelo?: number;
    sigla?: string;
    material?: string;
    observacao?: string;
}

function CreateTipoJazigo() {
    const { setUrl, setBody, setShowButtonSave, setValidationRequest, setItemsBreadsCrumbs } = useAppContext();
    const [formBasico, setFormBasico] = useState<PropsData>({});

    useEffect(() => {
        setItemsBreadsCrumbs([
            {
                label: 'Cemitério',
                url: '/cemiterio',
            },
            {
                label: 'Estruturas'
            },
            {
                label: 'Tipos de Jazigos',
                url: '/cemiterio/tipo-jazigo',
            },
            {
                label: 'Criar'
            }
        ]);
        setUrl(`${url}/api/v1/admin/cemiterio/tipo-jazigo`);
        setShowButtonSave(true);
        setFormBasico((prevImovelData) => {
            return {
                ...prevImovelData,
                ['empreendimento_id']: TokenService.getEmpreendimentoId()?.id
            };
        });
        setValidationRequest(false);
    }, []);

    const handleChange = (field: string, value: string) => {
        setFormBasico((prevGrupoData) => {
            return {
                ...prevGrupoData,
                [field]: value
            };
        });
        setBody(formBasico)
    };

    useEffect(() => {
        if (
            formBasico?.descricao &&
            formBasico?.qtd_gavetas &&
            formBasico?.modelo &&
            formBasico?.sigla &&
            formBasico?.material
        ) {
            setValidationRequest(true);
        }
        setBody(formBasico)
    }, [formBasico]);

    return (
        <>
            <div className="body-content">
                <div className="form-create-group">
                    <div className="form-create-group-header">
                        <h3>Cadastrar Tipo de Jazigo</h3>
                    </div>
                </div>
                <div className="form-group-create">
                    <Container>
                        <FormTipoJazigo
                            initialRightValues={[]}
                            Data={formBasico}
                            onInputChange={(field: string, value: string) => handleChange(`${field}`, value)}
                        />
                    </Container>
                </div>
            </div>
        </>
    );
}

export default CreateTipoJazigo;
