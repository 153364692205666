// ContratoModel.ts

export namespace ContratoModel {
  export interface IContrato {
    id: number;
    cliente_nome: string;
    unidade_imovel: string;
    sinal: string;
    nome_contrato: string;
    qtd_gavetas: string;
    qtd_parcelas: string;
    valor_outros: string;
    prox_reajuste: string;
    status: string;
    status_name: string;
    status_label: string;
    numero: string;
    proposta_id: string;
    data_venda: string;
    data_venda_format: string;
    imovel_id: string;
    imo_intermed: string;
    valor_total: string;
    valor_total_format: string;
    entrada: string;
    valor_parcelas: string;
    valor_acertado: string;
    part_corretor: string;
    anotacoes: string;
    valor_desconto: string;
    pasta: string;
    part_imob: string;
    part_corret: string;
    usuario_caixa: string;
    valor_excedente: string;
    prox_reajuste_format: string;
    valor_prox_reajuste: string;
    valor_condominio: string;
    valor_dinheiro: string;
    coordenador_pgto: string;
    data_acerto: string;
    coord_valor_pg: string;
    corr_bonif_valor_pg: string;
    corr_valor_trc_valor_pg: string;
    corr_intmd_valor_pg: string;
    corr_bonif_dtpg: string;
    corr_valor_trc_dtpg: string;
    corr_intmd_dtpg: string;
    multa_dif: string;
    juros_dif: string;
    intermed_valor_desc: string;
    justificativa_desc: string;
    dt_mov_contrato: string;
    securitizado: string;
    intermed_reduzida: string;
    part_corret_reduzida: string;
    part_imob_reduzida: string;
    imob_valor_pg: string;
    valor_presente_tab: string;
    qtd_parc_intermed: string;
    valor_parc_intermed: string;
    venc_prim_parc_intermed: string;
    valor_prim_parc_entrada: string;
    qtd_parc_entrada: string;
    coordenador_id: string;
    fatura_id: string;
    corretor_id: string;
    data_inicial: string;
    data_final: string;
    dia_vencimento: string;
    tx_hon_advoc: string;
    carencia_hon_advoc: string;
    vlr_hon_advoc: string;
    dt_rescindir_contrato: string;
    qtd_impressoes: string;
    tipo_garantia: string;
    saldo_financiar: string;
    corretagem_recebida: string;
    data_prim_parcela: string;
    data_ult_reajuste: string;
    data_ult_reajuste_format: string;
    priorizar_pgto_corretor: string;
    qtd_parcelas_pagas: string;
    data_ass_contrato: string;
    dia_repasse_aluguel: string;
    vistoriador_id: string;
    pgto_aluguel_posterior: string;
    tipo_contrato: string;
    tipo_contrato_name: string;
    tipo_contrato_label: string;
    clausula_especial: string;
    gerou_lancamento: string;
    vigencia_mes: string;
    antigo_id: string;
    protocolar: string;
    indice_reajuste_id: string;
    periodicidade_reajuste: string;
    filial_id: string;
    empreendimento_id: string;
    venc_prim_parc_entrada: string;
  }

  export class ContratoModel implements IContrato {
    id: number;
    nome_contrato: string;
    cliente_nome: string;
    unidade_imovel: string;
    sinal: string;
    qtd_gavetas: string;
    qtd_parcelas: string;
    valor_outros: string;
    prox_reajuste: string;
    status: string;
    status_name: string;
    status_label: string;
    numero: string;
    proposta_id: string;
    data_venda: string;
    data_venda_format: string;
    imovel_id: string;
    imo_intermed: string;
    valor_total: string;
    valor_total_format: string;
    entrada: string;
    valor_parcelas: string;
    valor_acertado: string;
    part_corretor: string;
    anotacoes: string;
    valor_desconto: string;
    pasta: string;
    part_imob: string;
    part_corret: string;
    usuario_caixa: string;
    valor_excedente: string;
    prox_reajuste_format: string;
    valor_prox_reajuste: string;
    valor_condominio: string;
    valor_dinheiro: string;
    coordenador_pgto: string;
    data_acerto: string;
    coord_valor_pg: string;
    corr_bonif_valor_pg: string;
    corr_valor_trc_valor_pg: string;
    corr_intmd_valor_pg: string;
    corr_bonif_dtpg: string;
    corr_valor_trc_dtpg: string;
    corr_intmd_dtpg: string;
    multa_dif: string;
    juros_dif: string;
    intermed_valor_desc: string;
    justificativa_desc: string;
    dt_mov_contrato: string;
    securitizado: string;
    intermed_reduzida: string;
    part_corret_reduzida: string;
    part_imob_reduzida: string;
    imob_valor_pg: string;
    valor_presente_tab: string;
    qtd_parc_intermed: string;
    valor_parc_intermed: string;
    venc_prim_parc_intermed: string;
    valor_prim_parc_entrada: string;
    qtd_parc_entrada: string;
    coordenador_id: string;
    fatura_id: string;
    corretor_id: string;
    data_inicial: string;
    data_final: string;
    dia_vencimento: string;
    tx_hon_advoc: string;
    carencia_hon_advoc: string;
    vlr_hon_advoc: string;
    dt_rescindir_contrato: string;
    qtd_impressoes: string;
    tipo_garantia: string;
    saldo_financiar: string;
    corretagem_recebida: string;
    data_prim_parcela: string;
    data_ult_reajuste: string;
    data_ult_reajuste_format: string;
    priorizar_pgto_corretor: string;
    qtd_parcelas_pagas: string;
    data_ass_contrato: string;
    dia_repasse_aluguel: string;
    vistoriador_id: string;
    pgto_aluguel_posterior: string;
    tipo_contrato: string;
    tipo_contrato_name: string;
    tipo_contrato_label: string;
    clausula_especial: string;
    gerou_lancamento: string;
    vigencia_mes: string;
    antigo_id: string;
    protocolar: string;
    indice_reajuste_id: string;
    periodicidade_reajuste: string;
    filial_id: string;
    empreendimento_id: string;
    venc_prim_parc_entrada: string;

    constructor(data: IContrato) {
      this.id = data.id;
      this.nome_contrato = data.nome_contrato;
      this.cliente_nome = data.cliente_nome;
      this.unidade_imovel = data.unidade_imovel;
      this.sinal = data.sinal;
      this.qtd_gavetas = data.qtd_gavetas;
      this.qtd_parcelas = data.qtd_parcelas;
      this.valor_outros = data.valor_outros;
      this.prox_reajuste = data.prox_reajuste;
      this.status = data.status;
      this.status_name = data.status_name;
      this.status_label = data.status_label;
      this.numero = data.numero;
      this.proposta_id = data.proposta_id;
      this.data_venda = data.data_venda;
      this.data_venda_format = data.data_venda_format;
      this.imovel_id = data.imovel_id;
      this.imo_intermed = data.imo_intermed;
      this.valor_total = data.valor_total;
      this.valor_total_format = data.valor_total_format;
      this.entrada = data.entrada;
      this.valor_parcelas = data.valor_parcelas;
      this.valor_acertado = data.valor_acertado;
      this.part_corretor = data.part_corretor;
      this.anotacoes = data.anotacoes;
      this.valor_desconto = data.valor_desconto;
      this.pasta = data.pasta;
      this.part_imob = data.part_imob;
      this.part_corret = data.part_corret;
      this.usuario_caixa = data.usuario_caixa;
      this.valor_excedente = data.valor_excedente;
      this.prox_reajuste_format = data.prox_reajuste_format;
      this.valor_prox_reajuste = data.valor_prox_reajuste;
      this.valor_condominio = data.valor_condominio;
      this.valor_dinheiro = data.valor_dinheiro;
      this.coordenador_pgto = data.coordenador_pgto;
      this.data_acerto = data.data_acerto;
      this.coord_valor_pg = data.coord_valor_pg;
      this.corr_bonif_valor_pg = data.corr_bonif_valor_pg;
      this.corr_valor_trc_valor_pg = data.corr_valor_trc_valor_pg;
      this.corr_intmd_valor_pg = data.corr_intmd_valor_pg;
      this.corr_bonif_dtpg = data.corr_bonif_dtpg;
      this.corr_valor_trc_dtpg = data.corr_valor_trc_dtpg;
      this.corr_intmd_dtpg = data.corr_intmd_dtpg;
      this.multa_dif = data.multa_dif;
      this.juros_dif = data.juros_dif;
      this.intermed_valor_desc = data.intermed_valor_desc;
      this.justificativa_desc = data.justificativa_desc;
      this.dt_mov_contrato = data.dt_mov_contrato;
      this.securitizado = data.securitizado;
      this.intermed_reduzida = data.intermed_reduzida;
      this.part_corret_reduzida = data.part_corret_reduzida;
      this.part_imob_reduzida = data.part_imob_reduzida;
      this.imob_valor_pg = data.imob_valor_pg;
      this.valor_presente_tab = data.valor_presente_tab;
      this.qtd_parc_intermed = data.qtd_parc_intermed;
      this.valor_parc_intermed = data.valor_parc_intermed;
      this.venc_prim_parc_intermed = data.venc_prim_parc_intermed;
      this.valor_prim_parc_entrada = data.valor_prim_parc_entrada;
      this.qtd_parc_entrada = data.qtd_parc_entrada;
      this.coordenador_id = data.coordenador_id;
      this.fatura_id = data.fatura_id;
      this.corretor_id = data.corretor_id;
      this.data_inicial = data.data_inicial;
      this.data_final = data.data_final;
      this.dia_vencimento = data.dia_vencimento;
      this.tx_hon_advoc = data.tx_hon_advoc;
      this.carencia_hon_advoc = data.carencia_hon_advoc;
      this.vlr_hon_advoc = data.vlr_hon_advoc;
      this.dt_rescindir_contrato = data.dt_rescindir_contrato;
      this.qtd_impressoes = data.qtd_impressoes;
      this.tipo_garantia = data.tipo_garantia;
      this.saldo_financiar = data.saldo_financiar;
      this.corretagem_recebida = data.corretagem_recebida;
      this.data_prim_parcela = data.data_prim_parcela;
      this.data_ult_reajuste = data.data_ult_reajuste;
      this.data_ult_reajuste_format = data.data_ult_reajuste_format;
      this.priorizar_pgto_corretor = data.priorizar_pgto_corretor;
      this.qtd_parcelas_pagas = data.qtd_parcelas_pagas;
      this.data_ass_contrato = data.data_ass_contrato;
      this.dia_repasse_aluguel = data.dia_repasse_aluguel;
      this.vistoriador_id = data.vistoriador_id;
      this.pgto_aluguel_posterior = data.pgto_aluguel_posterior;
      this.tipo_contrato = data.tipo_contrato;
      this.tipo_contrato_name = data.tipo_contrato_name;
      this.tipo_contrato_label = data.tipo_contrato_label;
      this.clausula_especial = data.clausula_especial;
      this.gerou_lancamento = data.gerou_lancamento;
      this.vigencia_mes = data.vigencia_mes;
      this.antigo_id = data.antigo_id;
      this.protocolar = data.protocolar;
      this.indice_reajuste_id = data.indice_reajuste_id;
      this.periodicidade_reajuste = data.periodicidade_reajuste;
      this.filial_id = data.filial_id;
      this.empreendimento_id = data.empreendimento_id;
      this.venc_prim_parc_entrada = data.venc_prim_parc_entrada;
    }

    static deserialize(data: any): ContratoModel {
      return new ContratoModel({
        id: data.id,
        nome_contrato: data.nome_contrato,
        cliente_nome: data.cliente_nome,
        unidade_imovel: data.unidade_imovel,
        sinal: data.sinal,
        qtd_gavetas: data.qtd_gavetas,
        qtd_parcelas: data.qtd_parcelas,
        valor_outros: data.valor_outros,
        prox_reajuste: data.prox_reajuste,
        status: data.status,
        status_name: data.status_name,
        status_label: data.status_label,
        numero: data.numero,
        proposta_id: data.proposta_id,
        data_venda: data.data_venda,
        data_venda_format: data.data_venda_format,
        imovel_id: data.imovel_id,
        imo_intermed: data.imo_intermed,
        valor_total: data.valor_total,
        valor_total_format: data.valor_total_format,
        entrada: data.entrada,
        valor_parcelas: data.valor_parcelas,
        valor_acertado: data.valor_acertado,
        part_corretor: data.part_corretor,
        anotacoes: data.anotacoes,
        valor_desconto: data.valor_desconto,
        pasta: data.pasta,
        part_imob: data.part_imob,
        part_corret: data.part_corret,
        usuario_caixa: data.usuario_caixa,
        valor_excedente: data.valor_excedente,
        prox_reajuste_format: data.prox_reajuste_format,
        valor_prox_reajuste: data.valor_prox_reajuste,
        valor_condominio: data.valor_condominio,
        valor_dinheiro: data.valor_dinheiro,
        coordenador_pgto: data.coordenador_pgto,
        data_acerto: data.data_acerto,
        coord_valor_pg: data.coord_valor_pg,
        corr_bonif_valor_pg: data.corr_bonif_valor_pg,
        corr_valor_trc_valor_pg: data.corr_valor_trc_valor_pg,
        corr_intmd_valor_pg: data.corr_intmd_valor_pg,
        corr_bonif_dtpg: data.corr_bonif_dtpg,
        corr_valor_trc_dtpg: data.corr_valor_trc_dtpg,
        corr_intmd_dtpg: data.corr_intmd_dtpg,
        multa_dif: data.multa_dif,
        juros_dif: data.juros_dif,
        intermed_valor_desc: data.intermed_valor_desc,
        justificativa_desc: data.justificativa_desc,
        dt_mov_contrato: data.dt_mov_contrato,
        securitizado: data.securitizado,
        intermed_reduzida: data.intermed_reduzida,
        part_corret_reduzida: data.part_corret_reduzida,
        part_imob_reduzida: data.part_imob_reduzida,
        imob_valor_pg: data.imob_valor_pg,
        valor_presente_tab: data.valor_presente_tab,
        qtd_parc_intermed: data.qtd_parc_intermed,
        valor_parc_intermed: data.valor_parc_intermed,
        venc_prim_parc_intermed: data.venc_prim_parc_intermed,
        valor_prim_parc_entrada: data.valor_prim_parc_entrada,
        qtd_parc_entrada: data.qtd_parc_entrada,
        coordenador_id: data.coordenador_id,
        fatura_id: data.fatura_id,
        corretor_id: data.corretor_id,
        data_inicial: data.data_inicial,
        data_final: data.data_final,
        dia_vencimento: data.dia_vencimento,
        tx_hon_advoc: data.tx_hon_advoc,
        carencia_hon_advoc: data.carencia_hon_advoc,
        vlr_hon_advoc: data.vlr_hon_advoc,
        dt_rescindir_contrato: data.dt_rescindir_contrato,
        qtd_impressoes: data.qtd_impressoes,
        tipo_garantia: data.tipo_garantia,
        saldo_financiar: data.saldo_financiar,
        corretagem_recebida: data.corretagem_recebida,
        data_prim_parcela: data.data_prim_parcela,
        data_ult_reajuste: data.data_ult_reajuste,
        data_ult_reajuste_format: data.data_ult_reajuste_format,
        priorizar_pgto_corretor: data.priorizar_pgto_corretor,
        qtd_parcelas_pagas: data.qtd_parcelas_pagas,
        data_ass_contrato: data.data_ass_contrato,
        dia_repasse_aluguel: data.dia_repasse_aluguel,
        vistoriador_id: data.vistoriador_id,
        pgto_aluguel_posterior: data.pgto_aluguel_posterior,
        tipo_contrato: data.tipo_contrato,
        tipo_contrato_name: data.tipo_contrato_name,
        tipo_contrato_label: data.tipo_contrato_label,
        clausula_especial: data.clausula_especial,
        gerou_lancamento: data.gerou_lancamento,
        vigencia_mes: data.vigencia_mes,
        antigo_id: data.antigo_id,
        protocolar: data.protocolar,
        indice_reajuste_id: data.indice_reajuste_id,
        periodicidade_reajuste: data.periodicidade_reajuste,
        filial_id: data.filial_idid,
        empreendimento_id: data.empreendimento_id,
        venc_prim_parc_entrada: data.venc_prim_parc_entrada,
      });
    }
  }
}
