import React, { useState, ReactElement, useEffect, ReactNode } from 'react';

interface FormState {
    [key: string]: string;
}
interface InputValidationMiddlewareProps {
    children: ReactElement[] | ReactElement;
}
const InputValidationMiddleware: React.FC<InputValidationMiddlewareProps> = ({ children }) => {
    const [formState, setFormState] = useState<FormState>({});
    const [errors, setErrors] = useState<Record<string, string>>({});

    const validateField = (name: string, value: string) => {
        let fieldError = '';

        if (value.trim() === '' && name !== 'email') {
            fieldError = `${name} is required`;
        }

        // Adicione outras validações conforme necessário

        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: fieldError,
        }));
    };

    const handleInputChange = (name: string, value: string) => {
        setFormState((prevFormState) => ({
            ...prevFormState,
            [name]: value,
        }));

        validateField(name, value);
    };

    useEffect(() => {
        // Validar todos os campos sempre que formState é alterado
        React.Children.forEach(children, (child: ReactElement) => {
            validateField(child.props.name, formState[child.props.name] || '');
        });
    }, [formState, children]);

    return (
        <div style={{height: '-webkit-fill-available'}}>
            {React.Children.map(children, (child: ReactElement) => {
                return React.cloneElement(child, {
                    key: child.props.name,
                    onChange: (e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(child.props.name, e.target.value),
                    error: errors[child.props.name],
                });
            })}
        </div>
    );
};

export default InputValidationMiddleware;
