import React, { useState, useEffect } from 'react';
import { Col, Form, Row } from 'react-bootstrap';

function Jazigo(props: any) {
    const [formData, setFormData] = useState<any>({});

    const handleFormChange = (field: string, value: any) => {
        props.onInputChange(field, value);
    }
    useEffect(() => {
        setFormData(props.formData);
    }, [props.formData]);
    return (
        <>
            <Row className="group-form">
                <Col>
                    <Form.Label>Em qual Coluna está o id do jazigo?</Form.Label>
                    <Form.Control
                        value={formData.id_externo || ''}
                        onChange={(e) => handleFormChange('id_externo', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está o tipo_jazigo_id para contato do comprador?</Form.Label>
                    <Form.Control
                        value={formData.tipo_jazigo_id || ''}
                        onChange={(e) => handleFormChange('tipo_jazigo_id', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está o identificador_1 do jazigo?</Form.Label>
                    <Form.Control
                        value={formData.identificador_1 || ''}
                        onChange={(e) => handleFormChange('identificador_1', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está o identificador_2 do jazigo?</Form.Label>
                    <Form.Control
                        value={formData.identificador_2 || ''}
                        onChange={(e) => handleFormChange('identificador_2', e.target.value)} />
                </Col>
            </Row>
            <Row className="group-form">
                <Col>
                    <Form.Label>Em qual Coluna está o Logradouro do jazigo?</Form.Label>
                    <Form.Control
                        value={formData.logradouro || ''}
                        onChange={(e) => handleFormChange('logradouro', e.target.value)} />
                </Col>
            </Row>
        </>
    );
}

export default Jazigo;
