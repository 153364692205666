import Swal from "sweetalert2";

export class AlertService {
  static sucess(title: string, text: string) {
    if (!Swal.isVisible()) {
      return Swal.fire(title, text, "success");
    } else {
      return Promise.resolve(); // Retorna uma Promise vazia para evitar erro
    }
  }

  static infomessage(title: string, text: string) {
    if (!Swal.isVisible()) {
      return Swal.fire(title, text, "info");
    } else {
      return Promise.resolve();
    }
  }

  static error(title: string, text: string) {
    if (!Swal.isVisible()) {
      const formattedText = text.replace(/\n/g, "<br>");
      Swal.fire(title, formattedText, "error");
    } else {
      return Promise.resolve();
    }
  }

  swalInit(obj: any) {
    if (!Swal.isVisible()) {
      return Swal.fire(obj);
    } else {
      return Promise.resolve();
    }
  }

  prompt(options: any) {
    if (!Swal.isVisible()) {
      const baseOptions = {
        showCancelButton: true,
        confirmButtonText: "Submit",
        input: "text",
      };
      return Swal.fire(Object.assign(baseOptions, options));
    } else {
      return Promise.resolve();
    }
  }

  static confirm(options: any) {
    if (!Swal.isVisible()) {
      const baseOptions = {
        showCancelButton: true,
        confirmButtonText: "Confirm",
        type: "warning",
      };
      return Swal.fire(Object.assign(baseOptions, options));
    } else {
      return Promise.resolve();
    }
  }

  alert(options: any) {
    if (!Swal.isVisible()) {
      const baseOptions = {
        confirmButtonText: "OK",
        type: "info",
      };
      return Swal.fire(Object.assign(baseOptions, options));
    } else {
      return Promise.resolve();
    }
  }

  question(options: any) {
    if (!Swal.isVisible()) {
      return this.alert(Object.assign({ type: "question" }, options));
    } else {
      return Promise.resolve();
    }
  }

  success(options: any) {
    if (!Swal.isVisible()) {
      return this.alert(Object.assign({ type: "success" }, options));
    } else {
      return Promise.resolve();
    }
  }

  error(options: any) {
    if (!Swal.isVisible()) {
      return this.alert(Object.assign({ type: "error" }, options));
    } else {
      return Promise.resolve();
    }
  }

  warn(options: any) {
    if (!Swal.isVisible()) {
      return this.alert(Object.assign({ type: "warn" }, options));
    } else {
      return Promise.resolve();
    }
  }

  info(options: any) {
    if (!Swal.isVisible()) {
      return this.alert(Object.assign({ type: "info" }, options));
    } else {
      return Promise.resolve();
    }
  }
}
