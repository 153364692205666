import React, { useState } from 'react';
import { Modal, Container, Form, Table, Col, Row } from 'react-bootstrap';
import { Button } from 'primereact/button';
import 'react-datepicker/dist/react-datepicker.css';
import { InputMask } from 'primereact/inputmask';
import { showToast } from '../../../../components/ToastComponent';
import api from '../../../../services/api';
import { url } from '../../../../environments/environments-develop';
import TokenService from '../../../../services/Auth/token.service';

interface ModalDesfazerProps {
  showModal: boolean;
  onHide: () => void;
  onInputChange: (field: string, value: any) => void;

}

const ModalDesfazer: React.FC<ModalDesfazerProps> = ({ showModal, onHide, onInputChange }) => {
  const [vencimento, setVencimento] = useState<string | null>(null);
  const [vencimentoError, setVencimentoError] = useState<string | null>(null);
  const [tipoConsulta, setTipoConsulta] = useState<string | null>(null);
  const empreendimentoId = TokenService.getEmpreendimentoId().id;

  const handleDesfazerReajuste = async () => {
    if (!vencimento) {
      showToast('error', 'É obrigatório informar o mês e ano de vencimento');
      return;
    }
    if (!tipoConsulta) {
      showToast('error', 'É obrigatório selecionar o tipo de consulta');
      return;
    }

    const payload = {
      empreendimento_id: empreendimentoId,
      vencimento,
      tipo_consulta: tipoConsulta
    };

    try {
      const response = await api.post(`${url}/api/v1/admin/financeiro/reverter-reajuste`, payload);
      if (response.status === 200) {
        showToast('success', 'Reajuste revertido com sucesso!');
        onHide();
      }
    } catch (error: any) {
      let mensagens: string[] = [];
      mensagens.push(error?.response?.data?.error?.message);
      showToast('error', String(mensagens));
    }
  };

  return (
    <Modal show={showModal} size="xl" onHide={() => onHide()}>
      <Modal.Header closeButton>
        <Modal.Title>Desfazer Reajuste</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <Form.Group className="imovel-form" style={{ overflowX: 'hidden' }}>
            <Row className="mb-3">
              <Col md="3">
                <Form.Label>Tipo de Consulta</Form.Label>
                <Form.Select
                  value={tipoConsulta || ''}
                  onChange={(e) => setTipoConsulta(e.target.value)}
                >
                  <option value="">Selecione...</option>
                  <option value="data_anterior_reajuste">Data do Reajuste Anterior</option>
                  <option value="data_novo_reajuste">Data do Novo Reajuste</option>
                </Form.Select>
              </Col>
              <Col md="3" style={{ marginRight: '8px' }}>
                <Form.Label>Mês e Ano de Vencimento</Form.Label>
                <InputMask
                  mask="99/9999"
                  id="vencimento"
                  value={vencimento ?? ''}
                  onChange={(e) => {
                    const value = e.target.value;
                    const [mes] = (value ?? '').split('/');
                    if (mes && parseInt(mes, 10) > 12) {
                      setVencimentoError('Por favor, insira um mês válido (1-12).');
                    } else {
                      setVencimentoError(null);
                    }
                    setVencimento(value ?? '');
                  }}
                  placeholder="mm/aaaa"
                  className={`form-control ${vencimentoError ? 'is-invalid' : ''}`}
                />
                {vencimentoError && (
                  <div className="invalid-feedback" style={{ display: 'block' }}>
                    {vencimentoError}
                  </div>
                )}
              </Col>
            </Row>
          </Form.Group>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button
          style={{ borderRadius: '20px', marginRight: '10px' }}
          severity="success"
          label="Desfazer Reajuste"
          onClick={handleDesfazerReajuste}
        />
        <Button
          style={{ borderRadius: '20px' }}
          severity="danger"
          label="Cancelar"
          onClick={onHide}
        />
      </Modal.Footer>
    </Modal>
  );
};

export default ModalDesfazer;
