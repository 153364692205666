import React, { useEffect, useState } from 'react';
import { Button, Modal} from 'react-bootstrap';
import { AlertService } from './AlertService';
import api from '../services/api';
import { url } from '../environments/environments-develop';
import ModalAltDataProgramadaForm from './ModalAltDataProgramadaForm';

interface ModalAltDataProgramadaProps {
    showModal: boolean;
    handleClose: () => void;
    lancamentSelected: any;
    urlSearchDataPut: any;
}

const ModalAltDataProgramada: React.FC<ModalAltDataProgramadaProps> = ({ showModal, urlSearchDataPut, handleClose, lancamentSelected }) => {
    const [show, setShow] = useState(showModal);
    const [formBasico, setFormBasico] = useState<any | null>(null);

    async function setSessionEditData(): Promise<void> {
        try {
            const response = await api.get(`${url}${urlSearchDataPut}${lancamentSelected}`);
            if (response.status === 200) {
                setFormBasico((prevGrupoData: any) => {
                    const dadosAtualizados = {
                        ...prevGrupoData,
                        ...response.data
                    };
                    return dadosAtualizados;
                });
            }
        } catch (error: any) {
            let mensagens: string[] = [];
            mensagens.push(error?.response?.data?.error?.message);
            AlertService.error('Erro!', String(mensagens));
        }
    }
    useEffect(() => {
        if (lancamentSelected) {
            setSessionEditData();
        }
    }, [lancamentSelected]);

    useEffect(() => {
        setShow(showModal);
    }, [showModal]);

    const handleSaveButton = async (): Promise<void> => {
        try {
            const response = await api.put(`${url}/api/v1/admin/financeiro/contasPagar/${lancamentSelected}`, formBasico);
            if (response.status === 200) {
                AlertService.sucess('Sucesso!', 'Salvo com sucesso!').then(() => {
                    setShow(false);
                    window.location.reload();
                });
            }
        } catch (error: any) {
            AlertService.infomessage('Erro!', String(error.message)).then(() => {
                setShow(false);
                window.location.reload();
            });
        }
    };

    const handleChange = (field: string, value: any) => {
        setFormBasico((prevForm: any) => {
            return {
                ...prevForm,
                [field]: value,
            };
        });
    };

    return (
        <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            size='lg'
        >
            <Modal.Header closeButton>
                <Modal.Title>Alterar Data Programada</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ModalAltDataProgramadaForm
                    formBasico={formBasico}
                    handleChange={handleChange}
                    handleSaveButton={handleSaveButton}
                />
            </Modal.Body>
            <Modal.Footer>
                <div style={{ display: 'flex' }}>
                    <Button className='button-save-or-update' style={{ marginRight: '27px' }} onClick={handleSaveButton} variant="success">Confirmar</Button>
                </div>
            </Modal.Footer>
        </Modal>
    );
};

export default ModalAltDataProgramada;

