// financeiroModels.ts

import { Deserializable } from "../../../../core/interfaces/deserializable.model";
import { BaseModel } from "../../Pessoas/Models/BaseModel";
import { ClienteModel } from "../../Pessoas/Models/ClienteModel";
import { FornecedorModel } from "../../Pessoas/Models/FornecedorModel";

export const LANCAMENTO_ENTRADA = "0";
export const LANCAMENTO_SAIDA = "1";
export interface ILancamentoFinanceiro {
  id: number | null;
  parent_id: number | null;
  nome: string | null;
  cliente_nome: string | null;
  fornecedor_nome: string | null;
  fornecedor: FornecedorModel | FornecedorModel[] | null;
  plano_conta_id: number | null;
  centro_custo: any[] | null;
  conta_financeiro_id: number | null;
  tipo_documento: number | null;
  bloqueado: boolean | null;
  cliente_id: number | null;
  cliente: ClienteModel | ClienteModel[] | null;
  fatura: number | null;
  filial_id: number | null;
  fornecedor_id: number | null;
  usuario_id: number | null;
  data_competencia: Date | null;
  data_arq_remessa: Date | null;
  data_baixa: Date | null;
  data_conciliacao: Date | null;
  data_efetivacao: Date | null;
  data_emissao: Date | null;
  data_ult_cobranca: Date | null;
  data_credito: Date | null;
  data_vencimento: Date | null;
  justificativa: string | null;
  num_parcela: number | null;
  protocolar: boolean | null;
  qtd_dias_carencia_juros: number | null;
  qtd_dias_carencia_multa: number | null;
  recorrente: boolean | null;
  situacao_lancamento: number | null;
  taxa_correcao_monetaria: number | null;
  taxa_juros: number | null;
  taxa_multa: number | null;
  valor_original: number | null;
  saldo: number | null;
  valor_correcao_monetaria: number | null;
  valor_desconto: number | null;
  valor_juros: number | null;
  valor_multa: number | null;
  valor_outros: number | null;
  valor_taxa_bancaria: number | null;
  tipo: number | null;
  modulo: number | null;
  agrupados: number | null;
  descricao_caixa: string | null;
  historico: string | null;
  numero_documento: string | null;
  situacao_pagamento: number | null;
  tipo_parcela: number | null;
  situacao_contrato: number | null;
  historico_situacao: number | null;
  status_lancamento: number | null;
  origem_lancamento: number | null;
  data_vencimento_renegociacao: Date | null;
  data_vencimento_format: string | null;
  data_pagamento_format: string | null;
  data_pagamento: Date | null;
  valor_pago: number | null;
  total_parcelas: number | null;
  venda_id: number | null;
  tipo_venda: number | null;
  empreendimento_id: number | null;
  local_cobranca: string | null;
  local_cobranca_name: string | null;
  ult_reaj_parc: Date | null;
  valor_original_format: string | null;
  saldo_format: string | null;
  tipo_parcela_enum_name: string | null;
  data_prog_pgto: Date | null;
  data_prog_pgto_format: string | null;
  justificativa_label: string | null;
  conta_financeira_name: string | null;
}

export class LancamentoFinanceiroModel
  extends BaseModel
  implements ILancamentoFinanceiro, Deserializable
{
  id: number | null = null;
  parent_id: number | null = null;
  nome: string | null = null;
  cliente_nome: string | null = null;
  cliente: ClienteModel | ClienteModel[] | null = null;
  fornecedor_nome: string | null = null;
  fornecedor: FornecedorModel | FornecedorModel[] | null = null;
  plano_conta_id: number | null = null;
  justificativa: string | null = null;
  centro_custo: any[] | null = null;
  conta_financeiro_id: number | null = null;
  tipo_documento: number | null = null;
  bloqueado: boolean | null = null;
  cliente_id: number | null = null;
  fatura: number | null = null;
  filial_id: number | null = null;
  fornecedor_id: number | null = null;
  usuario_id: number | null = null;
  data_competencia: Date | null = null;
  data_arq_remessa: Date | null = null;
  data_baixa: Date | null = null;
  data_conciliacao: Date | null = null;
  data_efetivacao: Date | null = null;
  data_emissao: Date | null = null;
  data_ult_cobranca: Date | null = null;
  data_credito: Date | null = null;
  data_vencimento: Date | null = null;
  num_parcela: number | null = null;
  protocolar: boolean | null = null;
  qtd_dias_carencia_juros: number | null = null;
  qtd_dias_carencia_multa: number | null = null;
  recorrente: boolean | null = null;
  situacao_lancamento: number | null = null;
  taxa_correcao_monetaria: number | null = null;
  taxa_juros: number | null = null;
  taxa_multa: number | null = null;
  valor_original: number | null = null;
  saldo: number | null = null;
  valor_correcao_monetaria: number | null = null;
  valor_desconto: number | null = null;
  valor_juros: number | null = null;
  valor_multa: number | null = null;
  valor_outros: number | null = null;
  valor_taxa_bancaria: number | null = null;
  tipo: number | null = null;
  modulo: number | null = null;
  agrupados: number | null = null;
  descricao_caixa: string | null = null;
  historico: string | null = null;
  numero_documento: string | null = null;
  situacao_pagamento: number | null = null;
  tipo_parcela: number | null = null;
  situacao_contrato: number | null = null;
  historico_situacao: number | null = null;
  status_lancamento: number | null = null;
  origem_lancamento: number | null = null;
  data_vencimento_renegociacao: Date | null = null;
  data_vencimento_format: string | null = null;
  data_pagamento: Date | null = null;
  valor_pago: number | null = null;
  total_parcelas: number | null = null;
  venda_id: number | null = null;
  tipo_venda: number | null = null;
  empreendimento_id: number | null = null;
  local_cobranca: string | null = null;
  ult_reaj_parc: Date | null = null;
  valor_original_format: string | null = null;
  saldo_format: string | null = null;
  tipo_parcela_enum_name: string | null = null;
  local_cobranca_name: string | null = null;
  data_pagamento_format: string | null = null;
  data_prog_pgto: Date | null = null;
  data_prog_pgto_format: string | null = null;
  justificativa_label: string | null = null;
  conta_financeira_name: string | null = null;

  deserialize(input: any): this {
    Object.assign(this, input);
    if (input?.fornecedor?.data) {
      this.fornecedor = new FornecedorModel().deserialize(
        input.fornecedor.data
      );
    } else if (input?.fornecedor) {
      this.fornecedor = new FornecedorModel().deserialize(input.fornecedor);
    }

    if (input?.cliente?.data) {
      this.cliente = new ClienteModel().deserialize(input.cliente.data);
    } else if (input?.cliente) {
      this.cliente = new ClienteModel().deserialize(input.cliente);
    }

    if (input?.centro_custo?.data) {
      this.centro_custo = input.centro_custo.data.reduce(
        (acc: any[], item: any) => {
          acc.push(item.id);
          return acc;
        },
        []
      );
    }

    return this;
  }
}
