import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";

interface PropsData {
    produtosServicosData: {
        data: any[]; 
    };
    onInputChange: (field: string, value: any) => void;
}

function EditProdutosServicos(props: PropsData) {
    const { data: vendas_itens } = props.produtosServicosData || {};

    const MoneyFormatter = (valor: number): string => {
        return new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
        }).format(valor);
    };

    const formatDesconto = (desconto: any, tipoDesconto: any) => {
        if (tipoDesconto === '2') {
            if (typeof desconto !== 'number') {
                desconto = parseFloat(desconto) || 0;
            }
            return desconto.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
        } else if (tipoDesconto === '1') {
            return `${desconto}%`;
        }
        return desconto;
    };

    return (
        <div style={{ minHeight: '350px', paddingLeft: '16px', paddingRight: '16px' }}>
            <DataTable
                value={Array.isArray(vendas_itens) ? vendas_itens : []} 
                paginator
                rows={8}
                rowsPerPageOptions={[8, 16, 25, 50]}
                dataKey="id" 
                tableStyle={{ minWidth: '50rem', textAlign: 'center' }}
                emptyMessage={
                    <span style={{ display: 'flex', justifyContent: 'center', fontStyle: 'italic' }}>
                        Nenhum item encontrado.
                    </span>
                }
            >
                <Column style={{ textAlign: 'center' }} field="produto_id" header="Id Prod"></Column>
                <Column style={{ textAlign: 'center' }} field="descricao_resumida" header="Descrição Resumida"></Column>
                <Column style={{ textAlign: 'center' }} field="quantidade" header="Qtd"></Column>
                <Column
                    style={{ textAlign: 'center' }}
                    field="valor_unitario"
                    header="Vlr.Unit Bruto"
                    body={(rowData) => MoneyFormatter(Number(rowData.valor_unitario))}
                />
                <Column
                    style={{ textAlign: 'center' }}
                    field="desconto"
                    header="Desconto"
                    body={(rowData) => formatDesconto(rowData.desconto, rowData.tipo_desconto)}
                />
                <Column
                    style={{ textAlign: 'center' }}
                    header="Vlr.Unit c/Desconto"
                    body={(rowData) => {
                        const valorUnitario = Number(rowData.valor_unitario) || 0;
                        const desconto = rowData.tipo_desconto === 1
                            ? (rowData.desconto / 100) * valorUnitario
                            : rowData.desconto;
                        return MoneyFormatter(valorUnitario - desconto);
                    }}
                />
                <Column
                    style={{ textAlign: 'center' }}
                    header="Vlr.Total"
                    body={(rowData) => {
                        const quantidade = Number(rowData.quantidade) || 1;
                        const valorUnitario = Number(rowData.valor_unitario) || 0;
                        const desconto = rowData.tipo_desconto === 1
                            ? (rowData.desconto / 100) * valorUnitario * quantidade
                            : rowData.desconto * quantidade;
                        return MoneyFormatter((valorUnitario * quantidade) - desconto);
                    }}
                />
            </DataTable>
        </div>
    );
}

export default EditProdutosServicos;
