import home from "./icon-home.png";
import empreendimento from "./icon-empreendimento.png";
import carteira from "./icon-carteira.png";
import vendas from "./icon-venda.png";
import financeiro from "./icon-financeiro.png";
import pessoas from "./icon-pessoa.png";
import receber from "./icon-receber.png";
import pagar from "./icon-pagar.png";
import planoConta from "./icon-plano-contas.png";
import movCaixa from "./icon-mov-caixa.png";
import contaFinac from "./icon-conta-financeira.png";
import centroCusto from "./icon-centro-custo.png";
import groupUser from "./group-user.png";
import usuario from "./usuario.png";
import filial from "./filial.png";
import clientes from "./clientes.png";
import corretores from "./corretores.png";
import imoveis from "./imoveis.png";
import tipoImoveis from "./tipoImoveis.png";
import subTipoImoveis from "./subTipoImoveis.png";
import classificacaoImoveis from "./classificacaoImoveis.png";
import empreendimentoObra from "./empreendimentoObra.png";
import tabelaPreco from "./tabelaPreco.png";
import configvenda from "./configvenda.png";
import configcarteira from "./configcarteira.png";
import contrato from "./contrato.png";
import gerarVenda from "./gerarVenda.png";
import rotinas from "./rotinas-especiais.png";
import boletos from "./boletos.png";
import relatorioFinanceiro from "./relatorioFinanceiro.png";
import relatorioVendaIntermed from "./relatorioVendaIntermed.png";
import importUser from "./import-user.png";
import audit from "./audit.png";
import indice from "./indice.png";
import tipoIndice from "./tipoIndice.png";
import exclusaoLotes from "./exclusao-lotes.png";
import contaCorrente from "./contaCorrente.png";
import cemiterio from "./cemiterio.png";
import arrowRight from "./arrow-right.png";
import estrutura from "./estrutura.png";
import tipoJazigo from "./tipo-jazigo.png";
import cadastroCemiterio from "./cadastro-cemiterio.png";
import mapaCemiterio from "./mapa-cemiterio.png";
import jazigo from "./jazigo.png";
import inumados from "./inumados.png";
import reajuste from "./reajuste.png";
import jazigoGrupos from "./jazigo-grupos.png";
import produto from "./produto.png";
import fornecedor from "./fornecedor.png";
import renegociacao from "./renegociacao.png";
import relatorioDisponibilidade from "./relatorioDisponibilidade.png";
import relatorioImoveis from "./relatorioImoveis.png";

function getImageByName(name: string) {
  const imageMap: Record<string, string> = {
    home: home,
    empreendimento: empreendimento,
    carteira: carteira,
    vendas: vendas,
    financeiro: financeiro,
    pessoas: pessoas,
    "contas-receber": receber,
    "plano-conta": planoConta,
    "contas-pagar": pagar,
    movimentacaofinanceira: movCaixa,
    "conta-financeira": contaFinac,
    "centro-custo": centroCusto,
    "group-user": groupUser,
    usuario: usuario,
    filial: filial,
    compradores: clientes,
    imoveis: imoveis,
    corretores: corretores,
    tipoImoveis: tipoImoveis,
    subTipoImoveis: subTipoImoveis,
    classificacaoImoveis: classificacaoImoveis,
    empreendimentoObra: empreendimentoObra,
    tabelapreco: tabelaPreco,
    configvenda: configvenda,
    configcarteira: configcarteira,
    contrato: contrato,
    "gerar-venda": gerarVenda,
    "rotinas-especiais": rotinas,
    boletos: boletos,
    relatorioFinanceiro: relatorioFinanceiro,
    relatorioVendaIntermed: relatorioVendaIntermed,
    "import-user": importUser,
    "modelo-relatorio": relatorioFinanceiro,
    "modelo-contrato": relatorioFinanceiro,
    audit: audit,
    Indice: indice,
    tipoIndice: tipoIndice,
    "exclusao-lotes": exclusaoLotes,
    "conta-corrente": contaCorrente,
    cemiterio: cemiterio,
    "arrow-right": arrowRight,
    estrutura: estrutura,
    "tipo-jazigo": tipoJazigo,
    "cadastro-cemiterio": cadastroCemiterio,
    "mapa-cemiterio": mapaCemiterio,
    jazigo: jazigo,
    inumados: inumados,
    reajuste: reajuste,
    "jazigo-grupos": jazigoGrupos,
    produto: produto,
    fornecedor: fornecedor,
    renegociacao: renegociacao,
    "relatorio-disponibilidade": relatorioDisponibilidade,
    "relatorio-imoveis": relatorioImoveis,
  };

  return imageMap[name] || null;
}

export { getImageByName };
