import { Col, Form, InputGroup, Row } from "react-bootstrap";
interface PropsData {
    Data: {
        codigoConcat?: string;
        codigo?: string;
        codigoPai?: string;
        nomePai?: string;
        nome?: string;
        tipo?: string;
        parent_id?: string;
        status?: boolean;
        recebe_lancamento?: boolean;
        contabil?: boolean;
    };
    initialRightValues: any;
    onInputChange: (field: string, value: any) => void;
}
function CentroCustoForm(props: PropsData) {
    const { codigo, codigoConcat, nomePai, codigoPai, parent_id, nome, tipo, recebe_lancamento, contabil, status } = props.Data || {};
    const handleChange = (field: string, value: any) => {
        props.onInputChange(field, value);
    };

    return (
        <>
            <Form.Group className="forms">
                {codigoPai && (<Row className="group-form">
                    <Col md="2">
                        <Form.Label>Segmento</Form.Label>
                        <Form.Control value={codigoPai} onChange={(e) => handleChange("codigoPai", e.target.value)} disabled />
                    </Col>
                    <Col md="4">
                        <Form.Label>Nome do segmento</Form.Label>
                        <Form.Control disabled value={nomePai || ''} onChange={(e) => handleChange("nomePai", e.target.value)} />
                    </Col>
                </Row>)}
                <Row className="mb-3">
                    {!codigoPai && props.Data.codigoConcat && (
                        <Col md="2">
                            <Form.Label className="mb-0">Segmento</Form.Label>
                            <Form.Control value={codigoConcat} onChange={(e) => handleChange("codigoConcat", e.target.value)} disabled />
                        </Col>
                    )}
                    <Col md="2">
                        <Form.Label className="mb-0">Código</Form.Label>
                        <Form.Control value={codigo || ''} onChange={(e) => handleChange("codigo", e.target.value)} />
                    </Col>
                    <Col md="4">
                        <Form.Label className="mb-0">Nome</Form.Label>
                        <Form.Control value={nome || ''} onChange={(e) => handleChange("nome", e.target.value)} />
                    </Col>
                    <Col md="3">
                        <Form.Label className="mb-0">Tipo</Form.Label>
                        <Form.Select value={tipo || ''} onChange={(e) => handleChange('tipo', e.target.value)}>
                            <option>Selecione...</option>
                            <option value={2}>Débito</option>
                            <option value={1}>Crédito</option>
                        </Form.Select>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col md="3" style={{ padding: "0px", display: "flex", alignItems: "center", flexDirection: "column" }}>
                        <Form.Label className="mb-0">Status?</Form.Label>
                        <Form.Check
                            style={{ display: "flex", alignItems: "center", justifyContent: "center", }}
                            checked={status || false}
                            onChange={(e) => handleChange("status", e.target.checked)}
                            type="switch"
                            id="custom-switch"
                        />
                    </Col>
                    <Col md="3" style={{ padding: "0px", display: "flex", alignItems: "center", flexDirection: "column" }}>
                        <Form.Label className="mb-0">Recebe Lançamento Finan?</Form.Label>
                        <Form.Check
                            style={{ display: "flex", alignItems: "center", justifyContent: "center", }}
                            checked={recebe_lancamento || false}
                            onChange={(e) => handleChange("recebe_lancamento", String(e.target.checked))}
                            type="switch"
                            id="custom-switch"
                        />
                    </Col>
                    <Col md="2" style={{ padding: "0px", display: "flex", alignItems: "center", flexDirection: "column" }}>
                        <Form.Label className="mb-0">Contábil?</Form.Label>
                        <Form.Check
                            style={{ display: "flex", alignItems: "center", justifyContent: "center", }}
                            checked={contabil || false}
                            onChange={(e) => handleChange("contabil", String(e.target.checked))}
                            type="switch"
                            id="custom-switch"
                        />
                    </Col>
                </Row>
            </Form.Group>
        </>
    );
}

export default CentroCustoForm;
