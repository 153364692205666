import { AlertService } from "../../../../components/AlertService";
import { url } from "../../../../environments/environments-develop";
import IndiceService from "../../Service/IndiceService";
import api from "../../../../services/api";
interface Consulta {
  order?: string;
  filtro?: any;
}
const consulta: Consulta = {
  order: "financeiro.lancamento_financeiros.data_vencimento;asc",
};

export const excluirIndice = (indice_id: any) => {
  AlertService.confirm({
    title: "Confirmar Exclusão!",
    text: "Deseja realmente excluir este índice?",
    cancelButtonText: "Não",
    confirmButtonText: "Sim",
  }).then((e: any) => {
    if (e?.isConfirmed) {
      IndiceService.excluir(indice_id).subscribe(
        (response) => {
          AlertService.sucess("Sucesso!", "Excluído com sucesso!").then(() => {
            window.location.reload();
          });
        },
        (error) => {
          let mensagens: string[] = [];
          Object.keys(error?.response?.data?.error?.message).forEach((key) => {
            mensagens.push("\n" + error?.response?.data?.error?.message[key][0]);
          });
          AlertService.error("Erro!", String(mensagens));
        }
      );
    }
  });
};

export const setSessionEditData = (value: any, url_redirect_edit: any) => {
  IndiceService.get(value).subscribe(
    (response) => {
      localStorage.setItem("dadosEdicao", JSON.stringify(response));
      window.location.href = url_redirect_edit;
    },
    (error) => {
      let mensagens: string[] = [];
      mensagens.push(error?.response?.data?.error?.message);
      AlertService.error("Erro!", String(mensagens));
    }
  );
};
