import React, { useEffect, useState } from 'react';
import { Button, Modal, Row, Col, Form } from 'react-bootstrap';
import { Checkbox } from 'primereact/checkbox';
import TokenService from '../../../../services/Auth/token.service';
import api from '../../../../services/api';
import { url_financeiro } from '../../../../environments/environments-develop';
import { toast } from 'react-toastify';

interface ConfirmarHomologacaoProps {
    Homologar: any;
    handleData: (newData: any) => void;
    handleClose: () => void;
    showModalHomologar: boolean;
}

const ConfirmarHomologacao: React.FC<ConfirmarHomologacaoProps> = ({ handleData, showModalHomologar, handleClose, Homologar }) => {
    const [show, setShow] = useState(showModalHomologar);

    useEffect(() => {
        setShow(showModalHomologar);
    }, [showModalHomologar]);

    const buscarParcelas = async () => {
        try {
            const response = await api.get(`${url_financeiro}/api/v1/admin/financeiro/renegociacao/homologar/${Homologar?.id}`);
            handleData(response.data);
            handleClose();
        } catch (error) {
            const errorMessage = (error as any)?.response?.data?.message || (error as any)?.error.message || 'Erro desconhecido ao buscar parcelas';
            toast.error(`Erro ao buscar parcelas: ${errorMessage}`);
        }
    };

    return (
        <Modal
            show={showModalHomologar}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            size='lg'
            className="modal-selecao-renegociacao"
        >
            <Modal.Header closeButton>
                <Modal.Title>Confirmar Homologacao</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row className='mb-3'>
                    <Col md='3'>
                        <Form.Label>Número do Contrato</Form.Label>
                        <Form.Control disabled type="text" value={Homologar?.contrato_id} />
                    </Col>
                    <Col md='6'>
                        <Form.Label>Cliente</Form.Label>
                        <Form.Control disabled type="text" value={Homologar?.nome_cliente} />
                    </Col>
                    <Col md='3'>
                        <Form.Label>Qtd Parcelas</Form.Label>
                        <Form.Control disabled type="text" value={Homologar?.quantidade_parcelas_renegociadas} />
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="success" onClick={buscarParcelas}>
                    Confirmar
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ConfirmarHomologacao;
