
import React from 'react';
import { BreadCrumb } from 'primereact/breadcrumb';
import { useAppContext } from '../contexts/AppContext';

export default function BreadsCrumbsComponent() {
  const { itemsBreadsCrumbs } = useAppContext();
  const home = { icon: 'pi pi-home', url: `/home` };

  return (
    <BreadCrumb model={itemsBreadsCrumbs} home={home} />
  );
}