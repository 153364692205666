
export namespace CessaoDeDireitoModel {
  export interface ICessaoDeDireito {
    id: number;
    contrato_id: number;
    local_pagamento: number;
    unidade: string;
    valor_servico: string;
    vencimento: Date;
    data: Date;
    status: number;
    observacao: String;
    empreendimento_id: number;
    filial_id: number;
  }

  export class CessaoDeDireitoModel implements ICessaoDeDireito {
    id: number;
    contrato_id: number;
    local_pagamento: number;
    unidade: string;
    valor_servico: string;
    vencimento: Date;
    data: Date;
    status: number;
    observacao: String;
    empreendimento_id: number;
    filial_id: number;

    constructor(data: ICessaoDeDireito) {
      this.id = data.id;
      this.contrato_id = data.contrato_id;
      this.local_pagamento = data.local_pagamento;
      this.unidade = data.unidade;
      this.valor_servico = data.valor_servico;
      this.vencimento = data.vencimento;
      this.data = data.data;
      this.status = data.status;
      this.observacao = data.observacao;
      this.filial_id = data.filial_id;
      this.empreendimento_id = data.empreendimento_id;
      }

    static deserialize(data: any): CessaoDeDireitoModel {
      return new CessaoDeDireitoModel({
        id: data.id,
        contrato_id: data.contrato_id,
        local_pagamento: data.local_pagamento,
        unidade: data.unidade,
        valor_servico: data.valor_servico,
        vencimento: data.vencimento,
        data: data.data,
        status: data.status,
        observacao: data.observacao,
        filial_id: data.filial_id,
        empreendimento_id: data.empreendimento_id,
        });
    }
  }
}
