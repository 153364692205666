import React, { useState, useEffect, useRef } from 'react';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { useAppContext } from '../../../../contexts/AppContext';
import { Toast } from 'primereact/toast';
import { fetchData } from './GestaoContratosServices';
import { actionTemplate } from './actionTemplate';
import { togglerStatus } from './TogglerStatus';
import Paginacao from './Paginacao';
import { Skeleton } from 'primereact/skeleton';

interface TableComponentsContratoArvoreListProps {
    urlSearch: string;
    urlSearchDataPut: string;
    url_redirect_edit: string;
    urlRedirectCreated: string;
    url_redirect_show: string;
}


const TableComponentsCessao: React.FC<TableComponentsContratoArvoreListProps> = ({ urlRedirectCreated, urlSearch, urlSearchDataPut, url_redirect_edit, url_redirect_show }) => {
    const [currentPage, setCurrentPage] = useState(0);
    const [nodes, setNodes] = useState<any | null>(null);
    const [titles, setTitles] = useState<any | null>(null);
    const [totalPages, setTotalPages] = useState(0);
    const { setUrl_button_create } = useAppContext();
    const [isLoading, setLoading] = useState(false);
    const [selectedNodeKeys, setSelectedNodeKeys] = useState<any>(null);
    const toast = useRef<any>(null);
    useEffect(() => {
        setUrl_button_create(urlRedirectCreated);
    }, [])
    useEffect(() => {
        fetchData(setLoading, urlSearch, currentPage, setTitles, setTotalPages, setNodes);
    }, [currentPage, urlSearch]);
    return (
        <div className="card">
            <Toast ref={toast} />
            <DataTable
                stripedRows
                size="small"
                value={nodes ? nodes : []}
                tableStyle={{ minWidth: '50rem' }}
                emptyMessage={<span style={{ display: 'flex', justifyContent: 'center', fontStyle: 'italic' }}>Nenhum item encontrado.</span>}
            >
                {titles && titles.map((col: any) => (
                    <Column
                        style={{ textAlign: 'center' }}
                        key={col.dataField}
                        field={col.dataField}
                        header={col.heading}
                        body={isLoading && (<Skeleton />)}
                    />
                ))}
                <Column
                    style={{ textAlign: 'center', borderBottom: '1px solid #bdbdbd96' }}
                    className='menu-context-grid-contrato'
                    body={(e) => actionTemplate(
                        e,
                        urlSearchDataPut,
                        url_redirect_edit,
                        setSelectedNodeKeys,
                        url_redirect_show
                    )}
                    header="Menu"
                    headerClassName=""
                />


            </DataTable>
            <Paginacao
                totalPages={totalPages}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
            />
        </div >
    );
};

export default TableComponentsCessao;
