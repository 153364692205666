import { Col, Form, InputGroup, Row } from "react-bootstrap";
import 'react-datepicker/dist/react-datepicker.css';
import { useEffect, useState } from "react";
import { mask } from "../../../../contexts/CpfCnpj";
import InputValidationMiddleware from "../../../../validators/InputValidationMiddleware";
import EnderecoFormContaFinanceira from './EnderecoFormContaFinanceira';
import TelefoneFormContaFInanceira from './TelefoneFormContaFInanceira';
function PessoaFormContaFInanceira(props: any,) {
    const [formData, setFormData] = useState<any>(props.formBasico || {});
    const [touchedFields, setTouchedFields] = useState<string[]>([]);
    const handleChange = (field: string, value: string) => {

        if (field === 'cpf_cnpj') {
            if (/^[0-9.;\/\-]*$/.test(value) && value.length <= 18) {
                props.onInputChangePessoaForm(field, value);
            }
        } else if (field === 'nome') {
            if (!value || value.length <= 60) {
                props.onInputChangePessoaForm(field, value);
            }
        } else if (field === 'nome') {
            if (/^\d*$/.test(value) && value.length <= 11) {
                props.onInputChangePessoaForm(field, value);
            }
        } else if (field === 'inscricao_municipal') {
            if (/^\d*$/.test(value) && value.length <= 25) {
                props.onInputChangePessoaForm(field, value);
            }
        } else if (field === 'inscricao_estadual') {
            if (/^\d*$/.test(value) && value.length <= 25) {
                props.onInputChangePessoaForm(field, value);
            }
        } else if (field === 'email') {
            if (!value || value.length <= 60) {
                props.onInputChangePessoaForm(field, value);
            }
        } else if (field === 'filiacao_mae') {
            if (!value || value.length <= 60) {
                props.onInputChangePessoaForm(field, value);
            }
        } else if (field === 'filiacao_pai') {
            if (!value || value.length <= 60) {
                props.onInputChangePessoaForm(field, value);
            }
        } else if (field === 'rg') {
            if (/^\d*$/.test(value) && value.length <= 11) {
                props.onInputChangePessoaForm(field, value);
            }
        } else {
            props.onInputChangePessoaForm(field, value);
        }
        if (!touchedFields.includes(field)) {
            setTouchedFields([...touchedFields, field]);
        }
    };

    useEffect(() => {
        setFormData((prevGrupoData: any) => {
            return {
                ...prevGrupoData,
                ...props.formBasico
            };
        });
    }, [props.formBasico]);
    const isInvalid = (field: string) => {
        return touchedFields.includes(field) && !props.formBasico[field];
    };
    return (
        <>
            <InputValidationMiddleware>
                <Form.Group className="imovel-form card" style={{ padding: '12px', paddingTop: '0px' }}>
                    <Row className="mb-3" style={{ background: '#e6e5e5', padding: '12px' }}>
                        <h3 className="label-form-create-imovel">Pessoa</h3>
                    </Row>
                    <Row className="mb-3">
                        <Form.Group as={Col} controlId="validationCustomUsername">
                            <Form.Label>Nome</Form.Label>
                            <InputGroup hasValidation>
                                <Form.Control
                                    required
                                    value={formData.nome || ''}
                                    onChange={(e) => handleChange("nome", e.target.value)}
                                    onBlur={(e) => handleChange("nome", e.target.value)}
                                    isInvalid={isInvalid("nome")}
                                />
                                <Form.Control.Feedback type="invalid">
                                    Este campo é Obrigatório.
                                </Form.Control.Feedback>
                            </InputGroup>
                        </Form.Group>
                        <Col>
                            <Form.Label>CPF ou CNPJ</Form.Label>
                            <InputGroup hasValidation>
                                <Form.Control
                                    value={mask(formData.cpf_cnpj || '')}
                                    onChange={(e) => handleChange("cpf_cnpj", e.target.value)}
                                    onBlur={(e) => handleChange("cpf_cnpj", e.target.value)}
                                    required
                                    isInvalid={isInvalid("cpf_cnpj")}
                                />
                                <Form.Control.Feedback type="invalid">
                                    Este campo é Obrigatório.
                                </Form.Control.Feedback>
                            </InputGroup>
                        </Col>
                        <Col>
                            <Form.Label>Email</Form.Label>
                            <InputGroup hasValidation>
                                <Form.Control
                                    value={formData.email || ''}
                                    onChange={(e) => handleChange("email", e.target.value)}
                                    onBlur={(e) => handleChange("email", e.target.value)}
                                    required
                                    isInvalid={isInvalid("email")}
                                />
                                <Form.Control.Feedback type="invalid">
                                    Este campo é Obrigatório.
                                </Form.Control.Feedback>
                            </InputGroup>
                        </Col>
                    </Row>
                    <EnderecoFormContaFinanceira
                        enderecoData={props.formBasico.enderecos ? props.formBasico.enderecos[0] : []}
                        onInputChangeEnderecos={(field: string, value: any) => props.onInputChangeEnderecos(`${field}`, value)}
                        onInputChangeBairro={(field: string, value: any) => props.onInputChangeBairro(`${field}`, value)}
                    />
                    <TelefoneFormContaFInanceira
                        telefoneData={props.formBasico.telefones ? props.formBasico.telefones[0] : []}
                        onInputChangeTelefones={(field: string, value: any) => props.onInputChangeTelefones(`${field}`, value)}
                    />
                </Form.Group >
            </InputValidationMiddleware>
        </>
    );
}

export default PessoaFormContaFInanceira;
