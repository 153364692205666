import React, { useState, useEffect } from 'react';
import { Button, Form, Row, Col, Modal } from 'react-bootstrap';
import { useDropzone } from 'react-dropzone';
import { AlertService } from '../components/AlertService';
import api from './api';
import { url } from '../environments/environments-develop';
import * as ExcelJS from 'exceljs';
import TableImportComponents from '../components/TableImportComponents';
import { count } from 'console';
import TokenService from './Auth/token.service';

interface FileUploaderFormProps {
  onFileUpload: (data: any) => void;
}
function safeSplit(obj: any, path: string): any {
  return path.split('.').reduce((acc, key) => (acc && acc[key] !== undefined ? acc[key] : undefined), obj);
}

const FileUploaderForm: React.FC<FileUploaderFormProps> = ({ onFileUpload }) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [contentRow, setContentRow] = useState<number | null>(null);
  const [sheetsToProcess, setSheetsToProcess] = useState<string>("");
  const [modo_import, setModoImport] = useState<any>();
  const [jsonData, setJsonData] = useState({});
  const [formularioEnvio, setFormularioEnvio] = useState({});
  const [formData, setFormData] = useState({
    identificador1: null,
    identificador2: null,
    area: null,
    logradouro: null,
    frente: null,
    fundo: null,
    confr_fundo: null,
    lado_direito: null,
    confr_lado_direito: null,
    lado_esquerdo: null,
    confr_lado_esquerdo: null,
    chanfro: null,
    imo_preco: null,
    imo_intermed: null,
    imo_matricula: null,
    observacao: null,
    intermed_reduzida: null,
    conta_ativo: null,
    conta_passivo: null,
    classif_preco: null,
    sub_tipo_imovel: null,
    tipo_imovel: null,
  });

  const [listPermi, setListPermi] = useState([]);
  const [header, setHeader] = useState<string[]>([]);
  const [titles, setTitles] = useState<string[]>([]);
  const [retorno, setRetorno] = useState<any[]>([]);
  const [qtdLotes, setQtdLotes] = useState<string>("");

  async function listEmpreendimento() {
    try {
      const response = await api.get(`${url}/api/v1/admin/vendas/empreendimentos`);
      setListPermi(response.data.data);
    } catch (error) {
      AlertService.error('Error', 'Ocorreu um erro ao buscar a lista de permissões. Erro:' + String(error));
    }
  }

  useEffect(() => {
    listEmpreendimento();
    const savedFormData = localStorage.getItem('formData');
    if (savedFormData) {
      setFormData(JSON.parse(savedFormData));
    }
  }, []);

  const openModal = () => {

  };

  const removeFile = () => {
    setSelectedFile(null);
    setContentRow(null);
    setSheetsToProcess("");

  };

  const handleFormChange = (field: string, value: any) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const handleOnDrop = async (
    file: File,
    startRow: number,
    sheetsToProcess: string,
  ) => {
    try {
      const arrayBuffer = await file.arrayBuffer();
      const uint8Array = new Uint8Array(arrayBuffer);

      const workbook = new ExcelJS.Workbook();
      await workbook.xlsx.load(uint8Array.buffer);

      var sheets = sheetsToProcess.split(',').map(sheetName => sheetName.trim());
      if (sheets.length === 1 && sheets[0] === '') {
        sheets = workbook.worksheets.map(sheet => sheet.name);
      }

      const newData: any[] = [];

      sheets.forEach((sheetName: any) => {
        const worksheet = workbook.getWorksheet(sheetName);
        if (worksheet) {
          worksheet.eachRow((row, rowNumber) => {
            try {
              if (rowNumber >= startRow) {
                var rowData: Record<string, any> = {};

                Object.keys(formData).forEach(key => {
                  const targetColumn: any = formData[key as keyof typeof formData];

                  if (targetColumn) {
                    try {
                      let cell: any;

                      if (key === 'identificador1') {
                        let quadra: any = formData.identificador1;
                        if (quadra.match(/\d+/) !== null) {
                          const matchResult = quadra.match(/[A-Za-z]+/);
                          if (matchResult) {
                            const coluna = matchResult[0];
                            const linhaResult = quadra.match(/\d+/);
                            if (linhaResult) {
                              const linha = Number(linhaResult[0]);
                              if (linha <= worksheet.rowCount) {
                                const valorCelula = worksheet.getCell(`${coluna}${linha}`).value;
                                if (valorCelula !== null && valorCelula !== undefined && valorCelula !== '') {
                                  rowData['identificador1'] = valorCelula;
                                }
                              }
                            } else {
                              AlertService.error('error', 'Formato inválido para a linha.');
                            }
                          } else {
                            AlertService.error('error', 'Formato inválido para a coluna.');
                          }
                        } else {
                          let cell: any = row.getCell(quadra).value;
                          if (cell && cell.result) {
                            rowData[key] = cell.result;
                          } else {
                            rowData[key] = cell;
                          }
                        }
                      } else if (key === 'area') {
                        let area: any = formData.area;

                        if (area) {
                          const positionArray = area.includes(',') ? area.split(',') : [area];

                          positionArray.forEach((position: any) => {
                            const trimmedPosition = position.trim();
                            let cell: any;

                            if (trimmedPosition) {
                              // Se a posição não estiver vazia, use-a diretamente
                              cell = row.getCell(trimmedPosition).value;
                            } else {
                              // Se a posição estiver vazia, encontre a próxima posição após a vírgula
                              const commaIndex = area.indexOf(',');
                              if (commaIndex !== -1) {
                                area = area.slice(commaIndex + 1);
                                const nextCommaIndex = area.indexOf(',');
                                const nextPosition = nextCommaIndex !== -1 ? area.slice(0, nextCommaIndex) : area;
                                area = area.slice(nextCommaIndex + 1);

                                cell = row.getCell(nextPosition).value;
                              }
                            }
                            if (cell) {
                              if (cell.result) {
                                rowData['area'] = cell.result;
                              } else {
                                rowData['area'] = cell;
                              }
                            }
                            rowData['sheet'] = sheetName;
                            rowData['linha'] = rowNumber;
                            rowData['coluna'] = targetColumn;
                          });
                        }
                      } else if (
                        row.getCell(targetColumn).value !== null &&
                        row.getCell(targetColumn).value !== undefined &&
                        row.getCell(targetColumn).value !== ''
                      ) {
                        cell = row.getCell(targetColumn).value;

                        if (cell && cell.result) {
                          rowData[key] = cell.result;
                        } else {
                          rowData[key] = cell;
                        }
                      }
                    } catch (columnError) {
                      console.error(`Error processing column ${targetColumn}:`, columnError);
                    }
                  }
                });

                if (Object.values(rowData).length > 0 && rowData['identificador2']) {
                  newData.push(rowData);
                }

                rowData = {};
              }
            } catch (rowError) {
              AlertService.error('error', `Ocorreu um erro ao tentar processar a linha: ${rowNumber}:` + rowError);
            }
          });
        }
      });
      setQtdLotes(String(newData.length));
      setJsonData(newData);
      const titles = ['sheet', 'linha', 'identificador1', 'identificador2', 'area', 'logradouro', 'frente', 'fundo', 'confr_fundo', 'lado_direito', 'confr_lado_direito', 'lado_esquerdo', 'confr_lado_esquerdo', 'chanfro', 'imo_preco', 'imo_intermed', 'imo_matricula', 'observacao', 'tipo_imovel','sub_tipo_imovel','classif_preco',];
      const titlesValues = ['Planilha', 'Linha', 'Quadra', 'Lote', 'Área', 'Logradouro', 'Frente', 'Fundo', 'Confrontante do Fundo', 'Lado Direito', 'Confrontante Lado Direito', 'Lado Esquerdo', 'Confrontante Lado Esquerdo', 'Chanfro', 'Preço do Imóvel/Lote', 'Intermediação', 'Matricula do Imóvel/Lote', 'Observação', 'Tipo Imóvel', 'SubTipo Imóvel', 'Classificação de Preço'];
      setHeader(titles);
      setTitles(titlesValues)
      setRetorno(newData);
      localStorage.setItem('formData', JSON.stringify(formData));
      localStorage.setItem('excelProcessado', JSON.stringify(newData));
      save(newData);
    } catch (error) {
      AlertService.error('Erro', 'Erro ao ler o arquivo XLSX: ' + String(error));
    }
  };

  const save = (json: any) => {
    if (selectedFile) {
      const fileName = selectedFile.name;
      const fileExtension = fileName.slice((fileName.lastIndexOf(".") - 1 >>> 0) + 2);
      const reader = new FileReader();
      const empreendimento = TokenService.getEmpreendimentoId().id;
      reader.onload = () => {
        const result = reader.result as string;
        if (result) {
          let form = {
            file: json,
            file_original: result.split(',')[1],
            extension_file_original: fileExtension,
            empreendimento_id: empreendimento,
            modo_import: Number(modo_import),
            localImport: 6
          };
          onFileUpload(form);
        } else {
          AlertService.error('error', 'Houve um erro ao tentar montar o formulário de importação. Caso o erro persista, entre em contato com o suporte.');
        }
      };

      reader.readAsDataURL(selectedFile);
    }
  };


  const columns = retorno.map(item =>
    header.map(title => {
      let value: any = item;
      const titleParts = safeSplit(value, title.toLowerCase()) as (string | number)[];
      value = titleParts !== undefined ? titleParts : 'N/A';

      return String(value || '');
    })
  );

  const onDrop = (acceptedFiles: File[]) => {
    if (acceptedFiles.length > 0) {
      const file = acceptedFiles[0];
      setSelectedFile(file);
    }
  };

  const handleConfigSubmit = () => {
    try {
      if (selectedFile) {
        handleOnDrop(selectedFile, contentRow || 1, sheetsToProcess);
        openModal();
      } else {
        AlertService.error('Erro', 'Nenhum arquivo selecionado.');
      }
    } catch (error: any) {
      AlertService.error('Erro', 'Erro: ' + error.message);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  return (
    <div>
      <div
        {...getRootProps()}
        style={{
          border: '2px dashed #cccccc',
          padding: '20px',
          textAlign: 'center',
        }}
      >
        <input {...getInputProps()} />
        {!selectedFile && (
          <p>Arraste e solte o arquivo de memorial descritivo aqui, ou clique para selecionar.</p>
        )}
        {selectedFile && (
          <>
            <p>{selectedFile.name}</p>
            <Button variant="contained" className='btn btn-danger' onClick={removeFile}>
              Remover Arquivo
            </Button>
          </>
        )}
      </div>
      <div style={{ margin: '24px' }}>
      </div>
      <div style={{ margin: '24px', color: '#fff', backgroundColor: '#ff1717' }}>
        <p><strong>Atenção</strong>, as colunas devem ser referenciadas por letras, da mesma forma que é exposta no excel</p>
      </div>
      {selectedFile && (

        <form style={{ width: '1200px' }}>
          <div style={{ margin: '24px', color: '#000', backgroundColor: '#ffdc14' }}>
            <p><strong>Observação</strong>, o campo referente a <strong>QUADRA</strong>, em caso do valor do mesmo estar em uma coluna e linha específica, informar o valor como mostrado no <strong>Exemplo 1</strong>, a não ser que tenha uma coluna onde listará o valor referente as quadras de cada lote, nesse caso deve ser seguido o <strong>Exemplo 2</strong> </p>
          </div>
          <Row className="group-form">
            <Col>
              <Form.Label>Qual o Modelo de importação?</Form.Label>
              <Form.Select
                value={modo_import || ''}
                onChange={(e) => setModoImport(e.target.value)}
              >
                <option>Selecione...</option>
                <option key="1" value="1">Inserir Lotes</option>
                <option key="2" value="2">Atualizar Lotes</option>
              </Form.Select>
            </Col>
            <Col>
              <Form.Label>Em qual coluna está a Quadra?</Form.Label>
              <Form.Control
                placeholder='(Ex 1: A11); (Ex 2: F)'
                value={formData.identificador1 || ''}
                onChange={(e) => handleFormChange('identificador1', e.target.value)}
              />
            </Col>
            <Col>
              <Form.Label>Em qual coluna está o Lote?</Form.Label>
              <Form.Control
                value={formData.identificador2 || ''}
                onChange={(e) => handleFormChange('identificador2', e.target.value)}
              />
            </Col>
          </Row>
          <Row className="group-form">
            <Col>
              <Form.Label>Em quais Colunas está a classificação do lote?</Form.Label>
              <Form.Control
                value={formData.classif_preco || ''}
                onChange={(e) => handleFormChange('classif_preco', e.target.value)} />
            </Col>
            <Col>
              <Form.Label>Em qual Coluna está o tipo do lote</Form.Label>
              <Form.Control
                value={formData.tipo_imovel || ''}
                onChange={(e) => handleFormChange('tipo_imovel', e.target.value)} />
            </Col>
            <Col>
              <Form.Label>Em qual Coluna está o subtipo do lote?</Form.Label>
              <Form.Control
                value={formData.sub_tipo_imovel || ''}
                onChange={(e) => handleFormChange('sub_tipo_imovel', e.target.value)} />
            </Col>
          </Row>
          <div style={{ margin: '24px', color: '#fff', backgroundColor: '#ff1717' }}>
            <p><strong>Atenção</strong>, na hora de informar a área do lote, no caso exista duas ou mais colunas, deve ser seguido o <strong>Exemplo 1 ou Exemplo 2</strong> mostrado no campo, caso seja somente uma coluna, deve ser seguido o <strong>Exemplo 3</strong> mostrado no campo.</p>
          </div>
          <Row className="group-form">
            <Col>
              <Form.Label>Em quais Colunas está a área do lote?</Form.Label>
              <Form.Control
                value={formData.area || ''}
                placeholder='(Ex: A, B); (Ex 2: F, G, H); (Ex 3: N)'
                onChange={(e) => handleFormChange('area', e.target.value)} />
            </Col>
            <Col>
              <Form.Label>Em qual Coluna está o logradouro</Form.Label>
              <Form.Control
                value={formData.logradouro || ''}
                onChange={(e) => handleFormChange('logradouro', e.target.value)} />
            </Col>
            <Col>
              <Form.Label>Em qual Coluna está à metragem da frente do lote?</Form.Label>
              <Form.Control
                value={formData.frente || ''}
                onChange={(e) => handleFormChange('frente', e.target.value)} />
            </Col>
          </Row>
          <Row className="group-form">
            <Col>
              <Form.Label>Em qual Coluna está à metragem do fundo do lote?</Form.Label>
              <Form.Control
                value={formData.fundo || ''}
                onChange={(e) => handleFormChange('fundo', e.target.value)} />
            </Col>
            <Col>
              <Form.Label>Em qual Coluna está o confrantante do fundo?</Form.Label>
              <Form.Control
                value={formData.confr_fundo || ''}
                onChange={(e) => handleFormChange('confr_fundo', e.target.value)} />
            </Col>
            <Col>
              <Form.Label>Em qual Coluna está metragem do lado esquerdo</Form.Label>
              <Form.Control
                value={formData.lado_esquerdo || ''}
                onChange={(e) => handleFormChange('lado_esquerdo', e.target.value)} />
            </Col>
          </Row>
          <Row className="group-form">
            <Col>
              <Form.Label>Em qual Coluna está o confrantante do lado esquerdo?</Form.Label>
              <Form.Control
                value={formData.confr_lado_esquerdo || ''}
                onChange={(e) => handleFormChange('confr_lado_esquerdo', e.target.value)} />
            </Col>
            <Col>
              <Form.Label>Em qual Coluna está a metragem do lado direito?</Form.Label>
              <Form.Control
                value={formData.lado_direito || ''}
                onChange={(e) => handleFormChange('lado_direito', e.target.value)} />
            </Col>
            <Col>
              <Form.Label>Em qual Coluna está o confrantante do lado direito?</Form.Label>
              <Form.Control
                value={formData.confr_lado_direito || ''}
                onChange={(e) => handleFormChange('confr_lado_direito', e.target.value)} />
            </Col>
          </Row>
          <Row className="group-form">
            <Col>
              <Form.Label>Em qual Coluna está a metragem do chanfro?</Form.Label>
              <Form.Control
                value={formData.chanfro || ''}
                onChange={(e) => handleFormChange('chanfro', e.target.value)} />
            </Col>
            <Col>
              <Form.Label>Em qual Coluna está a intermediação?</Form.Label>
              <Form.Control
                value={formData.imo_intermed || ''}
                onChange={(e) => handleFormChange('imo_intermed', e.target.value)} />
            </Col>
            <Col>
              <Form.Label>Em qual Coluna está o Valor do Imóvel?</Form.Label>
              <Form.Control
                value={formData.imo_preco || ''}
                onChange={(e) => handleFormChange('imo_preco', e.target.value)} />
            </Col>

          </Row>
          <Row className="group-form">
            <Col>
              <Form.Label>Existe intermediação Reduzida?Se sim, informe a coluna</Form.Label>
              <Form.Control
                value={formData.intermed_reduzida || ''}
                onChange={(e) => handleFormChange('intermed_reduzida', e.target.value)} />
            </Col>
            <Col>
              <Form.Label>Existe uma conta contábil ativa?Se sim, informe a coluna</Form.Label>
              <Form.Control
                value={formData.conta_ativo || ''}
                onChange={(e) => handleFormChange('conta_ativo', e.target.value)}
              />
            </Col>
            <Col>
              <Form.Label>Existe uma conta contábil passiva?Se sim, informe a coluna</Form.Label>
              <Form.Control
                value={formData.conta_passivo || ''}
                onChange={(e) => handleFormChange('conta_passivo', e.target.value)}
              />
            </Col>
          </Row>
          <div style={{ margin: '24px', color: '#000', backgroundColor: '#ffdc14' }}>
            <p><strong>Atenção</strong>, em caso de importação de todas as sheets contidas no excel, por favor mantenha o campo <strong>NOME SHEET</strong> em branco, caso contrario, informe o nome de cada sheet a ser importada</p>
          </div>
          <Row className="group-form">
            <Col>
              <Form.Label>Em qual Linha Inicial do Conteúdo?</Form.Label>
              <Form.Control
                value={contentRow || ''}
                onChange={(e) => setContentRow(parseInt(e.target.value, 10))}
              />
            </Col>
            <Col>
              <Form.Label>Nome Sheets (separadas por vírgula)</Form.Label>
              <Form.Control
                value={sheetsToProcess}
                placeholder='Deixe em branco para todas as sheets'
                onChange={(e) => setSheetsToProcess(e.target.value)}
              />
            </Col>
            <Col>
              <Form.Label>Em qual Coluna está a matricula do lote?</Form.Label>
              <Form.Control
                value={formData.imo_matricula || ''}
                onChange={(e) => handleFormChange('imo_matricula', e.target.value)} />
            </Col>
          </Row>
          <Button variant="contained" className='btn btn-success' onClick={handleConfigSubmit}>
            Confirmar Configurações
          </Button>
        </form>
      )}
      <div style={{ margin: '24px', color: '#000', backgroundColor: '#ffdc14' }}>
        <p><strong>Atenção</strong>, Após a listagem dos lotes importados, confira todos campos de cada lote para confirmar a sua veracidade, em caso de erro, confirá o formulário de importação e tente novamente, caso o erro persista entre em contato com o suporte ou consulte a documenteção.</p>
      </div>
      <Modal.Header>
        <Modal.Title>Listagem dos Dados de Importação</Modal.Title>
        <Modal.Title style={{ fontSize: "12px", display: 'flex' }}>Quantidade de Lotes a serem importadas: <p style={{ width: '35px', backgroundColor: 'yellow' }}>{qtdLotes}</p></Modal.Title>
      </Modal.Header>
      <TableImportComponents header={titles} columns={columns} itemsPerPage={7} urlSearchDataPut="/api/v1/admin/vendas/classificacoesimoveis/" url_redirect_edit="/classificacoesimoveis/update" />
    </div>
  );
};

export default FileUploaderForm;