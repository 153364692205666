import { useEffect, useRef } from "react";
import { handleToggleClickCancelarContrato, handleToggleClickEmitirContrato, handleToggleClickReativarContrato, setSessionEditData, setSessionShowData } from "./GestaoContratosServices";
import { Tooltip } from 'primereact/tooltip';
import { Toast } from 'primereact/toast';
import { SpeedDial } from "primereact/speeddial";

export const actionTemplate = (
    value: any,
    urlSearchDataPut: any,
    url_redirect_edit: any,
    setSelectedNodeKeys: (value: any) => void,
    url_redirect_show: any
) => {
    const toast = useRef<Toast>(null);
    const commonStyle = {
        width: '35px',
        height: '35px',
        top: '0px',
        color: '#fff'
    };
    const items = [
        {
            label: 'Visualizar',
            icon: 'pi pi-arrow-up-right',
            style: { ...commonStyle, backgroundColor: '#007bff' },
            command: () => {
                setSessionShowData(value.id, urlSearchDataPut, url_redirect_show);
            },
            tooltip: 'Visualizar'
        }
    ];

    useEffect(() => {
        items.forEach((item, index) => {
            const element = document.querySelectorAll('.p-speeddial-action')[index];
            if (element) {
                element.setAttribute('data-pr-tooltip', item.tooltip);
                element.setAttribute('data-pr-position', 'left');
            }
        });
    }, [items]);

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Toast ref={toast} />
            <Tooltip target="[data-pr-tooltip]" />
            <div style={{ position: 'relative' }}>
                <SpeedDial
                    model={items}
                    radius={45}
                    showIcon="pi pi-ellipsis-v"
                    hideIcon="pi pi-times"
                    type="linear"
                    direction="left"
                    style={{ top: 'calc(40%)', right: 18 }}
                    buttonClassName="button-speed-dial"
                />
            </div>
        </div>
    );
};