import React, { ReactNode, createContext, useContext, useState } from 'react';

export interface EnderecoData {
    apelido: string;
    logradouro: string;
    tipo_endereco: string;
    cep: string;
    numero: string;
    cidade: any;
    cidade_nome: string;
    cidade_id: string;
    complemento: string;
}

interface EnderecoContextProps {
    enderecosData: EnderecoData[];
    updateEnderecosData: (newData: EnderecoData[]) => void;
}

const EnderecoContext = createContext<EnderecoContextProps | undefined>(undefined);

export const useAppContext = () => {
    const context = useContext(EnderecoContext);
    if (!context) {
        throw new Error('useAppContext must be used within an AppProvider');
    }
    return context;
};

interface AppProviderProps {
    children: ReactNode;
}

export const EnderecoProvider: React.FC<AppProviderProps> = ({ children }) => {
    const [enderecosData, updateEnderecosData] = useState<EnderecoData[]>([]);

    const updateEnderecos = (newData: EnderecoData[]) => {
        updateEnderecosData(newData);
    };

    return (
        <EnderecoContext.Provider value={{ enderecosData, updateEnderecosData: updateEnderecos }}>
            {children}
        </EnderecoContext.Provider>
    );
};

export const useEnderecoContext = () => {
    return useContext(EnderecoContext);
};
