import React, { createContext, useContext, useState, ReactNode } from "react";
import TokenService from "../services/Auth/token.service";
import { PerfilProvider } from "./PerfilContext";
import { UsuarioProvider } from "./UsuarioContext";
import { PessoaProvider } from "./PessoaContext";
import { EnderecoProvider } from "./EnderecoContext";
import { TelefoneProvider } from "./TelefoneContext";
import { SidebarProvider, useSidebarContext } from "./SidebarContext";
import { ResponseProvider } from "./ResponseContext";
import { MenuItem } from 'primereact/menuitem';
import { Link } from "react-router-dom";
interface AppContextProps {
  url_button_create: string;
  setUrl_button_create: React.Dispatch<React.SetStateAction<string>>;
  contextItemsButtonMenu: any[];
  setContextItemsButtonMenu: React.Dispatch<React.SetStateAction<any[]>>;
  url: string;
  setUrl: React.Dispatch<React.SetStateAction<string>>;
  typeRequest: string;
  setTypeRequest: React.Dispatch<React.SetStateAction<string>>;
  body: string;
  setBody: React.Dispatch<React.SetStateAction<any>>;
  retorno: string;
  setRetorno: React.Dispatch<React.SetStateAction<any>>;
  situacaoLicenca: Number;
  setSituacaoLicenca: React.Dispatch<React.SetStateAction<Number>>;
  showButtonSave: boolean;
  setShowButtonSave: React.Dispatch<React.SetStateAction<boolean>>;
  validationRequest: boolean;
  setValidationRequest: React.Dispatch<React.SetStateAction<boolean>>;
  showSubSidebar: boolean;
  setShowSubSidebar: React.Dispatch<React.SetStateAction<boolean>>;
  perfilData: {
    nome: string;
    email: string;
    username: string;
    cpfCnpj: string;
    endereco: string;
    estado: string;
    cidade: string;
    cep: string;
    status: string;
  };
  itemsBreadsCrumbs: MenuItem[];
  setItemsBreadsCrumbs: React.Dispatch<React.SetStateAction<MenuItem[]>>;
  updatePerfilData: (newData: Partial<AppContextProps["perfilData"]>) => void;
}

const AppContext = createContext<AppContextProps | undefined>(undefined);

export const useAppContext = () => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error("useAppContext must be used within an AppProvider");
  }
  return context;
};

interface AppProviderProps {
  children: ReactNode;
}

export const AppProvider: React.FC<AppProviderProps> = ({ children }) => {
  const [showSubSidebar, setShowSubSidebar] = useState(true);
  const perfil = TokenService.getPerfil();
  const [showButtonSave, setShowButtonSave] = useState(false);
  const [validationRequest, setValidationRequest] = useState(true);
  const [url_button_create, setUrl_button_create] = useState("");
  const [contextItemsButtonMenu, setContextItemsButtonMenu] = useState<any[]>([]);
  const [url, setUrl] = useState("");
  const [body, setBody] = useState("");
  const [typeRequest, setTypeRequest] = useState("");
  const [retorno, setRetorno] = useState("");
  const [itemsBreadsCrumbs, setItemsBreadsCrumbs] = useState<MenuItem[]>([]);
  const [situacaoLicenca, setSituacaoLicenca] = useState<Number>(Number(perfil?.data?.situacao_licenca || 1)); 
  const [perfilData, setPerfilData] = useState({
    nome: "",
    email: "",
    username: "",
    cpfCnpj: "",
    endereco: "",
    estado: "1", // Valor padrão para Ativado
    cidade: "",
    cep: "",
    status: "",
  });
  const updatePerfilData = (
    newData: Partial<AppContextProps["perfilData"]>
  ) => {
    setPerfilData((prevData) => ({ ...prevData, ...newData }));
  };

  return (
    <AppContext.Provider
      value={{
        situacaoLicenca,
        setSituacaoLicenca,
        itemsBreadsCrumbs,
        setItemsBreadsCrumbs,
        typeRequest,
        setTypeRequest,
        url,
        setUrl,
        url_button_create,
        setUrl_button_create,
        contextItemsButtonMenu,
        setContextItemsButtonMenu,
        body,
        setBody,
        retorno,
        setRetorno,
        showSubSidebar,
        setShowSubSidebar,
        validationRequest,
        setValidationRequest,
        showButtonSave,
        setShowButtonSave,
        perfilData,
        updatePerfilData,
      }}
    >

      <SidebarProvider>
        <PerfilProvider>
          <UsuarioProvider>
            <PessoaProvider>
              <EnderecoProvider>
                <TelefoneProvider>
                  <ResponseProvider>
                    {children}
                  </ResponseProvider>
                </TelefoneProvider>
              </EnderecoProvider>
            </PessoaProvider>
          </UsuarioProvider>
        </PerfilProvider>
      </SidebarProvider>
    </AppContext.Provider>
  );
};
