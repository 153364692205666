import { MDBCol, MDBPageItem, MDBPageNav, MDBPagination, MDBRow } from 'mdbreact';

interface PaginacaoProps {
    totalPages: number;
    currentPage: number;
    setCurrentPage: (value: number) => void;
}

const Paginacao: React.FC<PaginacaoProps> = ({ totalPages, currentPage, setCurrentPage }) => {
    const renderPageNumbers = () => {
        const pageNumbers = [];

        for (let i = 0; i < totalPages; i++) {
            if (i < 3 || i === totalPages - 1 || (i >= currentPage - 1 && i <= currentPage + 1)) {
                pageNumbers.push(
                    <MDBPageItem key={i} active={i === currentPage}>
                        <MDBPageNav className="page-link" onClick={() => setCurrentPage(i)}>
                            <span key={i} onClick={() => setCurrentPage(i)} style={{ cursor: 'pointer', margin: '0 5px', fontWeight: i === currentPage ? 'bold' : 'normal' }}>
                                {i + 1}
                            </span>
                        </MDBPageNav>
                    </MDBPageItem>
                );
            }
        }
        return pageNumbers;
    };

    return (
        <div className="pagination-footer">
            <MDBRow>
                <MDBCol>
                    <MDBPagination circle>
                        <MDBPageItem disabled={currentPage === 0}>
                            <MDBPageNav className="page-link" aria-label="Previous" onClick={() => setCurrentPage(currentPage - 1)}>
                                <span aria-hidden="true">&laquo;</span>
                            </MDBPageNav>
                        </MDBPageItem>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            {renderPageNumbers()}
                        </div>
                        <MDBPageItem disabled={currentPage === totalPages - 1}>
                            <MDBPageNav className="page-link" aria-label="Next" onClick={() => setCurrentPage(currentPage + 1)}>
                                <span aria-hidden="true">&raquo;</span>
                            </MDBPageNav>
                        </MDBPageItem>
                    </MDBPagination>
                </MDBCol>
            </MDBRow>
        </div>
    );
};

export default Paginacao;
