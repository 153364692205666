import Omniteq from '../../../../../assets/images/logo_omniteq.png'
function LogoComponent({ empreendimento }: any) {
  return (
    <ul className="navbar-nav mr-auto mt-2 mt-lg-0 ulConfig">
      <li className="nav-item">
        <div className="nav-link">

          <img
            style={{ marginLeft: '10px' }}
            width="110" height="30"
            src={Omniteq}
          />
        </div>
      </li>
    </ul>
  );
}

export default LogoComponent;
