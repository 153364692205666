import { Outlet, useLocation } from 'react-router-dom';
import './../../styles/global.css';
import React, { useEffect, useState } from 'react';
import { useAppContext } from '../../contexts/AppContext';
import TokenService from '../../services/Auth/token.service';
import NavBarComponent from './navbar-layout/NavbarComponent';
import MenuComponent from './navbar-layout/components/MenuComponent';
import ButtonsCrumbsComponent from '../../components/ButtonsCrumbsComponent';
import FooterComponent from './footer-layout/FooterComponent';
import LoadingWrapper from '../../../core/Loader/LoadingWrapper';
import BreadsCrumbsComponent from '../../components/BreadsCrumbsComponent';
import { useSidebarContext } from '../../contexts/SidebarContext';
import SelectComponent from '../../components/SelectComponent';
import SelectEmpreendimentoComponent from '../../components/SelectEmpreendimentoComponent';
import SidebarRouterComponent from './navbar-layout/components/SidebarRouterComponent';
interface SubmenuItem {
  id: number;
  parent_id: number;
  name: string;
  icon: string;
  is_menu: boolean;
  is_context_menu: boolean;
  route: string;
  submenu: SubmenuItem[];
}

interface MenuProps {
  id: number;
  parent_id: number;
  name: string;
  icon: string;
  route: string;
  is_menu: boolean;
  is_context_menu: boolean;
  submenu: SubmenuItem[];
}

interface SidebarProps {
  menu: MenuProps[];
}
const Home: React.FC = () => {

  const location = useLocation();
  const { showSubSidebar, setShowSubSidebar } = useAppContext();
  const [selectedEmpreendimento, setSelectedEmpreendimento] = useState<number | null>(null);
  const menuProps: MenuProps[] = [];
  const empreendimentoList = TokenService.getFiliais();
  const permissoes = TokenService.getPermissoes();
  const { isVisible } = useSidebarContext();
  if (permissoes) {
    permissoes[0].permission.forEach((element: any) => {
      let data_route = cleanRoute("/" + element.slug);
      menuProps.push({
        id: element.id,
        parent_id: element.parent_id,
        name: element.titulo,
        icon: element.icon,
        route: data_route,
        is_menu: element.is_menu,
        is_context_menu: element.is_context_menu,
        submenu: [],
      });
    });
    permissoes[0].permission.forEach((element: any) => {
      if (element.is_context_menu === true) {
        const menuIndex = menuProps.findIndex((menu) => menu.id === element.parent_id && element.is_context_menu === true);
        if (menuIndex !== -1) {
          if (!menuProps[menuIndex].submenu) {
            menuProps[menuIndex].submenu = [];
          }
          let is_context_menu = cleanRoute("/" + element.slug);
          menuProps[menuIndex].submenu.push({
            id: element.id,
            parent_id: element.parent_id,
            name: element.titulo,
            icon: element.icon,
            route: is_context_menu,
            is_menu: element.is_menu,
            is_context_menu: element.is_context_menu,
            submenu: []
          });
        }
      }
    });
    // Ordenar o array menuProps para garantir que "home" seja o primeiro
    menuProps.sort((a, b) => {
      if (a.name.toLowerCase() === "home") {
        return -1;
      } else if (b.name.toLowerCase() === "home") {
        return 1;
      } else {
        return 0;
      }
    });
  }
  function cleanRoute(route: string) {
    return route.replace(/\/index$/, '');
  }
  const handleEmpreendimentoChange = (index: number) => {
    setSelectedEmpreendimento(index);
  };
  return (
    <LoadingWrapper>
      <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
        <NavBarComponent menuProps={menuProps} empreendimento={empreendimentoList} onEmpreendimentoChange={handleEmpreendimentoChange}>
          <MenuComponent menu={menuProps} />
        </NavBarComponent>
        <div className="content-breads">
          <BreadsCrumbsComponent />
          <ButtonsCrumbsComponent />
        </div>

        <div role="main" className="d-flex flex-grow-1 container-sidebar">
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {showSubSidebar && (
              <div className={`${!isVisible ? 'sidebar-visible' : 'sidebar-select-empreendimento'}`} style={{ paddingBottom: '15px' }}>
                <SelectEmpreendimentoComponent />
              </div>
            )}
            {showSubSidebar && (
              <div key="sidebar" className={`${!isVisible ? 'sidebar-visible' : 'sidebar'}`}>
                <SidebarRouterComponent menus={menuProps} />
              </div>
            )}
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            <Outlet />
          </div>
        </div>

        <footer>
          <FooterComponent />
        </footer>
      </div>
    </LoadingWrapper>
  );

}
export default Home;
