import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import 'react-datepicker/dist/react-datepicker.css';
import { InputNumber } from "primereact/inputnumber";
import { Calendar } from "primereact/calendar";
import { addLocale } from "primereact/api";
interface PropsData {
    secaoEntradaData: {
        entrada?: number;
        qtd_parc_entrada?: number;
        valor_prim_parc_entrada?: number;
        venc_prim_parc_entrada?: Date;
        tipo_desconto?: number;
        desconto?: number;
        observacao_condicao?: string;
    };
    onInputChange: (field: string, value: any) => void;
}

function EditSecaoEntrada(props: PropsData) {
    const { entrada, qtd_parc_entrada, valor_prim_parc_entrada } = props.secaoEntradaData || {};
    const [venc_prim_parc_entrada, setPrimeiroVencimentoEntrada] = useState<Date | null>(null);
    useEffect(() => {
        setPrimeiroVencimentoEntrada(props.secaoEntradaData?.venc_prim_parc_entrada ? new Date(props.secaoEntradaData?.venc_prim_parc_entrada) : null);
    }, [props.secaoEntradaData])
    const handleChange = (field: string, value: any) => {
        props.onInputChange(field, value);
    };
    addLocale('pt', {
        firstDayOfWeek: 0,
        dayNames: ['domingo', 'segunda-feira', 'terça-feira', 'quarta-feira', 'quinta-feira', 'sexta-feira', 'sábado'],
        dayNamesShort: ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sáb'],
        dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
        monthNames: ['janeiro', 'fevereiro', 'março', 'abril', 'maio', 'junho', 'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro'],
        monthNamesShort: ['jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez'],
        today: 'Hoje',
        clear: 'Limpar'
    });
    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            flex: '1',
            height: '100%',
            marginRight: '25px',
            minHeight: '350px',
        }}>
            <div style={{ display: 'flex', justifyContent: 'center', width: '100%', backgroundColor: '#4949498a', color: '#fff', paddingLeft: '5px', paddingBottom: '12px', paddingTop: '12px', borderRadius: '7px' }}>
                <h5
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        textAlign: 'center'
                    }}
                >
                    Informações Da Entrada
                </h5>
            </div>
            <hr />
            <Row className="mb-3">
                <Col md="3" style={{ display: 'flex', flexDirection: 'column', marginTop: '14px' }}>
                    <span className="p-float-label">
                        <InputNumber
                            style={{ width: '100%' }}
                            value={entrada ? Number(entrada) : null}
                            onChange={(e: any) => handleChange('entrada', e.value)}
                            mode="currency" currency="BRL" locale="pt-BR"
                        />
                        <label style={{ paddingTop: '2px ' }} htmlFor="ac" >Valor Entrada</label>
                    </span>
                </Col>
                <Col md="3" style={{ display: 'flex', flexDirection: 'column', marginTop: '14px' }}>
                    <span className="p-float-label">
                        <InputNumber
                            value={qtd_parc_entrada}
                            onChange={(e) => handleChange('qtd_parc_entrada', e.value)}
                            style={{ width: '100%' }}
                            mode="decimal"
                            min={0}
                            max={999}
                        />
                        <label style={{ paddingTop: '2px ' }} htmlFor="ac" >Qtd Parcelas Entrada</label>
                    </span>
                </Col>
            </Row>
            <Row className="mb-3">
                <Col md="3" style={{ display: 'flex', flexDirection: 'column', marginTop: '14px' }}>
                    <span className="p-float-label">
                        <InputNumber
                            style={{ width: '100%', height: '38px' }}
                            value={valor_prim_parc_entrada ? Number(valor_prim_parc_entrada) : null}
                            onChange={(e: any) => handleChange('valor_prim_parc_entrada', e.value)}
                            mode="currency" currency="BRL" locale="pt-BR"
                        />
                        <label style={{ paddingTop: '2px ' }} htmlFor="ac" >Valor 1ª Parcela Entrada</label>
                    </span>
                </Col>
                <Col md={3} style={{ display: 'flex', flexDirection: 'column', marginTop: '14px' }}>
                    <span className="p-float-label">
                        <Calendar
                            value={venc_prim_parc_entrada || null}
                            onChange={(event: any) => {
                                const selectedDates = event.value;
                                setPrimeiroVencimentoEntrada(selectedDates);
                                handleChange('venc_prim_parc_entrada', selectedDates ? selectedDates.toISOString() : null);
                            }}
                            showButtonBar
                            selectionMode="single"
                            dateFormat="dd/mm/yy"
                            locale="pt"
                            readOnlyInput
                            style={{ textAlign: 'center', width: '100%' }}
                            panelClassName="custom-calendar-panel"
                            required
                        />
                        <label style={{ paddingTop: '2px ' }} htmlFor="ac" >1º Vencimento Entrada</label>
                    </span>
                </Col>
            </Row>
        </div>
    );
}

export default EditSecaoEntrada;
