import iconMenuTresPonto from '../../../../assets/images/icon-menu-tres-pontos.png';
import { Button } from 'primereact/button';
import { useState } from 'react';
import { Row, Col, Form, NavLink, Modal, Container } from 'react-bootstrap';
import iconFiltroAvancado from '../../../../assets/images/icon-filtro-avancado.png';
import { useSidebarContext } from './../../../contexts/SidebarContext';

interface ContextSearchProps {
    header?: string;
    filtrosData: {
        nome?: string;
        email?: string;
        cpf_cnpj?: string;
        username?: string;
    };
    onInputClearList: () => void;
    onInputChangeList: (field: string, value: any) => void;
    onInputChange: (field: string, value: any) => void;
}

function ContentSearchImovel(props: ContextSearchProps) {
    const { nome, email, cpf_cnpj, username } = props.filtrosData || {};
    const [showModal, setShowModal] = useState(false);
    const { isVisible, updateVisibility } = useSidebarContext();

    const toggleVisibility = () => {
        const newValue = !isVisible;
        updateVisibility(newValue);
    };

    const handleChange = (field: string, value: any) => {
        props.onInputChange(field, value);
    };

    const handleChangeList = (field: string, value: any) => {
        props.onInputChangeList(field, value);
    };

    const handleClearList = () => {
        setShowModal(false);
        props.onInputClearList();
    };

    const handleFilter = () => {
        setShowModal(false);
        if (nome) {
            handleChangeList('nome', nome);
        }
        if (email) {
            handleChangeList('email', email);
        }
        if (cpf_cnpj) {
            handleChangeList('cpf_cnpj', cpf_cnpj);
        }
        if (username) {
            handleChangeList('username', username);
        }
    };

    return (
        <div className="contentSearch">
            <div className="contentSearch-header">
                <NavLink onClick={toggleVisibility}>
                    <img
                        className="menu-context"
                        src={iconMenuTresPonto}
                    />
                </NavLink>
                <Col className='text-center' style={{ fontSize: '20px', color: '#494949' }}>{props.header}</Col>
            </div>
            <div style={{ opacity: '34%' }} className="contentTraçoBodyHeader"></div>
            <div className="contentSearch-body">
                <div className='div-calendar'>
                    <Col>
                        <Form.Label>Nome</Form.Label>
                        <Form.Control
                            value={nome ? nome : ''}
                            onChange={(e) => handleChange("nome", e.target.value)}
                        />
                    </Col>
                </div>
                <div className='div-filtros-avancados' style={{ marginTop: '35px' }}>
                    <Button
                        style={{ height: '30px', borderRadius: '20px', padding: '5px 10px', marginRight: '5px', fontSize: '14px' }}
                        type="button"
                        rounded outlined severity="secondary"
                        onClick={() => { setShowModal(true) }}>
                        <img style={{ width: '25px', height: '35px' }} src={iconFiltroAvancado} />
                    </Button>
                    <Button
                        onClick={handleFilter}
                        style={{ height: '30px', borderRadius: '20px', padding: '5px 10px', marginRight: '5px', fontSize: '14px' }}
                        type="button"
                        severity="success"
                        icon="pi pi-filter"
                        rounded>
                        <p style={{ marginLeft: '5px', marginTop: '15px' }}>Pesquisar</p>
                    </Button>
                    <Button
                        onClick={handleClearList}
                        style={{ height: '30px', borderRadius: '20px', padding: '5px 10px', marginRight: '5px', fontSize: '14px' }}
                        type="button"
                        severity="danger"
                        icon="pi pi-delete-left"
                        rounded>
                        <p style={{ marginLeft: '5px', marginTop: '15px' }}>Limpar</p>
                    </Button>
                </div>
            </div>
            <Modal
                show={showModal}
                size='lg'
                onHide={() => setShowModal(false)}
                className='modal-filtro-avancado'
            >
                <Modal.Header closeButton>
                    <Modal.Title>Filtros Avançados</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Row>
                            <Col md='4'>
                                <Form.Label>CPF/CNPJ</Form.Label>
                                <Form.Control
                                    value={cpf_cnpj ? cpf_cnpj : ''}
                                    onChange={(e) => handleChange("cpf_cnpj", e.target.value)}
                                />
                            </Col>
                            <Col md='4'>
                                <Form.Label>Username</Form.Label>
                                <Form.Control
                                    value={username ? username : ''}
                                    onChange={(e) => handleChange("username", e.target.value)}
                                />
                            </Col>
                            <Col md='4'>
                                <Form.Label>Email</Form.Label>
                                <Form.Control
                                    value={email ? email : ''}
                                    onChange={(e) => handleChange("email", e.target.value)}
                                />
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        onClick={handleFilter}
                        style={{ height: '30px', borderRadius: '20px', padding: '5px 10px', marginRight: '5px', fontSize: '14px' }}
                        type="button"
                        severity="success"
                        icon="pi pi-filter"
                        rounded>
                        <p style={{ marginLeft: '5px', marginTop: '15px' }}>Pesquisar</p>
                    </Button>
                    <Button
                        onClick={handleClearList}
                        style={{ height: '30px', borderRadius: '20px', padding: '5px 10px', marginRight: '5px', fontSize: '14px' }}
                        type="button"
                        severity="danger"
                        icon="pi pi-delete-left"
                        rounded>
                        <p style={{ marginLeft: '5px', marginTop: '15px' }}>Limpar</p>
                    </Button>
                </Modal.Footer>
            </Modal>
        </div >
    );
};

export default ContentSearchImovel;