import { Deserializable } from "../../../../core/interfaces/deserializable.model";
import { BaseModel } from "../../Pessoas/Models/BaseModel";

export interface IProduto {
  id: Number | null;
  nome_servico: string | null;
  empreendimento_id: Number | null;
  filial_id: Number | null;
  descricao_resumida: string | null;
  descricao_detalhada: string | null;
  identificador: string | null;
  unidade: string | null;
  unidade_label: string | null;
  n_serie: string | null;
  acessorios: string | null;
  patrimonio: string | null;
  tipo: Number | null;
  tipo_label: string | null;
  control_saldo: Number | null;
  valor: Number | null;
  status: Number | null;
  status_label: string | null;
  tipo_comissao: Number | null;
  tipo_comissao_label: string | null;
  percentual_comissao: Number | null;
  stk_atual: Number | null;
  pontos: Number | null;
  condicoes_proposta: string | null;
  localizacao: string | null;
  vl_aquisic: Number | null;
}
export class ProdutoModel
  extends BaseModel
  implements IProduto, Deserializable
{
  id: Number | null = null;
  nome_servico: string | null = null;
  empreendimento_id: Number | null = null;
  filial_id: Number | null = null;
  descricao_resumida: string | null = null;
  descricao_detalhada: string | null = null;
  identificador: string | null = null;
  unidade: string | null = null;
  unidade_label: string | null = null;
  n_serie: string | null = null;
  acessorios: string | null = null;
  patrimonio: string | null = null;
  tipo: Number | null = null;
  tipo_label: string | null = null;
  control_saldo: Number | null = null;
  valor: Number | null = null;
  status: Number | null = null;
  status_label: string | null = null;
  tipo_comissao: Number | null = null;
  tipo_comissao_label: string | null = null;
  percentual_comissao: Number | null = null;
  stk_atual: Number | null = null;
  pontos: Number | null = null;
  condicoes_proposta: string | null = null;
  localizacao: string | null = null;
  vl_aquisic: Number | null = null;

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}
