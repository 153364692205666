import { Col, Form, InputGroup, Row } from "react-bootstrap";
interface PropsData {
    Data: {
        descricao?: string;
        qtd_gavetas?: number;
        modelo?: number;
        sigla?: string;
        material?: string;
        observacao?: string;
    };
    initialRightValues: any;
    onInputChange: (field: string, value: any) => void;
}
function FormTipoJazigo(props: PropsData) {
    const { descricao, qtd_gavetas, modelo, sigla, material, observacao } = props.Data || {};

    const handleChange = (field: string, value: any) => {
        if (field === "qtd_gavetas") {
            if (!isNaN(value) && value >= 0) {
                props.onInputChange(field, value);
            }
        } else if (field === "modelo") {
            if (value === "1" || value === "2") {
                props.onInputChange(field, value);
            }
        } else {
            props.onInputChange(field, value);
        }
    };

    return (
        <>
            <Form className="forms">
                <Row className="mb-3">
                    <Col style={{ display: 'flex', flexDirection: 'column' }}>
                        <Form.Label className="mb-0">Número de Gavetas</Form.Label>
                        <Form.Control
                            value={qtd_gavetas}
                            isInvalid={!qtd_gavetas}
                            onChange={(e) => handleChange("qtd_gavetas", e.target.value)}
                        />
                    </Col>
                    <Col style={{ display: 'flex', flexDirection: 'column' }}>
                        <Form.Label className="mb-0">Sigla</Form.Label>
                        <Form.Control
                            value={sigla}
                            isInvalid={!sigla}
                            onChange={(e) => handleChange("sigla", e.target.value)}
                        />
                    </Col>
                    <Col style={{ display: 'flex', flexDirection: 'column' }}>
                        <Form.Label className="mb-0">Modelo</Form.Label>
                        <Form.Select
                            value={modelo}
                            isInvalid={!modelo}
                            onChange={(e) => handleChange("modelo", e.target.value)}
                        >
                            <option>Selecione...</option>
                            <option value="1">Sobreposto</option>
                            <option value="2">Lado a Lado</option>
                        </Form.Select>
                    </Col>
                    <Col style={{ display: 'flex', flexDirection: 'column' }}>
                        <Form.Label className="mb-0">Material</Form.Label>
                        <Form.Control
                            value={material}
                            isInvalid={!material}
                            onChange={(e) => handleChange("material", e.target.value)}
                        />
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col style={{ display: 'flex', flexDirection: 'column' }}>
                        <Form.Label className="mb-0">Descrição</Form.Label>
                        <Form.Control
                            as="textarea"
                            value={descricao}
                            isInvalid={!descricao}
                            onChange={(e) => handleChange("descricao", e.target.value)}
                        />
                    </Col>
                    <Col style={{ display: 'flex', flexDirection: 'column' }}>
                        <Form.Label className="mb-0">Observação</Form.Label>
                        <Form.Control
                            as="textarea"
                            value={observacao}
                            onChange={(e) => handleChange("observacao", e.target.value)}
                        />
                    </Col>
                </Row>
            </Form>
        </>
    );
}

export default FormTipoJazigo;
