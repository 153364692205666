import React, { useState, useEffect } from 'react';
import api from '../../../../services/api';
import "primereact/resources/themes/lara-light-cyan/theme.css";
import { Column } from 'primereact/column';
import { MDBCol, MDBPageItem, MDBPageNav, MDBPagination, MDBRow } from 'mdbreact';
import { Button } from 'primereact/button';
import 'primereact/resources/themes/lara-light-cyan/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { Link } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Skeleton } from 'primereact/skeleton';
import { useAppContext } from '../../../../contexts/AppContext';
import { url } from '../../../../environments/environments-develop';
import { AlertService } from '../../../../components/AlertService';
import { Form } from 'react-bootstrap';
import { FaSort, FaSortDown, FaSortUp } from 'react-icons/fa';

const TableComponents: React.FC<any> = ({ include, ordernacao, urlRedirectCreated, urlSearch, urlSearchDataPut, url_redirect_edit }) => {
    const [currentPage, setCurrentPage] = useState(0);
    const [nodes, setNodes] = useState<any | null>(null);
    const [titles, setTitles] = useState<any | null>(null);
    const [totalPages, setTotalPages] = useState(0);
    const { setUrl_button_create } = useAppContext();
    const [isLoading, setLoading] = useState(false);
    const [sortOptions, setSortOptions] = useState<{ field: string, order: number } | null>(null);
    const [itemsPerPage, setItemsPerPage] = useState<number>(8);

    const handleSort = (field: string) => {
        let order = 1;
        if (sortOptions && sortOptions.field === field) {
            order = sortOptions.order === 1 ? -1 : sortOptions.order === -1 ? 0 : 1;
        }
        setSortOptions({ field, order });
        ordernacao(`${field};${order === 1 ? 'asc' : 'desc'}`);
    };    

    const getSortIcon = (field: string) => {
        if (sortOptions?.field === field) {
            return sortOptions.order === 1 ? <FaSortUp /> : sortOptions.order === -1 ? <FaSortDown /> : <FaSort style={{ color: '#b8b8b8' }} />;
        }
        return <FaSort style={{ color: '#b8b8b8' }} />;
    };

    const sortParam = sortOptions ? `${sortOptions.field};${sortOptions.order === 1 ? 'asc' : 'desc'}` : null ;

    useEffect(() => {
        if (sortParam) {
            ordernacao(sortParam);
        }
    }, [sortOptions]);

    useEffect(() => {
        setUrl_button_create(urlRedirectCreated);
    }, [])
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const response = await api.get(`${url}${urlSearch}&include=${include}&page=${currentPage + 1}&totalitems=${itemsPerPage}`);
                if (response.status === 200) {
                    const titles = response.data.headers.titles;
                    setTitles(titles)
                    const data = response.data.data;
                    const totalPages = response.data.meta.pagination.total_pages;
                    setTotalPages(totalPages);
                    setNodes(data);
                    setLoading(false);
                }
            } catch (error: any) {
                let mensagens: string[] = [];
                mensagens.push(error?.response?.data?.error?.message);
                AlertService.error('Erro!', String(mensagens));
            }
        };

        fetchData();
    }, [currentPage, urlSearch, itemsPerPage, sortParam]);

    const handleItemsPerPageChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setItemsPerPage(Number(event.target.value)); // Atualiza o número de itens por página
    };

    

    const renderPageNumbers = () => {
        const pageNumbers = [];

        for (let i = 0; i < totalPages; i++) {
            // Adicione as três primeiras páginas
            if (i < 3 || i === totalPages - 1 || (i >= currentPage - 1 && i <= currentPage + 1)) {
                pageNumbers.push(
                    <MDBPageItem key={i} active={i === currentPage}>
                        <MDBPageNav className="page-link" onClick={() => setCurrentPage(i)}>
                            <span key={i} onClick={() => setCurrentPage(i)} style={{ cursor: 'pointer', margin: '0 5px', fontWeight: i === currentPage ? 'bold' : 'normal' }}>
                                {i + 1}
                            </span>
                        </MDBPageNav>
                    </MDBPageItem>

                );
            }
        }

        return pageNumbers;
    };

    const actionTemplate = (value: any) => {

        return (
            <div className="flex flex-wrap gap-2" style={{ float: 'right' }}>
                <Link
                    to={url_redirect_edit}
                    onClick={(e) => setSessionEditData(value.id)}
                    className="link-perfil"
                >
                    <Button style={{ width: '30px', height: '30px', borderRadius: '20px', padding: '5px 10px', marginRight: '5px', fontSize: '14px' }} type="button" icon="pi pi-pencil" severity="warning" rounded>
                    </Button>
                </Link>
            </div>
        );
    };
    const setSessionEditData = async (value: any) => {
        try {
            const response = await api.get(`${url}${urlSearchDataPut}${value}?include=${include}`);
            if (response.status === 200) {
                await localStorage.setItem('dadosEdicao', JSON.stringify(response.data));
            }
        } catch (error: any) {
            let mensagens: string[] = [];
            mensagens.push(error?.response?.data?.error?.message);
            AlertService.error('Erro!', String(mensagens));
        }
    };

    return (
        <div className="card">
            <DataTable
                lazy
                stripedRows
                size="small"
                value={nodes ? nodes : []}
                tableStyle={{ minWidth: '50rem' }}
                emptyMessage={isLoading ? (<Skeleton />) : (<span style={{ display: 'flex', justifyContent: 'center', fontStyle: 'italic' }}>Nenhum item encontrado.</span>)}
            >
                <Column
                    style={{ textAlign: 'right', width: '7%' }}
                    key={'id'}
                    field={'id'}
                    headerClassName="righted-header"
                    header={<div className="righted-content" onClick={() =>handleSort('id')}>Id{getSortIcon('id')}</div>}
                    body={isLoading && (<Skeleton />)}
                />
                <Column
                    style={{ textAlign: 'left' }}
                    key={'nome'}
                    field={'nome'}
                    headerClassName="lefted-header"
                    header={<div className="lefted-content" onClick={() =>handleSort('vendas.tipo_imoveis.nome')}>Nome{getSortIcon('vendas.tipo_imoveis.nome')}</div>}
                    body={isLoading && (<Skeleton />)}
                />
                <Column
                    style={{ textAlign: 'left' }}
                    key={'status_name'}
                    field={'status_name'}
                    headerClassName="lefted-header"
                    header={<div className="lefted-content" onClick={() =>handleSort('vendas.tipo_imoveis.status')}>Status{getSortIcon('vendas.tipo_imoveis.status')}</div>}
                    body={isLoading && (<Skeleton />)}
                />
                <Column style={{ width: '10%' }} body={(e) => actionTemplate(e)} headerClassName="w-10rem" />
            </DataTable>
            <div className="pagination-footer">
                <MDBRow>
                    <MDBCol>
                        <MDBPagination circle>
                            <MDBPageItem disabled={currentPage === 0}>
                                <MDBPageNav className="page-link" aria-label="Previous" onClick={() => setCurrentPage(currentPage - 1)}>
                                    <span aria-hidden="true">&laquo;</span>
                                </MDBPageNav>
                            </MDBPageItem>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                {renderPageNumbers()}
                            </div>
                            <MDBPageItem disabled={currentPage === totalPages - 1}>
                                <MDBPageNav className="page-link" aria-label="Next" onClick={() => setCurrentPage(currentPage + 1)}>
                                    <span aria-hidden="true">&raquo;</span>
                                </MDBPageNav>
                            </MDBPageItem>
                        </MDBPagination>
                    </MDBCol>
                    <MDBCol>
                        <Form.Select
                            value={itemsPerPage}
                            onChange={handleItemsPerPageChange}
                        >
                            <option value={8}>8</option>
                            <option value={16}>16</option>
                            <option value={32}>32</option>
                            <option value={64}>64</option>
                        </Form.Select>
                    </MDBCol>
                </MDBRow>
            </div>
        </div>
    );
};

export default TableComponents;
