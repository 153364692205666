import { useState, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { AlertService } from '../../../../components/AlertService';
import { url } from '../../../../environments/environments-develop';
import api from '../../../../services/api';

function DetalhesContrato(props:any) {
    const [id, setId] = useState(props.data.id);
    const [dados, setDados] = useState({
        valor_aberto: 0,
        contador_aberto: 0,
        valor_pago: 0,
        contador_pago: 0,
        valor_vencido: 0,
        contador_vencido: 0
    });

    async function InitDadosDash() {
        if (!id) {
            return;
        }
        try {
            const response = await api.get(`${url}/api/v1/admin/vendas/get-dashboard/${id}`);
            setDados(response.data);
        } catch (error) {
            AlertService.error('Erro', 'Ocorreu um erro ao buscar os detalhes do contrato: ' + String(error));
        }
    }

    useEffect(() => {
        setId(props.data.id);
        InitDadosDash();
    }, [props.data, id]);

    function formatCurrency(value:any) {
        return Number(value).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    }

    function formatNumber(value:any) {
        return Number(value).toLocaleString('pt-BR');
    }

    return (
        <>
            <Row>
                {[
                    {
                        color: '#e9830e',
                        title: formatCurrency(dados?.valor_aberto),
                        subtitle: 'A pagar',
                        text: `Total a Pagar: ${formatNumber(dados?.contador_aberto)}`,
                        icon: "pi pi-clock",
                        count: formatNumber(dados?.contador_aberto),
                        bg: '#FFEAD9',
                        border: '#e9830e'
                    },
                    {
                        color: '#189428',
                        title: formatCurrency(dados?.valor_pago),
                        subtitle: 'Pagas',
                        text: `Total Pagas: ${formatNumber(dados?.contador_pago)}`,
                        icon: "pi pi-check-circle",
                        count: formatNumber(dados?.contador_pago),
                        bg: '#E1F5E4',
                        border: '#189428'
                    },
                    {
                        color: '#D32F2F',
                        title: formatCurrency(dados?.valor_vencido),
                        subtitle: 'Vencidos',
                        text: `Total Vencidos: ${formatNumber(dados?.contador_vencido)}`,
                        icon: "pi pi-exclamation-triangle",
                        count: formatNumber(dados?.contador_vencido),
                        bg: '#FFE5E0',
                        border: '#D32F2F'
                    },
                    {
                        color: '#6366f1', 
                        title: formatCurrency(0),
                        subtitle: 'Devolvidas',
                        text: 'Total Devolvidas: 0',
                        icon: "pi pi-undo",
                        count: '0',
                        bg: '#E0E7FF', 
                        border: '#6366f1'
                    }
                ].map((card, index) => (
                    <Col md={3} key={index} className="p-0">
                        <div className="d-flex" style={{
                            backgroundColor: card.bg,
                            border: `1px solid ${card.border}`,
                            borderRadius: '5px',
                            height: '125px',
                            margin: '5px',
                        }}>
                            <div className="flex-fill d-flex align-items-center justify-content-center" style={{
                                color: card.color,
                                fontSize: '36px',
                                fontWeight: 'bold',
                                width: '33.333%'
                            }}>
                                {card.count}
                            </div>
                            <div className="flex-fill d-flex flex-column justify-content-center" style={{
                                color: card.color,
                                width: '66.666%'
                            }}>
                                <div className="text-center" style={{fontWeight:'bold', fontSize:'24px'}}>
                                    {card.title}
                                </div>
                                <div className="text-center d-flex align-items-center justify-content-center">
                                    {card.subtitle} <i className={card.icon} style={{ marginLeft: '5px', color: card.color }}></i>
                                </div>
                                <div className="text-center">
                                    {card.text}
                                </div>
                            </div>
                        </div>
                    </Col>
                ))}
            </Row>
        </>
    );
}

export default DetalhesContrato;
