import { Col, Form, Row } from "react-bootstrap";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import CalendarIcon from '../../../../../assets/images/icon-calendar.png';
import pt from "date-fns/locale/pt";
import { useEffect, useState } from "react";
import { InputNumber } from "primereact/inputnumber";
import TokenService from "../../../../services/Auth/token.service";
import { showToast } from "../../../../components/ToastComponent";
interface PropsData {
    secaoIntermediacaoData: {
        imo_intermed?: number;
        descontoIntermed?: number;
        qtd_parc_intermed?: number;
        primeira_parcela_intermed?: number;
        primeiro_vencimento_intermed?: Date;
    };
    onInputChange: (field: string, value: any) => void;
}
function SecaoIntermediacao(props: PropsData) {
    const { imo_intermed, descontoIntermed, qtd_parc_intermed, primeira_parcela_intermed } = props.secaoIntermediacaoData || {};
    const [primeiro_vencimento_intermed, setPrimeiroVencimentoIntermed] = useState<Date | null>(null);
    const handleChange = (field: string, value: any) => {
        if (field === 'qtd_parc_intermed') {
            if (/^\d*$/.test(value) && value.length <= 5) {
                if (value <= TokenService.getEmpreendimentoId()?.config_venda?.qtd_parc_intermed) {
                    props.onInputChange(field, Number(value));
                } else {
                    showToast('error', 'Quantidade de parcelas da intermediação não pode ser maior que a configurada no empreendimento!');
                }
            }
        } else {
            props.onInputChange(field, value);
        }
    };
    useEffect(() => {
        setPrimeiroVencimentoIntermed(props.secaoIntermediacaoData.primeiro_vencimento_intermed ? new Date(props.secaoIntermediacaoData.primeiro_vencimento_intermed) : null);
    }, [props.secaoIntermediacaoData])
    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            flex: '1',
            height: '-webkit-fill-available',
            marginRight: '25px'
        }}>
            <Row className="mb-3">
                <Col>
                    <Form.Label>Valor Intermediação </Form.Label>
                    <InputNumber
                        disabled
                        style={{ width: '100%', height: '38px' }}
                        value={imo_intermed ? imo_intermed : null}
                        onChange={(e: any) => handleChange('imo_intermed', e.value)}
                        mode="currency" currency="BRL" locale="pt-BR"
                    />
                </Col>
                <Col>
                    <Form.Label> Desconto Intermediação</Form.Label>
                    <InputNumber
                        style={{ width: '100%', height: '38px' }}
                        value={descontoIntermed ? Number(descontoIntermed) : null}
                        onChange={(e: any) => handleChange('descontoIntermed', e.value)}
                        mode="currency" currency="BRL" locale="pt-BR"
                    />
                </Col>
            </Row>
            <Row className="mb-3">
                <Col>
                    <Form.Label>Qtd Parcelas Intermediação </Form.Label>
                    <Form.Control value={qtd_parc_intermed ? qtd_parc_intermed : undefined} onChange={(e) => handleChange('qtd_parc_intermed', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Valor 1º Parcela Intermediação</Form.Label>
                    <InputNumber
                        style={{ width: '100%', height: '38px' }}
                        value={primeira_parcela_intermed ? Number(primeira_parcela_intermed) : null}
                        onChange={(e: any) => handleChange('primeira_parcela_intermed', e.value)}
                        mode="currency" currency="BRL" locale="pt-BR"
                    />
                </Col>
                <Col md="3" controlId="formGridState" style={{ display: 'grid' }}>
                    <Form.Label>1º Vencimento Intermediação</Form.Label>
                    <DatePicker
                        selected={primeiro_vencimento_intermed}
                        onChange={(date: Date) => {
                            setPrimeiroVencimentoIntermed(date);
                            handleChange('primeiro_vencimento_intermed', date);
                        }}
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        fixedHeight
                        locale={pt}
                        customInput={
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <input
                                    type="text"
                                    placeholder="1º Vencimento Intermediação"
                                    className="dateFormFinanceiro"
                                    value={primeiro_vencimento_intermed ? primeiro_vencimento_intermed.toLocaleDateString() : ''}
                                    readOnly
                                />
                                <img
                                    className="calendar-icon"
                                    src={CalendarIcon}
                                />
                            </div>
                        }
                    />
                </Col>
            </Row>
        </div>
    );
}

export default SecaoIntermediacao;
