import { useAppContext } from '../../../contexts/AppContext';
import { useEffect, useState } from 'react';
import ModalBaixaManual from '../../../components/ModalBaixaManual';
import Paginacao from './Components/Paginacao';
import { DataTable, DataTableStateEvent } from 'primereact/datatable';
import { Column } from 'primereact/column';
import Legenda from './Components/Legenda';
import { togglerStatus } from './Components/TogglerStatus';
import { actionTemplate } from './Components/actionTemplate';
import { useAppContext as useResponseContext } from '../../../contexts/ResponseContext';
import { loadData } from './Service/ContasPagarService';
import { Button } from 'primereact/button';
import { Col, Form, NavLink, Row } from 'react-bootstrap';
import iconMenuTresPonto from '../../../../assets/images/icon-menu-tres-pontos.png';
import { useSidebarContext } from '../../../contexts/SidebarContext';
import ModalAltDataProgramada from '../../../components/ModalAltDataProgramada';
import iconFiltroAvancado from '../../../../assets/images/icon-filtro-avancado.png';
import ModalFiltroAvancado from './Components/ModalFiltroAvancado';
import { Skeleton } from 'primereact/skeleton';
import ModalAnexo from './Components/ModalAnexo';
import { FaSortUp, FaSortDown, FaSort } from 'react-icons/fa';

function ContasPagar() {
  const { showSubSidebar } = useAppContext();
  const [listFiltros, setListFiltros] = useState<any>({});
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState<number>(8); // Padrão 8 itens por página
  const [nodes, setNodes] = useState<any | null>(null);
  const { titles, updateTitles, pagination, updatePagination } = useResponseContext();
  const { setUrl_button_create, setItemsBreadsCrumbs } = useAppContext();
  const [showModal, setShowModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>(null);
  const [showModalAltDataProgramada, setShowModalAltDataProgramada] = useState(false);
  const url_redirect_edit = "/financeiro/contas-pagar/update";
  const urlRedirectCreated = "/financeiro/contas-pagar/store";
  const urlSearchDataPut = "/api/v1/admin/financeiro/contasPagar/";
  const { isVisible, updateVisibility } = useSidebarContext();
  const [showModalFiltro, setShowModalFiltro] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [showModalAnexo, setShowModalAnexo] = useState(false);
  const [formBasico, setFormBasico] = useState<any | null>(null);
  const [sortOptions, setSortOptions] = useState<{ field: string, order: number } | null>(null);

  useEffect(() => {
    setUrl_button_create(urlRedirectCreated);
    loadData(listFiltros, itemsPerPage, currentPage, setLoading, setNodes, updateTitles, updatePagination, setShowModalFiltro, sortOptions);
  }, [currentPage, sortOptions, itemsPerPage]);
  useEffect(() => {
    setItemsBreadsCrumbs([
      {
        label: 'Financeiro',
        url: '/financeiro',
      },
      {
        label: 'Lista de Contas à Pagar',
        url: '/financeiro/contas-pagar',
      }
    ]);
  }, []);
  const toggleVisibility = () => {
    const newValue = !isVisible;
    updateVisibility(newValue);
  };

  const handleSort = (field: string) => {
    let order = 1;
    if (sortOptions && sortOptions.field === field) {
      order = sortOptions.order === 1 ? -1 : sortOptions.order === -1 ? 0 : 1;
    }
    setSortOptions({ field, order });
  };

  const getSortIcon = (field: string) => {
    if (sortOptions?.field === field) {
      return sortOptions.order === 1 ? <FaSortUp /> : sortOptions.order === -1 ? <FaSortDown /> : <FaSort style={{ color: '#b8b8b8' }} />;
    }
    return <FaSort style={{ color: '#b8b8b8' }} />;
  };

  return (
    <div className="body-content">
      <div className={`${showSubSidebar ? "container-section-header" : "container-section-header-none"}`}>
        <div className="contentSearch">
          <div className="contentSearch-header">
            <NavLink onClick={toggleVisibility}>
              <img className="menu-context" src={iconMenuTresPonto} />
            </NavLink>
            <Col className='text-center' style={{ fontSize: '16px', color: '#494949' }}>Contas à Pagar</Col>
          </div>
          <div style={{ opacity: '34%' }} className="contentTraçoBodyHeader"></div>
          <div style={{
            display: "flex",
            justifyContent: 'space-between'
          }}>
            <Row style={{ margin: '10px' }}>
              <Col xs={12} md={3} lg={3}>
                <Form.Label>Numero documento</Form.Label>
                <Form.Control
                  value={listFiltros?.numero_documento || ''}
                  onChange={(e) => setListFiltros({ ...listFiltros, numero_documento: e.target.value })}
                />
              </Col>
              <Col xs={12} md={3} lg={3}>
                <Form.Label>Número do Contrato</Form.Label>
                <Form.Control
                  value={listFiltros?.numero_contrato || ''}
                  onChange={(e) => setListFiltros({ ...listFiltros, numero_contrato: e.target.value })}
                />
              </Col>
              <Col xs={12} md={3} lg={3}>
                <Form.Label>Contrato|Id</Form.Label>
                <Form.Control
                  value={listFiltros?.venda_id || ''}
                  onChange={(e) => setListFiltros({ ...listFiltros, venda_id: e.target.value })}
                />
              </Col>
              <Col xs={12} md={3} lg={3}>
                <Form.Label>Pesquisar nome</Form.Label>
                <Form.Control
                  value={listFiltros?.nome || ''}
                  onChange={(e) => setListFiltros({ ...listFiltros, nome: e.target.value })}
                />
              </Col>
            </Row>

            <Row style={{ marginTop: '40px', display: 'flex', justifyContent: 'right' }}>
              <Col xs={12} style={{ display: 'flex', justifyContent: 'right' }} className="mb-2">
                <Button
                  style={{ marginRight: '5px', height: '30px', borderRadius: '8px', fontSize: '14px' }}
                  type="button"
                  rounded outlined severity="secondary"
                  onClick={() => setShowModalFiltro(true)}
                >
                  <img style={{ width: '25px', height: '35px' }} src={iconFiltroAvancado} />
                </Button>
                <Button
                  style={{ marginRight: '5px', height: '30px', borderRadius: '8px', fontSize: '14px' }}
                  type="button"
                  severity="success"
                  icon="pi pi-filter"
                  rounded
                  onClick={() => loadData(listFiltros, itemsPerPage, currentPage, setLoading, setNodes, updateTitles, updatePagination, setShowModalFiltro)}
                >
                  <p className="d-inline-block" style={{ marginLeft: '5px', marginTop: '13px' }}>Pesquisar</p>
                </Button>
                <Button
                  style={{ height: '30px', borderRadius: '8px', fontSize: '14px' }}
                  type="button"
                  severity="danger"
                  icon="pi pi-delete-left"
                  rounded
                  onClick={() => {
                    setListFiltros({});
                    loadData({}, currentPage, itemsPerPage, setLoading, setNodes, updateTitles, updatePagination, setShowModalFiltro);
                  }}
                >
                  <p className="d-inline-block" style={{ marginLeft: '5px', marginTop: '13px' }}>Limpar</p>
                </Button>
              </Col>
            </Row>
          </div>
        </div >
      </div>
      <div className={`${showSubSidebar ? "container-section-body" : "container-section-body-none"}`}>
        <Legenda />
        <DataTable
          stripedRows
          lazy
          size="small"
          value={nodes ? nodes : []}
          tableStyle={{ minWidth: '50rem' }}
          emptyMessage={isLoading ? (<Skeleton />) : (<span style={{ display: 'flex', justifyContent: 'center', fontStyle: 'italic' }}>Nenhum item encontrado.</span>)}
        >
          <Column
            style={{ width: '5px' }}
            body={(e) => togglerStatus(e)}
            headerClassName=""
          />
          <Column
            style={{ textAlign: 'left' }}
            key={'fornecedor_nome'}
            field={'fornecedor_nome'}
            headerClassName="lefted-header"
            header={<div className="lefted-content" onClick={() => handleSort('core.pessoas.nome')}>Nome Fantasia {getSortIcon('core.pessoas.nome')}</div>}
            body={isLoading && (<Skeleton />)}
          />
          <Column
            style={{ textAlign: 'left' }}
            key={'numero_documento'}
            field={'numero_documento'}
            headerClassName="lefted-header"
            header={<div className="lefted-content" onClick={() => handleSort('financeiro.lancamento_financeiros.numero_documento')}>N°.Documento {getSortIcon('financeiro.lancamento_financeiros.numero_documento')}</div>}
          />
          <Column
            style={{ textAlign: 'right' }}
            key={'valor_original_format'}
            field={'valor_original_format'}
            headerClassName="righted-header"
            header={<div className="righted-content" onClick={() => handleSort('financeiro.lancamento_financeiros.valor_original')}>Vlr.Original {getSortIcon('financeiro.lancamento_financeiros.valor_original')}</div>}
          />
          <Column
            style={{ textAlign: 'right' }}
            key={'data_vencimento_format'}
            field={'data_vencimento_format'}
            headerClassName="righted-header"
            header={<div className="righted-content" onClick={() => handleSort('financeiro.lancamento_financeiros.data_vencimento')}>Dt.Vencimento {getSortIcon('financeiro.lancamento_financeiros.data_vencimento')}</div>}
          />
          <Column
            style={{ textAlign: 'right' }}
            key={'data_prog_pgto_format'}
            field={'data_prog_pgto_format'}
            headerClassName="righted-header"
            header={<div className="righted-content" onClick={() => handleSort('financeiro.lancamento_financeiros.data_prog_pgto')}>Dt.Programada {getSortIcon('financeiro.lancamento_financeiros.data_prog_pgto')}</div>}
            body={isLoading && (<Skeleton />)}
          />
          <Column
            style={{ textAlign: 'right' }}
            key={'data_pagamento_format'}
            field={'data_pagamento_format'}
            headerClassName="righted-header"
            header={<div className="righted-content" onClick={() => handleSort('financeiro.lancamento_financeiros.data_pagamento')}>Dt.Pago {getSortIcon('financeiro.lancamento_financeiros.data_pagamento')}</div>}
          />
          <Column
            style={{ textAlign: 'right' }}
            key={'valor_pago'}
            field={'valor_pago_format'}
            headerClassName="righted-header"
            header={<div className="righted-content" onClick={() => handleSort('financeiro.lancamento_financeiros.valor_pago')}>Vlr.Pago {getSortIcon('financeiro.lancamento_financeiros.valor_pago')}</div>}
          />
          <Column
            style={{ textAlign: 'left' }}
            key={'conta_financeira_name'}
            field={'conta_financeira_name'}
            headerClassName="lefted-header"
            header={<div className="lefted-content">Conta Financeiro</div>}
            body={isLoading && (<Skeleton />)}
          />
          <Column
            style={{ textAlign: 'left' }}
            key={'justificativa_label'}
            field={'justificativa_label'}
            headerClassName="lefted-header"
            header={<div className="lefted-content">Justificativa/Descrição</div>}
            body={isLoading && (<Skeleton />)}
          />
          <Column
            className='menu-context-grid-contas'
            body={(e) => actionTemplate(
              e,
              url_redirect_edit,
              setSelectedRow,
              setShowModal,
              setShowModalAltDataProgramada,
              setShowModalAnexo
            )}
            header="Menu"
            headerClassName=""
          />
        </DataTable>

        <ModalAnexo
          showModalAnexo={showModalAnexo}
          selectedNodeKeys={selectedRow}
          handleShowModalAnexo={setShowModalAnexo}
        />

        <ModalFiltroAvancado
          showModal={showModalFiltro}
          onHide={() => setShowModalFiltro(false)}
          handleClose={() => setShowModalFiltro(false)}
          listFiltros={listFiltros}
          setListFiltros={setListFiltros}
          loadData={loadData}
          setLoading={setLoading}
          currentPage={currentPage}
          setNodes={setNodes}
          updateTitles={updateTitles}
          updatePagination={updatePagination}
          setShowModalFiltro={setShowModalFiltro}
          itemsPerPage={itemsPerPage}
        />
        <ModalBaixaManual
          showModal={showModal}
          handleClose={() => setShowModal(false)}
          lancamentSelected={selectedRow}
          typeLancamento={0}
          urlSearchDataPut={urlSearchDataPut}
        />

        <ModalAltDataProgramada
          showModal={showModalAltDataProgramada}
          handleClose={() => setShowModalAltDataProgramada(false)}
          lancamentSelected={selectedRow}
          urlSearchDataPut={urlSearchDataPut}
        />
        <Paginacao
          totalPages={pagination}
          currentPage={currentPage}
          itemsPerPage={itemsPerPage}
          setItemsPorPagina={setItemsPerPage}
          setCurrentPage={setCurrentPage}
        />
      </div>
    </div>
  );
}

export default ContasPagar;
