import React, { useEffect, useState } from 'react';
import { Modal, Col, Form, FormControl, NavLink } from 'react-bootstrap';
import { Button } from 'primereact/button';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-autocomplete-input/dist/bundle.css';
import { useSidebarContext } from '../../../contexts/SidebarContext';
import { useAppContext } from '../../../contexts/AppContext';
import { url } from '../../../environments/environments-develop';
import api from '../../../services/api';
import { AlertService } from '../../../components/AlertService';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Calendar } from 'primereact/calendar';
import { InputMask } from 'primereact/inputmask';
import { addLocale } from 'primereact/api';
import { Button as PrimeButton } from 'primereact/button';
import { InputNumber } from 'primereact/inputnumber';
import TokenService from '../../../services/Auth/token.service';
import iconMenuTresPonto from './../../../../assets/images/icon-menu-tres-pontos.png';

function TaxaManutencao() {
  const [data_emissao, setDataEmissao] = useState<Date | null>(null);
  const [taxamanutencao, setTaxaManutencao] = useState<any>({
    empreendimento_id: TokenService.getEmpreendimentoId().id,
    data_emissao: data_emissao,
  });
  const [dadosTaxa, setDadosTaxa] = useState<any>();
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  const { isVisible, updateVisibility } = useSidebarContext();
  const { showSubSidebar, setItemsBreadsCrumbs } = useAppContext();
  const [contratos, setContratos] = useState<any[]>([]);
  const [editingRowId, setEditingRowId] = useState<number | null>(null);
  const [editedRowData, setEditedRowData] = useState<any>({});
  const [selectedContratos, setSelectedContratos] = useState<any[]>([]);
  const [vencimentoError, setVencimentoError] = useState<string | null>(null);

  const toggleVisibility = () => {
    const newValue = !isVisible;
    updateVisibility(newValue);
  };
  useEffect(() => {
    setItemsBreadsCrumbs([
      {
        label: 'Cemitério',
        url: '/cemiterio',
      },
      {
        label: 'Lista de Taxas de Manutenções',
        url: '/carteira/taxamanutencao',
      },
    ]);
  }, []);
  const handleChange = (field: string, value: any) => {
    setTaxaManutencao((prevGrupoData: any) => {
      return {
        ...prevGrupoData,
        [field]: value,
      };
    });
  };

  addLocale('pt', {
    firstDayOfWeek: 0,
    dayNames: ['domingo', 'segunda-feira', 'terça-feira', 'quarta-feira', 'quinta-feira', 'sexta-feira', 'sábado'],
    dayNamesShort: ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sáb'],
    dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
    monthNames: ['janeiro', 'fevereiro', 'março', 'abril', 'maio', 'junho', 'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro'],
    monthNamesShort: ['jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez'],
    today: 'Hoje',
    clear: 'Limpar'
  });

  const simularTaxaManutencao = async () => {
    try {
      const response = await api.post(
        `${url}/api/v1/admin/cemiterio/simular-taxa-manutencao`,
        taxamanutencao
      );
      if (response.status === 200) {
        setDadosTaxa(response?.data);
      }
    } catch (error: any) {
      let mensagens: string[] = [];
      if (error?.response?.data?.error) {
        const errorData = error?.response?.data?.error;
        if (typeof errorData.message === 'string') {
          mensagens.push(errorData.message);
        }
        else if (typeof errorData.message === 'object') {
          for (const key in errorData.message) {
            if (errorData.message.hasOwnProperty(key)) {
              const messageArray = errorData.message[key];
              if (Array.isArray(messageArray)) {
                mensagens = mensagens.concat(messageArray);
              }
            }
          }
        }
      }
      if (mensagens.length === 0) {
        mensagens.push("Nenhum registro encontrado para a data de vencimento informada.");
      }
      AlertService.error("Erro!", mensagens.join("\n"));
    }
  };

  const confirmarTaxaManutencao = async () => {
    try {
      const contratosParaEnviar = contratos.filter(
        (contrato) => !selectedContratos.includes(contrato)
      );
      const response = await api.post(
        `${url}/api/v1/admin/cemiterio/emitir-taxa-manutencao`,
        {
          ...taxamanutencao,
          contratos: contratosParaEnviar,
        }
      );
      if (response.status === 200) {
        AlertService.sucess('Sucesso!', 'Taxa de manutenção emitida com sucesso.');
        setShowConfirmModal(false);
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      }
    } catch (error: any) {
      let mensagens: string[] = [];
      if (error?.response?.data?.error) {
        const errorData = error?.response?.data?.error;
        if (typeof errorData.message === 'string') {
          mensagens.push(errorData.message);
        }
        else if (typeof errorData.message === 'object') {
          for (const key in errorData.message) {
            if (errorData.message.hasOwnProperty(key)) {
              const messageArray = errorData.message[key];
              if (Array.isArray(messageArray)) {
                mensagens = mensagens.concat(messageArray);
              }
            }
          }
        }
      }
      if (mensagens.length === 0) {
        mensagens.push("Nenhum registro encontrado para a data de vencimento informada.");
      }
      AlertService.error("Erro!", mensagens.join("\n"));
    }
  };

  const handleEditClick = (rowData: any) => {
    setEditingRowId(rowData?.uniqueId);
    setEditedRowData({ ...rowData });
  };

  const handleSaveClick = () => {
    const updatedContratos = contratos.map((contrato) =>
      contrato.uniqueId === editingRowId ? editedRowData : contrato
    );
    setContratos(updatedContratos);
    setEditingRowId(null);
    setEditedRowData({});
  };

  const handleCancelClick = () => {
    setEditingRowId(null);
    setEditedRowData({});
  };

  const actionBodyTemplate = (rowData: any) => {
    if (rowData?.uniqueId === editingRowId) {
      return (
        <>
          <PrimeButton
            icon="pi pi-check"
            className="p-button-rounded p-button-success p-button-text"
            onClick={handleSaveClick}
            tooltip="Salvar"
            tooltipOptions={{ position: 'top' }}
          />
          <PrimeButton
            icon="pi pi-times"
            className="p-button-rounded p-button-danger p-button-text"
            onClick={handleCancelClick}
            tooltip="Cancelar"
            tooltipOptions={{ position: 'top' }}
          />
        </>
      );
    } else {
      return (
        <>
          <PrimeButton
            icon="pi pi-pencil"
            className="p-button-rounded p-button-text"
            onClick={() => handleEditClick(rowData)}
            tooltip="Editar"
            tooltipOptions={{ position: 'top' }}
          />
        </>
      );
    }
  };

  useEffect(() => {
    if (dadosTaxa?.contratos) {
      const contratosComId = dadosTaxa.contratos.map((contrato: any, index: number) => ({
        ...contrato,
        uniqueId: index + 1,
      }));
      setContratos(contratosComId);
    }
  }, [dadosTaxa]);

  const contratosNaoSelecionados = contratos.filter(
    (contrato) => !selectedContratos.includes(contrato)
  );

  const totalParcelas = contratosNaoSelecionados.reduce(
    (acc: number, contrato: any) => acc + contrato.quantidade,
    0
  );

  const valorTotal = contratosNaoSelecionados.reduce(
    (acc: number, contrato: any) =>
      acc + parseFloat(contrato.valor) * contrato.quantidade,
    0
  );

  const formatarData = (dataString: string) => {
    const data = new Date(dataString);
    const dia = String(data.getDate()).padStart(2, '0');
    const mes = String(data.getMonth() + 1).padStart(2, '0');
    const ano = data.getFullYear();
    return `${dia}/${mes}/${ano}`;
  };

  return (
    <>
      <div className="body-content">
        <div className={`${showSubSidebar ? "container-section-header" : "container-section-header-none"}`}>
          <div className="contentSearch">
            <div className="contentSearch-header">
              <NavLink onClick={toggleVisibility}>
                <img className="menu-context" src={iconMenuTresPonto} alt="Menu" />
              </NavLink>
              <Col className="text-center" style={{ fontSize: '20px', color: 'black' }}>
                Taxa de Manutenção
              </Col>
            </div>
            <div style={{ opacity: '34%' }} className="contentTraçoBodyHeader" />
            <div className="contentSearch-body">
              <div className='div-calendar'>
                <Col md="2" style={{ marginRight: "8px" }}>
                  <Form.Label>Data Emissão</Form.Label>
                  <Calendar
                    value={data_emissao || null}
                    onChange={(event: any) => {
                      const selectedDates = event.value;
                      setDataEmissao(selectedDates);
                      handleChange(
                        'data_emissao',
                        selectedDates ? selectedDates.toISOString() : null
                      );
                    }}
                    showButtonBar
                    selectionMode="single"
                    dateFormat="dd/mm/yy"
                    locale="pt"
                    readOnlyInput
                    style={{ textAlign: 'center' }}
                    panelClassName="custom-calendar-panel"
                    required
                  />
                </Col>
                <Col md="2" style={{ marginRight: "8px" }}>
                  <Form.Label>Juros</Form.Label>
                  <FormControl
                    id="rate"
                    value={taxamanutencao.juros}
                    min={0.0}
                    step={0.001}
                    max={1.0}
                    onChange={(e) => handleChange('juros', e.target.value)}
                  />
                </Col>
                <Col md="3" style={{ marginRight: "8px" }}>
                  <Form.Label>Mês e Ano de Vencimento</Form.Label>
                  <InputMask
                    mask="99/9999"
                    id="vencimento"
                    value={taxamanutencao.vencimento || ''}
                    onChange={(e) => {
                      const value = e.target.value;
                      const [mes] = (value ?? '').split('/');
                      if (mes && parseInt(mes, 10) > 12) {
                        setVencimentoError('Por favor, insira um mês válido (1-12).');
                      } else {
                        setVencimentoError(null);
                      }
                      handleChange('vencimento', value);
                    }}
                    placeholder="mm/aaaa"
                    className={`form-control ${vencimentoError ? 'is-invalid' : ''}`}
                  />
                  {vencimentoError && (
                    <div className="invalid-feedback" style={{ display: 'block' }}>
                      {vencimentoError}
                    </div>
                  )}
                </Col>
              </div>
              <div className='div-filtros-avancados' style={{ marginTop: '35px' }} >
                <Button
                  onClick={simularTaxaManutencao}
                  style={{
                    height: '30px',
                    marginRight: '14px',
                    borderRadius: '20px',
                    marginBottom: '30px',
                  }}
                  type="button"
                  severity="success"
                  icon="pi pi-refresh"
                  rounded
                >
                  Simular Taxa
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div className={`${showSubSidebar ? "container-section-body" : "container-section-body-none"}`}>
          <div className="card">
            <DataTable
              value={contratos}
              selection={selectedContratos}
              onSelectionChange={(e) => setSelectedContratos(e.value)}
              dataKey="uniqueId"
              stripedRows
              footer={
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    padding: '10px',
                  }}
                >
                  <span>
                    <strong>Quantidade de Contratos:</strong> {contratosNaoSelecionados.length || 0}
                  </span>
                  <span>
                    <strong>Qtd Parcelas:</strong> {totalParcelas}
                  </span>
                  <span>
                    <strong>Valor Total Criado:</strong>{' '}
                    {valorTotal.toLocaleString('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                    })}
                  </span>
                </div>
              }
              emptyMessage={
                <span
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    fontStyle: 'italic',
                  }}
                >
                  Nenhum item encontrado.
                </span>
              }
            >
              <Column
                selectionMode="multiple"
                style={{
                  width: '3rem',
                  borderBottom: '1px solid #bdbdbd96'
                }}
              />
              <Column
                key={'codigo'}
                field={'codigo'}
                header={'Id'}
                style={{ borderBottom: '1px solid #bdbdbd96' }}
              />
              <Column
                key={'contrato'}
                field={'contrato'}
                header={'Contrato'}
                style={{ borderBottom: '1px solid #bdbdbd96' }}
              />
              <Column
                key={'nome'}
                field={'nome'}
                header={'Nome'}
                style={{ borderBottom: '1px solid #bdbdbd96' }}
              />
              <Column
                key={'quantidade'}
                field={'quantidade'}
                header={'Quantidade'}
                style={{
                  borderBottom: '1px solid #bdbdbd96',
                }}
                body={(rowData) => {
                  if (rowData?.uniqueId === editingRowId) {
                    return (
                      <InputNumber
                        value={editedRowData?.quantidade}
                        onValueChange={(e) =>
                          setEditedRowData({
                            ...editedRowData,
                            quantidade: e.value,
                          })
                        }
                        min={1}
                      />
                    );
                  } else {
                    return rowData?.quantidade;
                  }
                }}
              />
              <Column
                key={'valor'}
                field={'valor'}
                header={'Valor'}
                style={{ borderBottom: '1px solid #bdbdbd96' }}
                body={(rowData) => {
                  if (rowData?.uniqueId === editingRowId) {
                    return (
                      <InputNumber
                        value={editedRowData?.valor}
                        onValueChange={(e) =>
                          setEditedRowData({
                            ...editedRowData,
                            valor: e.value,
                            valor_label: parseFloat(e.value?.toString() || '0').toLocaleString('pt-BR', {
                              style: 'currency',
                              currency: 'BRL',
                            }),
                          })
                        }
                        mode="currency"
                        currency="BRL"
                        locale="pt-BR"
                      />
                    );
                  } else {
                    return parseFloat(rowData?.valor).toLocaleString('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                    });
                  }
                }}
              />
              <Column
                key={'vencimento'}
                field={'vencimento'}
                header={'Vencimento'}
                style={{ borderBottom: '1px solid #bdbdbd96' }}
                body={(rowData) => {
                  return formatarData(rowData.vencimento);
                }}
              />
              <Column
                header="Ações"
                body={actionBodyTemplate}
                style={{
                  width: '100px',
                  padding: '9px',
                  borderBottom: '1px solid #bdbdbd96',

                }}
              />
            </DataTable>
            <div className="div-buttons-reajuste">
              <Button
                onClick={() => setShowConfirmModal(true)}
                style={{
                  height: '30px',
                  marginBottom: '13px',
                  marginRight: '24px',
                  borderRadius: '20px',
                  padding: '5px 10px',
                  fontSize: '14px',
                }}
                type="button"
                severity="success"
                icon="pi pi-check"
                rounded
              >
                Confirmar
              </Button>
            </div>
          </div>
        </div>
      </div>
      <Modal show={showConfirmModal} size='xl' onHide={() => setShowConfirmModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Confirmar Emissão da Taxa de Manutenção</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <table className="table table-striped table-bordered">
            <thead>
              <tr>
                <th>Atributo</th>
                <th>Valor</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Quantidade de Contratos</td>
                <td>{contratosNaoSelecionados.length}</td>
              </tr>
              <tr>
                <td>Quantidade de Parcelas</td>
                <td>{totalParcelas}</td>
              </tr>
              <tr>
                <td>Valor Total</td>
                <td>
                  {valorTotal.toLocaleString('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  })}
                </td>
              </tr>
            </tbody>
          </table>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => setShowConfirmModal(false)}
            severity="danger"
            style={{ height: '30px', borderRadius: '20px', padding: '5px 10px' }}
          >
            Cancelar
          </Button>
          <Button
            onClick={confirmarTaxaManutencao}
            style={{ height: '30px', borderRadius: '20px', padding: '5px 10px' }}
            type="button"
            severity="success"
            icon="pi pi-check"
            rounded
          >
            Confirmar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default TaxaManutencao;
