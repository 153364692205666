import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import TokenService from '../../services/Auth/token.service';
import { useAppContext } from '../../contexts/AppContext';
import { url } from '../../environments/environments-develop';
import PessoaForm from '../Formularios/pessoa';
import UsuarioForm from '../Configuracao/Usuario/Formularios/usuario';
import EnderecoForm from '../Formularios/endereco';
import TelefoneForm from '../Formularios/telefone';
import { showToast } from '../../components/ToastComponent';
import { addTelefone, changeTelefone, removeTelefone } from '../../utils/TelefoneUtils'
import { addEndereco, changeEndereco, removeEndereco } from '../../utils/EnderecoUtils';
import { changeUsuario } from '../../utils/UsuarioUtils';
import ProgressBarForm from '../Formularios/progressBarForm';
import { useSidebarContext } from '../../contexts/SidebarContext';

function Perfil() {
    const { setTypeRequest, setUrl, setBody, retorno, setItemsBreadsCrumbs } = useAppContext();
    const [currentPart, setCurrentPart] = useState(1);
    const totalParts = 4;
    const [perfilData, setPerfilData] = useState<any>({});
    const { updateVisibility } = useSidebarContext();

    useEffect(() => {
        setItemsBreadsCrumbs([
            {
                label: 'Perfil',
                url: '/usuario/perfil',
            },
        ]);
        const storedData = TokenService.getPerfil().data;
        setPerfilData((prevGrupoData: any) => {
            const dadosAtualizados = {
                ...prevGrupoData,
                ...storedData,
                pessoa: storedData.pessoa?.data
            };
            return dadosAtualizados;
        });
        updateVisibility(false);
        setUrl(`${url}/api/v1/admin/core/user/${TokenService.getPerfil().data.id}?include=pessoa.telefones,pessoa.enderecos`);
        setBody(perfilData);
        setTypeRequest("PUT");

        return () => {
            updateVisibility(true);
        };
    }, []);
    useEffect(() => {
        setBody(perfilData);
    }, [perfilData]);
    const handleNext = () => {
        if (!perfilData) {
            showToast("error", "Os campos de Nome, Email e CPF ou CNPJ é obrigatório!")
            return;
        }
        if (!perfilData?.pessoa?.cpf_cnpj) {
            showToast("error", "O campo CPF ou CNPJ é obrigatório!")
            return;
        }
        if (!perfilData.pessoa.nome && perfilData?.pessoa?.cpf_cnpj.length < 15) {
            showToast("error", "O campo Nome é obrigatório!")
            return;
        }
        if (currentPart < totalParts) {
            setCurrentPart((prevPart) => prevPart + 1);
        }
    };

    const handleBack = () => {
        if (currentPart > 1) {
            setCurrentPart((prevPart) => prevPart - 1);
        }
    };

    //usuario formulario utils + pessoa formulario utils
    const handleChange = (field: string, value: string) => {
        changeUsuario(field, value, perfilData, setPerfilData)
    };

    // Endereco Formulario Utils
    const handleEnderecoInputChange = (index: number, field: string, value: string) => {
        changeEndereco(perfilData, setPerfilData, index, field, value);
    };
    const handleAddEndereco = () => {
        addEndereco(perfilData, setPerfilData);
    };
    const handleRemoveEndereco = (index: number) => {
        removeEndereco(perfilData, setPerfilData, index);
    };

    // Telefone Formulario Utils
    const handleTelefoneInputChange = (index: number, field: string, value: string) => {
        changeTelefone(perfilData, setPerfilData, index, field, value);
    };
    const handleAddTelefone = () => {
        addTelefone(perfilData, setPerfilData);
    };
    const handleRemoveTelefone = (index: number) => {
        removeTelefone(perfilData, setPerfilData, index);
    };
    const saveNewPerfil = () => {
        TokenService.setPerfil(retorno);
    }
    return (
        <div className="body-content">
            <div className="form-user-perfil">
                <Container>
                    <ProgressBarForm
                        currentPart={currentPart}
                        totalParts={totalParts}
                        handleBack={handleBack}
                        handleNext={handleNext}
                        steps={["Pessoa", "Usuário", "Endereço", "Telefone"]}
                        body={perfilData}
                    >
                        {currentPart === 1 && (
                            <PessoaForm
                                pessoaData={perfilData?.pessoa}
                                tipoPessoa={5}
                                tipoAcao={"editar"}
                                onInputChange={(field: string, value: string) => handleChange(`pessoa.${field}`, value)}
                            />
                        )}
                        {currentPart === 2 && (
                            <UsuarioForm
                                formData={perfilData}
                                onInputChange={(field: string, value: string) => handleChange(`${field}`, value)}
                            />
                        )}
                        {currentPart === 3 && (
                            <EnderecoForm
                                enderecosData={perfilData?.enderecos?.data || []}
                                onInputChange={(index: number, field: string, value: string) => handleEnderecoInputChange(index, field, value)}
                                onAddEndereco={() => handleAddEndereco()}  // Certifique-se de passar esta função
                                onRemoveEndereco={(index: number) => handleRemoveEndereco(index)}  // Certifique-se de passar esta função
                            />
                        )}
                        {currentPart === 4 && (
                            <TelefoneForm
                                telefonesData={perfilData?.telefones?.data || []}
                                onInputChange={(index: number, field: string, value: string) => {
                                    if (perfilData?.pessoa.telefones && perfilData.pessoa.telefones.length > 0) {
                                        handleTelefoneInputChange(index, field, value);
                                    } else {
                                        handleAddTelefone();
                                    }
                                }}
                                onAddTelefone={() => handleAddTelefone()}
                                onRemoveTelefone={(index: number) => handleRemoveTelefone(index)}
                            />
                        )}
                    </ProgressBarForm>
                </Container>
            </div>
        </div>
    );
}

export default Perfil;
