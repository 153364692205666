import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import { useAppContext } from '../../../contexts/AppContext';
import TokenService from '../../../services/Auth/token.service';
import CessaoDeDireitoForm from './Formularios/CessaoDeDireitoForm';
import { url } from '../../../environments/environments-develop';
import api from "../../../services/api";

function CreateCessaoDeDireito() {
    const { setUrl, setBody, setShowButtonSave, setItemsBreadsCrumbs } = useAppContext();
    const [formBasico, setFormBasico] = useState<any>({});
    const [formCompradores, setFormCompradores] = useState<any>(null);
    const listaClientesContratos = async (numero: any) => {
        try {
            const response = await api.get(`${url}/api/v1/admin/vendas/contrato-lista-clientes/${numero}/${TokenService.getEmpreendimentoId()?.id}`);
            let compradores: any[] = [];
            response.data.data.forEach((item: any) => {
                compradores.push({
                    cliente_id: item.cliente_id,
                    cliente_name: item.cliente_name,
                    principal: item.principal
                });
            });
            setFormCompradores(compradores);
        } catch (error) {
            console.error("warn", "Pessoa ainda não cadastrada no sistema!")
        }
    }

    useEffect(() => {
        setItemsBreadsCrumbs([
            {
                label: 'Carteira',
                url: '/carteira',
            },
            {
                label: 'Cessão de Direito',
                url: '/carteira/contrato-transferencia',
            },
            {
                label: 'Criar'
            }
        ]);
        const storedData = localStorage.getItem('dadosEdicao');
        if (storedData) {
            setFormBasico((prevGrupoData: any) => {
                const dadosAtualizados = {
                    ...prevGrupoData,
                    ['numero']: JSON.parse(storedData).numero,
                    ['unidade']: JSON.parse(storedData).imovel ? JSON.parse(storedData).imovel.unidade : JSON.parse(storedData).unidade_imovel,
                    ['empreendimento_id']: TokenService.getEmpreendimentoId()?.id,
                    ['valor_servico']: TokenService.getEmpreendimentoId()?.transf_valor
                };
                return dadosAtualizados;
            });
            listaClientesContratos(JSON.parse(storedData).numero);
        }
        setUrl(`${url}/api/v1/admin/carteira/contrato-transf`);
        setShowButtonSave(true);
    }, []);

    const handleChange = (field: string, value: any) => {
        setFormBasico((data: any) => {
            return {
                ...data,
                [field]: value
            };
        });
    }
    const handleAddCompradores = () => {
        if (formBasico && setFormBasico) {
            const novoCompradores: any = {
                cliente_id: "",
                principal: false,

            };
            const compradores = (formBasico?.contrato_cliente ? formBasico?.contrato_cliente : formBasico) || [];
            if (formBasico?.contrato_cliente) {
                setFormBasico((prevData: any) => {
                    return {
                        ...prevData,
                        contrato_cliente: [...compradores, novoCompradores],
                    };
                });
            } else {
                setFormBasico((prevData: any) => {
                    return {
                        ...prevData,
                        contrato_cliente: [novoCompradores],
                    };
                });
            }
        }
    };

    const handleRemoveCompradores = (index: number) => {

        if (formBasico && setFormBasico) {
            const updatedCompradores = [...formBasico?.contrato_cliente];
            updatedCompradores.splice(index, 1);
            setFormBasico((data: any) => {
                return {
                    ...data,
                    contrato_cliente: updatedCompradores,
                }
            });
        }
    };

    const handleCompradoresInputChange = (index: number, field: string, value: any) => {
        const clientes = formBasico?.contrato_cliente;
        if (formBasico && clientes && index >= 0 && index < clientes.length) {
            setFormBasico((prevformBasico: any) => {
                const updatedClientes = [...prevformBasico?.contrato_cliente];

                updatedClientes[index] = {
                    ...updatedClientes[index],
                    [field]: value,
                };
                return {
                    ...prevformBasico,
                    contrato_cliente: updatedClientes,
                };
            });
        }
    };
    useEffect(() => {
        setBody(formBasico)
    }, [formBasico])
    return (
        <>
            <div className="body-content">
                <div className="form-create-group">
                    <div className="form-create-group-header">
                        <h3>Cessão de Direitos</h3>
                    </div>
                </div>
                <div className="form-group-create">
                    <Container>
                        <CessaoDeDireitoForm
                            key={formBasico}
                            formBasico={formBasico}
                            formCompradores={formCompradores}
                            onInputChange={(field: string, value: any) => handleChange(field, value)}
                            onInputCompradores={(index: number, field: string, value: any) => handleCompradoresInputChange(index, field, value)}
                            onAddCompradores={handleAddCompradores}
                            onRemoveCompradores={(index: number) => handleRemoveCompradores(index)}
                        />
                    </Container>
                </div>
            </div>
        </>
    );
}

export default CreateCessaoDeDireito;
