import { useEffect, useRef } from "react";
import { handleOpenModal } from '../Service/VendaService';
import { Tooltip } from 'primereact/tooltip';
import { Toast } from 'primereact/toast';
import { SpeedDial } from "primereact/speeddial";
import 'primeicons/primeicons.css';

export const actionTemplateVendaAvulsa = (
    value: any,
    setSelectedRow: (value: any) => void,
    setShowModalConfirmarVenda: (value: any) => void,
    setShowModalCloneVenda: (value: any) => void,
    setShowModalImpressaoVendaAvulsa: (value: any) => void
) => {
    const data = value.data ? value.data : value;
    const toast = useRef<Toast>(null);

    const items: any[] = [
        {
            label: 'Converter Orçamento',
            icon: 'pi pi-check',
            style: {
                backgroundColor: '#0bca04',
                color: '#fff',
                top: '0px',
                width: '35px',
                height: '35px',
                display: `${data?.inumado_id ? 'none' : 'flex'}`
            },
            command: () => {
                handleOpenModal(data, setSelectedRow, setShowModalConfirmarVenda);
            },
            tooltip: 'Converter Orçamento',
        },
        {
            label: 'Clonar Orçamento',
            icon: 'pi pi-clone',
            style: {
                backgroundColor: '#e8550f',
                color: '#fff',
                top: '0px',
                width: '35px',
                height: '35px',
                display: `${data?.inumado_id ? 'none' : 'flex'}`
            },
            command: () => {
                handleOpenModal(data, setSelectedRow, setShowModalCloneVenda);
            },
            tooltip: 'Clonar Venda',
        },
        {
            label: 'Imprimir',
            icon: 'pi pi-print',
            style: {
                backgroundColor: '#007bff',
                color: '#fff',
                top: '0px',
                width: '35px',
                height: '35px',
                display: 'flex'
            },
            command: () => {
                handleOpenModal(data, setSelectedRow, setShowModalImpressaoVendaAvulsa);
            },
            tooltip: 'Imprimir',
        }

    ];

    useEffect(() => {
        items.forEach((item, index) => {
            const element = document.querySelectorAll('.p-speeddial-action')[index];
            if (element) {
                element.setAttribute('data-pr-tooltip', item.tooltip);
                element.setAttribute('data-pr-position', 'left');
            }
        });
    }, [items]);

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Toast ref={toast} />
            <Tooltip target="[data-pr-tooltip]" />
            <div style={{ position: 'relative' }}>
                <SpeedDial
                    model={items}
                    radius={45}
                    showIcon="pi pi-bars"
                    hideIcon="pi pi-times"
                    type="linear"
                    direction="left"
                    style={{ top: 'calc(40%)', right: 18 }}
                    buttonClassName="button-speed-dial"
                />
            </div>
        </div>
    );
};