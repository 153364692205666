/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { Button, Form, Row, Col, Modal, Spinner } from 'react-bootstrap';
import { useDropzone } from 'react-dropzone';
import { AlertService } from '../../../../components/AlertService';
import TokenService from '../../../../services/Auth/token.service';
import Comprador from '../Formularios/Comprador';
import ClassificacaoImovel from '../Formularios/ClassificacaoImovel';
import Corretor from '../Formularios/Corretor';
import Venda from '../Formularios/Venda';
import ContaRec from '../Formularios/ContaRec';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import ImovelImport from '../Formularios/Imovel';
import { ProgressBar } from 'primereact/progressbar';
import Cancelamento from '../Formularios/Cancelamento';
import Jazigo from '../Formularios/Cemiterio/Jazigo';
import ImportacaoGavetas from '../Formularios/Cemiterio/ImportacaoGavetas';
interface FileImportFormProps {
  onFileUpload: (data: any) => void;
}
const FileImportForm: React.FC<FileImportFormProps> = ({ onFileUpload }) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [contentRow, setContentRow] = useState<number | null>(null);
  const [sheetsToProcess, setSheetsToProcess] = useState<string>("");
  const [modoImport, setModoImport] = useState<any>(null);
  const [localImport, setLocalImport] = useState<any>(null);
  const [jsonData, setJsonData] = useState(false);
  const [formData, setFormData] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [qtdRegistros, setRegistros] = useState<string>("");
  const [finalizaEnvio, setFinalizaEnvio] = useState<boolean>(false);
  let contadorRegistros = 0;
  let contadorRequisicao = 0;

  useEffect(() => {
    const savedFormData = localStorage.getItem('formData');
    if (savedFormData) {
      setFormData(JSON.parse(savedFormData));
    }
  }, []);

  const removeFile = () => {
    setSelectedFile(null);
    setContentRow(null);
    setSheetsToProcess("");
  };

  const handleOnDrop = async (
    file: File,
    startRow: number,
    sheetsToProcess: string,
  ) => {
    setLoading(true);
    try {
      const arrayBuffer = await file.arrayBuffer();
      const uint8Array = new Uint8Array(arrayBuffer);

      // Pré-carregamento do módulo ExcelJS
      const ExcelJS = require('exceljs');

      const workbook = new ExcelJS.Workbook();
      await workbook.xlsx.load(uint8Array.buffer);

      var sheets = sheetsToProcess.split(',').map(sheetName => sheetName.trim());
      if (sheets.length === 1 && sheets[0] === '') {
        sheets = workbook.worksheets.map((sheet: any) => sheet.name);
      }

      const newData: any[] = [];

      sheets.forEach((sheetName: any) => {
        const worksheet = workbook.getWorksheet(sheetName);
        if (worksheet) {
          worksheet.eachRow((row: any, rowNumber: any) => {
            if (rowNumber >= startRow) {
              var rowData: Record<string, any> = {};
              Object.keys(formData).forEach(key => {
                const targetColumn: any = formData[key as keyof typeof formData];
                if (targetColumn && row.getCell(targetColumn)) {
                  try {
                    const cellValue = row.getCell(targetColumn).value;
                    if (cellValue !== null && cellValue !== undefined && cellValue !== '') {
                      rowData[key] = cellValue.result || cellValue;
                    }
                  } catch (columnError) {
                    console.error(`Error processing column ${targetColumn}:`, columnError);
                  }
                }
              });
              if (Object.values(rowData).length > 0) {
                newData.push(rowData);
                contadorRegistros++; // Incrementa o contador de registros processados
              }
            }

            // Verifica se o contador atingiu 10 mil e chama saveAndSendFormData
            if (contadorRegistros >= 10000) {
              saveAndSendFormData(newData);
              contadorRegistros = 0; // Reinicia o contador após enviar os dados
              newData.length = 0; // Limpa o array de dados para o próximo lote
            }
          });
        }
      });

      setRegistros(String(newData.length));
      setJsonData(true);
      localStorage.setItem('formData', JSON.stringify(formData));

      // Verifica se ainda há dados restantes para enviar
      if (newData.length > 0) {
        saveAndSendFormData(newData);
        contadorRegistros = 0; // Reinicia o contador após enviar os dados restantes
      } else {
        setFinalizaEnvio(true)
      }
    } catch (error) {
      console.error('Erro ao ler o arquivo XLSX:', error);
      AlertService.error('Erro', 'Erro ao ler o arquivo XLSX: ' + String(error));
    } finally {
      setLoading(false);
    }
  };

  const saveAndSendFormData = async (dados: any) => {
    setLoading(false);
    const empreendimento = TokenService.getEmpreendimentoId() ? TokenService.getEmpreendimentoId().id : null;
    const jsonData = JSON.stringify(dados);
    const zip = new JSZip();
    zip.file('data.json', jsonData);
    const zipContent: Blob = await zip.generateAsync({ type: 'blob' });

    // Salvar o arquivo ZIP localmente
    // saveAs(zipContent, 'arquivo.zip');
    const reader = new FileReader();
    reader.readAsDataURL(zipContent);
    reader.onload = () => {
      if (zipContent) {
        const formData = new FormData();
        formData.append('extension_file_original', 'zip');
        formData.append('empreendimento_id', empreendimento);
        formData.append('modo_import', String(Number(modoImport)));
        formData.append('localImport', localImport);
        formData.append('file_original', zipContent);
        contadorRequisicao++;
        onFileUpload(formData);
      } else {
        AlertService.error('error', 'Houve um erro ao converter os dados para base64.');
      }
    };

    reader.onerror = () => {
      AlertService.error('error', 'Houve um erro ao ler os dados.');
    };
  };


  const onDrop = (acceptedFiles: File[]) => {
    if (acceptedFiles.length > 0) {
      const file = acceptedFiles[0];
      setSelectedFile(file);
    }
  };
  const handleChange = (field: string, value: string) => {
    setFormData((prevFormData: any) => {
      return {
        ...prevFormData,
        [field]: value
      };
    });
  }
  const handleConfigSubmit = () => {
    try {
      const empreendimento = TokenService.getEmpreendimentoId() ? TokenService.getEmpreendimentoId().id : null;
      if (empreendimento) {
        if (selectedFile) {
          handleOnDrop(selectedFile, contentRow || 1, sheetsToProcess);
        } else {
          AlertService.error('Erro', 'Nenhum arquivo selecionado.');
        }
      } else {
        AlertService.error('Erro', 'Selecione o empreendimento corretamente.');
      }
    } catch (error: any) {
      console.error('Erro', 'Erro: ' + error.message);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  return (
    <div>
      <div
        {...getRootProps()}
        style={{
          border: '2px dashed #cccccc',
          padding: '20px',
          textAlign: 'center',
        }}
      >
        {loading === true && <>
          <div style={{ margin: '24px', color: '#000', backgroundColor: '#ffdc14' }}>
            <p><strong>Atenção</strong>, O arquivo está sendo processado, não faça nenhuma ação que possa que ocasione na saida da pagina ou o recarregamento da mesma! aguarde que em alguns instantes você poderá processeguir!</p>
          </div>
          <Spinner animation="border" variant="primary" />
        </>}
        <input {...getInputProps()} />
        {!selectedFile && loading === false && (
          <p>Arraste e solte o arquivo de importação aqui, ou clique para selecionar.</p>
        )}
        {selectedFile && loading === false && (
          <>
            <p>{selectedFile.name}</p>
            <Button variant="contained" className='btn btn-danger' onClick={removeFile}>
              Remover Arquivo
            </Button>
          </>
        )}
      </div>
      <div style={{ margin: '24px' }}>
      </div>
      {selectedFile && loading === false && (
        <form>
          <Row className="group-form">
            <Col>
              <Form.Label>Qual o Modelo de importação?</Form.Label>
              <Form.Select
                value={modoImport || ''}
                onChange={(e) => setModoImport(e.target.value)}
              >
                <option>Selecione...</option>
                <option key="1" value="1">Inserir</option>
              </Form.Select>
            </Col>
            <Col>
              <Form.Label>Aonde deseja importar este documento?</Form.Label>
              <Form.Select
                value={localImport || ''}
                onChange={(e) => setLocalImport(e.target.value)}
              >
                <option>Selecione...</option>
                <option key="1" value="1">Comprador</option>
                <option key="2" value="2">Corretor</option>
                <option key="3" value="3">Classificação de Lote</option>
                <option key="4" value="4">Imovel | Lote</option>
                <option key="5" value="5">Parcelas | Contas a Receber</option>
                <option key="6" value="6">Vendas | Contratos</option>
                <option key="8" value="8">Vendas Canceladas | Distratos</option>
                <option key="9" value="9">Jazigo</option>
                <option key="10" value="10">Importação de Gavetas</option>
              </Form.Select>
            </Col>
          </Row>
          {localImport == '1' && (
            <Comprador
              formData={formData}
              onInputChange={(field: string, value: string) => handleChange(`${field}`, value)}
            />
          )}
          {localImport == '2' && (
            <Corretor
              formData={formData}
              onInputChange={(field: string, value: string) => handleChange(`${field}`, value)}
            />
          )}
          {localImport == '3' && (
            <ClassificacaoImovel
              formData={formData}
              onInputChange={(field: string, value: string) => handleChange(`${field}`, value)}
            />
          )}
          {localImport == '4' && (
            <ImovelImport
              formData={formData}
              onInputChange={(field: string, value: string) => handleChange(`${field}`, value)}
            />
          )}
          {localImport == '5' && (
            <ContaRec
              formData={formData}
              onInputChange={(field: string, value: string) => handleChange(`${field}`, value)}
            />
          )}
          {localImport == '6' && (
            <Venda
              formData={formData}
              onInputChange={(field: string, value: string) => handleChange(`${field}`, value)}
            />
          )}
          {localImport == '8' && (
            <Cancelamento
              formData={formData}
              onInputChange={(field: string, value: string) => handleChange(`${field}`, value)}
            />
          )}
          {localImport == '9' && (
            <Jazigo 
              formData={formData}
              onInputChange={(field: string, value: string) => handleChange(`${field}`, value)}
            />
          )}
          {localImport == '10' && (
            <ImportacaoGavetas
              formData={formData}
              onInputChange={(field: string, value: string) => handleChange(`${field}`, value)}
            />
          )}
          <Row className="group-form">
            <Col>
              <Form.Label>Em qual Linha Inicial do Conteúdo?</Form.Label>
              <Form.Control
                value={contentRow || ''}
                onChange={(e) => setContentRow(parseInt(e.target.value, 10))}
              />
            </Col>
            <Col>
              <Form.Label>Nome Sheets (separadas por vírgula)</Form.Label>
              <Form.Control
                value={sheetsToProcess}
                placeholder='Deixe em branco para todas as sheets'
                onChange={(e) => setSheetsToProcess(e.target.value)}
              />
            </Col>
          </Row>
          <Row style={{ display: 'flex', justifyContent: 'center' }}>
            <Col md="3">
              <Button variant="contained" className='btn btn-success' onClick={handleConfigSubmit}>
                Processar Arquivo
              </Button>
            </Col>
          </Row>
        </form>
      )}
      {jsonData && loading === false && (<div>
        <Modal.Title style={{ fontSize: "12px", display: 'flex' }}>
          <ProgressBar value={contadorRequisicao}></ProgressBar>
        </Modal.Title>
      </div>)}
    </div>
  );
};

export default FileImportForm;