import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { AlertService } from '../../../../../../components/AlertService';
import api from '../../../../../../services/api';
import { url } from '../../../../../../environments/environments-develop';
import { abrirPDF } from '../../../../../../utils/PdfUtils';

interface ModalImpressaoVendaAvulsaProps {
    showModal: boolean;
    handleClose: () => void;
    selectedRow: any;
}

const ModalImpressaoVendaAvulsa: React.FC<ModalImpressaoVendaAvulsaProps> = ({ selectedRow, showModal, handleClose }) => {
    const [isLoading, setLoading] = useState(false);

    const handleSaveButton = async (): Promise<void> => {
        try {
            const response = await api.get(`${url}/api/v1/admin/vendas/venda-avulsa/emitir-venda/` + selectedRow?.id, {
                responseType: 'arraybuffer',
            });
            if (response.status === 200) {
                abrirPDF(response.data, 1);
            }
        } catch (error: any) {
            AlertService.infomessage('Erro!', error.message);
        } finally {
            setLoading(false);
            handleClose();
        };
    };

    useEffect(() => {
        if (isLoading) {
            handleSaveButton();
        }
    }, [isLoading, selectedRow]);

    const handleClick = () => setLoading(true);

    return (
        <Modal
            show={showModal}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            className="modal-input-impressao"
        >
            <Modal.Header closeButton>
                <Modal.Title>Impressão de Venda Avulsa</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                Deseja imprimir a venda avulsa?
            </Modal.Body>
            <Modal.Footer>
                <Button
                    style={{ color: 'white', backgroundColor: '#22c55e', borderColor: '#22c55e' }}
                    disabled={isLoading}
                    onClick={handleClick}
                >
                    {isLoading ? 'Carregando…' : 'Confirmar'}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ModalImpressaoVendaAvulsa;
