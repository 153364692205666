import { useAppContext } from '../../../contexts/AppContext';
import { useEffect, useState } from 'react';
import { AlertService } from '../../../components/AlertService';
import TableComponentsAudit from '../../../components/TableComponentsAudit';
import ContentSearchAudit from './componentes/ContentSearchAudit';
import { showToast } from '../../../components/ToastComponent';
function Audit() {
  const { showSubSidebar, setItemsBreadsCrumbs } = useAppContext();
  const [listFiltros, setListFiltros] = useState<any>({});
  const [filtros, setFiltros] = useState<string>();
  const [formBasico, setFormBasico] = useState<any>({});

  useEffect(() => {
    setItemsBreadsCrumbs([
      {
        label: 'Configurações',
        url: '/configuracoes',
      },
      {
        label: 'Listagem da Auditoria',
        url: '/audit',
      },
    ]);
  }, []);

  async function montarFiltro(): Promise<void> {
    try {
      const filtros = {
        ...listFiltros,
        origem: listFiltros.origem || 2,
      };

      if (filtros.dataInicio && !filtros.dataFim) {
        showToast('error', 'Se selecionado uma data de inicio deve-se selecionar uma data de fim');
        return;
      }
      if (!filtros.dataInicio && filtros.dataFim) {
        showToast('error', 'Se selecionado uma data de fim deve-se selecionar uma data de inicio');
        return;
      }

      const consulta: any = {
        consulta: {
          order: "id;desc",
          filtro: {
            "empreendimento_id": filtros ? filtros.empreendimento_id : null,
            "filial_id": filtros ? filtros.filial_id : null,
            "event": filtros ? filtros.event : null,
            "origem": filtros ? filtros.origem : null,
            "user_id": filtros ? filtros.user_id : null,
            "data_inicio": filtros ? filtros.dataInicio : null,
            "data_fim": filtros ? filtros.dataFim : null,
            "auditable_id": filtros ? filtros.auditable_id : null,
            "auditable_type": filtros ? filtros.auditable_type : null,
            "new_values": filtros ? filtros.new_values : null,
            "authorization": filtros ? filtros.authorization : null,
          }
        }
      };

      const queryParams: any = {};

      const camposEnviar = ['consulta'];

      camposEnviar.forEach((key: any) => {
        const value = consulta[key];
        if (value !== undefined && value !== null && value !== '') {
          if (key === 'consulta') {
            // Converte o objeto consulta em uma string JSON e codifica para URI
            queryParams[key] = JSON.stringify(value);
          } else {
            queryParams[key] = value;
          }
        }
      });

      const queryString = Object.entries(queryParams)
        .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(String(value))}`)
        .join('&');
      setFiltros(queryString);
    } catch (error: any) {
      AlertService.error('Erro!', String(error.response?.data?.message) || 'Erro desconhecido');
    }
  }

  useEffect(() => {
    montarFiltro();
  }, [listFiltros]);
  
  const handleChange = (field: string, value: string) => {
    setFormBasico((prevGrupoData: any) => {
      return {
        ...prevGrupoData,
        [field]: value
      };
    });
  };
  const handleChangeList = (field: string, value: string) => {
    setListFiltros((prevGrupoData: any) => {
      return {
        ...prevGrupoData,
        [field]: value
      };
    });
  };
  const handleClearList = () => {
    setListFiltros({});
    setFormBasico({
      origem: '2',
    });
    montarFiltro();
  };

  console.log('filtros', filtros);

  return (
    <div className="body-content">
      <div
        className={`${showSubSidebar ? "container-section-header" : "container-section-header-none"}`}
      >
        <ContentSearchAudit
          onInputChange={(field: string, value: string) => handleChange(`${field}`, value)}
          onInputChangeList={(field: string, value: string) => handleChangeList(`${field}`, value)}
          onInputClearList={() => handleClearList()}
          filtrosData={formBasico}
          header="Auditoria"
        />
      </div>
      <div
        className={`${showSubSidebar ? "container-section-body" : "container-section-body-none"}`}
      >
        <TableComponentsAudit
          key={filtros}
          urlSearch={`/api/v1/admin/core/audit?${filtros}`}
          urlShow="/api/v1/admin/core/audit/"
          urlRedirectShow="/audit/show"
        />
      </div>
    </div>
  );
}

export default Audit;
