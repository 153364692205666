import iconMenuTresPonto from '../../../../../assets/images/icon-menu-tres-pontos.png';
import { Button } from 'primereact/button';
import { useSidebarContext } from '../../../../contexts/SidebarContext';
import { Col, Form, NavLink } from 'react-bootstrap';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-autocomplete-input/dist/bundle.css';

interface ContextSearchProps {
  header?: string;
  filtrosData: {
    quadra?: string;
    lote?: string;
  };
  onInputClearList: () => void;
  onInputChangeList: (field: string, value: any) => void;
  onInputChange: (field: string, value: any) => void;
}

function ContentSearchExclusao(props: ContextSearchProps) {
  const { quadra, lote } = props.filtrosData || {};
  const { isVisible, updateVisibility } = useSidebarContext();

  const toggleVisibility = () => {
    const newValue = !isVisible;
    updateVisibility(newValue);
  };

  const handleChange = (field: string, value: any) => {
    props.onInputChange(field, value);
  };

  const handleChangeList = (field: string, value: any) => {
    props.onInputChangeList(field, value);
  };

  const handleClearList = () => {
    props.onInputClearList();
  };

  const handleFilter = () => {
    if (quadra) {
      handleChangeList('quadra', quadra);
    }
    if (lote) {
      handleChangeList('lote', lote);
    }
  };

  return (
    <div className="contentSearch">
      <div className="contentSearch-header">
        <NavLink onClick={toggleVisibility}>
          <img
            className="menu-context"
            src={iconMenuTresPonto}
          />
        </NavLink>
        <Col className='text-center' style={{ fontSize: '20px', color: '#494949' }}>{props.header}</Col>
      </div>
      <div style={{ opacity: '34%' }} className="contentTraçoBodyHeader"></div>
      <div className="contentSearch-body">
        <div className='filter-campos'>
          <div className='div-calendar' style={{ height: "37px", marginRight: '12px' }}>
            <Form.Control
              value={quadra ? quadra : ''}
              placeholder='Pesquisar por quadra'
              style={{ marginRight: '12px' }}
              onChange={(e) => handleChange("quadra", e.target.value)}
            />
            <Form.Control
              value={lote ? lote : ''}
              placeholder='Pesquisar por lote'
              onChange={(e) => handleChange("lote", e.target.value)}
            />
          </div>
        </div>
        <div className='div-filtros-avancados'>
          <Button
            onClick={handleFilter}
            style={{ height: '30px', borderRadius: '20px', padding: '5px 10px', marginRight: '5px', fontSize: '14px' }}
            type="button"
            severity="success"
            icon="pi pi-filter"
            rounded>
            <p style={{ marginLeft: '5px', marginTop: '13px' }}>Pesquisar</p>
          </Button>
          <Button
            onClick={handleClearList}
            style={{ height: '30px', borderRadius: '20px', padding: '5px 10px', marginRight: '5px', fontSize: '14px' }}
            type="button"
            severity="danger"
            icon="pi pi-delete-left"
            rounded>
            <p style={{ marginLeft: '5px', marginTop: '13px' }}>Limpar</p>
          </Button>
        </div>
      </div>
    </div>
  );
}

export default ContentSearchExclusao;
