import React, { useEffect, useState } from 'react';
import { Modal, Container, Row, Col, Form } from 'react-bootstrap';
import { Button } from 'primereact/button';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import pt from 'date-fns/locale/pt';
import CalendarIcon from '../../../../../assets/images/icon-calendar.png';

interface FiltroAvancadoModalProps {
    showModal: boolean;
    onHide: () => void;
    listFiltros: any;
    setListFiltros: any | null;
    loadData: (
        filtros: any,
        currentPage: number,
        setLoading: (nodes: any) => void,
        setNodes: (nodes: any) => void,
        updateTitles: (titles: string[]) => void,
        updatePagination: (pages: number) => void,
        setShowModalFiltro: (show: boolean) => void) => void;
    currentPage: number;
    setLoading: (nodes: any) => void;
    setNodes: (nodes: any) => void;
    updateTitles: (titles: string[]) => void;
    updatePagination: (pages: number) => void;
    setShowModalFiltro: (show: boolean) => void;
    handleClose: () => void;
}

const FiltroAvancadoModal: React.FC<FiltroAvancadoModalProps> = ({
    showModal,
    onHide,
    listFiltros,
    setListFiltros,
    setLoading,
    loadData,
    currentPage,
    setNodes,
    updateTitles,
    updatePagination,
    setShowModalFiltro
}) => {
    const [data_falecimento, setDataFalecimento] = useState<Date | null>();
    const [data_sepultamento, setDataSepultamento] = useState<Date | null>();

    useEffect(() => {
        setDataFalecimento(listFiltros?.data_falecimento ? new Date(listFiltros?.data_falecimento) : null);
        setDataSepultamento(listFiltros?.data_sepultamento ? new Date(listFiltros?.data_sepultamento) : null);
    }, [listFiltros]);

    return (
        <Modal
            show={showModal}
            onHide={onHide}
            size="lg"
            className="modal-filtro-avancado"
        >
            <Modal.Header closeButton>
                <Modal.Title>Filtros Avançados</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container>
                    <Row>
                        <Col md="3">
                            <Form.Label>Data Falecimento</Form.Label>
                            <Form.Group controlId="formGridState" >
                                <DatePicker
                                    selected={data_falecimento ? new Date(data_falecimento) : null}
                                    onChange={(date: Date) => {
                                        setDataFalecimento(date);
                                        setListFiltros({ ...listFiltros, data_falecimento: date.toISOString().split('T')[0] });
                                    }}
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    fixedHeight
                                    locale={pt}
                                    customInput={
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <input
                                                type="date"
                                                placeholder="Data Inicio"
                                                className="dateFormFinanceiro"
                                                value={data_falecimento ? data_falecimento.toISOString().split('T')[0] : ''}
                                                readOnly
                                            />
                                            <img
                                                className="calendar-icon"
                                                src={CalendarIcon}
                                            />
                                        </div>
                                    }
                                />
                            </Form.Group>
                        </Col>
                        <Col md="3">
                            <Form.Label>Data Sepultamento</Form.Label>
                            <Form.Group controlId="formGridState" >
                                <DatePicker
                                    selected={data_sepultamento ? new Date(data_sepultamento) : null}
                                    onChange={(date: Date) => {
                                        setDataSepultamento(date);
                                        setListFiltros({ ...listFiltros, data_sepultamento: date.toISOString().split('T')[0] });
                                    }}
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    fixedHeight
                                    locale={pt}
                                    customInput={
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <input
                                                type="date"
                                                placeholder="Data Fim"
                                                className="dateFormFinanceiro"
                                                value={data_sepultamento ? data_sepultamento.toISOString().split('T')[0] : ''}
                                                readOnly
                                            />
                                            <img
                                                className="calendar-icon"
                                                src={CalendarIcon}
                                            />
                                        </div>
                                    }
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    style={{ height: '30px', borderRadius: '20px', padding: '5px 10px', marginRight: '5px', fontSize: '14px' }}
                    type="button"
                    severity="success"
                    icon="pi pi-filter"
                    rounded
                    onClick={() => loadData(listFiltros, currentPage, setLoading, setNodes, updateTitles, updatePagination, setShowModalFiltro)}
                >
                    <p style={{ marginLeft: '5px', marginTop: '13px' }}>Pesquisar</p>
                </Button>
                <Button
                    style={{ height: '30px', borderRadius: '20px', padding: '5px 10px', marginRight: '5px', fontSize: '14px' }}
                    type="button"
                    severity="danger"
                    icon="pi pi-delete-left"
                    rounded
                    onClick={() => setListFiltros(null)}
                >
                    <p style={{ marginLeft: '5px', marginTop: '13px' }}>Limpar</p>
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default FiltroAvancadoModal;
