import { Deserializable } from "../../../../core/interfaces/deserializable.model";
import { BaseModel } from "./BaseModel";
import { PessoaModel } from "./PessoaModel";

export interface IFornecedor {
  status: boolean | null;
  pessoa: PessoaModel | PessoaModel[] | null;
  nome_fornecedor: string | null;
  email_fornecedor: string | null;
  telefone_fornecedor: string | null;
  created_at: Date | null;
  updated_at: Date | null;
}

export class FornecedorModel
  extends BaseModel
  implements IFornecedor, Deserializable
{
  status: boolean | null = null;
  pessoa: PessoaModel | PessoaModel[] | null = null;
  nome_fornecedor: string | null = null;
  email_fornecedor: string | null = null;
  telefone_fornecedor: string | null = null;
  created_at: Date | null = null;
  updated_at: Date | null = null;

  deserialize(input: any): this {
    Object.assign(this, input);
    if (input?.pessoa) {
      this.pessoa = new PessoaModel().deserialize(input.pessoa);
    } else if (input?.pessoa?.data) {
      this.pessoa = new PessoaModel().deserialize(input.pessoa.data);
    }
    return this;
  }
}
