import React, { useEffect } from "react";
import NavDropdown from "react-bootstrap/NavDropdown";
import { useState } from "react";
import TokenService from "../services/Auth/token.service";
import { Form, FormControl } from 'react-bootstrap';
import iconBarra from '../../assets/images/icon-barra-bottom.png';
import { AlertService } from "./AlertService";
import api from "../services/api";
import { url } from "../environments/environments-develop";

function SelectEmpreendimentoComponent() {
  const [selectedEmpreendimento, setSelectedEmpreendimento] = useState<number | null>(null);
  const [listPermi, setListPermi] = useState([]);
  const [titleSelect, setTitleSelect] = useState('Selecione');
  const [filtro, setFiltro] = useState(''); // Estado para o filtro

  const handleFiltroChange = (event: any) => {
    setFiltro(event.target.value); // Atualiza o valor do filtro conforme o usuário digita
  };
  const handleEmpreendimentoSelect = (index: number) => {
    if (window.location.href.includes("update")) {
      history.back();
    }
    setSelectedEmpreendimento(index);
  };
  async function listEmpreendimento() {
    try {
      const includeParam = 'config_venda,config_carteira,pessoa.telefones';
      const response = await api.get(`${url}/api/v1/admin/vendas/empreendimentos?include=${includeParam}`);
      setListPermi(response.data.data);
    } catch (error) {
      AlertService.error('Error', 'Ocorreu um erro ao buscar a lista de permissões. Erro:' + String(error));
    }
  }
  const handleEmpreendimentoChange = (element: any) => {
    if (window.location.href.includes("update") || window.location.href.includes("/update")) {
      history.back();
    }
    if (element) {
      setSelectedEmpreendimento(element.id);
      TokenService.setEmpreendimento(element);
      window.location.reload();
    }
  };
  useEffect(() => {
    setTitleSelect(TokenService.getEmpreendimentoId() !== null ? TokenService.getEmpreendimentoId()?.nome_empreendimento : 'Nenhum Empreendimento')
    listEmpreendimento();
  }, []);

  return (
    <>
      <Form.Label className="label-select-empreendimento">Empreendimento</Form.Label>
      <img alt="iconBarra" className="iconBarra" src={String(iconBarra)} />
      <NavDropdown className="selectEmpreendimento" title={`${titleSelect.substring(0, 22)}...`} menuVariant="dark">
        <FormControl
          placeholder="Filtrar por empreendimento"
          value={filtro}
          onChange={handleFiltroChange} // Atualiza o filtro
          style={{
            width: '96%',
            marginTop: '5px',
            marginBottom: '10px',
            marginLeft: '5px',
            marginRight: '5px',
            padding: '5px',
            background: '#d9d9d9'
          }}
        />
        {listPermi.length <= 0 && (
          <NavDropdown.Item
            className="dropdownSelect"
            key={null}
            disabled
          >
            <p className='label-select-not-found'>Nenhum Empreendimento Cadastrado</p>
          </NavDropdown.Item>
        )}
        {listPermi
          ?.filter((item: any) =>
            item.nome_empreendimento.toLowerCase().includes(filtro.toLowerCase()) // Aplica o filtro
          )
          .map((item: any, index) => (
            <NavDropdown.Item
              className="dropdownSelect"
              key={index}
              onClick={() => {
                handleEmpreendimentoChange(item);
              }}
              onSelect={() => {
                handleEmpreendimentoSelect(index);
              }}
            >
              <p className='label-select-not-found'>{item.nome_empreendimento}</p>
            </NavDropdown.Item>
          ))}
      </NavDropdown>
    </>
  );
};

export default SelectEmpreendimentoComponent;
