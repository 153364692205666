import { useAppContext } from '../../../../../contexts/AppContext';
import { useEffect, useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useAppContext as useResponseContext } from '../../../../../contexts/ResponseContext';
import { listGavetas, loadDataContratoJazigo, searchCliente, searchJazigo } from './Service/VendaService';
import { Col, Form, NavLink, Row } from 'react-bootstrap';
import iconMenuTresPonto from '../../../../../../assets/images/icon-menu-tres-pontos.png';
import iconAdd from '../../../../../../assets/images/iconAdd.png';
import iconEdit from '../../../../../../assets/images/IconEdit.png';
import iconJazigo from '../../../../../../assets/images/iconJazigo.png';
import iconIndice from '../../../../../../assets/images/iconIndice.png';
import iconVenda from '../../../../../../assets/images/iconVenda.png';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import CalendarIcon from '../../../../../../assets/images/icon-calendar.png';
import pt from 'date-fns/locale/pt';
import 'react-autocomplete-input/dist/bundle.css';
import './index.css';
import { Button } from 'primereact/button';
import { useSidebarContext } from '../../../../../contexts/SidebarContext';
import { AutoComplete } from 'primereact/autocomplete';
import ModalVendaContrato from './Components/ModalVendaContrato';
import ModalJazigosEstrutura from './Components/ModalJazigosEstrutura';
import { Skeleton } from 'primereact/skeleton';
import { MDBCol, MDBPageItem, MDBPageNav, MDBPagination, MDBRow } from 'mdbreact';
import { showToast } from '../../../../../components/ToastComponent';
import ModalEditVendaContrato from './Components/ModalEditVendaContrato';
import ModalListVendaAvulsa from './Components/ModalListVendaAvulsa';
import ModalIndices from './Components/ModalIndices';
import api from '../../../../../services/api';
import { AlertService } from '../../../../../components/AlertService';
import { url } from '../../../../../environments/environments-develop';
import { actionTemplateContratoJazigo } from './Components/actionTemplateContratoJazigo';
import ModalConfirmarCancelarContratoJazigo from './Components/ModalConfirmarCancelarContratoJazigo';
import ModalCancelarContratoJazigo from './Components/ModalCancelarContratoJazigo';
import ModalEmitirContrato from '../../../../Carteira/GestaoContratos/Components/ModalEmitirContrato';
import ModalAnexo from '../../../../Carteira/GestaoContratos/Components/ModalAnexo';
import ModalCancelarContrato from '../../../../Carteira/GestaoContratos/Components/ModalCancelarContrato';
import ModalReativarContrato from '../../../../Carteira/GestaoContratos/Components/ModalReativarContrato';
import ModalConfirmarReativarContrato from '../../../../Carteira/GestaoContratos/Components/ModalConfirmarReativarContrato';
import { searchContratoS } from '../../Services/VendaService';

function Cemiterio() {
    const { showSubSidebar } = useAppContext();
    const [listFiltros, setListFiltros] = useState<any>({});
    const [currentPage, setCurrentPage] = useState(0);
    const [nodes, setNodes] = useState<any | null>(null);
    const { titles, updateTitles, pagination, updatePagination } = useResponseContext();
    const [data_inicio, setData_inicio] = useState<string | null>(null);
    const [data_fim, setData_fim] = useState<string | null>(null);
    const { isVisible, updateVisibility } = useSidebarContext();
    const [filteredClient, setFilteredClient] = useState<any>(null);
    const [filteredJazigo, setFilteredJazigo] = useState<any>(null);
    const [showModalVendaContrato, setShowModalVendaContrato] = useState(false);
    const [showModalVendaAvulsa, setShowModalVendaAvulsa] = useState(false);
    const [showModalEditVendaContrato, setShowModalEditVendaContrato] = useState(false);
    const [showModalJazigosEstrutura, setShowModalJazigosEstrutura] = useState(false);
    const [showModalIndices, setShowModalIndices] = useState(false);
    const [selectedRow, setSelectedRow] = useState<any>(null);
    const [isLoading, setLoading] = useState(false);
    const [gavetasVerificadas, setGavetasVerificadas] = useState(false);
    const [dados, setDados] = useState<any | null>(null);
    const [disabled, setDisabled] = useState(true);
    const [showCancelarContratoJazigo, setShowCancelarContratoJazigo] = useState<boolean>(false);
    const [showConfirmarModalCancelarContratoJazigo, setShowConfirmarModalCancelarContratoJazigo] = useState<boolean>(false);
    const [formEmissao, setFormEmissao] = useState<any>({});
    const [selectedNodeKeys, setSelectedNodeKeys] = useState<any>(null);
    const [showModalCancelarContrato, setShowModalCancelarContrato] = useState<boolean>(false);
    const [showConfirmarModalReativarContrato, setShowConfirmarModalReativarContrato] = useState<boolean>(false);
    const [showModalReativarContrato, setShowModalReativarContrato] = useState<boolean>(false);
    const [showModalEmitirContrato, setShowModalEmitirContrato] = useState(false);
    const [dataCancelamento, setDataCancelamento] = useState<Date | null>(null);
    const [dataPrimeiraParcelaPaga, setDataPrimeiraParcelaPaga] = useState<Date | null>(null);
    const [showModalAnexo, setShowModalAnexo] = useState(false);
    const [formCancelamento, setFormCancelamento] = useState<any>({});
    const { setContextItemsButtonMenu } = useAppContext();
    const [FilteredContrato, setFilteredContrato] = useState<any>(null);

    useEffect(() => {
        loadDataContratoJazigo(listFiltros, currentPage, setLoading, setNodes, updateTitles, updatePagination);
    }, [currentPage]);

    const handleChange = (field: string, value: any) => {
        setListFiltros((prevGrupoData: any) => {
            return {
                ...prevGrupoData,
                [field]: value
            };
        });
    };
    useEffect(() => {
        const commonStyle = {
            width: '35px',
            height: '35px',
            top: '0px',
            color: '#fff'
        };
        const items = [
            {
                label: 'Cessão de Direitos',
                icon: 'pi pi-arrow-right-arrow-left',
                style: { ...commonStyle, backgroundColor: '#007bff', display: 'flex' },
                command: () => {
                    window.location.href = '/carteira/contrato-transferencia';
                },
                tooltip: 'Cessão de Direitos',
            }
        ];
        setContextItemsButtonMenu(items);
    }, [])
    const toggleVisibility = () => {
        const newValue = !isVisible;
        updateVisibility(newValue);
    };

    const renderPageNumbers = () => {
        const pageNumbers = [];
        for (let i = 0; i < pagination; i++) {
            if (i < 3 || i === pagination - 1 || (i >= currentPage - 1 && i <= currentPage + 1)) {
                pageNumbers.push(
                    <MDBPageItem key={i} active={i === currentPage}>
                        <MDBPageNav className="page-link" onClick={() => setCurrentPage(i)}>
                            <span key={i} onClick={() => setCurrentPage(i)} style={{ cursor: 'pointer', margin: '0 5px', fontWeight: i === currentPage ? 'bold' : 'normal' }}>
                                {i + 1}
                            </span>
                        </MDBPageNav>
                    </MDBPageItem>
                );
            }
        }
        return pageNumbers;
    };

    useEffect(() => {
        if (selectedRow?.id) {
            verificaIndices();
        } else {
            setDisabled(true);
        }
    }, [selectedRow?.id]);

    const verificaIndices = async () => {
        if (!selectedRow) {
            showToast('error', 'Nenhuma venda selecionada');
            return;
        }
        try {
            let include = 'compradores,imovel,corretor';
            const response = await api.get(`${url}/api/v1/admin/cemiterio/contrato-jazigo/${selectedRow.id}?include=${include}`);
            if (response.data.vendas_itens.data.length > 0) {
                setDados(response.data);
                setDisabled(false);
            } else {
                setDisabled(true);
            }
        } catch (error: any) {
            let mensagens: string[] = [];
            mensagens.push(error?.response?.data?.error?.message);
            AlertService.error('Erro!', String(mensagens));
        }
    }

    const onHandleVerified = () => {
        setGavetasVerificadas(true);
    }
    const notHandleVerified = () => {
        setGavetasVerificadas(false);
    }
    const handleClose = () => {
        setSelectedRow(null);
        setShowModalEditVendaContrato(false)
    }
    const openModalJazigoGavetas = () => {
        if (!selectedRow) {
            showToast('error', 'Nenhuma venda selecionada');
            return;
        } else if (gavetasVerificadas) {
            setShowModalJazigosEstrutura(true);
        } else if (!gavetasVerificadas) {
            showToast('error', 'Nenhuma gaveta encontrada neste jazigo');
            return;
        }
    }
    const openModalIndices = () => {
        if (!selectedRow) {
            showToast('error', 'Nenhuma venda selecionada');
            return;
        } else if (gavetasVerificadas) {
            setShowModalIndices(true);
        } else if (!gavetasVerificadas) {
            showToast('error', 'Nenhuma gaveta encontrada neste jazigo');
            return;
        }
    }
    const handleChangeEmitirContrato = (field: string, value: any) => {
        setFormEmissao((prevGrupoData: any) => {
            return {
                ...prevGrupoData,
                [field]: value
            };
        });
    };
    return (
        <div className="body-content">
            <div className={`${showSubSidebar ? "container-section-header" : "container-section-header-none"}`}>
                <div className="contentSearch">
                    <div className="contentSearch-header">
                        <NavLink onClick={toggleVisibility}>
                            <img className="menu-context" src={iconMenuTresPonto} />
                        </NavLink>
                        <Col className='text-left' style={{ fontSize: '20px', color: '#494949' }}>Venda de Jazigos - Inicio</Col>
                    </div>
                </div>
            </div>
            <div
                className={`${showSubSidebar ? "content-cards" : "content-cards-none"}`}>
                {!selectedRow && (
                    <div className="card-demonstrativo-adicionar" onClick={() => setShowModalVendaContrato(true)} >
                        <Col md="12" style={{ display: 'flex', padding: '12px' }}>
                            <Col className='text-left label-card'>Adicionar</Col>
                            <img className='img-card' src={iconAdd}></img>
                        </Col>
                        <div className="traco-card"></div>
                        <div className='label-button'>
                            <p className='p-venda'>Adicionar</p>
                        </div>
                    </div>
                )}
                {selectedRow && (
                    <div className="card-demonstrativo-editar" onClick={() => setShowModalEditVendaContrato(true)} >
                        <Col md="12" style={{ display: 'flex', padding: '12px' }}>
                            <Col className='text-left label-card'>Editar Venda</Col>
                            <img className='img-card' src={iconEdit}></img>
                        </Col>
                        <div className="traco-card"></div>
                        <div className='label-button'>
                            <p className='p-venda'>Editar</p>
                        </div>
                    </div>
                )}
                <div className={`card-demonstrativo-jazigo ${!selectedRow ? "disabled-jazigo" : ""}`} onClick={() => selectedRow && openModalJazigoGavetas()}>
                    <Col md="12" style={{ display: 'flex', padding: '12px' }}>
                        <Col className={`text-left label-card ${!selectedRow ? "disabled-text" : ""}`}>Jazigos | Estrutura</Col>
                        <img className='img-card' src={iconJazigo}></img>
                    </Col>
                    <div className="traco-card"></div>
                    <div className='label-button'>
                        <p className={`p-venda ${!selectedRow ? "disabled-text" : ""}`}>Visualizar</p>
                    </div>
                </div>
                <div className={`card-demonstrativo-indices ${disabled ? "disabled-indice" : ""}`} onClick={() => !disabled && openModalIndices()}>
                    <Col md="12" style={{ display: 'flex', padding: '12px' }}>
                        <Col className={`text-left label-card ${disabled ? "disabled-text" : ""}`}>Indices</Col>
                        <img className='img-card' src={iconIndice}></img>
                    </Col>
                    <div className="traco-card"></div>
                    <div className='label-button'>
                        <p className={`p-venda ${disabled ? "disabled-text" : ""}`}>Visualizar</p>
                    </div>
                </div>
                <div className="card-demonstrativo-venda-avulsa" onClick={() => setShowModalVendaAvulsa(true)}>
                    <Col md="12" style={{ display: 'flex', padding: '12px' }}>
                        <Col className='text-left label-card'>Venda Avulsa</Col>
                        <img className='img-card' src={iconVenda}></img>
                    </Col>
                    <div className="traco-card"></div>
                    <div className='label-button'>
                        <p className='p-venda'>Visualizar</p>
                    </div>
                </div >
            </div >
            <div className='body-content-reajuste'>
                <div className='content-reajuste'>
                    <div
                        className={`${showSubSidebar ? "container-section-reajuste" : "container-section-filtro-none"}`}
                    >
                        <Form.Group className="filtro-form" style={{ marginLeft: '22px' }}>
                            <Col className='text-center' style={{ fontSize: '18px', marginBottom: '10px', color: '#494949' }}>Filtros</Col>
                            <Col className='col-reajuste'>
                                <Form.Label>Cliente | Beneficiário</Form.Label>
                                <Col md="7">
                                    <AutoComplete
                                        field="nome_cliente"
                                        style={{ height: "37px", width: "-webkit-fill-available" }}
                                        value={listFiltros?.cliente ? listFiltros?.cliente : ''}
                                        suggestions={filteredClient}
                                        placeholder='Nome do cliente'
                                        completeMethod={(e) => searchCliente(e, currentPage, setFilteredClient)}
                                        onChange={(e) => setListFiltros({ ...listFiltros, cliente: e.value })}
                                        onSelect={(e) => setListFiltros({ ...listFiltros, cliente_id: e.value.id, cliente: e.value })}
                                        dropdown
                                    />
                                </Col>
                            </Col>
                            <Col className='col-reajuste'>
                                <Form.Label>Contrato</Form.Label>
                                <Col md="7">
                                    <AutoComplete
                                        placeholder='Pesquisar n° contrato'
                                        field="nome_contrato"
                                        style={{ height: "37px", width: "-webkit-fill-available" }}
                                        value={listFiltros?.contrato?.nome_contrato ? listFiltros?.contrato?.nome_contrato : (listFiltros?.buscarContrato ? listFiltros?.buscarContrato : '')}
                                        suggestions={FilteredContrato}
                                        completeMethod={(e) => searchContratoS(e, 1, setFilteredContrato, () => { }, () => { })}
                                        onChange={(e) => setListFiltros({ ...listFiltros, buscarContrato: e.value })}
                                        onSelect={(e) => setListFiltros({ ...listFiltros, contrato_id: e.value.id, contrato: e.value })}
                                        dropdown
                                    />
                                </Col>
                            </Col>
                            <Col className='col-reajuste'>
                                <Form.Label>Unidade</Form.Label>
                                <Col md="7">
                                    <Form.Control
                                        placeholder='Unidade do Imóvel'
                                        value={listFiltros?.unidade ? listFiltros?.unidade : ''}
                                        onChange={(e) => setListFiltros({ ...listFiltros, unidade: e.target.value })}
                                    />
                                </Col>
                            </Col>
                            <Col className='col-reajuste'>
                                <Form.Label>Tipo Jazigo</Form.Label>
                                <Col md="7">
                                    <AutoComplete
                                        field="sigla"
                                        style={{ height: "37px", width: "-webkit-fill-available" }}
                                        value={listFiltros?.jazigo ? listFiltros?.jazigo : ''}
                                        suggestions={filteredJazigo}
                                        placeholder='Sigla do Jazigo'
                                        completeMethod={(e) => searchJazigo(e, currentPage, setFilteredJazigo)}
                                        onChange={(e) => setListFiltros({ ...listFiltros, jazigo: e.value })}
                                        onSelect={(e) => setListFiltros({ ...listFiltros, jazigo_id: e.value.id, jazigo: e.value })}
                                        dropdown
                                    />
                                </Col>
                            </Col>
                            <Col className='col-reajuste'>
                                <Form.Label>Status</Form.Label>
                                <Col md="7">
                                    <Form.Select value={listFiltros?.status ? listFiltros?.status : ''} onChange={(e) => setListFiltros({ ...listFiltros, status: e.target.value })}>
                                        <option>Selecione...</option>
                                        <option value={1}>Faltando Acerto</option>
                                        <option value={2}>Acertado Intermediação</option>
                                        <option value={3}>Vigente</option>
                                        <option value={4}>Suspenso</option>
                                        <option value={5}>Em distrato</option>
                                        <option value={6}>Cancelado</option>
                                        <option value={7}>Quitado</option>
                                    </Form.Select>
                                </Col>
                            </Col>
                            <Col className='col-reajuste'>
                                <Form.Label>Dia Vencimento</Form.Label>
                                <Col md="7">
                                    <Form.Control
                                        placeholder='Dia do vencimento'
                                        value={listFiltros?.dia_vencimento ? listFiltros?.dia_vencimento : ''}
                                        onChange={(e) => setListFiltros({ ...listFiltros, dia_vencimento: e.target.value })}
                                    />
                                </Col>
                            </Col>
                            <Col className='col-reajuste'>
                                <Form.Label>Periodo</Form.Label>
                                <Col md="7">
                                    <Form.Select
                                        value={listFiltros?.tipo_consulta ? listFiltros?.tipo_consulta : ''}
                                        onChange={(e) => setListFiltros({ ...listFiltros, tipo_consulta: e.target.value })}
                                    >
                                        <option>Selecione...</option>
                                        <option value={1}>Data venda</option>
                                        <option value={2}>Dt Prox Reaj</option>
                                    </Form.Select>
                                </Col>
                            </Col>
                            <Col >
                                <Form.Label className='text-center' style={{ display: 'flex', justifyContent: 'center', marginTop: '12px' }}>Datas de Inicio ao Fim</Form.Label>
                            </Col>
                            <Col className='col-reajuste-data'>
                                <Form.Group as={Col} controlId="formGridState" style={{ display: 'grid' }}>
                                    <DatePicker
                                        selected={data_inicio ? new Date(data_inicio) : null}
                                        onChange={(date: Date) => {
                                            const formattedDate = date.toISOString().split('T')[0];
                                            setData_inicio(formattedDate);
                                            setListFiltros({ ...listFiltros, data_inicio: formattedDate });
                                        }}
                                        peekNextMonth
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                        fixedHeight
                                        locale={pt}
                                        customInput={
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <input
                                                    type="date"
                                                    placeholder="Data Inicio"
                                                    className="dateFormFinanceiro"
                                                    value={data_inicio ? data_inicio : ''}
                                                    readOnly
                                                />
                                                <img
                                                    className="calendar-icon"
                                                    src={CalendarIcon}
                                                />
                                            </div>
                                        }
                                    />

                                </Form.Group>
                                <p className='label-date'>Até</p>
                                <Form.Group as={Col} controlId="formGridState" style={{ display: 'grid' }}>
                                    <DatePicker
                                        selected={data_fim ? new Date(data_fim) : null}
                                        onChange={(date: Date) => {
                                            const formattedDate = date.toISOString().split('T')[0];
                                            setData_fim(formattedDate);
                                            setListFiltros({ ...listFiltros, data_fim: formattedDate });
                                        }}
                                        peekNextMonth
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                        fixedHeight
                                        locale={pt}
                                        customInput={
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <input
                                                    type="date"
                                                    placeholder="Data Fim"
                                                    className="dateFormFinanceiro"
                                                    value={data_fim ? String(data_fim) : ''}
                                                    readOnly
                                                />
                                                <img
                                                    className="calendar-icon"
                                                    src={CalendarIcon}
                                                />
                                            </div>
                                        }
                                    />
                                </Form.Group>
                            </Col>
                        </Form.Group>
                        <div className='div-buttons-reajuste'>
                            <Button
                                style={{ height: '30px', marginBottom: '13px', marginRight: '6px', borderRadius: '20px', padding: '5px 10px', fontSize: '14px' }}
                                type="button"
                                severity="success"
                                icon="pi pi-search"
                                rounded
                                onClick={() => loadDataContratoJazigo(listFiltros, currentPage, setLoading, setNodes, updateTitles, updatePagination)}>
                                <p style={{ marginLeft: '5px', marginTop: '13px' }}>Pesquisar</p>
                            </Button>
                            <Button
                                style={{ height: '30px', marginBottom: '13px', marginRight: '24px', borderRadius: '20px', padding: '5px 10px', fontSize: '14px' }}
                                type="button"
                                severity="danger"
                                icon="pi pi-delete-left"
                                rounded
                                onClick={() => {
                                    setListFiltros({});
                                    setData_inicio(null);
                                    setData_fim(null);
                                    loadDataContratoJazigo({}, currentPage, setLoading, setNodes, updateTitles, updatePagination);
                                }}>
                                <p style={{ marginLeft: '5px', marginTop: '13px' }}>Limpar</p>
                            </Button>
                        </div>
                    </div>
                </div>
                <div className='content-reajuste-memoria' >
                    <div
                        style={{ paddingLeft: '22px', paddingRight: '22px' }}
                        className="container-section-table"
                    >
                        <div className='table-card'>
                            <DataTable
                                size="small"
                                selectionMode="single"
                                selection={selectedRow}
                                onSelectionChange={(e) => setSelectedRow(e.value)}
                                stripedRows
                                lazy
                                value={nodes ? nodes : []}
                                emptyMessage={<span style={{ display: 'flex', justifyContent: 'center', fontStyle: 'italic' }}>Nenhum item encontrado.</span>}
                            >
                                <Column
                                    style={{ textAlign: 'right', width: '5%' }}
                                    key={'id'}
                                    field={'id'}
                                    headerClassName="righted-header"
                                    header={<div className="righted-content">Codigo</div>}
                                    body={isLoading && (<Skeleton />)}
                                />
                                <Column
                                    style={{ textAlign: 'left', width: '10%' }}
                                    key={'numero'}
                                    field={'numero'}
                                    headerClassName="lefted-header"
                                    header={<div className="lefted-content">Nº Contrato</div>}
                                    body={isLoading && (<Skeleton />)}
                                />
                                <Column
                                    style={{ textAlign: 'left', width: '10%' }}
                                    key={'data_venda_format'}
                                    field={'data_venda_format'}
                                    headerClassName="lefted-header"
                                    header={<div className="lefted-content">Data Venda</div>}
                                    body={isLoading && (<Skeleton />)}
                                />
                                <Column
                                    style={{ textAlign: 'left', width: '10%' }}
                                    key={'status_name'}
                                    field={'status_name'}
                                    headerClassName="lefted-header"
                                    header={<div className="lefted-content">Status</div>}
                                    body={isLoading && (<Skeleton />)}
                                />
                                <Column
                                    style={{ textAlign: 'left', width: '10%' }}
                                    key={'data_ult_reajuste_format'}
                                    field={'data_ult_reajuste_format'}
                                    headerClassName="lefted-header"
                                    header={<div className="lefted-content">Ult. Reajuste</div>}
                                    body={isLoading && (<Skeleton />)}
                                />
                                <Column
                                    style={{ textAlign: 'left', width: '10%' }}
                                    key={'prox_reajuste_format'}
                                    field={'prox_reajuste_format'}
                                    headerClassName="lefted-header"
                                    header={<div className="lefted-content">Prox. Reajuste</div>}
                                    body={isLoading && (<Skeleton />)}
                                />
                                <Column
                                    style={{ textAlign: 'right', width: '10%' }}
                                    key={'qtd_parcelas'}
                                    field={'qtd_parcelas'}
                                    headerClassName="righted-header"
                                    header={<div className="righted-content">Qtd. Parcelas</div>}
                                    body={isLoading && (<Skeleton />)}
                                />
                                <Column
                                    style={{ textAlign: 'right', width: '10%' }}
                                    key={'valor_total_format'}
                                    field={'valor_total_format'}
                                    headerClassName="righted-header"
                                    header={<div className="righted-content">Valor Total</div>}
                                    body={isLoading && (<Skeleton />)}
                                />
                                <Column
                                    style={{ textAlign: 'center', width: '4%' }}
                                    className='menu-context-grid-contas'
                                    body={(e) => actionTemplateContratoJazigo(
                                        e,
                                        setSelectedRow,
                                        setShowConfirmarModalCancelarContratoJazigo,
                                        setSelectedNodeKeys,
                                        (field: string, value: any) => handleChangeEmitirContrato(field, value),
                                        setShowConfirmarModalReativarContrato,
                                        setShowModalEmitirContrato,
                                        setShowModalAnexo
                                    )}
                                    header="Menu"
                                />
                            </DataTable>
                        </div>
                        <div className="pagination-footer">
                            <MDBRow>
                                <MDBCol>
                                    <MDBPagination circle>
                                        <MDBPageItem disabled={currentPage === 0}>
                                            <MDBPageNav className="page-link" aria-label="Previous" onClick={() => setCurrentPage(currentPage - 1)}>
                                                <span aria-hidden="true">&laquo;</span>
                                            </MDBPageNav>
                                        </MDBPageItem>
                                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                                            {renderPageNumbers()}
                                        </div>
                                        <MDBPageItem disabled={currentPage === pagination - 1}>
                                            <MDBPageNav className="page-link" aria-label="Next" onClick={() => setCurrentPage(currentPage + 1)}>
                                                <span aria-hidden="true">&raquo;</span>
                                            </MDBPageNav>
                                        </MDBPageItem>
                                    </MDBPagination>
                                </MDBCol>
                            </MDBRow>
                        </div>
                    </div>
                </div>
            </div>
            <ModalEmitirContrato
                showModalEmitirContrato={showModalEmitirContrato}
                selectedNodeKeys={selectedNodeKeys}
                formEmissao={formEmissao}
                onInputChange={(field: string, value: any) => handleChangeEmitirContrato(field, value)}
                handleShowModalEmitirContrato={setShowModalEmitirContrato}
            />

            <ModalAnexo
                showModalAnexo={showModalAnexo}
                selectedNodeKeys={selectedNodeKeys}
                handleShowModalAnexo={setShowModalAnexo}
            />

            <ModalCancelarContrato
                showModalCancelarContrato={showModalCancelarContrato}
                selectedNodeKeys={selectedNodeKeys}
                formCancelamento={formCancelamento}
                dados={null}
                dataCancelamento={dataCancelamento}
                dataPrimeiraParcelaPaga={dataPrimeiraParcelaPaga}
                handleDataPrimeiraParcelaPaga={setDataPrimeiraParcelaPaga}
                handleDataCancelamento={setDataCancelamento}
                onInputChange={(field: string, value: any) => handleChange(field, value)}
                handleShowModalCancelarContrato={setShowModalCancelarContrato}
            />

            <ModalReativarContrato
                showModalReativarContrato={showModalReativarContrato}
                handleShowModalReativarContrato={setShowModalReativarContrato}
                selectedNodeKeys={selectedNodeKeys}
                formCancelamento={formCancelamento}
                dataCancelamento={dataCancelamento}
                handleDataCancelamento={setDataCancelamento}
                dataPrimeiraParcelaPaga={dataPrimeiraParcelaPaga}
                handleDataPrimeiraParcelaPaga={setDataPrimeiraParcelaPaga}
                onInputChange={(field: string, value: any) => handleChange(field, value)}
            />

            <ModalConfirmarReativarContrato
                showConfirmarModalReativarContrato={showConfirmarModalReativarContrato}
                selectedNodeKeys={selectedNodeKeys}
                handleShowConfirmarModalReativarContrato={setShowConfirmarModalReativarContrato}
                handleShowModalReativarContrato={setShowModalReativarContrato}
            />
            <ModalConfirmarCancelarContratoJazigo
                selectedRow={selectedRow}
                showConfirmarModalCancelarContratoJazigo={showConfirmarModalCancelarContratoJazigo}
                handleShowConfirmarModalCancelarContratoJazigo={setShowConfirmarModalCancelarContratoJazigo}
                handleShowModalCancelarContratoJazigo={setShowCancelarContratoJazigo}
            />
            <ModalCancelarContratoJazigo
                selectedRow={selectedRow}
                showCancelarContratoJazigo={showCancelarContratoJazigo}
                handleShowModalCancelarContratoJazigo={setShowCancelarContratoJazigo}
                onInputChange={(field: string, value: any) => handleChange(field, value)}
            />
            <ModalVendaContrato
                showModal={showModalVendaContrato}
                handleClose={() => setShowModalVendaContrato(false)}
            />
            <ModalListVendaAvulsa
                filtro={selectedRow}
                showModal={showModalVendaAvulsa}
                handleClose={() => setShowModalVendaAvulsa(false)}
            />
            <ModalEditVendaContrato
                vendaContrato={selectedRow}
                showModal={showModalEditVendaContrato}
                handleClose={() => handleClose()}
            />
            <ModalJazigosEstrutura
                imovelSelected={selectedRow}
                onVerified={onHandleVerified}
                notVerified={notHandleVerified}
                showModal={showModalJazigosEstrutura}
                handleClose={() => setShowModalJazigosEstrutura(false)}
            />
            <ModalIndices
                showModal={showModalIndices}
                handleClose={() => setShowModalIndices(false)}
                filtro={dados}
            />
        </div >
    );
}

export default Cemiterio;
