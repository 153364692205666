import iconBarra from '../../../../../assets/images/barra-menu.svg';
import Nav from "react-bootstrap/Nav";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import TokenService from "../../../../services/Auth/token.service";
import { getImageByName } from "../../../../../assets/images";
interface SubmenuItem {
  name: string;
  icon: string;
  route: string;
  is_menu: boolean;
  is_context_menu: boolean;
}
interface SidebarProps {
  menu: SubmenuItem[] | null;
}
function MenuComponent({ menu }: SidebarProps) {
  const [selectedOption, setSelectedOption] = useState("#");
  const [selectedMenu, setSelectedMenu] = useState<any>();
  const navigate = useNavigate();
  const select = localStorage.getItem("menu-navbar");
  useEffect(() => {
    if (select) {
      setSelectedMenu(JSON.parse(select));
    }
  }, [select])
  useEffect(() => {
    const rotaAtual = window.location.pathname;

    const opcaoEncontrada: any = menu?.find((item) => item.route === rotaAtual);
    if (opcaoEncontrada) {
      setSelectedOption(opcaoEncontrada.icon);
    }

  }, []);
  const setMenuSelect = (selected: any) => {
    TokenService.setMenuSelect(selected);
  }
  return (
    <div className="menu-container">
      <Nav className="me-auto menu-navbar">
        {menu?.map((placement, index) => (
          placement.is_menu === true && (
            <Nav.Link
              key={index}
              // href={placement.route}
              className={`svgNavBar navBar ${selectedOption === placement.icon ? "selected" : ""}`}
              onClick={(e) => {
                setMenuSelect(placement);
                setSelectedOption(placement.icon);
                navigate(placement.route);
              }}
            >
              <img
                alt={placement.icon}
                className={placement.icon}
                src={getImageByName(placement.icon) ?? ""}
              />
              {placement.name}
              <div className="iconBarra"></div>
            </Nav.Link>
          )
        ))}
      </Nav>
    </div>
  );

}

export default MenuComponent;
