import { useEffect, useState } from "react";
import { Row } from "react-bootstrap";

function EditCompradores(props: any) {
    const [outrosCompradores, setOutrosCompradores] = useState();
    const [principalCompradores, setPrincipalCompradores] = useState();
    useEffect(() => {
        setOutrosCompradores(props.compradorData?.filter((comprador: any) => comprador.principal != 1));
        setPrincipalCompradores(props.compradorData?.filter((comprador: any) => comprador.principal != 0));
    }, [props.compradorData]);

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            flex: '1',
            height: '-webkit-fill-available',
            marginTop: '6px',
            minHeight: '350px'
        }}>
            {(principalCompradores || []).map((Comprador: any) => (
                <div style={{ textAlign: 'center' }}>
                    <Row>
                        <h5>Comprador Principal</h5>
                    </Row>
                    <hr />
                    {Comprador && (
                        <Row >
                            <p style={{ display: 'flex', justifyContent: 'center' }}> {Comprador?.cliente_name}</p>
                        </Row>
                    )}
                    <hr />
                </div>
            ))}
            {(outrosCompradores || []).map((Comprador: any) => (
                <div style={{ textAlign: 'center' }}>
                    <Row>
                        <h5>Outros Compradores vinculados ao contrato</h5>
                    </Row>
                    <hr />
                    {
                        Comprador ? (
                            <Row>
                                <p style={{ display: 'flex', justifyContent: 'center' }}>{Comprador?.cliente_name}</p>
                            </Row>
                        ) : (
                            <Row>
                                <p style={{ display: 'flex', justifyContent: 'center' }}>Nenhum outro comprador existente no contrato!</p>
                            </Row>
                        )
                    }
                    < hr />
                </div>
            ))}
        </div>
    );
}

export default EditCompradores;
