import React, { ReactNode, createContext, useContext, useState } from 'react';

export interface TelefoneData {
    nome_contato: string;
    ddd: string;
    numero: string;
    tipo_telefone: number;
    observacao: string;
}

interface TelefoneContextProps {
    telefoneData: TelefoneData[];
    updateTelefoneData: (newData: TelefoneData[]) => void;
}

const TelefoneContext = createContext<TelefoneContextProps | undefined>(undefined);

export const useAppContext = () => {
    const context = useContext(TelefoneContext);
    if (!context) {
        throw new Error('useAppContext must be used within an AppProvider');
    }
    return context;
};

interface AppProviderProps {
    children: ReactNode;
}

export const TelefoneProvider: React.FC<AppProviderProps> = ({ children }) => {
    const [telefoneData, updateTelefoneData] = useState<TelefoneData[]>([]);

    const updateTelefone = (newData: TelefoneData[]) => {
        updateTelefoneData(newData);
    };

    return (
        <TelefoneContext.Provider value={{ telefoneData, updateTelefoneData: updateTelefone }}>
            {children}
        </TelefoneContext.Provider>
    );
};

export const useTelefoneContext = () => {
    return useContext(TelefoneContext);
};
