import { useEffect, useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { VendaAvulsaList } from '../Service/VendaService';
import 'react-autocomplete-input/dist/bundle.css';
import '../index.css';
import { Col, Form, Modal, Row } from 'react-bootstrap';
import { MDBCol, MDBPageItem, MDBPageNav, MDBPagination, MDBRow } from 'mdbreact';
import { Skeleton } from 'primereact/skeleton';
import ModalVendaAvulsa from './ModalVendaAvulsa';
import { Button } from 'primereact/button';
import { AutoComplete } from 'primereact/autocomplete';
import FiltroAvancadoModal from './ModalFiltroAvancado';
import iconFiltroAvancado from '../../../../../../../assets/images/icon-filtro-avancado.png';
import { InputNumber } from 'primereact/inputnumber';
import { searchPessoa } from '../../../Services/VendaService';
import { actionTemplateVendaAvulsa } from './actionTemplateVendaAvulsa';
import ModalConfirmarVendaAvulsa from './ModalConfirmarVendaAvulsa';
import ModalCloneVendaAvulsa from './ModalCloneVendaAvulsa';
import ModalImpressaoVendaAvulsa from './ModalImpressaoVendaAvulsa';
import { searchCliente } from '../../../../../Financeiro/ContasReceber/Service/ContasReceberService';
import { showToast } from '../../../../../../components/ToastComponent';
interface ModalListVendaAvulsaProps {
    filtro: any;
    showModal: boolean;
    handleClose: () => void;
}

const ModalListVendaAvulsa: React.FC<ModalListVendaAvulsaProps> = ({ filtro, showModal, handleClose }) => {
    const [nodes, setNodes] = useState<any | null>(null);
    const [currentPage, setCurrentPage] = useState(0);
    const [titles, setTitles] = useState<any[]>([]);
    const [pagination, setPagination] = useState<any>();
    const [isLoading, setLoading] = useState(false);
    const [show, setShow] = useState(showModal);
    const [showModalVendaAvulsa, setShowModalVendaAvulsa] = useState(false);
    const [FilteredPessoa, setFilteredPessoa] = useState<any>(null);
    const [listFiltros, setListFiltros] = useState<any>({});
    const [showModalFiltro, setShowModalFiltro] = useState(false);
    const [showModalConfirmarVenda, setShowModalConfirmarVenda] = useState(false);
    const [showModalCloneVenda, setShowModalCloneVenda] = useState(false);
    const [showModalImpressaoVendaAvulsa, setShowModalImpressaoVendaAvulsa] = useState(false);
    const [selectedRow, setSelectedRow] = useState<any>(null);
    useEffect(() => {
        VendaAvulsaList({ ...listFiltros, contrato_id: filtro?.id }, currentPage, setLoading, setNodes, setTitles, setPagination);
    }, [currentPage, filtro]);
    useEffect(() => {
        setShow(showModal);
    }, [showModal])
    const handleChange = (field: string, value: any) => {
        setListFiltros((prevGrupoData: any) => {
            return {
                ...prevGrupoData,
                [field]: value
            };
        });
    };

    const renderPageNumbers = () => {
        const pageNumbers = [];
        for (let i = 0; i < pagination; i++) {
            if (i < 3 || i === pagination - 1 || (i >= currentPage - 1 && i <= currentPage + 1)) {
                pageNumbers.push(
                    <MDBPageItem key={i} active={i === currentPage}>
                        <MDBPageNav className="page-link" onClick={() => setCurrentPage(i)}>
                            <span key={i} onClick={() => setCurrentPage(i)} style={{ cursor: 'pointer', margin: '0 5px', fontWeight: i === currentPage ? 'bold' : 'normal' }}>
                                {i + 1}
                            </span>
                        </MDBPageNav>
                    </MDBPageItem>
                );
            }
        }
        return pageNumbers;
    };
    return (
        <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            size="xl"
        >
            <Modal.Header>
                <Modal.Title style={{ color: '#494949' }}>Vendas Avulsas</Modal.Title>
                <Button
                    style={{ height: '30px', borderRadius: '7px' }}
                    type="button"
                    icon="pi pi-plus"
                    severity="success"
                    onClick={(e) => setShowModalVendaAvulsa(true)}
                    rounded>Criar Venda</Button>
            </Modal.Header>
            <Modal.Body>
                <div className="body-content">
                    <Row>
                        <Col md="2" style={{ display: 'flex', flexDirection: 'column' }}>
                            <Form.Label>Cliente | Beneficiário</Form.Label>
                            <AutoComplete
                                field="nome_cliente"
                                style={{ height: "37px" }}
                                value={listFiltros.cliente}
                                suggestions={FilteredPessoa}
                                completeMethod={(e) => searchCliente(e, setFilteredPessoa)}
                                onChange={(e) => {
                                    setListFiltros({ ...listFiltros, cliente: e.value })
                                    handleChange('cliente', e.value)
                                }}
                                onSelect={(e) => {
                                    handleChange('cliente_id', e.value.id)
                                    setListFiltros({ ...listFiltros, cliente_id: e.value.id, cliente: e.value })
                                }}
                                dropdown
                            />
                        </Col>
                        <Col md="2" style={{ display: 'flex', flexDirection: 'column' }}>
                            <Form.Label>Tipo</Form.Label>
                            <Form.Select value={listFiltros?.tipo} onChange={(e) => handleChange('tipo', Number(e.target.value))}>
                                <option>Selecione o tipo</option>
                                <option value={1}>Orçamento</option>
                                <option value={2}>Venda</option>
                            </Form.Select>
                        </Col>
                        <Col md="2" style={{ display: 'flex', flexDirection: 'column' }}>
                            <Form.Label>Dia Vencimento</Form.Label>
                            <InputNumber
                                placeholder='Dia do vencimento'
                                style={{ width: '100%', height: '38px' }}
                                value={listFiltros?.dia_vencimento}
                                onChange={(e: any) => handleChange('dia_vencimento', e.value)}
                                locale="pt-BR"
                                min={0}
                                max={31}
                                maxLength={2}
                            />
                        </Col>
                        <Col className='div-filtros-avancados' style={{ marginTop: '15px' }}>
                            <Button
                                style={{ height: '30px', borderRadius: '7px', padding: '5px 10px', marginRight: '5px', fontSize: '14px' }}
                                type="button"
                                rounded outlined severity="secondary"
                                onClick={() => { setShowModalFiltro(true) }}>
                                <img style={{ width: '25px', height: '35px' }} src={iconFiltroAvancado} />
                            </Button>
                            <Button
                                style={{ position: 'relative', float: 'right', height: '30px', borderRadius: '7px', marginRight: '5px' }}
                                type="button"
                                icon="pi pi-search"
                                severity="success"
                                onClick={() => {
                                    VendaAvulsaList({ ...listFiltros, contrato_id: filtro?.id }, currentPage, setLoading, setNodes, setTitles, setPagination);
                                }}
                                rounded>
                                Filtrar
                            </Button>
                            <Button
                                style={{ height: '30px', borderRadius: '7px', padding: '5px 10px', marginRight: '5px', fontSize: '14px' }}
                                type="button"
                                severity="danger"
                                icon="pi pi-delete-left"
                                rounded
                                onClick={() => {
                                    setListFiltros({});
                                    VendaAvulsaList({ ...listFiltros, contrato_id: filtro?.id }, currentPage, setLoading, setNodes, setTitles, setPagination);
                                }}
                            >
                                <p style={{ marginLeft: '5px', marginTop: '13px' }}>Limpar</p>
                            </Button>
                        </Col>
                    </Row>
                    <hr />
                    <div className="container-section-body">
                        <DataTable
                            stripedRows
                            lazy
                            value={nodes ? nodes : []}
                            style={{ minHeight: '350px' }}
                            tableStyle={{ minWidth: '50rem', overflowX: 'hidden', overflowY: 'hidden' }}
                            emptyMessage={<span style={{ display: 'flex', justifyContent: 'center', fontStyle: 'italic' }}>Nenhum item encontrado.</span>}
                        >
                            <Column
                                style={{ textAlign: 'left' }}
                                key={'data_venda_label'}
                                field={'data_venda_label'}
                                headerClassName="sticky-header lefted-header"
                                header={<div className="lefted-content" >Data da Venda</div>}
                            />
                            <Column
                                style={{ textAlign: 'left' }}
                                key={'pessoa_nome_label'}
                                field={'pessoa_nome_label'}
                                headerClassName="sticky-header lefted-header"
                                header={<div className="lefted-content" >Cliente </div>}
                            />
                            <Column
                                style={{ textAlign: 'right' }}
                                key={'valor_final_label'}
                                field={'valor_final_label'}
                                headerClassName="sticky-header righted-header"
                                header={<div className="righted-content" >Total </div>}
                            />
                            <Column
                                style={{ textAlign: 'right' }}
                                key={'valor_sinal_label'}
                                field={'valor_sinal_label'}
                                headerClassName="sticky-header righted-header"
                                header={<div className="righted-content" >Vlr.Sinal </div>}
                            />
                            <Column
                                style={{ textAlign: 'right' }}
                                key={'quantidade_parcelas_label'}
                                field={'quantidade_parcelas_label'}
                                headerClassName="sticky-header righted-header"
                                header={<div className="righted-content" >Qtd.Parcelas </div>}
                            />
                            <Column
                                style={{ textAlign: 'right' }}
                                key={'valor_parcela_label'}
                                field={'valor_parcela_label'}
                                headerClassName="sticky-header righted-header"
                                header={<div className="righted-content" >Vlr.Parcelas </div>}
                            />
                            <Column
                                style={{ textAlign: 'left' }}
                                key={'corretor_label'}
                                field={'corretor_label'}
                                headerClassName="sticky-header lefted-header"
                                header={<div className="lefted-content" >Corretor </div>}
                            />
                            <Column
                                style={{ textAlign: 'left' }}
                                key={'tipo_venda_label'}
                                field={'tipo_venda_label'}
                                headerClassName="sticky-header lefted-header"
                                header={<div className="lefted-content" >Tipo </div>}
                            />
                            <Column
                                className='menu-context-grid-contas'
                                body={(e) => actionTemplateVendaAvulsa(
                                    e,
                                    setSelectedRow,
                                    setShowModalConfirmarVenda,
                                    setShowModalCloneVenda,
                                    setShowModalImpressaoVendaAvulsa
                                )}
                                header="Menu"
                                headerClassName="sticky-header"
                            />
                        </DataTable>
                    </div>
                    <ModalVendaAvulsa
                        showModal={showModalVendaAvulsa}
                        handleClose={() => setShowModalVendaAvulsa(false)}
                    />
                    <ModalConfirmarVendaAvulsa
                        selectedRow={selectedRow}
                        showModal={showModalConfirmarVenda}
                        handleClose={() => setShowModalConfirmarVenda(false)}
                    />
                    <ModalCloneVendaAvulsa
                        selectedRow={selectedRow}
                        showModal={showModalCloneVenda}
                        handleClose={() => setShowModalCloneVenda(false)}
                    />
                    <ModalImpressaoVendaAvulsa
                        selectedRow={selectedRow}
                        showModal={showModalImpressaoVendaAvulsa}
                        handleClose={() => setShowModalImpressaoVendaAvulsa(false)}
                    />
                    <FiltroAvancadoModal
                        showModal={showModalFiltro}
                        onHide={() => setShowModalFiltro(false)}
                        handleClose={() => setShowModalFiltro(false)}
                        listFiltros={listFiltros}
                        setListFiltros={setListFiltros}
                        loadData={VendaAvulsaList}
                        currentPage={currentPage}
                        setLoading={setLoading}
                        setNodes={setNodes}
                        updateTitles={setTitles}
                        updatePagination={setPagination}
                        setShowModalFiltro={setShowModalFiltro}
                    />
                    <div className="pagination-footer" style={{ display: 'flex' }}>
                        <MDBRow>
                            <MDBCol>
                                <MDBPagination circle>
                                    <MDBPageItem disabled={currentPage === 0}>
                                        <MDBPageNav className="page-link" aria-label="Previous" onClick={() => setCurrentPage(currentPage - 1)}>
                                            <span aria-hidden="true">&laquo;</span>
                                        </MDBPageNav>
                                    </MDBPageItem>
                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                        {renderPageNumbers()}
                                    </div>
                                    <MDBPageItem disabled={currentPage === pagination - 1}>
                                        <MDBPageNav className="page-link" aria-label="Next" onClick={() => setCurrentPage(currentPage + 1)}>
                                            <span aria-hidden="true">&raquo;</span>
                                        </MDBPageNav>
                                    </MDBPageItem>
                                </MDBPagination>
                            </MDBCol>
                        </MDBRow>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer style={{ display: 'flex', flexDirection: 'column' }}>
                <div style={{ display: 'flex', justifyContent: 'end', paddingTop: '15px' }}>
                    <Button style={{ borderRadius: '7px' }} onClick={() => { handleClose() }} className="button-back-form">
                        Fechar
                    </Button>
                </div>
            </Modal.Footer>
        </Modal>
    );
}

export default ModalListVendaAvulsa;
