import { Col, Form, Row } from "react-bootstrap";
import { useEffect, useState } from "react";
import { AlertService } from "../../../../components/AlertService";
import { url } from "../../../../environments/environments-develop";
import api from '../../../../services/api';

interface PropsData {
    Data: {
        empreendimento_id: string;
        venda_direta?: boolean;
        coef_intermediacao?: number;
        coef_admin?: number;
        coef_entrada?: number;
        coef_coordenador?: number;
        coef_corr_comiss?: number;
        multa_contrato_cancel?: number;
        multa_contrato_extravio?: number;
        qtd_limite_vendas?: number;
        qtd_parc_entrada?: number;
        qtd_parc_intermed?: number;
        modo_preco_fv_pf?: string;
        num_contrato_manual?: boolean;
        editar_contrato?: boolean;
        imp_contrato_sem_acerto?: boolean;
        intermed_embut_preco_imov?: boolean;
        imp_rcb_intermed_corretagem?: boolean;
        rcb_parte_corretor?: boolean;
        permitir_blt_intd_entrd?: boolean;
        cad_client_resumido?: boolean;
        alias_intermed?: string;
        intermed_desc?: string;
        parcela_chave?: boolean;
        coef_saldo_entrega_chave?: string;
        coef_parte_parc_proprio?: string;
        processa_entrada?: boolean;
        intermed_desc_part_adm?: string;

    };
    initialRightValues: any;
    onInputChange: (field: string, value: any) => void;
}
function ConfigVendaForm(props: PropsData) {
    const { empreendimento_id,
        venda_direta,
        coef_intermediacao,
        coef_admin,
        coef_entrada,
        coef_coordenador,
        coef_corr_comiss,
        multa_contrato_cancel,
        multa_contrato_extravio,
        modo_preco_fv_pf,
        qtd_limite_vendas,
        qtd_parc_entrada,
        qtd_parc_intermed,
        num_contrato_manual,
        editar_contrato,
        imp_contrato_sem_acerto,
        intermed_embut_preco_imov,
        imp_rcb_intermed_corretagem,
        rcb_parte_corretor,
        permitir_blt_intd_entrd,
        cad_client_resumido,
        alias_intermed,
        intermed_desc,
        parcela_chave,
        coef_saldo_entrega_chave,
        coef_parte_parc_proprio,
        processa_entrada,
        intermed_desc_part_adm } = props.Data || {};
    const [listPermi, setListPermi] = useState([]);

    const handleChange = (field: string, value: any) => {
        if (
            field === 'coef_intermediacao' || field === 'coef_admin' ||
            field === 'coef_corr_comiss' || field === 'coef_entrada' ||
            field === 'coef_coordenador' || field === 'multa_contrato_cancel' ||
            field === 'multa_contrato_extravio'
        ) {
            if (/^[0-9.;\/\-]*$/.test(value) && value.length <= 11) {
                props.onInputChange(field, value);
            }
        } else if (
            field === 'qtd_limite_vendas' || field === 'qtd_parc_entrada' || field === 'qtd_parc_intermed'
        ) {
            if (/^\d*$/.test(value) && value.length <= 11) {
                props.onInputChange(field, value);
            }
        } else if (field === 'alias_intermed') {
            if (!value || value.length <= 30) {
                props.onInputChange(field, value);
            }
        } else if (field === 'intermed_desc') {
            if (!value || value.length <= 120) {
                props.onInputChange(field, value);
            }
        } else if (field === 'coef_saldo_entrega_chave') {
            if (/^\d*$/.test(value) && value.length <= 30) {
                props.onInputChange(field, value);
            }
        } else if (field === 'coef_parte_parc_proprio') {
            if (!value || value.length <= 30) {
                props.onInputChange(field, value);
            }
        } else if (field === 'intermed_desc_part_adm') {
            if (!value || value.length <= 30) {
                props.onInputChange(field, value);
            }
        }
        else {
            props.onInputChange(field, value);
        }
    };
    async function listEmpreendimento() {
        try {
            const response = await api.get(`${url}/api/v1/admin/vendas/empreendimentos`);
            setListPermi(response.data.data);
        } catch (error) {
            AlertService.error('Error', 'Ocorreu um erro ao buscar a lista de permissões. Erro:' + String(error));
        }
    }
    useEffect(() => {
        listEmpreendimento();
    }, []);
    return (
        <>
            <div className="formulario-empreendimento">
                <Row className="mb-3">
                    <Col>
                        <Form.Label>Coeficiênte da Intermediação</Form.Label>
                        <Form.Control value={coef_intermediacao} onChange={(e) => handleChange('coef_intermediacao', e.target.value)} />
                    </Col>
                    <Col>
                        <Form.Label>Coeficiênte Administrativo</Form.Label>
                        <Form.Control value={coef_admin} onChange={(e) => handleChange('coef_admin', e.target.value)} />
                    </Col>
                    <Col>
                        <Form.Label>Coeficiênte da Entrada</Form.Label>
                        <Form.Control value={coef_entrada} onChange={(e) => handleChange('coef_entrada', e.target.value)} />
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col>
                        <Form.Label>Coeficiênte do Coordenador</Form.Label>
                        <Form.Control value={coef_coordenador} onChange={(e) => handleChange('coef_coordenador', e.target.value)} />
                    </Col>
                    <Col>
                        <Form.Label>Coeficiênte Comissão do Corretor</Form.Label>
                        <Form.Control value={coef_corr_comiss} onChange={(e) => handleChange('coef_corr_comiss', e.target.value)} />
                    </Col>
                    <Col>
                        <Form.Label>Multa Cancelamento do Contrato</Form.Label>
                        <Form.Control value={multa_contrato_cancel} onChange={(e) => handleChange('multa_contrato_cancel', e.target.value)} />
                    </Col>
                    <Col>
                        <Form.Label>Multa Extravio do Contrato</Form.Label>
                        <Form.Control value={multa_contrato_extravio} onChange={(e) => handleChange('multa_contrato_extravio', e.target.value)} />
                    </Col>
                </Row>
                <Row className="mb-3">

                    <Col>
                        <Form.Label>Alias da Intermediação</Form.Label>
                        <Form.Control value={alias_intermed} onChange={(e) => handleChange('alias_intermed', e.target.value)} />
                    </Col>

                    <Col>
                        <Form.Label>Qtd. Limite de Venda</Form.Label>
                        <Form.Control value={qtd_limite_vendas} onChange={(e) => handleChange('qtd_limite_vendas', e.target.value)} />
                    </Col>
                    <Col>
                        <Form.Label>Qtd. Max. parcelas da Entrada</Form.Label>
                        <Form.Control value={qtd_parc_entrada} onChange={(e) => handleChange('qtd_parc_entrada', e.target.value)} />
                    </Col>
                    <Col>
                        <Form.Label>Qtd. Max. parcelas da Intermediação</Form.Label>
                        <Form.Control value={qtd_parc_intermed} onChange={(e) => handleChange('qtd_parc_intermed', e.target.value)} />
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col md="2" style={{ padding: "0px", display: "flex", alignItems: "center", flexDirection: "column" }}>
                        <Form.Label>N° Contrato Manual?</Form.Label>
                        <Form.Check
                            style={{ display: "flex", alignItems: "center", justifyContent: "center", }}
                            checked={num_contrato_manual || false}
                            onChange={(e) => handleChange("num_contrato_manual", e.target.checked)}
                            type="switch"
                            id="custom-switch"
                        />
                    </Col>
                    <Col md="2" style={{ padding: "0px", display: "flex", alignItems: "center", flexDirection: "column" }}>
                        <Form.Label>Permitir Editar Contrato?</Form.Label>
                        <Form.Check
                            style={{ display: "flex", alignItems: "center", justifyContent: "center", }}
                            checked={editar_contrato || false}
                            onChange={(e) => handleChange("editar_contrato", e.target.checked)}
                            type="switch"
                            id="custom-switch"
                        />
                    </Col>
                    <Col md="2" style={{ padding: "0px", display: "flex", alignItems: "center", flexDirection: "column" }}>
                        <Form.Label>impressão Contrato S/Acerto?</Form.Label>
                        <Form.Check
                            style={{ display: "flex", alignItems: "center", justifyContent: "center", }}
                            checked={imp_contrato_sem_acerto || false}
                            onChange={(e) => handleChange("imp_contrato_sem_acerto", e.target.checked)}
                            type="switch"
                            id="custom-switch"
                        />
                    </Col>
                    <Col md="2" style={{ padding: "0px", display: "flex", alignItems: "center", flexDirection: "column" }}>
                        <Form.Label>Intermd. embt. preço imovel?</Form.Label>
                        <Form.Check
                            style={{ display: "flex", alignItems: "center", justifyContent: "center", }}
                            checked={intermed_embut_preco_imov || false}
                            onChange={(e) => handleChange("intermed_embut_preco_imov", e.target.checked)}
                            type="switch"
                            id="custom-switch"
                        />
                    </Col>
                    <Col md="2" style={{ padding: "0px", display: "flex", alignItems: "center", flexDirection: "column" }}>
                        <Form.Label>Processar Entrada?</Form.Label>
                        <Form.Check
                            style={{ display: "flex", alignItems: "center", justifyContent: "center", }}
                            checked={processa_entrada || false}
                            onChange={(e) => handleChange("processa_entrada", e.target.checked)}
                            type="switch"
                            id="custom-switch"
                        />
                    </Col>
                    <Col md="2" style={{ padding: "0px", display: "flex", alignItems: "center", flexDirection: "column" }}>
                        <Form.Label>Imprimir Recibo Intermd?</Form.Label>
                        <Form.Check
                            style={{ display: "flex", alignItems: "center", justifyContent: "center", }}
                            checked={imp_rcb_intermed_corretagem || false}
                            onChange={(e) => handleChange("imp_rcb_intermed_corretagem", e.target.checked)}
                            type="switch"
                            id="custom-switch"
                        />
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col md="2" style={{ padding: "0px", display: "flex", alignItems: "center", flexDirection: "column" }}>
                        <Form.Label>Boleto Entrada da Intermd?</Form.Label>
                        <Form.Check
                            style={{ display: "flex", alignItems: "center", justifyContent: "center", }}
                            checked={permitir_blt_intd_entrd || false}
                            onChange={(e) => handleChange("permitir_blt_intd_entrd", e.target.checked)}
                            type="switch"
                            id="custom-switch"
                        />
                    </Col>
                    <Col md="2" style={{ padding: "0px", display: "flex", alignItems: "center", flexDirection: "column" }}>
                        <Form.Label>Cadastro Cliente Resumido?</Form.Label>
                        <Form.Check
                            style={{ display: "flex", alignItems: "center", justifyContent: "center", }}
                            checked={cad_client_resumido || false}
                            onChange={(e) => handleChange("cad_client_resumido", e.target.checked)}
                            type="switch"
                            id="custom-switch"
                        />
                    </Col>
                    <Col md="2" style={{ padding: "0px", display: "flex", alignItems: "center", flexDirection: "column" }}>
                        <Form.Label>Recibo da Parte do Corretor?</Form.Label>
                        <Form.Check
                            style={{ display: "flex", alignItems: "center", justifyContent: "center", }}
                            checked={rcb_parte_corretor || false}
                            onChange={(e) => handleChange("rcb_parte_corretor", e.target.checked)}
                            type="switch"
                            id="custom-switch"
                        />
                    </Col>
                    <Col md="2" style={{ padding: "0px", display: "flex", alignItems: "center", flexDirection: "column" }}>
                        <Form.Label>Permitir Venda Direta?</Form.Label>
                        <Form.Check
                            style={{ display: "flex", alignItems: "center", justifyContent: "center", }}
                            checked={venda_direta || false}
                            onChange={(e) => handleChange("venda_direta", e.target.checked)}
                            type="switch"
                            id="custom-switch"
                        />
                    </Col>
                    <Form.Group as={Col} controlId="formGridState">
                        <Form.Label>Modelo de Preço</Form.Label>
                        <Form.Select value={modo_preco_fv_pf || ''} onChange={(e) => handleChange('modo_preco_fv_pf', e.target.value)}>
                            <option>Selecione...</option>
                            <option value={1}>FV</option>
                            <option value={2}>PV</option>
                        </Form.Select>
                    </Form.Group>
                </Row>
                <Row className="mb-3">
                    <Col>
                        <Form.Label>Desconto da Intermediação</Form.Label>
                        <Form.Control value={intermed_desc} onChange={(e) => handleChange('intermed_desc', e.target.value)} />
                    </Col>
                    <Col style={{ padding: "0px", display: "flex", alignItems: "center", flexDirection: "column" }}>
                        <Form.Label>Parcela Chave</Form.Label>
                        <Form.Check
                            style={{ display: "flex", alignItems: "center", justifyContent: "center", }}
                            checked={parcela_chave || false}
                            onChange={(e) => handleChange("parcela_chave", e.target.checked)}
                            type="switch"
                            id="custom-switch"
                        />
                    </Col>
                    <Col>
                        <Form.Label>Coeficiênte para entrega da chave</Form.Label>
                        <Form.Control value={coef_saldo_entrega_chave} onChange={(e) => handleChange('coef_saldo_entrega_chave', e.target.value)} />
                    </Col>

                    <Col>
                        <Form.Label>Desconto parte da intermediação ADM </Form.Label>
                        <Form.Control value={intermed_desc_part_adm} onChange={(e) => handleChange('intermed_desc_part_adm', e.target.value)} />
                    </Col>
                </Row>
            </div>
        </>
    );
}

export default ConfigVendaForm;
