import { Badge, Button, Col, Form, Modal, ProgressBar, Row, ListGroup, Card } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import CalendarIcon from '../../../../../assets/images/icon-calendar.png';
import pt from 'date-fns/locale/pt';
import { cancelamento } from './GestaoContratosServices';
import TokenService from '../../../../services/Auth/token.service';
import { useEffect } from 'react';
import { InputNumber } from 'primereact/inputnumber';

interface ModalCancelarContratoProps {
    showModalCancelarContrato: boolean;
    handleShowModalCancelarContrato: (show: boolean) => void;
    formCancelamento: {
        data?: string;
        motivo?: number;
        observacao?: string;
        id_contrato?: number;
        valor_indenizado?: string;
        qtd_pgtos?: number;
        data_prim_vencimento?: string;
        valor_indenizado_intermediacao?: string;
        qtd_pgtos_intermediacao?: number;
        data_prim_vencimento_intermediacao?: string;
        tipo_calculo?: number;
    };
    onInputChange: (key: string, value: any) => void;
    dataCancelamento: Date | null;
    handleDataCancelamento: (date: Date) => void;
    dataPrimeiraParcelaPaga: Date | null;
    handleDataPrimeiraParcelaPaga: (date: Date) => void;
    selectedNodeKeys: string[];
    dados: any;
}

const ModalCancelarContrato: React.FC<ModalCancelarContratoProps> = ({
    dados,
    formCancelamento,
    handleDataCancelamento,
    handleDataPrimeiraParcelaPaga,
    handleShowModalCancelarContrato,
    onInputChange,
    showModalCancelarContrato,
    selectedNodeKeys,
    dataCancelamento,
    dataPrimeiraParcelaPaga,
}) => {
    const parseCurrency = (value: string): number => {
        if (!value) return 0;
        const numericString = value.replace(/[^\d,-]/g, '').replace('.', '').replace(',', '.');
        return parseFloat(numericString) || 0;
    };

    const formatMoney = (value: number): string => {
        return new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
        }).format(value);
    };

    useEffect(() => {
        if (dados) {
            onInputChange('id_contrato', dados?.id);
        }
    }, [dados, onInputChange]);

    const valorTotalNum = parseCurrency(dados?.valor_total);
    const valorPagoNum = parseCurrency(dados?.valor_pago_nominal);
    const valorIntermedNum = parseCurrency(dados?.valor_intermed);
    const valorEntradaNum = parseCurrency(dados?.valor_entrada);
    const valorParcelaNum = parseCurrency(dados?.valor_parcela);
    const valorPagoComJurosNum = parseCurrency(dados?.valor_pago_com_juros);
    const valorPagoIntermedComJurosNum = parseCurrency(dados?.valor_pago_intermediacao_com_juros);
    const valorPagoIntermedNominalNum = parseCurrency(dados?.valor_pago_intermediaca_nominal);
    const valorInadimplenteNominalNum = parseCurrency(dados?.valor_inadimplente_nominal);
    const valorAbertoNominalNum = parseCurrency(dados?.valor_aberto_nominal);
    const qtdParcelasPagas = parseInt(dados?.qtd_parcelas_pagas) || 0;
    const qtdParcelasInadimplente = parseInt(dados?.qtd_parcelas_inadimplente) || 0;
    const qtdAberto = parseInt(dados?.qtd_aberto) || 0;
    const qtdParcTotal = parseInt(dados?.qtd_parc) || 0;
    const percentPago = valorTotalNum !== 0 ? (valorPagoNum / valorTotalNum) * 100 : 0;
    const valorRestante = valorTotalNum - valorPagoNum;

    return (
        <Modal
            show={showModalCancelarContrato}
            size="xl"
            onHide={() => handleShowModalCancelarContrato(false)}
            centered
            dialogClassName="rounded-modal"
        >
            <Modal.Header closeButton>
                <Modal.Title>Cancelar Contrato Nº {dados?.contrato}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className=" bg-light rounded mb-4 shadow-sm small-font">
                    <Row>
                        <Col md={6}>
                            <h6>Informações do Empreendimento</h6>
                            <ListGroup>
                                <ListGroup.Item><strong>Empreendimento:</strong> {dados?.empreendimento_nome}</ListGroup.Item>
                                <ListGroup.Item><strong>Data da Venda:</strong> {dados?.data_venda}</ListGroup.Item>
                                <ListGroup.Item><strong>{TokenService.getEmpreendimentoId() !== null ? TokenService.getEmpreendimentoId()?.alias_identificador1 : 'Identificador 1'}: </strong> {dados?.identificador1}</ListGroup.Item>
                                <ListGroup.Item><strong>{TokenService.getEmpreendimentoId() !== null ? TokenService.getEmpreendimentoId()?.alias_identificador2 : 'Identificador 2'}: </strong> {dados?.identificador2}</ListGroup.Item>
                            </ListGroup>
                        </Col>
                        <Col md={6}>
                            <h6>Detalhes do Comprador</h6>
                            <ListGroup>
                                <ListGroup.Item><strong>Comprador:</strong> {dados?.comprador_principal}</ListGroup.Item>
                                <ListGroup.Item><strong>Área:</strong> {dados?.area} m²</ListGroup.Item>
                                <ListGroup.Item><strong>Logradouro:</strong> {dados?.logradouro}</ListGroup.Item>
                                {dados?.qtd_gavetas_jazigo > 0 && dados?.sigla_jazigo > 0 && (
                                    <>
                                        <ListGroup.Item><strong>Qtd de Gavetas:</strong> {dados?.qtd_gavetas_jazigo}</ListGroup.Item>
                                        <ListGroup.Item><strong>Sigla do Jazigo:</strong> {dados?.sigla_jazigo}</ListGroup.Item>
                                    </>
                                )}
                            </ListGroup>
                        </Col>
                    </Row>
                </div>
                <div className="bg-white rounded shadow-sm">
                    <h6 className="mb-3">Detalhes da Intermediação</h6>
                    <Row>
                        <Col md={4}>
                            <div className="mb-3 text-center">
                                <Badge bg="danger" className="mb-2">Valor de Intermediação</Badge>
                                <h6>{formatMoney(valorIntermedNum)}</h6>
                            </div>
                        </Col>
                        <Col md={4}>
                            <div className="mb-3 text-center">
                                <Badge bg="success" className="mb-2">Valor Pago (Nominal)</Badge>
                                <h6>{formatMoney(valorPagoIntermedNominalNum)}</h6>
                            </div>
                        </Col>
                        <Col md={4}>
                            <div className="mb-3 text-center">
                                <Badge bg="primary" className="mb-2">Valor Pago com Juros</Badge>
                                <h6>{formatMoney(valorPagoIntermedComJurosNum)}</h6>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className="bg-light rounded mb-4 shadow-sm small-font p-3">
                    <h6>Detalhes Financeiros</h6>
                    <Row className="finance-row">
                        <Col className="finance-col">
                            <Card className="finance-card text-center">
                                <Card.Body className="d-flex flex-column justify-content-center align-items-center">
                                    <Card.Title>Valor da Parcela</Card.Title>
                                    <Card.Text>{formatMoney(valorParcelaNum)}</Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col className="finance-col">
                            <Card className="finance-card text-center">
                                <Card.Body className="d-flex flex-column justify-content-center align-items-center">                                    <Card.Title>Valor de Entrada</Card.Title>
                                    <Card.Text>{formatMoney(valorEntradaNum)}</Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col className="finance-col">
                            <Card className="finance-card text-center">
                                <Card.Body className="d-flex flex-column justify-content-center align-items-center">                                    <Card.Title>Valor Pago com Juros</Card.Title>
                                    <Card.Text>{formatMoney(valorPagoComJurosNum)}</Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col className="finance-col">
                            <Card className="finance-card text-center">
                                <Card.Body className="d-flex flex-column justify-content-center align-items-center">                                    <Card.Title>Valor Inadimplente Nominal</Card.Title>
                                    <Card.Text>{formatMoney(valorInadimplenteNominalNum)}</Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col className="finance-col">
                            <Card className="finance-card text-center">
                                <Card.Body className="d-flex flex-column justify-content-center align-items-center">                                    <Card.Title>Valor Aberto Nominal</Card.Title>
                                    <Card.Text>{formatMoney(valorAbertoNominalNum)}</Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </div>
                <Row className="mt-4 small-font">
                    <Col md={6}>
                        <h6>Valor Total Pago</h6>
                        <ProgressBar className="mb-3" style={{ height: '20px' }}>
                            <ProgressBar
                                now={percentPago}
                                label={`${percentPago.toFixed(2)}%`}
                                variant="success"
                                key={1}
                            />
                            <ProgressBar
                                now={100 - percentPago}
                                label={`${(100 - percentPago).toFixed(2)}%`}
                                variant="danger"
                                key={2}
                            />
                        </ProgressBar>
                        <div className="legend d-flex justify-content-center">
                            <div className="legend-item text-center me-3">
                                <span className="legend-circle bg-success"></span>
                                <strong className="d-block">Pago</strong>
                                <span className="value">{formatMoney(valorPagoNum)}</span>
                            </div>
                            <div className="legend-item text-center">
                                <span className="legend-circle bg-danger"></span>
                                <strong className="d-block">Restante</strong>
                                <span className="value">{formatMoney(valorRestante)}</span>
                            </div>
                            <div className="legend-item text-center ms-3">
                                <span className="legend-circle bg-secondary"></span>
                                <strong className="d-block">Total</strong>
                                <span className="value">{formatMoney(valorTotalNum)}</span>
                            </div>
                        </div>
                    </Col>
                    <Col md={6}>
                        <h6>Qtd de Parcelas</h6>
                        <ProgressBar className="mb-3" style={{ height: '20px' }}>
                            {qtdParcelasPagas > 0 && (
                                <ProgressBar
                                    now={(qtdParcelasPagas / qtdParcTotal) * 100}
                                    label={`${((qtdParcelasPagas / qtdParcTotal) * 100).toFixed(2)}%`}
                                    variant="success"
                                    key={1}
                                />
                            )}
                            {qtdParcelasInadimplente > 0 && (
                                <ProgressBar
                                    now={(qtdParcelasInadimplente / qtdParcTotal) * 100}
                                    label={`${((qtdParcelasInadimplente / qtdParcTotal) * 100).toFixed(2)}%`}
                                    variant="warning"
                                    key={2}
                                />
                            )}
                            {qtdAberto > 0 && (
                                <ProgressBar
                                    now={(qtdAberto / qtdParcTotal) * 100}
                                    label={`${((qtdAberto / qtdParcTotal) * 100).toFixed(2)}%`}
                                    variant="info"
                                    key={3}
                                />
                            )}
                        </ProgressBar>
                        <div className="legend d-flex justify-content-center flex-wrap">
                            <div className="legend-item text-center me-3 mb-2">
                                <span className="legend-circle bg-success"></span>
                                <strong className="d-block">Pagas</strong>
                                <span className="value">{qtdParcelasPagas}</span>
                            </div>
                            <div className="legend-item text-center me-3 mb-2">
                                <span className="legend-circle bg-warning"></span>
                                <strong className="d-block">Inadimplentes</strong>
                                <span className="value">{qtdParcelasInadimplente}</span>
                            </div>
                            <div className="legend-item text-center me-3 mb-2">
                                <span className="legend-circle bg-info"></span>
                                <strong className="d-block">A Pagar</strong>
                                <span className="value">{qtdAberto}</span>
                            </div>
                            <div className="legend-item text-center mb-2">
                                <span className="legend-circle bg-secondary"></span>
                                <strong className="d-block">Total</strong>
                                <span className="value">{qtdParcTotal}</span>
                            </div>
                        </div>
                    </Col>
                </Row>
                <div className="bg-white rounded shadow-sm">
                    <h6 className="text-center mb-4">Formulário de Cancelamento</h6>
                    <Form>
                        <Row className="mb-3">
                            <Col md={4}>
                                <Form.Label>Motivo</Form.Label>
                                <Form.Select
                                    value={formCancelamento.motivo || ''}
                                    isInvalid={!formCancelamento.motivo}
                                    onChange={(e) => onInputChange('motivo', Number(e.target.value))}
                                >
                                    <option value="">Selecione...</option>
                                    <option value={1}>Erro de digitação</option>
                                    {/* <option value={2}>Contrato em negociação</option> */}
                                    <option value={3}>Contratos Recebidos sem Devolução de Valores</option>
                                    <option value={4}>Contratos Recebidos com Devolução de Valores</option>
                                    {/* <option value={5}>Cancelamento para Troca</option> */}
                                </Form.Select>
                            </Col>
                            <Col md={3}>
                                <Form.Label>Data de Cancelamento</Form.Label>
                                <DatePicker
                                    selected={dataCancelamento}
                                    onChange={(date: Date) => {
                                        handleDataCancelamento(date);
                                        onInputChange('data', date.toISOString());
                                    }}
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    fixedHeight
                                    locale={pt}
                                    customInput={
                                        <div className="d-flex align-items-center">
                                            <Form.Control
                                                type="text"
                                                isInvalid={!formCancelamento.data}
                                                value={
                                                    formCancelamento?.data ? new Date(formCancelamento?.data).toLocaleDateString() : ''
                                                }
                                                readOnly
                                            />
                                            <img className="calendar-icon ms-2" src={CalendarIcon} alt="Calendar" />
                                        </div>
                                    }
                                />
                            </Col>
                            <Col md={5}>
                                <Form.Label>Observação</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    rows={1}
                                    isInvalid={!formCancelamento.observacao}
                                    value={formCancelamento.observacao || ''}
                                    onChange={(e) => onInputChange('observacao', e.target.value)}
                                />
                            </Col>
                        </Row>
                        {formCancelamento.motivo === 4 && (
                            <>
                                <Row className="mb-3">
                                    <Col md={3}>
                                        <Form.Label>Valor Indenizado</Form.Label>
                                        <InputNumber
                                            value={typeof formCancelamento?.valor_indenizado === 'string'
                                                ? (formCancelamento?.valor_indenizado ? parseFloat(formCancelamento?.valor_indenizado.replace(',', '.')) : null)
                                                : (formCancelamento?.valor_indenizado ? formCancelamento?.valor_indenizado : null)}
                                            onChange={(e) => onInputChange('valor_indenizado', e.value)}
                                            minFractionDigits={2}
                                            maxFractionDigits={24}
                                            style={{ width: '100%', height: '38px' }}
                                            locale="pt-BR"
                                            invalid={!formCancelamento.valor_indenizado}
                                        />
                                    </Col>
                                    <Col md={3}>
                                        <Form.Label>Qtd de Pagamentos</Form.Label>
                                        <Form.Control
                                            type="number"
                                            value={formCancelamento.qtd_pgtos || ''}
                                            onChange={(e) => onInputChange('qtd_pgtos', parseInt(e.target.value))}
                                            min="0"
                                            step="1"
                                            isInvalid={!formCancelamento.qtd_pgtos}
                                        />
                                    </Col>
                                    <Col md={3}>
                                        <Form.Label>Data do Primeiro Vencimento</Form.Label>
                                        <DatePicker
                                            selected={formCancelamento.data_prim_vencimento ? new Date(formCancelamento.data_prim_vencimento) : null}
                                            onChange={(date: Date) => {
                                                onInputChange('data_prim_vencimento', date.toISOString());
                                            }}
                                            peekNextMonth
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                            fixedHeight
                                            locale={pt}
                                            customInput={
                                                <div className="d-flex align-items-center">
                                                    <Form.Control
                                                        type="text"
                                                        value={
                                                            formCancelamento.data_prim_vencimento
                                                                ? new Date(formCancelamento.data_prim_vencimento).toLocaleDateString()
                                                                : ''
                                                        }
                                                        isInvalid={!formCancelamento.data_prim_vencimento}
                                                        readOnly
                                                    />
                                                    <img className="calendar-icon ms-2" src={CalendarIcon} alt="Calendar" />
                                                </div>
                                            }
                                        />
                                    </Col>
                                    <Col md={3}>
                                        <Form.Label>Valor Indenizado Intermediação</Form.Label>
                                        <InputNumber
                                            value={typeof formCancelamento?.valor_indenizado_intermediacao === 'string'
                                                ? (formCancelamento?.valor_indenizado_intermediacao ? parseFloat(formCancelamento?.valor_indenizado_intermediacao.replace(',', '.')) : null)
                                                : (formCancelamento?.valor_indenizado_intermediacao ? formCancelamento?.valor_indenizado_intermediacao : null)}
                                            onChange={(e) => onInputChange('valor_indenizado_intermediacao', e.value)}
                                            minFractionDigits={2}
                                            maxFractionDigits={24}
                                            style={{ width: '100%', height: '38px' }}
                                            locale="pt-BR"
                                        />
                                    </Col>
                                </Row>
                                <Row className="mb-3">
                                    <Col md={3}>
                                        <Form.Label>Qtd de Pagamentos Intermediação</Form.Label>
                                        <Form.Control
                                            type="number"
                                            value={formCancelamento.qtd_pgtos_intermediacao || ''}
                                            onChange={(e) => onInputChange('qtd_pgtos_intermediacao', Number(e.target.value))}
                                            min="0"
                                        />
                                    </Col>
                                    <Col md={3}>
                                        <Form.Label>Data do Primeiro Vencimento Intermediação</Form.Label>
                                        <DatePicker
                                            selected={formCancelamento.data_prim_vencimento_intermediacao ? new Date(formCancelamento.data_prim_vencimento_intermediacao) : null}
                                            onChange={(date: Date) => {
                                                onInputChange('data_prim_vencimento_intermediacao', date.toISOString());
                                            }}
                                            peekNextMonth
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                            fixedHeight
                                            locale={pt}
                                            customInput={
                                                <div className="d-flex align-items-center">
                                                    <Form.Control
                                                        type="text"
                                                        value={
                                                            formCancelamento.data_prim_vencimento_intermediacao
                                                                ? new Date(formCancelamento.data_prim_vencimento_intermediacao).toLocaleDateString()
                                                                : ''
                                                        }
                                                        readOnly
                                                    />
                                                    <img className="calendar-icon ms-2" src={CalendarIcon} alt="Calendar" />
                                                </div>
                                            }
                                        />
                                    </Col>
                                    <Col md={3}>
                                        <Form.Label>Tipo de Cálculo</Form.Label>
                                        <Form.Select
                                            value={formCancelamento.tipo_calculo || ''}
                                            onChange={(e) => onInputChange('tipo_calculo', Number(e.target.value))}
                                            isInvalid={!formCancelamento.tipo_calculo}
                                        >
                                            <option value="">Selecione...</option>
                                            <option value={1}>Valor do Titulo</option>
                                            <option value={2}>Valor Pago(Com Juros)</option>
                                        </Form.Select>
                                    </Col>
                                </Row>
                            </>
                        )}
                    </Form>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="secondary"
                    onClick={() => handleShowModalCancelarContrato(false)}
                >
                    Cancelar
                </Button>
                <Button
                    variant="success"
                    onClick={() => {
                        cancelamento(selectedNodeKeys, formCancelamento, handleShowModalCancelarContrato)
                    }
                    }
                >
                    Confirmar
                </Button>
            </Modal.Footer>
            <style>{`
                  .rounded-modal .modal-content {
        border-radius: 15px;
    }
    .shadow-sm {
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    }
    .calendar-icon {
        width: 20px;
        cursor: pointer;
    }
    .bg-primary {
        background-color: #007bff !important;
    }
    .text-primary {
        color: #007bff !important;
    }
    .bg-light {
        background-color: #f8f9fa !important;
    }
    .bg-white {
        background-color: #ffffff !important;
    }
    .text-secondary {
        color: #6c757d !important;
    }
    .progress-bar-custom {
        height: 25px; /* Aumenta a altura do ProgressBar */
        border-radius: 10px; /* Bordas arredondadas */
    }
    .badge-custom {
        font-size: 0.9rem;
        padding: 0.5em 0.75em;
    }
    .badge-group {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        margin-top: 10px;
    }
    .small-font {
        font-size: 12px;
    }
    .legend {
        margin-top: 10px;
    }
    .legend-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-right: 15px;
    }
    .legend-circle {
        width: 12px;
        height: 12px;
        border-radius: 50%;
        display: inline-block;
    }
    .legend-item strong {
        font-weight: bold;
        margin-top: 5px;
    }
    .value {
        font-weight: bold;
        margin-top: 2px;
    }
    /* Responsividade para legendas */
    @media (max-width: 576px) {
        .legend-item {
            margin-right: 10px;
            margin-bottom: 10px;
        }
    }
    /* Estilos para Detalhes Financeiros */
    .finance-row {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    .finance-col {
        flex: 0 0 19%; /* Aproximadamente 20% menos margens */
        max-width: 19%;
        padding: 5px;
    }
    @media (max-width: 1200px) {
        .finance-col {
            flex: 0 0 24%;
            max-width: 24%;
        }
    }
    @media (max-width: 992px) {
        .finance-col {
            flex: 0 0 32%;
            max-width: 32%;
        }
    }
    @media (max-width: 768px) {
        .finance-col {
            flex: 0 0 48%;
            max-width: 48%;
        }
    }
    @media (max-width: 576px) {
        .finance-col {
            flex: 0 0 100%;
            max-width: 100%;
        }
    }
    .finance-card {
        border: none;
        box-shadow: 0 2px 5px rgba(0,0,0,0.1);
        transition: transform 0.2s;
        height: 100px; 
    }
    .finance-card:hover {
        transform: translateY(-5px);
    }
    .finance-card .card-title {
        font-size: 14px;
        margin-bottom: 5px;
    }
    .finance-card .card-text {
        font-size: 16px;
        font-weight: bold;
        align-items: center;
        text-align: center;
    }
            `}</style>
        </Modal >
    );

};

export default ModalCancelarContrato;
