import React from "react";
import NavDropdown from "react-bootstrap/NavDropdown";
import { useState } from "react";
import TokenService from "../services/Auth/token.service";
import { FormControl } from "react-bootstrap";

interface ListSelect {
  selects: Array<any> | null;
  onSelect: (index: number) => void;
}

async function changeContext(filialId: number): Promise<void> {
  try {
    TokenService.atualizarFilial(filialId);
  } catch (error) {
    console.error('Erro na chamada da API:', error);
  }
}

function SelectComponent({ selects, onSelect }: ListSelect) {
  const [selectedFilial, setSelectedFilial] = useState<number | null>(null);
  const [filtro, setFiltro] = useState(''); // Estado para o filtro

  const handleFiltroChange = (event: any) => {
    setFiltro(event.target.value); // Atualiza o valor do filtro
  };
  const handleFilialSelect = (index: number) => {
    setSelectedFilial(index);
    onSelect(index);
  };

  const handleFilialChange = (element: any) => {
    const filiais: Array<any> = TokenService.getFiliais();
    const filialSelect = filiais.filter((perm) => perm.id === element.id);

    if (filialSelect.length > 0) {
      TokenService.setFilialId(filialSelect[0]);
      setSelectedFilial(filialSelect[0].id);
      if (window.location.href.includes("update")) {
        history.back();
      }
      changeContext(filialSelect[0].id);
    }
  };

  return (
    <NavDropdown
      className="selectEmpreendimento"
      style={{ color: '#e8550f', fontSize: '16px' }}
      title={
        <p className="label-select-filial">
          {TokenService.getFilialId()?.nome_conta?.substring(0, 15)}...
        </p>
      }
      menuVariant="dark"
    >
      <FormControl
        placeholder="Filtrar por nome"
        value={filtro}
        onChange={handleFiltroChange} // Atualiza o filtro conforme o usuário digita
        style={{
          width: '96%',
          marginTop: '5px',
          marginBottom: '10px',
          marginLeft: '5px',
          marginRight: '5px',
          padding: '5px',
          background: '#d9d9d9'
        }}
      />
      {selects
        ?.filter((item) =>
          item.nome_conta.toLowerCase().includes(filtro.toLowerCase()) // Aplica o filtro
        )
        .map((item, index) => (
          <NavDropdown.Item
            className="dropdownSelect"
            key={index}
            onClick={() => {
              handleFilialChange(item);
            }}
            onSelect={() => {
              item.onSelect();
              handleFilialSelect(index);
            }}
          >
            <p style={{
              width: '96%',
              marginBottom: '10px',
              marginLeft: '5px',
              marginRight: '5px',
              padding: '5px',
              textAlign: 'center'
            }}>
              {item.nome_conta}

            </p>
          </NavDropdown.Item>
        ))}
    </NavDropdown>
  );
};

export default SelectComponent;
