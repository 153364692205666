import React, { useState, useEffect } from 'react';
import { Col, Form, Row } from 'react-bootstrap';

function Comprador(props: any) {
    const [formData, setFormData] = useState<any>({});

    const handleFormChange = (field: string, value: any) => {
        props.onInputChange(field, value);
    }
    useEffect(() => {
        setFormData(props.formData);
    }, [props.formData]);
    return (
        <>
            <Row className="group-form">
                <Col>
                    <Form.Label>Em qual Coluna está o id do comprador?</Form.Label>
                    <Form.Control
                        value={formData.id_externo || ''}
                        onChange={(e) => handleFormChange('id_externo', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está o nome do comprador?</Form.Label>
                    <Form.Control
                        value={formData.nome || ''}
                        onChange={(e) => handleFormChange('nome', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está o cpf do comprador?</Form.Label>
                    <Form.Control
                        value={formData.cpf_cnpj || ''}
                        onChange={(e) => handleFormChange('cpf_cnpj', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está o email do comprador?</Form.Label>
                    <Form.Control
                        value={formData.email || ''}
                        onChange={(e) => handleFormChange('email', e.target.value)} />
                </Col>
            </Row>
            <Row className="group-form">
                <Col>
                    <Form.Label>Em qual Coluna está a estado civil do comprador?</Form.Label>
                    <Form.Control
                        value={formData.estado_civil || ''}
                        onChange={(e) => handleFormChange('estado_civil', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está o sexo do comprador?</Form.Label>
                    <Form.Control
                        value={formData.sexo || ''}
                        onChange={(e) => handleFormChange('sexo', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está o RG do comprador?</Form.Label>
                    <Form.Control
                        value={formData.rg || ''}
                        onChange={(e) => handleFormChange('rg', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está o telefone para contato do comprador?</Form.Label>
                    <Form.Control
                        value={formData.telefone || ''}
                        onChange={(e) => handleFormChange('telefone', e.target.value)} />
                </Col>
            </Row>
            <Row className="group-form">
                <Col>
                    <Form.Label>Em qual Coluna está a cidade do comprador?</Form.Label>
                    <Form.Control
                        value={formData.cidade_nome || ''}
                        onChange={(e) => handleFormChange('cidade_nome', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está o Bairro do comprador?</Form.Label>
                    <Form.Control
                        value={formData.bairro || ''}
                        onChange={(e) => handleFormChange('bairro', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está o Logradouro do comprador?</Form.Label>
                    <Form.Control
                        value={formData.logradouro || ''}
                        onChange={(e) => handleFormChange('logradouro', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está o complemento do endereço do comprador?</Form.Label>
                    <Form.Control
                        value={formData.complemento || ''}
                        onChange={(e) => handleFormChange('complemento', e.target.value)} />
                </Col>
            </Row>
            <Row className="group-form">
                <Col>
                    <Form.Label>Em qual Coluna está o cep do comprador?</Form.Label>
                    <Form.Control
                        value={formData.cep || ''}
                        onChange={(e) => handleFormChange('cep', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está o numero do endereço do comprador?</Form.Label>
                    <Form.Control
                        value={formData.numero || ''}
                        onChange={(e) => handleFormChange('numero', e.target.value)} />
                </Col>
            </Row>
            <Row className="group-form">
                <Col>
                    <Form.Label>Em qual Coluna está a observacao do comprador?</Form.Label>
                    <Form.Control
                        value={formData.observacao || ''}
                        onChange={(e) => handleFormChange('observacao', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está o id do corretor do comprador?</Form.Label>
                    <Form.Control
                        value={formData.corretor_id || ''}
                        onChange={(e) => handleFormChange('corretor_id', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está o inibe da remessa do comprador?</Form.Label>
                    <Form.Control
                        value={formData.inibe_remessa || ''}
                        onChange={(e) => handleFormChange('inibe_remessa', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está a segurado do comprador?</Form.Label>
                    <Form.Control
                        value={formData.segurado || ''}
                        onChange={(e) => handleFormChange('segurado', e.target.value)} />
                </Col>
            </Row>
            <Row className="group-form">
                <Col>
                    <Form.Label>Em qual Coluna está o negativado do comprador?</Form.Label>
                    <Form.Control
                        value={formData.negativado || ''}
                        onChange={(e) => handleFormChange('negativado', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está a prox. cobrança do comprador?</Form.Label>
                    <Form.Control
                        value={formData.prox_cobranca || ''}
                        onChange={(e) => handleFormChange('prox_cobranca', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está o data de nascimento do comprador?</Form.Label>
                    <Form.Control
                        value={formData.data_nascimento || ''}
                        onChange={(e) => handleFormChange('data_nascimento', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está a regime união do comprador?</Form.Label>
                    <Form.Control
                        value={formData.regime_uniao || ''}
                        onChange={(e) => handleFormChange('regime_uniao', e.target.value)} />
                </Col>
            </Row>
            <Row className="group-form">
                <Col>
                    <Form.Label>Em qual Coluna está a data de fundação do comprador?</Form.Label>
                    <Form.Control
                        value={formData.data_fundacao || ''}
                        onChange={(e) => handleFormChange('data_fundacao', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está o razão social do comprador?</Form.Label>
                    <Form.Control
                        value={formData.razao_social || ''}
                        onChange={(e) => handleFormChange('razao_social', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está a inscrição municipal do comprador?</Form.Label>
                    <Form.Control
                        value={formData.inscricao_municipal || ''}
                        onChange={(e) => handleFormChange('inscricao_municipal', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está a inscrição estadual do comprador?</Form.Label>
                    <Form.Control
                        value={formData.inscricao_estadual || ''}
                        onChange={(e) => handleFormChange('inscricao_estadual', e.target.value)} />
                </Col>
            </Row>
            <Row className="group-form">
                <Col>
                    <Form.Label>Em qual Coluna está o orgão expeditor do RG do comprador?</Form.Label>
                    <Form.Control
                        value={formData.orgao_exp || ''}
                        onChange={(e) => handleFormChange('orgao_exp', e.target.value)} />
                </Col>
            </Row>
        </>
    );
}

export default Comprador;
