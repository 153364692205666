import { Button, FormControl, FormGroup, FormLabel, Modal } from 'react-bootstrap';
import api from '../../../../services/api';
import { useState } from 'react';
import { AlertService } from '../../../../components/AlertService';
import { url } from '../../../../environments/environments-develop';

interface ModalResetBoletoProps {
    showModalResetBoletos: boolean;
    handleShowModalResetBoletos: (show: boolean) => void;
}

const ModalResetBoleto: React.FC<ModalResetBoletoProps> = ({ showModalResetBoletos, handleShowModalResetBoletos }) => {
    const [numeroContrato, setNumeroContrato] = useState('');

    async function resetarBoleto() {
        if (!numeroContrato.trim()) {
            AlertService.error('Erro', 'Por favor, insira o número do contrato.');
            return;
        }
        try {
            const response = await api.post(`${url}/api/v1/admin/financeiro/reset-boleto/${numeroContrato}`);
            if (response.status === 200) {
                AlertService.sucess('Sucesso', 'Boleto resetado com sucesso');
                handleShowModalResetBoletos(false);
                setNumeroContrato('');
            } else {
                AlertService.error('Erro', 'Falha ao resetar o boleto. Tente novamente.');
            }
        } catch (error: any) {
            AlertService.error('Erro', `Ocorreu um erro ao resetar o boleto: ${error.message || error}`);
        }
    }

    return (
        <Modal show={showModalResetBoletos} size='lg' onHide={() => handleShowModalResetBoletos(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Resetar Boleto</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <FormGroup>
                    <FormLabel>Id do Contrato</FormLabel>
                    <FormControl
                        type="text"
                        value={numeroContrato}
                        onChange={(e) => setNumeroContrato(e.target.value)}
                        placeholder="Insira o número do contrato"
                    />
                </FormGroup>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => handleShowModalResetBoletos(false)}>
                    Cancelar
                </Button>
                <Button variant="success" onClick={resetarBoleto}>
                    Confirmar
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ModalResetBoleto;
