import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { emitirContrato } from './GestaoContratosServices';
import { url } from '../../../../environments/environments-develop';
import api from '../../../../services/api';
import { useEffect, useState } from 'react';
import { AlertService } from '../../../../components/AlertService';
import TokenService from '../../../../services/Auth/token.service';
interface ModalEmitirContratoProps {
    showModalEmitirContrato: boolean;
    handleShowModalEmitirContrato: (show: boolean) => void;
    selectedNodeKeys: any;
    formEmissao: {
        numero_contrato?: number;
        modelo_contrato_id?: number;
    };
    onInputChange: (key: string, value: any) => void;
}

const ModalEmitirContrato: React.FC<ModalEmitirContratoProps> = (props) => {
    const [modeloContrato, setModeloContrato] = useState([]);
    useEffect(() => {
        InitModeloContrato();
        props.onInputChange('empreendimento_id', Number(TokenService.getEmpreendimentoId() ? TokenService.getEmpreendimentoId()?.id : null))
    }, []);

    async function InitModeloContrato() {
        try {
            const response = await api.get(`${url}/api/v1/admin/vendas/modelo-contratos`);
            setModeloContrato(response.data.data);
        } catch (error) {
            AlertService.error('Error', 'Ocorreu um erro ao buscar a lista de permissões. Erro:' + String(error));
        }
    }

    return (
        <Modal show={props.showModalEmitirContrato} size='xl' onHide={() => props.handleShowModalEmitirContrato(false)}>
            <Modal.Header closeButton>
                <Modal.Title className='text-center'>Selecione o modelo de contrato para poder prosseguir com a emissão do contrato.</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row className="group-form" style={{ display: 'flex', justifyContent: 'center' }}>
                    <Col md="6">
                        <Form.Label>Modelo de Contrato</Form.Label>
                        <Form.Select value={props.formEmissao.modelo_contrato_id} onChange={(e) => props.onInputChange('modelo_contrato_id', Number(e.target.value))}>
                            <option>Selecione...</option>
                            {modeloContrato?.map((item: any) => (
                                <option value={item.id} key={item.id}>
                                    {item.nome}
                                </option>
                            ))}
                        </Form.Select>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="success" onClick={() => { emitirContrato(props.formEmissao, props.handleShowModalEmitirContrato) }}>Confirmar</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ModalEmitirContrato;
