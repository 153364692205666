import { useEffect, useRef } from "react";
import { handleOpenModal } from '../Service/VendaService';
import { Tooltip } from 'primereact/tooltip';
import { Toast } from 'primereact/toast';
import { SpeedDial } from "primereact/speeddial";
import 'primeicons/primeicons.css';

export const actionTemplateJazigoEstrutura = (
    value: any,
    setSelectedRow: (value: any) => void,
    setShowModalInputInumado: (value: any) => void,
    setShowModalExumacao: (value: any) => void
) => {
    const data = value.data ? value.data : value;
    const toast = useRef<Toast>(null);
    const commonStyle = {
        width: '35px',
        height: '35px',
        top: '0px',
        color: '#fff'
    };

    const items: any[] = [
        {
            label: 'Inputar Inumado',
            icon: 'pi pi-user-plus',
            style: {
                backgroundColor: 'green',
                color: '#fff',
                top: '0px',
                width: '35px',
                height: '35px',
                display: `${data?.inumado_id ? 'none' : 'flex'}`
            },
            command: () => {
                handleOpenModal(data, setSelectedRow, setShowModalInputInumado);
            },
            tooltip: 'Inputar Inumado',
        },
        {
            label: 'Exumar Inumado',
            icon: 'pi pi-user-minus',
            style: {
                backgroundColor: 'red',
                color: '#fff',
                top: '0px',
                width: '35px',
                height: '35px',
                display: `${data?.inumado_id ? 'flex' : 'none'}`
            },
            command: () => {
                handleOpenModal(data, setSelectedRow, setShowModalExumacao);
            },
            tooltip: 'Exumar Inumado',
        }
    ];

    useEffect(() => {
        items.forEach((item, index) => {
            const element = document.querySelectorAll('.p-speeddial-action')[index];
            if (element) {
                element.setAttribute('data-pr-tooltip', item.tooltip);
                element.setAttribute('data-pr-position', 'left');
            }
        });
    }, [items]);

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Toast ref={toast} />
            <Tooltip target="[data-pr-tooltip]" />
            <div style={{ position: 'relative' }}>
                <SpeedDial
                    model={items}
                    radius={45}
                    showIcon="pi pi-ellipsis-v"
                    hideIcon="pi pi-times"
                    type="linear"
                    direction="left"
                    style={{ top: 'calc(40%)', right: 18 }}
                    buttonClassName="button-speed-dial"
                />
            </div>
        </div>
    );
};