import React, { useEffect, useState } from 'react';
import { Modal, Container, Row, Col, Form } from 'react-bootstrap';
import { Button } from 'primereact/button';
import 'react-datepicker/dist/react-datepicker.css';
import { Calendar } from 'primereact/calendar';
import { InputNumber } from 'primereact/inputnumber';
import pt from 'date-fns/locale/pt';
import { addLocale } from 'primereact/api';

interface ModalDataVencimentoProps {
    showModal: boolean;
    onHide: () => void;
    formModalRotina: any;
    handleClose: () => void;
    onInputChange: (field: string, value: any) => void;
}

const ModalDataVencimento: React.FC<ModalDataVencimentoProps> = ({
    showModal,
    onHide,
    formModalRotina,
    onInputChange
}) => {
    const [formData, setformData] = useState<any[]>(formModalRotina || []);

    useEffect(() => {
        setformData(formModalRotina);
    }, [formModalRotina]);

    addLocale('pt', {
        firstDayOfWeek: 0,
        dayNames: ['domingo', 'segunda-feira', 'terça-feira', 'quarta-feira', 'quinta-feira', 'sexta-feira', 'sábado'],
        dayNamesShort: ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sáb'],
        dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
        monthNames: ['janeiro', 'fevereiro', 'março', 'abril', 'maio', 'junho', 'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro'],
        monthNamesShort: ['jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez'],
        today: 'Hoje',
        clear: 'Limpar'
    });

    const handleChange = (index: number, field: string, value: any) => {
        const updatedFormData = [...formData];
        updatedFormData[index] = {
            ...updatedFormData[index],
            [field]: value,
        };
        setformData(updatedFormData);
    };

    const confirmar = () => {
        onInputChange('parcelas', formData)
        onHide();
    };
    const cancelar = () => {
        onInputChange('parcelas', []);
        onInputChange('num_parcela', null);
        onHide();
    };

    return (
        <Modal
            show={showModal}
            onHide={onHide}
            size="lg"
            backdrop="static"
            keyboard={false}
            className="modal-filtro-avancado"
        >
            <Modal.Header>
                <Modal.Title>Parcelas</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container>
                    {formData && formData.map((col: any, i: number) => (
                        <Row key={i} style={{ marginBottom: '14px' }}>
                            <Col md="2">
                                <label style={{ fontSize: "13px", fontWeight: "bold" }}>{`${i + 1}° Parcela`}</label>
                            </Col>
                            <Col md="3" style={{ display: 'grid', flexDirection: 'column' }}>
                                <Form.Label>Data de Vencimento</Form.Label>
                                <Calendar
                                    value={new Date(col.date)} // Garante que a data é um objeto Date
                                    onChange={(event: any) => {
                                        const selectedDate = event.value ? event.value.toISOString() : null;
                                        handleChange(i, 'date', selectedDate);
                                    }}
                                    showButtonBar
                                    selectionMode="single"
                                    dateFormat="dd/mm/yy"
                                    locale="pt"
                                />
                            </Col>
                            <Col style={{ display: 'flex', flexDirection: 'column' }}>
                                <Form.Label>Valor da Parcela</Form.Label>
                                <InputNumber
                                    value={col.valor_original}
                                    onChange={(e) => handleChange(i, 'valor_original', e.value)}
                                    minFractionDigits={2}
                                    maxFractionDigits={24}
                                    style={{ width: '100%', height: '38px' }}
                                    locale="pt-BR"
                                />
                            </Col>
                        </Row>
                    ))}
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    style={{ height: '30px', borderRadius: '20px', padding: '5px 10px', marginRight: '5px', fontSize: '14px' }}
                    type="button"
                    severity="success"
                    icon="pi pi-plus"
                    rounded
                    onClick={() => confirmar()}
                >
                    <p style={{ marginLeft: '5px', marginTop: '13px' }}>Confirmar</p>
                </Button>
                <Button
                    style={{ height: '30px', borderRadius: '20px', padding: '5px 10px', marginRight: '5px', fontSize: '14px' }}
                    type="button"
                    severity="danger"
                    icon="pi pi-delete-left"
                    rounded
                    onClick={() => cancelar()}
                >
                    <p style={{ marginLeft: '5px', marginTop: '13px' }}>Cancelar</p>
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ModalDataVencimento;
