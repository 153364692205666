// ProfissaoModel.ts

export interface IProfissao {
    id: number | null;
    descricao: string | null;
    codigo_cbo: string | null;
}

export class ProfissaoModel implements IProfissao {
    id: number | null = null;
    descricao: string | null = null;
    codigo_cbo: string | null = null;

    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
      }
}
