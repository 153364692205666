import { CessaoDeDireitoModel } from './../Models/CessaoDeDireitoModel';
import axios from "../../../services/api";
import { Observable, from, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { url } from "../../../environments/environments-develop";

interface IApiResponse<T> {
  data: T;
  headers: {
    titles: string[];
  };
  meta: any;
}

class CessaoDeDireitoService {
  private resourceUrl = url + "/api/v1/admin/carteira/contrato-transf";

  list(
    params: any = {},
    headers: any = null,
    updateTitles: (titles: string[]) => void,
    updatePagination: (totalPages: number) => void
  ): Observable<CessaoDeDireitoModel.CessaoDeDireitoModel[]> {
    return from(
      axios
        .get<IApiResponse<CessaoDeDireitoModel.ICessaoDeDireito[]>>(
          this.resourceUrl,
          {
            params,
            headers,
          }
        )
        .then((response) => {
          updateTitles(response.data.headers.titles);
          updatePagination(response.data.meta.pagination.total_pages);
          return response.data.data.map((item) =>
            CessaoDeDireitoModel.CessaoDeDireitoModel.deserialize(item)
          );
        })
    ).pipe(catchError((error) => throwError(error)));
  }

  get(
    id: number,
    params: any = {},
    headers: any = null
  ): Observable<CessaoDeDireitoModel.CessaoDeDireitoModel> {
    return from(
      axios
        .get<CessaoDeDireitoModel.ICessaoDeDireito>(
          `${this.resourceUrl}/${id}`,
          {
            params,
            headers,
          }
        )
        .then((response) =>
          CessaoDeDireitoModel.CessaoDeDireitoModel.deserialize(response.data)
        )
    ).pipe(catchError((error) => throwError(error)));
  }

  create(
    data: any,
    params: any = {},
    headers: any = null
  ): Observable<CessaoDeDireitoModel.CessaoDeDireitoModel> {
    return from(
      axios
        .post<CessaoDeDireitoModel.ICessaoDeDireito>(this.resourceUrl, data, {
          params,
          headers,
        })
        .then((response) =>
          CessaoDeDireitoModel.CessaoDeDireitoModel.deserialize(response.data)
        )
    ).pipe(catchError((error) => throwError(error)));
  }

  update(
    id: number,
    data: any,
    params: any = {},
    headers: any = null
  ): Observable<CessaoDeDireitoModel.CessaoDeDireitoModel> {
    return from(
      axios
        .put<CessaoDeDireitoModel.ICessaoDeDireito>(
          `${this.resourceUrl}/${id}`,
          data,
          {
            params,
            headers,
          }
        )
        .then((response) =>
          CessaoDeDireitoModel.CessaoDeDireitoModel.deserialize(response.data)
        )
    ).pipe(catchError((error) => throwError(error)));
  }

  excluir(params: any = {}, headers: any = null): Observable<any> {
    return from(
      axios
        .post(`${this.resourceUrl}/destroy-all`, { ids: params }, { headers })
        .then((response) => response.data)
    ).pipe(catchError((error) => throwError(error)));
  }
}

export default new CessaoDeDireitoService();
