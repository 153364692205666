import { Col, Form, InputGroup, Row } from "react-bootstrap";
interface PropsData {
    Data: {
        rotulo?: string;
        valor_m2?: string;
        status?: boolean;
    };
    initialRightValues: any;
    onInputChange: (field: string, value: any) => void;
}
function ClassificacaoImovelForm(props: PropsData) {
    const { rotulo, valor_m2 } = props.Data || {};
    const handleChange = (field: string, value: any) => {
        props.onInputChange(field, value);
    };


    return (
        <>
            <Form.Group className="forms" controlId="validationCustomUsername">
                <Row className="mb-3">
                    <Col md="2">
                        <Form.Label className="mb-0">Rótulo</Form.Label>
                        <InputGroup hasValidation>
                            <Form.Control
                                required
                                value={rotulo || ''}
                                onChange={(e) => handleChange("rotulo", e.target.value)}
                                onBlur={(e) => handleChange("rotulo", e.target.value)}
                            />
                            <Form.Control.Feedback type="invalid">
                                Este campo é Obrigatório.
                            </Form.Control.Feedback>
                        </InputGroup>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col md="2">
                        <Form.Label className="mb-0">Valor por m²</Form.Label>
                        <Form.Control value={valor_m2 || ''} onChange={(e) => handleChange("valor_m2", e.target.value)} />
                    </Col>
                </Row>
            </Form.Group>
        </>
    );
}

export default ClassificacaoImovelForm;
