import { useEffect, useState } from "react";
import { Col, Form, InputGroup, Row } from "react-bootstrap";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import CalendarIcon from '../../../../../assets/images/icon-calendar.png';
import pt from "date-fns/locale/pt";
import { InputNumber } from "primereact/inputnumber";
import { AlertService } from "../../../../components/AlertService";
import { url } from "../../../../environments/environments-develop";
import api from '../../../../services/api';
import { Calendar } from "primereact/calendar";
import { addLocale } from "primereact/api";
interface PropsData {
    secaoParcelamentoData: {
        tabela_preco_id?: string;
        valor_parcelas?: string;
        data_prim_parcela?: string;
    };
    onInputChange: (field: string, value: any) => void;
}

function SecaoParcelamento(props: PropsData) {
    const { tabela_preco_id, valor_parcelas } = props.secaoParcelamentoData || {};
    const [data_prim_parcela, setPrimeiroVencimentoImovel] = useState<Date | null>(null);
    const [listTabelaPreco, setListTabelaPreco] = useState([]);
    const handleChange = (field: string, value: string) => {
        props.onInputChange(field, value);
    };
    useEffect(() => {
        setPrimeiroVencimentoImovel(props.secaoParcelamentoData.data_prim_parcela ? new Date(props.secaoParcelamentoData.data_prim_parcela) : null);
    }, [props.secaoParcelamentoData])
    async function InitCompradores() {
        try {
            const response = await api.get(`${url}/api/v1/admin/vendas/tabelapreco`);
            setListTabelaPreco(response.data.data);
        } catch (error) {
            AlertService.error('Error', 'Ocorreu um erro ao buscar a lista de permissões. Erro:' + String(error));
        }
    }
    addLocale('pt', {
        firstDayOfWeek: 0,
        dayNames: ['domingo', 'segunda-feira', 'terça-feira', 'quarta-feira', 'quinta-feira', 'sexta-feira', 'sábado'],
        dayNamesShort: ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sáb'],
        dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
        monthNames: ['janeiro', 'fevereiro', 'março', 'abril', 'maio', 'junho', 'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro'],
        monthNamesShort: ['jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez'],
        today: 'Hoje',
        clear: 'Limpar'
    });
    useEffect(() => {
        InitCompradores();
    }, []);
    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            flex: '1',
            height: '-webkit-fill-available',
            marginRight: '16px',
            marginLeft: '16px',
            minHeight: '350px'
        }}>
            <Row className="mb-3">
                <Col md={3} style={{ display: 'flex', flexDirection: 'column' }}>
                    <Form.Label>Parcelas</Form.Label>
                    <InputGroup>
                        <Form.Select isInvalid={!tabela_preco_id} value={tabela_preco_id} onChange={(e) => handleChange('tabela_preco_id', e.target.value)}>
                            <option>Selecione...</option>
                            {listTabelaPreco?.map((item: any, key: any) => (
                                <option key={key} value={item.id}>
                                    {item.num_parcela}
                                </option>
                            ))}
                        </Form.Select>
                    </InputGroup>
                </Col>
                <Col md={3} style={{ display: 'flex', flexDirection: 'column' }}>
                    <Form.Label>Valor da Parcela</Form.Label>
                    <InputNumber
                        disabled
                        style={{ width: '100%', height: '38px' }}
                        value={valor_parcelas ? Number(valor_parcelas) : 0}
                        onChange={(e: any) => handleChange('valor_parcelas', e.value)}
                        mode="currency" currency="BRL" locale="pt-BR"
                    />
                </Col>
                <Col md={3} style={{ display: 'flex', flexDirection: 'column' }}>
                    <Form.Label>Vencimento 1ª Parcela do Jazigo</Form.Label>
                    <Calendar
                        invalid={!data_prim_parcela}
                        value={data_prim_parcela || null}
                        onChange={(event: any) => {
                            const selectedDates = event.value;
                            setPrimeiroVencimentoImovel(selectedDates);
                            handleChange('data_prim_parcela', selectedDates ? selectedDates.toISOString() : null);
                        }}
                        placeholder="Vencimento 1ª Parcela do Jazigo"
                        showButtonBar
                        selectionMode="single"
                        dateFormat="dd/mm/yy"
                        locale="pt"
                        readOnlyInput
                        style={{ textAlign: 'center', width: '100%' }}
                        panelClassName="custom-calendar-panel"
                        required
                    />
                </Col>
            </Row>
        </div>
    );
}

export default SecaoParcelamento;
