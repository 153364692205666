import iconMenuTresPonto from '../../../../assets/images/icon-menu-tres-pontos.png';
import { Button } from 'primereact/button';
import { useEffect, useState } from 'react';
import { useSidebarContext } from '../../../contexts/SidebarContext';
import { Col, Form, InputGroup, Modal, NavLink, Row } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import CalendarIcon from '../../../../assets/images/icon-calendar.png';
import pt from 'date-fns/locale/pt';
import 'react-autocomplete-input/dist/bundle.css';
import { AlertService } from '../../../components/AlertService';
import TokenService from '../../../services/Auth/token.service';
import { useAppContext } from '../../../contexts/AppContext';
import { url, url_financeiro } from '../../../environments/environments-develop';
import api from '../../../services/api';
import { abrirPDF } from '../../../utils/PdfUtils';
import { AutoComplete } from 'primereact/autocomplete';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import ButtonClose from 'react-bootstrap/Button';
import { MDBCol, MDBPageItem, MDBPageNav, MDBPagination, MDBRow } from 'mdbreact';
import { showToast } from '../../../components/ToastComponent';
import { searchCliente } from '../../Financeiro/MovCaixa/Service/MovCaixaService';
import { Calendar } from 'primereact/calendar';
import { addLocale } from 'primereact/api';
import Paginacao from './Components/Paginacao';
import { useAppContext as useResponseContext } from '../../../contexts/ResponseContext';
import { loadData } from './Services/RelatorioService';

function ContaCorrenteClienteForm() {
  const [listFiltros, setListFiltros] = useState<any>({});
  const [nomeCliente, setNomeCliente] = useState<string>();
  const [nodes, setNodes] = useState<any | null>(null);
  const [totalPages, setTotalPages] = useState(0);
  const { isVisible, updateVisibility } = useSidebarContext();
  const [dataInicio, setDataInicio] = useState<string | null>(null);
  const [dataFim, setDataFim] = useState<string | null>(null);
  const { showSubSidebar, setItemsBreadsCrumbs } = useAppContext();
  const [FilteredCliente, setFilteredCliente] = useState<any>(null);
  const [nome, setNome] = useState<string>('');
  const [showModalListLancamento, setShowModalListLancamento] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const { titles, updateTitles, pagination, updatePagination } = useResponseContext();
  const [itemsPerPage, setItemsPerPage] = useState<number>(8);
  const consultaPorPeriodo = [
    { label: 'Vencimento', value: 'data_vencimento' },
    { label: 'Pagamento', value: 'data_pagamento' },
    { label: 'Baixa', value: 'data_baixa' },
    { label: 'Emissão', value: 'data_emissao' },
    { label: 'Credito', value: 'data_credito' },
    { label: 'Criação', value: 'created_at' },
    { label: 'Alteração', value: 'updated_at' },
  ];
  const tipoLancamento = [
    { label: 'Contas à Receber', value: 0 },
    { label: 'Contas à Pagar', value: 1 },
  ];
  const getTipoParcela = [
    { label: 'Ordinaria', value: 0 },
    { label: 'Avulsa', value: 1 },
    { label: 'Agrupada', value: 2 },
  ];

  const getSituacaoPagamento = [
    { label: 'Aberto', value: 0 },
    { label: 'Baixado', value: 1 },
    { label: 'Inadimplente', value: 2 },
  ];

  const toggleVisibility = () => {
    const newValue = !isVisible;
    updateVisibility(newValue);
  };

  useEffect(() => {
    setItemsBreadsCrumbs([
      {
        label: 'Carteira',
        url: '/carteira',
      },
      {
        label: 'Relatório Conta Corrente',
        url: '/conta/corrente',
      }
    ]);
  }, []);

  addLocale('pt', {
    firstDayOfWeek: 0,
    dayNames: ['domingo', 'segunda-feira', 'terça-feira', 'quarta-feira', 'quinta-feira', 'sexta-feira', 'sábado'],
    dayNamesShort: ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sáb'],
    dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
    monthNames: ['janeiro', 'fevereiro', 'março', 'abril', 'maio', 'junho', 'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro'],
    monthNamesShort: ['jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez'],
    today: 'Hoje',
    clear: 'Limpar'
  });
  useEffect(() => {
    loadData(listFiltros, itemsPerPage, currentPage, setNodes, updateTitles, updatePagination, setNomeCliente, setShowModalListLancamento);
  }, [currentPage, itemsPerPage]);

  async function gerarPDF(): Promise<void> {
    try {
      if (!listFiltros?.numero_contrato && !listFiltros?.cliente_id && !listFiltros?.fornecedor_id && !listFiltros?.unidade) {
        showToast('error', 'Selecione um contrato ou cliente ou unidade');
        return;
      }
      if (listFiltros?.tipo_consulta && !listFiltros?.data_inicial && !listFiltros?.data_final) {
        showToast('error', 'Se selecionado o tipo de consulta deve-se selecionar uma data de inicio e de fim');
        return;
      }
      if (listFiltros?.data_inicial && listFiltros.data_final && !listFiltros.tipo_consulta) {
        showToast('error', 'Se selecionado uma data de inicio e de fim, o tipo de consulta deve ser selecionado');
        return;
      }
      if (listFiltros?.data_inicial && !listFiltros.data_final) {
        showToast('error', 'Se selecionado uma data de inicio deve-se selecionar uma data de fim e o tipo de consulta');
        return;
      }
      if (!listFiltros?.data_inicial && listFiltros.data_final) {
        showToast('error', 'Se selecionado uma data de fim deve-se selecionar uma data de inicio e o tipo de consulta');
        return;
      }
      const consulta: any = {
        consulta: {
          order: "data_vencimento;asc",
          filtro: {
            numero_contrato: listFiltros?.numero_contrato || null,
            situacao_pagamento: 1,
            unidade: listFiltros?.unidade,
            cliente_id: listFiltros?.cliente_id,
            fornecedor_id: listFiltros?.fornecedor_id,
            tipo_consulta: listFiltros?.tipo_consulta,
            data_inicial: listFiltros.data_inicial,
            data_final: listFiltros.data_final,
            tipo_lancamento: 3,
            campos: [
              "venda_id",
              "numero_documento",
              listFiltros?.cliente_id ? null : "cliente_id",
              "tipo",
              "data_vencimento",
              "data_pagamento",
              "valor_original",
              "valor_pago",
              "justificativa"
            ].filter(campo => campo !== null),
            tipo_relatorio: 1,
            empreendimento_id: TokenService.getEmpreendimentoId()?.id || null
          }
        }
      };


      const queryParams: any = {};

      const camposEnviar = ['consulta'];

      camposEnviar.forEach((key: any) => {
        const value = consulta[key];
        if (value !== undefined && value !== null && value !== '') {
          if (key === 'consulta') {
            queryParams[key] = JSON.stringify(value);
          } else {
            queryParams[key] = value;
          }
        }
      });

      const queryString = Object.entries(queryParams)
        .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(String(value))}`)
        .join('&');
      const response = await api.get(`${url}/api/v1/admin/financeiro/gerador-arquivo-relatorio?${queryString}`, {
        responseType: 'arraybuffer',
      });
      if (response.status === 200) {
        handleOpenPDF(response.data, listFiltros);
      }

    } catch (error: any) {
      AlertService.error('Erro!', String(error.response?.data?.message) || 'Erro desconhecido');
    }
  }

  const handleOpenPDF = (pdfBase64: string, listFiltros: any) => {
    abrirPDF(pdfBase64, listFiltros);
  }

  async function search(event: any) {
    // Timeout to emulate a network connection
    let _FilteredPessoa: any;

    if (event.query) {
      try {
        _FilteredPessoa = await api.get(`${url}/api/v1/admin/financeiro/cliente/buscarClienteList/${event.query.toLowerCase()}`);
      } catch (error) {
        console.error('Erro ao buscar cliente:', error);
        _FilteredPessoa = [];
      }
    } else {
      _FilteredPessoa = [];
    }
    setFilteredCliente(_FilteredPessoa?.data?.data);
  }

  const handleChangeCliente = (field: string, value: any) => {
    handleChange('cliente_nome', value.nome);
    handleChange(field, value.id);
  }
  const handleChange = (field: string, value: any) => {
    setListFiltros((prevGrupoData: any) => {
      return {
        ...prevGrupoData,
        [field]: value
      };
    });
  };
  const handleClearList = () => {
    setListFiltros({});
    setDataFim(null);
    setDataInicio(null);
  }

  return (
    <div className="body-content">
      <div
        className={`${showSubSidebar ? "container-section-header" : "container-section-header-none"}`}
      >
        <div className="contentSearch">
          <div className="contentSearch-header">
            <NavLink onClick={toggleVisibility}>
              <img
                className="menu-context"
                src={iconMenuTresPonto}
              />
            </NavLink>
            <Col className='text-center' style={{ fontSize: '20px', color: '#494949' }}>Relatório Conta Corrente</Col>
          </div>
          <div style={{ opacity: '34%' }} className="contentTraçoBodyHeader"></div>
          <Form.Group className="imovel-form" style={{ marginLeft: '22px' }}>
            <Row className="mb-3">
              <Col md={2} style={{ display: 'flex', flexDirection: 'column' }}>
                <Form.Label>Número do Contrato</Form.Label>
                <Form.Control placeholder='Número do contrato' value={listFiltros.numero_contrato || ''} onChange={(e) => handleChange("numero_contrato", e.target.value)} />
              </Col>
              <Col md={2} style={{ display: 'flex', flexDirection: 'column' }}>
                <Form.Label>Cliente|Favorecido</Form.Label>
                <AutoComplete
                  field="nome"
                  style={{ height: "37px" }}
                  value={listFiltros.nome_cliente ? listFiltros.nome_cliente : listFiltros.nome}
                  suggestions={FilteredCliente}
                  completeMethod={(e) => searchCliente(e, setFilteredCliente)}
                  onChange={(e) => {
                    setListFiltros({ ...listFiltros, nome: e.value })
                    handleChange('nome', e.value)
                  }}
                  onSelect={(e) => {
                    if (e?.value?.cliente_id) {
                      handleChange('cliente_id', e.value.cliente_id)
                      setListFiltros({ ...listFiltros, cliente_id: e.value.cliente_id, nome: e.value })
                    } else if (e?.value?.fornecedor_id) {
                      handleChange('fornecedor_id', e.value.fornecedor_id)
                      setListFiltros({ ...listFiltros, fornecedor_id: e.value.fornecedor_id, nome: e.value })
                    } else {
                      handleChange('pessoa_id', e.value.id)
                      setListFiltros({ ...listFiltros, cliente_id: e.value.id, nome: e.value })
                    }
                  }}
                  dropdown
                />
              </Col>
              <Col md={2} style={{ display: 'flex', flexDirection: 'column' }}>
                <Form.Label>Unidade</Form.Label>
                <Form.Control value={listFiltros.unidade || ''} onChange={(e) => handleChange("unidade", e.target.value)} />
              </Col>
              <Col md={2} style={{ display: 'flex', flexDirection: 'column' }}>
                <Form.Label>Tipo de data</Form.Label>
                <Form.Select
                  value={listFiltros.tipo_consulta || ''}
                  onChange={(e) => handleChange('tipo_consulta', e.target.value || null)}
                >
                  <option value="">Selecione...</option>
                  {consultaPorPeriodo.map((item: any) => (
                    <option key={item.value} value={item.value}>{item.label}</option>
                  ))}
                </Form.Select>
              </Col>
              <Col md={2} style={{ display: 'flex', flexDirection: 'column' }}>
                <Form.Label>Data de Inicio</Form.Label>
                <Calendar
                  value={dataInicio ? new Date(dataInicio) : null}
                  onChange={(event: any) => {
                    const selectedDates = event.value;
                    setDataInicio(selectedDates);
                    setListFiltros({ ...listFiltros, data_inicial: selectedDates });
                  }}
                  placeholder="Data Inicio"
                  showButtonBar
                  selectionMode="single"
                  dateFormat="dd/mm/yy"
                  locale="pt"
                  readOnlyInput
                  style={{ textAlign: 'center', width: '100%' }}
                  panelClassName="custom-calendar-panel"
                />
              </Col>
              <Col md={2} style={{ display: 'flex', flexDirection: 'column' }}>
                <Form.Label>Data de Fim</Form.Label>
                <Calendar
                  value={dataFim ? new Date(dataFim) : null}
                  onChange={(event: any) => {
                    const selectedDates = event.value;
                    setDataFim(selectedDates);
                    setListFiltros({ ...listFiltros, data_final: selectedDates });
                  }}
                  placeholder="Data Inicio"
                  showButtonBar
                  selectionMode="single"
                  dateFormat="dd/mm/yy"
                  locale="pt"
                  readOnlyInput
                  style={{ textAlign: 'center', width: '100%' }}
                  panelClassName="custom-calendar-panel"
                />
              </Col>
            </Row>
          </Form.Group>
          <div className='div-filtros-avancados'>
            <Button
              onClick={() => loadData(listFiltros, itemsPerPage, currentPage, setNodes, updateTitles, updatePagination, setNomeCliente, setShowModalListLancamento)}
              style={{ height: '30px', marginBottom: '13px', borderRadius: '20px', padding: '5px 10px', marginRight: '5px', fontSize: '14px' }}
              type="button"
              severity="success"
              icon="pi pi-filter"
              rounded>
              <p style={{ marginLeft: '5px', marginTop: '13px' }}>Buscar</p>
            </Button>
            <Button
              onClick={() => handleClearList()}
              style={{ height: '30px', marginBottom: '13px', borderRadius: '20px', padding: '5px 10px', marginRight: '5px', fontSize: '14px' }}
              type="button"
              severity="danger"
              icon="pi pi-delete-left"
              rounded>
              <p style={{ marginLeft: '5px', marginTop: '13px' }}>Limpar</p>
            </Button>
          </div>
        </div>
      </div>
      <Modal show={showModalListLancamento} size='xl' style={{ padding: '0px !important' }} onHide={() => setShowModalListLancamento(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Conta Corrente</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ padding: '0px !important', display: 'flex', flexDirection: 'column', alignContent: 'center', justifyContent: 'center', justifyItems: 'center' }}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <h6>{nomeCliente}</h6>
            <p>
              N° Contrato: {listFiltros?.numero_contrato ? listFiltros?.numero_contrato : 'Todos'} <br />
              Data: {listFiltros?.data_inicial && listFiltros?.data_final ? `${listFiltros.data_inicial} à ${listFiltros.data_final}` : 'Todo o Período'}
            </p>
          </div>
          <DataTable
            stripedRows
            lazy
            value={nodes ? nodes : []}
            tableStyle={{ minWidth: '50rem' }}
            emptyMessage={<span style={{ display: 'flex', justifyContent: 'center', fontStyle: 'italic' }}>Nenhum item encontrado.</span>}
          >
            {!listFiltros?.numero_contrato && <Column
              key={'venda_id'} field={'venda_id'} header={'N° Contrato'}
              style={{ textAlign: 'center', borderBottom: '1px solid #bdbdbd96' }}
            />}
            <Column
              key={'numero_documento'} field={'numero_documento'} header={'Documento'}
              style={{ textAlign: 'center', borderBottom: '1px solid #bdbdbd96' }}
            />
            <Column
              key={'data_vencimento_format'} field={'data_vencimento_format'} header={'Vencimento'}
              style={{ textAlign: 'center', borderBottom: '1px solid #bdbdbd96' }}
            />
            <Column
              key={'data_pagamento_format'} field={'data_pagamento_format'} header={'Dt.Pgto'}
              style={{ textAlign: 'center', borderBottom: '1px solid #bdbdbd96' }}
            />
            <Column
              key={'valor_original_format'} field={'valor_original_format'} header={'Valor'}
              style={{ textAlign: 'center', borderBottom: '1px solid #bdbdbd96' }}
            />
            <Column
              key={'valor_pago_format'} field={'valor_pago_format'} header={'Valor Pago'}
              style={{ textAlign: 'center', borderBottom: '1px solid #bdbdbd96' }}
            />
            <Column
              key={'justificativa'} field={'justificativa'} header={'Descrição'}
              style={{ textAlign: 'center', borderBottom: '1px solid #bdbdbd96' }}
            />
          </DataTable>
          <Paginacao
            totalPages={pagination}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            itemsPerPage={itemsPerPage}
            setItemsPorPagina={setItemsPerPage}
          />
        </Modal.Body>
        <Modal.Footer>
          <ButtonClose variant="success" onClick={() => { setShowModalListLancamento(false) }}>Fechar</ButtonClose>
          <ButtonClose variant="success" onClick={() => gerarPDF()}> Gerar PDF </ButtonClose>
        </Modal.Footer>
      </Modal>

    </div>
  );
}

export default ContaCorrenteClienteForm;