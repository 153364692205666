import { useState, useEffect } from 'react';
import { Col, Row, Table, Modal, Button } from 'react-bootstrap';
import { AlertService } from '../../../../components/AlertService';
import { url } from '../../../../environments/environments-develop';
import api from '../../../../services/api';

function ModalListagem({ showModal, handleShowModal }: any) {
   
    const fakeTableData = [
        { id: 1, nome: 'Contrato A', valor: 1000, status: 'Pendente' },
        { id: 2, nome: 'Contrato B', valor: 2000, status: 'Pago' },
        { id: 3, nome: 'Contrato C', valor: 1500, status: 'Vencido' },
    ];

    function formatCurrency(value: any) {
        return Number(value).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    };

    return (
        <Modal show={showModal} onHide={() => handleShowModal(false)} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Listagem de Importações</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Table striped bordered hover className="mt-4">
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Nome</th>
                            <th>Valor</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {fakeTableData.map((item) => (
                            <tr key={item.id}>
                                <td>{item.id}</td>
                                <td>{item.nome}</td>
                                <td>{formatCurrency(item.valor)}</td>
                                <td>{item.status}</td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => handleShowModal(false)}>
                    Fechar
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default ModalListagem;
