import axios from "../../../services/api";
import { Observable, from, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { url } from "../../../environments/environments-develop";
import { TipoJazigoModel } from "../Models/TipoJazigoModel";

interface IApiResponse<T> {
  data: T;
  headers: {
    titles: string[];
  };
  meta: any;
}

class TipoJazigoService {
  private resourceUrl = url + "/api/v1/admin/cemiterio/tipo-jazigo";

  list(
    params: any = {},
    headers: any = null
  ): Observable<TipoJazigoModel.TipoJazigo[]> {
    return from(
      axios
        .get<IApiResponse<TipoJazigoModel.ITipoJazigo[]>>(this.resourceUrl, {
          params,
          headers,
        })
        .then((response) => {
          return response.data.data.map((item) =>
            TipoJazigoModel.TipoJazigo.deserialize(item)
          );
        })
    ).pipe(catchError((error) => throwError(error)));
  }

  listAll(
    params: any = {},
    headers: any = null,
    updateTitles: (titles: string[]) => void,
    updatePagination: (totalPages: number) => void
  ): Observable<TipoJazigoModel.TipoJazigo[]> {
    return from(
      axios
        .get<IApiResponse<TipoJazigoModel.ITipoJazigo[]>>(this.resourceUrl, {
          params,
          headers,
        })
        .then((response) => {
          updateTitles(response.data.headers.titles);
          updatePagination(response.data.meta.pagination.total_pages);
          return response.data.data.map((item) =>
            TipoJazigoModel.TipoJazigo.deserialize(item)
          );
        })
    ).pipe(catchError((error) => throwError(error)));
  }

  get(
    id: number,
    params: any = {},
    headers: any = null
  ): Observable<TipoJazigoModel.TipoJazigo> {
    return from(
      axios
        .get<TipoJazigoModel.ITipoJazigo>(`${this.resourceUrl}/${id}`, {
          params,
          headers,
        })
        .then((response) =>
          TipoJazigoModel.TipoJazigo.deserialize(response.data)
        )
    ).pipe(catchError((error) => throwError(error)));
  }

  create(
    data: any,
    params: any = {},
    headers: any = null
  ): Observable<TipoJazigoModel.TipoJazigo> {
    return from(
      axios
        .post<TipoJazigoModel.ITipoJazigo>(this.resourceUrl, data, {
          params,
          headers,
        })
        .then((response) =>
          TipoJazigoModel.TipoJazigo.deserialize(response.data)
        )
    ).pipe(catchError((error) => throwError(error)));
  }

  update(
    id: number,
    data: any,
    params: any = {},
    headers: any = null
  ): Observable<TipoJazigoModel.TipoJazigo> {
    return from(
      axios
        .put<TipoJazigoModel.ITipoJazigo>(`${this.resourceUrl}/${id}`, data, {
          params,
          headers,
        })
        .then((response) =>
          TipoJazigoModel.TipoJazigo.deserialize(response.data)
        )
    ).pipe(catchError((error) => throwError(error)));
  }

  excluir(params: any = {}, headers: any = null): Observable<any> {
    return from(
      axios
        .post(`${this.resourceUrl}/destroy-all`, { ids: params }, { headers })
        .then((response) => response.data)
    ).pipe(catchError((error) => throwError(error)));
  }
}

export default new TipoJazigoService();
