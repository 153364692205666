export function abrirPDF(pdf, listFiltros) {
    var typeArchive = listFiltros.tipo_relatorio;
    var file = null;
    if (typeArchive == 2) {
      file = new Blob([pdf], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
    } else {
      file = new Blob([pdf], { type: 'application/pdf' });
    }
    const fileURL = URL.createObjectURL(file);
    window.open(fileURL, '_blank');
  }