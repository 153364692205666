import React, { useState, useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap';
import TokenService from '../../../../../../services/Auth/token.service';
import { AlertService } from '../../../../../../components/AlertService';
import '../index.css';
import { url } from '../../../../../../environments/environments-develop';
import api from '../../../../../../services/api';
import { Steps } from 'primereact/steps';
import EditVendaForm from '../Formularios/EditVendaForm';

interface ModalEditVendaContratoProps {
    vendaContrato: any;
    showModal: boolean;
    handleClose: () => void;
}

const ModalEditVendaContrato: React.FC<ModalEditVendaContratoProps> = ({ vendaContrato, showModal, handleClose }) => {
    const [show, setShow] = useState(showModal);
    const [currentPart, setCurrentPart] = useState(0)
    const totalParts = 4;
    const [formBasico, setFormBasico] = useState<any>({});
    const [valorAcessorios, setValorAcessorios] = useState<any>(0);
    useEffect(() => {
        setShow(showModal);
    }, [showModal])
    useEffect(() => {
        const totalValorAcessorios = (formBasico?.produto_servicos || []).reduce((acc: number, form: any) => {
            if (form?.produto) {
                return acc + ((form?.quantidade ?? 1) * (form?.produto?.valor ?? 0)) - (form?.tipo_desconto == 1 ? (form?.desconto / 100 * ((form?.produto?.valor ?? 0) * (form?.quantidade ?? 1))) : (form?.desconto * (form?.quantidade ?? 1)));
            }
            return acc;
        }, 0);

        setValorAcessorios(totalValorAcessorios);
    }, [formBasico?.produto_servicos]);
    const setSessionEditData = async (value: any) => {
        try {
            let include = 'compradores,imovel,corretor';
            const response = await api.get(`${url}/api/v1/admin/cemiterio/contrato-jazigo/${value}?include=${include}`);
            if (response.status === 200) {
                setFormBasico((prevGrupoData: any) => {
                    const dadosAtualizados = {
                        ...prevGrupoData,
                        ...response.data,
                        cliente_compradores: response.data?.cliente_compradores?.data
                    };
                    return dadosAtualizados;
                });
            }
        } catch (error: any) {
            let mensagens: string[] = [];
            mensagens.push(error?.response?.data?.error?.message);
            AlertService.error('Erro!', String(mensagens));
        }
    };
    useEffect(() => {
        if (vendaContrato) {
            try {
                setSessionEditData(vendaContrato.id)
            } catch (error) {
                AlertService.error("error", String(error))
            }
        }

    }, [vendaContrato]);

    const handleNext = () => {
        if (currentPart < totalParts) {
            setCurrentPart((prevPart) => prevPart + 1);
        }
    };

    const handleChange = (field: string, value: any) => {
        setFormBasico((prevGrupoData: any) => {
            return {
                ...prevGrupoData,
                [field]: value
            };
        });
    }

    const handleBack = () => {
        if (currentPart > 0) {
            setCurrentPart((prevPart) => prevPart - 1);
        }
    };

    const handleSaveButton = async (): Promise<void> => {
        try {
            let data = {
                "data_venda": formBasico?.data_venda ? new Date(formBasico?.data_venda) : new Date().toUTCString(),
                "numero": formBasico?.numero ?? null,
                "imovel_id": formBasico?.imovel_id ?? null,
                "entrada": formBasico?.entrada ?? null,
                "tabela_preco_id": formBasico?.tabela_preco_id ?? null,
                "anotacoes": formBasico?.anotacoes ?? null,
                "valor_desconto": Number(formBasico?.valor_desconto) ?? null,
                "justificativa_desc": formBasico?.justificativa_desc ?? null,
                "status": 1,
                "tipo_contrato": 1,
                "data_prim_parcela": formBasico?.data_prim_parcela ? new Date(formBasico?.data_prim_parcela) : null,
                "venc_prim_parc_entrada": formBasico?.venc_prim_parc_entrada ? new Date(formBasico?.venc_prim_parc_entrada) : null,
                "valor_prim_parc_entrada": formBasico?.valor_prim_parc_entrada ?? null,
                "qtd_parc_entrada": formBasico?.qtd_parc_entrada ?? 0,
                "coordenador_id": formBasico?.coordenador_id ?? null,
                "corretor_id": formBasico?.corretor_id ?? null,
                "tipo_garantia": formBasico?.tipo_garantia ?? null,
                "clausula_especial": null,
                "gerou_lancamento": TokenService.getEmpreendimentoId()?.gerar_parcelas_contas_receber ?? false,
                "indice_reajuste_id": null,
                "periodicidade_reajuste": 12,
                "empreendimento_id": TokenService.getEmpreendimentoId()?.id,
                "cliente_compradores": formBasico?.cliente_compradores ?? [],
                "data_prim_manutencao": formBasico?.data_prim_manutencao ?? null,
                "data_entrega": formBasico?.data_entrega ?? null,
                "modo_jazigo": formBasico?.modo_jazigo ?? null,
                "tipo_jazigos_vendas": formBasico?.tipo_jazigos_vendas ?? null,
                "coef_manutencao": formBasico?.coef_manutencao ?? null,
                "manutencao_periodicidade": formBasico?.manutencao_periodicidade ?? null,
            }
            const response = await api.put(`${url}/api/v1/admin/cemiterio/contrato-jazigo/${formBasico?.id}`, data);
            if (response.status === 200) {
                AlertService.sucess('Sucesso!', 'Salvo com sucesso!').then(() => {
                    setShow(false);
                    window.location.reload();
                });
            }
        } catch (error: any) {
            AlertService.infomessage('Erro!', String(error.message)).then(() => {
                setShow(false);
                window.location.reload();
            });
        }
    };

    const items = [
        {
            label: 'Dísponibilidade',
            command: (event: any) => {
                setCurrentPart(event.index)
            }
        },
        {
            label: 'Compradores',
            command: (event: any) => {
                setCurrentPart(event.index)
            }
        },
        {
            label: 'Produtos e Serviços',
            command: (event: any) => {
                setCurrentPart(event.index)
            }
        },
        {
            label: 'Entrada',
            command: (event: any) => {
                setCurrentPart(event.index)
            }
        },
        {
            label: 'Parcelamento',
            command: (event: any) => {
                setCurrentPart(event.index)
            }
        }
    ];

    const MoneyFormatter = (saldoDevedor: any) => {
        const formatMoney = (value: number): string => {
            return new Intl.NumberFormat('pt-BR', {
                style: 'currency',
                currency: 'BRL',
            }).format(value);
        };
        return formatMoney(saldoDevedor);
    };

    return (
        <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            size="xl"
        >
            <Modal.Header style={{ display: 'flex', flexDirection: 'column' }}>
                <Modal.Title style={{ marginBottom: '25px', color: '#494949' }}>Alterar Venda de Jazigo</Modal.Title>
                <div style={{ display: 'block', width: '-webkit-fill-available' }}>
                    <Steps model={items} activeIndex={currentPart} readOnly={false} />
                </div>
            </Modal.Header>
            <Modal.Body>
                <EditVendaForm
                    onInputChange={(field: string, value: string) => handleChange(`${field}`, value)}
                    currentPart={currentPart}
                    formBasico={formBasico}
                />
            </Modal.Body>
            <Modal.Footer style={{ display: 'flex', flexDirection: 'column', marginRight: '10px' }}>
                {formBasico?.imovel?.qtd_gaveta && (
                    <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', backgroundColor: '#4949498a', paddingLeft: '5px', paddingBottom: '2px', paddingTop: '2px', borderRadius: '7px' }}>
                        <label
                            htmlFor="ac"
                            style={{ display: 'flex', margin: '8px', color: '' }}
                        >
                            <p style={{ fontSize: '14px !important', margin: '0px', padding: '3px', backgroundColor: '#494949', color: '#fff', border: '1px solid #494949', borderTopLeftRadius: '4px' }}>
                                <strong>Jazigo</strong>
                            </p>
                            <p style={{ fontSize: '14px !important', margin: '0px', padding: '3px', backgroundColor: '#e8550f', color: '#fff', border: '1px solid #e8550f', borderBottomRightRadius: '4px' }}>
                                <strong>{formBasico?.imovel?.unidade} - {formBasico?.imovel?.qtd_gaveta} GV</strong>
                            </p>
                        </label>
                        <label
                            htmlFor="ac"
                            style={{ display: 'flex', margin: '8px', color: '' }}
                        >
                            <p style={{ fontSize: '14px !important', margin: '0px', padding: '3px', backgroundColor: '#494949', color: '#fff', border: '1px solid #494949', borderTopLeftRadius: '4px' }}>
                                <strong>Valor Jazigo</strong>
                            </p>
                            <p style={{ fontSize: '14px !important', margin: '0px', padding: '3px', backgroundColor: '#e8550f', color: '#fff', border: '1px solid #e8550f', borderBottomRightRadius: '4px' }}>
                                <strong>{MoneyFormatter(formBasico?.imovel?.imo_preco)}</strong>
                            </p>
                        </label>
                        <label
                            htmlFor="ac"
                            style={{ display: 'flex', margin: '8px', color: '' }}
                        >
                            <p style={{ fontSize: '14px !important', margin: '0px', padding: '3px', backgroundColor: '#494949', color: '#fff', border: '1px solid #494949', borderTopLeftRadius: '4px' }}>
                                <strong>Valor Acessórios</strong>
                            </p>
                            <p style={{ fontSize: '14px !important', margin: '0px', padding: '3px', backgroundColor: '#e8550f', color: '#fff', border: '1px solid #e8550f', borderBottomRightRadius: '4px' }}>
                                <strong>{MoneyFormatter(formBasico?.vl_venda_avulso)}</strong>
                            </p>
                        </label>
                        <label
                            htmlFor="ac"
                            style={{ display: 'flex', margin: '8px' }}
                        >
                            <p style={{ fontSize: '14px !important', margin: '0px', padding: '3px', backgroundColor: '#494949', color: '#fff', border: '1px solid #494949', borderTopLeftRadius: '4px' }}>
                                <strong>Entrada</strong>
                            </p>
                            <p style={{ fontSize: '14px !important', margin: '0px', padding: '3px', backgroundColor: '#e8550f', color: '#fff', border: '1px solid #e8550f', borderBottomRightRadius: '4px' }}>
                                <strong>{MoneyFormatter(formBasico?.entrada ?? 0)}</strong>
                            </p>
                        </label>
                        <label
                            htmlFor="ac"
                            style={{ display: 'flex', margin: '8px' }}
                        >
                            <p style={{ fontSize: '14px !important', margin: '0px', padding: '3px', backgroundColor: '#494949', color: '#fff', border: '1px solid #494949', borderTopLeftRadius: '4px' }}>
                                <strong>Desconto Jazigo</strong>
                            </p>
                            <p style={{ fontSize: '14px !important', margin: '0px', padding: '3px', backgroundColor: '#e8550f', color: '#fff', border: '1px solid #e8550f', borderBottomRightRadius: '4px' }}>
                                <strong>{MoneyFormatter(formBasico?.valor_desconto ?? 0)}</strong>
                            </p>
                        </label>
                        <label
                            style={{ display: 'flex', margin: '8px', marginRight: '15px' }}
                        >
                            <p style={{ fontSize: '14px !important', margin: '0px', padding: '3px', backgroundColor: '#494949', color: '#fff', border: '1px solid #494949', borderTopLeftRadius: '4px' }}>
                                <strong>Parcelamento</strong>
                            </p>
                            <p style={{ fontSize: '14px !important', margin: '0px', padding: '3px', backgroundColor: '#e8550f', color: '#fff', border: '1px solid #e8550f', borderBottomRightRadius: '4px' }}>
                                <strong> {formBasico?.qtd_parcelas ?? '1'} x {(formBasico?.valor_parcelas ?? 0)}</strong>
                            </p>
                        </label>
                    </div>
                )}
                <div style={{ display: 'flex', justifyContent: 'end', paddingTop: '15px' }}>
                    <Button onClick={() => { handleClose() }} className="button-back-form" variant="primary">
                        Fechar
                    </Button>
                    {currentPart !== 0 && (
                        <Button onClick={() => { handleBack() }} className="button-back-form" variant="primary">
                            Voltar
                        </Button>
                    )}
                    {currentPart !== totalParts && (
                        <Button className="button-next-form" variant="primary" onClick={handleNext}>
                            Próximo
                        </Button>
                    )}
                    {currentPart === totalParts && (<Button className='button-save-or-update' onClick={handleSaveButton} variant="success">Salvar</Button>)}
                </div>
            </Modal.Footer>
        </Modal>
    );
}

export default ModalEditVendaContrato;
