import iconMenuTresPonto from './../../../../../assets/images/icon-menu-tres-pontos.png';
import { Button } from 'primereact/button';
import { useSidebarContext } from './../../../../contexts/SidebarContext';
import { Col, Form, NavLink } from 'react-bootstrap';

interface ContextSearchProps {
    header?: string;
    filtrosData: {
        qtd_gavetas?: number;
        sigla?: string;
    };
    onInputClearList: () => void;
    onInputChangeList: (field: string, value: any) => void;
    onInputChange: (field: string, value: any) => void;
}

function FiltroTipoJazigo(props: ContextSearchProps) {
    const { qtd_gavetas, sigla } = props.filtrosData || {};
    const { isVisible, updateVisibility } = useSidebarContext();

    const toggleVisibility = () => {
        const newValue = !isVisible;
        updateVisibility(newValue);
    };

    const handleChange = (field: string, value: any) => {
        props.onInputChange(field, value);
    };

    const handleChangeList = (field: string, value: any) => {
        props.onInputChangeList(field, value);
    };

    const handleClearList = () => {
        props.onInputClearList();
    };

    const handleFilter = () => {
        if (sigla) {
            handleChangeList('sigla', sigla);
        }
        if (qtd_gavetas) {
            handleChangeList('qtd_gavetas', qtd_gavetas);
        }
    };

    return (
        <div className="contentSearch">
            <div className="contentSearch-header">
                <NavLink onClick={toggleVisibility}>
                    <img
                        className="menu-context"
                        src={iconMenuTresPonto}
                    />
                </NavLink>
                <Col className='text-center' style={{ fontSize: '20px', color: '#494949' }}>{props.header}</Col>
            </div>
            <div style={{ opacity: '34%' }} className="contentTraçoBodyHeader"></div>
            <div className="contentSearch-body">
                <div className='div-calendar'>
                    <Col md="4" style={{ marginRight: "8px" }}>
                        <Form.Label>Sigla</Form.Label>
                        <Form.Control
                            value={sigla ? sigla : ''}
                            onChange={(e) => handleChange("sigla", e.target.value)}
                        />
                    </Col>
                    <Col md='6' style={{ marginRight: "8px" }}>
                        <Form.Label>Quantidade de Gavetas</Form.Label>
                        <Form.Control
                            value={qtd_gavetas ? qtd_gavetas : ''}
                            onChange={(e) => handleChange("qtd_gavetas", e.target.value)}
                        />
                    </Col>
                </div>
                <div className='div-filtros-avancados' style={{ marginTop: '35px' }}>
                    <Button
                        onClick={handleFilter}
                        style={{ height: '30px', borderRadius: '20px', padding: '5px 10px', marginRight: '5px', fontSize: '14px' }}
                        type="button"
                        severity="success"
                        icon="pi pi-filter"
                        rounded>
                        <p style={{ marginLeft: '5px', marginTop: '13px' }}>Pesquisar</p>
                    </Button>
                    <Button
                        onClick={handleClearList}
                        style={{ height: '30px', borderRadius: '20px', padding: '5px 10px', marginRight: '5px', fontSize: '14px' }}
                        type="button"
                        severity="danger"
                        icon="pi pi-delete-left"
                        rounded>
                        <p style={{ marginLeft: '5px', marginTop: '13px' }}>Limpar</p>
                    </Button>
                </div>
            </div>
        </div>
    );
}

export default FiltroTipoJazigo;
