import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import { useAppContext } from '../../../contexts/AppContext';
import { url } from '../../../environments/environments-develop';
import { AlertService } from '../../../components/AlertService';
import ImovelForm from './Formularios/imovelForm';

interface PropsImovelData {
    identificador1?: string;
    identificador2?: string;
    unidade?: string;
    area?: string;
    incdec?: string;
    status?: boolean;
    corretor_id?: string;
    logradouro?: string;
    frente?: string;
    fundo?: string;
    confr_fundo?: string;
    lado_direito?: string;
    confr_lado_direito?: string;
    lado_esquerdo?: string;
    confr_lado_esquerdo?: string;
    chanfro?: string;
    imo_preco?: string;
    imo_intermed?: string;
    imo_matricula?: string;
    construcao?: string;
    ult_pgto_iptu?: string;
    observacao?: string;
    tipo_imovel_id?: number;
    sub_tipo_imovel_id?: number;
    classif_preco_id?: number;
}
function EditImoveis() {
    const { setUrl, setBody, setShowButtonSave, setTypeRequest, setItemsBreadsCrumbs } = useAppContext();
    const [imovelData, setImovelData] = useState<PropsImovelData>({});

    useEffect(() => {
        try {
            setItemsBreadsCrumbs([
                {
                    label: 'Empreendimento',
                    url: '/empreendimento',
                },
                {
                    label: 'Imóveis',
                    url: '/imovel',
                },
                {
                    label: 'Editar'
                }
            ]);
            const storedData = localStorage.getItem('dadosEdicao');
            if (storedData) {
                setImovelData((prevImovelData) => {
                    const dadosAtualizados = {
                        ...prevImovelData,
                        ...JSON.parse(storedData)
                    };
                    return dadosAtualizados;
                });
                setTypeRequest('PUT');
                setUrl(`${url}/api/v1/admin/vendas/imovel/${storedData && JSON.parse(storedData).id}`);
                setShowButtonSave(true);
            }
            return () => {
                localStorage.removeItem('dadosEdicao');
            };
        } catch (error) {
            AlertService.error("error", String(error))
        }
    }, []);
    useEffect(() => {
        setBody(imovelData)
    }, [imovelData]);

    const handleChange = (field: string, value: any) => {
        setImovelData((prevImovelData) => {
            return {
                ...prevImovelData,
                [field]: value
            };
        });
        setTypeRequest('PUT');
    }
    return (
        <div className="body-content">
            <div className="form-create-group">
                <div className="form-create-group-header">
                    <h3>Alterar Imóvel</h3>
                </div>
            </div>
            <div className="form-group-edit-imovel">
                <Container>
                    <ImovelForm
                        initialRightValues={[]}
                        imovelData={imovelData}
                        onInputChange={(field: string, value: any) => handleChange(field, value)}
                    />
                </Container>
            </div>
        </div>
    );
}

export default EditImoveis;
