import { Col, Form, InputGroup, Row } from "react-bootstrap";
import 'react-datepicker/dist/react-datepicker.css';
import { useEffect, useState } from "react";
import { InputNumber } from "primereact/inputnumber";
import DetalhesContrato from "../Components/DetalhesContrato";

function FormShow(props: any) {
    const {
        status_name,
        data_venda_format,
        imovel,
        imo_intermed,
        valor_total,
        entrada,
        qtd_parcelas,
        valor_parcelas,
        valor_desconto,
        intermed_valor_desc,
        qtd_parc_intermed,
        valor_parc_intermed,
        qtd_parc_entrada,
        corretor,
        saldo_financiar,
        tipo_contrato_name,
        valor_prim_parc_entrada,

    } = props.Data || {};
    const [prox_reajuste, setProxReajuste] = useState<Date | null>(null);
    const [venc_prim_parc_intermed, setVencPrimParcIntermed] = useState<Date | null>(null);
    const [venc_prim_parc_entrada, setVencPrimParcEntrada] = useState<Date | null>(null);
    const [data_prim_parcela, setDataPrimParcela] = useState<Date | null>(null);
    const [data_ult_reajuste, setUltReajuste] = useState<Date | null>(null);
    const [formCompradores, setFormCompradores] = useState<any>({
        pessoa: []
    });
    const handleChange = (field: string, value: any) => {
        props.onInputChange(field, value);
    };
    useEffect(() => {
        if (props.Data?.cliente_compradores) {
            let comprador: number[] = [];

            props.Data?.cliente_compradores?.forEach((item: any) => {
                comprador.push(item);
            });
            setFormCompradores((prevData: any) => {
                return {
                    ...prevData,
                    pessoa: comprador
                };
            });
        }
        setProxReajuste(props.Data.prox_reajuste ? new Date(props.Data.prox_reajuste) : null);
        setVencPrimParcIntermed(props.Data.venc_prim_parc_intermed ? new Date(props.Data.venc_prim_parc_intermed) : null);
        setVencPrimParcEntrada(props.Data.venc_prim_parc_entrada ? new Date(props.Data.venc_prim_parc_entrada) : null);
        setDataPrimParcela(props.Data.data_prim_parcela ? new Date(props.Data.data_prim_parcela) : null);
        setUltReajuste(props.Data.data_ult_reajuste ? new Date(props.Data.data_ult_reajuste) : null)
    }, [props.Data])
    return (
        <div style={{ margin: '15px' }}>
            <DetalhesContrato
                data={props.Data}
            />
            {formCompradores?.pessoa.map((comprador: any) => (
                <Row className="group-form" key={comprador.id}>
                    <Col md="3">
                        <Form.Label>Comprador</Form.Label>
                        <p>{comprador?.cliente_name}</p>
                    </Col>
                    <Col md="3">
                        <Form.Label>Comprador Principal</Form.Label>
                        <p>{comprador?.principal === 1 ? 'Sim' : 'Não'}</p>
                    </Col>
                    <Col md="3">
                        <Form.Label>Unidade do Imóvel</Form.Label>
                        <p>{imovel ? imovel?.unidade : null}</p>
                    </Col>
                    <Col md="2">
                        <Form.Label>Qtd. Parcelas</Form.Label>
                        <p>{qtd_parcelas}</p>
                    </Col>
                </Row>
            ))}
            <Row className="group-form">
                <Col md="3">
                    <Form.Label>Status</Form.Label>
                    <Form.Control
                        value={status_name}
                        disabled
                    />
                </Col>
                <Col md="3">
                    <Form.Label>Data da Venda</Form.Label>
                    <Form.Control
                        value={data_venda_format}
                        disabled
                    />
                </Col>
                <Col md="3">
                    <Form.Label>Intermediação</Form.Label>
                    <InputNumber
                        style={{ width: '100%', height: '38px' }}
                        value={imo_intermed ? imo_intermed : 0}
                        mode="currency" currency="BRL" locale="pt-BR"
                        disabled
                    />
                </Col>
                <Col md="3">
                    <Form.Label>Valor Total</Form.Label>
                    <InputNumber
                        style={{ width: '100%', height: '38px' }}
                        value={valor_total ? valor_total : 0}
                        mode="currency" currency="BRL" locale="pt-BR"
                        disabled
                    />
                </Col>
            </Row>
            <Row className="mb-3">
                <Col md="3">
                    <Form.Label>Entrada</Form.Label>
                    <InputNumber
                        style={{ width: '100%', height: '38px' }}
                        value={entrada}
                        mode="currency" currency="BRL" locale="pt-BR"
                        disabled
                    />
                </Col>
                <Col md="3">
                    <Form.Label>Valor das Parcelas</Form.Label>
                    <Form.Control
                        value={valor_parcelas}
                        disabled
                    />
                </Col>
                <Col md="3">
                    <Form.Label>Desconto</Form.Label>
                    <InputNumber
                        style={{ width: '100%', height: '38px' }}
                        value={valor_desconto}
                        mode="currency" currency="BRL" locale="pt-BR"
                        disabled
                    />
                </Col>
                <Col md="3">
                    <Form.Label>Desconto da Intermediação</Form.Label>
                    <InputNumber
                        style={{ width: '100%', height: '38px' }}
                        value={intermed_valor_desc}
                        mode="currency" currency="BRL" locale="pt-BR"
                        disabled
                    />
                </Col>
            </Row>
            <Row className="mb-3">
                <Col md="3">
                    <Form.Label>Qtd. Parcelas Intermediação</Form.Label>
                    <Form.Control
                        value={qtd_parc_intermed}
                        disabled
                    />
                </Col>
                <Col md="3">
                    <Form.Label>Valor da Primeira Parc. Intermed.</Form.Label>
                    <InputNumber
                        style={{ width: '100%', height: '38px' }}
                        value={valor_parc_intermed}
                        mode="currency" currency="BRL" locale="pt-BR"
                        disabled
                    />
                </Col>
                <Col md="3" controlId="formGridState" style={{ display: 'grid' }}>
                    <Form.Label>Venc. Da Primeira Parc. Intermed</Form.Label>
                    <Form.Control
                        value={venc_prim_parc_intermed?.toLocaleDateString()}
                        disabled
                    />
                </Col>
                <Col md="3">
                    <Form.Label>Valor Da Primeira Parc. Entrada</Form.Label>
                    <InputNumber
                        style={{ width: '100%', height: '38px' }}
                        value={valor_prim_parc_entrada}
                        mode="currency" currency="BRL" locale="pt-BR"
                        disabled
                    />
                </Col>
            </Row>
            <Row className="mb-3">
                <Col md="3" controlId="formGridState" style={{ display: 'grid' }}>
                    <Form.Label>Venc. Da Primeira Parc. Entrada</Form.Label>
                    <Form.Control
                        value={venc_prim_parc_entrada?.toLocaleDateString()}
                        disabled
                    />
                </Col>
                <Col md="3">
                    <Form.Label>Qtd. Parcela da entrada</Form.Label>
                    <Form.Control
                        value={qtd_parc_entrada}
                        disabled
                    />
                </Col>
                <Col md="3" controlId="formGridState" style={{ display: 'grid' }}>
                    <Form.Label>Data Primeira Parcela do Imovel</Form.Label>
                    <Form.Control
                        value={data_prim_parcela?.toLocaleDateString()}
                        disabled
                    />
                </Col>
                <Col md="3">
                    <Form.Label>Saldo à Financiar</Form.Label>
                    <Form.Control
                        value={saldo_financiar}
                        disabled
                    />
                </Col>
            </Row>
            <Row>
                <Col md="3">
                    <Form.Label>Tipo Contrato</Form.Label>
                    <Form.Control
                        value={tipo_contrato_name}
                        disabled
                    />
                </Col>
                <Col md="3">
                    <Form.Label>Corretor</Form.Label>
                    <Form.Control
                        value={corretor?.nome_corretor}
                        disabled
                    />
                </Col>
                <Col md="3" controlId="formGridState" style={{ display: 'grid' }}>
                    <Form.Label>Data do Proximo Reajuste</Form.Label>
                    <Form.Control
                        value={prox_reajuste?.toLocaleDateString()}
                        disabled
                    />
                </Col>
                <Col md="3" controlId="formGridState" style={{ display: 'grid' }}>
                    <Form.Label>Data do Ult. Reajuste</Form.Label>
                    <Form.Control
                        value={data_ult_reajuste?.toLocaleDateString()}
                        disabled
                    />
                </Col>
            </Row>
        </div>
    );
}

export default FormShow;
