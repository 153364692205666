import { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { url } from "../../../../environments/environments-develop";
import api from '../../../../services/api';
import { AlertService } from "../../../../components/AlertService";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import CalendarIcon from '../../../../../assets/images/icon-calendar.png';
import pt from 'date-fns/locale/pt';
import { AutoComplete } from "primereact/autocomplete";
import { searchCliente } from "../Service/ContasReceberService";
import { TreeSelect } from 'primereact/treeselect';
import { TreeNode } from 'primereact/treenode';
import { InputNumber } from "primereact/inputnumber";
import ModalDataVencimento from "../Components/ModalDataVencimento";
import { InputMask } from 'primereact/inputmask';
import { addLocale } from "primereact/api";
import { Calendar } from "primereact/calendar";
interface PropsData {
    Data: {
        tipo?: number;
        tipo_documento?: number;
        justificativa?: string;
        cliente_id?: string;
        cliente?: any;
        pessoa_id?: string;
        recorrente?: boolean;
        numero_documento?: string;
        conta_financeiro_id?: string;
        plano_conta_id?: string;
        centro_custo?: string[];
        centro_custo_format?: string[];
        data_competencia?: string;
        num_parcela?: number;
        valor_pago?: string;
        valor_original?: number;
        data_vencimento?: string;
        data_emissao?: string;
        data_efetivacao?: string;
        descricao_caixa?: string;
    };
    onInputChange: (field: string, value: any) => void;
}
function ContasReceberForm(props: any) {
    const [listContaFinanceira, setListContaFinanceira] = useState([]);
    const [listPlanoConta, setListPlanoConta] = useState<TreeNode[]>([]);
    const [selectedNodesPlanoContas, setSelectedNodesPlanoContas] = useState<any>([]);
    const [listCentroCusto, setListCentroCusto] = useState<TreeNode[]>([]);
    const [selectedNodesCentroCusto, setSelectedNodesCentroCusto] = useState<any>([]);
    const [data_emissao, setDataEmissao] = useState<Date | null>(null);
    const [data_vencimento, setDataVencimento] = useState<Date | null>(null);
    var {
        tipo_documento,
        justificativa,
        cliente,
        pessoa_id,
        cliente_id,
        recorrente,
        numero_documento,
        conta_financeiro_id,
        valor_original,
        plano_conta_id,
        centro_custo,
        centro_custo_format,
        data_competencia,
        descricao_caixa
    } = props.Data || {};
    const handleChange = (field: string, value: any) => {
        props.onInputChange(field, value);
    };
    const [FilteredCliente, setFilteredCliente] = useState<any>(null);
    const [listFiltros, setListFiltros] = useState<any>({});
    const [display_modal_rotina, setDisplayModalRotina] = useState<any>(false);
    const [form_modal_rotina, setFormModalRotina] = useState<any>([]);
    const [numParcela, setNumParcela] = useState<any | undefined>(props.Data?.num_parcela || '');

    useEffect(() => {
        setNumParcela(props.Data?.num_parcela);
    }, [props.Data?.num_parcela]);

    useEffect(() => {
        setDataEmissao(props.Data?.data_emissao ? new Date(props.Data.data_emissao) : null);
        setDataVencimento(props.Data?.data_vencimento ? new Date(props.Data.data_vencimento) : null)
        data_competencia && handleChange('data_competencia', data_competencia);
    }, [props.Data?.data_emissao, props.Data?.data_vencimento, data_competencia])
    async function InitContaFinanceira() {
        try {
            const response = await api.get(`${url}/api/v1/admin/financeiro/conta-financeira`);
            setListContaFinanceira(response.data.data);
        } catch (error) {
            AlertService.error('Error', 'Ocorreu um erro ao buscar a lista de permissões. Erro:' + String(error));
        }
    }
    const openModalRotina = (parcela: any, valorTotal: any) => {
        if (parcela > 1 && valorTotal) {
            if (!valorTotal) {
                return handleChange('num_parcela', null);
            }
            let valor = valorTotal / parcela;
            const formGroup = [];
            for (let index = 0; index < parcela; index++) {
                const date = new Date();
                date.setMonth(date.getMonth() + index);
                const newFormGroup = {
                    date: date,
                    valor_original: valor,
                };
                formGroup.push(newFormGroup);
            }
            setFormModalRotina(formGroup);
            setDisplayModalRotina(true);
        }
    }
    async function InitPlanoConta() {
        try {
            const includeParam = 'tipo_label,filhos.filhos.filhos.filhos.filhos.filhos';
            const response = await api.get(`${url}/api/v1/admin/financeiro/plano-conta?include=${includeParam}`);
            if (response.status === 200) {
                const data = response.data.data;

                const buildTreeNode = (item: any): TreeNode | null => {
                    if (item) {
                        const hasChildren = item.filhos?.data && item.filhos.data.length > 0;
                        return {
                            key: item.id.toString(),
                            label: item.nome || item.id.toString(),
                            data: item,
                            icon: hasChildren ? 'pi pi-fw pi-folder' : 'pi pi-fw pi-file',
                            children: hasChildren ? item.filhos.data.map((child: any) => buildTreeNode(child)) : null,
                            selectable: !hasChildren,  // Não pode ser selecionado se tiver filhos
                        };
                    }
                    return null;
                };

                const nodesData: TreeNode[] = data
                    ? data.reduce((acc: TreeNode[], item: any) => {
                        const treeNode = buildTreeNode(item);
                        if (treeNode) {
                            acc.push(treeNode);
                        }
                        return acc;
                    }, [])
                    : [];
                setListPlanoConta(nodesData);
            }
        } catch (error) {
            AlertService.error('Error', 'Ocorreu um erro ao buscar a lista de permissões. Erro: ' + String(error));
        }
    }
    async function InitCentroCusto() {
        try {
            const includeParam = 'tipo_label,filhos.filhos.filhos.filhos.filhos.filhos'
            const response = await api.get(`${url}/api/v1/admin/financeiro/centro-custo?include=${includeParam}`);
            if (response.status === 200) {
                const data = response.data.data;

                const buildTreeNode = (item: any): TreeNode | null => {
                    if (item) {
                        const hasChildren = item.filhos?.data && item.filhos.data.length > 0;
                        return {
                            key: item.id.toString(),
                            label: item.nome || item.id.toString(),
                            data: item,
                            icon: hasChildren ? 'pi pi-fw pi-folder' : 'pi pi-fw pi-file',
                            children: hasChildren ? item.filhos.data.map((child: any) => buildTreeNode(child)) : null,
                            selectable: !hasChildren,  // Não pode ser selecionado se tiver filhos
                        };
                    }
                    return null;
                };

                const nodesData: TreeNode[] = data
                    ? data.reduce((acc: TreeNode[], item: any) => {
                        const treeNode = buildTreeNode(item);
                        if (treeNode) {
                            acc.push(treeNode);
                        }
                        return acc;
                    }, [])
                    : [];
                setListCentroCusto(nodesData);
            }
        } catch (error) {
            AlertService.error('Error', 'Ocorreu um erro ao buscar a lista de permissões. Erro:' + String(error));
        }
    }
    addLocale('pt', {
        firstDayOfWeek: 0,
        dayNames: ['domingo', 'segunda-feira', 'terça-feira', 'quarta-feira', 'quinta-feira', 'sexta-feira', 'sábado'],
        dayNamesShort: ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sáb'],
        dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
        monthNames: ['janeiro', 'fevereiro', 'março', 'abril', 'maio', 'junho', 'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro'],
        monthNamesShort: ['jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez'],
        today: 'Hoje',
        clear: 'Limpar'
    });
    useEffect(() => {
        InitContaFinanceira();
        InitPlanoConta();
        InitCentroCusto();
    }, []);
    return (
        <>
            <Form.Group className="formulario-empreendimento">
                <Row className="group-form">
                    <Col md="3" style={{ display: 'flex', flexDirection: 'column' }}>
                        <Form.Label>Cliente|Devedor</Form.Label>
                        <AutoComplete
                            field="nome_cliente"
                            style={{ height: "37px" }}
                            value={cliente}
                            invalid={!cliente}
                            suggestions={FilteredCliente}
                            completeMethod={(e) => searchCliente(e, setFilteredCliente)}
                            onChange={(e) => {
                                setListFiltros({ ...listFiltros, cliente: e.value })
                                handleChange('cliente', e.value)
                            }}
                            onSelect={(e) => {
                                handleChange('cliente_id', e.value.id)
                                setListFiltros({ ...listFiltros, cliente_id: e.value.id, cliente: e.value })
                            }}
                            dropdown
                        />
                    </Col>
                    <Col md="3" style={{ display: 'flex', flexDirection: 'column' }}>
                        <Form.Label>Tipos de Documentos</Form.Label>
                        <Form.Select value={tipo_documento} onChange={(e) => handleChange('tipo_documento', e.target.value)}>
                            <option>Selecione...</option>
                            <option value={0}>Nota Fiscal</option>
                            <option value={1}>Fatura</option>
                            <option value={2}>Recibo</option>
                            <option value={3}>Boleto</option>
                            <option value={4}>Comprovante de Despesa</option>
                            <option value={5}>Cheque</option>
                            <option value={6}>Nota Promissória</option>
                            <option value={7}>Título Provisório</option>
                            <option value={8}>Rascunho</option>
                            <option value={9}>Transferência</option>
                            <option value={10}>Assinatura</option>
                            <option value={14}>Cupom Fiscal</option>
                            <option value={15}>Guia</option>
                        </Form.Select>
                    </Col>
                    <Col md={3} style={{ display: 'flex', flexDirection: 'column' }}>
                        <Form.Label>
                            Data de Emissão
                        </Form.Label>
                        <div className=" flex justify-center" >
                            <Calendar
                                invalid={!data_emissao}
                                value={data_emissao || null}
                                onChange={(event: any) => {
                                    const selectedDates = event.value;
                                    setDataEmissao(selectedDates);
                                    handleChange('data_emissao', selectedDates ? selectedDates.toISOString() : null);
                                }}
                                placeholder="Data de emissão"
                                showButtonBar
                                selectionMode="single"
                                dateFormat="dd/mm/yy"
                                locale="pt"
                                readOnlyInput
                                style={{ textAlign: 'center', width: '100%' }}
                                panelClassName="custom-calendar-panel"
                                required
                            />
                        </div>
                    </Col>
                    <Col md="3" style={{ display: 'flex', flexDirection: 'column' }}>
                        <Form.Label>Recorrente?</Form.Label>
                        <Form.Select value={recorrente?.toString()} onChange={(e) => handleChange("recorrente", e.target.value === "true")}>
                            <option>Selecione...</option>
                            <option value="true">Sim</option>
                            <option value="false">Não</option>
                        </Form.Select>
                    </Col>

                </Row>
                <Row className="group-form">
                    <Col md="3" style={{ display: 'flex', flexDirection: 'column' }}>
                        <Form.Label>Número Documento</Form.Label>
                        <Form.Control value={numero_documento || ''} onChange={(e) => handleChange('numero_documento', e.target.value)} />
                    </Col>
                    <Col md="9" style={{ display: 'flex', flexDirection: 'column' }}>
                        <Form.Label>Descrição do caixa</Form.Label>
                        <Form.Control placeholder="RCB REF [Serviço/Produto] [VENC 99/99/9999] [PARC 1/9]" value={descricao_caixa} onChange={(e) => handleChange('descricao_caixa', e.target.value)} />
                    </Col>
                </Row>
                <Row className="group-form">
                    <Col md="3" style={{ display: 'flex', flexDirection: 'column' }}>
                        <Form.Label>Conta Financeira</Form.Label>
                        <Form.Select value={conta_financeiro_id || ''} onChange={(e) => handleChange('conta_financeiro_id', e.target.value)}>
                            <option>Selecione...</option>
                            {listContaFinanceira?.map((item: any) => (
                                <option value={item.id} key={item.id}>
                                    {item.nome}
                                </option>
                            ))}
                        </Form.Select>
                    </Col>
                    <Col md="3" style={{ display: 'flex', flexDirection: 'column' }}>
                        <Form.Label>Plano de Contas</Form.Label>
                        <TreeSelect
                            value={plano_conta_id}
                            options={listPlanoConta}
                            onChange={(e) => {
                                const selectedValues: any = e.value;
                                handleChange('plano_conta_id', selectedValues)
                            }}
                            filter
                            selectionMode="single"
                            placeholder="Selecione um plano de conta"
                            display="chip"
                            className="w-full md:w-20rem"
                            style={{ width: '100%' }}
                            onFocus={InitPlanoConta}
                        />
                    </Col>
                    <Col md="3" style={{ display: 'flex', flexDirection: 'column' }}>
                        <Form.Label>Centro de Custo</Form.Label>
                        <TreeSelect
                            value={centro_custo ? centro_custo?.reduce((acc: any, item: any) => {
                                acc[item] = true;
                                return acc;
                            }, []) : []}
                            options={listCentroCusto}
                            onChange={(e) => {
                                const selectedValues: any = e.value || [];
                                let centros: any[] = [];
                                Object.keys(selectedValues).forEach((key, index) => {
                                    centros.push(Number(key));
                                });
                                handleChange('centro_custo', centros)
                            }}
                            filter
                            placeholder="Selecione um centro de custo"
                            display="chip"
                            selectionMode="multiple"
                            className="w-full md:w-20rem"
                            style={{ width: '100%' }}
                            onFocus={InitCentroCusto}
                        />
                    </Col>

                    <Col md="3" style={{ display: 'flex', flexDirection: 'column' }}>
                        <Form.Label>Valor Total do Lançamento</Form.Label>
                        <InputNumber
                            invalid={!valor_original}
                            value={typeof valor_original === 'string'
                                ? (valor_original ? parseFloat(valor_original.replace(',', '.')) : null)
                                : (valor_original ? valor_original : null)}
                            onChange={(e) => handleChange('valor_original', e.value)}
                            minFractionDigits={2}
                            maxFractionDigits={24}
                            style={{ width: '100%', height: '38px' }}
                            locale="pt-BR"
                            onBlur={(e) => openModalRotina(numParcela, valor_original)}
                        />
                    </Col>
                </Row>
                <Row className="group-form">
                    {!recorrente && (<Col md="2" style={{ display: 'flex', flexDirection: 'column' }}>
                        <div style={{ display: 'flex', width: '100%' }}>
                            <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                <Form.Label>N° Parcelas </Form.Label>
                                <Form.Control
                                    value={numParcela || ''}
                                    style={{ width: '100%' }}
                                    onChange={(e) => {
                                        const value = e.target.value ? parseInt(e.target.value, 10) : 0;
                                        setNumParcela(value);
                                        handleChange('num_parcela', value);
                                    }}
                                    onBlur={(e) => openModalRotina(numParcela, valor_original)}
                                />
                            </div>
                            {numParcela && numParcela >= 2
                                ? (<div style={{ display: 'flex', alignItems: 'center', marginLeft: '8px', marginTop: '25px' }}>
                                    <a type="button" onClick={() => openModalRotina(numParcela, valor_original)}>
                                        <svg
                                            className="button-create"
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="16"
                                            height="16"
                                            viewBox="0 0 16 16"
                                            fill="none"
                                        >
                                            <path
                                                d="M16 7.30435H8.69565V0H7.30435V7.30435H0V8.69565H7.30435V16H8.69565V8.69565H16V7.30435Z"
                                                fill="white"
                                            />
                                        </svg>
                                    </a>
                                </div>)
                                : ''
                            }
                        </div>
                    </Col>)}
                    <Col md={3} style={{ display: 'flex', flexDirection: 'column' }}>
                        {(numParcela && numParcela >= 2)
                            ? (<Form.Label>Dt. Venc Parcelamento</Form.Label>)
                            : (<Form.Label>Data de Vencimento</Form.Label>)
                        }
                        <div className=" flex justify-center">
                            <Calendar
                                invalid={!data_vencimento}
                                value={data_vencimento || null}
                                onChange={(event: any) => {
                                    const selectedDates = event.value;
                                    setDataVencimento(selectedDates);
                                    handleChange('data_vencimento', selectedDates ? selectedDates.toISOString() : null);
                                }}
                                placeholder="Data de Vencimento"
                                showButtonBar
                                selectionMode="single"
                                dateFormat="dd/mm/yy"
                                locale="pt"
                                readOnlyInput
                                style={{ textAlign: 'center', width: '100%' }}
                                panelClassName="custom-calendar-panel"
                                required
                            />
                        </div>
                    </Col>
                    <Col md="2" style={{ display: 'flex', flexDirection: 'column' }}>
                        <Form.Label>Data de Competência</Form.Label>
                        <InputMask
                            mask="99/9999"
                            value={data_competencia || ''}
                            onChange={(e) => handleChange('data_competencia', e.target.value)}
                            placeholder="xx/xxxx"
                            className="form-control"
                        />
                    </Col>
                    <Col md="5" style={{ display: 'flex', flexDirection: 'column' }}>
                        <Form.Label>Justificativa / Descrição</Form.Label>
                        <Form.Control isInvalid={!justificativa} value={justificativa || ''} onChange={(e) => handleChange("justificativa", e.target.value)} />
                    </Col>
                </Row>
            </Form.Group>
            <ModalDataVencimento
                showModal={display_modal_rotina}
                onHide={() => setDisplayModalRotina(false)}
                handleClose={() => setDisplayModalRotina(false)}
                formModalRotina={form_modal_rotina}
                onInputChange={(field: string, value: string) => handleChange(`${field}`, value)}
            />
        </>
    );
}

export default ContasReceberForm;
