import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Outlet } from "react-router-dom";
import TokenService from "../../services/Auth/token.service";
import LoadingWrapper from "../../../core/Loader/LoadingWrapper";
import Accessdenied from '../../../assets/images/403.png';
import NavBarComponent from "../Layout/navbar-layout/NavbarComponent";
import MenuComponent from "../Layout/navbar-layout/components/MenuComponent";
interface SubmenuItem {
    id: number;
    parent_id: number;
    name: string;
    icon: string;
    is_menu: boolean;
    is_context_menu: boolean;
    route: string;
}

interface MenuProps {
    id: number;
    parent_id: number;
    name: string;
    icon: string;
    route: string;
    is_menu: boolean;
    is_context_menu: boolean;
    submenu: SubmenuItem[];
}

interface SidebarProps {
    menu: MenuProps[];
}

const NotFound: React.FC = () => {
    const location = useLocation();
    const [showSidebar, setShowSidebar] = useState(true);
    const [selectedEmpreendimento, setSelectedEmpreendimento] = useState<number | null>(null);
    const menuProps: MenuProps[] = [];
    const empreendimentoList = TokenService.getFiliais();
    const permissoes = TokenService.getPermissoes();

    if (permissoes) {
        permissoes[0].permission.forEach((element: any) => {
            menuProps.push({
                id: element.id,
                parent_id: element.parent_id,
                name: element.titulo,
                icon: element.icon,
                route: "/" + element.slug,
                is_menu: element.is_menu,
                is_context_menu: element.is_context_menu,
                submenu: [],
            });

            if (element.is_context_menu === true) {
                const menuIndex = menuProps.findIndex((menu) => menu.id === element.parent_id);
                if (menuIndex !== -1) {
                    if (!menuProps[menuIndex].submenu) {
                        menuProps[menuIndex].submenu = [];
                    }

                    menuProps[menuIndex].submenu.push({
                        id: element.id,
                        parent_id: element.parent_id,
                        name: element.titulo,
                        icon: element.icon,
                        route: "/" + element.slug,
                        is_menu: element.is_menu,
                        is_context_menu: element.is_context_menu
                    });
                }
            }
        });

        // Ordenar o array menuProps para garantir que "home" seja o primeiro
        menuProps.sort((a, b) => {
            if (a.name.toLowerCase() === "home") {
                return -1;
            } else if (b.name.toLowerCase() === "home") {
                return 1;
            } else {
                return 0;
            }
        });
    }
    useEffect(() => {
        setShowSidebar(location.pathname !== "/");
    }, [location]);

    const handleEmpreendimentoChange = (index: number) => {
        setSelectedEmpreendimento(index);
    };
    return (
        <LoadingWrapper>
            <div className="AppLayout">
                <div className="body">
                    <header>
                        <NavBarComponent menuProps={[]} empreendimento={empreendimentoList} onEmpreendimentoChange={handleEmpreendimentoChange}>
                            <MenuComponent menu={menuProps} />
                        </NavBarComponent>
                    </header>

                    <div className="body-content">
                        <div
                            className="pageAccessDenied "
                        >
                            <h1> 403 - Acesso Negado </h1>
                        </div>
                    </div>

                </div>
            </div>
        </LoadingWrapper>

    );
}

export default NotFound;
