import { useAppContext } from '../../../contexts/AppContext';
import { useEffect, useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { actionTemplate } from './Components/actionTemplate';
import { useAppContext as useResponseContext } from '../../../contexts/ResponseContext';
import { loadData } from './Service/InumadoService';
import { Button } from 'primereact/button';
import { Col, Form, NavLink } from 'react-bootstrap';
import { useSidebarContext } from '../../../contexts/SidebarContext';
import iconMenuTresPonto from '../../../../assets/images/icon-menu-tres-pontos.png';
import iconFiltroAvancado from '../../../../assets/images/icon-filtro-avancado.png';
import ModalFiltroAvancado from './Components/ModalFiltroAvancado';
import { Skeleton } from 'primereact/skeleton';
import Paginacao from './Components/Paginacao';

function Inumados() {
  const { showSubSidebar } = useAppContext();
  const [listFiltros, setListFiltros] = useState<any>({});
  const [currentPage, setCurrentPage] = useState(0);
  const [nodes, setNodes] = useState<any | null>(null);
  const { titles, updateTitles, pagination, updatePagination } = useResponseContext();
  const { setUrl_button_create, setItemsBreadsCrumbs } = useAppContext();
  const [selectedRow, setSelectedRow] = useState<any>(null);
  const url_redirect_edit = "/inumados/update";
  const urlRedirectCreated = "/inumados/store";
  const urlSearchDataPut = "/api/v1/admin/cemiterio/inumado/";
  const { isVisible, updateVisibility } = useSidebarContext();
  const [showModalFiltro, setShowModalFiltro] = useState(false);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    setItemsBreadsCrumbs([
      {
        label: 'Cemitério',
        url: '/cemiterio',
      },
      {
        label: 'Lista de Inumados',
        url: '/inumados',
      },
    ]);
  }, []);

  useEffect(() => {
    setUrl_button_create(urlRedirectCreated);
    loadData(listFiltros, currentPage, setLoading, setNodes, updateTitles, updatePagination, setShowModalFiltro);
  }, [currentPage]);
  const toggleVisibility = () => {
    const newValue = !isVisible;
    updateVisibility(newValue);
  };

  return (
    <div className="body-content">
      <div className={`${showSubSidebar ? "container-section-header" : "container-section-header-none"}`}>
        <div className="contentSearch">
          <div className="contentSearch-header">
            <NavLink onClick={toggleVisibility}>
              <img className="menu-context" src={iconMenuTresPonto} />
            </NavLink>
            <Col className='text-center' style={{ fontSize: '20px', color: '#494949' }}>Inumados</Col>
          </div>
          <div style={{ opacity: '34%' }} className="contentTraçoBodyHeader"></div>
          <div className="contentSearch-body">
            <div className='filter-campos'>
              <div className='div-calendar'>
                <Col md="4" style={{ marginRight: "8px" }}>
                  <Form.Label>Nome</Form.Label>
                  <Form.Control
                    value={listFiltros?.nome ? listFiltros?.nome : ''}
                    onChange={(e) => setListFiltros({ ...listFiltros, nome: e.target.value })}
                  />
                </Col>
                <Col md='4' style={{ marginRight: "8px" }}>
                  <Form.Label>Número Interno</Form.Label>
                  <Form.Control
                    value={listFiltros?.numero_interno ? listFiltros?.numero_interno : ''}
                    onChange={(e) => setListFiltros({ ...listFiltros, numero_interno: e.target.value })}
                  />
                </Col>
              </div>
            </div>
            <div className='div-filtros-avancados' style={{ marginTop: '35px' }}>
              <Button
                style={{ height: '30px', borderRadius: '20px', padding: '5px 10px', marginRight: '5px', fontSize: '14px' }}
                type="button"
                rounded outlined severity="secondary"
                onClick={() => { setShowModalFiltro(true) }}>
                <img style={{ width: '25px', height: '35px' }} src={iconFiltroAvancado} />
              </Button>
              <Button
                style={{ height: '30px', borderRadius: '20px', padding: '5px 10px', marginRight: '5px', fontSize: '14px' }}
                type="button"
                severity="success"
                icon="pi pi-filter"
                rounded
                onClick={() => loadData(listFiltros, currentPage, setLoading, setNodes, updateTitles, updatePagination, setShowModalFiltro)}
              >
                <p style={{ marginLeft: '5px', marginTop: '13px' }}>Pesquisar</p>
              </Button>
              <Button
                style={{ height: '30px', borderRadius: '20px', padding: '5px 10px', marginRight: '5px', fontSize: '14px' }}
                type="button"
                severity="danger"
                icon="pi pi-delete-left"
                rounded
                onClick={() => {
                  setListFiltros({});
                  loadData(listFiltros, currentPage, setLoading, setNodes, updateTitles, updatePagination, setShowModalFiltro);
                }}              >
                <p style={{ marginLeft: '5px', marginTop: '13px' }}>Limpar</p>
              </Button>
            </div>
          </div>
        </div >
      </div>
      <div className={`${showSubSidebar ? "container-section-body" : "container-section-body-none"}`}>
        <DataTable
          stripedRows
          lazy
          value={nodes ? nodes : []}
          tableStyle={{ minWidth: '50rem' }}
          emptyMessage={isLoading ? (<Skeleton />) : (<span style={{ display: 'flex', justifyContent: 'center', fontStyle: 'italic' }}>Nenhum item encontrado.</span>)}        >
          <Column
            style={{ textAlign: 'right', width: '9%' }}
            key={'numero_interno_label'}
            field={'numero_interno_label'}
            headerClassName="righted-header"
            header={<div className="righted-content">Nº Interno</div>}
            body={isLoading && (<Skeleton />)}
          />
          <Column
            style={{ textAlign: 'left' }}
            key={'pessoa.nome_label'}
            field={'pessoa.nome_label'}
            headerClassName="lefted-header"
            header={<div className="lefted-content">Nome</div>}
            body={isLoading && (<Skeleton />)}
          />
          <Column
            style={{ textAlign: 'right' }}
            key={'pessoa.cpf_cnpj_label'}
            field={'pessoa.cpf_cnpj_label'}
            headerClassName="righted-header"
            header={<div className="righted-content">CPF/CNPJ</div>}
            body={isLoading && (<Skeleton />)}
          />
          <Column
            style={{ textAlign: 'left' }}
            key={'data_hora_falecimento_label'}
            field={'data_hora_falecimento_label'}
            headerClassName="lefted-header"
            header={<div className="lefted-content">Falecimento</div>}
            body={isLoading && (<Skeleton />)}
          />
          <Column
            style={{ textAlign: 'left' }}
            key={'data_hora_sepultamento_label'}
            field={'data_hora_sepultamento_label'}
            headerClassName="lefted-header"
            header={<div className="lefted-content">Sepultamento</div>}
            body={isLoading && (<Skeleton />)}
          />
          <Column
            className='menu-context-grid-inumado'
            body={(e) => actionTemplate(
              e,
              url_redirect_edit,
              setSelectedRow
            )}
            header="Menu"
            headerClassName=""
          />
        </DataTable>
        <ModalFiltroAvancado
          showModal={showModalFiltro}
          onHide={() => setShowModalFiltro(false)}
          handleClose={() => setShowModalFiltro(false)}
          listFiltros={listFiltros}
          setListFiltros={setListFiltros}
          loadData={loadData}
          setLoading={setLoading}
          currentPage={currentPage}
          setNodes={setNodes}
          updateTitles={updateTitles}
          updatePagination={updatePagination}
          setShowModalFiltro={setShowModalFiltro}
        />
        <Paginacao
          totalPages={pagination}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </div>
    </div>
  );
}

export default Inumados;