import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import { useAppContext } from '../../../contexts/AppContext';
import { url } from '../../../environments/environments-develop';
import PessoaForm from '../../Formularios/pessoa';
import UsuarioForm from './Formularios/usuario';
import EnderecoForm from '../../Formularios/endereco';
import TelefoneForm from '../../Formularios/telefone';
import { useUsuarioContext } from '../../../contexts/UsuarioContext';
import { showToast } from '../../../components/ToastComponent';
import { addTelefone, changeTelefone, removeTelefone } from '../../../utils/TelefoneUtils';
import { addEndereco, changeEndereco, removeEndereco } from '../../../utils/EnderecoUtils';
import { changeUsuario } from '../../../utils/UsuarioUtils';
import ProgressBarForm from '../../Formularios/progressBarForm';
import { addUserGrupo, changeUserGrupo, removeUserGrupo } from '../../../utils/GrupoUser';
import GrupoFIlial from './Formularios/UserGrupos';

function CreateUsuario() {
    const { setUrl, setItemsBreadsCrumbs } = useAppContext();
    const usuarioContext = useUsuarioContext();
    const [currentPart, setCurrentPart] = useState(1);
    const totalParts = 5;
    const [formData, setFormData] = useState<any>();

    useEffect(() => {
        setItemsBreadsCrumbs([
            {
                label: 'Configurações',
                url: '/configuracoes',
            },
            {
                label: 'Usuários',
                url: '/usuario',
            },
            {
                label: 'Criar'
            }
        ]);
        setUrl(`${url}/api/v1/admin/core/user`);
    }, []);

    const handleNext = () => {
        if (!formData) {
            showToast("error", "Os campos de Nome e CPF ou CNPJ é obrigatório!")
            return;
        }
        if (!formData?.pessoa?.cpf_cnpj) {
            showToast("error", "O campo CPF ou CNPJ é obrigatório!")
            return;
        }
        if (!formData.pessoa.nome && formData?.pessoa?.cpf_cnpj.length < 15) {
            showToast("error", "O campo Nome é obrigatório!")
            return;
        }
        if (!formData.pessoa.data_fundacao && formData?.pessoa?.cpf_cnpj.length > 14) {
            showToast("error", "O campo de Data de Fundação é obrigatório!")
            return;
        }
        if (currentPart < totalParts) {
            setCurrentPart((prevPart) => prevPart + 1);
        }
    };

    const handleBack = () => {
        if (currentPart > 1) {
            setCurrentPart((prevPart) => prevPart - 1);
        }
    };
    const handleChange = (field: string, value: any) => {
        if (field.startsWith('pessoa.')) {
            const pessoaField = field.replace('pessoa.', '');
            setFormData((data: any) => {
                return {
                    ...data,
                    pessoa: {
                        ...data?.pessoa,
                        [pessoaField]: value
                    },
                }
            });
        } else {
            setFormData((data: any) => {
                return {
                    ...data,
                    [field]: value
                }
            });
        }
    };

    // UserGrupo Formulario Utils
    const handleUserGrupoInputChange = (index: number, field: string, value: string) => {
        changeUserGrupo(formData, setFormData, index, field, value);
    };
    const handleAddUserGrupo = () => {
        addUserGrupo(formData, setFormData);
    };
    const handleRemoveUserGrupo = (index: number) => {
        removeUserGrupo(formData, setFormData, index);
    };

    // Endereco Formulario Utils
    const handleEnderecoInputChange = (index: number, field: string, value: string) => {
        changeEndereco(formData, setFormData, index, field, value);
    };
    const handleAddEndereco = () => {
        addEndereco(formData, setFormData);
    };
    const handleRemoveEndereco = (index: number) => {
        removeEndereco(formData, setFormData, index);
    };

    // Telefone Formulario Utils
    const handleTelefoneInputChange = (index: number, field: string, value: string) => {
        changeTelefone(formData, setFormData, index, field, value);
    };
    const handleAddTelefone = () => {
        addTelefone(formData, setFormData);
    };
    const handleRemoveTelefone = (index: number) => {
        removeTelefone(formData, setFormData, index);
    };
    return (
        <div className="body-content">
            <div className="form-user-create">
                <Container>
                    <ProgressBarForm
                        currentPart={currentPart}
                        totalParts={totalParts}
                        handleBack={handleBack}
                        handleNext={handleNext}
                        steps={["Pessoa", "Usuário", "Filial|Grupos", "Endereço", "Telefone"]}
                        body={formData}
                    >
                        {currentPart === 1 && (
                            <PessoaForm
                                tipoPessoa={4}
                                tipoAcao={"criar"}
                                isEnablePerfil={true}
                                pessoaData={formData?.pessoa}
                                onInputChange={(field: string, value: any) => handleChange(`pessoa.${field}`, value)}
                            />
                        )}
                        {currentPart === 2 && (
                            <UsuarioForm
                                formData={formData}
                                tipoAcao={"criar"}
                                onInputChange={(field: string, value: string) => handleChange(`${field}`, value)}
                            />
                        )}
                        {currentPart === 3 && (
                            <GrupoFIlial
                                formData={formData?.user_grupos || []}
                                onInputChange={(index: number, field: string, value: string) => handleUserGrupoInputChange(index, field, value)}
                                onAddEndereco={handleAddUserGrupo}
                                onRemoveEndereco={handleRemoveUserGrupo}
                            />
                        )}
                        {currentPart === 4 && (
                            <EnderecoForm
                                enderecosData={formData?.pessoa?.enderecos || []}
                                onInputChange={(index: number, field: string, value: string) => handleEnderecoInputChange(index, field, value)}
                                onAddEndereco={handleAddEndereco}
                                onRemoveEndereco={handleRemoveEndereco}
                            />
                        )}

                        {currentPart === 5 && (
                            <TelefoneForm
                                telefonesData={formData?.pessoa?.telefones || []}
                                onInputChange={(index: number, field: string, value: string) => {
                                    if (formData?.pessoa?.telefones && formData?.pessoa?.telefones?.length > 0) {
                                        handleTelefoneInputChange(index, field, value);
                                    } else {
                                        handleAddTelefone();
                                    }
                                }}
                                onAddTelefone={() => handleAddTelefone()}
                                onRemoveTelefone={(index: number) => handleRemoveTelefone(index)}
                            />
                        )}
                    </ProgressBarForm>

                </Container>
            </div>
        </div>
    );
}

export default CreateUsuario;
