import { Col, Form, Row } from "react-bootstrap";

function CorretoresForm(props: any) {
    const { status, comissao, qtd_lt_reservado, creci, observacao } = props.obraData || {};

    const handleChange = (field: string, value: string) => {
        if (field === 'comissao') {
            if (/^[0-9.;]*$/.test(value) && value.length <= 15) {
                props.onInputChange(field, value);
            }
        } else if (field === 'creci') {
            if (/^\d*$/.test(value) && value.length <= 11) {
                props.onInputChange(field, value);
            }
        } else if (field === 'qtd_lt_reservado') {
            if (/^\d*$/.test(value) && value.length <= 4) {
                props.onInputChange(field, value);
            }
        } else if (field === 'observacao') {
            if (!value || value.length <= 60) {
                props.onInputChange(field, value);
            }
        } else {
            props.onInputChange(field, value);
        }
    };

    return (
        <>
            <Form.Group className="usuario-form">
                <Row className="mb-3">
                    <Col>
                        <Form.Label>Comissão</Form.Label>
                        <Form.Control value={comissao} onChange={(e) => handleChange('comissao', e.target.value)} />
                    </Col>
                    <Col>
                        <Form.Label>Qtd permitida p/ Lotes reservados</Form.Label>
                        <Form.Control value={qtd_lt_reservado} onChange={(e) => handleChange('qtd_lt_reservado', e.target.value)} />
                    </Col>
                    <Col>
                        <Form.Label>CRECI</Form.Label>
                        <Form.Control value={creci} onChange={(e) => handleChange('creci', e.target.value)} />
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col md="2">
                        <Form.Label>Status</Form.Label>
                        <Form.Select value={status} onChange={(e) => handleChange('status', e.target.value)} >
                            <option>Selecione...</option>
                            <option value={0}>Ativo</option>
                            <option value={1}>Desativado</option>
                        </Form.Select>
                    </Col>
                    <Col>
                        <Form.Label>Observação</Form.Label>
                        <Form.Control value={observacao} onChange={(e) => handleChange('observacao', e.target.value)} />
                    </Col>
                </Row>
            </Form.Group>
        </>
    );
}

export default CorretoresForm;
