import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import { AlertService } from './AlertService';
import { url } from '../environments/environments-develop';
import api from '../services/api';
import { useEffect, useState } from 'react';

const not = (a: readonly number[], b: readonly number[]) =>
  a.filter(value => !b.includes(value));

const intersection = (a: readonly number[], b: readonly number[]) => {
  return a.filter(value => b.find((item: any) => item.id === value) !== undefined);
};

export interface TransferListProps {
  onCheckBoxChange: (grupos_permissoes: readonly any[]) => void;
  initialRight: readonly any[];
}

export default function TransferList(props: TransferListProps) {
  const [checked, setChecked] = useState<readonly number[]>([]);
  const [left, setLeft] = useState<readonly number[]>([]);
  const [right, setRight] = useState<readonly number[]>([]);

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  async function listPermission() {
    try {
      const response = await api.get(`${url}/api/v1/admin/core/listar-permissoes`);
      const data = localStorage.getItem('dadosEdicao');
      var dadosEdicao: any[] = [];
      dadosEdicao = response.data.data;
      if (data) {
        let valor: any[] = [];
        JSON.parse(data)?.grupo_permissoes?.data.map((value: any) => valor.push(value.permissoes));
        setRight(valor);
        dadosEdicao = response.data.data.filter((item: any) => !valor.some((v: any) => v.id === item.id));
      }
      setLeft(dadosEdicao);
    } catch (error) {
      AlertService.error('Error', 'Ocorreu um erro ao buscar a lista de permissões. Erro:' + String(error));
    }
  }
  useEffect(() => {
    props.onCheckBoxChange(retorno(right));
  }, [right]);

  useEffect(() => {
    listPermission();

  }, []);

  const retorno = (data: any) => {
    return data.map((value: any) => ({
      permissao_id: value.id,
      modulo_id: value.modulo_id
    }));
  };

  const handleToggle = (value: any) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleAllRight = () => {
    setRight(right.concat(left));
    setLeft([]);
  };

  const handleCheckedRight = () => {
    setRight((prevRight: any) => [...prevRight, ...leftChecked.map(value => left.find((item: any) => item.id === value))]);
    setLeft(prevLeft => prevLeft.filter((item: any) => !leftChecked.includes(item.id)));
    setChecked(prevChecked => not(prevChecked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft((prevLeft: any) => [...prevLeft, ...rightChecked.map(value => right.find((item: any) => item.id === value))]);
    setRight(prevRight => prevRight.filter((item: any) => !rightChecked.includes(item.id)));
    setChecked(prevChecked => not(prevChecked, rightChecked));
  };

  const handleAllLeft = () => {
    setLeft(left.concat(right));
    setRight([]);
  };

  const customList = (items: readonly any[], label: string) => (
    <Paper sx={{ width: 360, height: 230, overflow: 'auto', border: '0.7px solid #dee2e6 ', boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.32)' }}>
      <label className='label-select-transfer'>{label}</label>
      <List dense component="div" role="list">
        {(items || []).map((value: any, index) => {
          const labelId = `transfer-list-item-${index}-label`;
          return (
            <ListItem
              key={index}
              role="listitem"
              button
              onClick={handleToggle(value.id)}
              className={checked.indexOf(value.id) !== -1 ? "button-selection" : ""}
            >
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(value.id) !== -1}
                  tabIndex={-1}
                  disableRipple
                  style={{ visibility: "hidden" }}
                  inputProps={{
                    'aria-labelledby': labelId,
                  }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={`${value.titulo}`} />
            </ListItem>
          );
        })}
      </List>
    </Paper>
  );

  return (
    <Grid container spacing={1} justifyContent="center" alignItems="center">
      <Grid item>{customList(left, "Permissões Disponíveis")}</Grid>
      <Grid item>
        <Grid container direction="column" alignItems="center">
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleAllRight}
            disabled={left.length === 0}
            aria-label="move all right"
          >
            ≫
          </Button>
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleCheckedRight}
            disabled={leftChecked.length === 0}
            aria-label="move selected right"
          >
            &gt;
          </Button>
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleCheckedLeft}
            disabled={rightChecked.length === 0}
            aria-label="move selected left"
          >
            &lt;
          </Button>
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleAllLeft}
            disabled={right.length === 0}
            aria-label="move all left"
          >
            ≪
          </Button>
        </Grid>
      </Grid>
      <Grid item>{customList(right, "Permissões Selecionadas")}</Grid>
    </Grid>
  );
}
